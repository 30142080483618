import React from "react";

const SendIcon = () => {
  return (
    <svg
      width="42"
      height="42"
      viewBox="0 0 62 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="62" height="62" rx="14" fill="#4B5A5A" />
      <path
        d="M40.9532 36.5051L43.5278 28.7812C45.7771 22.0336 46.9016 18.6598 45.1207 16.8789C43.3399 15.098 39.9661 16.2226 33.2185 18.4718L25.4945 21.0465C20.0485 22.8618 17.3256 23.7695 16.5518 25.1005C15.8157 26.3667 15.8157 27.9305 16.5518 29.1967C17.3256 30.5278 20.0485 31.4354 25.4945 33.2507C26.1694 33.4757 26.9287 33.3151 27.4341 32.8144L35.6926 24.6324C36.1572 24.1721 36.907 24.1756 37.3672 24.6402C37.8276 25.1048 37.8241 25.8546 37.3594 26.3149L29.2343 34.3648C28.6774 34.9166 28.501 35.7613 28.7489 36.5051C30.5642 41.951 31.4719 44.6741 32.803 45.4478C34.0691 46.184 35.633 46.184 36.8992 45.4478C38.2303 44.6741 39.1379 41.9512 40.9532 36.5051Z"
        fill="white"
      />
    </svg>
  );
};

export default SendIcon;
