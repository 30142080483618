import React, { useState, useRef } from "react";
import Typography from "@mui/material/Typography";
import { Box, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { bgColors } from "../../utils/bgColors";
// import CloudSvg from "../../assets/svgs/CloudSvg";
// import { Button } from "react-scroll";

const useStyles = makeStyles({
  DragAndDropStyles: {
    color: bgColors.green,
    border: `1px solid ${bgColors.green}`,
    borderRadius: "8px",
    width: "150px",
    height: "32px",
    // padding: "5px",
    cursor: "pointer",
    backgroundColor: "#fff",
    "&:hover": {
      backgroundColor: "rgba(68, 71, 70, 0.08)",
    },
  },
  link: {
    textDecoration: "none",
    color: bgColors.blue,
  },
  inputDisplay: {
    display: "none",
  },
});

interface FileUploadProps {
  selectedFiles: File[];
  setSelectedFiles: React.Dispatch<React.SetStateAction<File[]>>;
}

const FileUpload: React.FC<FileUploadProps> = ({
  selectedFiles,
  setSelectedFiles,
}) => {
  const classes = useStyles();

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const files = Array.from(event.target.files);
      setSelectedFiles(files);
    }
  };

  return (
    <div>
      <Grid item xs={12}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            onClick={() => fileInputRef.current?.click()}
            className={classes.DragAndDropStyles}
          >
            <Typography>Choose a file</Typography>
          </Box>
        </Box>
        <input
          type="file"
          multiple
          accept=".xls, .xlsx"
          className={classes.inputDisplay}
          onChange={handleFileChange}
          ref={fileInputRef}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {selectedFiles?.map((file, index) => (
            <div key={index}>{file?.name}</div>
          ))}
        </div>
      </Grid>
    </div>
  );
};

export default FileUpload;
