import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Typography,
  InputLabel,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import TextFeildWithBorderComponet from "../common/TextFieldWithBorderComponent";
import { bgColors } from "../../utils/bgColors";
import { useAppDispatch, useAppSelector } from "../../utils/redux-hooks";
import { createContactTags } from "../../redux/slices/Contacts/CreateContactTags";
import { toastActions } from "../../utils/toastSlice";
import { getContactTags } from "../../redux/slices/Contacts/getContactTags";
import { IoMdPricetag } from "react-icons/io";

const useStyles = makeStyles({
  changePasswordContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  updateButtonStyles: {
    backgroundColor: "#3C3C3C",
    color: "#ffffff",
    height: "40px",
    borderRadius: "8px",
    width: "100%",
  },
  blackColor: {
    color: "#303030 !important",
    fontWeight: "500 !important",
  },
  signOutButtonStyles: {
    backgroundColor: "#3C3C3C",
    color: "#ffffff",
    height: "40px",
    // width: "30%",
    borderRadius: "8px",
    fontSize: "14px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // paddingLeft: "35px",
    // paddingRight: "35px",
    paddingInline: "27px ",
    fontWeight: 500,
    cursor: "pointer",
  },
  cancelButtonStyles: {
    backgroundColor: "#ffffff",
    color: "#000000",
    height: "40px",
    // width: "30%",
    borderRadius: "8px",
    // paddingLeft: "35px",
    // paddingRight: "35px",
    paddingInline: "27px ",
    border: `1px solid ${bgColors.gray3}`,
    fontSize: "14px",
    fontWeight: 500,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
});

const MoveNewTagMember = ({ open, handleClose }: any) => {
  const userProfileSlice = useAppSelector((state: any) => state?.adminLogin);
  const userData = userProfileSlice?.data;
  const [tagsData, setTagsData] = useState("");
  const [error, setError] = useState(false);
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const handleChange = (e: any) => {
    setTagsData(e.target.value);
    setError(false);
    setHelperText("");
  };
  const handleSave = async () => {
    if (tagsData?.trim() !== "") {
      const bodyData = {
        BusinessId: userData?.companyId,
        UserId: userData?.userId,
        data: tagsData,
      };
      const body = {
        businessId: userData?.companyId,
        userId: userData?.userId,
        search: "",
      };
      try {
        const response: any = await dispatch(createContactTags(bodyData));
        // console.log("res", response);
        setTagsData("");
        if (
          response?.payload?.message === "The tag was created successfully."
        ) {
          dispatch(
            toastActions.setToaster({
              type: "success",
              message: response?.payload?.message,
            })
          );
          await dispatch(getContactTags(body));
          handleClose();
        } else {
          dispatch(
            toastActions.setToaster({
              type: "error",
              message: response?.payload,
            })
          );
        }
      } catch (error: any) {
        console.log(error, "error");
        dispatch(
          toastActions.setToaster({
            type: "error",
            message: `Error creating Tag`,
          })
        );
      }
      handleClose();
    } else {
      setError(true);
      setHelperText("Tag name is required");
    }
  };

  const handleCloseAll = () => {
    handleClose();
    setTagsData("");
    setError(false);
    setHelperText("");
  };
  const [helperText, setHelperText] = useState("");

  return (
    <Dialog
      open={open}
      onClose={handleCloseAll}
      PaperProps={{ style: { minWidth: "28%", borderRadius: "20px" } }}
    >
      <DialogTitle>
        <Box m={2} mb={0} className={classes.changePasswordContainer}>
          <Typography
            variant="h6"
            style={{
              fontWeight: "bold",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span style={{ marginTop: "5px" }}>
              <IoMdPricetag />
            </span>
            &nbsp;Create Tag
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box mx={0} mt={0}>
          <Box>
            <InputLabel className={classes.blackColor}>Tag Name</InputLabel>
            <TextFeildWithBorderComponet
              label="Tag name"
              name="text"
              placeholder=""
              value={tagsData}
              error={error}
              // helperText={error ? "Enter tag name" : " "}
              helperText={helperText}
              onChange={handleChange}
              sx={{
                "& input": {
                  // fontWeight: "600"
                },
              }}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions sx={{ paddingTop: "0px" }}>
        <Box
          m={{ xs: 1, md: 2 }}
          display="flex"
          flexDirection="row"
          gap={2}
          justifyContent={"center"}
          sx={{
            marginTop: "0px !important",
          }}
          width="100%"
        >
          <button
            className={classes.cancelButtonStyles}
            onClick={handleCloseAll}
          >
            Cancel
          </button>
          <button className={classes.signOutButtonStyles} onClick={handleSave}>
            Create
          </button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default MoveNewTagMember;
