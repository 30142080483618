import React from "react";
import TemplatePreviewLayout from "../../TemplateComponents/TemplateForm/templatePreviewLayout";
import { Box } from "@mui/material";
// import { convertFromHTML } from "draft-js";
import { parseTextToHtml } from "../../../utils/functions";

export const maxWidthStyle = { maxWidth: { xs: "330px", md: "580px" } };

const renderMessageContent = (
  msg: {
    templateBody: null;
    templateHeader: string | undefined;
    templateFooter: string | undefined;
    templateMediaType: any;
    templateMediaFile: string | File | undefined;
    mediaMimeType: string;
    mediaUrl: string | undefined;
    mediaFileName: any;
    textMessage: string;
    mediaCaption: any;
  },
  index: string | number,
  messageRefs: any,
  handleOpenMediaPopover: (arg0: any) => void,
  highlightText: (arg0: any) => any,
  linkifyText: (arg0: any) => any,
  buttonsArray:
    | {
        buttonType: string;
        buttonValue?: string | undefined;
        buttonName?: string | undefined;
      }[]
    | undefined
) => {
  // console.log("msg:", msg?.textMessage);

  if (msg?.templateBody !== null) {
    return (
      <Box sx={maxWidthStyle}>
        <TemplatePreviewLayout
          header={msg?.templateHeader || ""}
          body={msg?.templateBody}
          footer={msg?.templateFooter || ""}
          mediaType={msg?.templateMediaType}
          mediaFile={msg?.templateMediaFile}
          buttons={buttonsArray}
        />
      </Box>
    );
  }
  // if (msg?.mediaMimeType && !msg?.mediaUrl) return <>{msg?.mediaFileName}</>;
  if (msg?.textMessage) {
    return (
      <Box sx={maxWidthStyle}>
        <span
          ref={(el) => (messageRefs.current[index] = el)}
          dangerouslySetInnerHTML={{
            __html: highlightText(
              linkifyText(parseTextToHtml(msg?.textMessage))
            ),
          }}
        />
      </Box>
    );
  }
  if (["image/jpeg", "image/png"]?.includes(msg?.mediaMimeType)) {
    return (
      <Box sx={maxWidthStyle}>
        <img
          src={msg?.mediaUrl}
          alt="media"
          style={{ maxWidth: 330, cursor: "pointer" }}
          onClick={() => handleOpenMediaPopover(msg)}
        />
        <div
          dangerouslySetInnerHTML={{
            __html: highlightText(
              linkifyText(parseTextToHtml(msg?.mediaCaption || ""))
            ),
          }}
        />
      </Box>
    );
  }
  if (
    ["audio/mpeg", "audio/ogg", "audio/aar", "audio/amr"]?.includes(
      msg?.mediaMimeType?.split(";")[0]
    )
  ) {
    return (
      <Box sx={maxWidthStyle}>
        <audio
          src={msg?.mediaUrl}
          controls // Added controls for user interaction
          style={{ maxWidth: 330, cursor: "pointer" }}
          // onClick={() => handleOpenMediaPopover(msg)} // Optional if you need to handle click
        />
      </Box>
    );
  }

  if (msg?.mediaMimeType === "application/pdf") {
    return (
      <Box sx={maxWidthStyle}>
        <a
          href={msg?.mediaUrl}
          // download={msg?.mediaCaption}
          target="_blank"
          style={{ fontSize: "12px" }}
          rel="noreferrer"
        >
          <img
            src="/images/pdfIcon.png"
            alt=""
            style={{ height: 60, width: 60, cursor: "pointer" }}
          />
          <div
            dangerouslySetInnerHTML={{
              __html: highlightText(parseTextToHtml(msg?.mediaFileName || "")),
            }}
          />
        </a>
        <div
          dangerouslySetInnerHTML={{
            __html: highlightText(
              linkifyText(parseTextToHtml(msg?.mediaCaption || ""))
            ),
          }}
        />
      </Box>
    );
  }
  if (msg?.mediaMimeType === "text/plain") {
    return (
      <Box sx={maxWidthStyle}>
        <a
          href={msg?.mediaUrl}
          // download={msg?.mediaCaption}
          target="_blank"
          style={{ fontSize: "12px", textDecoration: "none" }}
          rel="noreferrer"
        >
          <img
            src="/images/txtIcon.png"
            alt=""
            style={{ height: 60, width: 60, cursor: "pointer" }}
          />
          <div
            dangerouslySetInnerHTML={{
              __html: highlightText(parseTextToHtml(msg?.mediaFileName || "")),
            }}
          />
        </a>
        <div
          dangerouslySetInnerHTML={{
            __html: highlightText(
              linkifyText(parseTextToHtml(msg?.mediaCaption || ""))
            ),
          }}
        />
      </Box>
    );
  }
  if (
    msg?.mediaMimeType ===
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
  ) {
    return (
      <Box sx={maxWidthStyle}>
        <a
          href={msg?.mediaUrl}
          // download={msg?.mediaCaption}
          target="_blank"
          style={{ fontSize: "12px", textDecoration: "none" }}
          rel="noreferrer"
        >
          <img
            src="/images/docxIcon.png"
            alt=""
            style={{ height: 60, width: 60, cursor: "pointer" }}
          />
          <div
            dangerouslySetInnerHTML={{
              __html: highlightText(parseTextToHtml(msg?.mediaFileName || "")),
            }}
          />
        </a>
        <div
          dangerouslySetInnerHTML={{
            __html: highlightText(
              linkifyText(parseTextToHtml(msg?.mediaCaption || ""))
            ),
          }}
        />
      </Box>
    );
  }
  if (msg?.mediaMimeType === "video/mp4") {
    return (
      <Box sx={maxWidthStyle}>
        <video controls src={msg?.mediaUrl} style={{ maxWidth: 330 }} />
        <div
          dangerouslySetInnerHTML={{
            __html: highlightText(
              linkifyText(parseTextToHtml(msg?.mediaCaption || ""))
            ),
          }}
        />
      </Box>
    );
  }
  if (
    msg?.mediaMimeType ===
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  ) {
    return (
      <Box sx={maxWidthStyle}>
        <a
          href={msg?.mediaUrl}
          // download={msg?.mediaCaption}
          target="_blank"
          style={{ fontSize: "12px", textDecoration: "none" }}
          rel="noreferrer"
        >
          <img
            src="/images/xlsxIcon.png"
            alt=""
            style={{ height: 60, width: 60, cursor: "pointer" }}
          />
          <div
            dangerouslySetInnerHTML={{
              __html: highlightText(parseTextToHtml(msg?.mediaFileName || "")),
            }}
          />
        </a>
        <div
          dangerouslySetInnerHTML={{
            __html: highlightText(
              linkifyText(parseTextToHtml(msg?.mediaCaption || ""))
            ),
          }}
        />
      </Box>
    );
  }
  return null;
};

export default renderMessageContent;
