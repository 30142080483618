import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  IconButton,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
  Select,
  Button,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import AddVariablePopup from "./AddVariablePopup";
import ButtonsAndListComponent from "./ButtonsAndListComponent";
import VariableAssignmentComponent from "./variableAssignmentComponent";
import TextFieldWithBorderComponent from "../../common/TextFieldWithBorderComponent";
import {
  dictionaryOptions,
  SaveResponseRadioOptions,
  variableOptions,
} from "./functions";
import { bgColors } from "../../../utils/bgColors";
import LoadingComponent from "../../common/LoadingComponent";
import { useAppSelector } from "../../../utils/redux-hooks";

interface WebhookAccordionsProps {
  requestType: string;
  requestUrl: string;
  handleRequestTypeChange: (e: any) => void;
  handleRequestUrlChange: (value: string) => void;
  handleAddVariable: (field: string, index?: number) => void;
  headers: Array<{ key: string; value: string }>;
  handleHeaderChange: (index: number, field: string, value: string) => void;
  handleStepDataChange: (stepNumber: number, data: any) => void;
  handleRemoveHeader: (index: number) => void;
  handleAddHeader: () => void;
  handleAddNewClick: () => void;
  handlePopupClose: () => void;
  handlePopupSuccess: () => void;
  expandedStep: number;
  workflowData: any;
  handleVariablesChange: (stepNumber: number, newVariables: any[]) => void;
  handleEditorStateChange: (
    stepNumber: number,
    newEditorOrTextState: any,
    type: string
  ) => void;
  headerValueInputRef: React.RefObject<HTMLInputElement>;
  requestUrlInputRef: React.RefObject<HTMLInputElement>;
  bodyDescriptionInputRef: React.RefObject<HTMLInputElement>;
  handleBodyDescriptionChange: (e: any) => void;
  bodyDescription: string;
  handleSaveUserVariableChange: (e: any) => void;
  selectedVariable: string;
  responseOption: string;
  setResponseOption: (value: string) => void;
  variableNames: any;
  isAddNewPopupOpen: boolean;
  setNewVariableName: (value: string) => void;
  newVariableName: string;
  errorDescription: string;
  handleErrorDescriptionChange: (e: any) => void;
  handleSelectedListChange: (list: any) => void;
  handleButtonsChange: (buttons: any) => void;
  showSaveUserResponse: boolean;
  toggleSaveUserResponse: () => void;
  handleSave: (step: number) => void;
  isWorkflowEditing: boolean;
}

const WebhookAccordions: React.FC<WebhookAccordionsProps> = ({
  requestType,
  requestUrl,
  handleRequestTypeChange,
  handleRequestUrlChange,
  handleAddVariable,
  handleStepDataChange,
  headers,
  handleHeaderChange,
  handleRemoveHeader,
  handleAddHeader,
  handleAddNewClick,
  handlePopupClose,
  handlePopupSuccess,
  expandedStep,
  workflowData,
  handleVariablesChange,
  handleEditorStateChange,
  headerValueInputRef,
  requestUrlInputRef,
  bodyDescriptionInputRef,
  handleBodyDescriptionChange,
  bodyDescription,
  handleSaveUserVariableChange,
  selectedVariable,
  responseOption,
  setResponseOption,
  variableNames,
  isAddNewPopupOpen,
  setNewVariableName,
  newVariableName,
  errorDescription,
  handleErrorDescriptionChange,
  handleSelectedListChange,
  handleButtonsChange,
  showSaveUserResponse,
  toggleSaveUserResponse,
  handleSave,
  isWorkflowEditing,
}) => {
  const { createWorkflowStatus } = useAppSelector(
    (state: any) => state?.createWorkflow
  );
  const { updateWorkflowStatus } = useAppSelector(
    (state: any) => state?.updateWorkflow
  );
  const currentStep = workflowData?.filter(
    (workflow: any) => workflow?.stepType === 3
  );
  //   console.log("headers", workflowData, headers);

  return (
    <div>
      {/* First Accordion: Define URL */}
      <Accordion sx={{ padding: "0px", marginBottom: "8px" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{ minHeight: "30px", padding: "1px 6px", margin: "0px" }}
        >
          <Typography sx={{ fontSize: "14px" }}>Define URL</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: "8px" }}>
          <div style={{ marginBottom: "8px" }}>
            <Select
              //   label="Request Type"
              //   placeholder=""
              value={requestType}
              onChange={(e) => handleRequestTypeChange(e as any)}
              fullWidth
              variant="outlined"
              sx={{
                height: "36px",
                fontSize: "12px",
                borderRadius: "6px",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "6px",
                },
              }}
            >
              <MenuItem sx={{ fontSize: "12px" }} value="GET">
                GET
              </MenuItem>
              <MenuItem sx={{ fontSize: "12px" }} value="POST">
                POST
              </MenuItem>
              <MenuItem sx={{ fontSize: "12px" }} value="PUT">
                PUT
              </MenuItem>
              <MenuItem sx={{ fontSize: "12px" }} value="DELETE">
                DELETE
              </MenuItem>
            </Select>
            <TextFieldWithBorderComponent
              inputRef={requestUrlInputRef}
              label="Request URL"
              variant="outlined"
              placeholder=""
              name="key"
              fullWidth
              sx={{
                marginTop: "8px",
                fontSize: "12px",
              }}
              value={requestUrl}
              onChange={(e: any) => handleRequestUrlChange(e.target.value)}
            />
          </div>
          <Typography
            color="green"
            variant="body2"
            sx={{ cursor: "pointer", fontSize: "12px", mb: 1 }}
            onClick={() => handleAddVariable("requestUrl")}
          >
            + Add variable
          </Typography>
          {currentStep[0]?.veriables?.some(
            (variable: any) => variable.type === 2
          ) && (
            <VariableAssignmentComponent
              requestUrl={requestUrl}
              expandedStep={expandedStep}
              variableType={2}
              workflowData={workflowData}
              variableOptions={variableOptions}
              dictionaryOptions={dictionaryOptions}
              handleVariablesChange={handleVariablesChange}
              handleEditorStateChange={handleEditorStateChange}
            />
          )}
        </AccordionDetails>
      </Accordion>

      {/* Second Accordion: Customize Header */}
      <Accordion sx={{ padding: "0px", marginBottom: "8px" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{ minHeight: "30px", padding: "1px 6px", margin: "0px" }}
        >
          <Typography sx={{ fontSize: "14px" }}>Customize Header</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: "8px" }}>
          {headers?.map((header, index) => (
            <div
              key={index}
              style={{
                alignItems: "center",
                marginBottom: "8px",
              }}
            >
              <Box sx={{ display: "flex" }}>
                <TextFieldWithBorderComponent
                  label="Key"
                  variant="outlined"
                  placeholder=""
                  name="key"
                  value={header.key}
                  onChange={(e: any) =>
                    handleHeaderChange(index, "key", e.target.value)
                  }
                  sx={{ marginRight: "8px", fontSize: "12px" }}
                  fullWidth
                  disabled={index === 0} // Disable first row
                />
                <TextFieldWithBorderComponent
                  inputRef={headerValueInputRef}
                  label="Value"
                  variant="outlined"
                  placeholder=""
                  name="value"
                  value={header.value}
                  onChange={(e: any) =>
                    handleHeaderChange(index, "value", e.target.value)
                  }
                  sx={{ marginRight: "8px", fontSize: "12px" }}
                  fullWidth
                  disabled={index === 0} // Disable first row
                />
                {index > 0 && (
                  <IconButton
                    color="secondary"
                    onClick={() => handleRemoveHeader(index)}
                    aria-label="remove header"
                    size="small"
                  >
                    <RemoveCircleOutlineIcon fontSize="small" />
                  </IconButton>
                )}
              </Box>
              {headers?.length > 1 &&
                headers[0]?.value === "application/json" && (
                  <>
                    {index !== 0 && !headers[index]?.value.includes("{{") && (
                      <Typography
                        color="green"
                        variant="body2"
                        onClick={() => handleAddVariable("headers", index)}
                        sx={{
                          cursor: "pointer",
                          fontSize: "12px",
                          margin: "0px 6px",
                        }}
                      >
                        + Add variable
                      </Typography>
                    )}
                  </>
                )}
            </div>
          ))}
          {currentStep[0]?.veriables?.some(
            (variable: any) => variable.type === 4
          ) && (
            <VariableAssignmentComponent
              arrayState={headers}
              expandedStep={expandedStep}
              variableType={4}
              workflowData={workflowData}
              variableOptions={variableOptions}
              dictionaryOptions={dictionaryOptions}
              handleVariablesChange={handleVariablesChange}
              handleEditorStateChange={handleEditorStateChange}
            />
          )}
          <Typography
            variant="body2"
            color="green"
            onClick={handleAddHeader}
            sx={{ cursor: "pointer", fontSize: "12px", marginTop: "6px" }}
          >
            + Add another header
          </Typography>
        </AccordionDetails>
      </Accordion>

      {/* Third Accordion: Customize Body */}
      <Accordion sx={{ padding: "0px", marginBottom: "8px" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{
            minHeight: "30px",
            padding: "1px 6px",
            margin: "0px",
          }}
        >
          <Box sx={{ display: "block" }}>
            <Typography sx={{ fontSize: "14px" }}>Customize Body</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: "8px" }}>
          <Typography variant="body2" sx={{ fontSize: "12px", color: "gray" }}>
            Request Body (JSON only)
          </Typography>
          <TextFieldWithBorderComponent
            inputRef={bodyDescriptionInputRef}
            label="Body"
            variant="outlined"
            placeholder=""
            name="body"
            value={bodyDescription}
            onChange={handleBodyDescriptionChange}
            fullWidth
            multiline
            rows={4}
            sx={{
              fontSize: "12px",
            }}
          />
          <Typography
            color="green"
            variant="body2"
            sx={{ cursor: "pointer", fontSize: "12px", margin: "0px 6px" }}
            onClick={() => handleAddVariable("body")}
          >
            + Add variable
          </Typography>
          {currentStep[0]?.veriables?.some(
            (variable: any) => variable.type === 3
          ) && (
            <VariableAssignmentComponent
              bodyDescription={bodyDescription}
              expandedStep={expandedStep}
              variableType={3}
              workflowData={workflowData}
              variableOptions={variableOptions}
              dictionaryOptions={dictionaryOptions}
              handleVariablesChange={handleVariablesChange}
              handleEditorStateChange={handleEditorStateChange}
            />
          )}
        </AccordionDetails>
      </Accordion>

      {/* Fourth Accordion: Save Response */}
      <Accordion sx={{ padding: "0px", marginBottom: "8px" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{ minHeight: "30px", padding: "1px 6px", margin: "0px" }}
        >
          <Box sx={{ display: "block" }}>
            <Typography sx={{ fontSize: "14px" }}>Save Response</Typography>
            <Typography
              variant="body2"
              sx={{ fontSize: "12px", color: "gray" }}
            >
              Select where you want to save this user response
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: "8px" }}>
          <Box sx={{ mt: 1, mb: 2 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                onClick={toggleSaveUserResponse}
                style={{
                  color: "green",
                  fontWeight: "600",
                  fontSize: "14px",
                  cursor: "pointer",
                }}
              >
                + Save user response
              </Typography>
              <Box>
                <IconButton size="small" onClick={toggleSaveUserResponse}>
                  {showSaveUserResponse ? (
                    <CancelOutlinedIcon fontSize="small" color="error" />
                  ) : (
                    <CheckCircleOutlineIcon fontSize="small" color="primary" />
                  )}
                </IconButton>
              </Box>
            </Box>

            {showSaveUserResponse && (
              <Box
                sx={{
                  mt: 2,
                  px: 1,
                  bgcolor: "white",
                  //   border: "1px solid gray",
                  position: "relative",
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: 2,
                    right: 0,
                    display: "flex",
                  }}
                >
                  <IconButton
                    size="small"
                    onClick={() => {
                      /* Add save functionality */
                    }}
                    sx={{ mr: 1 }}
                  >
                    <CheckCircleOutlineIcon fontSize="small" color="primary" />
                  </IconButton>
                </Box>
                <RadioGroup
                  value={responseOption}
                  onChange={(e) => setResponseOption(e.target.value)}
                >
                  {SaveResponseRadioOptions?.map((option) => (
                    <FormControlLabel
                      key={option.value}
                      value={option.value}
                      control={
                        <Radio
                          sx={{
                            "&.Mui-checked": { color: "green" },
                            "& .MuiSvgIcon-root": { fontSize: 16 },
                          }}
                        />
                      }
                      label={option.label}
                      sx={{
                        "& .MuiFormControlLabel-label": { fontSize: "14px" },
                      }}
                    />
                  ))}
                </RadioGroup>
                {responseOption === "Variable" && (
                  <Select
                    fullWidth
                    displayEmpty
                    value={
                      workflowData[expandedStep - 1]?.responseCustomerMessage
                        ?.veriableName?.id || selectedVariable
                    }
                    onChange={handleSaveUserVariableChange}
                    sx={{
                      mt: 1,
                      height: "36px",
                      fontSize: "14px",
                      borderRadius: "10px",
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "10px",
                      },
                    }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 150,
                          overflowY: "auto",
                        },
                      },
                    }}
                  >
                    <MenuItem value="" disabled>
                      <Typography style={{ fontSize: "14px" }}>
                        Select a variable
                      </Typography>
                    </MenuItem>
                    {variableNames?.data?.map((item: any) => (
                      <MenuItem
                        key={item.id}
                        value={item.id}
                        sx={{ fontSize: 14 }}
                      >
                        {item.veriableName}
                      </MenuItem>
                    ))}
                    <Box
                      sx={{
                        position: "sticky",
                        bottom: 0,
                        backgroundColor: "background.paper",
                        borderTop: "1px solid",
                        borderColor: "divider",
                        display: "flex",
                        justifyContent: "center",
                        p: 1,
                      }}
                    >
                      <Typography
                        sx={{
                          color: "green",
                          fontWeight: 600,
                          fontSize: "14px",
                          cursor: "pointer",
                        }}
                        onClick={() => handleAddNewClick()}
                      >
                        + Add New
                      </Typography>
                    </Box>
                  </Select>
                )}
                <AddVariablePopup
                  open={isAddNewPopupOpen}
                  onClose={handlePopupClose}
                  onSuccess={handlePopupSuccess}
                  setNewVariableName={setNewVariableName}
                  newVariableName={newVariableName}
                />
              </Box>
            )}
          </Box>
        </AccordionDetails>
      </Accordion>

      {/* Fifth Accordion: Error Handling */}
      <Accordion sx={{ padding: "0px", marginBottom: "8px" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{ minHeight: "30px", padding: "1px 6px", margin: "0px" }}
        >
          <Box sx={{ display: "block" }}>
            <Typography sx={{ fontSize: "14px" }}>Error Handling</Typography>
            <Typography
              variant="body2"
              sx={{ fontSize: "12px", color: "gray" }}
            >
              Inform end users how to proceed in case of errors
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: "8px" }}>
          <Typography sx={{ fontSize: "12px" }}>
            Default Error Response
          </Typography>
          <Typography variant="body2" sx={{ fontSize: "11px", color: "gray" }}>
            Short description of default response
          </Typography>
          <TextFieldWithBorderComponent
            label="Default Error Response"
            variant="outlined"
            placeholder=""
            value={errorDescription}
            onChange={handleErrorDescriptionChange}
            name="defaultError"
            fullWidth
            multiline
            rows={4}
            sx={{
              fontSize: "10px",
            }}
          />

          <ButtonsAndListComponent
            expandedStep={expandedStep}
            handleStepDataChange={handleStepDataChange}
            workflowData={workflowData}
            handleSelectedListChange={handleSelectedListChange}
            handleButtonsChange={handleButtonsChange}
          />
        </AccordionDetails>
      </Accordion>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        {createWorkflowStatus === "loading" ||
        updateWorkflowStatus === "loading" ? (
          <LoadingComponent height="50px" color={bgColors.blue} />
        ) : (
          <Button
            fullWidth
            variant="outlined"
            onClick={() => handleSave(expandedStep)}
            style={{
              color: bgColors.green,
              border: `1px solid ${bgColors.green}`,
              borderRadius: "8px",
              width: "70px",
              height: "36px",
              textTransform: "capitalize",
              // padding: "5px",
              cursor: "pointer",
              backgroundColor: "#fff",
              marginTop: "3px",
            }}
          >
            {isWorkflowEditing ? "Update" : "Save"}
          </Button>
        )}
      </Box>
    </div>
  );
};

export default WebhookAccordions;
