import React from "react";

const ReadIconSvg = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9167 7.58324H20.5834C21.1821 7.58324 21.6667 7.09813 21.6667 6.49991C21.6667 5.90169 21.1821 5.41658 20.5834 5.41658H11.9167C11.3179 5.41658 10.8334 5.90169 10.8334 6.49991C10.8334 7.09813 11.3179 7.58324 11.9167 7.58324ZM11.9167 14.0832H20.5834C21.1821 14.0832 21.6667 13.5981 21.6667 12.9999C21.6667 12.4017 21.1821 11.9166 20.5834 11.9166H11.9167C11.3179 11.9166 10.8334 12.4017 10.8334 12.9999C10.8334 13.5981 11.3179 14.0832 11.9167 14.0832ZM11.9167 20.5832H20.5834C21.1821 20.5832 21.6667 20.0981 21.6667 19.4999C21.6667 18.9017 21.1821 18.4166 20.5834 18.4166H11.9167C11.3179 18.4166 10.8334 18.9017 10.8334 19.4999C10.8334 20.0981 11.3179 20.5832 11.9167 20.5832ZM4.57885 20.2515L5.66218 21.3349C5.87376 21.5464 6.15098 21.6523 6.4281 21.6523C6.70522 21.6523 6.98244 21.5464 7.19402 21.3349L9.36068 19.1682C9.78383 18.745 9.78383 18.0595 9.36068 17.6362C8.93753 17.213 8.252 17.2131 7.82874 17.6362L6.42799 19.037L6.11058 18.7196C5.68743 18.2964 5.00189 18.2964 4.57863 18.7196C4.15538 19.1427 4.15559 19.8284 4.57885 20.2515ZM4.57885 13.7515L5.66218 14.8349C5.87376 15.0464 6.15098 15.1523 6.4281 15.1523C6.70522 15.1523 6.98244 15.0464 7.19402 14.8349L9.36068 12.6682C9.78383 12.245 9.78383 11.5595 9.36068 11.1363C8.93753 10.713 8.252 10.7131 7.82874 11.1363L6.42799 12.537L6.11058 12.2196C5.68743 11.7964 5.00189 11.7964 4.57863 12.2196C4.15538 12.6427 4.15559 13.3284 4.57885 13.7515ZM5.66218 8.33486C5.87376 8.54643 6.15098 8.65228 6.4281 8.65228C6.70522 8.65228 6.98244 8.54643 7.19402 8.33486L9.36068 6.16819C9.78383 5.74504 9.78383 5.05951 9.36068 4.63625C8.93753 4.21299 8.252 4.2131 7.82874 4.63625L6.42799 6.037L6.11058 5.71958C5.68743 5.29643 5.00189 5.29643 4.57863 5.71958C4.15538 6.14273 4.15548 6.82827 4.57863 7.25153L5.66218 8.33486Z"
        fill="#4B5A5A"
      />
    </svg>
  );
};

export default ReadIconSvg;
