import React from "react";

const ArrowDownSvg = () => {
  return (
    <svg
      width="12"
      height="10"
      viewBox="0 0 14 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.6"
        d="M12.5862 1.10352L6.7931 6.89662L1 1.10352"
        stroke="#4B5A5A"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowDownSvg;
