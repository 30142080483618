import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputLabel,
} from "@mui/material";
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from "../../../utils/redux-hooks";
import { addVariableName } from "../../../redux/slices/Workflows/addVariableNamesSlice";
import { toastActions } from "../../../utils/toastSlice";
import { getVariableNames } from "../../../redux/slices/Workflows/getVariableNamesSlice";
import LoadingComponent from "../../common/LoadingComponent";
import { bgColors } from "../../../utils/bgColors";
import { makeStyles } from "@mui/styles";
import TextFeildWithBorderComponet from "../../common/TextFieldWithBorderComponent";

interface AddVariablePopupProps {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  setNewVariableName: any;
  newVariableName: any;
}

const useStyles = makeStyles({
  changePasswordContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  blackColor: {
    color: "#303030 !important",
    fontWeight: "500 !important",
  },
  signOutButtonStyles: {
    backgroundColor: "#3C3C3C",
    color: "#ffffff",
    height: "40px",
    borderRadius: "8px",
    fontSize: "14px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingInline: "27px ",
    cursor: "pointer",
  },
  cancelButtonStyles: {
    backgroundColor: "#ffffff",
    color: "#000000",
    height: "40px",
    borderRadius: "8px",
    paddingInline: "27px ",
    border: `1px solid ${bgColors.gray3}`,
    fontSize: "14px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
});

const AddVariablePopup: React.FC<AddVariablePopupProps> = ({
  open,
  onClose,
  onSuccess,
  setNewVariableName,
  newVariableName,
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const userProfileSlice = useAppSelector((state: any) => state?.adminLogin);
  const userData = userProfileSlice?.data;
  const [isVariableLoading, setIsVariableLoading] = useState(false);

  const handleSaveNewVariable = async () => {
    if (!newVariableName.trim()) {
      dispatch(
        toastActions.setToaster({
          message: "Variable name cannot be empty",
          type: "error",
        })
      );
      return;
    }

    const payload = {
      companyId: userData?.companyId,
      userId: userData?.id,
      veriableName: newVariableName,
    };

    try {
      setIsVariableLoading(true);
      const result = await dispatch(addVariableName(payload)).unwrap();
      if (result.success) {
        setIsVariableLoading(false);
        dispatch(getVariableNames(payload)).unwrap();
        dispatch(
          toastActions.setToaster({
            message: "Variable added successfully",
            type: "success",
          })
        );
        setNewVariableName("");
        onSuccess();
        onClose();
      } else {
        dispatch(
          toastActions.setToaster({
            message: result.message || "Failed to add variable",
            type: "error",
          })
        );
      }
    } catch (error) {
      dispatch(
        toastActions.setToaster({
          message: "An error occurred while adding the variable",
          type: "error",
        })
      );
    }
    setIsVariableLoading(false);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { minWidth: "28%", borderRadius: "20px" } }}
    >
      <DialogTitle>
        <Box mx={1} mb={0} className={classes.changePasswordContainer}>
          <Typography
            variant="h6"
            style={{
              fontWeight: "bold",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Add new variable
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box mx={0} mt={0}>
          <Box>
            <InputLabel className={classes.blackColor}>Variable</InputLabel>
            <TextFeildWithBorderComponet
              //   id="variable-name"
              label="Variable Name"
              name="text"
              placeholder=""
              value={newVariableName}
              onChange={(e) => setNewVariableName(e.target.value)}
            />
          </Box>
        </Box>
      </DialogContent>
      {isVariableLoading ? (
        <LoadingComponent height="auto" color={bgColors?.blue} />
      ) : (
        <DialogActions sx={{ paddingTop: "0px" }}>
          <Box
            m={{ xs: 1, md: 2 }}
            sx={{ marginTop: "0px !important" }}
            display="flex"
            flexDirection="row"
            gap={2}
            justifyContent={"center"}
            width="100%"
          >
            <button className={classes.cancelButtonStyles} onClick={onClose}>
              Cancel
            </button>
            <button
              className={classes.signOutButtonStyles}
              onClick={handleSaveNewVariable}
            >
              Save
            </button>
          </Box>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default AddVariablePopup;
