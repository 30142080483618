import React, { useRef, useState } from "react";
import { Box, IconButton } from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";

interface CustomAudioPlayerProps {
  mediaUrl: string;
}

const CustomAudioPlayer: React.FC<CustomAudioPlayerProps> = ({ mediaUrl }) => {
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  //   const [isMuted, setIsMuted] = useState(false);

  const handlePlayPause = () => {
    if (audioRef.current) {
      if (audioRef.current.paused) {
        audioRef.current.play();
        setIsPlaying(true);
      } else {
        audioRef.current.pause();
        setIsPlaying(false);
      }
    }
  };

  //   const handleStop = () => {
  //     if (audioRef.current) {
  //       audioRef.current.pause();
  //       audioRef.current.currentTime = 0;
  //       setIsPlaying(false);
  //     }
  //   };

  //   const handleMuteUnmute = () => {
  //     if (audioRef.current) {
  //       audioRef.current.muted = !audioRef.current.muted;
  //       setIsMuted(audioRef.current.muted);
  //     }
  //   };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden",
        backgroundImage: `url("/images/audio.png")`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "repeat",
        backgroundBlendMode: "color-burn",
        padding: "20px",
        width: "125px",
        height: "100px",
        objectFit: "cover",
        borderRadius: "8px",
        border: "1px solid #cdcdcd",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      }}
    >
      <audio ref={audioRef} src={mediaUrl} style={{ display: "none" }} />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "4px",
        }}
      >
        {/* <IconButton onClick={handleStop} sx={{ color: bgColors?.green1 }}>
          <StopIcon />
        </IconButton> */}
        <IconButton onClick={handlePlayPause}>
          {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
        </IconButton>
        {/* <IconButton onClick={handleMuteUnmute} sx={{ color: bgColors?.green1 }}>
          {isMuted ? <VolumeOffIcon /> : <VolumeUpIcon />}
        </IconButton> */}
      </Box>
    </Box>
  );
};

export default CustomAudioPlayer;
