import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Typography,
  Box,
  Chip,
  IconButton,
  Button,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import AddIcon from "@mui/icons-material/Add";
import AddWalletBalancePopup from "./PaymentPopover";
import { toastActions } from "../../utils/toastSlice";
import { useAppDispatch, useAppSelector } from "../../utils/redux-hooks";
import {
  getExpectedWalletDetails,
  getPaymentDetails,
  getWalletDetails,
} from "../../redux/slices/Wallet/WalletSlice";
import axios from "axios";
import { fetchCreateOrder } from "../../redux/slices/Subscription/SubscriptionSlice";

function capitalizeFirstLetter(string: any) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const USER_API_URL = process.env.REACT_APP_BASE_URL;

const TransactionHistory = ({ open, onClose, transactions }: any) => {
  // console.log(transactions)
  const dispatch = useAppDispatch();
  // const transactions: any = [];
  const getuserPermissionData = useAppSelector(
    (state: any) => state.getUserPermissions?.data
  );

  const getCreateOrderStatus = useAppSelector(
    (state:any) => state?.Subscription?.createOrderStatus
  );
  // console.log(getCreateOrderStatus);

  const loginData = useAppSelector((state: any) => state.adminLogin.data);
  const companyId = loginData?.companyId;
  const accountDetails = useAppSelector((state:any) => state.accountData);
  // const userInfoSlice = useAppSelector((state) => state.adminLogin);
  // const userInfo = userInfoSlice?.data;
  const isVerifiedWithMeta = accountDetails?.data?.companyVerificationStatus;
  // console.log(isVerifiedWithMeta, "isVerifiedWithMeta");

  const hasAddBalancePermissions = (permissions: any) => {
    for (const addBalanceItem of permissions) {
      if (addBalanceItem.hasOwnProperty("addBalance")) {
        return true;
      }
    }
  };

  const getAddBalancePermissions = hasAddBalancePermissions(
    getuserPermissionData?.wallet || ""
  );

  const [opened, setOpen] = useState(false);
  const [addBalanceTooltip, setAddBalanceTooltip] = useState(false);
  const [isMetaVerifiedToolTip, setIsMetaVerifiedToolTip] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const loadScript = (src: string) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => resolve(true);
      script.onerror = () => resolve(false);
      document.body.appendChild(script);
    });
  };

  const handleUpgrade = async (amount: any) => {
    // console.log(amount)
    if (getAddBalancePermissions) {
      let planId = amount;
      let currency = 1;
      const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );

      if (!res) {
        alert("Razorpay SDK failed to load. Are you online?");
        return;
      }

      const amountInPaise = parseFloat(planId.toString()); // Convert to paise
      // const amountInPaise = parseFloat(planId.toString()).toFixed(1); // Convert to paise
      // const amountInPaise = parseFloat(planId); // Convert to paise and ensure it's a number
      // const formattedAmount = (amountInPaise / 100).toFixed(1); // Format to 70.00
      // const amountInPaise = parseFloat(planId.toString()); // Convert to paise
      const data = {
        // "orderAmount": 70.0,
        amount: amountInPaise,
        currency: currency,
      };

      // const rol = await axios.post(
      //     `${USER_API_URL}/PaymentGateway/CreateOrder`,
      //     data,
      //     {
      //         headers: {
      //             "Content-Type": "application/json",
      //             Authorization: localStorage.getItem("token"),
      //         },
      //     }
      // );
      // console.log(rol, "rol");

      const response = await dispatch(fetchCreateOrder(data));
      const rol = response.payload;
      // console.log(rol, "rol");
      if (rol?.message === "Create Order Successfully") {
        setOpen(false);
        onClose();
      }

      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY_ID,
        amount: amountInPaise,
        currency: "INR",
        order_id: rol?.data?.orderId,
        handler: async function (response: any) {
          const data = {
            razorpayPaymentId: response.razorpay_payment_id,
            razorpayOrderId: response.razorpay_order_id,
            razorpaySignature: response.razorpay_signature,
          };
          // console.log(data, "datas");

          // const result = await axios.post("https://user.engageto.com/api/PaymentsDetails/wallet/verify-payment", data, {
          //   headers: {
          //     'Content-Type': 'application/json',
          //     Authorization: localStorage.getItem("token"),
          //   }
          // });
          // console.log(result, 'result')
          // if (result?.data?.message === "Wallet balance updated successfully.") {
          //   dispatch(toastActions.setToaster({
          //     type: "success",
          //     message: `${result?.data?.message}`,
          //   }));
          //   // dispatch(fetchCurrentPlanDetails(userId));
          //   // dispatch(getPaymentStatus({ paymentId: data?.razorpayPaymentId, orderId: data?.razorpayOrderId }))
          // }
          const PaymentResponse = await dispatch(
            getPaymentDetails({
              paymentId: data?.razorpayPaymentId,
              orderId: data?.razorpayOrderId,
            })
          );
          const res = PaymentResponse?.payload;
          // console.log(res);
          setOpen(false);
          onClose();
          if (
            res?.message ===
            "Amount has been successfully added to your wallet."
          ) {
            // const { startDate, endDate } = getLast30DaysDates();
            dispatch(getWalletDetails());
            dispatch(getExpectedWalletDetails(companyId));
            dispatch(
              toastActions.setToaster({
                type: "success",
                message: `${res?.message}`,
              })
            );
            // setOpen(false)
            // onClose()

            // dispatch(walletTransactions({ startDate: startDate, endDate: endDate }));
            // history("/payment");
          }
          // history("/payment");
        },
        theme: {
          color: "#61dafb",
        },
      };

      //@ts-ignore
      const paymentObject = new window.Razorpay(options);

      paymentObject.on("payment.failed", function (response: any) {
        // console.log(response, "response");
        dispatch(
          toastActions.setToaster({
            type: "error",
            message: `${response.error.reason}`,
          })
        );
        // axios.post("https://user.engageto.com/api/PaymentsDetails/payment-failed", {
        //   code: response.error.code,
        //   description: response.error.description,
        //   source: response.error.source,
        //   step: response.error.step,
        //   reason: response.error.reason,
        //   order_id: response.error.metadata.order_id,
        //   payment_id: response.error.metadata.payment_id
        // }).then(result => {
        //   console.log(result);
        //   dispatch(toastActions.setToaster({
        //     type: "error",
        //     message: `Payment failed: ${response.error.reason}`,
        //   }));
        // });
      });

      paymentObject.on("payment.pending", function (pending: any) {
        // console.log(pending, "pending");
        dispatch(
          toastActions.setToaster({
            type: "error",
            message: `${pending.error.reason}`,
          })
        );
        // axios.post("https://user.engageto.com/api/PaymentsDetails/payment-failed", {
        //   code: response.error.code,
        //   description: response.error.description,
        //   source: response.error.source,
        //   step: response.error.step,
        //   reason: response.error.reason,
        //   order_id: response.error.metadata.order_id,
        //   payment_id: response.error.metadata.payment_id
        // }).then(result => {
        //   console.log(result);
        //   dispatch(toastActions.setToaster({
        //     type: "error",
        //     message: `Payment failed: ${response.error.reason}`,
        //   }));
        // });
      });
      paymentObject.open();
    } else {
      setAddBalanceTooltip(true);
      setTimeout(() => {
        setAddBalanceTooltip(false);
      }, 2000);
    }
  };

  const getTransactionIcon = (paymentMethod: any) => {
    switch (paymentMethod) {
      case "wallet":
        return <AccountBalanceWalletIcon />;
      case "upi":
        return <CreditCardIcon />;
      default:
        return <CreditCardIcon />;
    }
  };

  const getStatusColor = (status: any) => {
    switch (status) {
      case "paid":
        return "success";
      case "pending":
        return "warning";
      case "failed":
        return "error";
      default:
        return "default";
    }
  };

  const formatDate = (timestamp: any) => {
    return new Date(timestamp * 1000).toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };

  const handleAddBalance = () => {
    if (isVerifiedWithMeta) {
      setOpen(true);
    } else {
      setIsMetaVerifiedToolTip(true);
      setTimeout(() => {
        setIsMetaVerifiedToolTip(false);
      }, 4000);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h6">Transaction History</Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        {transactions?.length > 0 ? (
          <List>
            {transactions?.map((transaction: any) => (
              <ListItem
                key={transaction.transactionid}
                sx={{
                  mb: 2,
                  bgcolor: "background.paper",
                  borderRadius: 2,
                  boxShadow: 1,
                }}
              >
                <ListItemIcon>
                  {getTransactionIcon(transaction.paymentMethod)}
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography variant="subtitle1">
                        {transaction?.paymentMethod
                          ? transaction.paymentMethod.charAt(0).toUpperCase() +
                            transaction.paymentMethod.slice(1)
                          : "Unknown Method"}
                      </Typography>
                      <Typography variant="subtitle1" fontWeight="bold">
                        {transaction.amount < 0 ? (
                          <ArrowDownwardIcon color="error" fontSize="small" />
                        ) : (
                          <ArrowUpwardIcon color="success" fontSize="small" />
                        )}
                        ₹{Math.abs(transaction.amount)}
                      </Typography>
                    </Box>
                  }
                  secondary={
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography variant="body2" color="text.secondary">
                        {formatDate(transaction?.transactionDate)}
                      </Typography>
                      <Chip
                        label={capitalizeFirstLetter(transaction?.status)}
                        size="small"
                        color={getStatusColor(transaction.status)}
                      />
                    </Box>
                  }
                />
              </ListItem>
            ))}
          </List>
        ) : (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            minHeight="300px"
            textAlign="center"
          >
            <AccountBalanceWalletIcon
              sx={{ fontSize: 80, color: "text.secondary", mb: 2 }}
            />
            <Typography variant="h6" gutterBottom>
              No Transactions Yet
            </Typography>
            <Typography variant="body1" color="text.secondary" mb={3}>
              Your wallet is looking a bit empty. Time to add some funds!
            </Typography>
            <Tooltip
              title="Your account has not been verified with meta.
                            Please link with meta to add wallet amount."
              placement="bottom"
              open={isMetaVerifiedToolTip}
              onClose={() => setIsMetaVerifiedToolTip(false)}
            >
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                // onClick={() => {/* Handle add funds action */ }}
                // onClick={() => {
                //   setOpen(true);
                // }}
                onClick={() => handleAddBalance()}
              >
                Add Funds
              </Button>
            </Tooltip>
            <AddWalletBalancePopup
              open={opened}
              addBalanceTooltip={addBalanceTooltip}
              handleClose={handleClose}
              handleUpgrade={handleUpgrade}
            />
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default TransactionHistory;
