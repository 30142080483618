import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  Typography,
  InputLabel,
  Grid,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import CloseSvg from "../../assets/svgs/CloseSvg";
import { makeStyles } from "@mui/styles";
import TextFeildWithBorderComponet from "../common/TextFieldWithBorderComponent";
import PhoneInput from "../common/PhoneInput";
import { bgColors } from "../../utils/bgColors";
import { useAppDispatch, useAppSelector } from "../../utils/redux-hooks";
import { createContactTags } from "../../redux/slices/Contacts/CreateContactTags";
import { toastActions } from "../../utils/toastSlice";
import { getContactTags } from "../../redux/slices/Contacts/getContactTags";
import {
  fetchAllRoles,
  postCreatedRoles,
} from "../../redux/slices/ManageCompany/DisplayAllRoles";
import LoadingComponent from "../common/LoadingComponent";

const useStyles = makeStyles({
  changePasswordContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  blackColor: {
    color: "#303030 !important",
    fontWeight: "600 !important",
  },
  signOutButtonStyles: {
    backgroundColor: "#3C3C3C",
    color: "#ffffff",
    height: "40px",
    // width: "30%",
    borderRadius: "8px",
    fontSize: "14px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // paddingLeft: "35px",
    // paddingRight: "35px",
    paddingInline: "23px ",
    fontWeight: "600",
    cursor: "pointer",
  },
  cancelButtonStyles: {
    backgroundColor: "#ffffff",
    color: "#000000",
    height: "40px",
    // width: "30%",
    borderRadius: "8px",
    // paddingLeft: "35px",
    // paddingRight: "35px",
    paddingInline: "23px ",
    border: `1px solid ${bgColors.gray3}`,
    fontSize: "14px",
    fontWeight: "600",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
});

const NewRoleMember = ({ open, handleClose, companyId }: any) => {
  // console.log(open,'openRole');
  // console.log(handleClose,'handleCloseRole');

  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [isNewRoleMemberLoading, setIsNewRoleMemberLoading] = useState(false);

  const userProfileSlice = useAppSelector((state:any) => state?.adminLogin);
  const userData = userProfileSlice?.data;

  const [tagsData, setTagsData] = useState("");
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState("");

  const handleChange = (e: any) => {
    setTagsData(e.target.value);
    setError(false);
    setHelperText("");
  };

  const handleCloseAll = () => {
    handleClose();
    setTagsData("");
    setError(false);
    setHelperText("");
  };

  const handleSave = async () => {
    if (tagsData?.trim().length) {
      // console.log(tagsData?.length, 'tagsData?.length')
      const bodyData = {
        name: tagsData,
        companyId: companyId ? companyId : userData?.companyId,
      };
      setIsNewRoleMemberLoading(true);
      try {
        const response: any = await dispatch(postCreatedRoles(bodyData));
        // console.log("tagres", response);

        if (response?.payload?.message === "Role added successfully.") {
          dispatch(
            toastActions.setToaster({
              type: "success",
              message: response?.payload?.message,
            })
          );
          await dispatch(
            fetchAllRoles(companyId ? companyId : userData?.companyId)
          );
          handleClose();
          setTagsData("");
        } else {
          dispatch(
            toastActions.setToaster({
              type: "error",
              message: response?.payload,
            })
          );
        }
      } catch (error: any) {
        // console.log(error);
        dispatch(
          toastActions.setToaster({
            type: "error",
            message: `Error creating Role`,
          })
        );
      }
      setIsNewRoleMemberLoading(false);
    } else {
      setError(true);
      setHelperText("Role name is required");
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleCloseAll}
      PaperProps={{ sx: { minWidth: "20%", borderRadius: "20px" } }}
    >
      <DialogTitle>
        <Box mx={1} mb={0} className={classes.changePasswordContainer}>
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            Create Role
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box mx={0} mt={0}>
          <Box>
            <InputLabel className={classes.blackColor}>Role Name</InputLabel>
            <TextFeildWithBorderComponet
              label="Role name"
              name="text"
              placeholder=""
              value={tagsData}
              error={error}
              helperText={helperText}
              onChange={handleChange}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box
          m={{ xs: 1, md: 2 }}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          width="100%"
        >
          {isNewRoleMemberLoading ? (
            <Box
              sx={{ display: "flex", width: "100%", justifyContent: "center" }}
            >
              <LoadingComponent height="auto" color={bgColors?.blue} />
            </Box>
          ) : (
            <>
              <button
                className={classes.cancelButtonStyles}
                onClick={handleCloseAll}
              >
                Cancel
              </button>
              <button
                className={classes.signOutButtonStyles}
                onClick={handleSave}
              >
                Create
              </button>
            </>
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default NewRoleMember;
