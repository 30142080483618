/* global process */

import {
  Box,
  Grid,
  Tooltip,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  Button,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { bgColors } from "../../utils/bgColors";
// import { useNavigate } from "react-router-dom";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import BalanceIconSvg from "../../assets/svgs/BalanceIconSvg";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { useAppDispatch, useAppSelector } from "../../utils/redux-hooks";
import { toastActions } from "../../utils/toastSlice";
import {
  billingDetails,
  getExpectedWalletDetails,
  getPaymentDetails,
  getWalletDetails,
  walletInvoice,
  walletTransactions,
} from "../../redux/slices/Wallet/WalletSlice";
import LoadingComponent from "../../components/common/LoadingComponent";
import { format, parseISO } from "date-fns";
import {
  fetchCreateOrder,
  fetchSubscriptionDeductions,
  subscriptionInvoice,
} from "../../redux/slices/Subscription/SubscriptionSlice";
import AddWalletBalancePopup from "../../components/WalletComponents/PaymentPopover";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import AddIcon from "@mui/icons-material/Add";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import DateRangePicker from "../../components/WalletComponents/DatePickerComponent";

import PaymentsIcon from "@mui/icons-material/Payments";
import TransactionHistory from "../../components/WalletComponents/WalletTransactionsPopup";

const useStyles = makeStyles(() => ({
  mainContainer: {
    backgroundColor: bgColors.white1,
    padding: "14px",
    // height: "100%",
    width: "100%",
  },
  bgContainer: {
    backgroundColor: bgColors.white,
    borderRadius: "25px",
    // padding: "20px",
  },
  manageContainer: {
    marginBottom: "20px",
    marginTop: "5px",
    width: "100%",
  },
  planButton: {
    backgroundColor: bgColors.green1,
    color: bgColors.green,
    fontWeight: "700",
    padding: "6px 22px",
    borderRadius: 25,
  },
  blackColor: {
    // color: `${bgColors.black1} !important`,
    // fontWeight: "600 !important",
  },
  grayColor: {
    fontWeight: "600 !important",
    opacity: "60%",
  },
  updateButtonStyles: {
    color: bgColors.green,
    border: `1px solid ${bgColors.green}`,
    height: "32px",
    borderRadius: "8px",
    fontWeight: "20px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  makePayment: {
    color: "#3C3C3C !important",
    fontWeight: "600 !important",
    border: `2px solid ${bgColors.black1}`,
    padding: "10px 40px",
    borderRadius: "14px",
    background: "#3C3C3C1A",
  },
  fileContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    borderBottom: "1px solid #838383",
  },
  fileNameContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  popoverContent: {
    padding: "18px",
    display: "flex",
    flexDirection: "column",
  },
  subscriptionContainer: {
    border: `2px solid ${bgColors.gray5}`,
    borderRadius: "18px",
    padding: "10px",
  },
  justifySpaceBetween: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "100px",
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
  },
  RsStyles: {
    fontSize: "16px",
    fontWeight: "500",
    fontFamily: "inter",
    color: "gray",
  },
  planAmout: {
    fontSize: "33px",
    margin: 0,
  },
  alignCenter: {
    display: "flex",
    alignItems: "center",
  },
  tabsContainer: {
    width: "100%",
  },
  account: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "5px",
  },
  switch: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: "18px !important",
    color: `${bgColors.black1} !important`,
  },
  lineStyles: {
    border: "none",
    borderTop: `1px solid ${bgColors.gray2}`,
    width: "100%",
    margin: "0 auto",
  },
  table: {
    borderCollapse: "separate",
    borderSpacing: "0 8px",
    borderColor: "lightgray",
    "& th, & td": {
      padding: "3px",
      borderBottom: "1px solid #f0f0f0",
    },
    "& th:last-child, & td:last-child": {},
  },
  teamProfileContainer: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  threedots: {
    border: "2px solid #F2F2F2",
    padding: "10px",
    borderRadius: "12px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  editButtonContainer: {
    border: "2px solid #DBDBDB",
    padding: "8px",
    borderRadius: "12px",
    backgroundColor: "#F4F4F4",
    width: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
}));

declare global {
  interface Window {
    Razorpay: any;
  }
}

const capitalizeFirstLetter = (string: any) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const Wallet = () => {
  const classes = useStyles();
  // const history = useNavigate();
  const dispatch = useAppDispatch();

  // const getCurrentDate = () => {
  //   const today = new Date();
  //   const year = today.getFullYear();
  //   const month = String(today.getMonth() + 1).padStart(2, "0"); // January is 0!
  //   const day = String(today.getDate()).padStart(2, "0");
  //   return `${year}-${month}-${day}`;
  // };

  const loginData = useAppSelector((state: any) => state.adminLogin.data);
  const isPlatform = loginData?.isPlatform;
  const companyId = loginData?.companyId;

  const accountDetails = useAppSelector((state:any) => state.accountData);
  const isVerifiedWithMeta = accountDetails?.data?.companyVerificationStatus;

  const transactionData = useAppSelector(
    (state:any) => state?.wallet?.transactionData?.data?.transactions
  );

  const expectedAmountDetails = useAppSelector(
    (state:any) => state?.wallet?.expectedAmount?.data
  );
  const expectedWalletstatus = useAppSelector(
    (state:any) => state?.wallet?.expectedWalletstatus
  );

  const expectedAmount = expectedAmountDetails
    ? expectedAmountDetails?.expectedWalletBalance
    : 0;
  const totalAmount = expectedAmountDetails
    ? expectedAmountDetails?.walletBalance
    : 0;
  const walletTransactionsHistory = useAppSelector(
    (state:any) => state?.wallet?.transactionData?.data?.transactionsHistory
  );
  const subscriptionData = useAppSelector(
    (state:any) => state?.Subscription?.subscriptionDeductions?.data
  );
  const transactionDataStatus = useAppSelector(
    (state:any) => state?.wallet?.transactionDataStatus
  );
  const subscriptionDataStatus = useAppSelector(
    (state:any) => state?.Subscription?.subscriptionDeductionsStatus
  );
  const paymentStatus = useAppSelector((state:any) => state?.wallet?.isStatus);
  const getuserPermissionData = useAppSelector(
    (state:any) => state.getUserPermissions?.data
  );
  const walletPermissionsArray = getuserPermissionData?.wallet;
  const walletTransactionsPermissionsObject = walletPermissionsArray?.find(
    (item: any) =>
      Object.prototype.hasOwnProperty.call(item, "walletTransactions")
  );

  const subscriptionTransactionsPermissionsObject =
    walletPermissionsArray?.find((item: any) =>
      Object.prototype.hasOwnProperty.call(item, "subscriptionDeductions")
    );

  const walletTransactionsPermissoinsActions =
    walletTransactionsPermissionsObject
      ? walletTransactionsPermissionsObject.walletTransactions
      : [];

  const subscriptionTransactionsPermissoinsActions =
    subscriptionTransactionsPermissionsObject
      ? subscriptionTransactionsPermissionsObject.subscriptionDeductions
      : [];

  const [walletInvoiceTooltipOpen, setWalletInvoiceTooltipOpen] = useState("");
  const [subscriptionInvoiceTooltipOpen, setSubscriptionInvoiceTooltipOpen] =
    useState("");
  const [subscriptionTooltipMessage, setSubscriptionTooltipMessage] =
    useState("");
  const [addBalanceTooltip, setAddBalanceTooltip] = useState(false);
  const [isMetaVerifiedToolTip, setIsMetaVerifiedToolTip] = useState(false);
  const [tooltipView, setTooltipView] = useState("");
  const [open, setOpen] = useState(false);
  const [amountClear, setAmountClear] = useState(false);
  // const [formData, setFormData] = useState<any>({
  //   selectedDate: getCurrentDate(),
  // });
  const [value, setValue] = React.useState("1");
  const [hoveredRow, setHoveredRow] = useState(null);
  const [startCalenderDate, setStartCalenderDate] = useState<any>(null);
  const [endCalenderDate, setEndCalenderDate] = useState<any>(null);
  const [isDateRangeApplied, setIsDateRangeApplied] = useState(false);
  const [transactionHistoryOpen, setTransactionHistoryOpen] = useState(false);

  // const handleDateChange = (event: any) => {
  //   const newDate = event.target.value;

  //   setFormData({ ...formData, selectedDate: newDate });
  // };

  const theme = createTheme({
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            paddingLeft: 0,
          },
        },
      },
      // MuiTabPanel: {
      //   styleOverrides: {
      //     root: {
      //       paddingLeft: 0,
      //     },
      //   },
      // },
    },
  });

  const handleClose = () => {
    setOpen(false);
  };

  const hasAccessToDownloadWalletTransactionInvoice = (permission: any) => {
    if (walletTransactionsPermissoinsActions?.includes(permission)) {
      return true;
    }
    return false;
  };

  const hasAccessToDownloadSubscriptionTransactionInvoice = (
    permission: any
  ) => {
    if (subscriptionTransactionsPermissoinsActions?.includes(permission)) {
      return true;
    }
    return false;
  };

  const hasWalletPermissions = (permissions: any) => {
    for (const walletItem of permissions) {
      if (
        Object.prototype.hasOwnProperty.call(walletItem, "walletTransactions")
      ) {
        return true;
      }
    }
    return false;
  };

  const hasSubscriptionPermissions = (permissions: any) => {
    for (const subscriptionItem of permissions) {
      if (
        Object.prototype.hasOwnProperty.call(
          subscriptionItem,
          "subscriptionDeductions"
        )
      ) {
        return true;
      }
    }
    return false;
  };

  const hasAddBalancePermissions = (permissions: any) => {
    for (const addBalanceItem of permissions) {
      if (Object.prototype.hasOwnProperty.call(addBalanceItem, "addBalance")) {
        return true;
      }
    }
    return false;
  };

  const getWalletPermissions = hasWalletPermissions(
    getuserPermissionData?.wallet || ""
  );
  const getSubscriptionPermissions = hasSubscriptionPermissions(
    getuserPermissionData?.wallet || ""
  );
  const getAddBalancePermissions = hasAddBalancePermissions(
    getuserPermissionData?.wallet || ""
  );
  const loadScript = (src: string) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => resolve(true);
      script.onerror = () => resolve(false);
      document.body.appendChild(script);
    });
  };

  // const secretKey = process.env.REACT_APP_RAZORPAY_KEY_ID;

  const handleUpgrade = async (amount: any) => {
    if (getAddBalancePermissions) {
      let planId = amount;
      let currency = 1;
      const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );

      if (!res) {
        alert("Razorpay SDK failed to load. Are you online?");
        return;
      }

      const amountInPaise = parseFloat(planId.toString()); // Convert to paise
      // const amountInPaise = parseFloat(planId.toString()).toFixed(1); // Convert to paise
      // const amountInPaise = parseFloat(planId); // Convert to paise and ensure it's a number
      // const formattedAmount = (amountInPaise / 100).toFixed(1); // Format to 70.00
      // const amountInPaise = parseFloat(planId.toString()); // Convert to paise
      const data = {
        amount: amountInPaise,
        currency: currency,
      };
      const response: any = await dispatch(fetchCreateOrder(data));
      const rol = response.payload;
      if (rol?.message === "Create Order Successfully") {
        setAmountClear(true);
        setOpen(false);
      }
      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY_ID,
        amount: amountInPaise,
        currency: "INR",
        order_id: rol?.data?.orderId,
        handler: async function (response: any) {
          const data = {
            razorpayPaymentId: response.razorpay_payment_id,
            razorpayOrderId: response.razorpay_order_id,
            razorpaySignature: response.razorpay_signature,
          };
          const PaymentResponse = await dispatch(
            getPaymentDetails({
              paymentId: data?.razorpayPaymentId,
              orderId: data?.razorpayOrderId,
            })
          );
          const res = PaymentResponse?.payload;
          if (
            res?.message ===
            "Amount has been successfully added to your wallet."
          ) {
            dispatch(getExpectedWalletDetails(companyId));
            dispatch(getWalletDetails());
            const { startDate, endDate } = getLast30DaysDates();
            dispatch(
              toastActions.setToaster({
                type: "success",
                message: `${res?.message}`,
              })
            );
            dispatch(
              walletTransactions({ startDate: startDate, endDate: endDate })
            );
          }
        },
        theme: {
          color: "#61dafb",
        },
      };

      const paymentObject = new window.Razorpay(options);

      paymentObject.on("payment.failed", function (response: any) {
        dispatch(
          toastActions.setToaster({
            type: "error",
            message: `${response.error.reason}`,
          })
        );
        // axios.post("https://user.engageto.com/api/PaymentsDetails/payment-failed", {
        //   code: response.error.code,
        //   description: response.error.description,
        //   source: response.error.source,
        //   step: response.error.step,
        //   reason: response.error.reason,
        //   order_id: response.error.metadata.order_id,
        //   payment_id: response.error.metadata.payment_id
        // }).then(result => {
        //   console.log(result);
        //   dispatch(toastActions.setToaster({
        //     type: "error",
        //     message: `Payment failed: ${response.error.reason}`,
        //   }));
        // });
      });

      paymentObject.on("payment.pending", function (pending: any) {
        dispatch(
          toastActions.setToaster({
            type: "error",
            message: `${pending.error.reason}`,
          })
        );
      });
      paymentObject.open();
    } else {
      setAddBalanceTooltip(true);
      setTimeout(() => {
        setAddBalanceTooltip(false);
      }, 2000);
    }
  };

  useEffect(() => {
    if (getSubscriptionPermissions && getWalletPermissions) {
      setValue("1");
    } else if (getWalletPermissions) {
      setValue("1");
    } else if (getSubscriptionPermissions) {
      setValue("2");
    }
  }, [getSubscriptionPermissions, getWalletPermissions]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const handlePopoverOpen = (event: any, date: any) => {
    const hasPermission = hasAccessToDownloadWalletTransactionInvoice(
      "downloadWalletTransactionInvoice"
    );
    if (hasPermission) {
      if (event) {
        downloadInvoice(event, date);
      }
    } else {
      setWalletInvoiceTooltipOpen(event);
      setTimeout(() => {
        setWalletInvoiceTooltipOpen("");
      }, 2000);
    }
  };

  const SubscriptionPopoverOpen = (orderAmount: any, event: any, date: any) => {
    const hasPermission = hasAccessToDownloadSubscriptionTransactionInvoice(
      "downloadSubscriptionDeductionInvoice"
    );
    if (hasPermission) {
      if (event && orderAmount !== 0) {
        downloadSubscriptionInvoice(event, date);
      } else {
        setSubscriptionTooltipMessage("Not allowed for 0 amount");
        setSubscriptionInvoiceTooltipOpen(event);
      }
    } else {
      setSubscriptionInvoiceTooltipOpen(event);
      setTimeout(() => {
        setSubscriptionInvoiceTooltipOpen("");
      }, 2000);
    }
  };
  const formatDate = (dateString: any) => {
    const date = parseISO(dateString);
    return {
      date: format(date, "dd MMM yyyy"),
      time: format(date, "hh : mm a").replace("AM", "am").replace("PM", "pm"),
    };
  };

  // Function to get the start and end dates for the last 30 days
  const getLast30DaysDates = () => {
    const endDate = new Date(); // Today's date
    const startDate = new Date();
    startDate.setDate(startDate.getDate() - 30); // Subtract 30 days from today

    const formatDate = (date: any) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    };

    return {
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
    };
  };

  React.useEffect(() => {
    const { startDate, endDate } = getLast30DaysDates();
    dispatch(getWalletDetails());
    dispatch(walletTransactions({ startDate: startDate, endDate: endDate }));
    dispatch(
      fetchSubscriptionDeductions()
      // { startDate: startDate, endDate: endDate }
    );
    dispatch(billingDetails(companyId));
    dispatch(getExpectedWalletDetails(companyId));
  }, [dispatch, loginData]);
  const [isHovered, setIsHovered] = useState(false);

  const downloadInvoice = async (id: string, date: any) => {
    // Decode the base64 string
    const response = await dispatch(walletInvoice(id));
    const data = response.payload;
    if (data) {
      const byteCharacters = atob(data?.data);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      // Create a Blob from the byte array
      const blob = new Blob([byteArray], { type: "application/pdf" });
      // Create a URL for the Blob
      const url = URL.createObjectURL(blob);
      // Create a link element to trigger the download
      const link = document.createElement("a");
      link.href = url;
      link.download = `Invoice ${date}`;
      document.body.appendChild(link);
      link.click();
      // Clean up and remove the link

      // link.parentNode.removeChild(link);
      if (link.parentNode) {
        link.parentNode.removeChild(link);
      } else {
        console.warn("Link element has no parent node, unable to remove it.");
      }
      URL.revokeObjectURL(url);

      dispatch(
        toastActions.setToaster({
          type: "success",
          message: "Successfully downloaded",
        })
      );
    } else {
      dispatch(
        toastActions.setToaster({
          type: "error",
          message: "Unable to download",
        })
      );
    }
  };
  const downloadSubscriptionInvoice = async (id: string, date: any) => {
    // Decode the base64 string
    const response = await dispatch(subscriptionInvoice(id));
    const data = response.payload;
    if (data) {
      const byteCharacters = atob(data?.data?.fileContent);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      // Create a Blob from the byte array
      const blob = new Blob([byteArray], { type: "application/pdf" });
      // Create a URL for the Blob
      const url = URL.createObjectURL(blob);
      // Create a link element to trigger the download
      const link = document.createElement("a");
      link.href = url;
      link.download = `Invoice ${date}`;
      document.body.appendChild(link);
      link.click();
      // Clean up and remove the link

      // link.parentNode.removeChild(link);
      if (link.parentNode) {
        link.parentNode.removeChild(link);
      } else {
        console.warn("Link element has no parent node, unable to remove it.");
      }
      URL.revokeObjectURL(url);

      dispatch(
        toastActions.setToaster({
          type: "success",
          message: "Successfully downloaded",
        })
      );
    } else {
      dispatch(
        toastActions.setToaster({
          type: "error",
          message: "Unable to download",
        })
      );
    }
  };

  const handleDateRangeChange = ({ startDate, endDate }: any) => {
    setStartCalenderDate(startDate);
    setEndCalenderDate(endDate);
    setIsDateRangeApplied(false);
  };

  const handleApplyDateRange = () => {
    if (isDateRangeApplied) {
      const { startDate, endDate } = getLast30DaysDates();

      // Reset the date range

      setStartCalenderDate(null);
      setEndCalenderDate(null);
      setIsDateRangeApplied(false);

      dispatch(walletTransactions({ startDate: startDate, endDate: endDate }));
    } else {
      // Apply the date range
      setIsDateRangeApplied(true);

      dispatch(
        walletTransactions({
          startDate: startCalenderDate,
          endDate: endCalenderDate,
        })
      );
      // Call the API to fetch data within the selected date range
    }
  };

  const handleRowHover = (rowId: any) => {
    setHoveredRow(rowId);
  };
  const handleAddBalance = () => {
    if (isVerifiedWithMeta) {
      setOpen(true);
    } else {
      setIsMetaVerifiedToolTip(true);
      setTimeout(() => {
        setIsMetaVerifiedToolTip(false);
      }, 4000);
    }
  };
  return (
    <ThemeProvider theme={theme}>
      <Grid className={classes.mainContainer}>
        <Grid className={classes.bgContainer}>
          <Box className={classes.manageContainer} px={2} pt={2} ml={-4}>
            <Typography
              mt={1}
              ml={4}
              variant="h6"
              className={classes.blackColor}
              sx={{ display: "flex" }}
            >
              <span style={{ marginTop: "5px" }}>
                <PaymentsIcon sx={{ height: "22px" }} />
              </span>
              &nbsp;Wallet
            </Typography>
          </Box>
          {transactionDataStatus !== "loading" ||
          expectedWalletstatus !== "loading" ? (
            <>
              <Box sx={{ flexGrow: 1 }} px={2}>
                <Grid container spacing={2} sx={{ alignItems: "stretch" }}>
                  {/* Left side - Wallet Balance */}
                  <Grid item xs={12} md={12} sx={{ display: "flex" }}>
                    <Box
                      sx={{
                        padding: 2,
                        // paddingTop: "5px",
                        // paddingLeft: "10px",
                        // paddingRight: "10px",
                        borderRadius: 2,
                        border: "1px solid #e0e0e0",
                        flex: 1,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      {paymentStatus === "loading" ? (
                        <LoadingComponent height="auto" color="#1976d2" />
                      ) : (
                        <Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-evenly",
                                gap: "50px",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "space-evenly",
                                }}
                              >
                                <Typography
                                  variant="body2"
                                  // sx={{ color: "#757575" }}
                                  className={classes.grayColor}
                                >
                                  Available Balance
                                </Typography>
                                <Typography
                                  sx={{
                                    fontSize: {
                                      xs: "1.5rem",
                                      sm: "2rem",
                                      md: "1.5rem",
                                    },

                                    mt: 1.5,
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                  }}
                                >
                                  <span className={classes.RsStyles}>Rs </span>
                                  {totalAmount?.toLocaleString()}
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "space-evenly",
                                }}
                              >
                                <Typography
                                  variant="body2"
                                  // sx={{ color: "#757575" }}
                                  className={classes.grayColor}
                                >
                                  Expected Balance
                                </Typography>
                                <Typography
                                  sx={{
                                    fontSize: {
                                      xs: "1.5rem",
                                      sm: "2rem",
                                      md: "1.5rem",
                                    },

                                    mt: 1.5,
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                  }}
                                >
                                  <span className={classes.RsStyles}>Rs </span>
                                  {expectedAmount?.toLocaleString()}
                                </Typography>
                              </Box>
                            </Box>

                            <Box
                              ml={4}
                              mr={4}
                              // style={{ textAlign: "center" }}
                            >
                              <Tooltip
                                title="Your account has not been verified with meta.
                            Please link with meta to add wallet amount."
                                placement="top"
                                open={
                                  isMetaVerifiedToolTip && tooltipView === "add"
                                }
                                onClose={() => setIsMetaVerifiedToolTip(false)}
                              >
                                <button
                                  className={classes.updateButtonStyles}
                                  // style={{
                                  //   backgroundColor: `${bgColors.black} !important`,
                                  //   width: "50% !important",
                                  //   fontSize: "14px !important",
                                  //   fontWeight: "Semi Bold !important",
                                  //   borderRadius: "8px !important",
                                  // }}

                                  onClick={() => {
                                    handleAddBalance();
                                    setTooltipView("add");
                                  }}
                                  onMouseEnter={() => setIsHovered(true)}
                                  onMouseLeave={() => setIsHovered(false)}
                                  style={{
                                    backgroundColor: isHovered
                                      ? "rgba(68, 71, 70, 0.08)"
                                      : "#fff",
                                    cursor: isHovered ? "pointer" : "default", // Optional: change cursor style on hover
                                  }}
                                >
                                  <BalanceIconSvg
                                    style={{
                                      fill: bgColors.green,
                                    }}
                                  />{" "}
                                  Add Balance
                                </button>
                              </Tooltip>
                              <AddWalletBalancePopup
                                open={open}
                                amountClear={amountClear}
                                addBalanceTooltip={addBalanceTooltip}
                                handleClose={handleClose}
                                handleUpgrade={handleUpgrade}
                              />
                            </Box>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </Grid>
                  <Typography
                    variant="body2"
                    sx={{ color: "#757575", mt: 1, ml: 2 }}
                  >
                    <Box component="span" sx={{ color: "red" }}>
                      Note:
                    </Box>
                    The expected balance shows pending amount at the current
                    time. The available balance may update after all
                    transactions have been fully processed.
                  </Typography>
                  {/* Right side - Low Balance Message */}
                  <Grid item xs={12} md={12} sx={{ display: "flex" }}>
                    {totalAmount >= 1000 &&
                    expectedWalletstatus !== "loading" ? (
                      <Box
                        sx={{
                          flex: 1,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          backgroundColor: "rgba(232, 245, 233, 0.5)", // Light green background
                          borderRadius: 2,
                          padding: 2,
                        }}
                      >
                        <Typography
                          variant="h6"
                          color="success.main"
                          gutterBottom
                          sx={{ display: "flex", alignItems: "center" }}
                        >
                          <CheckCircleOutlineIcon sx={{ mr: 1 }} /> Healthy
                          Balance
                        </Typography>
                        <Typography variant="body1">
                          Your wallet balance is in good standing. You have
                          sufficient funds for upcoming services.
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            mt: 2,
                          }}
                        >
                          <Button
                            variant="outlined"
                            color="success"
                            startIcon={<AddIcon />}
                            onClick={() => setOpen(true)}
                            sx={{ borderRadius: "12px" }}
                          >
                            Add More Funds
                          </Button>

                          <Button
                            variant="text"
                            color="primary"
                            endIcon={<ArrowForwardIcon />}
                            onClick={() => setTransactionHistoryOpen(true)}
                          >
                            View Transactions
                          </Button>
                          <TransactionHistory
                            open={transactionHistoryOpen}
                            onClose={() => setTransactionHistoryOpen(false)}
                            transactions={
                              walletTransactionsHistory &&
                              walletTransactionsHistory
                            }
                          />
                        </Box>
                      </Box>
                    ) : (
                      (totalAmount ||
                        (totalAmount === 0 &&
                          expectedWalletstatus !== "loading")) && (
                        <Box
                          sx={{
                            flex: 1,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            backgroundColor: "rgba(255, 235, 238, 0.5)",
                            borderRadius: 2,
                            padding: 2,
                            // border: '1px solid #e0e0e0',
                          }}
                        >
                          <Typography
                            variant="h6"
                            color="error"
                            gutterBottom
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            <ErrorOutlineIcon sx={{ mr: 1 }} /> Low Balance
                            Alert
                          </Typography>
                          <Typography variant="body1">
                            Your wallet balance is running low. Consider adding
                            funds to avoid any interruptions in service.
                          </Typography>
                          <Typography variant="body1" sx={{ mt: 1 }}>
                            The minimum balance required to maintain is 1000 Rs.
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              //  mt: 2
                            }}
                          >
                            <Tooltip
                              title="Your account has not been verified with meta.
                            Please link with meta to add wallet amount."
                              placement="right"
                              open={
                                isMetaVerifiedToolTip && tooltipView === "alert"
                              }
                              onClose={() => setIsMetaVerifiedToolTip(false)}
                            >
                              <Button
                                variant="contained"
                                color="primary"
                                sx={{ mt: 2, alignSelf: "flex-start" }}
                                // onClick={() => setOpen(true)}
                                onClick={() => {
                                  handleAddBalance();
                                  setTooltipView("alert");
                                }}
                              >
                                Top Up Now
                              </Button>
                            </Tooltip>{" "}
                            <Button
                              variant="text"
                              color="primary"
                              endIcon={<ArrowForwardIcon />}
                              onClick={() => setTransactionHistoryOpen(true)}
                            >
                              View Transactions
                            </Button>
                            <TransactionHistory
                              open={transactionHistoryOpen}
                              onClose={() => setTransactionHistoryOpen(false)}
                              transactions={
                                walletTransactionsHistory &&
                                walletTransactionsHistory
                              }
                            />
                          </Box>
                        </Box>
                      )
                    )}
                  </Grid>
                </Grid>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  gap: "30px",
                }}
              >
                <Box ml={2}>
                  <Typography
                    variant="body2"
                    className={classes.blackColor}
                    style={{ marginTop: "30px", fontWeight: "bold" }}
                  >
                    Transactions
                  </Typography>
                  <Typography
                    variant="body2"
                    className={classes.grayColor}
                    style={{ marginTop: "10px" }}
                  >
                    Find all your transactions happening through your wallet and
                    card (for subscription fee) here.
                  </Typography>
                </Box>
                {/* {transactionData && transactionData.length > 0 && value === "1" && */}
                {value === "1" && getWalletPermissions && (
                  <Box
                    ml={2}
                    sx={{
                      display: "flex",
                      // justifyContent: "space-between"
                      gap: "20px",
                    }}
                  >
                    <DateRangePicker
                      onDateRangeChange={handleDateRangeChange}
                      disabled={transactionData && transactionData?.length < 1}
                      startCalenderDate={startCalenderDate}
                      endCalenderDate={endCalenderDate}
                    />
                    <Button
                      disabled={!startCalenderDate || !endCalenderDate}
                      onClick={handleApplyDateRange}
                      sx={{
                        border: "1px solid green",
                        borderRadius: "12px",
                        color: "green",
                        height: "35px",
                        fontSize: "12px",
                      }}
                    >
                      {isDateRangeApplied ? "Reset" : "Apply"}
                    </Button>
                  </Box>
                )}
              </Box>

              <Box
                style={{
                  marginTop: "10px",
                }}
              >
                <TabContext value={value}>
                  <Box px={2}>
                    <TabList
                      onChange={handleChange}
                      indicatorColor="secondary"
                      aria-label="simple tabs example"
                      sx={{
                        "& .MuiTabs-indicator": {
                          backgroundColor: bgColors.green,
                          height: "2px",
                        },
                        "& .Mui-selected": {
                          color: `${bgColors.black} !important`,
                          fontWeight: "600 !important",
                        },
                        // Add this to adjust padding for all tabs
                        "& .MuiTab-root": {
                          paddingRight: "10px",
                          paddingLeft: "0px",
                        },
                      }}
                    >
                      {getWalletPermissions && (
                        <Tab
                          label="Wallet Transactions"
                          value="1"
                          sx={{
                            textTransform: "none",
                            paddingRight: "10px", // Specific to this tab
                          }}
                        />
                      )}
                      {/* { allowAccessToSubscriptionDetails&&  */}
                      {getSubscriptionPermissions && (
                        <Tab
                          label="Subscription Deductions"
                          value="2"
                          sx={{
                            textTransform: "none",
                            paddingRight: "10px",
                            marginLeft: "20px",
                          }}
                        />
                      )}
                      {/* } */}
                    </TabList>
                  </Box>
                  <hr className={classes.lineStyles} />
                  {getWalletPermissions && (
                    <TabPanel sx={{ padding: 0 }} value="1">
                      <Box>
                        <TableContainer component={Box}>
                          <Table
                            className={classes.table}
                            sx={{ width: "100%" }}
                          >
                            <TableHead className={classes.grayColor}>
                              <TableRow>
                                <TableCell>
                                  <span style={{ marginLeft: "10px" }}>
                                    Date
                                  </span>
                                </TableCell>
                                <TableCell>Transactions</TableCell>
                                <TableCell>Amount</TableCell>
                                <TableCell>Invoice</TableCell>
                              </TableRow>
                            </TableHead>
                            {transactionDataStatus !== "loading" ? (
                              <TableBody>
                                {transactionData &&
                                transactionData.length > 0 ? (
                                  transactionData?.map(
                                    (row: any, index: any) => {
                                      const formattedDate = formatDate(
                                        row.endDate
                                      );
                                      const isShadowHovered =
                                        row?.id === hoveredRow;
                                      return (
                                        <TableRow
                                          key={index}
                                          onMouseEnter={() =>
                                            handleRowHover(row?.id)
                                          }
                                          onMouseLeave={() =>
                                            setHoveredRow(null)
                                          }
                                          sx={{
                                            cursor: "pointer",
                                            boxShadow: isShadowHovered ? 3 : 0,
                                            backgroundColor: row?.isChecked
                                              ? "#DBDBDB"
                                              : "inherit",
                                            transition: "box-shadow",
                                          }}
                                        >
                                          <TableCell>
                                            <Box
                                              className={
                                                classes.teamProfileContainer
                                              }
                                              sx={{
                                                display: "flex",
                                                marginLeft: "10px",
                                              }}
                                            >
                                              <Typography
                                                className={classes.blackColor}
                                                sx={{ fontSize: "14px" }}
                                              >
                                                {formattedDate.time +
                                                  " " +
                                                  formattedDate.date}
                                              </Typography>
                                            </Box>
                                          </TableCell>
                                          <TableCell
                                            className={classes.blackColor}
                                          >
                                            {row.description}
                                          </TableCell>
                                          <TableCell
                                            className={classes.blackColor}
                                          >
                                            -₹ {row.cost}
                                            <span
                                              style={{
                                                color: "gray",
                                                fontSize: "15px",
                                              }}
                                            >
                                              /Updated balance ₹{" "}
                                              {row.currentBalance.toLocaleString()}
                                            </span>
                                          </TableCell>
                                          <TableCell>
                                            {walletInvoiceTooltipOpen ===
                                            row?.id ? (
                                              <Tooltip
                                                title="Access Denied"
                                                placement="left"
                                                open={
                                                  walletInvoiceTooltipOpen ===
                                                  row?.id
                                                }
                                                onClose={() =>
                                                  setWalletInvoiceTooltipOpen(
                                                    ""
                                                  )
                                                }
                                              >
                                                <Box>
                                                  <IconButton>
                                                    <FileDownloadOutlinedIcon />
                                                  </IconButton>
                                                </Box>
                                              </Tooltip>
                                            ) : (
                                              <Tooltip
                                                title="Download"
                                                placement="bottom"
                                              >
                                                <Box>
                                                  <IconButton
                                                    onClick={() =>
                                                      handlePopoverOpen(
                                                        row.id,
                                                        formattedDate.date
                                                      )
                                                    }
                                                  >
                                                    <FileDownloadOutlinedIcon />
                                                  </IconButton>
                                                </Box>
                                              </Tooltip>
                                            )}
                                          </TableCell>
                                        </TableRow>
                                      );
                                    }
                                  )
                                ) : (
                                  <TableRow>
                                    <TableCell colSpan={7} align="center">
                                      No Transactions
                                    </TableCell>
                                  </TableRow>
                                )}
                              </TableBody>
                            ) : (
                              <TableBody
                                sx={{
                                  position: "relative",
                                  margin: "20px",
                                  height: "500px",
                                }}
                              >
                                <TableRow
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: "100%",
                                    position: "absolute",
                                    left: "50%",
                                    transform: "translateX(-50%)",
                                    paddingLeft: "15px",
                                    top: "20px",
                                  }}
                                >
                                  <LoadingComponent
                                    height="auto"
                                    color={bgColors.blue}
                                  />
                                </TableRow>
                              </TableBody>
                            )}
                          </Table>
                        </TableContainer>
                      </Box>
                    </TabPanel>
                  )}
                  {getSubscriptionPermissions && (
                    <TabPanel sx={{ padding: 0 }} value="2">
                      <Box>
                        <TableContainer component={Box}>
                          <Table className={classes.table}>
                            <TableHead className={classes.grayColor}>
                              <TableRow>
                                <TableCell>
                                  <span style={{ marginLeft: "10px" }}>
                                    Date
                                  </span>
                                </TableCell>
                                <TableCell>Description</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Amount</TableCell>
                                <TableCell>Invoice</TableCell>
                              </TableRow>
                            </TableHead>

                            {!isPlatform ? (
                              subscriptionDataStatus !== "loading" ? (
                                <TableBody>
                                  {subscriptionData &&
                                  subscriptionData?.length > 0 ? (
                                    subscriptionData?.map((row: any) => {
                                      const formattedDate = formatDate(
                                        row.updatedAt
                                      );
                                      return (
                                        <TableRow key={row.id}>
                                          <TableCell>
                                            <Box
                                              className={
                                                classes.teamProfileContainer
                                              }
                                            >
                                              <Typography
                                                className={classes.blackColor}
                                              >
                                                {formattedDate.date}
                                              </Typography>
                                              <Typography>
                                                {formattedDate.time}
                                              </Typography>
                                            </Box>
                                          </TableCell>
                                          <TableCell
                                            className={classes.blackColor}
                                          >
                                            {row.description}
                                          </TableCell>
                                          <TableCell
                                            className={classes.blackColor}
                                          >
                                            {capitalizeFirstLetter(row.status)}
                                          </TableCell>
                                          <TableCell
                                            className={classes.blackColor}
                                          >
                                            ₹{" "}
                                            {parseFloat(
                                              row.orderAmount
                                            ).toLocaleString("en-IN")}
                                          </TableCell>
                                          <TableCell
                                            className={classes.threedots}
                                          >
                                            <Box>
                                              {subscriptionInvoiceTooltipOpen ===
                                                row?.id &&
                                              subscriptionTooltipMessage ===
                                                "" ? (
                                                <Tooltip
                                                  title="Access Denied"
                                                  placement="left"
                                                  open
                                                  onClose={() =>
                                                    setSubscriptionInvoiceTooltipOpen(
                                                      ""
                                                    )
                                                  }
                                                >
                                                  <Box>
                                                    <IconButton
                                                      onClick={() =>
                                                        SubscriptionPopoverOpen(
                                                          row.orderAmount,
                                                          row.id,
                                                          formattedDate.date
                                                        )
                                                      }
                                                    >
                                                      <FileDownloadOutlinedIcon />
                                                    </IconButton>
                                                  </Box>
                                                </Tooltip>
                                              ) : (
                                                <Tooltip
                                                  title={
                                                    subscriptionTooltipMessage !==
                                                      "" &&
                                                    subscriptionInvoiceTooltipOpen ===
                                                      row?.id
                                                      ? subscriptionTooltipMessage
                                                      : "Download"
                                                  }
                                                  placement="left"
                                                >
                                                  <Box>
                                                    <IconButton
                                                      onClick={() =>
                                                        SubscriptionPopoverOpen(
                                                          row.orderAmount,
                                                          row.id,
                                                          formattedDate.date
                                                        )
                                                      }
                                                    >
                                                      <FileDownloadOutlinedIcon />
                                                    </IconButton>
                                                  </Box>
                                                </Tooltip>
                                              )}
                                            </Box>
                                          </TableCell>
                                        </TableRow>
                                      );
                                    })
                                  ) : (
                                    <TableRow>
                                      <TableCell colSpan={7} align="center">
                                        No Subscriptions
                                      </TableCell>
                                    </TableRow>
                                  )}
                                </TableBody>
                              ) : (
                                // <LoadingComponent height="auto" color={bgColors?.blue} />
                                <TableBody
                                  sx={{
                                    position: "relative",
                                    margin: "20px",
                                    height: "500px",
                                  }}
                                >
                                  <TableRow
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      width: "100%",
                                      position: "absolute",
                                      left: "50%",
                                      transform: "translateX(-50%)",
                                      paddingLeft: "15px",
                                      top: "20px",
                                    }}
                                  >
                                    <LoadingComponent
                                      height="auto"
                                      color={bgColors.blue}
                                    />
                                  </TableRow>
                                </TableBody>
                              )
                            ) : (
                              <TableRow>
                                <TableCell>
                                  <Box className={classes.teamProfileContainer}>
                                    -
                                  </Box>
                                </TableCell>
                                <TableCell className={classes.blackColor}>
                                  Subscription Charges-renewal
                                </TableCell>
                                <TableCell className={classes.blackColor}>
                                  Unlimited
                                </TableCell>
                                <TableCell className={classes.blackColor}>
                                  -
                                </TableCell>
                                <TableCell className={classes.threedots}>
                                  <Box>-</Box>
                                </TableCell>
                              </TableRow>
                            )}
                          </Table>
                        </TableContainer>
                      </Box>
                    </TabPanel>
                  )}
                  <br />
                </TabContext>
              </Box>
            </>
          ) : (
            <LoadingComponent height="520px" color={bgColors.blue} />
          )}
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default Wallet;
