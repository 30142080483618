import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import ChatInformationSvg from "../../../assets/svgs/ChatInformationSvg";
import RedCloseIcon from "../../../assets/svgs/RedCloseIcon";
import RightArrowSvg from "../../../assets/svgs/RightArrowSvg";
import PersistentDrawerLeft from "../chatSideBarComponents/ChatDrawer";
// import HelpChatProfileSvg from "../../../assets/svgs/HelpChatProfileSvg";
import Button from "@mui/material/Button";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import AssignToPopover from "./AssignToPopover";
import ChatArrowDownSvg from "../../../assets/svgs/ChatArrowDownSvg";
import { useAppSelector } from "../../../utils/redux-hooks";

interface ChatHeaderProps {
  open: boolean;
  chatData: any;
  handleCloseDrawer: () => void;
  handleDrawerOpen: () => void;
  chat: {
    name: string;
    plan?: string;
    assignTo?: string;
  };
  showUserDetails: boolean;
  setUserDetailsTooltipOpen: React.Dispatch<React.SetStateAction<boolean>>;
  userDetailsTooltipOpen: boolean;
  toggleUserDetails: () => void;
  classes: any;
  bgColors: any;
  handleAssignClick: (event: any) => void;
  assignTooltipOpen: boolean;
  setAssignTooltipOpen: React.Dispatch<React.SetStateAction<boolean>>;
  anchorEl: HTMLElement | null;
  handleClosePopover: () => void;
  contactChatStatus: string;
  closeChatTooltipOpen: boolean;
  setCloseChatTooltipOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleCloseChat: () => void;
  contacts: any;
}

const ChatHeader: React.FC<ChatHeaderProps> = ({
  open,
  chatData,
  contacts,
  chat,
  showUserDetails,
  classes,
  bgColors,
  anchorEl,
  contactChatStatus,
  userDetailsTooltipOpen,
  assignTooltipOpen,
  closeChatTooltipOpen,
  setUserDetailsTooltipOpen,
  setAssignTooltipOpen,
  setCloseChatTooltipOpen,
  toggleUserDetails,
  handleCloseDrawer,
  handleDrawerOpen,
  handleAssignClick,
  handleClosePopover,
  handleCloseChat,
}) => {
  const accountData = useAppSelector((state: any) => state?.accountData?.data);
  const getAllTeamMembersData = useAppSelector(
    (state: any) => state?.getAllTeamMembersByCompanyId?.data
  );
  const teamMembersData = getAllTeamMembersData?.filter(
    (item: any) => item?.status === true
  );
  const assignee = teamMembersData?.filter(
    (item: any) => item?.id === chat?.assignTo
  );
  // console.log("assignee", contactChatStatus);

  return (
    <Box
      p={{ xs: 0.5, md: 2 }}
      sx={{
        background: bgColors?.white1,
        boxShadow: "1px 4px 4px #CDCDCD",
        display: { xs: "block", md: "flex" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{
            display: { xs: "flex", md: "none" },
            position: "absolute",
            left: 0,
            background: "#cdcdcd",
          }}
        >
          <RightArrowSvg />
        </IconButton>
        <PersistentDrawerLeft
          open={open}
          handleCloseDrawer={handleCloseDrawer}
          contacts={contacts}
          // setContacts={setContacts}
        />
        {/* {chat?.name === "help" ? (
          <HelpChatProfileSvg />
        ) : ( */}
        <img src={"/images/profile.png"} alt="" height={45} />
        {/* )} */}
        <Box mx={1}>
          <Box display="flex" alignItems="center">
            <Typography mt={-0.5} className={classes.blackColor}>
              {chat?.name === "undefined" || chat?.name === "help"
                ? ""
                : chat?.name}
            </Typography>
            &nbsp;
            {!showUserDetails && (
              <Box className={classes.flexCenterCursor}>
                {userDetailsTooltipOpen ? (
                  <Tooltip
                    title="Access Denied"
                    placement="right"
                    open={userDetailsTooltipOpen}
                    onClose={() => setUserDetailsTooltipOpen(false)}
                  >
                    <Box
                      className={classes.overlay}
                      onClick={toggleUserDetails}
                    >
                      <ChatInformationSvg />
                    </Box>
                  </Tooltip>
                ) : (
                  <Tooltip title="View user details" placement="bottom">
                    <Box
                      className={classes.overlay}
                      onClick={toggleUserDetails}
                    >
                      <ChatInformationSvg />
                    </Box>
                  </Tooltip>
                )}
              </Box>
            )}
          </Box>
          {chatData?.isOptIn === 1 ? (
            <Typography color={bgColors?.green} mt={-0.5} fontSize={10}>
              Opted In
            </Typography>
          ) : (
            <Typography color={bgColors?.red} mt={-0.5} fontSize={10}>
              Opted out
            </Typography>
          )}
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          ml: "auto",
          mt: { xs: 1, md: 0 },
          alignItems: "center",
        }}
      >
        <>
          <Tooltip
            title="Access Denied"
            placement="top"
            open={assignTooltipOpen}
            onClose={() => setAssignTooltipOpen(false)}
          >
            <Box
              mr={2}
              className={
                chat?.assignTo !== null
                  ? classes?.assigned
                  : classes?.SaveChangesButton
              }
              onClick={handleAssignClick}
              sx={{
                "&:hover": {
                  cursor: "pointer",
                  backgroundColor: "rgba(68, 71, 70, 0.08)",
                },
              }}
            >
              {chat?.assignTo !== null ? (
                <Tooltip
                  placement="left"
                  title={`Assigned to ${
                    assignee[0]?.name === accountData?.name
                      ? "you"
                      : assignee[0]?.name || null
                  }`}
                >
                  <HowToRegIcon fontSize="small" />
                </Tooltip>
              ) : (
                <button className={classes.assignButton}>
                  Assign to &nbsp;&nbsp;|
                </button>
              )}
              <Box
                // mr={1}
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <ChatArrowDownSvg
                  style={{
                    width: "15px",
                    height: "15px",
                    stroke: bgColors.green,
                  }}
                />
              </Box>
            </Box>
          </Tooltip>
          <AssignToPopover
            contact={[chat]}
            anchorEl={anchorEl}
            onClose={handleClosePopover}
          />
        </>

        {contactChatStatus?.includes("open") ? (
          <Tooltip
            title="Access Denied"
            placement="top"
            open={closeChatTooltipOpen}
            onClose={() => setCloseChatTooltipOpen(false)}
          >
            <Box
              className={classes.resetCloseBtnContainer}
              sx={{
                "&:hover": {
                  cursor: "pointer",
                  backgroundColor: bgColors.red2,
                },
              }}
              onClick={handleCloseChat}
            >
              <Button
                sx={{
                  fontSize: "12px",
                  color: bgColors.red,
                  height: "32px",
                  background: "transparent",
                  "&:hover": {
                    background: "unset",
                  },
                }}
              >
                Mark as resolved
              </Button>
              <RedCloseIcon
                style={{
                  minWidth: "20px",
                }}
              />
            </Box>
          </Tooltip>
        ) : contactChatStatus?.includes("resolved") ? (
          <Box
            className={classes.resetBtnContainer}
            sx={{
              backgroundColor: bgColors.red2,
            }}
          >
            <Typography
              sx={{ fontSize: "12px" }}
              className={classes.changePasswordText}
            >
              Resolved
            </Typography>
          </Box>
        ) : contactChatStatus?.includes("expired") ? (
          <Box
            className={classes.resetBtnContainer}
            sx={{
              backgroundColor: bgColors.red2,
            }}
          >
            <Typography
              sx={{ fontSize: "12px" }}
              className={classes.changePasswordText}
            >
              Expired
            </Typography>
          </Box>
        ) : (
          contactChatStatus?.includes("new") && (
            <Box
              className={classes.resetBtnContainer}
              sx={{
                borderColor: "transparent",
                backgroundColor: bgColors.green1,
              }}
            >
              <Typography
                sx={{ fontSize: "12px", color: bgColors.green }}
                className={classes.changePasswordText}
              >
                New Chat
              </Typography>
            </Box>
          )
        )}
      </Box>
    </Box>
  );
};

export default ChatHeader;
