import React from 'react'

const TemplateSvg = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M25.5455 1.10645H6.45455C3.44208 1.10645 1 3.54853 1 6.56099V10.6064H2H10.5H13.5H30H31V6.56099C31 3.54853 28.5579 1.10645 25.5455 1.10645ZM10.5 13.6064H2H1V25.6519C1 28.6644 3.44208 31.1064 6.45455 31.1064H10.5V30.1064V13.6064ZM13.5 31.1064V30.1064V13.6064H30H31V25.6519C31 28.6644 28.5579 31.1064 25.5455 31.1064H13.5Z" fill="#4B5A5A"/>
</svg>
  )
}

export default TemplateSvg