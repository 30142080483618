import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { bgColors } from "../../../utils/bgColors";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import NewFaqPopup from "../../../components/HelpCenterComponents/NewFaqPopup";
import { useAppDispatch, useAppSelector } from "../../../utils/redux-hooks";
import { fetchHelpCenter } from "../../../redux/slices/HelpCenter/HelpCenterSlice";
import LoadingComponent from "../../../components/common/LoadingComponent";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import HelpIcon from "@mui/icons-material/Help";
import React from "react";

const useStyles = makeStyles({
  mainContainer: {
    // backgroundColor: bgColors.white1,
    // padding: "14px",
    // height: "100%",
    // width: "100%",
    backgroundColor: bgColors.white1,
    padding: "14px",
    height: "100% !important",
    width: "100%",
    overflowY: "auto",
    display: "flex",
    // @ts-expect-error: Important override of CSS style for flex direction
    flexDirection: "column !important",
  },
  bgContainer: {
    backgroundColor: bgColors.white,
    borderRadius: "25px",
    height: "auto",
    width: "100%",
    display: "flex",
   // @ts-expect-error: Important override of CSS style for flex direction
    flexDirection: "column !important",
    position: "relative",
    flex: "1",
    overflow: "auto",
  },
  manageContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  blackColor: {
    color: `${bgColors.black1} !important`,
    fontWeight: "600 !important",
  },
  customParagraph: {
    margin: 0,
  },
  grayColor: {
    color: `${bgColors.gray1} !important`,
    // opacity: "60% !important",
    // fontSize: "20px !important",
  },
  SaveChangesButton: {
    // backgroundColor: bgColors.green,
    border: `1px solid ${bgColors.green}`,
    color: bgColors.green,
    borderRadius: "8px",
    width: "90px",
    height: "32px",
    padding: "6px",
    cursor: "pointer",
  },
});

const HelpCenter = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [openDialog, setOpenDialog] = useState(false);
  const [addFaqTooltip, setFaqTooltip] = useState(false);
  // const loginData = useAppSelector((state) => state?.adminLogin?.data);
  const getuserPermissionData = useAppSelector(
    (state:any) => state.getUserPermissions?.data
  );
  const manageCompanyObject = getuserPermissionData?.profile?.find(
    (item: any) => Object?.prototype?.hasOwnProperty?.call(item, "helpCenter")
  );
  const manageCompanyActions = manageCompanyObject
    ? manageCompanyObject?.helpCenter
    : [];
  // console.log("manageCompanyActions", getPermissionData?.profile);

  const hasAccess = (permission: any) => {
    if (manageCompanyActions?.includes(permission)) {
      return true;
    }
    return false;
  };

  const handleOpenDialog = () => {
    const hasPermission = hasAccess("addNewFaq");
    if (hasPermission) {
      setOpenDialog(true);
    } else {
      setFaqTooltip(true);
      setTimeout(() => {
        setFaqTooltip(false);
      }, 2000);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const helpCenterData = useAppSelector((state:any) => state?.helpCenter);
  const helpCenter = helpCenterData?.data;
  // const helpCenter:any = [];
  // console.log(helpCenter, 'helpCenter')
  const helpCenterStatus = useAppSelector(
    (state:any) => state?.helpCenter?.dataStatus
  );
  // console.log(helpCenterStatus)
  useEffect(() => {
    dispatch(fetchHelpCenter());
  }, []);
  const [isHovered, setIsHovered] = useState(false);

  // console.log(helpCenter)

  return (
    <Grid
      className={classes.mainContainer}
      sx={{ height: helpCenterStatus !== "loading" ? "auto" : "100%" }}
    >
      {/* {helpCenterStatus === "loading" ? */}
      <Grid className={classes.bgContainer}>
        <Box
          // py={{ xs: 1, md: 4,sm: 1,lg: 1,xl: 1}}
          pt={{ xs: 1, md: 4 }}
          px={{ xs: 1, md: 3 }}
          // mt={4} mb={2}
          // px={{ xs: 1, md: 4 }}
          className={classes.manageContainer}
        >
          <Typography
            variant="h6"
            className={classes.blackColor}
            sx={{ display: "flex" }}
          >
            <span style={{ marginTop: "3px" }}>
              <HelpIcon sx={{ height: "20px" }} />
            </span>
            &nbsp;Frequently Asked Questions
          </Typography>
          <Tooltip
            title="Access Denied"
            placement="left"
            open={addFaqTooltip}
            onClose={() => setFaqTooltip(false)}
          >
            <Box style={{ marginLeft: "auto" }} onClick={handleOpenDialog}>
              <button
                className={classes.SaveChangesButton}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                style={{
                  // backgroundColor:"#fff",
                  backgroundColor: isHovered
                    ? "rgba(68, 71, 70, 0.08)"
                    : "#fff",
                  cursor: isHovered ? "pointer" : "default", // Optional: change cursor style on hover
                  // Add other styles if needed
                }}
              >
                + Add FAQ
              </button>
            </Box>
          </Tooltip>
          <NewFaqPopup open={openDialog} handleClose={handleCloseDialog} />
        </Box>
        {helpCenterStatus !== "loading" ? (
          <Box
            mt={2}
            mb={2}
            sx={{
              flex: "1",
            }}
          >
            {helpCenter?.length ? (
              helpCenter !== null &&
              Array.isArray(helpCenter) &&
              helpCenter &&
              helpCenter?.map((faq: any, index: any) => (
                <Box key={index} px={2} py={1}>
                  <Accordion
                    style={{
                      border: `1px solid ${bgColors.gray5}`,
                      borderRadius: "10px",
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`panel${index}-content`}
                      id={`panel${index}-header`}
                    >
                      <Typography
                        sx={{
                          fontSize: 14,
                          width: "100%",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "normal",
                          wordBreak: "break-word",
                        }}
                        className={classes.blackColor}
                      >
                        {/* {faq?.question} */}
                        <ReactMarkdown
                          remarkPlugins={[remarkGfm]}
                          components={{
                            p: ({
                              //  node,
                              ...props
                            }) => (
                              <p
                                {...props}
                                className={classes.customParagraph}
                              />
                            ),
                          }}
                          rehypePlugins={[rehypeRaw]}
                        >
                          {faq.question}
                        </ReactMarkdown>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        paddingTop: "0px",
                        lineHeight: 0,
                      }}
                    >
                      <Typography
                        component="div"
                        sx={{
                          fontSize: 14,
                          width: "100%",
                          wordBreak: "break-word",
                        }}
                        className={classes.grayColor}
                      >
                        {/* <div dangerouslySetInnerHTML={{ __html: faq.answer }} /> */}
                        <ReactMarkdown
                          remarkPlugins={[remarkGfm]}
                          rehypePlugins={[rehypeRaw]}
                          components={{
                            p: ({
                              // node,
                              ...props
                            }) => (
                              <p
                                {...props}
                                className={classes.customParagraph}
                              />
                            ),
                          }}
                        >
                          {faq.answer}
                        </ReactMarkdown>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              ))
            ) : (
              <>
                <Typography
                  sx={{
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {/* No FAQ's added */}
                  No FAQ&apos;s added
                </Typography>
              </>
            )}
          </Box>
        ) : (
          <LoadingComponent height="100%" color={bgColors.blue} />
        )}
      </Grid>
      {/* : <LoadingComponent height="100%" color={bgColors.blue} />} */}
    </Grid>
  );
};

export default HelpCenter;
