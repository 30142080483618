/* global process */
import {
  Box,
  Card,
  CardContent,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import CheckCircleSvg from "../../../assets/svgs/CheckCircleSvg";
import { useAppDispatch, useAppSelector } from "../../../utils/redux-hooks";
import {
  fetchAllPlans,
  fetchPlanDetailsByPlanId,
} from "../../../redux/slices/Subscription/SubscriptionSlice";
import axios from "axios";
import LoadingComponent from "../../../components/common/LoadingComponent";
import { toastActions } from "../../../utils/toastSlice";
import { bgColors } from "../../../utils/bgColors";
import { getWalletAndSubscription } from "../../../redux/slices/Wallet/WalletSlice";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
// import { differenceInDays, isLeapYear } from "date-fns";
import NoAccessPage from "../../../components/common/NoAccess";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const USER_API_URL = process.env.REACT_APP_BASE_URL;

const useStyles = makeStyles({
  mainContainer: {
    backgroundColor: bgColors.white1,
    padding: "14px",
    width: "100%",
    height: "100%",
  },
  bgContainer: {
    backgroundColor: bgColors.white,
    borderRadius: "25px",
    height: "100%",
    flex: 1,
    // width: "100%",
  },
  blackColor: {
    color: `${bgColors.black1} !important`,
    fontWeight: "600 !important",
  },
  MonthlyButton: {
    backgroundColor: bgColors.green,
    color: bgColors.white,
    borderRadius: "20px",
    width: "100px",
    height: "40px",
    padding: "14px",
    cursor: "pointer",
    fontSize: "12px",
  },
  WeeklyButton: {
    // backgroundColor: bgColors.white,
    // color: bgColors.black,
    borderRadius: "20px",
    width: "100px",
    height: "40px",
    padding: "14px",
    cursor: "pointer",
    fontSize: "12px",
  },
  currentButton: {
    backgroundColor: bgColors.gray2,
    color: bgColors.green,
    borderRadius: "20px",
    width: "80%",
    height: "40px",
    padding: "12px",
    cursor: "pointer",
    fontSize: "16px",
    fontWeight: "600",
  },
  RsStyles: {
    fontSize: "16px",
    marginTop: "30px",
  },
  planAmount: {
    fontSize: "44px",
    margin: 0,
  },
  card: {
    borderRadius: "20px !important",
    transition: "all 0.3s ease",
    marginRight: "30px",
    position: "relative", // To position the tick mark
    "&:hover": {
      backgroundColor: bgColors.green,
      color: `${bgColors.white1} !important`,
      transform: "scaleY(1.2)",
      "& .MuiTypography-root": {
        color: `${bgColors.white1} !important`,
      },
      "& .MuiSvgIcon-root": {
        color: `${bgColors.white1} !important`,
      },
    },
  },
  activatedCard: {
    border: `2px solid ${bgColors.green}`, // Border to highlight the activated card
  },
  tickMark: {
    position: "absolute",
    top: "10px",
    right: "10px",
    color: bgColors.green,
  },
});

// interface Plan {
//   planName: string;
//   price: number;
//   type: "Quarterly" | "Annually";
// }

// interface CurrentPlan {
//   planName: string;
//   planEndDate: string;
//   planType: "Quarterly" | "Annually";
//   currentSubscriptionPlanAmount: number;
// }

const PLAN_RANKS: { [key: string]: number } = {
  "Quarterly Basic": 1,
  "Quarterly Pro": 2,
  "Annually Basic": 3,
  "Annually Pro": 4,
};

const getPlanRank = (planName: string, planType: string) => {
  return PLAN_RANKS[`${planType} ${planName}`] || 0;
};

const UpgradePlan = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const getAllPlans = useAppSelector(
    (state:any) => state?.Subscription?.getAllPlansData
  );

  const status = useAppSelector((state:any) => state.Subscription?.status);
  const loginData = useAppSelector((state:any) => state.adminLogin?.data);

  const userId = loginData?.userId;
  const companyId = loginData?.companyId;

  let currentPlan = useAppSelector(
    (state:any) => state?.wallet?.walletAndSubscription?.data
  );

  const getuserPermissionData = useAppSelector(
    (state:any) => state.getUserPermissions?.data
  );

  const [planType, setPlanType] = useState("3");
  const [choosePlanPermissionTooltip, setChoosePlanPermissionTooltip] =
    useState("");
  const [tooltipMeesage, setTooltipMessage] = useState("");

  const subscriptionPermissionObject = getuserPermissionData?.profile?.find(
    (item: any) => Object?.prototype?.hasOwnProperty?.call(item, "subscription")
  );
  const subscriptionPermissionActions = subscriptionPermissionObject
    ? subscriptionPermissionObject?.subscription
    : [];

  const hasAccess = (permission: any) => {
    if (subscriptionPermissionActions?.includes(permission)) {
      return true;
    }
    return false;
  };

  const handlePlanPeriod = (plan: any) => {
    setPlanType(plan);
    dispatch(fetchAllPlans({ planType: plan, companyId }));
  };

  useEffect(() => {
    dispatch(fetchAllPlans({ planType, companyId }));
  }, [dispatch, userId]);

  const loadScript = (src: string) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => resolve(true);
      script.onerror = () => resolve(false);
      document.body.appendChild(script);
    });
  };

  const handleBack = () => {
    navigate("/profile/subscription");
  };

  // function calculateProratedAmount(
  //   currentPlan: CurrentPlan,
  //   newPlan: Plan
  // ): number {
  //   const today = new Date();
  //   const planEndDate = new Date(currentPlan.planEndDate);
  //   const daysRemaining = Math.max(differenceInDays(planEndDate, today), 0);

  //   let daysInPeriod: number;
  //   if (currentPlan.planType === "Quarterly") {
  //     daysInPeriod = 91;
  //   } else {
  //     daysInPeriod = isLeapYear(today) ? 366 : 365;
  //   }

  //   const unusedAmount =
  //     (currentPlan.currentSubscriptionPlanAmount / daysInPeriod) *
  //     daysRemaining;

  //   let newPlanFullPrice: number;
  //   if (newPlan.type === "Quarterly") {
  //     newPlanFullPrice = newPlan.price;
  //   } else {
  //     const daysInNewYear = isLeapYear(new Date(today.getFullYear() + 1, 0, 1))
  //       ? 366
  //       : 365;
  //     newPlanFullPrice = newPlan.price * (daysInNewYear / 365); // Adjust for leap year
  //   }

  //   const proratedAmount = Math.max(newPlanFullPrice - unusedAmount, 0);

  //   return Math.round(proratedAmount * 100) / 100; // Round to 2 decimal places
  // }

  const payForPlan = (
    PlanId: number,
    amount: number,
    planName: string,
    planType: string
  ) => {
    const currentUsingPlan =
      currentPlan?.subscriptionPlan?.planName === planName &&
      currentPlan?.subscriptionPlan?.planType === planType;
    const currentPlanRank = getPlanRank(
      currentPlan?.subscriptionPlan?.planName,
      currentPlan?.subscriptionPlan?.planType
    );
    const newPlanRank = getPlanRank(planName, planType);

    if (currentUsingPlan) {
      setTooltipMessage("Use 'Make Payment' for extending your plan.");
      setChoosePlanPermissionTooltip(currentPlan?.subscriptionPlan?.planName);
      setTimeout(() => {
        setChoosePlanPermissionTooltip("");
      }, 2000);
    } else if (newPlanRank < currentPlanRank) {
      dispatch(
        toastActions.setToaster({
          type: "error",
          message: "You cannot downgrade your subscription plan.",
        })
      );
      return;
    } else {
      const hasPermission = hasAccess("choosePlan");
      if (hasPermission) {
        // const CurrentPlan: CurrentPlan = {
        //   planName: currentPlan.subscriptionPlan.planName,
        //   planEndDate: currentPlan.subscriptionPlan.planEndDate,
        //   planType: currentPlan.subscriptionPlan.planType,
        //   currentSubscriptionPlanAmount:
        //     currentPlan.subscriptionPlan.currentSubscriptionPlanAmount,
        // };

        // const newPlan: Plan = {
        //   planName: planName,
        //   price: amount,
        //   type: planType as "Quarterly" | "Annually",
        // };

        // const proratedAmount = calculateProratedAmount(CurrentPlan, newPlan);
        displayRazorpay(PlanId, amount, planName, planType);
      } else {
        setTooltipMessage("Access Denied.");
        setChoosePlanPermissionTooltip(planName);
        setTimeout(() => {
          setChoosePlanPermissionTooltip("");
        }, 2000);
      }
    }
  };

  const displayRazorpay = async (
    PlanId: number,
    amount: number,
    planName: string,
    planType: string
  ) => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    const getPlanDetails = await dispatch(
      fetchPlanDetailsByPlanId({
        PlanId,
        planType,
        companyId,
        isUpgradePlan: true,
      })
    );
    const planData = getPlanDetails?.payload?.data;
    // const amountInPaise = parseFloat(amount.toString()); // Convert to paise
    const data = {
      totalAmount: planData?.totalAmount,
      igstAmount: planData?.igstAmount,
      amount: planData?.remainingUpgradePlanAmount,
      subscriptionPlanId: planName,
      durationType: planType,
      isUpgradePlan: true,
      // discountId:""
    };
    // Display custom confirmation modal with amount breakdown
    const userConfirmed = window.confirm(
      `Plan: ${planName}\nAmount: ₹${data?.amount?.toFixed(
        2
      )}\nGST: ₹${data?.igstAmount?.toFixed(
        2
      )}\nTotal Amount: ₹${data?.totalAmount?.toFixed(2)}
      \n\nDo you want to proceed to payment?`
    );

    if (!userConfirmed) return;

    const rol = await axios.post(
      `${USER_API_URL}/PaymentsDetails/create-order`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    const options = {
      key: process.env.REACT_APP_RAZORPAY_KEY_ID,
      // amount: data?.amount,
      currency: "INR",
      order_id: rol?.data?.orderId,
      handler: async function (response: any) {
        const data = {
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,
          isUpgradePlan: true,
        };

        const result = await axios.post(
          `${USER_API_URL}/PaymentsDetails/verify-signature`,
          data,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: localStorage.getItem("token"),
            },
          }
        );
        if (result?.data === "Payment verification successful.") {
          dispatch(
            toastActions.setToaster({
              type: "success",
              message: `${result?.data}`,
            })
          );
          dispatch(getWalletAndSubscription(companyId));
          navigate("/profile/subscription");
        }
      },
      theme: {
        color: "#61dafb",
      },
    };


    const paymentObject = new window.Razorpay(options);

    paymentObject.on("payment.failed", function (response: any) {
      dispatch(
        toastActions.setToaster({
          type: "error",
          message: `${response.error.reason}`,
        })
      );
      // axios.post("https://user.engageto.com/api/PaymentsDetails/payment-failed", {
      //   code: response.error.code,
      //   description: response.error.description,
      //   source: response.error.source,
      //   step: response.error.step,
      //   reason: response.error.reason,
      //   order_id: response.error.metadata.order_id,
      //   payment_id: response.error.metadata.payment_id
      // }).then(result => {
      //   console.log(result);
      //   dispatch(toastActions.setToaster({
      //     type: "error",
      //     message: `Payment failed: ${response.error.reason}`,
      //   }));
      // });
    });

    paymentObject.on("payment.pending", function (pending: any) {
      dispatch(
        toastActions.setToaster({
          type: "error",
          message: `${pending.error.reason}`,
        })
      );
      // axios.post("https://user.engageto.com/api/PaymentsDetails/payment-failed", {
      //   code: response.error.code,
      //   description: response.error.description,
      //   source: response.error.source,
      //   step: response.error.step,
      //   reason: response.error.reason,
      //   order_id: response.error.metadata.order_id,
      //   payment_id: response.error.metadata.payment_id
      // }).then(result => {
      //   console.log(result);
      //   dispatch(toastActions.setToaster({
      //     type: "error",
      //     message: `Payment failed: ${response.error.reason}`,
      //   }));
      // });
    });
    paymentObject.open();
  };

  return (
    <Grid container item className={classes.mainContainer}>
      {hasAccess("upgradePlan") ? (
        <Grid item className={classes.bgContainer}>
          <Box>
            <Tooltip
              title="Back"
              sx={{
                cursor: "pointer",
              }}
            >
              <ArrowBackIcon onClick={handleBack} />
            </Tooltip>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="h5" className={classes.blackColor}>
                Simple, transparent pricing
              </Typography>
            </Box>
            <Box mt={1} textAlign="center">
              <Typography
                variant="body2"
                style={{
                  color: bgColors.black1,
                  opacity: "60%",
                  fontWeight: "600",
                }}
              >
                No contracts. No surprise fees.
              </Typography>
            </Box>

            <Box mt={2} textAlign="center">
              <button
                className={classes.MonthlyButton}
                style={{
                  backgroundColor:
                    planType === "3" ? bgColors.green : bgColors.white,
                  color: planType === "3" ? bgColors.white : bgColors.black,
                }}
                onClick={() => {
                  handlePlanPeriod("3");
                }}
              >
                Quarterly
              </button>
              <button
                className={classes.WeeklyButton}
                style={{
                  backgroundColor:
                    planType === "4" ? bgColors.green : bgColors.white,
                  color: planType === "4" ? bgColors.white : bgColors.black,
                }}
                onClick={() => {
                  handlePlanPeriod("4");
                }}
              >
                Annually
              </button>
              <Typography
                variant="body2"
                sx={{
                  color: "#757575",
                  lineHeight: 0.43,
                  marginTop: "20px",
                  fontSize: "12px",
                }}
              >
                <Box component="span" sx={{ color: "red" }}>
                  Note:
                </Box>
                The prices shown exclude GST.
              </Typography>
            </Box>

            {status === "loading" ? (
              <LoadingComponent height="100%" color={bgColors.blue} />
            ) : (
              <Box
                mt={8}
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
                gap="30px"
              >
                {getAllPlans?.data?.length > 0 ? (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "20px",
                    }}
                  >
                    {getAllPlans?.data?.map((plan: any) => (
                      <Box key={plan?.planDetails?.id}>
                        <Card
                          className={`${classes.card} ${
                            currentPlan?.subscriptionPlan?.planName ===
                              plan?.planDetails?.planName &&
                            currentPlan?.subscriptionPlan?.planType ===
                              plan?.type
                              ? classes.activatedCard
                              : ""
                          }`}
                        >
                          <CardContent>
                            {currentPlan?.subscriptionPlan?.planName ===
                              plan?.planDetails?.planName &&
                              currentPlan?.subscriptionPlan?.planType ===
                                plan?.type && (
                                <CheckCircleIcon className={classes.tickMark} />
                              )}
                            <Box display="flex" flexDirection="row">
                              <CurrencyRupeeIcon
                                style={{
                                  fontSize: "18px",
                                  marginTop: "30px",
                                  color: bgColors.black1,
                                  opacity: "60%",
                                  fontWeight: "600",
                                }}
                              />
                              <h1 className={classes.planAmount}>
                                {plan?.planDetails?.price?.toLocaleString()}
                              </h1>
                              <span className={classes.RsStyles}>
                                /{plan?.type}
                              </span>
                            </Box>
                            <Typography
                              variant="h6"
                              className={classes.blackColor}
                              sx={{ mb: 1 }}
                            >
                              {plan?.planDetails?.planName}
                            </Typography>
                            <Typography
                              variant="body2"
                              style={{
                                color: bgColors.black1,
                                opacity: "60%",
                                fontWeight: "600",
                              }}
                              sx={{ mb: 1 }}
                            >
                              {/* For most businesses that want to optimize web queries */}
                              {plan?.planDiscription}
                            </Typography>
                            <Box
                              className={classes.blackColor}
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              {plan?.analyticsPrice.map((item: any, index: number) => {
                                return (
                                  <Box
                                  key={index} 
                                    sx={{
                                      mb: 1,
                                      display: "flex",
                                      flexDirection: "row",
                                      gap: 1,
                                    }}
                                  >
                                    <CheckCircleSvg />{" "}
                                    <Typography
                                      variant="body2"
                                      style={{
                                        color: bgColors.black1,
                                        opacity: "60%",
                                        fontWeight: "600",
                                      }}
                                    >
                                      ₹ {item?.cost} on{" "}
                                      {item?.conversationCategory.toLowerCase()}
                                    </Typography>
                                  </Box>
                                );
                              })}
                            </Box>

                            <Tooltip
                              title={tooltipMeesage}
                              placement="top"
                              open={
                                choosePlanPermissionTooltip ===
                                plan?.planDetails?.planName
                              }
                              onClose={() => setChoosePlanPermissionTooltip("")}
                            >
                              <Box
                                mt={2}
                                display="flex"
                                flexDirection="row"
                                alignItems="center"
                                justifyContent="center"
                              >
                                <button
                                  className={classes.currentButton}
                                  onClick={() =>
                                    payForPlan(
                                      plan?.planDetails?.id,
                                      plan?.planDetails?.price,
                                      plan?.planDetails?.planName,
                                      plan?.type
                                    )
                                  }
                                >
                                  {currentPlan?.subscriptionPlan?.planName ===
                                    plan?.planDetails?.planName &&
                                  currentPlan?.subscriptionPlan?.planType ===
                                    plan?.type
                                    ? "Activated"
                                    : "Choose Plan"}
                                </button>
                              </Box>
                            </Tooltip>
                          </CardContent>
                        </Card>
                      </Box>
                    ))}
                  </Box>
                ) : (
                  <Typography
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    No plans to show
                  </Typography>
                )}
              </Box>
            )}
          </Box>
        </Grid>
      ) : (
        <NoAccessPage />
      )}
    </Grid>
  );
};

export default UpgradePlan;
