import {
  Avatar,
  AvatarGroup,
  Box,
  Grid,
  IconButton,
  TablePagination,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { bgColors } from "../../../utils/bgColors";
import { makeStyles } from "@mui/styles";
// import SearchIconSvg from "../../../assets/svgs/SearchIconSvg";
import AddNewCompany from "../../../components/ManageClientsComponents/AddNewCompany";
import { useNavigate } from "react-router-dom";
// import { MANAGE_CLIENTS } from "../../../Apis/AdminLogin/ManageClients/ManageClient";
import { useAppDispatch, useAppSelector } from "../../../utils/redux-hooks";
import {
  fetchGetAllClients,
  // getAllClientsSliceActions,
} from "../../../redux/slices/ManageClients/getAllClientSlice";
import LoadingComponent from "../../../components/common/LoadingComponent";
import SearchIconSvg2 from "../../../assets/svgs/SearchIconSvg2";
import useDebouncedFetch from "../../../utils/debounceHook";
import PeopleIcon from "@mui/icons-material/People";
import NoAccessPage from "../../../components/common/NoAccess";
import { hasManageClientsPermission } from "../../../utils/permissions";

const useStyles = makeStyles({
  mainContainer: {
    // backgroundColor: bgColors.white1,
    padding: "14px",
    height: "100vh",
    width: "100%",
    overflowY: "auto",
  },
  bgContainer: {
    backgroundColor: bgColors.white,
    paddingBottom: "18px",
    borderRadius: "25px",
    height: "93%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
  },
  manageTeamContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "20px",
    width: "full",
  },
  searchField: {
    width: "100%",
    borderRadius: "12px",
    // backgroundColor: bgColors.white2,
    backgroundColor: "white",
    "& input::placeholder": {
      textAlign: "left",
      fontSize: "14px",
      fontFamily: "Inter",
      color: "#4B5A5A !important",
      fontWeight: "500 !important",
    },
  },
  SaveChangesButton: {
    // backgroundColor: bgColors.green,
    color: bgColors.green,
    borderRadius: "8px",
    border: `1px solid ${bgColors.green}`,
    width: "100px",
    height: "32px",
    // padding: "10px",
    cursor: "pointer",
  },
  blackColor: {
    color: `${bgColors.black1} !important`,
    fontWeight: "600 !important",
  },
  planButton: {
    backgroundColor: bgColors.green1,
    color: bgColors.green,
    fontWeight: "700",
    paddingTop: 6,
    paddingRight: 22,
    paddingBottom: 6,
    paddingLeft: 22,
    borderRadius: 25,
  },
  basicPlanButton: {
    backgroundColor: bgColors.blue3,
    color: bgColors.blue2,
    fontWeight: "700",
    paddingTop: 6,
    paddingRight: 22,
    paddingBottom: 6,
    paddingLeft: 22,
    borderRadius: 25,
  },
  proPlanButton: {
    backgroundColor: bgColors.yellow2,
    color: bgColors.yellow,
    fontWeight: "700",
    paddingTop: 6,
    paddingRight: 22,
    paddingBottom: 6,
    paddingLeft: 22,
    borderRadius: 25,
  },
  enterPricePlanButton: {
    backgroundColor: bgColors.red2,
    color: bgColors.red1,
    fontWeight: "700",
    paddingTop: 6,
    paddingRight: 22,
    paddingBottom: 6,
    paddingLeft: 22,
    borderRadius: 25,
  },
  cardContainer: {
    backgroundColor: bgColors.white1,
    width: "96%",
    height: "100px",
    borderRadius: "14px",
    display: "flex",
    flexDirection: "row",
    cursor: "pointer",
    alignItems: "center",
  },
  textContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  maringAuto: {
    marginLeft: "auto",
  },
});

const ManageClients = () => {
  const classes = useStyles();
  const history = useNavigate();
  const dispatch = useAppDispatch();

  const clientResponse = useAppSelector((state: any) => state.getAllClients);
  const clients = clientResponse?.data;

  // console.log(clients)
  const getuserPermissionData = useAppSelector(
    (state: any) => state.getUserPermissions?.data
  );
  const accountData = useAppSelector((state: any) => state?.accountData?.data);
  const currentPlanDetails = useAppSelector(
    (state: any) => state?.wallet?.walletAndSubscription?.data
  );
  const loginData = useAppSelector((state: any) => state.adminLogin.data);
  const companyId = loginData?.companyId;
  // console.log(companyId)

  const manageClientObject = getuserPermissionData?.profile?.find((item: any) =>
    Object?.prototype?.hasOwnProperty?.call(item, "manageClients")
  );
  const manageClientActions = manageClientObject
    ? manageClientObject?.manageClients
    : [];
  const hasClientPermission = hasManageClientsPermission(
    getuserPermissionData?.profile
  );

  const debouncedFetchClients = useDebouncedFetch(fetchGetAllClients, 1500);

  const [openDialog, setOpenDialog] = useState(false);
  // const [clientDetails, setClientDetails] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  // console.log(searchQuery, "searchQuery");
  const [addClientTooltipOpen, setAddClientTooltipOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const paginatedClients =
    clients?.length > 0
      ? clients
          ?.filter(
            (client: any) => client?.status === true && client?.id !== companyId
          )
          ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      : [];

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenDialog = () => {
    const hasPermission = hasAcess("addClient");
    if (hasPermission) {
      setOpenDialog(true);
    } else {
      setAddClientTooltipOpen(true);
      setTimeout(() => {
        setAddClientTooltipOpen(false);
      }, 2000);
    }
  };

  // const handleSearchQuery = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   // console.log(event)
  //   setSearchQuery(event.target.value);
  // };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    const data = {
      search: searchQuery,
      includeInactive: true,
      sortBy: "",
      isSortAscending: true,
    };

    debouncedFetchClients(data);
  }, [dispatch, searchQuery]);

  const handleClientClick = (client: any) => {
    // setClientDetails(client);
    history(`/profile/manage-clients/${client.id}`, {
      state: { clientDetails: client },
    });
  };

  const hasAcess = (permission: any) => {
    if (manageClientActions?.includes(permission)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      {currentPlanDetails?.subscriptionPlan?.isActive &&
      accountData?.isMetaEnabled &&
      hasClientPermission ? (
        <Grid
          className={classes.mainContainer}
          // sx={{ height: clientResponse?.status === "loading" ? "100%" : "auto" }}
        >
          <Box className={classes.bgContainer}>
            <Box className={classes.manageTeamContainer}>
              {/* <Box m={4}> */}
              <Typography
                variant="h6"
                sx={{ my: 3, ml: 3, width: "280px", display: "flex" }}
                className={classes.blackColor}
              >
                <span style={{ marginTop: "5px" }}>
                  <PeopleIcon sx={{ height: "22px" }} />
                </span>
                &nbsp;Manage Clients
              </Typography>
              {/* </Box> */}
              <TextField
                className={classes.searchField}
                variant="standard"
                size="small"
                value={searchQuery}
                fullWidth
                // onChange={handleSearchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                InputProps={{
                  disableUnderline: true,
                  style: {
                    padding: "10px",
                    fontSize: "12px",
                    fontWeight: "600 !important",
                    height: "38px",
                    // border: '1px solid gray',
                    borderRadius: "14px",
                  },
                  startAdornment: (
                    <IconButton
                      sx={{
                        p: 0,
                        color: "inherit",
                        paddingTop: "3px",
                      }}
                      aria-label="search"
                    >
                      {/* <SearchIconSvg /> */}
                      <SearchIconSvg2 />
                    </IconButton>
                  ),
                }}
                inputProps={{
                  style: {
                    // Additional style for placeholder
                    fontWeight: "600 !important",
                    padding: "0px",
                  },
                }}
                placeholder="Search clients"
              />
              <Tooltip
                open={addClientTooltipOpen}
                onClose={() => setAddClientTooltipOpen(false)}
                title="Access Denied"
                placement="top"
              >
                <Box
                  style={{ marginLeft: "auto" }}
                  m={3}
                  onClick={handleOpenDialog}
                >
                  <button
                    className={classes.SaveChangesButton}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    style={{
                      // backgroundColor:"#fff",
                      backgroundColor: isHovered
                        ? "rgba(68, 71, 70, 0.08)"
                        : "#fff",
                      cursor: isHovered ? "pointer" : "default", // Optional: change cursor style on hover
                      // Add other styles if needed
                    }}
                  >
                    + Add Client
                  </button>
                </Box>
              </Tooltip>
              <AddNewCompany
                open={openDialog}
                handleClose={handleCloseDialog}
              />
            </Box>
            {clientResponse?.status === "loading" ? (
              <Box sx={{ height: "100%" }}>
                <LoadingComponent height="90vh" color={bgColors.blue} />
              </Box>
            ) : (
              <>
                {paginatedClients?.length > 0 ? (
                  paginatedClients?.map((client: any) => (
                    <Box
                      key={client.id}
                      mx="auto"
                      mt={2}
                      className={classes.cardContainer}
                      onClick={() => handleClientClick(client)}
                    >
                      <Box p={2} sx={{ maxWidth: 100 }}>
                        <img
                          style={{ width: "60px", height: "60px" }}
                          src={
                            client?.companyLogoLink || "/images/companyLogo.png"
                          }
                          alt={`company Logo`}
                        />
                      </Box>
                      <Box>
                        <Box className={classes.textContainer}>
                          <Typography
                            variant="body2"
                            className={classes.blackColor}
                          >
                            {client?.businessName}
                          </Typography>
                          <Box>
                            <Box ml={3}>
                              <button
                                className={
                                  client.currentPlan === "INTRO PLAN"
                                    ? classes.planButton
                                    : client.currentPlan === "Basic"
                                    ? classes.basicPlanButton
                                    : client.currentPlan === "PRO PLAN"
                                    ? classes.proPlanButton
                                    : classes.enterPricePlanButton
                                }
                              >
                                {/* INTRO PLAN */}
                                {client.currentPlan
                                  ? client.currentPlan.toUpperCase()
                                  : "INTRO PLAN"}
                              </button>
                            </Box>
                          </Box>
                        </Box>
                        <Box>
                          <Typography variant="body2" mt={1} color="#303030">
                            {client?.companyAddress}
                          </Typography>
                        </Box>
                      </Box>

                      <Box mr={2} style={{ marginLeft: "auto" }}>
                        <Typography
                          variant="body2"
                          mt={1}
                          color="#303030"
                          textAlign="right"
                        >
                          {client?.countryName}
                        </Typography>
                        <AvatarGroup max={4}>
                          {client?.users?.length &&
                            client?.users
                              ?.filter((item: any) => item?.status === true)
                              ?.map((avatar: any, index: any) => (
                                <Avatar
                                  key={index}
                                  alt={`Avatar ${index + 1}`}
                                  src={
                                    avatar?.image
                                      ? avatar?.image
                                      : "/images/profile.png"
                                  }
                                />
                              ))}
                        </AvatarGroup>
                      </Box>
                    </Box>
                  ))
                ) : (
                  <div
                    style={{
                      flex: "1",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Box
                      sx={{
                        flex: "1",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      No Data Found
                    </Box>
                  </div>
                )}
              </>
            )}
          </Box>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={
              clients?.filter(
                (client: any) =>
                  client?.status === true && client?.id !== companyId
              ).length || 0
            }
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Grid>
      ) : (
        <NoAccessPage />
      )}
    </>
  );
};

export default ManageClients;
