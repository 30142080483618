import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Grid, Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Template from "../../assets/svgs/TemplateSvg";
import { useAppSelector } from "../../utils/redux-hooks";
import LocalLibraryOutlinedIcon from "@mui/icons-material/LocalLibraryOutlined";
import { TbTemplate } from "react-icons/tb";
import {
  checkAllTemplatesPermission,
  checkTemplatesLibraryPermission,
} from "../../utils/permissions";

const useStyles = makeStyles({
  container: {
    backgroundColor: "#F8F8F8",
    height: "100vh",
    textAlign: "center",
  },
  sideBarContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
    alignItems: "center",
  },
  manageItem: {
    cursor: "pointer",
    padding: "5px",
  },
  activeItem: {
    backgroundColor: "#f0f0f0",
    color: "#303030 !important",
    "& .MuiTypography-root": {
      fontWeight: "600 !important",
    },
  },
  signOutContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  textColor: {
    color: "#4B5A5A",
    fontSize: "20px",
  },
  blackColor: {
    color: "#000000",
    fontWeight: "600 !important",
  },
  sideBar: {
    position: "sticky",
    top: 0,
    height: "100vh",
    overflowY: "auto",
  },
});

const TemplateSidebar = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  const getuserPermissionSlice = useAppSelector(
    (state: any) => state.getUserPermissions
  );
  const getUserPermissionData = getuserPermissionSlice?.data;

  const [activeSection, setActiveSection] = useState("");

  const hasTemplatesLibraryPermission = checkTemplatesLibraryPermission(
    getUserPermissionData?.Templates
  );
  const hasAllTemplatesPermission = checkAllTemplatesPermission(
    getUserPermissionData?.Templates
  );
  // Function to handle navigation and set active section
  const handleNavigation = (path: string) => {
    navigate(`/templates-${path}`);
    setActiveSection(path);
  };

  useEffect(() => {
    const path = location.pathname;
    setActiveSection(path || "");
  }, [location.pathname]);

  return (
    <Grid className={classes.container}>
      <Box textAlign="start" pl={1} className={classes.sideBarContainer} pt={3}>
        <Box m={2} mb={1}>
          <Template />
        </Box>
        <Typography variant="h6" className={classes.blackColor}>
          Templates
        </Typography>
      </Box>

      <Box>
        {hasTemplatesLibraryPermission && (
          <Box
            m={2}
            pl={1}
            textAlign="start"
            className={`${classes.manageItem} ${classes.textColor} ${
              activeSection.includes("library") ? classes.activeItem : ""
            }`}
            onClick={() => handleNavigation("library")}
          >
            <Typography
              fontSize="14px"
              sx={{ display: "flex", alignItems: "center" }}
            >
              <span style={{ paddingTop: "2px" }}>
                <LocalLibraryOutlinedIcon
                  sx={{ marginTop: "5x", height: "18px" }}
                />
              </span>
              &nbsp;Library
            </Typography>
          </Box>
        )}
        {hasAllTemplatesPermission && (
          <Box
            m={2}
            pl={1}
            textAlign="start"
            className={`${classes.manageItem} ${classes.textColor} ${
              activeSection.includes("all") ? classes.activeItem : ""
            }`}
            onClick={() => handleNavigation("all")}
          >
            <Typography
              fontSize="14px"
              sx={{ display: "flex", alignItems: "center" }}
            >
              <span style={{ marginTop: "2px", height: "20px" }}>
                <TbTemplate />
              </span>
              &nbsp;All Templates
            </Typography>
          </Box>
        )}
      </Box>
    </Grid>
  );
};

export default TemplateSidebar;
