import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Typography,
  InputLabel,
  Grid,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import CloseSvg from "../../assets/svgs/CloseSvg";
import { makeStyles } from "@mui/styles";
import TextFeildWithBorderComponet from "../common/TextFieldWithBorderComponent";
import PhoneInput from "../common/PhoneInput";
import { useAppDispatch, useAppSelector } from "../../utils/redux-hooks";
// import { fetchAllRoles } from "../../redux/slices/ManageCompany/DisplayAllRoles";
// import { fetchAllCountryCodes } from "../../redux/slices/ManageAccount/GetAllCountryCode";
import { ADMIN_MANAGE_COMPANY_APIS } from "../../Apis/ManageCompany/ManageCompany";
import { toastActions } from "../../utils/toastSlice";
// import { fetchGetAllTeamMembers } from "../../redux/slices/ManageCompany/GetAllTeamMembers";
import { fetchGetAllTeamMembersByCompanyId } from "../../redux/slices/ManageCompany/GetAllTeamMembersByCompanyId";
import LoadingComponent from "../common/LoadingComponent";
import { bgColors } from "../../utils/bgColors";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { validatePhoneNumber } from "../../utils/functions";
const ALPHA_REGEX = /^[a-zA-Z\s]*$/;
const useStyles = makeStyles({
  changePasswordContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    cursor: "pointer",
  },
  updateButtonStyles: {
    backgroundColor: "#3C3C3C",
    color: "#ffffff",
    height: "40px",
    borderRadius: "8px",
    width: "100%",
    cursor: "pointer",
  },
  blackColor: {
    color: "#303030 !important",
    fontWeight: "500 !important",
  },
});

const AddNewTeamMember = ({ open, handleClose }: any) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const getAllRoles = useAppSelector((state:any) => state.getAllRoles);
  const getAllCountryCodes = useAppSelector((state:any) => state.getAllCoutryCodes);
  const loginData = useAppSelector((state:any) => state?.adminLogin?.data);
  const getAllRolesData = getAllRoles?.data;
  const getAllCountryCodesData = getAllCountryCodes?.data;
  const codeArray =
    getAllCountryCodesData &&
    getAllCountryCodesData?.map((item: any) => ({
      countryCode: item?.countryCode,
      countryImage: item?.countryImage,
    }));
  const [isAddNewTeamMemberLoading, setIsAddNewTeamMemberLoading] =
    useState(false);

  // const [editedData, setEditedData] = useState<any>({});
  const [errors, setErrors] = useState<any>({});
  // console.log("getAllCountryCodesData", getAllCountryCodes);
  // Local state for form fields and errors
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    countryCode: "+91",
    phoneNumber: "",
    role: "",
  });
  // console.log(formData)
  const [formErrors, setFormErrors] = useState({
    fullName: "",
    email: "",
    countryCode: "",
    phoneNumber: "",
    role: "",
  });

  const handlePhoneChange = (value: string) => {
    setFormData({ ...formData, phoneNumber: value });
    setErrors({ ...errors, phoneNumber: "" });
    setFormErrors({ ...formErrors, phoneNumber: "" });
  };
  const handleCountryCodeChange = (value: string) => {
    // console.log("value", value);
    setFormData({ ...formData, countryCode: value });
    setFormErrors({ ...formErrors, phoneNumber: "" });
  };

  // const handleInputChange = (e: any) => {
  //   const { name, value } = e.target;
  //   setFormData({
  //     ...formData,
  //     [name]: value,
  //   });
  //   // Clear the error message when user starts typing
  //   setFormErrors({
  //     ...formErrors,
  //     [name]: "",
  //   });
  // };
  const handleInputChange = (event: any) => {
    const { name, value } = event.target;

    // Validate fullName to allow only alphabets and spaces
    if (name === "fullName" && !ALPHA_REGEX.test(value)) {
      return;
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setFormErrors((prevData) => ({
      ...prevData,
      [name]: "",
    }));
  };

  // console.log("phoneNumber", formData.phoneNumber);
  const isFormValid = () => {
    const errors = { ...formErrors };
    let isValid = true;

    if (!formData?.fullName.trim()) {
      errors.fullName = "Full Name is required.";
      isValid = false;
    } else if (formData?.fullName.length > 75) {
      errors.fullName = "Full Name cannot be more than 75 characters.";
      isValid = false;
    }

    // if (!formData?.countryCode) {
    //   errors.countryCode = "Country code is required";
    //   isValid = false;
    // }
    if (!formData.email) {
      errors.email = "Email address is required.";
      isValid = false;
    } else if (
      /\^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(formData.email)
    ) {
      errors.email = "Invalid email address.";
      isValid = false;
    }
    // console.log(formData.phoneNumber, formData?.countryCode)
    const isValidPhone = /^\d+$/.test(formData?.phoneNumber);
    if (!formData?.phoneNumber && !formData?.countryCode) {
      errors.phoneNumber = "Phone Number & Country Code are required.";
      isValid = false;
    } else if (!formData?.phoneNumber && formData?.countryCode) {
      errors.phoneNumber = "phone number is required.";
      isValid = false;
    } else if (
      !validatePhoneNumber(formData?.phoneNumber, formData?.countryCode)
    ) {
      errors.phoneNumber = "This phone number format is not recognized. Please check the country code and phone number.";
      isValid = false;
    } else if (!formData?.countryCode && formData?.phoneNumber) {
      errors.phoneNumber = "Country code is required.";
      isValid = false;
    } else if (!isValidPhone) {
      errors.phoneNumber = "Phone number should only contain numbers.";
      isValid = false;
    }
    // else if (formData?.phoneNumber && formData?.countryCode) {
    //   isValid = true;
    // }

    if (
      formData?.email &&
      !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(formData.email)
    ) {
      errors.email = "Invalid email address.";
      isValid = false;
    }

    if (!formData?.role) {
      errors.role = "Role is required.";
      isValid = false;
    }
    // if (!formData?.countryName) {
    //     errors.countryName = "Country name is required";
    //     isValid = false;
    // }

    setFormErrors(errors);
    return isValid;
  };

  const handleSave = async () => {
    // console.log(isFormValid())
    if (isFormValid()) {
      // If there are errors, update the state with error messages
      // setFormErrors(newErrors);
      // setFormData({
      //   fullName: "",
      //   email: "",
      //   phoneNumber: "",
      //   countryCode: "",
      //   role: "",
      // });
      setIsAddNewTeamMemberLoading(true);
      try {
        // const mobileNumber =
        //   formData.phoneNumber.slice(0, 3) + formData.phoneNumber.slice(3);
        const response = await ADMIN_MANAGE_COMPANY_APIS.addTeamMember(
          formData
        );
        // console.log("response", response);
        if (response.status === 200) {
          dispatch(
            toastActions.setToaster({
              type: "success",
              message: response?.data?.message,
            })
          );
          const postData = {
            search: "",
            companyId: loginData?.companyId,
          };
          dispatch(fetchGetAllTeamMembersByCompanyId(postData));
          setFormData({
            fullName: "",
            email: "",
            phoneNumber: "",
            countryCode: "+91",
            role: "",
          });
          handleClose();
        }
        // window.location.reload();
        else {
          // console.log("error", response);
          dispatch(
            toastActions.setToaster({
              type: "error",
              message: response?.data?.message,
            })
          );
          setFormData({
            fullName: "",
            email: "",
            phoneNumber: "",
            countryCode: "+91",
            role: "",
          });
          // handleClose();
        }
      } catch (err: any) {
        // console.log("err", err);
        dispatch(
          toastActions.setToaster({
            type: "error",
            message:
              err?.response?.data?.message ||
              err?.response?.data?.errors?.Mobile[0],
          })
        );
        setFormData({
          fullName: "",
          email: "",
          phoneNumber: "",
          countryCode: "+91",
          role: "",
        });
        handleClose();
      }
      setIsAddNewTeamMemberLoading(false);
    }
  };

  // useEffect(() => {
  //   dispatch(fetchAllRoles(loginData?.companyId));
  //   dispatch(fetchAllCountryCodes());
  // }, [dispatch]);

  // console.log("formData", formData, getRoles);

  const handleCloseAll = () => {
    handleClose();
    setFormData({
      fullName: "",
      email: "",
      phoneNumber: "",
      countryCode: "+91",
      role: "",
    });
    setFormErrors({
      fullName: "",
      email: "",
      phoneNumber: "",
      countryCode: "+91",
      role: "",
    });
  };

  return (
    <Dialog
      open={open}
      onClose={handleCloseAll}
      PaperProps={{ style: { minWidth: "35%", borderRadius: "20px" } }}
    >
      <DialogTitle>
        <Box m={2} mb={0} className={classes.changePasswordContainer}>
          <Typography variant="h6" sx={{ display: "flex" }}>
            {" "}
            <span style={{ marginTop: "3px" }}>
              <PersonAddIcon sx={{ height: "22px" }} />
            </span>
            &nbsp;Add Team Member
          </Typography>
          <Box onClick={handleCloseAll}>
            <CloseSvg />
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box m={2} mt={0}>
          <Box mb={2}>
            <InputLabel className={classes.blackColor}>Full name*</InputLabel>
            <TextFeildWithBorderComponet
              label="Enter your full name"
              name="fullName"
              placeholder=""
              value={formData.fullName}
              onChange={handleInputChange}
              error={!!formErrors.fullName}
              helperText={formErrors.fullName}
              sx={{
                "& input": {
                  // fontWeight: "600"
                },
              }}
            />
          </Box>
          <Box mb={2}>
            <InputLabel className={classes.blackColor}>Email*</InputLabel>
            <TextFeildWithBorderComponet
              label="Enter your email address"
              name="email"
              placeholder=""
              value={formData.email}
              onChange={handleInputChange}
              error={!!formErrors.email}
              helperText={formErrors.email}
              sx={{
                "& input": {
                  // fontWeight: "600"
                },
              }}
            />
          </Box>
          <Box mb={3}>
            <InputLabel className={classes.blackColor}>
              Phone Number*
            </InputLabel>
            <Grid item xs={12} mt={1}>
              <PhoneInput
                width="130px"
                gapRequired="48px"
                phoneNumWidth="270px"
                countryCodeArray={codeArray && codeArray}
                name="phoneNumber"
                countryCode={formData.countryCode || "+91"}
                onCountryCodeChange={handleCountryCodeChange}
                propsValue={formData.phoneNumber || ""}
                onChange={handlePhoneChange}
                error={!!formErrors.phoneNumber}
                // helperText={formErrors.phoneNumber}
              />
            </Grid>
            {formErrors.phoneNumber && (
              <Typography color="#d32f2f" sx={{ fontSize: "0.75rem" }}>
                {formErrors.phoneNumber}
              </Typography>
            )}
          </Box>

          <Box mb={2}>
            <InputLabel
              className={classes.blackColor}
              style={{ marginBottom: 5 }}
            >
              Role*
            </InputLabel>
            <FormControl fullWidth size="small" error={!!formErrors.role}>
              <InputLabel
                // id="demo-simple-select-label"
                id="demo-simple-select-error-label"
                sx={{ fontSize: { xs: 12, md: 14 } }}
              >
                Select role for member
              </InputLabel>
              <Select
                // labelId="demo-simple-select-label"
                // id="demo-simple-select"
                labelId="demo-simple-select-error-label"
                id="demo-simple-select-error"
                name="role"
                size="small"
                value={formData.role}
                onChange={handleInputChange}
                error={!!formErrors.role}
                label="Select role for member"
                // inputlabelp
                inputProps={{
                  style: {
                    fontSize: "14px",
                    // fontWeight: "600"
                  },
                }}
                sx={{
                  fontSize: "14px",
                  borderRadius: "8px",
                  "& input": {
                    // fontWeight: "600"
                  },
                  "& .MuiList-root .MuiButtonBase-root": {
                    // fontWeight: "600"
                  },
                  "& .MuiSelect-select": {
                    // fontWeight: "600"
                  },
                }}
              >
                {getAllRolesData &&
                  getAllRolesData
                    ?.filter((item: any) => item?.name !== "Owner")
                    ?.map((item: any) => (
                      <MenuItem
                        key={item.id}
                        value={item.name}
                        sx={{ fontSize: 14 }}
                      >
                        {item?.name}
                      </MenuItem>
                    ))}
              </Select>
              {formErrors.role && (
                <FormHelperText>{formErrors.role}</FormHelperText>
              )}
            </FormControl>
            {/* {formErrors.role && (
              <Typography variant="caption" color="error">
                {formErrors.role}
              </Typography>
            )} */}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box width="100%" ml={4} mr={4} mb={4}>
          {isAddNewTeamMemberLoading ? (
            <LoadingComponent height="auto" color={bgColors?.blue} />
          ) : (
            <button onClick={handleSave} className={classes.updateButtonStyles}>
              Add Member
            </button>
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default AddNewTeamMember;
