import React from "react";
import { Box, TextField } from "@mui/material";

const InputPair = ({ input, onChange, index }: any) => {
  // console.log(input);

  return (
    <Box display="flex" gap={2} mb={2}>
      <TextField
        label="Input"
        value={input.title}
        onChange={(e) => onChange(index, "title", e.target.value)}
        fullWidth
        size="small"
        sx={{
          width: "50%",
          height: "40px",
          borderRadius: "10px",
          "& .MuiOutlinedInput-root": {
            borderRadius: "10px",
          },
        }}
      />
      <TextField
        label="Description"
        value={input.description}
        onChange={(e) => onChange(index, "description", e.target.value)}
        fullWidth
        size="small"
        sx={{
          height: "40px",
          borderRadius: "10px",
          "& .MuiOutlinedInput-root": {
            borderRadius: "10px",
          },
        }}
      />
    </Box>
  );
};

export default InputPair;
