import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { TEMPLATE_APIS } from "../../../Apis/Template/TemplateApis";

export interface IData {
  status: "loading" | "succeeded" | "failed" | "idle";
  data: any;
}

const initialState: IData = {
  status: "idle",
  data: null,
};

export const fetchTemplateName = createAsyncThunk(
  "fetchTemplateName",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await TEMPLATE_APIS.checkTemplateName(data);
      return response?.data;
    } catch (error: any) {
      return rejectWithValue(
        error?.response?.data?.message || "An error occurred"
      );
    }
  }
);

export const templateNameSlice = createSlice({
  name: "templateNameSlice",
  initialState,
  reducers: {
    // setData: (state, action) => {
    //   state.data = action.payload;
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTemplateName.pending, (state) => {
        state.status = "loading";
        state.data = [];
      })
      .addCase(fetchTemplateName.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(fetchTemplateName.rejected, (state, action) => {
        state.status = "failed";
        state.data = action.payload;
      });
  },
});

export const GetTemplateNameActions = templateNameSlice.actions;
export default templateNameSlice.reducer;
