import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { AUTOMATION_API } from "../../../Apis/AdminLogin/Automation/Automation";

export interface IData {
    addVariableNameStatus: "loading" | "succeeded" | "failed" | "idle";
    addVariableNameData: any;
  }

  const initialState: IData = {
    addVariableNameStatus: "idle",
    addVariableNameData: null,
  };

  export const addVariableName = createAsyncThunk(
    "addVariableName",
    async (data: any, { rejectWithValue }) => {
      try {
        const response = await AUTOMATION_API.addVariableName(data);
        return response?.data;
      } catch (error: any) {
        // Provide a descriptive error message
        return rejectWithValue(error.response || 'An error occurred');
      }
    }
  );


  export const addVariableNameSlice = createSlice({
    name: "addVariableName",
    initialState,
    reducers: {
       
    },
    extraReducers: (builder) => {
      builder
        .addCase(addVariableName.pending, (state) => {
          state.addVariableNameStatus = "loading";
          state.addVariableNameData = null;
        })
        .addCase(addVariableName.fulfilled, (state, action) => {
          state.addVariableNameStatus = "succeeded";
          state.addVariableNameData = action.payload;
        })
        .addCase(addVariableName.rejected, (state) => {
          state.addVariableNameStatus = "failed";
        });
    },
  });


export const addVariableNamesActions = addVariableNameSlice.actions;
export default addVariableNameSlice.reducer;
