
// import { Popover, List, ListItem, ListItemText} from "@mui/material";

// const CampaignFilterPopOvers = ({ anchorEl, handleClose ,options,handleOptionClick}:any) => {

//   return (
//     <Popover
//       open={Boolean(anchorEl)}
//       anchorEl={anchorEl}
//       onClose={handleClose}
//       anchorOrigin={{
//         vertical: "bottom",
//         horizontal: "right",
//       }}
//       transformOrigin={{
//         vertical: "top",
//         horizontal: "right",
//       }}
//     >
//       <List>
//         {options.map((option:{id:number,option:string}) => (
//           <ListItem key={option.id} onClick={() => handleOptionClick(option.option)} style={{ cursor: "pointer"}}>
//             <ListItemText primary={option.option} sx={{
//               color:"green",
//               padding:"-80px"
//             }}/>
//           </ListItem>
//         ))}
//       </List>
//     </Popover>
//   );
// };

// export default CampaignFilterPopOvers;
import { Popover, List, ListItem, ListItemText, ListItemButton, ListItemIcon } from "@mui/material";
import EventIcon from '@mui/icons-material/Event';
import ChecklistRtlIcon from '@mui/icons-material/ChecklistRtl';

const CampaignFilterPopOvers = ({ anchorEl, handleClose, options, handleOptionClick }: any) => {
  // console.log(options)
  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <List sx={{ padding: 0, margin: 0 }}>
        {options.map((option: { id: number, option: string }) => (
          <ListItem
            key={option.id}
            onClick={() => handleOptionClick(option.option)}
            sx={{
              cursor: "pointer",
              padding: "0",
              margin: "0",
              height: "30px",
              fontSize: "14px",
            }}
          >
            <ListItemButton sx={{
              cursor: "pointer",
              padding: "0",
              margin: "0",
              height: "30px",
              fontSize: "14px",
            }}>
              {option.option === "All" ? (
                <ListItemIcon
                  sx={{
                    cursor: "pointer",
                    padding: "0",
                    margin: "0",
                    width: "20px",
                    height: "20px",
                    transform: "scale(0.7)",
                    marginLeft: "2px"
                  }}
                >
                  <ChecklistRtlIcon />
                </ListItemIcon>
              ) : (
                <ListItemIcon
                  sx={{
                    cursor: "pointer",
                    padding: "0",
                    margin: "0",
                    width: "20px",
                    height: "20px",
                    transform: "scale(0.7)",
                    marginLeft: "2px"
                  }}
                >
                  <EventIcon />
                </ListItemIcon>
              )}
              <ListItemText
                primary={option.option}
                sx={{
                  cursor: "pointer",
                  padding: "0",
                  margin: "0",
                  height: "30px",
                  transform: "scale(0.8)",
                  marginLeft: "-30px",
                  paddingTop: "3px",
                  minWidth: 0,  // Ensure text doesn't create extra space
                }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Popover>
  );
};

export default CampaignFilterPopOvers;


