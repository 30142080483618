import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { WORKFLOW_API } from "../../../Apis/AdminLogin/Automation/Workflows";

export interface IData {
  createWorkflowStatus: "loading" | "succeeded" | "failed" | "idle";
  createWorkflowData: any;
}

const initialState: IData = {
  createWorkflowStatus: "idle",
  createWorkflowData: null,
};

export const createWorkflow = createAsyncThunk(
  "workflow/createWorkflow",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await WORKFLOW_API.createWorkflow(data);
      return response?.data;
    } catch (error: any) {
      // Provide a descriptive error message
      return rejectWithValue(error.response || "An error occurred");
    }
  }
) as any;

export const createWorkflowSlice = createSlice({
  name: "createWorkflow",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createWorkflow.pending, (state) => {
        state.createWorkflowStatus = "loading";
        state.createWorkflowData = null;
      })
      .addCase(createWorkflow.fulfilled, (state, action) => {
        state.createWorkflowStatus = "succeeded";
        state.createWorkflowData = action.payload;
      })
      .addCase(createWorkflow.rejected, (state) => {
        state.createWorkflowStatus = "failed";
      });
  },
});

export const createWorkflowActions = createWorkflowSlice.actions;
export default createWorkflowSlice.reducer;
