import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { bgColors } from "../../utils/bgColors";
import EditIconSvg from "../../assets/svgs/EditIconSvg";
import DeleteIconSvg from "../../assets/svgs/DeleteIconSvg";
import AddAutoReplyPopup from "./AddAutoReply";
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from "../../utils/redux-hooks";
import { getAutoReply } from "../../redux/slices/Automation/getAutoReplySlice";
import { useSelector } from "react-redux";
import LoadingComponent from "../../components/common/LoadingComponent";
import { toastActions } from "../../utils/toastSlice";
import { deleteAutoReply } from "../../redux/slices/Automation/deleteAutoReplySlice";
import DeletePopUp from "../../components/common/DeletePopup";
import { parseTextToHtml } from "../../utils/functions";
import { checkAutoReplyPermission } from "../../utils/permissions";
import NoAccessPage from "../../components/common/NoAccess";

const useStyles = makeStyles({
  mainContainer: {
    backgroundColor: bgColors.white,
    borderRadius: "25px",
    marginTop: "20px",
    width: "100%",
  },
  chatArea: {
    padding: "20px",
  },
  bgContainer: {
    backgroundColor: bgColors.white,
    borderRadius: "25px",
    height: "100%",
    width: "100%",
  },
  manageContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  blackColor: {
    color: `${bgColors.black1} !important`,
  },
  SaveChangesButton: {
    color: bgColors.green,
    border: `1px solid ${bgColors.green}`,
    borderRadius: "8px",
    width: "140px",
    height: "32px",
    cursor: "pointer",
  },
  textColor: {
    color: bgColors.gray1,
    fontSize: "16px",
  },
  table: {
    borderCollapse: "separate",
    borderSpacing: "0",
    textAlign: "center",
    borderColor: "lightgray",
    "& th, & td": {
      borderBottom: "1px solid #f0f0f0",
      padding: "4px",
    },
    "& th:first-child, & td:first-child": {
      borderLeft: "none",
    },
    "& th:last-child, & td:last-child": {
      borderRight: "none",
    },
  },
  grayColor: {
    color: "#303030",
    opacity: "60%",
    fontSize: "13px",
  },
  editButtonContainer: {
    border: "2px solid #DBDBDB",
    padding: "8px",
    borderRadius: "12px",
    backgroundColor: "#F4F4F4",
    width: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  row: {
    transition: "box-shadow 0.3s",
  },
  rowHovered: {
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
  },
});

const AutoReply = () => {
  const classes = useStyles();
  const userProfileSlice = useAppSelector((state: any) => state?.adminLogin);
  const dispatch = useAppDispatch();
  const { status, data } = useSelector((state: any) => state.getAutoReply);
  const userData = userProfileSlice?.data;
  const getuserPermissionSlice = useAppSelector(
    (state: any) => state.getUserPermissions
  );
  const getuserPermissionData = getuserPermissionSlice?.data;
  const automationPermissionsArray = getuserPermissionData?.automation;
  const autoReplyObject = automationPermissionsArray?.find((item: any) =>
    Object.prototype.hasOwnProperty.call(item, "autoReply")
  );
  const autoReplyActions = autoReplyObject ? autoReplyObject.autoReply : [];
  const [isHovered, setIsHovered] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [editingItem, setEditingItem] = useState(null);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const [deletingItemId, setDeletingItemId] = useState(null);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [addAutoReplyTooltip, setAddAutoReplyTooltip] = useState(false);
  const [editAutoReplyTooltip, setEditAutoReplyTooltip] = useState("");
  const [deleteAutoReplyTooltip, setDeleteAutoReplyTooltip] = useState("");

  const hasAutoReplyPermission = checkAutoReplyPermission(
    automationPermissionsArray
  );
  const hasAccess = (permission: any) => {
    if (autoReplyActions?.includes(permission)) {
      return true;
    }
    return false;
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const handleSaveAutoReply = () => {
    // Implement logic to save the new auto reply
    // console.log("New auto reply:", data);
  };

  const handleOpenPopup = (item: any) => {
    if (item !== null) {
      const hasPermission = hasAccess("editAutoReply");
      if (hasPermission) {
        setEditingItem(item);
        setIsPopupOpen(true);
      } else {
        setEditAutoReplyTooltip(item);
        setTimeout(() => {
          setEditAutoReplyTooltip("");
        }, 2000);
      }
    } else {
      const hasPermission = hasAccess("addAutoReply");
      if (hasPermission) {
        setEditingItem(item);
        setIsPopupOpen(true);
      } else {
        setAddAutoReplyTooltip(true);
        setTimeout(() => {
          setAddAutoReplyTooltip(false);
        }, 2000);
      }
    }
  };
  const handleConfirmDelete = async () => {
    if (!deletingItemId) return;

    setIsDeleteLoading(true);
    try {
      const data = {
        companyId: userData?.companyId,
        autoReplyAutomationId: deletingItemId,
      };
      const response: any = await dispatch(deleteAutoReply(data));
      setIsDeleteLoading(false);
      const payload = {
        companyId: userData?.companyId,
      };
      dispatch(getAutoReply(payload));
      if (response.payload.success) {
        dispatch(
          toastActions.setToaster({
            type: "success",
            message:
              response?.payload?.message ||
              "Auto reply custom message deleted successfully",
          })
        );
      } else {
        dispatch(
          toastActions.setToaster({
            type: "error",
            message:
              response?.payload?.message ||
              "Failed to delete auto reply custom message",
          })
        );
      }
    } catch (error: any) {
      console.error("Error deleting auto reply custom message:", error);
      dispatch(
        toastActions.setToaster({
          type: "error",
          message:
            error?.payload?.message ||
            "Failed to delete auto reply custom message",
        })
      );
    } finally {
      setIsDeletePopupOpen(false);
      setDeletingItemId(null);
      setIsDeleteLoading(false);
    }
  };
  const handleDelete = (autoReplyAutomationId: any) => {
    const hasPermission = hasAccess("deleteAutoReply");
    if (hasPermission) {
      setDeletingItemId(autoReplyAutomationId);
      setIsDeletePopupOpen(true);
    } else {
      setDeleteAutoReplyTooltip(autoReplyAutomationId);
      setTimeout(() => {
        setDeleteAutoReplyTooltip("");
      }, 2000);
    }
  };

  const MAX_DISPLAY_LENGTH = 50;

  const truncateText = (text: any, maxLength: any) => {
    // Remove all HTML tags and normalize whitespace
    const plainText = text
      ?.replace(/<[^>]+>/g, "")
      ?.replace(/\s+/g, " ")
      ?.trim();
    if (plainText?.length <= maxLength) return plainText;
    return plainText?.substr(0, maxLength) + "...";
  };

  useEffect(() => {
    const payload = {
      companyId: userData?.companyId,
    };
    dispatch(getAutoReply(payload));
  }, [dispatch, userData?.companyId]);

  return (
    <>
      {hasAutoReplyPermission ? (
        <Grid container className={classes.mainContainer}>
          <Grid item className={classes.bgContainer}>
            <Box className={classes.manageContainer}>
              <Box ml={4} mt={2} mb={1}>
                <Typography
                  variant="h6"
                  className={classes.blackColor}
                  sx={{ fontWeight: "600" }}
                >
                  Custom Auto Replies
                </Typography>
              </Box>
              <Tooltip
                title="Access Denied"
                placement="top"
                open={addAutoReplyTooltip}
                onClose={() => setAddAutoReplyTooltip(false)}
              >
                <Box
                  sx={{ marginLeft: "auto" }}
                  mt={4}
                  mr={3}
                  onClick={() => handleOpenPopup(null)}
                >
                  <button
                    className={classes.SaveChangesButton}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    style={{
                      backgroundColor: isHovered
                        ? "rgba(68, 71, 70, 0.08)"
                        : "#fff",
                      cursor: isHovered ? "pointer" : "default",
                    }}
                  >
                    + Add Auto Reply
                  </button>
                </Box>
              </Tooltip>
              <AddAutoReplyPopup
                open={isPopupOpen}
                onClose={handleClosePopup}
                onSave={handleSaveAutoReply}
                companyId={userData?.companyId}
                userId={userData?.userId}
                editData={editingItem}
              />
            </Box>
            <Box ml={4}>
              <Typography variant="body2" className={classes.textColor}>
                This section allows users to create and manage Custom Auto
                Replies in response to customer chats.
              </Typography>
            </Box>
            <TableContainer
              component={Box}
              mt={3}
              sx={{
                height: "76vh",
                overflowY: "scroll",
                position: "relative",
                scrollBehavior: "smooth",
                "&::-webkit-scrollbar": {
                  cursor: "pointer",
                  width: "6px",
                },
                "&::-webkit-scrollbar-thumb": {
                  cursor: "pointer",
                  backgroundColor: "#cdcdcd",
                  borderRadius: "8px",
                  transition: "background-color 0.2s ease-in-out",
                },
              }}
            >
              <Table className={classes.table} sx={{ position: "relative" }}>
                <TableHead className={classes.grayColor}>
                  <TableRow>
                    <TableCell>
                      <span style={{ marginLeft: "15px" }}>Input</span>
                    </TableCell>
                    <TableCell>Auto Response Preview</TableCell>
                    <TableCell>Response Type</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                {status === "loading" ? (
                  <Box
                    sx={{
                      position: "absolute",
                      left: "50%",
                      top: "50%",
                      marginTop: "50px",
                    }}
                  >
                    <LoadingComponent height="50px" color={bgColors.blue} />
                  </Box>
                ) : (
                  <TableBody>
                    {data?.length === 0 ? (
                      <TableRow sx={{ height: "60vh" }}>
                        <TableCell
                          colSpan={12}
                          align="center"
                          sx={{
                            width: "100%",
                            border: "none !important",
                          }}
                        >
                          No Data Found
                        </TableCell>
                      </TableRow>
                    ) : (
                      Array.isArray(data) &&
                      data?.map((row: any) => (
                        <TableRow
                          key={row.autoReplyAutomation.id}
                          className={classes.row}
                          onMouseEnter={(e) =>
                            e.currentTarget.classList.add(classes.rowHovered)
                          }
                          onMouseLeave={(e) =>
                            e.currentTarget.classList.remove(classes.rowHovered)
                          }
                        >
                          <TableCell className={classes.blackColor}>
                            <Box sx={{ marginLeft: "15px" }}>
                              {row.autoReplyAutomation.input}
                            </Box>
                          </TableCell>
                          <TableCell className={classes.blackColor}>
                            <Tooltip
                              title={
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: parseTextToHtml(
                                      row.bodyMessage
                                        ? row.bodyMessage
                                        : row?.autoReplyAutomation
                                            ?.workflowName || ""
                                    ),
                                  }}
                                />
                              }
                              placement="top"
                              arrow
                            >
                              <Typography
                                noWrap
                                sx={{
                                  maxWidth: "300px",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                  fontSize: "0.875rem",
                                }}
                              >
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: parseTextToHtml(
                                      truncateText(
                                        row.bodyMessage
                                          ? row.bodyMessage
                                          : row?.autoReplyAutomation
                                              ?.workflowName || "",
                                        MAX_DISPLAY_LENGTH
                                      )
                                    ),
                                  }}
                                />
                              </Typography>
                            </Tooltip>
                          </TableCell>
                          <TableCell className={classes.blackColor}>
                            {row.autoReplyAutomation.automationResponseType ===
                            1
                              ? "Custom Message"
                              : "Workflow"}
                          </TableCell>
                          <TableCell>
                            <Box sx={{ display: "flex", gap: "5px" }}>
                              <Tooltip
                                title={
                                  editAutoReplyTooltip
                                    ? "Access Denied"
                                    : "Edit"
                                }
                                placement="top"
                                // open={editAutoReplyTooltip === row}
                                onClose={() => setEditAutoReplyTooltip("")}
                              >
                                <Box
                                  sx={{ cursor: "pointer" }}
                                  onClick={() => handleOpenPopup(row)}
                                >
                                  <EditIconSvg />
                                </Box>
                              </Tooltip>
                              <Tooltip
                                title={
                                  deleteAutoReplyTooltip
                                    ? "Access Denied"
                                    : "Delete"
                                }
                                placement="top"
                                // open={editAutoReplyTooltip === row}
                                onClose={() => setDeleteAutoReplyTooltip("")}
                              >
                                <Box
                                  sx={{ cursor: "pointer" }}
                                  onClick={() =>
                                    handleDelete(row?.autoReplyAutomation?.id)
                                  }
                                >
                                  <DeleteIconSvg />
                                </Box>
                              </Tooltip>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
            <DeletePopUp
              open={isDeletePopupOpen}
              handleDelete={handleConfirmDelete}
              handleClose={() => setIsDeletePopupOpen(false)}
              title="Auto Reply"
              handleLoad={isDeleteLoading}
            />
          </Grid>
        </Grid>
      ) : (
        <NoAccessPage />
      )}
    </>
  );
};

export default AutoReply;
