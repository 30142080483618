import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { WORKFLOW_API } from "../../../Apis/AdminLogin/Automation/Workflows";

export interface IData {
    updateWorkflowListStatus: "loading" | "succeeded" | "failed" | "idle";
    updateWorkflowListData: any;
}

const initialState: IData = {
    updateWorkflowListStatus: "idle",
    updateWorkflowListData: null,
};

export const updateWorkflowList = createAsyncThunk(
    "autoReply/updateWorkflowList",
    async (data: any, { rejectWithValue }) => {
        try {
            const response = await WORKFLOW_API.updateWorkflowList(data);
            return response?.data;
        } catch (error: any) {
            // Provide a descriptive error message
            return rejectWithValue(error.response || 'An error occurred');
        }
    }
);


export const updateWorkflowListSlice = createSlice({
    name: "updateWorkflow",
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(updateWorkflowList.pending, (state) => {
                state.updateWorkflowListStatus = "loading";
                state.updateWorkflowListData = null;
            })
            .addCase(updateWorkflowList.fulfilled, (state, action) => {
                state.updateWorkflowListStatus = "succeeded";
                state.updateWorkflowListData = action.payload;
            })
            .addCase(updateWorkflowList.rejected, (state) => {
                state.updateWorkflowListStatus = "failed";
            });
    },
});


export const updateWorkflowListSliceActions = updateWorkflowListSlice.actions;
export default updateWorkflowListSlice.reducer;
