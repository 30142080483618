import {
  createAsyncThunk,
  createSlice,
  // isRejectedWithValue,
} from "@reduxjs/toolkit";
import { ANALYTICS_API } from "../../../Apis/Analytics/Analytics";

export interface IData {
  status: "loading" | "succeeded" | "failed" | "idle";
  data: any;
}

const initialState: IData = {
  status: "idle",
  data: null,
};

export const fetchInboxAnalysisByCompanyId = createAsyncThunk(
  "fetchInboxAnalysisByCompanyId",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await ANALYTICS_API.getInboxAnalytics(data);
      return response?.data;
    } catch (error: any) {
      // Provide a descriptive error message
      return rejectWithValue(
        error?.response?.data?.message || "An error occurred"
      );
    }
  }
);

export const inboxAnalysysSlice = createSlice({
  name: "inboxAnalysysSlice",
  initialState,
  reducers: {
    // setData: (state, action) => {
    //   state.data = action.payload;
    // },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchInboxAnalysisByCompanyId.pending, (state) => {
        state.status = "loading";
        //   state.error = ""
        state.data = null;
      })
      .addCase(fetchInboxAnalysisByCompanyId.fulfilled, (state, action) => {
        state.status = "succeeded";
        //   state.error = ""
        state.data = action.payload;
      })
      .addCase(fetchInboxAnalysisByCompanyId.rejected, (state) => {
        state.status = "failed";
        // state.data = [];
        //   state.error = action.error.message || "";
      });
  },
});

export const agentPerformanceActions = inboxAnalysysSlice.actions;
export default inboxAnalysysSlice.reducer;
