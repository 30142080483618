import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Typography,
} from "@mui/material";
import CloseSvg from "../../assets/svgs/CloseSvg";
import { makeStyles } from "@mui/styles";
// import TextFeildWithBorderComponet from "../common/TextFieldWithBorderComponent";
// import PhoneInput from "../common/PhoneInput";
import { useAppDispatch, useAppSelector } from "../../utils/redux-hooks";
// import { fetchAllRoles } from "../../redux/slices/ManageCompany/DisplayAllRoles";
// import { fetchAllCountryCodes } from "../../redux/slices/ManageAccount/GetAllCountryCode";
// import { ADMIN_MANAGE_COMPANY_APIS } from "../../Apis/ManageCompany/ManageCompany";
// import { toastActions } from "../../utils/toastSlice";
// import { fetchGetAllTeamMembers } from "../../redux/slices/ManageCompany/GetAllTeamMembers";
// import { fetchGetAllTeamMembersByCompanyId } from "../../redux/slices/ManageCompany/GetAllTeamMembersByCompanyId";
import DevicePreview from "./PreViewDevice";

// const ALPHA_REGEX = /^[a-zA-Z\s]*$/;

const useStyles = makeStyles({
  changePasswordContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    cursor: "pointer",
  },
  updateButtonStyles: {
    backgroundColor: "#3C3C3C",
    color: "#ffffff",
    height: "40px",
    borderRadius: "8px",
    width: "100%",
    cursor: "pointer",
  },
  blackColor: {
    color: "#303030 !important",
    fontWeight: "600 !important",
  },
  dialogContent: {
    display: "flex",
    justifyContent: "center",
    overflowX: "hidden", // Disable horizontal scrolling
    overflowY: "auto", // Enable vertical scrolling
    scrollbarWidth: "none", // For Firefox
    "&::-webkit-scrollbar": {
      display: "none", // Hide scrollbar for Chrome, Safari, and Opera
    },
    "-ms-overflow-style": "none", // IE and Edge
  },
});

const PreviewPopover = ({ open, handleClose }: any) => {
  const classes = useStyles();
  // const dispatch = useAppDispatch();
  // const getAllRoles = useAppSelector((state) => state.getAllRoles);
  // const getAllCountryCodes = useAppSelector((state) => state.getAllCoutryCodes);
  // const loginData = useAppSelector((state) => state?.adminLogin?.data);
  // useEffect(() => {
  //   dispatch(fetchAllRoles(loginData?.companyId));
  //   dispatch(fetchAllCountryCodes());
  // }, [dispatch]);

  // console.log("formData", formData, getRoles);

  const handleCloseAll = () => {
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleCloseAll}
      PaperProps={{ style: { minWidth: "25%", borderRadius: "20px" } }}
    >
      <DialogTitle>
        <Box mx={2} mb={0} className={classes.changePasswordContainer}>
          <Typography variant="h6">Profile Preview</Typography>
          <Box onClick={handleCloseAll}>
            <CloseSvg />
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent
        style={{
          display: "flex",
          justifyContent: "center",
          overflowX: "hidden", // Disable horizontal scrolling
          overflowY: "auto", // Enable vertical scrolling
          scrollbarWidth: "none", // For Firefox
        }}
        className={classes.dialogContent}
      >
        {/* <Box m={2} mt={0} sx={{ width: "300px" }}> */}
        <DevicePreview />
        {/* </Box> */}
      </DialogContent>
      {/* <DialogActions>
        <Box width="100%" ml={4} mr={4} mb={4}>
          <button onClick={handleSave} className={classes.updateButtonStyles}>
            Add Member
          </button>
        </Box>
      </DialogActions> */}
    </Dialog>
  );
};

export default PreviewPopover;
