import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { HELP_CENTER_APIS } from "../../../Apis/AdminLogin/HelpCenter/HelpCenter";

export interface IData {
  dataStatus: "loading" | "succeeded" | "failed" | "idle";
  status: "loading" | "succeeded" | "failed" | "idle";
  data: any;
}

const initialState: IData = {
  dataStatus: "idle",
  status: "idle",
  data: [],
};

export const fetchHelpCenter = createAsyncThunk(
  "fetchHelpNotifications",
  async () => {
    const response = await HELP_CENTER_APIS.getHelpCenterData();
    // console.log("response", response);
    return response?.data;
  }
);

export const postHelpCenter = createAsyncThunk(
  "PostManageNotifications",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await HELP_CENTER_APIS.postHelpCenterData(data);
      // console.log("response", response);
      return response?.data;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || "An error occurred"
      );
    }
  }
);

export const HelpCenterSlice = createSlice({
  name: "HelpCenterSlice",
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchHelpCenter.pending, (state) => {
        state.dataStatus = "loading";
        state.data = [];
      })
      .addCase(fetchHelpCenter.fulfilled, (state, action) => {
        state.dataStatus = "succeeded";
        state.data = action.payload;
      })
      .addCase(fetchHelpCenter.rejected, (state) => {
        state.dataStatus = "failed";
        state.data = [];
      })
      .addCase(postHelpCenter.pending, (state) => {
        state.status = "loading";
        state.data = [];
      })
      .addCase(postHelpCenter.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(postHelpCenter.rejected, (state) => {
        state.status = "failed";
        state.data = [];
      });
  },
});

export const HelpCenterActions = HelpCenterSlice.actions;
export default HelpCenterSlice.reducer;
