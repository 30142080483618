import React, { useEffect, useState } from 'react';
import { TextField, Box } from '@mui/material';

const DateRangePicker = ({ onDateRangeChange, disabled, startCalenderDate, endCalenderDate }: any) => {
    // console.log(startCalenderDate)
    // console.log(endCalenderDate)
    const [startDate, setStartDate] = useState(startCalenderDate || '');
    const [endDate, setEndDate] = useState(endCalenderDate || '');

    useEffect(() => {
        setStartDate(startCalenderDate || '');
    }, [startCalenderDate]);

    useEffect(() => {
        setEndDate(endCalenderDate || '');
    }, [endCalenderDate]);

    const handleStartDateChange = (event: any) => {
        const newStartDate = event.target.value;
        setStartDate(newStartDate);
        if (endDate && newStartDate > endDate) {
            setEndDate('');
        }
        onDateRangeChange({ startDate: newStartDate, endDate });
    };

    const handleEndDateChange = (event: any) => {
        const newEndDate = event.target.value;
        setEndDate(newEndDate);
        onDateRangeChange({ startDate, endDate: newEndDate });
    };


    const getCurrentDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    return (
        <Box sx={{ display: 'flex', gap: 2 }}>
            <TextField
                label="Start Date"
                type="date"
                value={startDate}
                onChange={handleStartDateChange}
                InputLabelProps={{
                    shrink: true,
                }}
                inputProps={{
                    max: getCurrentDate(), // Set maximum date to today
                }}
                disabled={disabled}
                sx={{
                    '& .MuiInputBase-root': {
                        height: '35px', // Adjust this value to your preferred height
                        borderRadius: '12px', // Set border radius
                    },
                    '& .MuiInputBase-input': {
                        padding: '8px 14px', // Adjust padding to center the text vertically
                    },
                }}
            />
            <TextField
                label="End Date"
                type="date"
                value={endDate}
                onChange={handleEndDateChange}
                InputLabelProps={{
                    shrink: true,
                }}
                inputProps={{
                    min: startDate, // Set minimum date to start date
                    max: getCurrentDate(), // Set maximum date to today
                }}
                disabled={!startDate} // Disable end date picker if start date is not selected
                sx={{
                    '& .MuiInputBase-root': {
                        height: '35px', // Adjust this value to your preferred height
                        borderRadius: '12px', // Set border radius
                    },
                    '& .MuiInputBase-input': {
                        padding: '8px 14px', // Adjust padding to center the text vertically
                    },
                }}
            />
        </Box>
    );
};

export default DateRangePicker;