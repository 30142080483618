import {
  Box,
  Dialog,
  // DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { bgColors } from "../../utils/bgColors";
import { useNavigate } from "react-router-dom";
import { ADMIN_SIGNOUT_APIS } from "../../Apis/AdminLogin/Signout/SignOutApis";
import { useAppDispatch } from "../../utils/redux-hooks";
import { toastActions } from "../../utils/toastSlice";
import { useState } from "react";
import LoadingComponent from "../common/LoadingComponent";

const useStyles = makeStyles({
  signOutButtonStyles: {
    backgroundColor: "#3C3C3C",
    color: "#ffffff",
    height: "40px",
    borderRadius: "8px",
    width: "180%",
    padding: "8px",
    fontWeight: "600",
    cursor: "pointer",
  },
  cancelButtonStyles: {
    backgroundColor: "#ffffff",
    color: "#000000",
    height: "40px",
    borderRadius: "8px",
    border: `1px solid ${bgColors.gray3}`,
    width: "180%",
    padding: "8px",
    fontWeight: "600",
    cursor: "pointer",
  },
  grayColor: {
    color: `${bgColors.black1} !important`,
    opacity: "60% !important",
    fontWeight: "600 !important",
  },
});

const SignOutPopUp = ({ open, handleClose }: any) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);

  const handleSignOutClick = async () => {
    setLoading(true);
    try {
      const response = await ADMIN_SIGNOUT_APIS.signOut();
      // console.log("response", response);
      if (response.status === 200) {
        dispatch(
          toastActions.setToaster({
            type: "success",
            message: `${response?.data?.message}`,
          })
        );
        localStorage.clear();
        sessionStorage.clear();
        localStorage.removeItem("persist:root");
        localStorage.removeItem("token");
        handleClose();
        navigate("/login");
        // window.location.reload();
      }
    } catch (err: any) {
      dispatch(
        toastActions.setToaster({
          type: "error",
          message: err,
        })
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: { minWidth: "18%", borderRadius: "20px", padding: "0px 40px" },
      }}
    >
      <DialogContent>
        <Box m={2} mt={1}>
          <Box mb={2}>
            <Typography variant="body2" className={classes.grayColor}>
              Are you sure you want to sign out?
            </Typography>
          </Box>
        </Box>
      </DialogContent>
      {loading ? (
        <LoadingComponent color={bgColors?.blue} height="auto" />
      ) : (
        <Box
          mb={3}
          display="flex"
          flexDirection="row"
          gap={2}
          justifyContent={"center"}
        >
          <button className={classes.cancelButtonStyles} onClick={handleClose}>
            Cancel
          </button>
          <button
            className={classes.signOutButtonStyles}
            onClick={handleSignOutClick}
          >
            Sign Out
          </button>
        </Box>
      )}
    </Dialog>
  );
};

export default SignOutPopUp;
