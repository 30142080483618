import React from "react";

const Analytics = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1874_159)">
        <path
          d="M14.7622 15.2413V20.6323C14.7979 21.3388 14.6896 22.0452 14.4438 22.7085C14.198 23.3719 13.8199 23.9783 13.3325 24.491C12.8451 25.0037 12.2585 25.412 11.6084 25.6909C10.9583 25.9699 10.2583 26.1137 9.55084 26.1137C8.84342 26.1137 8.14341 25.9699 7.49331 25.6909C6.84322 25.412 6.25661 25.0037 5.76918 24.491C5.28175 23.9783 4.90368 23.3719 4.65791 22.7085C4.41214 22.0452 4.30384 21.3388 4.33955 20.6323V15.2413C4.40713 13.9047 4.98565 12.6452 5.95555 11.7231C6.92546 10.8009 8.21256 10.2867 9.55084 10.2867C10.8891 10.2867 12.1763 10.8009 13.1462 11.7231C14.1161 12.6452 14.6946 13.9047 14.7622 15.2413ZM27.5009 5.21797V20.6323C27.5009 22.0162 26.9512 23.3434 25.9726 24.3219C24.994 25.3005 23.6668 25.8502 22.283 25.8502C20.8991 25.8502 19.5719 25.3005 18.5933 24.3219C17.6147 23.3434 17.065 22.0162 17.065 20.6323V5.21797C17.065 3.83408 17.6147 2.50688 18.5933 1.52832C19.5719 0.549757 20.8991 0 22.283 0C23.6668 0 24.994 0.549757 25.9726 1.52832C26.9512 2.50688 27.5009 3.83408 27.5009 5.21797ZM29.9901 32.1065H2.03671C1.50716 32.1065 0.999288 31.8961 0.624839 31.5217C0.25039 31.1472 0.0400391 30.6394 0.0400391 30.1098C0.0400391 29.5803 0.25039 29.0724 0.624839 28.698C0.999288 28.3235 1.50716 28.1131 2.03671 28.1131H29.9901C30.5197 28.1131 31.0275 28.3235 31.402 28.698C31.7764 29.0724 31.9868 29.5803 31.9868 30.1098C31.9868 30.6394 31.7764 31.1472 31.402 31.5217C31.0275 31.8961 30.5197 32.1065 29.9901 32.1065Z"
          fill="#4B5A5A"
        />
      </g>
      <defs>
        <clipPath id="clip0_1874_159">
          <rect width="32" height="32.1065" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Analytics;
