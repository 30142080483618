import React from "react";

const ChatUserLocationSvg = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.0003 2.3335C11.525 2.3335 9.151 3.31683 7.40066 5.06717C5.65032 6.81751 4.66699 9.19148 4.66699 11.6668C4.66699 17.9668 12.892 25.0835 13.242 25.3868C13.4533 25.5676 13.7222 25.6669 14.0003 25.6669C14.2784 25.6669 14.5473 25.5676 14.7587 25.3868C15.167 25.0835 23.3337 17.9668 23.3337 11.6668C23.3337 9.19148 22.3503 6.81751 20.6 5.06717C18.8496 3.31683 16.4757 2.3335 14.0003 2.3335ZM14.0003 22.9252C11.5153 20.5918 7.00033 15.5635 7.00033 11.6668C7.00033 9.81031 7.73782 8.02984 9.05058 6.71708C10.3633 5.40433 12.1438 4.66683 14.0003 4.66683C15.8568 4.66683 17.6373 5.40433 18.9501 6.71708C20.2628 8.02984 21.0003 9.81031 21.0003 11.6668C21.0003 15.5635 16.4853 20.6035 14.0003 22.9252ZM14.0003 7.00016C13.0773 7.00016 12.1751 7.27386 11.4077 7.78664C10.6402 8.29942 10.0421 9.02825 9.68889 9.88097C9.33568 10.7337 9.24326 11.672 9.42333 12.5773C9.60339 13.4825 10.0478 14.314 10.7005 14.9667C11.3531 15.6193 12.1847 16.0638 13.0899 16.2438C13.9951 16.4239 14.9335 16.3315 15.7862 15.9783C16.6389 15.6251 17.3677 15.0269 17.8805 14.2595C18.3933 13.4921 18.667 12.5898 18.667 11.6668C18.667 10.4292 18.1753 9.24217 17.3002 8.367C16.425 7.49183 15.238 7.00016 14.0003 7.00016ZM14.0003 14.0002C13.5388 14.0002 13.0877 13.8633 12.704 13.6069C12.3203 13.3505 12.0212 12.9861 11.8446 12.5598C11.668 12.1334 11.6218 11.6642 11.7118 11.2116C11.8019 10.759 12.0241 10.3432 12.3504 10.0169C12.6767 9.69059 13.0925 9.46836 13.5451 9.37833C13.9977 9.2883 14.4669 9.33451 14.8933 9.51111C15.3196 9.68771 15.684 9.98678 15.9404 10.3705C16.1968 10.7542 16.3337 11.2053 16.3337 11.6668C16.3337 12.2857 16.0878 12.8792 15.6502 13.3167C15.2127 13.7543 14.6192 14.0002 14.0003 14.0002Z"
        fill="#4B5A5A"
      />
    </svg>
  );
};

export default ChatUserLocationSvg;
