import React from "react";

const DownArraowSvg = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 28 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 1.5L10 9.5L2 1.5"
        stroke="#4B5A5A"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DownArraowSvg;
