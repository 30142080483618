import { bgColors } from "../../utils/bgColors";
import { makeStyles } from "@mui/styles";
import ArrowDownSvg from "../../assets/svgs/ArrowDownSvg";
import ExportWhiteIcon from "../../assets/svgs/ExportWhiteIcon";
import { useAppDispatch, useAppSelector } from "../../utils/redux-hooks";
import React, {
  useEffect,
  useState,
  ChangeEvent,
  useRef,
  useCallback,
} from "react";
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TextField,
  CircularProgress
} from "@mui/material";
import ContactsFilterPopover from "../../components/ContactsComponents/ContactsFilterPopOver";
import { BiSolidCategory } from "react-icons/bi";
import { MdChecklist, MdDelete } from "react-icons/md";
import { VscIssueDraft } from "react-icons/vsc";
import { IoIosCloseCircle } from "react-icons/io";
import { SiMarketo, SiTicktick } from "react-icons/si";
import { GiSandsOfTime } from "react-icons/gi";
import TemplatesFilterPopover from "../../components/TemplateComponents/TemplatesFilterPopover";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import LoadingComponent from "../../components/common/LoadingComponent";
import { CgPerformance } from "react-icons/cg";
// import useDebouncedFetch from "../../utils/debounceHook";
import { fetchAgentPerformanceByCompanyId } from "../../redux/slices/Analytics/AgentAnalyticsSlice";
// import { agentPerformanceSlice } from "../../redux/slices/Analytics/AgentAnalyticsSlice";
import { ANALYTICS_API } from "../../Apis/Analytics/Analytics";
import DateRangePicker from "../../components/AnalyticsComponent/DatePickerComponent";

const theme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          // Adjust the default padding for all TableCell components
          padding: "0px", // Set your desired padding value here
        },
      },
    },
  },
});

const useStyles = makeStyles({
  mainContainer: {
    backgroundColor: bgColors.white1,
    padding: "14px",
    height: "100%",
    width: "100%",
  },
  bgContainer: {
    backgroundColor: bgColors.white,
    borderRadius: "25px",
    height: "100%",
    width: "100%",
    overflow: "hidden !important",
    display: "flex",
    flexDirection: "column",
  },
  manageContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  blackColor: {
    color: `${bgColors.black1} !important`,
    fontWeight: "600 !important",
  },
  grayColor: {
    color: `${bgColors.black1} !important`,
    opacity: "60% !important",
  },
  grayColor1: {
    color: "#303030",
    opacity: "90%",
    fontSize: "13px",
    // padding:"5px"
  },
  rotatedIcon: {
    cursor: "pointer",
    paddingRight: "5px",
    transform: "rotate(180deg)",
  },
  iconStyles: {
    cursor: "pointer",
    paddingLeft: "5px",
  },
  messageCountContainer: {
    // border: `2px solid ${bgColors.gray5}`,
    // borderRadius: "12px",
    // padding: "2px 5px",
    height: "31px",
    border: "1px solid #F2F2F2",
    cursor: "pointer",
    borderRadius: "5px",
    paddingInline: "8px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  table: {
    borderCollapse: "separate",
    borderSpacing: "0",
    textAlign: "center",
    borderColor: "lightgray",
    "& th, & td": {
      // borderTop: '1px solid gray',
      borderBottom: "1px solid #f0f0f0",
      height: "35.8px",
    },
    "& th:first-child, & td:first-child": {
      borderLeft: "none",
    },
    "& th:last-child, & td:last-child": {
      borderRight: "none",
    },
  },
  messageInnerContainer: {
    display: "flex",
    flexDirection: "row",
  },
});

const subOptions = {
  Category: [{ id: 1, option: "Marketing" }],
  // SubCategory: subCategoriesList,
  Status: [
    { id: 1, option: "Pending" },
    { id: 2, option: "Approved" },
    { id: 3, option: "Rejected" },
    { id: 4, option: "Draft" },
    { id: 5, option: "Deleted" },
  ],
};

const options = [
  {
    id: 1,
    option: "View All",
  },
  {
    id: 2,
    option: "Category",
  },
  {
    id: 3,
    option: "Status",
  },
];

const AnalyticsAgentPerformance = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [pageNumber, setPageNumber] = React.useState(1);
  const userData = useAppSelector((state: any) => state?.adminLogin?.data);
  // const loginData = useAppSelector((state: any) => state.adminLogin.data);

  const agentData = useAppSelector(
    (state: any) => state?.agentPerformance
  );
  // console.log(agentData,"agentdataa")
  const [selectedDate, setSelectedDate] = useState(() => {
    const currentDate = new Date();
    return currentDate.toISOString().split('T')[0]; // Formats the date to 'YYYY-MM-DD'
  });

  const handleDateChange = (event: any) => {
    setSelectedDate(event.target.value);
  };

  const [anchorElFilter, setAnchorElFilter] = useState<null | HTMLElement>(
    null
  );
  const [anchorElTemplate, setAnchorElTemplate] = useState<null | HTMLElement>(
    null
  );
  const [selectedFilter, setSelectedFilter] = useState("Tags");
  const [selectedFilter2, setSelectedFilter2] = React.useState({
    column: "",
    value: "",
  });
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [page, setPage] = useState(1);
  const [pageData, setPageData] = useState(agentData.agentData || []);
  const [teamData, setTeamData] = useState<any>([]);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [isDateRangeApplied, setIsDateRangeApplied] = useState(false);
  const today = () => {
    const currentDate = new Date();
    return currentDate.toISOString().split('T')[0]; // Formats the date to 'YYYY-MM-DD'
  }
  const [endCalenderDate, setEndCalenderDate] = useState(today());
  const [startCalenderDate, setStartCalenderDate] = useState(today());

  useEffect(() => {
    const postData = {
      userId: userData?.userId,
      companyId: userData?.companyId,
      pageNumber: pageNumber,
      fromDate: startCalenderDate,
      toDate: endCalenderDate,
      tags: teamData,
      isExportData: false,
      per_page: 40,
      filters: {
        sorting: {
          column: "",
          order: "",
        },
        filtering: {
          filterType: "and",
          conditions: [
            {
              column: selectedFilter2?.column,
              operator: "equal",
              value: selectedFilter2?.value,
            },
          ],
        },
      },
    };

    dispatch(fetchAgentPerformanceByCompanyId(postData));
    //  setPageData(agentData.agentData);
    // console.log("agentPerformance", agentData);
  }, [
    dispatch,
    pageNumber,
    endCalenderDate,
    startCalenderDate,
    teamData
  ]);
  useEffect(() => {
    setPageData(agentData.agentData);
  }, [agentData])

  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isExportAllByIdLoading, setIsExportAllByIdLoading] = useState(false);

  const tableContainerRef = useRef(null);

  const handleLoadMore = useCallback(() => {
    if (
      !isLoadingMore &&
      pageData?.length !== agentData?.data?.total &&
      pageNumber <= Math.ceil(agentData?.data?.total / 40)
    ) {
      setIsLoadingMore(true);
      setPageNumber((prevPage) => prevPage + 1);
    }
  }, [isLoadingMore, pageData?.length]);

  const handleScroll = () => {
    if (tableContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        tableContainerRef.current;
      if (
        scrollTop + clientHeight >= scrollHeight - 20 &&
        !isLoadingMore
        // && pageData.length !== templatesSlice?.data?.total
      ) {
        handleLoadMore();
      }
    }
  };

  useEffect(() => {
    if (agentData) {
      if (pageNumber === 1) {
        setPageData(agentData);
      } else {
        setPageData((prevPageData: any) => [
          ...prevPageData,
          ...agentData,
        ]);
      }
      setIsLoadingMore(false);
    }
  }, [agentData]);

  const getContactTag = useAppSelector(
    (state: any) => state?.getContactTagsData?.data
  );
  const activeTagsArray: any = getContactTag.filter((tag: any) => tag.isActive);
  const filteredTags = activeTagsArray?.filter((tag: any) =>
    tag?.tag?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleFilterClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElFilter(event.currentTarget);
  };

  const handleCloseFilterPopover = () => {
    setAnchorElFilter(null);
  };

  const handleTemplateFilterClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElTemplate(event.currentTarget);
  };

  const handleCloseTemplatePopover = () => {
    setAnchorElTemplate(null);
  };

  const handleOptionClick = (option: string) => {
    setSelectedFilter(option);
    handleCloseFilterPopover();
  };

  const handleOptionClick2 = (option: string, subOption: string) => {
    setSelectedFilter2({
      column: option,
      value: subOption,
    });
    handleCloseTemplatePopover();
  };

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const handleRowHover = (rowId: any) => {
    setHoveredRow(rowId);
  };

  const handleDateRangeChange = ({ startDate, endDate }: any) => {
    setStartCalenderDate(startDate);
    setEndCalenderDate(endDate);
    setIsDateRangeApplied(false);
    // console.log("StartCalenderDate", startCalenderDate, "EndCalenderDate", endCalenderDate)
  };

  const getIconComponent = (option: any) => {
    // console.log('Option.....',option);
    switch (option) {
      case "Marketing":
        return (
          <span style={{ display: "flex", alignItems: "center" }}>
            <span style={{ paddingTop: "3px" }}>
              <SiMarketo />
            </span>
            &nbsp; Marketing
          </span>
        );
      // Add more cases as needed
      case "1":
        return (
          <span style={{ display: "flex", alignItems: "center" }}>
            <span style={{ paddingTop: "3px" }}>
              <GiSandsOfTime />
            </span>
            &nbsp; Pending
          </span>
        );
      case "2":
        return (
          <span style={{ display: "flex", alignItems: "center" }}>
            <span style={{ paddingTop: "3px" }}>
              <SiTicktick />
            </span>
            &nbsp; Approved
          </span>
        );
      case "3":
        return (
          <span style={{ display: "flex", alignItems: "center" }}>
            <span style={{ paddingTop: "3px" }}>
              <IoIosCloseCircle />
            </span>
            &nbsp; Rejected
          </span>
        );
      case "4":
        return (
          <span style={{ display: "flex", alignItems: "center" }}>
            <span style={{ paddingTop: "3px" }}>
              <VscIssueDraft />
            </span>
            &nbsp; Draft
          </span>
        );
      case "5":
        return (
          <span style={{ display: "flex", alignItems: "center" }}>
            <span style={{ paddingTop: "3px" }}>
              <MdDelete />
            </span>
            &nbsp; Deleted
          </span>
        );
      case "":
        return (
          <span style={{ display: "flex", alignItems: "center" }}>
            <span style={{ paddingTop: "3px" }}>
              <MdChecklist />
            </span>
            &nbsp; View All
          </span>
        );
      case "Category":
        return (
          <span style={{ display: "flex", alignItems: "center" }}>
            <span style={{ paddingTop: "3px" }}>
              <BiSolidCategory />
            </span>
            &nbsp; Category
          </span>
        );
      case "Status":
        return (
          <span style={{ display: "flex", alignItems: "center" }}>
            <span style={{ paddingTop: "3px" }}></span>&nbsp; Status
          </span>
        );
      default:
        return ""; // Default icon or content
    }
  };

  const handleExportById = async () => {
    // const hasPermission = hasExportByIdPermission(manageContactsObject);      
    try {
      let data = {
        userId: userData?.userId,
        companyId: userData?.companyId,
        pageNumber: pageNumber,
        fromDate: startCalenderDate,
        tags: teamData,
        toDate: endCalenderDate,
        isExportData: true,
        // per_page: 40,
      }
      setIsExportAllByIdLoading(true);
      const getResponseById = await ANALYTICS_API.getAgentPerformance(data);
      let rdata:string = getResponseById.data.data;
      // Decode base64 string to binary data
      const byteCharacters = atob(rdata);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      // Create a blob from the byte array
      const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);
      // console.log("url...", url);
      const link = document.createElement("a");

      link.href = url;
      link.download = "exported_Analytics.xlsx";
      link.style.display = "none";
      document.body.appendChild(link);

      link.click();

      // Cleanup
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
      setIsExportAllByIdLoading(false);
    } catch (error) {
      console.error("Error exporting Analytics Agent Performance:", error);
      setIsExportAllByIdLoading(false);
    }
  };

  return (
    <Grid className={classes.mainContainer}>
      <Box className={classes.bgContainer} >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100px",
            overflow: "hidden",
          }} mt={1}
        >
          <Box className={classes.manageContainer}>
            <Box my={2} mx={3}>
              <Typography
                variant="h6"
                className={classes.blackColor}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <CgPerformance />
                &nbsp;Agent Performance
              </Typography>
            </Box>
          </Box>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
            ml={2}
          >
            <Box style={{ display: "flex", flexDirection: "row" }}>
              <Box className={classes.messageCountContainer}>
                <Typography
                  className={classes.grayColor1}
                  sx={{ fontSize: "13px" }}
                  onClick={handleFilterClick}
                >
                  {selectedFilter}
                </Typography>
                <Box className={classes.iconStyles} onClick={handleFilterClick}>
                  <ArrowDownSvg />
                </Box>
                <ContactsFilterPopover
                  anchorEl={anchorElFilter}
                  handleClose={handleCloseFilterPopover}
                  options={filteredTags}
                  handleOptionClick={handleOptionClick}
                  handleSearchChange={handleSearchChange}
                  setSearchQuery={setSearchQuery}
                  setPage={setPage}
                  setTeamData={setTeamData}
                />
              </Box>
              {/* <Box className={classes.messageCountContainer} mx={2}>
                <Box className={classes.messageInnerContainer}>
                  <Typography variant="body2" className={classes.grayColor}>
                    Events
                  </Typography>
                  <Box className={classes.iconStyles}>
                    <ArrowDownSvg />
                  </Box>
                </Box>
              </Box> */}
              {/* <Box className={classes.messageCountContainer}  mx={2}>
                <Box className={classes.messageInnerContainer}>
                  <Typography
                    className={classes.grayColor}
                    style={{
                      padding: "3px",
                      fontSize: 12,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {getIconComponent(selectedFilter2?.value)}
                    &nbsp; &nbsp;|
                  </Typography>
                  <Box
                    className={
                      anchorElTemplate
                        ? classes.rotatedIcon
                        : classes.iconStyles
                    }
                    onClick={handleTemplateFilterClick}
                  >
                    <ArrowDownSvg />
                  </Box>
                  <TemplatesFilterPopover
                    anchorEl={anchorElTemplate}
                    handleClose={handleCloseTemplatePopover}
                    options={options}
                    subOptions={subOptions}
                    handleOptionClick={handleOptionClick2}
                  />
                </Box>
              </Box> */}
              {/* <Box className={classes.messageCountContainer} mx={2}>
                <TextField
                  // label="Select Date"
                  type="date"
                  value={selectedDate}
                  onChange={handleDateChange}
                  InputLabelProps={{
                    shrink: true, // Ensures the label is displayed above the input field
                  }}
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      border: "none",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                    "& .MuiInputBase-root": {
                      height: "31px",
                    },
                    "& .MuiInputBase-input": {
                      padding: "8px 14px",
                    },
                  }}
                />
              </Box> */}
                            <Box>
                <Box
                  ml={2}
                  sx={{
                    display: "flex",
                    // justifyContent: "space-between"
                    border: "1px solid #eee",
                    borderRadius: "5px",
                    height: "31px",
                  }}
                >
                  <DateRangePicker
                    onDateRangeChange={handleDateRangeChange}
                    // disabled={transactionData && transactionData?.length < 1}
                    startCalenderDate={startCalenderDate}
                    endCalenderDate={endCalenderDate}
                  />
                  {/* <Button
                    // disabled={!startCalenderDate || !endCalenderDate}
                    onClick={handleApplyDateRange}
                    sx={{
                      color: "green",
                      fontSize: "12px",
                      padding: 0,
                      margin: 0,
                    }}
                  >
                    <Tooltip title={tooltipText}>{icon}</Tooltip>
                  </Button> */}
                </Box>
              </Box>
            </Box>
            <Box mr={3}>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  // backgroundColor: bgColors.blue,
                  borderRadius: "8px",
                  padding: "10px",
                  cursor: "pointer",
                  width: "130px",
                  height: "32px",
                  color: bgColors.green,
                  border: `1px solid ${bgColors.green}`,
                }}
                sx={{
                  "&:hover": {
                    cursor: "pointer",
                    backgroundColor: "rgba(68, 71, 70, 0.08)",
                  },
                }}
              >
                {isExportAllByIdLoading ? (
                  <Box sx={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center" }}>
                    <CircularProgress size={16} />
                  </Box>
                ) : (
                  <Box sx={{ display: "flex", width: "100%", justifyContent: "space-between" }} onClick={handleExportById} >
                    <Typography variant="body2" style={{ padding: "5px" }}>
                      Export
                    </Typography>
                    <Box className={classes.iconStyles}>
                      <ExportWhiteIcon
                        style={{
                          fill: bgColors.green,
                          paddingTop: "5px",
                        }}
                      />
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box style={{ flex: "1", overflow: "hidden" }}>
          <ThemeProvider theme={theme}>
            <TableContainer
              component={Box}
              mt={2}
              sx={{
                height: "100%",
                scrollBehavior: "smooth",
                // overflowY:
                //   pageData?.length !== 0 && pageData !== null
                //     ? "scroll"
                //     : "hidden",
                // scrollbarWidth:
                //   pageData?.length !== 0 && pageData !== null ? "thin" : "none",
              }}
              onScroll={handleScroll}
              ref={tableContainerRef}
            // px={1}
            >
              <Table className={classes.table} sx={{ fontSize: "14px" }}>
                <TableHead>
                  <TableRow className={classes.grayColor}>
                    <TableCell sx={{ paddingLeft: "20px", maxWidth: "30%" }}>
                      {" "}
                      Name
                    </TableCell>
                    <TableCell>Assigned</TableCell>
                    <TableCell>Reassigned</TableCell>
                    <TableCell>Responded</TableCell>
                    <TableCell>Resolved</TableCell>
                    <TableCell>Resolution Time (Mins)</TableCell>
                    <TableCell>Avg Response Time (Mins)</TableCell>
                    {/* <TableCell>Closed</TableCell> */}
                  </TableRow>
                </TableHead>
                {agentData.agentStatus == 'loading' && !isLoadingMore ? (
                  <TableBody
                    sx={{
                      position: "relative",
                      height: "500px",
                    }}
                  >
                    <TableRow
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        position: "absolute",
                        left: "50%",
                        transform: "translateX(-50%)",
                        paddingLeft: "50px",
                        top: "20px",
                      }}
                    >
                      <LoadingComponent height="500px" color={bgColors.blue} />
                    </TableRow>
                  </TableBody>
                ) : (
                  // <TableBody>
                  //   {pageData?.map((item:any, index:any) => {
                  //     const isHovered = index === hoveredRow;
                  //     return (
                  //       <TableRow
                  //         key={index}
                  //         onMouseEnter={() => handleRowHover(index)}
                  //         onMouseLeave={() => setHoveredRow(null)}
                  //         sx={{
                  //           cursor: "pointer",
                  //           boxShadow: isHovered ? 3 : 0,
                  //           transition: "box-shadow 0.3s",
                  //         }}
                  //       >
                  //         <TableCell sx={{ paddingLeft: "20px" }}>
                  //           {item.name}
                  //         </TableCell>
                  //         <TableCell sx={{ textAlign: "center" }}>
                  //           {item.assigned}
                  //         </TableCell>
                  //         <TableCell sx={{ textAlign: "center" }}>
                  //           {item.reassigned}
                  //         </TableCell>
                  //         <TableCell sx={{ textAlign: "center" }}>
                  //           {item.responded}
                  //         </TableCell>
                  //         <TableCell sx={{ textAlign: "center" }}>
                  //           {item.resolved}
                  //         </TableCell>
                  //         <TableCell sx={{ textAlign: "center" }}>
                  //           {item.resolutionTime}
                  //         </TableCell>
                  //         <TableCell sx={{ textAlign: "center" }}>
                  //           {item.avgResponseTime}
                  //         </TableCell>
                  //         <TableCell sx={{ textAlign: "center" }}>
                  //           {item.closed}
                  //         </TableCell>
                  //       </TableRow>
                  //     );
                  //   })}
                  // </TableBody>
                  <TableBody>
                    {pageData.agentData?.length !== 0 ? (
                      pageData.agentData?.map((item: any, index: any) => {
                        const isHovered = index === hoveredRow;
                        return (
                          <TableRow
                            key={index}
                            onMouseEnter={() => handleRowHover(index)}
                            onMouseLeave={() => setHoveredRow(null)}
                            sx={{
                              cursor: "pointer",
                              boxShadow: isHovered ? 3 : 0,
                              transition: "box-shadow 0.3s",
                            }}
                          >
                            <TableCell sx={{ paddingLeft: "20px" }}>
                              {item.agent?.name}
                            </TableCell>
                            <TableCell sx={{ textAlign: "center" }}>
                              {item.assignedCount}
                            </TableCell>
                            <TableCell sx={{ textAlign: "center" }}>
                              {item.reassignedCount}
                            </TableCell>
                            <TableCell sx={{ textAlign: "center" }}>
                              {item.respondedCount}
                            </TableCell>
                            <TableCell sx={{ textAlign: "center" }}>
                              {item.resolvedCount}
                            </TableCell>
                            <TableCell sx={{ textAlign: "center" }}>
                              {item.resolutionTime}
                            </TableCell>
                            <TableCell sx={{ textAlign: "center" }}>
                              {item.avgResonseTime}
                            </TableCell>
                            {/* <TableCell sx={{ textAlign: "center" }}>
                              {item.closed}
                            </TableCell> */}
                          </TableRow>
                        );
                      })
                    ) : (
                      <TableRow
                        sx={{
                          position: "relative",
                          height: "70vh",
                        }}
                      >
                        <TableCell
                          colSpan={12}
                          sx={{
                            width: "100%",
                            fontSize: "16px",
                            textAlign: "center",
                            top: "20px",
                            border: "none !important",
                          }}
                        >
                          No Data Found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                )}
                <br />
              </Table>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: "20px",
                }}
              >
                {pageData?.length !== 0 &&
                  // pageData !== null &&
                  // pageData.length !== templatesSlice?.data?.total &&
                  !isLoadingMore && (
                    <Typography
                      // onClick={handleLoadMore}
                      sx={{
                        cursor: "pointer",
                        "&:hover": {
                          color: "blue",
                        },
                      }}
                    >
                      {/* {" "}
                      {"loading" !== "loading" ? (
                        // <button
                        //   style={{
                        //     cursor: 'pointer',
                        //     fontSize: "14px",
                        //     borderRadius: '5px',
                        //     border: `1px solid ${bgColors.green}`,
                        //     backgroundColor: bgColors.white,
                        //     color: bgColors.green,
                        //     padding: '5px'
                        //   }}
                        // >
                        //   Load More...
                        // </button>
                        <></>
                      ) : (
                        ""
                      )} */}
                    </Typography>
                  )}
                {isLoadingMore && (
                  <LoadingComponent height="50px" color={bgColors.blue} />
                )}
              </Box>
            </TableContainer>
          </ThemeProvider>
        </Box>
      </Box>
    </Grid>
  );
};

export default AnalyticsAgentPerformance;
