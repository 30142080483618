import React from "react";

const EditSvg = ({ style }: { style?: React.CSSProperties }) => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        style={style}
        d="M4.16663 15.5H7.69996C7.80963 15.5007 7.91835 15.4796 8.01988 15.4382C8.12141 15.3967 8.21376 15.3356 8.29162 15.2584L14.0583 9.48337L16.425 7.1667C16.5031 7.08923 16.5651 6.99706 16.6074 6.89551C16.6497 6.79396 16.6715 6.68504 16.6715 6.57503C16.6715 6.46502 16.6497 6.3561 16.6074 6.25455C16.5651 6.153 16.5031 6.06083 16.425 5.98337L12.8916 2.40836C12.8142 2.33026 12.722 2.26826 12.6204 2.22595C12.5189 2.18365 12.41 2.16187 12.3 2.16187C12.1899 2.16187 12.081 2.18365 11.9795 2.22595C11.8779 2.26826 11.7858 2.33026 11.7083 2.40836L9.35829 4.7667L3.57496 10.5417C3.49772 10.6196 3.43662 10.7119 3.39515 10.8134C3.35368 10.915 3.33266 11.0237 3.33329 11.1334V14.6667C3.33329 14.8877 3.42109 15.0997 3.57737 15.256C3.73365 15.4122 3.94561 15.5 4.16663 15.5ZM12.3 4.17503L14.6583 6.53337L13.475 7.7167L11.1166 5.35837L12.3 4.17503ZM4.99996 11.475L9.94162 6.53337L12.3 8.8917L7.35829 13.8334H4.99996V11.475ZM17.5 17.1667H2.49996C2.27895 17.1667 2.06698 17.2545 1.9107 17.4108C1.75442 17.5671 1.66663 17.779 1.66663 18C1.66663 18.2211 1.75442 18.433 1.9107 18.5893C2.06698 18.7456 2.27895 18.8334 2.49996 18.8334H17.5C17.721 18.8334 17.9329 18.7456 18.0892 18.5893C18.2455 18.433 18.3333 18.2211 18.3333 18C18.3333 17.779 18.2455 17.5671 18.0892 17.4108C17.9329 17.2545 17.721 17.1667 17.5 17.1667Z"
        fill="white"
      />
    </svg>
  );
};

export default EditSvg;
