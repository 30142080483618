/* global process */

import axios from "axios";

const ANALYTICS_API_URL = process.env.REACT_APP_BASE_URL;
const getAgentPerformance = (data: any) => {
  let urlTag = ""
  if (data.tags.length > 0) {
    for (let i = 0; i < data.tags.length; i++) {
      urlTag = urlTag + `Tags=${data.tags[i]}&`
    }
  }
  let date = ""
  if (data?.fromDate !== "" && data?.fromDate !== undefined && data?.toDate !== "" && data?.toDate !== undefined) {
    date = `&FromDate=${data?.fromDate}&ToDate=${data?.toDate}`
  }
  return axios({
    // url: `${ANALYTICS_API_URL}/Analytics/GetAgentPerformance/${data?.companyId}?Date=${data?.date}&IsExportData=${data?.isExportData}`,
    url: `${ANALYTICS_API_URL}/Analytics/GetAgentPerformance/${data?.companyId}?${urlTag}IsExportData=${data?.isExportData}${date}`,
    // 'https://dev.engageto.com/api/Analytics/GetAgentPerformance/99969011-7b1d-4c2d-92a6-fba9ca31a261?Date=2024-07-25&IsExportData=false' \
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
  });
};
const getGetOverviewAnalytics = (data: any) => {
  let urlTag = ""
  if (data.tags.length > 0) {
    for (let i = 0; i < data.tags.length; i++) {
      urlTag = urlTag + `Tags=${data.tags[i]}&`
    }
  }
  let date = ""
  if (data?.fromDate !== "" && data?.fromDate !== undefined && data?.toDate !== "" && data?.toDate !== undefined) {
    date = `&FromDate=${data?.fromDate}&ToDate=${data?.toDate}`
  }

  return axios({
    // url: `${ANALYTICS_API_URL}/Analytics/GetOverviewAnalytics/${data?.companyId}?Date=${data?.date}&IsExportData=${data?.isExportData}`,
    url: `${ANALYTICS_API_URL}/Analytics/GetOverviewAnalytics/${data?.companyId}?${urlTag}IsExportData=${data?.isExportData}${date}`,
    // 'https://dev.engageto.com/api/Analytics/GetOverviewAnalytics/99969011-7b1d-4c2d-92a6-fba9ca31a261?Date=2024-07-25&IsExportData=false' 
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
  });
};

const getInboxAnalytics = (data: any) => {
  let urlTag = ""
  if (data.tags.length > 0) {
    for (let i = 0; i < data.tags.length; i++) {
      urlTag = urlTag + `Tags=${data.tags[i]}&`
    }
  }
  let date = ""
  if (data?.fromDate !== "" && data?.fromDate !== undefined && data?.toDate !== "" && data?.toDate !== undefined) {
    date = `&FromDate=${data?.fromDate}&ToDate=${data?.toDate}`
  }
  return axios({
    url: `${ANALYTICS_API_URL}/Analytics/GetInboxAnalytics/${data?.companyId}?${urlTag}IsExportData=${data?.isExportData}${date}`,
    // 'https://dev.engageto.com/api/Analytics/GetInboxAnalytics/99969011-7b1d-4c2d-92a6-fba9ca31a261?IsExportData=false&FromDate=2024-07-25&ToDate=2024-08-20' \
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
  });
};

export const ANALYTICS_API = {
  getAgentPerformance, 
  getGetOverviewAnalytics,
  getInboxAnalytics
};
