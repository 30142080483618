/* global process */

import axios from "axios";

const USER_API_URL = process.env.REACT_APP_BASE_URL;

const postAddAmount = (data: any) => {
  // console.log(data, 'dataa')
  return axios({
    // url: `${USER_API_URL}/PaymentsDetails/create-order`,
    url: `${USER_API_URL}/PaymentsDetails/wallet/add-amount`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
    // data: JSON.stringify({ data: data }),
    data: JSON.stringify(data),
    // data: data,
  });
};
// http://user.engageto.com/api/UserWallet/GetUserWalletDetails
const getUserWalletDetails = () => {
  return axios({
    url: `${USER_API_URL}/UserWallet/GetUserWalletDetails`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
  });
};

const getExpectedWalletDetails = (companyId: string) => {
  return axios({
    url: `${USER_API_URL}/UserWallet/GetExpectedWalletBalance/${companyId}`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
  });
};

const getPaymentStatus = ({
  paymentId,
  orderId,
}: {
  paymentId: string;
  orderId: string;
}) => {
  return axios({
    // url: `${USER_API_URL}/PaymentGateway/GetOrderDetails?paymentId=324322&orderId=12321312`,
    url: `${USER_API_URL}/PaymentGateway/GetOrderDetails?paymentId=${paymentId}&orderId=${orderId}`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
  });
};

const walletTransactions = ({
  startDate,
  endDate,
}: {
  startDate: string;
  endDate: string;
}) => {
  return axios({
    url: `${USER_API_URL}/ConversationAnalytics/NotificationCost?startDate=${startDate}&endDate=${endDate}`,
    // 'https://user.engageto.com/api/ConversationAnalytics/NotificationCost?startDate=2024-05-25&endDate=2024-06-03'
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
  });
};

const walletInvoice = (userId: string) => {
  return axios({
    url: `${USER_API_URL}/ConversationAnalytics/DownloadWalletTransactionInvoice/${userId}`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
  });
};

const billingDetails = (companyId: string) => {
  return axios({
    url: `${USER_API_URL}/CompanyDetails/get-company/${companyId}`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
  });
};

// https://user.engageto.com/api/CompanyDetails/UpdateBillingDetails'
const editBillingDetails = (data: any) => {
  return axios({
    url: `${USER_API_URL}/CompanyDetails/UpdateBillingDetails`,
    data,
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
  });
};

const getWalletAndSubscription = (companyId: string) => {
  return axios({
    url: `${USER_API_URL}/UserWallet/GetWalletAndSubscription/${companyId}`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
  });
};

const getAllDiscounts = () => {
  return axios({
    url: `${USER_API_URL}/UserWallet/GetAllDiscountCoupons`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
  });
};

const getDiscountByCode = ({
  discountCode,
  companyId,
}: {
  discountCode: string;
  companyId: string;
}) => {
  return axios({
    // url: `${USER_API_URL}/UserWallet/GetDiscountByCode?code=Welcome%20Offer&companyId=99969011-7b1d-4c2d-92a6-fba9ca31a261`,
    url: `${USER_API_URL}/UserWallet/GetDiscountByCode?code=${discountCode}&companyId=${companyId}`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
  });
};

export const WALLET_APIS = {
  postAddAmount,
  getUserWalletDetails,
  getExpectedWalletDetails,
  getPaymentStatus,
  walletTransactions,
  walletInvoice,
  billingDetails,
  editBillingDetails,
  getWalletAndSubscription,
  getAllDiscounts,
  getDiscountByCode,
};
// 'https://user.engageto.com/api/CompanyDetails/get-company/99969011-7b1d-4c2d-92a6-fba9ca31a261'
