import React from "react";
import { Box } from "@mui/material";
import NewPasswordForm from "../../components/LoginComponents/NewPasswordForm";
import { makeStyles } from "@mui/styles";
import EngagetoLogoSvg from "../../assets/svgs/EngagetoLogoSvg";

const useStyles = makeStyles({
  background: {
    backgroundImage: `url("/images/new-pwd-bg.png")`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
  },
  logoContainer: {
    // padding: '16px',
  },
  formContainer: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

const NewPassword = () => {
  const classes = useStyles();
  return (
    <Box className={classes.background}>
      <Box className={classes.logoContainer}>
        <EngagetoLogoSvg />
      </Box>
      <Box className={classes.formContainer}>
        <NewPasswordForm loginPromptText="Set your new password" />
      </Box>
    </Box>
  );
};

export default NewPassword;
