import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from "@reduxjs/toolkit";


export interface IToast {
    type: string;
    message: string;
}

const initialState: IToast = {
  type: "",
  message: ""
};

export const toastSlice = createSlice({
    name: "toastSlice",
    initialState,
    reducers: {
        setToaster: (state, action:PayloadAction<IToast>) => {
            state.type = action.payload.type;
            state.message = action.payload.message;
        },
    },
});

export const toastActions = toastSlice.actions;
export const toastReducer = toastSlice.reducer;
  
