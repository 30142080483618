import React from "react";

interface TimeDifferenceProps {
  lastMessageAt: string; // Assuming lastMessageAt is a string representing the time
}

const TimeDifference: React.FC<TimeDifferenceProps> = ({ lastMessageAt }) => {
  const calculateTimeDifference = (lastMessageAt: string): string => {
    const parts = lastMessageAt.split(".");

    if (parts.length === 3) {
      const days = parseInt(parts[0], 10);
      return `${days} day${days !== 1 ? "s" : ""}`;
    } else if (parts.length < 3) {
      const timeParts = parts[0].split(":");
      const hours = parseInt(timeParts[0], 10);
      const minutes = parseInt(timeParts[1], 10);
      // const seconds = parseInt(timeParts[2], 10);

      if (hours > 0) {
        return `${hours} hr${hours !== 1 ? "s" : ""}`;
      } else if (minutes > 0) {
        return `${minutes} min${minutes !== 1 ? "s" : ""}`;
      } else {
        return `Just now`;
      }
    } else {
      throw new Error("Invalid time format");
    }
  };
  // console.log("last time", lastMessageAt);

  return <span>{calculateTimeDifference(lastMessageAt)}</span>;
};

export default TimeDifference;
