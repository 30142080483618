import React from "react";

const InboxSvg = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.3334 4H6.66675C5.60588 4 4.58847 4.42143 3.83832 5.17157C3.08818 5.92172 2.66675 6.93913 2.66675 8V28C2.66716 28.2363 2.73033 28.4682 2.84982 28.672C2.9693 28.8758 3.14079 29.0442 3.34675 29.16C3.54602 29.2729 3.77103 29.3326 4.00008 29.3333C4.23939 29.3333 4.47427 29.2688 4.68008 29.1467L10.6667 25.52C10.888 25.3883 11.1427 25.3234 11.4001 25.3333H25.3334C26.3943 25.3333 27.4117 24.9119 28.1618 24.1618C28.912 23.4116 29.3334 22.3942 29.3334 21.3333V8C29.3334 6.93913 28.912 5.92172 28.1618 5.17157C27.4117 4.42143 26.3943 4 25.3334 4ZM10.6667 16C10.403 16 10.1453 15.9218 9.92599 15.7753C9.70672 15.6288 9.53582 15.4205 9.43491 15.1769C9.33399 14.9333 9.30759 14.6652 9.35903 14.4065C9.41048 14.1479 9.53747 13.9103 9.72394 13.7239C9.91041 13.5374 10.148 13.4104 10.4066 13.359C10.6653 13.3075 10.9334 13.3339 11.177 13.4348C11.4206 13.5357 11.6289 13.7066 11.7754 13.9259C11.9219 14.1452 12.0001 14.403 12.0001 14.6667C12.0001 15.0203 11.8596 15.3594 11.6096 15.6095C11.3595 15.8595 11.0204 16 10.6667 16ZM16.0001 16C15.7364 16 15.4786 15.9218 15.2593 15.7753C15.0401 15.6288 14.8692 15.4205 14.7682 15.1769C14.6673 14.9333 14.6409 14.6652 14.6924 14.4065C14.7438 14.1479 14.8708 13.9103 15.0573 13.7239C15.2437 13.5374 15.4813 13.4104 15.74 13.359C15.9986 13.3075 16.2667 13.3339 16.5103 13.4348C16.754 13.5357 16.9622 13.7066 17.1087 13.9259C17.2552 14.1452 17.3334 14.403 17.3334 14.6667C17.3334 15.0203 17.1929 15.3594 16.9429 15.6095C16.6928 15.8595 16.3537 16 16.0001 16ZM21.3334 16C21.0697 16 20.8119 15.9218 20.5927 15.7753C20.3734 15.6288 20.2025 15.4205 20.1016 15.1769C20.0007 14.9333 19.9743 14.6652 20.0257 14.4065C20.0771 14.1479 20.2041 13.9103 20.3906 13.7239C20.5771 13.5374 20.8147 13.4104 21.0733 13.359C21.3319 13.3075 21.6 13.3339 21.8437 13.4348C22.0873 13.5357 22.2955 13.7066 22.442 13.9259C22.5885 14.1452 22.6667 14.403 22.6667 14.6667C22.6667 15.0203 22.5263 15.3594 22.2762 15.6095C22.0262 15.8595 21.687 16 21.3334 16Z"
        fill="#4B5A5A"
      />
    </svg>
  );
};

export default InboxSvg;
