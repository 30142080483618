/* global process */

import axios from "axios";

const USER_API_URL = process.env.REACT_APP_BASE_URL;

const addClient = (data: any) => {
  const formData = new FormData();
  formData.append("CompanyName", data?.companyName);
  formData.append("ClientName", data?.clientName);
  formData.append("OfficialWebsite", data?.website);
  formData.append("Email", data?.email);
  formData.append("BusinessEmail", data?.businessEmail);
  formData.append("CountryName", data?.country);
  //   formData.append("CountryCode", data?.countryCode);
  formData.append("Address", data?.address);
  return axios({
    url: `${USER_API_URL}/ClientDetails/add-client`,
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: localStorage.getItem("token"),
    },
    data: formData,
  });
};

const getAllClients = (data: any) => {
  return axios({
    url: `${USER_API_URL}/ClientDetails/get-all-clients?searchQuery=${data?.search}&includeInactive=${data?.includeInactive}&sortBy=${data?.sortBy}&isSortAscending=${data?.isSortAscending}`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
    // data : {
    //     searchQuery : data?.search,
    //     includeInactive: data?.includeInactive,
    //     sortBy: data?.sortBy,
    //     isSortAscending: data?.isSortAscending
    // }
  });
};

const getClientsById = (data: any) => {
  return axios({
    url: `${USER_API_URL}/ClientDetails/get-client/${data?.clientId}`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
  });
};

const updateClient = (data: any) => {
  const formData = new FormData();
  formData.append("CompanyName", data?.companyName);
  // formData.append("ClientName", data?.clientName);
  formData.append("OfficialWebsite", data?.website);
  formData.append("BusinessEmail", data?.businessEmail);
  formData.append("CountryCode", data?.countryCode);
  formData.append("PhoneNumber", data?.phoneNumber);
  formData.append("CountryName", data?.country);
  formData.append("BusinessCategory", data?.businessCategory);
  formData.append("Address", data?.address);
  formData.append("CompanyLogoLink", data?.companyLogoLink);
  return axios({
    url: `${USER_API_URL}/ClientDetails/update-client/${data?.clientId}`,
    method: "PUT",
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: localStorage.getItem("token"),
    },
    data: formData,
  });
};

const deleteClient = (data: any) => {
  return axios({
    url: `${USER_API_URL}/ClientDetails/delete-client/${data?.clientId}`,
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
  });
};

const addTeamMemberByCompanyId = (data: any) => {
  return axios({
    url: `${USER_API_URL}/TeamDetails/add-client-teammember`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
    data: data,
  });
};

export const MANAGE_CLIENTS = {
  addClient,
  getAllClients,
  getClientsById,
  updateClient,
  deleteClient,
  addTeamMemberByCompanyId,
};
