import React from "react";
import { Modal, Box, Button } from "@mui/material";
import { bgColors } from "../../../utils/bgColors";

interface MediaModalProps {
  open: boolean;
  onClose: () => void;
  media: any;
  sharedMedia: any;
}

const MediaModal: React.FC<MediaModalProps> = ({
  open,
  onClose,
  media,
  sharedMedia,
}) => {
  // console.log("media", media);
  // console.log("sharedMedia", sharedMedia);
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="media-modal-title"
      aria-describedby="media-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80%",
          maxWidth: "800px",
          bgcolor: bgColors?.gray5,
          boxShadow: 24,
          borderRadius: "12px",
          px: 2,
          pt: 4,
          pb: 1,
          textAlign: "center",
        }}
      >
        {/* Render different content based on media type */}
        {(media?.mediaMimeType === "image/png" ||
          media?.mediaMimeType === "image/jpeg") && (
          <img
            src={media?.mediaUrl}
            alt="Image Preview"
            style={{
              maxWidth: "100%",
              height: "400px",
              border: "1px solid #cdcdcd",
            }}
          />
        )}
        {sharedMedia && (
          <Box>
            {typeof sharedMedia?.mediaUrl === "string" &&
            sharedMedia?.mediaUrl?.endsWith(".mp4") ? (
              <video
                src={sharedMedia?.mediaUrl}
                style={{
                  maxWidth: "100%",
                  height: "400px",
                  border: "1px solid #cdcdcd",
                }}
                controls
                //  onClick={() => handleOpenMediaPopover(media)
              />
            ) : (
              <img
                src={sharedMedia?.mediaUrl}
                alt="Image Preview"
                style={{
                  maxWidth: "100%",
                  height: "400px",
                  border: "1px solid #cdcdcd",
                }}
              />
            )}
          </Box>
        )}
        {/* {media?.mediaMimeType === "application/pdf" && (
          <embed src={media?.mediaUrl} width="100%" height="500" />
        )}
        {media?.mediaMimeType === "text/plain" && (
          <iframe src={media?.mediaUrl} width="100%" height="500"></iframe>
        )}
        {media?.mediaMimeType ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document" && (
          <iframe src={media?.mediaUrl} width="100%" height="500"></iframe>
        )}
        {media?.mediaMimeType ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" && (
          <iframe src={media?.mediaUrl} width="100%" height="500"></iframe>
          //   <h2 style={{ width: "100%", height: "300" }}>
          //   File Downloaded Successfully
          // </h2>
        )}
        {media?.mediaMimeType === "pptx" && (
          <iframe src={media?.mediaUrl} width="100%" height="500"></iframe>
        )} */}
        <br />
        <Button
          sx={{
            my: 1,
            justifyContent: "center",
            bgcolor: bgColors?.blue,
            color: bgColors?.white,
            "&:hover": {
              bgcolor: bgColors?.blue,
            },
          }}
          onClick={onClose}
        >
          Close
        </Button>
      </Box>
    </Modal>
  );
};

export default MediaModal;
