import React from "react";
import { Box, Typography } from "@mui/material";

const NoAccessPage: React.FC = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      width="100%"
      //   style={{ backgroundColor: "#f5f5f5" }}
    >
      <Typography variant="h6" align="center">
        You do not have access to this page.
      </Typography>
    </Box>
  );
};

export default NoAccessPage;
