import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AUTOMATION_API } from "../../../Apis/AdminLogin/Automation/Automation";

export interface IData {
    status: "loading" | "succeeded" | "failed" | "idle";
    data: any;
  }

  const initialState: IData = {
    status: "idle",
    data: null,
  };

  export const postAutoReply = createAsyncThunk(
    "autoReply/postAutoReply",
    async (payload: any, { rejectWithValue }) => {
      try {
        const response = await AUTOMATION_API.createAutoReplyCustomMessage(payload);
        return response?.data;
      } catch (error: any) {
        // Provide a descriptive error message
        return rejectWithValue(error.response || 'An error occurred');
      }
    }
  );


  export const autoReplySlice = createSlice({
    name: "autoReply",
    initialState,
    reducers: {
       
    },
    extraReducers: (builder) => {
      builder
        .addCase(postAutoReply.pending, (state) => {
          state.status = "loading";
          state.data = null;
        })
        .addCase(postAutoReply.fulfilled, (state, action) => {
          state.status = "succeeded";
          state.data = action.payload;
        })
        .addCase(postAutoReply.rejected, (state) => {
          state.status = "failed";
        });
    },
  });


export const autoReplySliceActions = autoReplySlice.actions;
export default autoReplySlice.reducer;
