import React from "react";
import { Box, Popover } from "@mui/material";
import EmojiPicker, { EmojiStyle, Theme } from "emoji-picker-react";

interface EmojiPopoverProps {
  open: boolean;
  anchorEl: HTMLDivElement | null;
  onClose: () => void;
  onEmojiSelect: (emoji: string) => void;
}

const EmojiPopover: React.FC<EmojiPopoverProps> = ({
  open,
  anchorEl,
  onClose,
  onEmojiSelect,
}) => {
  const handleEmojiClick = (event: any) => {
    const clickedEmoji = event.emoji;
    // console.log("emoji", event, emojiData);
    onEmojiSelect(clickedEmoji);
    onClose();
  };
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <Box p={2}>
        <EmojiPicker
          emojiStyle={EmojiStyle.FACEBOOK}
          theme={Theme.LIGHT}
          onEmojiClick={handleEmojiClick}
          skinTonesDisabled
        />
      </Box>
    </Popover>
  );
};

export default EmojiPopover;
