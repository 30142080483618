import React from "react";
import { FunctionComponent } from "react";
import { Box, Typography, Grid } from "@mui/material";
import ButtonComponent from "../common/ButtonComponent";
import { bgColors } from "../../utils/bgColors";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";

export type LoginFormContainerType = {
  loginPromptText?: string;
  frameDivLeft?: number | string; // Adjust the type according to your requirements
};

const useStyles = makeStyles({
  container: {
    width: "180%",
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
    borderRadius: "15px",
    padding: "14%",
    textAlign: "center",
    boxSizing: "border-box",
    backgroundColor: "#ffffff",
  },
  button: {
    backgroundColor: `${bgColors.green}!important`,
    width: "100% !important",
    fontSize: "24px !important",
    fontWeight: "Bold !important",
    borderRadius: "12px !important",
  },
  promptText: {
    marginBottom: "20px !important",
    fontWeight: "bold !important",
    textAlign: "center",
  },
  emailmsg: {
    color: `#707070 !important`,
    fontWeight: "Medium !important",
    fontSize: "18px !important",
    textAlign: "center",
  },
});

const ResetPasswordContainer: FunctionComponent<LoginFormContainerType> = ({
  loginPromptText,
}) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const handleLogin = () => {
    navigate("/client-login");
  };
  return (
    <Grid container item xs={12} mt={15}>
      <Grid xs={2}></Grid>
      <Grid
        item
        xs={5}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box className={classes.container}>
          <Grid item xs={12} textAlign="center">
            <img alt="Reset" src="/images/Reset.png" />
          </Grid>
          <Typography variant="h5" mt={2} className={classes.promptText}>
            {loginPromptText}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} textAlign="center">
              <Typography variant="h6" className={classes.emailmsg}>
                Your password has been changed
              </Typography>
              <Typography variant="h6" className={classes.emailmsg}>
                successfully.
              </Typography>
            </Grid>
            <Grid item xs={12} mt={3}>
              <ButtonComponent
                onClick={handleLogin}
                title="Log In"
                className={classes.button}
              />
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ResetPasswordContainer;
