import CryptoJS from "crypto-js";

// Define the encryption key (replace with your actual key)
const key = CryptoJS.enc.Utf8.parse("1234567890123456"); // Must be 16 bytes for AES-128

// Encryption function
export const encrypt = (plainText: string): string => {
  const iv = CryptoJS.lib.WordArray.random(16); // Generate a random 16-byte IV

  const encrypted = CryptoJS.AES.encrypt(plainText, key, {
    iv: iv,
    padding: CryptoJS.pad.Pkcs7,
    mode: CryptoJS.mode.CBC,
  });

  // Concatenate IV and ciphertext and convert to Base64 string
  return iv.concat(encrypted.ciphertext).toString(CryptoJS.enc.Base64);
};

// Decryption function
export const decrypt = (cipherText: string): string => {
  const encryptedBytes = CryptoJS.enc.Base64.parse(cipherText);

  // Extract the first 16 bytes (IV) from the ciphertext
  const iv = CryptoJS.lib.WordArray.create(encryptedBytes.words.slice(0, 4));

  // The rest is the actual ciphertext
  const ciphertext = CryptoJS.lib.WordArray.create(
    encryptedBytes.words.slice(4)
  );

  // Create a CipherParams object
  const cipherParams = CryptoJS.lib.CipherParams.create({
    ciphertext: ciphertext,
    iv: iv,
  });

  // Decrypt using the key, IV, and other options
  const decrypted = CryptoJS.AES.decrypt(cipherParams, key, {
    iv: iv,
    padding: CryptoJS.pad.Pkcs7,
    mode: CryptoJS.mode.CBC,
  });

  // Convert decrypted data to UTF-8 string
  return decrypted.toString(CryptoJS.enc.Utf8);
};
