import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  Typography,
  InputLabel,
  Grid,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import CloseSvg from "../../assets/svgs/CloseSvg";
import { makeStyles } from "@mui/styles";
import TextFeildWithBorderComponet from "../common/TextFieldWithBorderComponent";
import PhoneInput from "../common/PhoneInput";
import { useAppDispatch, useAppSelector } from "../../utils/redux-hooks";
import { ADMIN_MANAGE_COMPANY_APIS } from "../../Apis/ManageCompany/ManageCompany";
import { findAllByPlaceholderText } from "@testing-library/react";
import { toastActions } from "../../utils/toastSlice";
import { fetchGetAllTeamMembers } from "../../redux/slices/ManageCompany/GetAllTeamMembers";
import { fetchGetAllTeamMembersByCompanyId } from "../../redux/slices/ManageCompany/GetAllTeamMembersByCompanyId";
import LoadingComponent from "../common/LoadingComponent";
import { bgColors } from "../../utils/bgColors";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { validatePhoneNumber } from "../../utils/functions";

const useStyles = makeStyles({
  changePasswordContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  updateButtonStyles: {
    backgroundColor: "#3C3C3C",
    color: "#ffffff",
    height: "40px",
    borderRadius: "8px",
    width: "100%",
    fontSize: "14px",
    fontWeight: "600",
    cursor: "pointer",
  },
  blackColor: {
    color: "#303030 !important",
    fontWeight: "500 !important",
  },
});

const EditTeamMember = ({ open, handleClose, data, usedFor }: any) => {
  // console.log("data", data);

  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [isEditTeamMemberLoading, SetIsEditTeamMemberLoading] = useState(false);
  const getAllRoles = useAppSelector((state:any) => state.getAllRoles);
  const getAllRolesData = getAllRoles?.data;
  const getAllCountryCodes = useAppSelector((state:any) => state.getAllCoutryCodes);
  const getAllCountryCodesData = getAllCountryCodes?.data;
  const codeArray =
    getAllCountryCodesData &&
    getAllCountryCodesData?.map((item: any) => ({
      countryCode: item?.countryCode,
      countryImage: item?.countryImage,
    }));

  // Local state for form fields and errors

  const [formData, setFormData] = useState({
    fullName: data?.name || "",
    email: data?.emailAddress || "",
    phoneNumber: data?.phoneNumber || "",
    countryCode: data?.countryCode || "+91",
    role: data?.roleId || "",
  });

  // console.log(formData)

  useEffect(() => {
    setFormData({
      fullName: data?.name || "",
      email: data?.emailAddress || "",
      countryCode: data?.countryCode || "+91",
      phoneNumber: data?.phoneNumber || "",
      role: data?.roleId || "",
    });
  }, [data, open]);

  const [formErrors, setFormErrors] = useState({
    fullName: "",
    email: "",
    phone: "",
    role: "",
    countryCode: "",
  });

  const handlePhoneChange = (value: string) => {
    // console.log("value", value);\

    // // Validate phone number format
    // const phoneRegex = /^\d{14}$/;
    // if (!phoneRegex.test(value)) {
    //   setFormErrors({
    //     ...formErrors,
    //     phone: "Phone number must be 10 digits",
    //   });
    // } else {
    //   setFormErrors({ ...formErrors, phone: "" });
    // }
    setFormData({ ...formData, phoneNumber: value });
    setFormErrors({
      ...formErrors,
      phone: "",
    });
  };

  const handleCountryCodeChange = (value: string) => {
    // console.log("value", value);
    setFormData({ ...formData, countryCode: value });
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    // Clear the error message when user starts typing
    setFormErrors({
      ...formErrors,
      [name]: "",
    });
  };

  const validateEmail = (email: string) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email.trim()) {
      return "";
    } else if (!emailPattern.test(email.toLowerCase())) {
      return "Invalid Email Address";
    }
    return "";
  };

  const handleRoleChange = (event: any) => {
    const roleValue = event.target.value as string;
    setFormData({
      ...formData,
      role: roleValue,
    });
    setFormErrors({
      ...formErrors,
      role: "", // Clear role error on change
    });
  };

  const handleSave = async () => {
    // Perform form validation
    let valid = true;
    const newErrors: any = {};

    if (formData.fullName.trim() === "") {
      newErrors.fullName = "Full Name is required";
      valid = false;
    } else if (formData.fullName.length > 75) {
      newErrors.fullName = "Full Name cannot be more than 75 characters.";
      valid = false;
    }

    // Validate Email
    const emailError = validateEmail(formData.email);
    if (emailError) {
      newErrors.email = emailError;
      valid = false;
    }

    if (formData?.phoneNumber.trim() === "" && formData?.countryCode === "") {
      newErrors.phone = "Phone Number & Country Code are required.";
      valid = false;
    } else if (formData?.phoneNumber.trim() === "" && formData?.countryCode) {
      newErrors.phone = "Phone number is required.";
      valid = false;
    } else if (
      !validatePhoneNumber(formData?.phoneNumber, formData?.countryCode)
    ) {
      newErrors.phone =
        "This phone number format is not recognized. Please check the country code and phone number.";
      valid = false;
    } else if (formData?.phoneNumber && formData?.countryCode === "") {
      newErrors.phone = "Country code is required.";
    }

    if (formData.role === "") {
      newErrors.role = "Role is required";
      valid = false;
    }

    if (!valid) {
      // If there are errors, update the state with error messages
      setFormErrors(newErrors);
      return;
    }
    SetIsEditTeamMemberLoading(true);
    try {
      const teamMemberId = data?.id;
      const response = await ADMIN_MANAGE_COMPANY_APIS.editTeamMember(
        teamMemberId,
        formData
      );
      // console.log(response, 'response')
      if (response.status === 200) {
        dispatch(
          toastActions.setToaster({
            type: "success",
            message: response?.data?.message,
          })
        );
        const postData = {
          search: "",
          companyId: data?.companyId,
        };
        dispatch(fetchGetAllTeamMembersByCompanyId(postData));
      }
      // window.location.reload();
    } catch (err: any) {
      dispatch(
        toastActions.setToaster({
          type: "error",
          message: err?.response?.data?.message,
        })
      );
      // window.location.reload();
    }
    // console.log("Form Data:", formData);
    SetIsEditTeamMemberLoading(false);
    handleClose();
  };

  const handleCloseAll = () => {
    handleClose();
    setFormData({
      fullName: "",
      email: "",
      phoneNumber: "",
      countryCode: "+91",
      role: "",
    });
    setFormErrors({
      fullName: "",
      email: "",
      phone: "",
      countryCode: "",
      role: "",
    });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{ style: { minWidth: "35%", borderRadius: "20px" } }}
    >
      <DialogTitle>
        <Box m={2} mb={0} className={classes.changePasswordContainer}>
          <Typography variant="h6" sx={{ display: "flex" }}>
            <span style={{ marginTop: "3px" }}>
              <PersonAddIcon sx={{ height: "22px" }} />
            </span>
            &nbsp;Edit Team Member
          </Typography>
          <Box onClick={handleCloseAll} sx={{ cursor: "pointer" }}>
            <CloseSvg />
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box m={2} mt={0}>
          <Box mb={2}>
            <InputLabel className={classes.blackColor}>Full name</InputLabel>
            <TextFeildWithBorderComponet
              label="Enter your full name"
              name="fullName"
              placeholder=""
              value={formData.fullName}
              onChange={handleInputChange}
              error={!!formErrors.fullName}
              helperText={formErrors.fullName}
              sx={{
                "& input": {
                  // fontWeight: "600"
                },
              }}
            />
          </Box>
          <Box mb={2}>
            <InputLabel className={classes.blackColor}>Email</InputLabel>
            <TextFeildWithBorderComponet
              label="Enter your email address"
              name="email"
              placeholder=""
              disabled
              value={formData.email}
              onChange={handleInputChange}
              error={!!formErrors.email}
              helperText={formErrors.email}
            />
          </Box>
          <Box mb={3}>
            <InputLabel className={classes.blackColor}>Phone Number</InputLabel>
            <Grid xs={12}>
              <PhoneInput
                width="130px"
                gapRequired="48px"
                phoneNumWidth="270px"
                countryCodeArray={codeArray && codeArray}
                name="phone"
                onCountryCodeChange={handleCountryCodeChange}
                onChange={handlePhoneChange}
                countryCode={formData?.countryCode || ""}
                propsValue={formData?.phoneNumber || ""}
                error={!!formErrors.phone}
                // helperText={formErrors.phone}
              />
            </Grid>
            {formErrors.phone && (
              <Typography variant="caption" color="error" sx={{ fontSize: 12 }}>
                {formErrors.phone}
              </Typography>
            )}
          </Box>

          <Box mb={2}>
            <InputLabel
              className={classes.blackColor}
              style={{ marginBottom: 5 }}
            >
              Role
            </InputLabel>
            {formData?.role !== "Owner" ? (
              <FormControl fullWidth size="small" error={!!formErrors.role}>
                <InputLabel
                  // id="demo-simple-select-label"
                  id="demo-simple-select-error-label"
                  sx={{ fontSize: { xs: 12, md: 14 } }}
                >
                  Select role for member
                </InputLabel>
                <Select
                  // labelId="demo-simple-select-label"
                  // id="demo-simple-select"
                  labelId="demo-simple-select-error-label"
                  id="demo-simple-select-error"
                  name="role"
                  size="small"
                  value={formData.role}
                  onChange={handleRoleChange}
                  error={!!formErrors.role}
                  label="Select role for member"
                  placeholder=""
                  inputProps={{
                    style: {
                      fontSize: "14px",
                    },
                  }}
                  sx={{
                    borderRadius: "8px",
                    fontSize: "14px",
                    "& .MuiSelect-select": {
                      // fontWeight: "600"
                    },
                  }}
                >
                  {getAllRolesData &&
                    getAllRolesData
                      ?.filter((item: any) => item?.name !== "Owner")
                      ?.map((item: any) => {
                        return (
                          <MenuItem
                            key={item.id}
                            value={item.name}
                            sx={{ fontSize: 14 }}
                          >
                            {item.name}
                          </MenuItem>
                        );
                      })}
                </Select>
                {/* {formErrors.role && (
                <Typography variant="caption" color="error">
                  {formErrors.role}
                </Typography>
              )} */}
                {formErrors.role && (
                  <FormHelperText>{formErrors.role}</FormHelperText>
                )}
              </FormControl>
            ) : (
              <TextFeildWithBorderComponet
                label="Role"
                name="role"
                placeholder=""
                disabled
                value={formData.role}
                // onChange={handleInputChange}
                // error={!!formErrors.email}
                // helperText={formErrors.email}
                sx={{
                  "& input": {
                    // fontWeight: "600"
                  },
                }}
              />
            )}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box width="100%" mx={4} mb={4}>
          {isEditTeamMemberLoading ? (
            <LoadingComponent height="auto" color={bgColors?.blue} />
          ) : (
            <button onClick={handleSave} className={classes.updateButtonStyles}>
              Update
            </button>
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default EditTeamMember;
