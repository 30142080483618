import {
  Box,
  Checkbox,
  Chip,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { bgColors } from "../../utils/bgColors";
import SearchIconSvg2 from "../../assets/svgs/SearchIconSvg2";
import AddUserIcon from "../../assets/svgs/AddUserIcon";
import FilterIconSvg from "../../assets/svgs/FilterIconSvg";
import ReadIconSvg from "../../assets/svgs/ReadIconSvg";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ChatFilterMainPopover from "./chatSideBarComponents/ChatFilterMainPopover";
import NewContactPopOver from "../ContactsComponents/NewContactPopOver";
import { useAppSelector } from "../../utils/redux-hooks";
import TimeDifference from "./inboxDetailsComponents/LastMessageTimeDisplay";
import ChatArrowDownSvg from "../../assets/svgs/ChatArrowDownSvg";
import AssignToPopover from "./inboxDetailsComponents/AssignToPopover";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import BasicFilterPopover, {
  basicFilterMapping,
  getSelectedFilter,
} from "./chatSideBarComponents/BasicFilterPopOver";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import LoadingComponent from "../common/LoadingComponent";
import { formatLastMessage } from "../../utils/functions";

const useStyles = makeStyles({
  mainContainer: {
    backgroundColor: bgColors.white1,
    padding: "6px 6px 6px 0px",
    height: "100%",
    width: "100%",
    overflowY: "hidden" /* Initially hide the scrollbar */,
    position: "relative" /* Needed for absolute positioning of scrollbar */,
    "&:hover": {
      overflowY: "scroll" /* Show scrollbar on hover */,
      paddingRight: "0px" /* Reserve space for the scrollbar */,
    },
    /* Scrollbar styling */
    "&::-webkit-scrollbar": {
      cursor: "pointer",
      width: "6.75px" /* Width of the scrollbar */,
    },
    "&::-webkit-scrollbar-thumb": {
      cursor: "pointer",
      backgroundColor: "#cdcdcd" /* Color of the scrollbar thumb */,
      borderRadius: "8px" /* Rounded corners for the scrollbar thumb */,
      transition:
        "background-color 0.2s ease-in-out" /* Optional: Add a transition effect for smooth hover */,
    },
  },
  searchField: {
    // width: "100%",
    borderRadius: "12px",
    height: "38px",
    // fontSize: "14px",
    backgroundColor: "transparent",
    "& input::placeholder": {
      textAlign: "left",
      fontSize: "14px",
      fontFamily: "Inter",
      color: "#4B5A5A !important",
      fontWeight: "500 !important",
    },
  },
  grayColor: {
    color: "#4B5A5A !important",
    opacity: "60%",
    // padding:"5px"
  },
  spaceBetween: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  messageIconStyles: {
    // backgroundColor: "#00934F",
    border: "1px solid #00934F",
    borderRadius: "8px",
    width: "43px",
    height: "30px",
    // padding: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  filterContainer: {
    display: "flex",
    flexDirection: "row",
  },
  loadMoreContainer: {
    fontSize: 12,
    marginInline: "auto",
    // maxWidth: "160px",
    textAlign: "center",
    backgroundColor: bgColors?.gray4,
    // borderRadius: "14px",
    padding: "4px",
    // marginBottom: "8px",
    cursor: "pointer",
    "&:hover": {
      color: bgColors?.blue,
    },
  },
  borderContainer: {
    border: "2px solid #F2F2F2",
    borderRadius: "8px",
    height: "32px",
    padding: "4px",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
      border: `2px solid ${bgColors.green1}`,
      cursor: "pointer",
      backgroundColor: "rgba(68, 71, 70, 0.08)",
    },
  },
  blackColor: {
    color: `${bgColors.black1} !important`,
    fontWeight: "600 !important",
    overflow: "hidden",
    whiteSpace: "nowrap",
    maxWidth: "120px",
    textOverflow: "ellipsis",
  },
  greenButton: {
    backgroundColor: bgColors.green1,
    color: bgColors.green,
    fontWeight: "700",
    borderRadius: 12,
    paddingInline: "6px",
  },
  yellowButton: {
    backgroundColor: bgColors.yellow2,
    color: bgColors.yellow,
    fontWeight: "700",
    borderRadius: 12,
    paddingInline: "6px",
  },
  redButton: {
    backgroundColor: bgColors.red2,
    color: bgColors.red1,
    fontWeight: "700",
    borderRadius: 12,
    paddingInline: "6px",
  },
  darkGreenButton: {
    backgroundColor: bgColors.green,
    color: bgColors.white,
    fontWeight: "700",
    borderRadius: 12,
    paddingInline: "6px",
  },
  chatDetailsContainer: {
    display: "flex",
    flexDirection: "row",
    cursor: "pointer",
    width: "100%",
    paddingInline: "6px",
    borderRadius: "6px",
    alignItems: "center",
  },
  contactsCount: {
    backgroundColor: bgColors?.gray5,
    color: bgColors?.black,
    borderRadius: "24px",
    height: "24px",
    width: "34px",
    fontSize: "10px",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  basicFilterButton: {
    color: bgColors.white,
    border: "2px solid #F2F2F2",
    borderRadius: "12px",
    fontSize: "12px",
    // width: "140px",
    padding: "10px 5px",
    display: "flex",
    height: "32px",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  helpContainer: {
    display: "flex",
    flexDirection: "row",
    cursor: "pointer",
  },
  helpTextContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  flexRowContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  notificationContainer: {
    backgroundColor: bgColors.green,
    color: bgColors.white,
    padding: "2px 3px 1px 3px",
    width: "16px",
    borderRadius: "50%",
    textAlign: "center",
  },
  chatDetailsContainerHover: {
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.01)",
    transition: "box-shadow 0.3s ease",
    cursor: "pointer",
    width: "100%",
    "&:hover": {
      boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.3)",
    },
  },
  chatClicked: {
    backgroundColor: "#E8E8E8",
  },
});

const ChatSideBar = ({
  contacts,
  searchInput,
  filterData,
  chatsPageNumber,
  paginatedContactsLoading,
  setSearchInput,
  setFilterData,
  setContactNumber,
  setChatsPageNumber,
}: {
  contacts: any;
  searchInput: string;
  filterData: any;
  chatsPageNumber: number;
  paginatedContactsLoading: boolean;
  setSearchInput: (search: any) => void;
  setFilterData: (filter: any) => void;
  setContactNumber: (number: any) => void;
  setChatsPageNumber: (number: any) => void;
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const params = useParams();
  const chatsContainerRef = useRef<HTMLDivElement>(null);

  const getuserPermissionData = useAppSelector(
    (state: any) => state.getUserPermissions?.data
  );
  const manageInboxObject = getuserPermissionData?.inbox;
  const contactsData = contacts?.data;
  const accountData = useAppSelector((state: any) => state?.accountData?.data);
  
  const [anchorEl, setAnchorEl] = useState(null);
  const [openBasicFilter, setOpenBasicFilter] = useState<any>(null);
  const [selectedFilterText, setSelectedFilterText] = useState<string>("");
  const [selectedItems, setSelectedItems] = useState<any>([]);
  const [activateChatSelection, setActivateChatSelection] =
    useState<boolean>(false);
  const [anchorElement, setAnchorElement] = useState(null);
  const [openNewContactPopover, setOpenNewContactPopover] = useState(false);
  const [addContactTooltip, setAddContactTooltip] = useState(false);

  const hasAddContactPermission = (permission: any) => {
    for (const profileItem of permission) {
      if (
        Object.prototype.hasOwnProperty.call(profileItem, "addNewInboxContact")
      ) {
        return true;
      }
    }
    return false;
  };

  const handleMainFilterClick = (event: any) => {
    setAnchorElement(event.currentTarget);
  };

  const handleCloseMainPopover = () => {
    setAnchorElement(null);
  };

  const handleOpenAssignPopup = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseAssignPopup = () => {
    setAnchorEl(null);
  };

  const handleSelectAll = () => {
    if (selectedItems?.length === contactsData?.length) {
      setSelectedItems([]);
    } else {
      setSelectedItems(contactsData);
    }
  };

  const handleSelection = () => {
    if (selectedItems?.length !== 0) {
      setSelectedItems([]);
      setActivateChatSelection(false);
    } else {
      setActivateChatSelection(!activateChatSelection);
    }
  };

  const handleSearchChange = (event: any) => {
    const inputValue = event.target.value;
    // console.log("inputValue", inputValue);
    setSearchInput(inputValue);
  };

  const handleItemClick = (checkedContact: any) => {
    // Check if the checkedContact is already selected
    const isSelected = selectedItems?.some(
      (selectedItem: any) => selectedItem?.contact === checkedContact?.contact
    );

    if (isSelected) {
      // If checkedContact is already selected, remove it from the selected items
      setSelectedItems(
        selectedItems?.filter(
          (selectedItem: any) =>
            selectedItem?.contact !== checkedContact?.contact
        )
      );
    } else {
      // If checkedContact is not selected, add it to the selected items
      setSelectedItems([...selectedItems, checkedContact]);
    }
  };

  const handleApplyFilter = (filterData: any) => {
    setFilterData(filterData);
    navigate("/inbox/help");
  };
  const handleApplyBasicFilter = (filterData: any) => {
    // console.log("filters applied", filterData);
    setSelectedFilterText(filterData?.filterText);
    setFilterData(filterData?.filters);
    navigate("/inbox/help");
  };

  const handleOpenNewContactPopup = () => {
    const hasPermission = hasAddContactPermission(manageInboxObject);
    if (hasPermission) {
      setOpenNewContactPopover(true);
    } else {
      setAddContactTooltip(true);
      setTimeout(() => {
        setAddContactTooltip(false);
      }, 2000);
    }
  };

  const handleCloseNewContactPopup = () => {
    setOpenNewContactPopover(false);
  };

  useEffect(() => {
    const selected = getSelectedFilter(filterData?.filters, basicFilterMapping);
    setSelectedFilterText(selected);
  }, [filterData]);

  const loadMessages = () => {
    setChatsPageNumber(chatsPageNumber + 1);
  };

  useEffect(() => {
    if (searchInput || filterData) {
      setChatsPageNumber(1);
    }
  }, [searchInput, filterData]);

  return (
    <Grid
      className={classes?.mainContainer}
      ref={chatsContainerRef}
      id="scrollableChatsDiv"
    >
      <Box p={1} className={classes?.spaceBetween} sx={{ width: "100%" }}>
        <TextField
          className={classes?.searchField}
          variant="standard"
          size="small"
          fullWidth
          value={searchInput}
          // disabled={accountData?.companyVerificationStatus === false}
          InputProps={{
            disableUnderline: true,
            style: {
              padding: "10px",
              fontSize: "12px",
              height: "38px",
              fontWeight: "600 !important",
            },
            startAdornment: (
              <IconButton sx={{ p: 0, paddingTop: "3px" }}>
                <SearchIconSvg2 />
              </IconButton>
            ),
          }}
          inputProps={{
            style: {
              // Additional style for placeholder
              fontWeight: "600 !important",
              paddingTop: "3px", // Apply font weight here
            },
          }}
          onChange={handleSearchChange}
          placeholder="Search chats"
        />

        <Tooltip
          title="Access Denied"
          placement="top"
          open={addContactTooltip}
          onClose={() => setAddContactTooltip(false)}
        >
          <Box
            ml={1}
            className={classes?.messageIconStyles}
            onClick={handleOpenNewContactPopup}
            sx={{
              "&:hover": {
                cursor: "pointer",
                backgroundColor: "rgba(68, 71, 70, 0.08)",
              },
            }}
          >
            <AddUserIcon
              style={{
                stroke: "#00934F",
              }}
            />
          </Box>
        </Tooltip>
      </Box>
      {/* </Box> */}
      <Box className={classes?.spaceBetween} px={1} pb={1}>
        {
          // selectedItems?.length !== 0 && selectedItems !== undefined
          activateChatSelection === true ? (
            <Box display="flex" alignItems="center">
              <Checkbox
                checked={selectedItems?.length === contactsData?.length}
                onChange={handleSelectAll}
                color="default"
                size="small"
                sx={{ width: 36, height: 30 }}
              />
              <Typography
                sx={{
                  textAlign: "center",
                  color: bgColors?.black,
                  fontSize: "14px",
                }}
              >
                &nbsp;&nbsp;{selectedItems?.length}&nbsp;Selected
              </Typography>
            </Box>
          ) : (
            <Box className={classes?.basicFilterButton}>
              <Box className={classes?.contactsCount}>
                {contacts?.totalCount}
              </Box>
              <Box
                pl={0.5}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  justifyContent: "space-between",
                }}
                onClick={(e) => setOpenBasicFilter(e.currentTarget)}
              >
                <Typography
                  sx={{
                    textAlign: "center",
                    color: bgColors?.gray1,
                    fontSize: "10px",
                    px: 0.5,
                  }}
                >
                  {selectedFilterText}&nbsp;
                </Typography>
                <ChatArrowDownSvg
                  style={{
                    width: "15px",
                    height: "15px",
                    stroke: bgColors.green,
                  }}
                />
              </Box>
            </Box>
          )
        }
        <Box gap={2} className={classes?.filterContainer}>
          {
            // selectedItems?.length !== 0 && selectedItems !== undefined
            activateChatSelection === true ? (
              <Tooltip title="Assign">
                <Box
                  className={classes?.borderContainer}
                  onClick={handleOpenAssignPopup}
                >
                  <AssignmentIndIcon fontSize="small" />
                </Box>
              </Tooltip>
            ) : (
              <Tooltip title="Apply Filters">
                <Box
                  className={classes?.borderContainer}
                  // style={{ cursor: "pointer" }}
                  onClick={handleMainFilterClick}
                >
                  <FilterIconSvg />
                </Box>
              </Tooltip>
            )
          }
          <Tooltip
            title={
              // selectedItems?.length === 0 || selectedItems === undefined
              activateChatSelection === false
                ? "Click to select chats"
                : "Clear selection"
            }
          >
            <Box
              className={classes?.borderContainer}
              sx={{
                backgroundColor: activateChatSelection
                  ? "rgba(68, 71, 70, 0.08)"
                  : "transparent",
              }}
              onClick={handleSelection}
            >
              <ReadIconSvg />
            </Box>
          </Tooltip>
        </Box>
      </Box>
      {accountData?.companyVerificationStatus === false && (
        <Box sx={{ textAlign: "center", m: 2 }}>
          <Chip
            label={
              <Typography sx={{ fontSize: 10, textAlign: "center" }}>
                Your account has not been verified with meta.
                <br />
                Please link with meta to chat with your contacts.
              </Typography>
            }
            color="warning"
            size="small"
            sx={{ height: "auto", width: "100%", padding: "4px" }}
          />
        </Box>
      )}
      <Box>
        {contactsData?.length !== 0 ? (
          contactsData?.map((chat: any, index: number) => (
            <Box
              className={`${classes?.chatDetailsContainer} ${
                classes?.chatDetailsContainerHover
              } ${params?.id === chat?.contact ? classes?.chatClicked : ""}`}
              key={index}
              onClick={() => {
                if (activateChatSelection === false) {
                  navigate(`/inbox/${chat?.contact}`);
                  // setSearchInput("");
                  setContactNumber(chat?.contact);
                } else {
                  handleItemClick(chat);
                }
              }}
            >
              <Box display="flex" alignItems="center" py={0.5}>
                {activateChatSelection ? (
                  <Checkbox
                    checked={
                      selectedItems
                        ?.map((item: any) => item?.contact)
                        ?.includes(chat?.contact) || false
                    }
                    onChange={() => handleItemClick(chat)}
                    color="default"
                    size="small"
                    sx={{ width: 40, height: 40 }}
                  />
                ) : chat?.isSpam ? (
                  <Box
                    sx={{
                      width: 36,
                      height: 36,
                      margin: 0.5,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "50%",
                      overflow: "hidden",
                      border: "1px solid #ccc",
                      background: bgColors?.red2,
                    }}
                  >
                    <ReportGmailerrorredIcon
                      sx={{ color: bgColors?.red }}
                      fontSize="medium"
                    />
                  </Box>
                ) : (
                  <img
                    src={
                      selectedItems
                        ?.map((item: any) => item?.contact)
                        ?.includes(chat?.contact)
                        ? "/images/TICK.png"
                        : "/images/profile.png"
                    }
                    height={40}
                    alt="chartIcon1"
                  />
                )}
              </Box>
              <Box
                px={1}
                py={chat?.chatStatus === "resolved" ? 1 : 0.5}
                ml="auto"
                sx={{ width: "100%" }}
              >
                <Box className={classes?.helpTextContainer}>
                  <Box className={classes?.flexRowContainer}>
                    <Typography
                      sx={{ fontSize: { xs: "10px", md: "12px" } }}
                      className={classes?.blackColor}
                    >
                      {chat?.name}
                    </Typography>
                    <Box
                      ml={1}
                      className={
                        chat?.chatStatus === "open"
                          ? classes?.greenButton
                          : chat?.chatStatus === "expired"
                          ? classes?.yellowButton
                          : chat?.chatStatus === "new"
                          ? classes?.darkGreenButton
                          : classes?.redButton
                      }
                    >
                      <Typography
                        sx={{ fontSize: "10px", textTransform: "capitalize" }}
                        alignItems="center"
                      >
                        {chat?.chatStatus}
                      </Typography>
                    </Box>
                  </Box>
                  <Typography
                    sx={{ fontSize: "9px" }}
                    className={classes?.grayColor}
                    key={index}
                  >
                    {chat?.lastMessageAt !== null && (
                      <TimeDifference lastMessageAt={chat?.lastMessageAt} />
                    )}
                  </Typography>
                </Box>
                <Box className={classes?.flexRowContainer}>
                  <Typography
                    sx={{ fontSize: { xs: "9px", md: "11px" } }}
                    className={classes?.grayColor}
                    dangerouslySetInnerHTML={{
                      __html: formatLastMessage(
                        chat?.lastMessage?.length > 40
                          ? `${chat?.lastMessage?.slice(0, 40)}...`
                          : chat?.lastMessage
                      ),
                    }}
                  />

                  {chat?.unRead !== 0 && (
                    <Typography
                      sx={{ fontSize: "9px", ml: "auto" }}
                      className={classes?.notificationContainer}
                    >
                      {chat?.unRead}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>
          ))
        ) : (
          <Box sx={{ textAlign: "center", mt: 2 }}>No chats found</Box>
        )}

        {chatsPageNumber < contacts?.totalPages &&
          (paginatedContactsLoading ? (
            <LoadingComponent color={bgColors?.blue} height="auto" />
          ) : (
            <Box onClick={loadMessages} className={classes.loadMoreContainer}>
              Click to view more chats...
            </Box>
          ))}
      </Box>

      <AssignToPopover
        contact={selectedItems}
        anchorEl={anchorEl}
        onClose={handleCloseAssignPopup}
      />
      <NewContactPopOver
        open={openNewContactPopover}
        handleClose={handleCloseNewContactPopup}
      />
      <ChatFilterMainPopover
        anchorEl={anchorElement}
        handleClose={handleCloseMainPopover}
        onApplyFilter={handleApplyFilter}
        filterData={filterData}
      />
      <BasicFilterPopover
        anchorEl={openBasicFilter}
        handleClose={() => setOpenBasicFilter(null)}
        handleSelectBasicFilter={handleApplyBasicFilter}
        selectedFilterText={selectedFilterText}
      />
    </Grid>
  );
};

export default ChatSideBar;
