import React from 'react'

const FaceBookSvg = () => {
    return (
        <div>
            <svg width="28" height="20" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_6446_120)">
                    <path d="M24.5001 0.5H3.50006C1.56981 0.5 6.10352e-05 2.06975 6.10352e-05 4V25C6.10352e-05 26.9303 1.56981 28.5 3.50006 28.5H24.5001C26.4303 28.5 28.0001 26.9303 28.0001 25V4C28.0001 2.06975 26.4303 0.5 24.5001 0.5Z" fill="white" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M23.6251 14.5H19.2501V11C19.2501 10.034 20.0341 10.125 21.0001 10.125H22.7501V5.75H19.2501C17.8577 5.75 16.5223 6.30312 15.5377 7.28769C14.5532 8.27226 14.0001 9.60761 14.0001 11V14.5H10.5001V18.875H14.0001V28.5H19.2501V18.875H21.8751L23.6251 14.5Z" fill="#1976D2" />
                </g>
                <defs>
                    <clipPath id="clip0_6446_120">
                        <rect x="6.10352e-05" y="0.5" width="28" height="28" rx="4" fill="white" />
                    </clipPath>
                </defs>
            </svg>

        </div>
    )
}

export default FaceBookSvg
