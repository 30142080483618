import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CONTACT_TAGS_APIS } from "../../../Apis/Contacts/ContactTagsApis";

export interface IData {
  status: "loading" | "succeeded" | "failed" | "idle";
  data: any;
}

const initialState: IData = {
  status: "idle",
  data: null,
};

export const updateContactTags = createAsyncThunk(
  "updateContactTags",
  async (data: any) => {
    const response = await CONTACT_TAGS_APIS.updateContactTags(data);
    return response?.data;
  }
);

export const editContactTagsSlice = createSlice({
  name: "editContactTagsSlice",
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(updateContactTags.pending, (state) => {
        state.status = "loading";
        //   state.error = ""
        state.data = [];
      })
      .addCase(updateContactTags.fulfilled, (state, action) => {
        state.status = "succeeded";
        //   state.error = ""
        state.data = action.payload;
      })
      .addCase(updateContactTags.rejected, (state) => {
        state.status = "failed";
        state.data = [];
        //   state.error = action.error.message || "";
      });
  },
});

export const editContactTagsActions = editContactTagsSlice.actions;
export default editContactTagsSlice.reducer;
