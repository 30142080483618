import React from "react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  centeredContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    borderRadius:"24px",
    height: "96vh", // Adjust as needed
  },
  heading: {
    fontSize: "24px", // Adjust as needed
  },
});

const CenteredContent: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.centeredContent}>
      <h1 className={classes.heading}>Coming Soon...</h1>
    </div>
  );
};

export default CenteredContent;
