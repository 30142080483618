import { Box, Grid, Typography } from "@mui/material";
// import LogOut from "../../assets/svgs/LogOut";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router";
// import SignOutPopUp from "../SignOutComponents/SignOutPopUp";
import { useAppSelector } from "../../utils/redux-hooks";
import WalletIconSvg from "../../assets/svgs/WalletIconSvg";
// import { fetchManagePermissions } from "../../redux/slices/ManagePermissions/ManagePermissionsSlice";
// import SellIcon from "@mui/icons-material/Sell";
import PaymentsIcon from "@mui/icons-material/Payments";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";

const useStyles = makeStyles({
  container: {
    backgroundColor: "#F8F8F8",
    height: "100vh",
    textAlign: "center",
  },
  sideBarContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
    alignItems: "center",
  },
  manageItem: {
    cursor: "pointer",
    padding: "5px",
  },
  activeItem: {
    backgroundColor: "#f0f0f0",
    color: "#303030 !important",
    "& .MuiTypography-root": {
      fontWeight: "600 !important",
    },
  },
  signOutContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  textColor: {
    color: "#4B5A5A",
    fontSize: "20px",
  },
  blackColor: {
    color: "#000000",
    fontWeight: "600 !important",
  },
  sideBar: {
    position: "sticky",
    top: 0,
    height: "100vh",
    overflowY: "auto",
  },
});

const WalletSideBar = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  // const dispatch = useAppDispatch();
  const [activeSection, setActiveSection] = useState("");
  // const getManageAccount = useAppSelector(
  //   (state: any) => state.manageAccountData
  // );
  // const data = getManageAccount?.data;

  // Function to handle navigation and set active section
  const handleNavigation = (path: string) => {
    navigate(`/${path}`);
    setActiveSection(path);
  };

  // const [openDialog, setOpenDialog] = useState(false);

  // const handleOpenDialog = () => {
  //   setOpenDialog(true);
  // };

  // const handleCloseDialog = () => {
  //   setOpenDialog(false);
  // };

  useEffect(() => {
    const path = location.pathname;
    setActiveSection(path || "");
  }, [location.pathname]);
  // console.log("activeSection", activeSection);

  // const loginData = useAppSelector((state: any) => state.adminLogin.data);
  // const roleId = loginData?.roleId;
  // const companyId = loginData?.companyId;

  // const permissions = useAppSelector((state) => state.managePermissions.data);
  // const allowAccessToBillingSection = permissions?.[2]?.access;

  // useEffect(() => {
  // dispatch(fetchManagePermissions({ roleId: roleId, companyId }));
  // }, [dispatch, loginData]);

  return (
    <Grid className={classes.container}>
      <Box textAlign="start" pl={1} className={classes.sideBarContainer} pt={3}>
        <Box m={2} mb={1}>
          <WalletIconSvg />
        </Box>
        <Typography variant="h6" className={classes.blackColor}>
          Wallet
        </Typography>
      </Box>
      <Box>
        <Box
          m={2}
          pl={1}
          textAlign="start"
          className={`${classes.manageItem} ${classes.textColor} ${
            activeSection.includes("wallet") &&
            !activeSection.includes("billing-details")
              ? classes.activeItem
              : ""
          }`}
          onClick={() => handleNavigation("wallet")}
        >
          <Typography
            fontSize="14px"
            sx={{ display: "flex", alignItems: "center" }}
          >
            <span style={{ marginTop: "3px" }}>
              <PaymentsIcon sx={{ marginTop: "2px", height: "18px" }} />
            </span>
            &nbsp;Wallet
          </Typography>
        </Box>
        {/* {allowAccessToBillingSection&& */}
        <Box
          m={2}
          pl={1}
          textAlign="start"
          className={`${classes.manageItem} ${classes.textColor} ${
            activeSection.includes("billing-details") ? classes.activeItem : ""
          }`}
          onClick={() => handleNavigation("billing-details")}
        >
          <Typography
            fontSize="14px"
            sx={{ display: "flex", alignItems: "center" }}
          >
            <span style={{ marginTop: "5px" }}>
              <ReceiptLongIcon sx={{ marginTop: "2px", height: "18px" }} />
            </span>
            &nbsp;Billing Details
          </Typography>
        </Box>
        {/* } */}
      </Box>
      {/* <Box
          className={`${classes.signOutContainer} ${classes.manageItem} ${classes.textColor
            } ${activeSection === "signOut" ? classes.activeItem : ""}`}
        >
          <LogOut />
          <Typography variant="body1" ml={2} onClick={handleOpenDialog}>
            Sign Out
          </Typography>
          <SignOutPopUp open={openDialog} handleClose={handleCloseDialog} />
        </Box> */}
    </Grid>
  );
};

export default WalletSideBar;
