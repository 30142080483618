import { Badge, Box, Grid, Snackbar, Tooltip, Typography } from "@mui/material";
// import AhexLogoSvg from "../../assets/svgs/AhexLogoSvg";
import InboxSvg from "../../assets/svgs/InboxSvg";
import ContactSvg from "../../assets/svgs/ContactSvg";
import AnalyticsSvg from "../../assets/svgs/Analytics";
import AutomationSvg from "../../assets/svgs/AutomationSvg";
import TemplateSvg from "../../assets/svgs/TemplateSvg";
import StoreIcon from "@mui/icons-material/Store";
import WidgetsOutlinedIcon from "@mui/icons-material/WidgetsOutlined";
import { makeStyles } from "@mui/styles";
import { useLocation, useNavigate } from "react-router-dom";
import CampaignsSvg from "../../assets/svgs/CampaignsSvg";
import WalletSvg from "../../assets/svgs/WalletSvg";
import React, { MouseEventHandler, useState } from "react";
import { useAppSelector } from "../../utils/redux-hooks";
// import { fetchCurrentPlanDetails } from "../../redux/slices/Subscription/SubscriptionSlice";
import { bgColors } from "../../utils/bgColors";
// import EngagetoLogoSvg from "../../assets/svgs/EngagetoLogoSvg";
import EngagetoSmallLogoSvg from "../../assets/svgs/EngagetoSmallLogoSvg";
import {
  checkAllTemplatesPermission,
  checkOnetimeCampaignsPermission,
  checkSceduledCampaignsPermission,
  checkTemplatesLibraryPermission,
} from "../../utils/permissions";

const useStyles = makeStyles({
  formatContainer: {
    backgroundColor: bgColors.white2,
    textAlign: "center",
    display: "flex",
    overflowY: "auto",
    // scrollbarWidth: "thin",
  },
  lineStyles: {
    border: "none",
    borderTop: "3px solid #4B5A5A",
    width: "80%",
    margin: "0 auto",
    opacity: "20%",
  },
  cursorClass: {
    cursor: "pointer",
    opacity: "60%",
  },
  selected: {
    opacity: "100%",
  },
});

interface Classes {
  cursorClass: string;
  selected: string;
}

const renderBoxWithTooltip = (
  hasPermission: any,
  handleClick: MouseEventHandler<HTMLDivElement> | undefined,
  SvgIcon: any,
  label: string | undefined,
  path: string,
  classes: Classes,
  location: ReturnType<typeof useLocation>,
  openTooltip: any,
  setOpenTooltip: React.Dispatch<React.SetStateAction<any>>
) => (
  <Tooltip
    title="Access Denied"
    arrow
    placement="right"
    open={
      openTooltip?.toggle &&
      openTooltip?.label === label &&
      ((label !== "Contacts" && hasPermission?.length === 0) ||
        hasPermission === undefined)
    } // Show tooltip on click if permission is not granted
  >
    <Box
      mb={{ xs: 1, md: 2.5 }}
      mx={{ xs: 1.5, md: 0 }}
      mt={{ xs: 3, md: 0 }}
      className={`${classes.cursorClass} ${
        location.pathname.includes(path) && classes.selected
      }`}
      onClick={(event) => {
        if (
          (label !== "Contacts" && hasPermission?.length === 0) ||
          hasPermission === undefined
        ) {
          setOpenTooltip({ toggle: true, label: label });
          setTimeout(() => setOpenTooltip({ toggle: false, label: "" }), 3000); // Hide tooltip after 3 seconds
        } else {
          handleClick?.(event);
        }
      }}
      sx={{
        cursor:
          (label !== "Contacts" && hasPermission?.length === 0) ||
          hasPermission === undefined
            ? "not-allowed"
            : "pointer",
      }}
    >
      <SvgIcon />
      <Typography
        sx={{
          fontSize: "12px",
          display: { xs: "none", md: "flex" },
          justifyContent: "center",
        }}
        color="#4B5A5A"
      >
        {label}
      </Typography>
    </Box>
  </Tooltip>
);

const MainSideBar = (props: any) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  // const dispatch = useAppDispatch();

  const loginData = useAppSelector((state: any) => state.adminLogin.data);
  const getuserPermissionSlice = useAppSelector(
    (state: any) => state.getUserPermissions
  );
  const accountData = useAppSelector((state: any) => state?.accountData?.data);
  const getuserPermissionData = getuserPermissionSlice?.data;
  // const userId = loginData?.userId;
  // const permissionsSlice = useAppSelector((state) => state.managePermissions);
  // const permissions = permissionsSlice?.data;
  const templatesPermissions = getuserPermissionData?.Templates;
  const campaignsPermissions = getuserPermissionData?.campaigns;
  // const allowToViewAnalyticsPage = permissions?.[14]?.access;
  const currentPlanDetails = useAppSelector(
    (state: any) => state?.wallet?.walletAndSubscription?.data
  );
  // const currentPlanName = currentPlanDetails?.data?.planDetails?.planName;

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openTooltip, setOpenTooltip] = useState({ toggle: false, label: "" });

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleNavigation = (path: string) => {
    navigate(path);
  };

  const hasTemplatesLibraryPermission =
    checkTemplatesLibraryPermission(templatesPermissions);
  const hasAllTemplatesPermission =
    checkAllTemplatesPermission(templatesPermissions);
  const hasOnetimeCampaignsPermission =
    checkOnetimeCampaignsPermission(campaignsPermissions);
  const hasScheduledCampaignsPermission =
    checkSceduledCampaignsPermission(campaignsPermissions);

  const handleProfileClick = () => {
    handleNavigation(`/profile/manage-account/${loginData?.userId}`);
  };

  const handleWalletClick = () => {
    handleNavigation("/wallet");
  };

  const handleAnalyticsClick = () => {
    handleNavigation("/analytics/overview");
  };

  const handleCommerceClick = () => {
    handleNavigation("/commerce-settings");
  };

  const handleIntegrationsClick = () => {
    handleNavigation("/whatsapp-link");
  };

  const handleTemplateClick = () => {
    if (hasTemplatesLibraryPermission) {
      handleNavigation("/templates-library");
    } else if (hasAllTemplatesPermission) {
      handleNavigation("/templates-all");
    }
  };

  const handleAutomationClick = () => {
    handleNavigation("/automation/inbox-settings");
  };

  const handleContactsClick = () => {
    handleNavigation("/contacts");
  };

  const handleCampaignClick = () => {
    if (hasOnetimeCampaignsPermission) {
      handleNavigation("/campaigns/one-time");
    } else if (hasScheduledCampaignsPermission) {
      handleNavigation("/campaigns/scheduled");
    }
  };

  const handleInboxClick = () => {
    navigate("/inbox/help", {});
  };

  // console.log("location", location.pathname.includes("templates"));

  return (
    <Grid
      sx={{
        flexDirection: { xs: "row", md: "column" },
        height: { xs: "67px", md: "100vh !important" },
        paddingLeft: { xs: "10px", md: "0px" },
        overflowX: "hidden",
      }}
      className={classes.formatContainer}
    >
      {/* py={{ xs: 1, md: 2.5 }} */}
      <Box pt={{ xs: 1, md: 2 }} pb={1}>
        <EngagetoSmallLogoSvg />
      </Box>
      <hr className={classes.lineStyles} />
      {(accountData?.companyVerificationStatus &&
        accountData?.isMetaEnabled &&
        currentPlanDetails?.subscriptionPlan?.isActive) ||
      (accountData?.companyVerificationStatus === false &&
        accountData?.isMetaEnabled === false &&
        currentPlanDetails?.subscriptionPlan?.isActive &&
        currentPlanDetails?.subscriptionPlan?.planName === "Intro") ? (
        <Box>
          {/* {getuserPermissionData?.inbox && ( */}
          <Box
            mt={{ xs: 3, md: 2 }}
            mb={{ xs: 0, md: 2.5 }}
            mx={{ xs: 1.5, md: 0 }}
            className={`${classes.cursorClass} ${
              location.pathname.includes("/inbox/") &&
              !location.pathname.includes("/inbox-settings") &&
              classes.selected
            }`}
            onClick={handleInboxClick}
          >
            <Badge
              badgeContent={props?.totalUnreads}
              color="success"
              sx={{
                "& .MuiBadge-badge": {
                  fontSize: "10px", // Adjust the font size here
                  height: "16px",
                  minWidth: "16px",
                },
              }}
            >
              <InboxSvg />
            </Badge>
            <Typography
              sx={{
                fontSize: "12px",
                display: { xs: "none", md: "flex" },
                justifyContent: "center",
              }}
              color="#4B5A5A"
            >
              Inbox
            </Typography>
          </Box>
          {/* )} */}
          {
            // getuserPermissionData?.campaigns &&
            renderBoxWithTooltip(
              getuserPermissionData.campaigns,
              handleCampaignClick,
              CampaignsSvg,
              "Campaigns",
              "/campaigns/",
              classes,
              location,
              openTooltip,
              setOpenTooltip
            )
          }
          {getuserPermissionData?.contacts &&
            renderBoxWithTooltip(
              getuserPermissionData.contacts,
              handleContactsClick,
              ContactSvg,
              "Contacts",
              "/contacts",
              classes,
              location,
              openTooltip,
              setOpenTooltip
            )}
          {getuserPermissionData?.automation &&
            renderBoxWithTooltip(
              getuserPermissionData.automation,
              handleAutomationClick,
              AutomationSvg,
              "Automation",
              "/automation",
              classes,
              location,
              openTooltip,
              setOpenTooltip
            )}
          {getuserPermissionData?.Templates &&
            renderBoxWithTooltip(
              getuserPermissionData.Templates,
              handleTemplateClick,
              TemplateSvg,
              "Templates",
              "/templates",
              classes,
              location,
              openTooltip,
              setOpenTooltip
            )}
          {getuserPermissionData?.wallet &&
            renderBoxWithTooltip(
              getuserPermissionData.wallet,
              handleWalletClick,
              WalletSvg,
              "Wallet",
              "/wallet",
              classes,
              location,
              openTooltip,
              setOpenTooltip
            )}
          {!getuserPermissionData?.analytics &&
            renderBoxWithTooltip(
              !getuserPermissionData.analytics,
              handleAnalyticsClick,
              AnalyticsSvg,
              "Analytics",
              "/analytics",
              classes,
              location,
              openTooltip,
              setOpenTooltip
            )}
          {!getuserPermissionData?.commerce &&
            renderBoxWithTooltip(
              !getuserPermissionData.commerce,
              handleCommerceClick,
              StoreIcon,
              "Commerce",
              "/commerce",
              classes,
              location,
              openTooltip,
              setOpenTooltip
            )}
          {!getuserPermissionData?.integrations &&
            renderBoxWithTooltip(
              !getuserPermissionData.integrations,
              handleIntegrationsClick,
              WidgetsOutlinedIcon,
              "Integrations",
              "/integrations",
              classes,
              location,
              openTooltip,
              setOpenTooltip
            )}
        </Box>
      ) : (
        <Box>
          <Box
            mt={{ xs: 3, md: 2 }}
            mb={{ xs: 0, md: 2.5 }}
            mx={{ xs: 1.5, md: 0 }}
            className={`${classes.cursorClass} ${
              location.pathname.includes("/inbox") &&
              !location.pathname.includes("/inbox-settings") &&
              classes.selected
            }`}
            sx={{ cursor: "not-allowed" }}
            onClick={() => {
              setOpenTooltip({
                toggle: true,
                label: "Inbox",
              });
              setTimeout(
                () => setOpenTooltip({ toggle: false, label: "" }),
                3000
              );
            }}
          >
            <Badge
              badgeContent={props?.totalUnreads}
              color="success"
              sx={{
                "& .MuiBadge-badge": {
                  fontSize: "10px", // Adjust the font size here
                  height: "16px",
                  minWidth: "16px",
                },
              }}
            >
              <InboxSvg />
            </Badge>
            <Tooltip
              title="Access Denied"
              arrow
              placement="right"
              open={openTooltip?.toggle && openTooltip?.label === "Inbox"} // Show tooltip on click if permission is not granted
            >
              <Typography
                sx={{
                  fontSize: "12px",
                  display: { xs: "none", md: "flex" },
                  justifyContent: "center",
                }}
                color="#4B5A5A"
              >
                Inbox
              </Typography>
            </Tooltip>
          </Box>
          {renderBoxWithTooltip(
            undefined,
            undefined, // No click handler since access is denied
            CampaignsSvg,
            "Campaigns",
            "/campaigns",
            classes,
            location,
            openTooltip,
            setOpenTooltip
          )}
          {renderBoxWithTooltip(
            undefined,
            undefined,
            ContactSvg,
            "Contacts",
            "/contacts",
            classes,
            location,
            openTooltip,
            setOpenTooltip
          )}
          {renderBoxWithTooltip(
            undefined,
            undefined,
            AutomationSvg,
            "Automation",
            "/automation",
            classes,
            location,
            openTooltip,
            setOpenTooltip
          )}
          {renderBoxWithTooltip(
            undefined,
            undefined,
            TemplateSvg,
            "Templates",
            "/templates",
            classes,
            location,
            openTooltip,
            setOpenTooltip
          )}
          {renderBoxWithTooltip(
            undefined,
            undefined,
            WalletSvg,
            "Wallet",
            "/wallet",
            classes,
            location,
            openTooltip,
            setOpenTooltip
          )}
          {renderBoxWithTooltip(
            undefined,
            undefined,
            AnalyticsSvg,
            "Analytics",
            "/analytics",
            classes,
            location,
            openTooltip,
            setOpenTooltip
          )}
          {!getuserPermissionData?.commerce &&
            renderBoxWithTooltip(
              !getuserPermissionData?.commerce,
              handleCommerceClick,
              StoreIcon,
              "Commerce",
              "/commerce",
              classes,
              location,
              openTooltip,
              setOpenTooltip
            )}
          {!getuserPermissionData?.commerce &&
            renderBoxWithTooltip(
              !getuserPermissionData?.commerce,
              handleIntegrationsClick,
              WidgetsOutlinedIcon,
              "Integrations",
              "/integrations",
              classes,
              location,
              openTooltip,
              setOpenTooltip
            )}
        </Box>
      )}

      <Box
        mb={{ xs: 1, md: 2 }}
        px={{ xs: 1.5, md: 0 }}
        mt={{ xs: 2, md: "auto" }}
        className={`${classes.cursorClass} ${
          location.pathname.includes("/profile") && classes.selected
        }`}
        onClick={() => {
          handleProfileClick();
        }}
      >
        <img
          alt="profile"
          src={
            localStorage.getItem("userImage")
              ? localStorage.getItem("userImage")
              : loginData?.userImage || "/images/profile.png"
          }
          style={{ width: "36px", height: "36px", borderRadius: "2px" }}
        />
        <Typography
          sx={{
            fontSize: "12px",
            display: { xs: "none", md: "flex" },
            justifyContent: "center",
          }}
          color="#4B5A5A"
        >
          Profile
        </Typography>
      </Box>
      {/* )} */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message="You don't have permission to access this section."
      />
    </Grid>
  );
};

export default MainSideBar;
