import React, { useState } from "react";
import {
  Box,
  TextField,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Button,
  Paper,
  Grid,
  InputAdornment,
  InputLabel,
} from "@mui/material";
import TextFieldWithBorderComponent from "../common/TextFieldWithBorderComponent";
import { bgColors } from "../../utils/bgColors";
import { makeStyles } from "@mui/styles";
import InfoIconComponent from "./InfoIcon";
import ColorPickerField from "./ColorPicker";

const useStyles = makeStyles({
  mainContainer: {
    backgroundColor: bgColors.white1,
    padding: "14px",
    height: "100%",
    width: "100%",
    // overflowY: "auto",
  },
  bgContainer: {
    backgroundColor: bgColors.white,
    borderRadius: "25px",
    height: "100%",
    width: "100%",
  },
  manageContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  SaveChangesButton: {
    color: bgColors.green,
    border: `1px solid ${bgColors.green}`,
    // backgroundColor: bgColors.blue,
    borderRadius: "8px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
    cursor: "pointer",
    width: "120px",
    height: "32px",
    gap: "0px",
  },
  mainBorderStyles: {
    borderRadius: "18px",
    border: `2px solid ${bgColors.gray5}`,
  },
  emailContainer: {
    display: "flex",
    gap: "10px",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    // gap: "10px"
  },
  blackColor: {
    color: `${bgColors.black1} !important`,
    fontWeight: "500 !important",
  },
  flexDirection: {
    display: "flex",
    alignItems: "center",
  },
});

const WhatsAppWidgetConfig = ({
  config,
  handleChange,
  handleColorChange,
}: {
  config: any;
  handleChange: (field: string) => (event: any) => void;
  handleColorChange: (color: any) => void;
}) => {
  // console.log(config, "WidgetConfig");
  const classes = useStyles();

  return (
    // <Paper elevation={3} sx={{ p: 3, maxWidth: 600, margin: "auto" }}>
    <Box
      sx={{
        display: "flex",
        gap: "45px",
        marginTop: "30px",
      }}
    >
      <Box sx={{ marginLeft: "10px" }}>
        <Typography>Chat Widget</Typography>
        <Typography
          sx={{ color: "#6E6E6E", fontSize: "12px", fomtWeight: "400" }}
        >
          You can personalize the user message based on your website's URL
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "30px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "30px",
          }}
        >
          <Box>
            <Box className={classes.flexDirection}>
              <Typography
                variant="body1"
                className={classes.blackColor}
                sx={{ fontSize: { xs: 12, md: 14 }, mb: 1 }}
              >
                BrandName
              </Typography>
              <Box>
                <InfoIconComponent tooltip="Your brand name to be displayed in the widget." />
              </Box>
            </Box>
            <TextFieldWithBorderComponent
              placeholder=""
              label="Enter brand name"
              name="name"
              // size="small"
              value={config.brandName}
              // sx={{fontSize:"22px",fontWeight:"600 !important"}}
              onChange={handleChange("brandName")}
              sx={{ width: "280px" }}
              // fontweight="bold"
              //   error={!!valid.name}
              //   helperText={valid.name}
            />
          </Box>
          <Box>
            <Box className={classes.flexDirection}>
              <Typography
                variant="body1"
                className={classes.blackColor}
                sx={{ fontSize: { xs: 12, md: 14 }, mb: 1 }}
              >
                Brand Subtitle
              </Typography>
              <Box>
                <InfoIconComponent tooltip="Status of brand to be displayed to the user. ex. - Typically replies in minutes." />
              </Box>
            </Box>
            <TextFieldWithBorderComponent
              placeholder=""
              label="Enter subtitle here"
              name="name"
              // size="small"
              value={config.brandSubtitle}
              // sx={{fontSize:"22px",fontWeight:"600 !important"}}
              onChange={handleChange("brandSubtitle")}
              sx={{ width: "280px" }}
              // fontweight="bold"
              //   error={!!valid.name}
              //   helperText={valid.name}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "30px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box className={classes.flexDirection}>
              <Typography
                variant="body1"
                className={classes.blackColor}
                sx={{ fontSize: { xs: 12, md: 14 }, mb: 1 }}
              >
                Brand Color
              </Typography>
              <Box>
                <InfoIconComponent tooltip="Pick color which resonates with the primary color in your website." />
              </Box>
            </Box>
            {/* <Box
              sx={{
                border: "1px solid #ccc", // Add border to make it look like a field
                borderRadius: 2, // Round the corners
                padding: "8px", // Add padding
                display: "flex",
                alignItems: "center",
                width: "280px", // Adjust width to fit content
                height: "40px",
                backgroundColor: "#fff", // Background color for input-like appearance
                marginTop: "9px",
              }}
            >
              <ColorPicker
                value={config.brandColor}
                onChange={handleColorChange}
                // sx={{
                //   "& .MuiInputBase-root": {
                //     borderBottom: "none", // Removes the underline for the input
                //   },
                // }}
              />
            </Box> */}
            <Box sx={{ marginTop: "8px" }}>
              <ColorPickerField
                value={config.brandColor}
                onChange={handleColorChange}
              />
            </Box>
          </Box>
          <Box>
            <Box className={classes.flexDirection}>
              <Typography
                variant="body1"
                className={classes.blackColor}
                sx={{ fontSize: { xs: 12, md: 14 }, mb: 1 }}
              >
                Widget CTA Text
              </Typography>
              <Box>
                <InfoIconComponent tooltip="CTA text to be displayed inside the widget." />
              </Box>
            </Box>
            <TextFieldWithBorderComponent
              placeholder=""
              label="Enter widget CTA"
              name="name"
              // size="small"
              value={config.widgetCtaText}
              // sx={{fontSize:"22px",fontWeight:"600 !important"}}
              onChange={handleChange("widgetCtaText")}
              sx={{ width: "280px" }}
              // fontweight="bold"
              //   error={!!valid.name}
              //   helperText={valid.name}
            />
          </Box>
        </Box>
        <Box>
          <Box className={classes.flexDirection}>
            <Typography
              variant="body1"
              className={classes.blackColor}
              sx={{ fontSize: { xs: 12, md: 14 }, mb: 1 }}
            >
              Brand Image Url
            </Typography>
            <Box>
              <InfoIconComponent tooltip="Profile image of brand to displayed in the widget." />
            </Box>
          </Box>
          <TextFieldWithBorderComponent
            placeholder=""
            label="Enter image url"
            name="name"
            // size="small"
            value={config.brandImageUrl}
            // sx={{fontSize:"22px",fontWeight:"600 !important"}}
            onChange={handleChange("brandImageUrl")}
            // fontweight="bold"
            //   error={!!valid.name}
            //   helperText={valid.name}
          />
        </Box>
        <Box>
          <Box className={classes.flexDirection}>
            <Typography
              variant="body1"
              className={classes.blackColor}
              sx={{ fontSize: { xs: 12, md: 14 }, mb: 1 }}
            >
              Default On-screen Message
            </Typography>
            <Box>
              <InfoIconComponent tooltip="Business message to be displayed in the widget." />
            </Box>
          </Box>

          <TextField
            fullWidth
            label="Enter user's Pre-filled message"
            variant="outlined"
            value={config.defaultOnScreenMessage}
            onChange={handleChange("defaultOnScreenMessage")}
            multiline
            rows={2}
          />
          <Typography
            sx={{ color: "#6E6E6E", fontSize: "12px", fontWeight: "400" }}
          >
            You can use defaults - {"{{ page_url }} & {{ page_title }}"}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", gap: "25px" }}>
          <FormControl component="fieldset">
            <Box className={classes.flexDirection}>
              <Typography
                variant="body1"
                className={classes.blackColor}
                sx={{ fontSize: { xs: 12, md: 14 }, mb: 1 }}
              >
                Open widget on Mobile screen
              </Typography>
              <Box>
                <InfoIconComponent tooltip="If checked No, User will be redirected to WhatsApp on button click." />
              </Box>
            </Box>
            <RadioGroup
              row
              value={config.openWidgetOnMobileScreen}
              onChange={handleChange("openWidgetOnMobileScreen")}
            >
              <FormControlLabel
                value="yes"
                control={
                  <Radio
                    sx={{
                      "&.Mui-checked": {
                        color: "green", // Set checked radio button color to green
                      },
                    }}
                  />
                }
                label="Yes"
              />
              <FormControlLabel
                value="no"
                control={
                  <Radio
                    sx={{
                      "&.Mui-checked": {
                        color: "green", // Set checked radio button color to green
                      },
                    }}
                  />
                }
                label="No"
              />
            </RadioGroup>
          </FormControl>
          <FormControl component="fieldset">
            <Box className={classes.flexDirection}>
              <Typography
                variant="body1"
                className={classes.blackColor}
                sx={{ fontSize: { xs: 12, md: 14 }, mb: 1 }}
              >
                Open widget by default
              </Typography>
              <Box>
                <InfoIconComponent tooltip="If checked No, Widget will open only after button click." />
              </Box>
            </Box>
            <RadioGroup
              row
              value={config.openWidgetByDefault}
              onChange={handleChange("openWidgetByDefault")}
            >
              <FormControlLabel
                value="yes"
                control={
                  <Radio
                    sx={{
                      "&.Mui-checked": {
                        color: "green", // Set checked radio button color to green
                      },
                    }}
                  />
                }
                label="Yes"
              />
              <FormControlLabel
                value="no"
                control={
                  <Radio
                    sx={{
                      "&.Mui-checked": {
                        color: "green", // Set checked radio button color to green
                      },
                    }}
                  />
                }
                label="No"
              />
            </RadioGroup>
          </FormControl>
          {/* <FormControl component="fieldset">
            <Box className={classes.flexDirection}>
              <Typography
                variant="body1"
                className={classes.blackColor}
                sx={{ fontSize: { xs: 12, md: 14 }, mb: 1 }}
              >
                Re-open widget by default
              </Typography>
              <Box>
                <InfoIconComponent tooltip="Once the user closes the widget, Set re-open behavior of opening widget by default on next time page load." />
              </Box>
            </Box>
            <RadioGroup
              row
              value={config.reOpenWidgetByDefault}
              onChange={handleChange("reOpenWidgetByDefault")}
            >
              <FormControlLabel
                value="yes"
                control={
                  <Radio
                    sx={{
                      "&.Mui-checked": {
                        color: "green", // Set checked radio button color to green
                      },
                    }}
                  />
                }
                label="Yes"
              />
              <FormControlLabel
                value="no"
                control={
                  <Radio
                    sx={{
                      "&.Mui-checked": {
                        color: "green", // Set checked radio button color to green
                      },
                    }}
                  />
                }
                label="No"
              />
            </RadioGroup>
          </FormControl> */}
        </Box>
      </Box>
    </Box>
    // </Paper>
  );
};

export default WhatsAppWidgetConfig;
