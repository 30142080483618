import React from "react";
import { Box, Typography, IconButton } from "@mui/material";
import { Send as SendIcon } from "@mui/icons-material";
import ReactDevicePreview from "react-device-preview";
import PhoneIcon from "@mui/icons-material/Phone";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import TemplatePreviewLayout from "./templatePreviewLayout";
import { useAppSelector } from "../../../utils/redux-hooks";

interface DevicePreviewProps {
  canEdit?: boolean;
  header?: string;
  body?: string;
  footer?: string;
  mediaType?: number;
  mediaFile?: any;
  buttons: {
    buttonType: string;
    buttonValue: string;
    buttonName?: string; // Optional property
  }[];
}

const DevicePreviewComponent: React.FC<DevicePreviewProps> = ({
  header,
  body,
  footer,
  mediaType,
  mediaFile,
  buttons,
}) => {
  const companyData = useAppSelector((state) => state?.companyData?.data);

  return (
    <ReactDevicePreview device="iphonex" color="black" scale="0.75">
      <Box
        sx={{
          backgroundImage: `url("/images/whatsappBG.jpg")`,
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* iPhone X-like status bar */}
        <Box
          style={{
            background: "#f0f0f0",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "10px 20px",
          }}
        >
          {/* Left side */}
          <Box style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="body2">22:30</Typography>
          </Box>

          {/* Right side */}
          <Box style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="body2">5G</Typography>
            <Typography variant="body2" sx={{ marginLeft: "8px" }}>
              80%
            </Typography>
          </Box>
        </Box>

        {/* To-contact name */}
        <Box
          style={{
            background: "#f0f0f0",
            padding: "10px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <img
            src="/images/profile.png" // Sample profile pic
            alt="Profile"
            style={{ width: "30px", height: "30px", borderRadius: "50%" }}
          />
          <Typography ml={1} sx={{ fontSize: 14, fontWeight: "bold" }}>
            {companyData?.company?.businessName}
          </Typography>
          {/* Phone and video call icons */}
          <Box style={{ marginLeft: "auto" }}>
            <IconButton aria-label="video call">
              <VideoCallIcon />
            </IconButton>
            <IconButton aria-label="phone call">
              <PhoneIcon />
            </IconButton>
          </Box>
        </Box>
        <Box
          style={{
            background: "#fff",
            borderRadius: "10px",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            overflow: "scroll",
            height: "auto",
            margin: "8px 10px",
          }}
        >
          <TemplatePreviewLayout
            header={header}
            body={body}
            footer={footer}
            mediaType={mediaType}
            mediaFile={mediaFile}
            buttons={buttons}
          />
        </Box>
        {/* Send bar */}
        <Box
          m={2}
          style={{
            background: "#ffffff",
            border: "1px solid #999",
            display: "flex",
            alignItems: "center",
            borderRadius: "10px",
            marginTop: "auto",
          }}
        >
          {/* Spacer */}
          <Box style={{ flex: 1 }}></Box>

          {/* Send button */}
          <IconButton
            style={{ marginRight: "4px", color: "#075e54" }}
            aria-label="send"
          >
            <SendIcon />
          </IconButton>
        </Box>
      </Box>
    </ReactDevicePreview>
  );
};

export default DevicePreviewComponent;
