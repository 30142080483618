import React from "react";
import AppRoutes from "./routes/AppRoutes";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./Theme";
import { CssBaseline } from "@mui/material";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import { useAppSelector } from "./utils/redux-hooks";
import ToastComponent from "./components/common/ToastComponent";

function App() {
  const toaster = useAppSelector((state:any) => state.toastState);
  const isToastFound = toaster.type && toaster.message;

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {isToastFound ? (
          <ToastComponent type={toaster?.type} message={toaster?.message} />
        ) : null}
        <AppRoutes />
      </ThemeProvider>
    </>
  );
}
export default App;
