/* global process */

import axios from "axios";

const USER_API_URL = process.env.REACT_APP_BASE_URL

const getCompanyDetails = (id: any) => {
  return axios({
    url: `${USER_API_URL}/CompanyDetails/get-company/${id}`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
  });
};
const getAllManageCompanies = () => {
  return axios({
    url: `${USER_API_URL}/CompanyDetails/get-all-companies`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
  });
};

const getAllTeamMembrers = () => {
  return axios({
    url: `${USER_API_URL}/TeamDetails/get-all`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
  });
};

const getAllTeamMembersByCompanyId = (data: any) => {
  return axios({
    url: `${USER_API_URL}/TeamDetails/${data?.companyId}?searchQuery=${data?.search}`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
  });
};

const addTeamMember = (formData: any) => {
  return axios({
    url: `${USER_API_URL}/TeamDetails/add-teammember`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
    data: formData,
  });
};

const displayAllroles = (data: any) => {
  return axios({
    url: `${USER_API_URL}/Role/DisplayAllRoles/${data}`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
  });
};

const createRoles = (data: any) => {
  return axios({
    url: `${USER_API_URL}/Role`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
    // data: JSON.stringify({ data: data }),
    data: {
      name: data?.name,
      companyId: data?.companyId
    },

    // data: data,
  });
};

// const editCompanyDetails = (data: any) => {
//   return axios({
//     url: `${USER_API_URL}/CompanyDetails/update-company/${data?.companyId}`,
//     method: "PUT",
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: localStorage.getItem("token"),
//     },
//     data: data,
//   });
// };

const editCompanyDetails = ({
  email,
  clientWebsite,
  address,
  businessCategory,
  countryCode,
  phoneNumber,
  gstNumber,
  countryName,
  description,
  companyName,
  companyLegalName,
  companyRegistered,
  companyType,
  customerCareEmail,
  customerCarePhone,
  customerCareCountryCode,
  grievanceOfficerName,
  grievanceOfficerPhone,
  grievanceOfficerCountryCode,
  grievanceOfficerEmail,
  companyId,
  companyLogoLink,
}: {
  email: string;
  clientWebsite: string;
  address: string;
  businessCategory: string;
  phoneNumber: string;
  countryCode: string;
  gstNumber: string;
  countryName: string;
  description: string;
  companyName: string;
  companyLegalName: string;
  companyRegistered: string;
  companyType: string;
  customerCareEmail: string;
  customerCarePhone: string;
  customerCareCountryCode: string;
  grievanceOfficerName: string;
  grievanceOfficerPhone: string;
  grievanceOfficerCountryCode: string;
  grievanceOfficerEmail: string;
  companyId: string;
  companyLogoLink: any;
}) => {
  const formData = new FormData();

  // Append fields to the formData object
  formData.append("businessEmail", email);
  formData.append("businessWebsite", clientWebsite);
  formData.append("companyAddress", address);
  formData.append("businessCategory", businessCategory);
  formData.append("phoneNumber", phoneNumber);
  formData.append("countryCode", countryCode);
  formData.append("gstNumber", gstNumber);
  formData.append("countryName", countryName);
  formData.append("description", description);
  formData.append("businessName", companyName);
  formData.append("companyLegalName", companyLegalName);
  formData.append("companyRegistered", companyRegistered);
  formData.append("companyType", companyType);
  formData.append("customerCareEmail", customerCareEmail);
  formData.append("customerCarePhone", customerCarePhone);
  formData.append("customerCareCountryCode", customerCareCountryCode);
  formData.append("grievanceOfficerName", grievanceOfficerName);
  formData.append("grievanceOfficerPhone", grievanceOfficerPhone);
  formData.append("grievanceOfficerCountryCode", grievanceOfficerCountryCode);
  formData.append("grievanceOfficerEmail", grievanceOfficerEmail);
  formData.append("id", companyId);
  formData.append("image", companyLogoLink);

  return axios({
    url: `${USER_API_URL}/CompanyDetails/update-company/${companyId}`,
    method: "PUT",
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: localStorage.getItem("token"),
    },
    data: formData,
  });
};

const editTeamMember = (teamMemberId: string, data: any) => {
  return axios({
    url: `${USER_API_URL}/TeamDetails/updateTeamMember/${teamMemberId}`,
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
    data: data,
  });
};

const deleteTeamMember = (teamMemberId: any) => {
  return axios({
    url: `${USER_API_URL}/TeamDetails/delete-team-member/${teamMemberId}`,
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
  });
};

export const ADMIN_MANAGE_COMPANY_APIS = {
  getAllManageCompanies,
  getAllTeamMembrers,
  getAllTeamMembersByCompanyId,
  addTeamMember,
  displayAllroles,
  createRoles,
  editTeamMember,
  deleteTeamMember,
  editCompanyDetails,
  getCompanyDetails,
};
