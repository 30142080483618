import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import TextFeildWithBorderComponet from "../../../components/common/TextFieldWithBorderComponent";
import PhoneInput from "../../../components/common/PhoneInput";
import { bgColors } from "../../../utils/bgColors";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../utils/redux-hooks";
// import { updateCompanyDetails } from "../../../redux/slices/ManageCompany/UpdateCompanyDetails";
import { fetchCompanyDetails } from "../../../redux/slices/ManageCompany/CompanyDetailsSlice";
// import { fetchGetAllCountries } from "../../../redux/slices/ManageAccount/GetAllCountryDetails";
// import { fetchAllCountryCodes } from "../../../redux/slices/ManageAccount/GetAllCountryCode";
import { ADMIN_MANAGE_COMPANY_APIS } from "../../../Apis/ManageCompany/ManageCompany";
import { toastActions } from "../../../utils/toastSlice";
import LoadingComponent from "../../../components/common/LoadingComponent";
import RoomPreferencesIcon from "@mui/icons-material/RoomPreferences";
import NoAccessPage from "../../../components/common/NoAccess";
import { validatePhoneNumber } from "../../../utils/functions";

const useStyles = makeStyles({
  mainContainer: {
    backgroundColor: bgColors.white1,
    padding: "14px",
    height: "100%",
    width: "100%",
    overflowY: "auto",
  },
  bgContainer: {
    backgroundColor: bgColors.white,
    borderRadius: "25px",
    height: "100%",
    width: "100%",
    overflow: "auto",
  },
  manageContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  SaveChangesButton: {
    // backgroundColor: bgColors.green,
    border: `1px solid ${bgColors.green}`,
    color: bgColors.green,
    borderRadius: "8px",
    width: "110px",
    height: "32px",
    // padding: "10px",
    cursor: "pointer",
  },
  mainBorderStyles: {
    borderRadius: "18px",
    border: `2px solid ${bgColors.gray5}`,
  },
  editColor: {
    color: `${bgColors.green} !important`,
    fontWeight: "600 !important",
    // fontSize: "14px !important",
  },
  blackColor: {
    color: `${bgColors.black1} !important`,
    fontWeight: "500 !important",
    top: "8px",
  },
  grayColor: {
    color: `${bgColors.black1} !important`,
    opacity: "60% !important",
  },
  editButtonContainer: {
    borderRadius: "34px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "120px",
    height: "32px",
    cursor: "pointer",
    border: `2px solid ${bgColors.green}`,
  },
  profileEditContainer: {
    textAlign: "center",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  hiddenInput: {
    display: "none",
  },
});

// export const businessCategories = [
//   { categoryName: "Information Technology", value: "informationTechnology" },
//   { categoryName: "Retail", value: "retail" },
//   { categoryName: "Healthcare", value: "healthcare" },
//   { categoryName: "Finance and Banking", value: "financeAndBanking" },
//   { categoryName: "Education", value: "education" },
//   { categoryName: "Real Estate", value: "realEstate" },
//   { categoryName: "Hospitality and Tourism", value: "hospitalityAndTourism" },
//   { categoryName: "Manufacturing", value: "manufacturing" },
//   { categoryName: "Food and Beverage", value: "foodAndBeverage" },
//   { categoryName: "Automotive", value: "automotive" },
//   { categoryName: "Construction", value: "construction" },
//   { categoryName: "Entertainment and Media", value: "entertainmentAndMedia" },
//   {
//     categoryName: "Transportation and Logistics",
//     value: "transportationAndLogistics",
//   },
//   { categoryName: "Energy and Utilities", value: "energyAndUtilities" },
//   { categoryName: "Agriculture and Farming", value: "agricultureAndFarming" },
//   { categoryName: "Others", value: "other" },
// ];

// export const businessCategories = [
//   { categoryName: "Restaurant", value: "restaurant" },
//   { categoryName: "Travel", value: "travel" },
//   { categoryName: "Retail", value: "retail" },
//   { categoryName: "ProfServices", value: "prof_Services" },
//   { categoryName: "nonProfit", value: "nonProfit" },
//   { categoryName: "Health", value: "health" },
//   { categoryName: "Hotel", value: "hotel" },
//   { categoryName: "Govt", value: "govt" },
//   { categoryName: "Grocery", value: "grocery" },
//   // { categoryName: "Health", value: "health" },
//   // { categoryName: "Hotel", value: "hotel" },
//   // { categoryName: "Non Profit", value: "nonprofit" },
//    // { categoryName: "NotABiz", value: "notABiz" },
//   { categoryName: "Others", value: "other" },
//   // { categoryName: "ProfServices", value: "prof_Services" },
//   // { categoryName: "Restaurant", value: "restaurant" },
//   // { categoryName: "Retail", value: "retail" },
//   // { categoryName: "Travel", value: "travel" },
//   // { categoryName: "Undefined", value: "undefined" },
// ];

export const businessCategories = [
  { categoryName: "Govt", value: "govt" },
  { categoryName: "Grocery", value: "grocery" },
  { categoryName: "Health", value: "health" },
  { categoryName: "Hotel", value: "hotel" },
  { categoryName: "Non Profit", value: "nonProfit" },
  { categoryName: "Others", value: "other" },
  { categoryName: "Prof Services", value: "prof_Services" },
  { categoryName: "Restaurant", value: "restaurant" },
  { categoryName: "Retail", value: "retail" },
  { categoryName: "Travel", value: "travel" },
];

export const companyTypes = [
  { companyName: "Corporation", value: "corporation" },
  { companyName: "Partnership", value: "partnership" },
  { companyName: "Sole Proprietorship", value: "soleProprietorship" },
  {
    companyName: "Limited Liability Company",
    value: "limitedLiabilityCompany",
  },
  { companyName: "Non-Profit Organization", value: "nonProfitOrganization" },
  { companyName: "Cooperative", value: "cooperative" },
  { companyName: "Franchise", value: "franchise" },
  { companyName: "Public", value: "Public" },
  { companyName: "Private", value: "Private" },
  { companyName: "Others", value: "other" },
];

const EditManageCompany = () => {
  const classes = useStyles();
  const params = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // const editedCompanyData: any = useAppSelector(
  //   (state) => state?.updatedCompanyData
  // );
  // const loginData = useAppSelector((state) => state?.adminLogin?.data);
  const company = useAppSelector((state: any) => state?.companyData);
  const companyData = company?.data;
  const getuserPermissionData = useAppSelector(
    (state: any) => state.getUserPermissions?.data
  );
  const manageCompanyObject = getuserPermissionData?.profile?.find(
    (item: any) =>
      Object?.prototype?.hasOwnProperty?.call(item, "manageCompany")
  );
  const manageCompanyActions = manageCompanyObject
    ? manageCompanyObject?.manageCompany
    : [];
  const hasEditCompanyAcess = (permission: any) => {
    if (manageCompanyActions?.includes(permission)) {
      return true;
    }
    return false;
  };
  // console.log(companyData, "companyData");
  const [isEditManageCompanyLoading, setIsEditManageCompanyLoading] =
    useState(false);

  const [img, setImg] = useState("");
  const [formData, setFormData] = useState({
    email: companyData?.company?.businessEmail || "",
    clientWebsite: companyData?.company?.businessWebsite || "",
    address: companyData?.company?.companyAddress || "",
    businessCategory: companyData?.company?.businessCategory || "",
    phoneNumber: companyData?.company?.phoneNumber || "",
    countryCode: companyData?.company?.countryCode || "+91",
    gstNumber: companyData?.company?.gstNumber || "",
    countryName: companyData.company?.countryName || "",
    description: companyData?.company?.description || "",
    companyName: companyData?.company?.businessName || "",
    companyLegalName: companyData?.company?.companyLegalName || "",
    companyRegistered: companyData?.company?.companyRegistered || false,
    companyType: companyData?.company?.companyType || "",
    customerCareEmail: companyData?.company?.customerCareEmail || "",
    customerCareCountryCode:
      companyData?.company?.customerCareCountryCode || "+91",
    customerCarePhone: companyData?.company?.customerCarePhone || "",
    grievanceOfficerName: companyData?.company?.grievanceOfficerName || "",
    grievanceOfficerPhone: companyData?.company?.grievanceOfficerPhone || "",
    grievanceOfficerEmail: companyData?.company?.grievanceOfficerEmail || "",
    grievanceOfficerCountryCode:
      companyData?.company?.grievanceOfficerCountryCode || "+91",
    companyId: companyData?.company?.id,
    companyLogoLink: companyData?.company?.companyLogoLink || null,
  });
  // console.log(formData, 'formData')

  const [valid, setValid] = useState({
    companyName: "",
    email: "",
    clientWebsite: "",
    phoneNumber: "",
    gstNumber: "",
    customerCareEmail: "",
    customerCarePhone: "",
    grievanceOfficerEmail: "",
    grievanceOfficerPhone: "",
  });
  // console.log(valid, 'valid')
  const [errors, setErrors] = useState<any>({});

  const validateRequiredFields = () => {
    const errors = { ...valid };
    let isValid = true;

    if (!formData?.companyName) {
      errors.companyName = "Company Name is required";
      isValid = false;
    } else if (formData.companyName.length > 75) {
      errors.companyName = "Name cannot be more than 75 characters.";
      isValid = false;
    }
    // if (!formData?.countryCode) {
    //     errors.countryCode = "Country code is required";
    //     isValid = false;
    // }

    const emailPattern =
      // /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/;

    if (!formData.email) {
      errors.email = "Email address is required";
      isValid = false;
    } else if (!emailPattern.test(formData.email)) {
      errors.email = "Invalid email address";
      isValid = false;
    }

    if (
      formData?.grievanceOfficerEmail?.trim() &&
      !emailPattern.test(formData.grievanceOfficerEmail.trim())
    ) {
      errors.grievanceOfficerEmail = "Invalid email address";
      isValid = false;
    }

    if (
      formData?.customerCareEmail?.trim() &&
      !emailPattern.test(formData.customerCareEmail.trim())
    ) {
      errors.customerCareEmail = "Invalid email address";
      isValid = false;
    }

    // const isValidPhone = /^\d+$/.test(formData?.phoneNumber);
    // if (!formData?.phoneNumber) {
    //   errors.phoneNumber = "Phone number is required.";
    //   isValid = false;
    // } else if (formData?.phoneNumber.length !== 10) {
    //   errors.phoneNumber = "Phone number must be 10 digits.";
    //   isValid = false;
    // } else if (!isValidPhone) {
    //   errors.phoneNumber = "Phone number should only contain numbers.";
    //   isValid = false;
    // }

    const isValidGrievanceOfficerPhone = /^\d+$/.test(
      formData?.grievanceOfficerPhone
    );

    // if (!formData?.grievanceOfficerPhone) {
    //   isValid = true
    // }
    // else

    // if (
    //   formData?.grievanceOfficerPhone?.trim() &&
    //   formData.grievanceOfficerPhone.length !== 10
    // ) {
    //   errors.grievanceOfficerPhone = "Phone number must be 10 digits";
    //   isValid = false;
    // } else
    if (
      formData?.grievanceOfficerPhone?.trim() &&
      !isValidGrievanceOfficerPhone
    ) {
      errors.grievanceOfficerPhone = "Phone number should only contain numbers";
      isValid = false;
    } else if (
      formData?.grievanceOfficerPhone &&
      !validatePhoneNumber(
        formData?.grievanceOfficerPhone,
        formData?.grievanceOfficerCountryCode
      )
    ) {
      errors.grievanceOfficerPhone =
        "This phone number format is not recognized. Please check the country code and phone number.";
      isValid = false;
    }

    const isValidCustomerCarePhone = /^\d+$/.test(formData?.customerCarePhone);
    // if (
    //   formData?.customerCarePhone?.trim() &&
    //   formData.customerCarePhone.length !== 10
    // ) {
    //   errors.customerCarePhone = "Phone number must be 10 digits";
    //   isValid = false;
    // } else
    if (formData?.customerCarePhone?.trim() && !isValidCustomerCarePhone) {
      errors.customerCarePhone = "Phone number should only contain numbers";
      isValid = false;
    } else if (
      formData?.customerCarePhone &&
      !validatePhoneNumber(
        formData?.customerCarePhone,
        formData?.customerCareCountryCode
      )
    ) {
      errors.customerCarePhone =
        "This phone number format is not recognized. Please check the country code and phone number.";
      isValid = false;
    }

    if (!formData.clientWebsite) {
      errors.clientWebsite = "Website is required";
      isValid = false;
    } else {
      const websitePattern =
        /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
      if (!websitePattern.test(formData.clientWebsite)) {
        errors.clientWebsite = "Invalid website format.";
        isValid = false;
      }
    }

    // if (!formData?.gstNumber) {
    //   isValid = true;
    // }
    // else
    // if (!/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/.test(formData?.gstNumber.trim())) {
    //   errors.gstNumber = "Invalid GST Number";
    //   isValid = false;
    // }
    if (
      formData?.gstNumber?.trim() &&
      !/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/.test(
        formData.gstNumber.trim()
      )
    ) {
      errors.gstNumber = "Invalid GST Number";
      isValid = false;
    }

    // if (!formData.grievanceOfficerEmail) {
    //   isValid = true;
    // }
    // else

    // if (!emailPattern.test(formData.grievanceOfficerEmail)) {
    //   errors.grievanceOfficerEmail = "Invalid email address";
    //   isValid = false;
    // }

    setValid(errors);
    return isValid;
  };

  const countryCodeArray = useAppSelector(
    (state: any) => state.getAllCoutryCodes?.data
  );
  const codeArray =
    countryCodeArray &&
    countryCodeArray?.map((item: any) => ({
      countryCode: item?.countryCode,
      countryImage: item?.countryImage,
    }));
  const getAllCountries = useAppSelector(
    (state: any) => state.getAllCountries?.data
  );

  const handleChange = (e: any) => {
    // const isRequiredFields = validateRequiredFields();
    // console.log(e)
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setValid((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  // console.log("name", name)

  const handlePhoneChange = (value: string) => {
    // console.log("value", value);
    // Validate phone number format
    // const phoneRegex = /^\d{14}$/;
    // if (!phoneRegex.test(value)) {
    //   setErrors({ ...errors, phoneNumber: "Phone number must be 10 digits" });
    // } else {
    //   setErrors({ ...errors, phoneNumber: "" });
    // }
    setFormData({ ...formData, phoneNumber: value });
    setValid((prevErrors) => ({
      ...prevErrors,
      phoneNumber: "",
    }));
  };

  const handleCountryCodeChange = (value: string) => {
    // console.log("value", value);
    setFormData({ ...formData, countryCode: value });
  };
  const handleGrievanceOfficerPhoneChange = (value: string) => {
    // console.log("value", value);
    // Validate phone number format
    // const phoneRegex = /^\d{14}$/;
    // if (!phoneRegex.test(value)) {
    //   setErrors({
    //     ...errors,
    //     grievanceOfficerPhone: "Phone number must be 10 digits",
    //   });
    // } else {
    //   setErrors({ ...errors, grievanceOfficerPhone: "" });
    // }
    setFormData({ ...formData, grievanceOfficerPhone: value });
    setValid((prevErrors) => ({
      ...prevErrors,
      grievanceOfficerPhone: "",
    }));
  };

  const handleGrievanceOfficerCountryCodeChange = (value: string) => {
    // console.log("value", value);
    setFormData({ ...formData, grievanceOfficerCountryCode: value });
  };

  const handleCustomerCarePhoneChange = (value: string) => {
    setFormData({ ...formData, customerCarePhone: value });
    setValid((prevErrors) => ({
      ...prevErrors,
      customerCarePhone: "",
    }));
  };

  const handleCustomerCareCountryCodeChange = (value: string) => {
    // console.log("value", value);
    setFormData({ ...formData, customerCareCountryCode: value });
  };

  const handleFieldChange = (fieldName: string, value: string) => {
    setFormData({ ...formData, [fieldName]: value });
  };

  const handleSubmit = async () => {
    // const errorsCopy: Record<string, string> = {};
    const isRequiredFields = validateRequiredFields();
    // console.log(isRequiredFields, "isRequiredFields");

    // setErrors(errorsCopy);

    if (isRequiredFields) {
      setIsEditManageCompanyLoading(true);
      try {
        const response = await ADMIN_MANAGE_COMPANY_APIS.editCompanyDetails({
          ...formData,
          companyId: formData?.companyId,
        });
        // console.log(response, 'response')
        if (response.status === 200) {
          // dispatch(fetchAccountDetails(response?.data?.id));
          dispatch(fetchCompanyDetails(params?.id));
          navigate(-1);
          // setFormData({});
          setImg("");
          dispatch(
            toastActions.setToaster({
              type: "success",
              message: `${response?.data?.message}`,
            })
          );
        }
      } catch (err: any) {
        // console.log(err, 'err');
        dispatch(
          toastActions.setToaster({
            type: "error",
            message: `${err?.response?.data?.message}`,
          })
        );
      }
      setIsEditManageCompanyLoading(false);
    }
  };

  const handleImageEditClick = () => {
    const inputFile = document.getElementById("imageInput");
    if (inputFile) {
      inputFile.click();
    }
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      // Validate image format
      const imageType = file.type;
      if (imageType !== "image/jpeg" && imageType !== "image/png") {
        setErrors({ ...errors, image: "Image must be JPG or PNG format" });
        return;
      } else {
        setErrors({ ...errors, image: "" });
      }

      const reader = new FileReader();
      reader.onload = () => {
        const binaryString = reader.result;
        const bytes = new Uint8Array(binaryString as ArrayBuffer);
        const blob = new Blob([bytes], { type: file.type });
        setImg(URL.createObjectURL(blob));
        const formDataNew = new FormData();
        formDataNew.append("image", blob, file.name);
        setFormData({
          ...formData,
          companyLogoLink: formDataNew.get("image"),
        });
      };
      reader.readAsArrayBuffer(file);
    }
  };
  useEffect(() => {
    dispatch(fetchCompanyDetails(params?.id));
    // dispatch(fetchGetAllCountries());
    // dispatch(fetchAllCountryCodes());
  }, []);

  // console.log("editedCompanyData", formData, businessCategories);
  const [isHovered, setIsHovered] = useState(false);
  const selectedCountry = formData?.countryName
    ? getAllCountries?.find(
        (country: { countryName: any }) =>
          country.countryName === formData.countryName
      )
    : null;
  return (
    <Grid className={classes.mainContainer}>
      {hasEditCompanyAcess("editCompanyDetails") ? (
        <Grid className={classes.bgContainer}>
          <Box
            pt={{ xs: 1, md: 4 }}
            px={{ xs: 1, md: 3 }}
            className={classes.manageContainer}
          >
            <Typography variant="h6" sx={{ display: "flex" }}>
              <span style={{ marginTop: "5px" }}>
                <RoomPreferencesIcon
                  sx={{ height: "25px", marginTop: "-2px" }}
                />
              </span>
              &nbsp;Edit Company Details
            </Typography>
            {/* <Box m={2}> */}
            {isEditManageCompanyLoading ? (
              <LoadingComponent height="auto" color={bgColors?.blue} />
            ) : (
              <button
                className={classes.SaveChangesButton}
                onClick={handleSubmit}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                style={{
                  backgroundColor: isHovered
                    ? "rgba(68, 71, 70, 0.08)"
                    : "#fff",
                  cursor: isHovered ? "pointer" : "default",
                }}
              >
                Update Details
              </button>
            )}
            {/* </Box> */}
          </Box>
          <Box p={{ xs: 1, md: 3 }}>
            <Box p={4} className={classes.mainBorderStyles}>
              <Box m={2} className={classes.profileEditContainer}>
                <img
                  alt="company"
                  style={{
                    width: "70px",
                    height: "70px",
                    borderRadius: "14px",
                  }}
                  src={
                    img ||
                    formData?.companyLogoLink ||
                    "/images/companyLogo.png"
                  }
                />
                <Box
                  className={classes.editButtonContainer}
                  onClick={handleImageEditClick}
                  ml={2}
                >
                  <Typography
                    //  variant="body2"
                    className={classes.editColor}
                    sx={{ fontSize: { xs: 10, md: 12 } }}
                    p={1}
                  >
                    Change Image
                  </Typography>
                </Box>
                <input
                  type="file"
                  id="imageInput"
                  className={classes.hiddenInput}
                  accept="image/*"
                  onChange={handleImageChange}
                />
              </Box>
              <Box style={{ display: "flex", justifyContent: "center" }}>
                <Typography variant="body2" color="error">
                  {errors.image}
                </Typography>
              </Box>
              <Grid container justifyContent="space-between">
                <Grid item xs={12} md={5.5} mr={0}>
                  <Box mb={3}>
                    <InputLabel
                      className={classes.blackColor}
                      sx={{ fontSize: { xs: 12, md: 14 }, mb: 1 }}
                    >
                      Company Name
                    </InputLabel>
                    <TextFeildWithBorderComponet
                      name="companyName"
                      label="Enter company name"
                      value={formData?.companyName}
                      onChange={handleChange}
                      placeholder=""
                      error={!!valid.companyName}
                      helperText={valid.companyName}
                      // fontweight="bold"
                    />
                  </Box>

                  <Box mb={3}>
                    <InputLabel
                      className={classes.blackColor}
                      sx={{ fontSize: { xs: 12, md: 14 }, mb: 1 }}
                    >
                      Company Email
                    </InputLabel>
                    <TextFeildWithBorderComponet
                      // disabled
                      name="email"
                      label="Enter company email"
                      value={formData?.email}
                      onChange={handleChange}
                      placeholder=""
                      error={!!valid.email}
                      helperText={valid.email}
                      // fontweight="bold"
                    />
                  </Box>
                  <Box mb={3}>
                    <InputLabel
                      className={classes.blackColor}
                      sx={{ fontSize: { xs: 12, md: 14 }, mb: 1 }}
                    >
                      Company Website
                    </InputLabel>
                    <TextFeildWithBorderComponet
                      name="clientWebsite"
                      label="Enter company website"
                      value={formData?.clientWebsite}
                      onChange={handleChange}
                      placeholder=""
                      error={!!valid.clientWebsite}
                      helperText={valid.clientWebsite}
                      // fontweight="bold"
                    />
                  </Box>
                  <Box mb={3}>
                    <InputLabel
                      className={classes.blackColor}
                      sx={{ fontSize: { xs: 12, md: 14 }, mb: 1 }}
                    >
                      Company Address
                    </InputLabel>
                    <TextFeildWithBorderComponet
                      sx={{ mt: 1 }}
                      name="address"
                      label="Enter company address"
                      value={formData?.address}
                      onChange={handleChange}
                      placeholder=""
                      // fontweight="bold"
                    />
                  </Box>
                </Grid>
                <Grid item ml={0} xs={12} md={5.5}>
                  <Box mb={3}>
                    <InputLabel
                      className={classes.blackColor}
                      sx={{ fontSize: { xs: 12, md: 14 }, mb: 2 }}
                    >
                      Company Category
                    </InputLabel>
                    <FormControl fullWidth size="small">
                      <InputLabel
                        id="demo-simple-select-label"
                        sx={{ fontSize: { xs: 12, md: 14 } }}
                      >
                        Company category
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        size="small"
                        value={formData?.businessCategory}
                        label="Company category"
                        inputProps={{
                          style: {
                            fontSize: "14px",
                            // fontWeight: "bold",
                          },
                        }}
                        sx={{
                          borderRadius: "8px",
                          fontSize: "14px", // Apply borderRadius here
                          // fontWeight: "bold",
                        }}
                        onChange={(e) =>
                          handleFieldChange("businessCategory", e.target.value)
                        }
                      >
                        {businessCategories &&
                          businessCategories?.map((item: any) => (
                            <MenuItem
                              key={item?.value}
                              value={item?.value}
                              sx={{
                                fontSize: 14,
                                fontWeight:
                                  item?.value === formData?.businessCategory
                                    ? "normal"
                                    : "normal",
                              }}
                            >
                              {item.categoryName}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Box>

                  <Box mb={3} mt={3}>
                    <InputLabel
                      className={classes.blackColor}
                      sx={{ fontSize: { xs: 12, md: 14 }, mb: 1 }}
                    >
                      Phone Number
                    </InputLabel>
                    {/* <Grid xs={12}> */}
                    <PhoneInput
                      width="130px"
                      gapRequired="58px"
                      phoneNumWidth="220px"
                      countryCodeArray={codeArray}
                      // fontweight="bold"
                      name="phoneNumber"
                      disabled={true}
                      onCountryCodeChange={handleCountryCodeChange}
                      onChange={handlePhoneChange}
                      countryCode={formData?.countryCode || ""}
                      propsValue={formData?.phoneNumber || ""}
                      error={!!valid.phoneNumber}
                      // helperText={valid.phoneNumber}
                    />
                    {valid.phoneNumber && (
                      <Typography
                        variant="caption"
                        color="error"
                        sx={{ fontSize: 12 }}
                      >
                        {valid.phoneNumber}
                      </Typography>
                    )}
                    {/* </Grid> */}
                  </Box>
                  <Box mb={3}>
                    <InputLabel
                      className={classes.blackColor}
                      sx={{ fontSize: { xs: 12, md: 14 }, mb: 1 }}
                    >
                      GST Number
                    </InputLabel>
                    <TextFeildWithBorderComponet
                      name="gstNumber"
                      // fontweight="bold"
                      label="Enter GST number"
                      value={formData?.gstNumber}
                      onChange={handleChange}
                      error={!!valid.gstNumber}
                      helperText={valid.gstNumber}
                      placeholder=""
                    />
                  </Box>
                  {/* <Box mb={3}>
                  <InputLabel
                    className={classes.blackColor}
                    sx={{ fontSize: { xs: 12, md: 14 }, mb: 2 }}
                  >
                    Country
                  </InputLabel>
                  <FormControl fullWidth size="small">
                    <InputLabel
                      id="demo-simple-select-label"
                      sx={{ fontSize: { xs: 12, md: 14 }, mb: 1 }}
                    >
                      Country
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Country"
                      size="small"
                      inputProps={{
                        style: {
                          fontSize: "14px",
                          fontWeight: "bold",
                        },
                      }}
                      sx={{
                        borderRadius: "8px",
                        fontSize: "14px", // Apply borderRadius here
                      }}
                      value={formData?.countryName || ""}
                      onChange={(e) =>
                        handleFieldChange("countryName", e.target.value)
                      }
                    >
                      {getAllCountries &&
                        getAllCountries?.map((item: any) => (
                          <MenuItem
                            value={item?.countryName}
                            sx={{ fontSize: 14 }}
                          >
                            <Typography
                              sx={{
                                fontSize: 14,
                                fontWeight:
                                  item?.countryName === formData?.countryName
                                    ? "bold"
                                    : "normal",
                              }}
                            >
                              {item.countryName}
                            </Typography>
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Box> */}
                  <Box mb={3}>
                    <Typography
                      className={classes.blackColor}
                      sx={{ fontSize: { xs: 12, md: 14 }, mb: 2 }}
                    >
                      Country
                    </Typography>
                    <Autocomplete
                      fullWidth
                      size="small"
                      options={getAllCountries || []}
                      getOptionLabel={(option) => option.countryName}
                      value={selectedCountry}
                      onChange={(event, newValue) => {
                        // console.log(event, newValue, "event");
                        handleFieldChange(
                          "countryName",
                          newValue ? newValue.countryName : ""
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Country"
                          sx={{
                            "& .MuiInputLabel-root": {
                              fontSize: { xs: 12, md: 14 },
                              mb: 1,
                            },
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "8px",
                              fontSize: "14px",
                            },
                          }}
                          InputProps={{
                            ...params.InputProps,
                            style: {
                              fontSize: "14px",
                              // fontWeight: "bold",
                            },
                          }}
                        />
                      )}
                      renderOption={(props, option) => (
                        <li {...props}>
                          <Typography
                            sx={{
                              fontSize: 14,
                              fontWeight:
                                option.countryName === formData?.countryName
                                  ? "normal"
                                  : "normal",
                            }}
                          >
                            {option.countryName}
                          </Typography>
                        </li>
                      )}
                    />
                    {/* <Typography variant="body2" color="error">
        {errors.countryName}
      </Typography> */}
                  </Box>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <InputLabel
                  className={classes.blackColor}
                  sx={{ fontSize: { xs: 12, md: 14 }, mb: 1 }}
                >
                  Description
                </InputLabel>
                <Box mt={1}>
                  <textarea
                    style={{
                      width: "100%",
                      height: "140px",
                      border: "2px solid #DBDBDB",
                      borderRadius: "8px",
                      padding: "8px",
                      fontWeight: formData?.description ? "normal" : "normal",
                      color: formData?.description
                        ? bgColors.black
                        : "rgba(150, 160, 173, 1)",
                      fontFamily: "unset",
                      fontSize: "14px",
                    }}
                    placeholder="Enter company description"
                    value={formData?.description || ""}
                    onChange={(e) =>
                      handleFieldChange("description", e.target.value)
                    }
                  />
                </Box>
              </Grid>
              <Grid
                container
                mt={3}
                justifyContent="space-between"
                style={{
                  borderBottom: "2px solid #DBDBDB",
                  paddingBottom: "20px",
                  width: "100%",
                }}
              >
                <Grid item xs={12} md={5.5} mr={0}>
                  <Box mb={3}>
                    <InputLabel
                      className={classes.blackColor}
                      sx={{ fontSize: { xs: 12, md: 14 }, mb: 1 }}
                    >
                      Legal Name of Company
                    </InputLabel>
                    <TextFeildWithBorderComponet
                      name="companyLegalName"
                      label="Enter company legal name"
                      // fontweight="bold"
                      value={formData?.companyLegalName || ""}
                      onChange={(e) =>
                        handleFieldChange("companyLegalName", e.target.value)
                      }
                      placeholder=""
                    />
                  </Box>
                  <Box mb={3}>
                    <InputLabel
                      className={classes.blackColor}
                      sx={{ fontSize: { xs: 12, md: 14 }, mb: 2 }}
                    >
                      Company Registered
                    </InputLabel>
                    <FormControl fullWidth size="small">
                      <InputLabel
                        id="demo-simple-select-label"
                        sx={{ fontSize: { xs: 12, md: 14 }, mb: 1 }}
                      >
                        Company registered
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={formData?.companyRegistered}
                        // value={10}
                        label="Company registered"
                        size="small"
                        inputProps={{
                          style: {
                            fontSize: 14,
                            // fontWeight: "bold",
                          },
                        }}
                        sx={{
                          borderRadius: "8px",
                          fontSize: "14px",
                          // fontWeight: "bold",
                        }}
                        onChange={(e) =>
                          handleFieldChange("companyRegistered", e.target.value)
                        }
                      >
                        <MenuItem value={"true"} sx={{ fontSize: 14 }}>
                          Yes
                        </MenuItem>
                        <MenuItem value={"false"} sx={{ fontSize: 14 }}>
                          No
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item ml={0} xs={12} md={5.5}>
                  <Box mb={3}>
                    <InputLabel
                      className={classes.blackColor}
                      sx={{ fontSize: { xs: 12, md: 14 }, mb: 2 }}
                    >
                      Company Type
                    </InputLabel>
                    <FormControl fullWidth size="small">
                      <InputLabel
                        id="demo-simple-select-label"
                        sx={{ fontSize: { xs: 12, md: 14 }, mb: 1 }}
                      >
                        {" "}
                        Company type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={formData?.companyType}
                        label=" Company type"
                        size="small"
                        inputProps={{
                          style: {
                            fontSize: "14px",
                            // fontWeight: "bold",
                          },
                        }}
                        sx={{ borderRadius: "8px", fontSize: "14px" }}
                        onChange={(e) =>
                          handleFieldChange("companyType", e.target.value)
                        }
                      >
                        {companyTypes &&
                          companyTypes?.map((item: any) => (
                            <MenuItem
                              key={item?.value}
                              value={item?.value}
                              sx={{ fontSize: 14 }}
                            >
                              <Typography
                                sx={{
                                  fontSize: 14,
                                  fontWeight:
                                    item?.value === formData?.companyType
                                      ? "normal"
                                      : "normal",
                                }}
                              >
                                {item?.companyName}
                              </Typography>
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
              </Grid>

              <Grid
                container
                mt={3}
                justifyContent="space-between"
                style={{
                  borderBottom: "2px solid #DBDBDB",
                  paddingBottom: "20px",
                  width: "100%",
                }}
              >
                <Grid item xs={12} md={5.5} mr={4}>
                  <Box mb={3}>
                    <InputLabel
                      className={classes.blackColor}
                      sx={{ fontSize: { xs: 12, md: 14 }, mb: 1 }}
                    >
                      Customer Care Email
                    </InputLabel>
                    <TextFeildWithBorderComponet
                      name="customerCareEmail"
                      label="Enter customer care email"
                      // fontweight="bold"
                      value={formData?.customerCareEmail || ""}
                      onChange={handleChange}
                      placeholder=""
                      error={!!valid.customerCareEmail}
                      helperText={valid.customerCareEmail}
                    />
                  </Box>
                </Grid>
                <Grid item ml={3} xs={12} md={5.5}>
                  <Box mb={3}>
                    <InputLabel
                      className={classes.blackColor}
                      sx={{ fontSize: { xs: 12, md: 14 }, mb: 1 }}
                    >
                      Customer Care Phone Number
                    </InputLabel>
                    <PhoneInput
                      width="130px"
                      gapRequired="48px"
                      phoneNumWidth="270px"
                      // fontweight="bold"
                      countryCodeArray={codeArray}
                      onCountryCodeChange={handleCustomerCareCountryCodeChange}
                      onChange={handleCustomerCarePhoneChange}
                      propsValue={formData?.customerCarePhone}
                      countryCode={formData?.customerCareCountryCode}
                      name="customerCarePhone"
                      error={!!valid.customerCarePhone}
                      // helperText={valid.customerCareCountryCode}
                    />
                    {valid.customerCarePhone && (
                      <Typography
                        variant="caption"
                        color="error"
                        sx={{ fontSize: 12 }}
                      >
                        {valid.customerCarePhone}
                      </Typography>
                    )}
                  </Box>
                </Grid>
              </Grid>

              {/* {loginData && !loginData?.roleName?.includes("Admin") && ( */}
              <Grid container mt={3}>
                <Grid container justifyContent="space-between">
                  <Grid item xs={12} md={5.5} mr={0}>
                    <Box mb={3}>
                      <InputLabel
                        className={classes.blackColor}
                        sx={{ fontSize: { xs: 12, md: 14 }, mb: 1 }}
                      >
                        Grievance Officer Name
                      </InputLabel>
                      <TextFeildWithBorderComponet
                        name="grievanceOfficerName"
                        label="Enter grievance name"
                        // fontweight="bold"
                        value={formData?.grievanceOfficerName || ""}
                        onChange={handleChange}
                        placeholder=""
                      />
                    </Box>
                    <Box mb={3} mt={3}>
                      <InputLabel
                        className={classes.blackColor}
                        style={{ marginBottom: 5 }}
                        sx={{ fontSize: { xs: 12, md: 14 } }}
                      >
                        Grievance Officer Phone Number
                      </InputLabel>
                      <Grid xs={12}>
                        <PhoneInput
                          width="130px"
                          gapRequired="48px"
                          phoneNumWidth="270px"
                          // fontweight="bold"
                          countryCodeArray={codeArray}
                          onCountryCodeChange={
                            handleGrievanceOfficerCountryCodeChange
                          }
                          onChange={handleGrievanceOfficerPhoneChange}
                          propsValue={formData?.grievanceOfficerPhone}
                          countryCode={formData?.grievanceOfficerCountryCode}
                          name="grievanceOfficerPhone"
                          error={!!valid.grievanceOfficerPhone}
                          // helperText={valid.grievanceOfficerPhone}
                        />
                        {valid.grievanceOfficerPhone && (
                          <Typography
                            variant="caption"
                            color="error"
                            sx={{ fontSize: 12 }}
                          >
                            {valid.grievanceOfficerPhone}
                          </Typography>
                        )}
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item ml={0} xs={12} md={5.5}>
                    <Box mb={3}>
                      <InputLabel
                        className={classes.blackColor}
                        sx={{ fontSize: { xs: 12, md: 14 }, mb: 1 }}
                      >
                        Grievance Officer Email
                      </InputLabel>
                      <TextFeildWithBorderComponet
                        type="email"
                        name="grievanceOfficerEmail"
                        // fontweight="bold"
                        label="Enter grievance officer email"
                        value={formData?.grievanceOfficerEmail}
                        onChange={handleChange}
                        error={!!valid.grievanceOfficerEmail}
                        helperText={valid.grievanceOfficerEmail}
                        placeholder=""
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              {/* )} */}
            </Box>
          </Box>
        </Grid>
      ) : (
        <NoAccessPage />
      )}
    </Grid>
  );
};

export default EditManageCompany;
