import React from 'react'

const BalanceIconSvg = ({ style }: { style?: React.CSSProperties }) => {
    return (
        <svg width="19" height="18" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1235_112)">
                <path style={style} d="M29.1598 9V6.5C29.1598 5.12125 28.0385 4 26.6598 4H10.4098C8.34229 4 6.65979 5.6825 6.65979 7.75V22.75C6.65979 25.5012 8.90229 26.5 10.4098 26.5H29.1598C30.5385 26.5 31.6598 25.3787 31.6598 24V11.5C31.6598 10.1213 30.5385 9 29.1598 9ZM26.6598 20.25H24.1598V15.25H26.6598V20.25ZM10.4098 9C10.0879 8.9856 9.78405 8.84762 9.56139 8.61477C9.33872 8.38193 9.21446 8.07217 9.21446 7.75C9.21446 7.42783 9.33872 7.11807 9.56139 6.88523C9.78405 6.65238 10.0879 6.5144 10.4098 6.5H26.6598V9H10.4098Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_1235_112">
                    <rect width="30" height="30" fill="white" transform="translate(0.15979)" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default BalanceIconSvg
