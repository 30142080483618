import React, { useState } from "react";
import {
  Box,
  TextField,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Paper,
  InputAdornment,
} from "@mui/material";
import TextFieldWithBorderComponent from "../common/TextFieldWithBorderComponent";
import { bgColors } from "../../utils/bgColors";
import { makeStyles } from "@mui/styles";
import InfoIconComponent from "./InfoIcon";
import ColorPickerField from "./ColorPicker";

const useStyles = makeStyles({
  blackColor: {
    color: `${bgColors.black1} !important`,
    fontWeight: "500 !important",
  },
  flexDirection: {
    display: "flex",
    // justifyContent: "space-evenly",
    alignItems: "center",
  },
});

const WhatsAppChatConfig = ({
  config,
  handleChange,
  handleColorChange,
}: {
  config: any;
  handleChange: (field: string) => (event: any) => void;
  handleColorChange: (color: any) => void;
}) => {
  // console.log(config, "config");
  const classes = useStyles();

  return (
    // <Paper elevation={3} sx={{ p: 3, maxWidth: 600, margin: "auto" }}>
    <Box
      sx={{
        display: "flex",
        gap: "45px",
        marginTop: "30px",
      }}
    >
      <Box sx={{ marginLeft: "10px" }}>
        <Typography>Chat Button Configuration</Typography>
        <Typography
          sx={{ color: "#6E6E6E", fontSize: "12px", fomtWeight: "400" }}
        >
          You can personalize the user message based on your website's URL
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "30px",
        }}
      >
        <Box>
          <Box className={classes.flexDirection}>
            <Typography
              variant="body1"
              className={classes.blackColor}
              sx={{ fontSize: { xs: 12, md: 14 }, mb: 1 }}
            >
              Whatsapp Phone Number
            </Typography>
            <Box>
              <InfoIconComponent tooltip="Your WhatsApp number on which user will be redirected with pre-filled message." />
            </Box>
          </Box>
          <TextFieldWithBorderComponent
            placeholder=""
            label="Enter the number with dial-code"
            name="name"
            // size="small"
            value={config.phoneNumber}
            // sx={{fontSize:"22px",fontWeight:"600 !important"}}
            onChange={handleChange("phoneNumber")}
            // fontweight="bold"
            //   error={!!valid.name}
            //   helperText={valid.name}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "50px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box className={classes.flexDirection}>
              <Typography
                variant="body1"
                className={classes.blackColor}
                sx={{ fontSize: { xs: 12, md: 14 }, mb: 1 }}
              >
                Button Background
              </Typography>
              <Box>
                <InfoIconComponent tooltip="Color to used for WhatsApp button. Default is #4dc247." />
              </Box>
            </Box>
            {/* <Box
              sx={{
                border: "1px solid #ccc", // Add border to make it look like a field
                borderRadius: 2, // Round the corners
                padding: "8px", // Add padding
                display: "flex",
                alignItems: "center",
                width: "260px", // Adjust width to fit content
                height: "40px",
                backgroundColor: "#fff", // Background color for input-like appearance
                marginTop: "9px",
              }}
            >
              <ColorPicker
                value={config.buttonBackground}
                onChange={handleColorChange}
                // sx={{
                //   "& .MuiInputBase-root": {
                //     borderBottom: "none", // Removes the underline for the input
                //   },
                // }}
              />
            </Box> */}
            <Box sx={{ marginTop: "8px" }}>
              <ColorPickerField
                value={config.buttonBackground}
                onChange={handleColorChange}
              />
            </Box>
          </Box>
          {/* <TextField
            //   fullWidth
            label="WhatsApp Phone Number"
            variant="outlined"
            value={config.phoneNumber}
            onChange={handleChange("phoneNumber")}
            placeholder="Enter number with dial-code"
          /> */}
          <Box>
            <Box className={classes.flexDirection}>
              <Typography
                variant="body1"
                className={classes.blackColor}
                sx={{ fontSize: { xs: 12, md: 14 }, mb: 1 }}
              >
                CTA Text
              </Typography>
              <Box>
                <InfoIconComponent tooltip="CTA text to displayed in WhatsApp button. ex - Chat with us" />
              </Box>
            </Box>
            <TextFieldWithBorderComponent
              placeholder=""
              label="Chat with us"
              name="name"
              // size="small"
              value={config.ctaText}
              // sx={{fontSize:"22px",fontWeight:"600 !important"}}
              onChange={handleChange("ctaText")}
              sx={{ width: "280px" }}
              // fontweight="bold"
              //   error={!!valid.name}
              //   helperText={valid.name}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "50px",
          }}
        >
          <Box>
            <Box className={classes.flexDirection}>
              <Typography
                variant="body1"
                className={classes.blackColor}
                sx={{ fontSize: { xs: 12, md: 14 }, mb: 1 }}
              >
                Margin Bottom(px)
              </Typography>
              <Box>
                <InfoIconComponent tooltip="Margin from the bottom, Default is 30px." />
              </Box>
            </Box>
            <TextField
              label="Margin Bottom"
              type="number"
              value={config.marginBottom}
              onChange={handleChange("marginBottom")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">px</InputAdornment>
                ),
                sx: {
                  borderRadius: 3, // Increase the borderRadius
                  height: 40, // Decrease the height of the input field
                },
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: 3, // Adjust borderRadius of the entire input field
                  height: 40, // Adjust height
                },
              }}
            />
          </Box>
          <Box>
            <Box className={classes.flexDirection}>
              <Typography
                variant="body1"
                className={classes.blackColor}
                sx={{ fontSize: { xs: 12, md: 14 }, mb: 1 }}
              >
                Margin Left(px)
              </Typography>
              <Box>
                <InfoIconComponent tooltip="Margin from the left, Default is 30px." />
              </Box>
            </Box>
            <TextField
              // fullWidth
              label="Margin Left"
              variant="outlined"
              type="number"
              value={config.marginLeft}
              onChange={handleChange("marginLeft")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">px</InputAdornment>
                ),
                sx: {
                  borderRadius: 3, // Increase the borderRadius
                  height: 40, // Decrease the height of the input field
                },
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: 3, // Adjust borderRadius of the entire input field
                  height: 40, // Adjust height
                },
              }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "50px",
          }}
        >
          <Box>
            <Box className={classes.flexDirection}>
              <Typography
                variant="body1"
                className={classes.blackColor}
                sx={{ fontSize: { xs: 12, md: 14 }, mb: 1 }}
              >
                Margin Right(px)
              </Typography>
              <Box>
                <InfoIconComponent tooltip="Margin from the right. Default is 30px." />
              </Box>
            </Box>
            <TextField
              // fullWidth
              label="Margin Right"
              variant="outlined"
              type="number"
              value={config.marginRight}
              onChange={handleChange("marginRight")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">px</InputAdornment>
                ),
                sx: {
                  borderRadius: 3, // Increase the borderRadius
                  height: 40, // Decrease the height of the input field
                },
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: 3, // Adjust borderRadius of the entire input field
                  height: 40, // Adjust height
                },
              }}
            />
          </Box>
          <Box>
            <Box className={classes.flexDirection}>
              <Typography
                variant="body1"
                className={classes.blackColor}
                sx={{ fontSize: { xs: 12, md: 14 }, mb: 1 }}
              >
                Border Radius(px)
              </Typography>
              <Box>
                <Box>
                  <InfoIconComponent tooltip="Border radius of WhatsApp button. Default is 24px." />
                </Box>
              </Box>
            </Box>
            <TextField
              // fullWidth
              label="Border Radius"
              variant="outlined"
              type="number"
              value={config.borderRadius}
              onChange={handleChange("borderRadius")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">px</InputAdornment>
                ),
                sx: {
                  borderRadius: 3, // Increase the borderRadius
                  height: 40, // Decrease the height of the input field
                },
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: 3, // Adjust borderRadius of the entire input field
                  height: 40, // Adjust height
                },
              }}
            />
          </Box>
        </Box>

        <Box>
          <Box className={classes.flexDirection}>
            <Typography
              variant="body1"
              className={classes.blackColor}
              sx={{ fontSize: { xs: 12, md: 14 }, mb: 1 }}
            >
              Default Pre-filled Message
            </Typography>
            <Box>
              <InfoIconComponent tooltip="User message to be pre-filled when redirected to WhatsApp." />
            </Box>
          </Box>

          <TextField
            fullWidth
            label="Default Pre-filled Message"
            variant="outlined"
            value={config.defaultMessage}
            onChange={handleChange("defaultMessage")}
            multiline
            rows={2}
          />
          <Typography
            sx={{ color: "#6E6E6E", fontSize: "12px", fontWeight: "400" }}
          >
            You can use defaults - {"{{ page_url }} & {{ page_title }}"}
          </Typography>
        </Box>
        <FormControl component="fieldset">
          <Box className={classes.flexDirection}>
            <Typography
              variant="body1"
              className={classes.blackColor}
              sx={{ fontSize: { xs: 12, md: 14 }, mb: 1 }}
            >
              Position
            </Typography>
            <Box>
              <InfoIconComponent tooltip="Position of the widget and button" />
            </Box>
          </Box>
          <RadioGroup
            row
            value={config.position}
            onChange={handleChange("position")}
          >
            <FormControlLabel
              value="bottom-right"
              control={
                <Radio
                  sx={{
                    "&.Mui-checked": {
                      color: "green", // Set checked radio button color to green
                    },
                  }}
                />
              }
              label="Bottom-Right"
            />
            <FormControlLabel
              value="bottom-left"
              control={
                <Radio
                  sx={{
                    "&.Mui-checked": {
                      color: "green", // Set checked radio button color to green
                    },
                  }}
                />
              }
              label="Bottom-Left"
            />
          </RadioGroup>
        </FormControl>

        {/* <Button
          variant="contained"
          color="primary"
          onClick={() => console.log(config)}
        >
          Save Configuration
        </Button> */}
      </Box>
    </Box>
    // </Paper>
  );
};

export default WhatsAppChatConfig;
