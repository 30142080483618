import React from "react";

const EngagetoSmallLogoSvg = () => {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" fill="" />
      <path
        d="M8.5 11.18V6.43368L12.1445 7.78978L23.5019 3.63672V8.38308L12.1445 12.5361L8.5 11.18Z"
        fill="#292929"
      />
      <path
        d="M8.5 26.8602V22.1139L12.1445 23.47L23.5019 19.3169V24.0633L12.1445 28.2163L8.5 26.8602Z"
        fill="#292929"
      />
      <path
        d="M8.5 19.0629V14.3165L12.1445 15.6726L20.6202 12.5366V17.283L12.1445 20.419L8.5 19.0629Z"
        fill="#292929"
      />
    </svg>
  );
};

export default EngagetoSmallLogoSvg;
