import React from "react";

const WalletSvg = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30 10.5V7.5C30 5.8455 28.6545 4.5 27 4.5H7.5C5.019 4.5 3 6.519 3 9V27C3 30.3015 5.691 31.5 7.5 31.5H30C31.6545 31.5 33 30.1545 33 28.5V13.5C33 11.8455 31.6545 10.5 30 10.5ZM27 24H24V18H27V24ZM7.5 10.5C7.11378 10.4827 6.74911 10.3171 6.48191 10.0377C6.21472 9.75831 6.0656 9.38661 6.0656 9C6.0656 8.61339 6.21472 8.24169 6.48191 7.96227C6.74911 7.68286 7.11378 7.51728 7.5 7.5H27V10.5H7.5Z"
        fill="#4B5A5A"
      />
    </svg>
  );
};

export default WalletSvg;
