import React from "react";
import { TextField, Typography, Box } from "@mui/material";
import { styled } from "@mui/system";

const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    backgroundColor: "#f6f6f6", // Light background color for the field
    "& fieldset": {
      borderColor: "transparent", // Remove border outline
    },
    "&:hover fieldset": {
      borderColor: "#e0e0e0", // Light grey on hover
    },
    "&.Mui-focused fieldset": {
      borderColor: "#e0e0e0", // Light grey when focused
    },
    "& textarea::placeholder": {
      color: "#b0b0b0", // Placeholder color
    },
  },
});

const CustomMessageInput = ({
  textFieldRef,
  message,
  handleInputChange,
}: any) => {
  return (
    <Box sx={{ width: "100%", padding: "10px" }}>
      <CustomTextField
        ref={textFieldRef}
        placeholder="Add your customer message that user will send to you"
        multiline
        rows={4} // Controls the height of the text field
        // fullWidth
        sx={{ width: "500px" }}
        value={message}
        onChange={handleInputChange} // Handles the input change
      />
    </Box>
  );
};

export default CustomMessageInput;
