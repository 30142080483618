import React from 'react'

const RedDeleteIconSvg = () => {
  return (
    <svg width="44" height="54" viewBox="0 0 84 74" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="80" height="74" rx="14" fill="#FF7171"/>
<path d="M39.3333 31C38.5333 31 38 31.5333 38 32.3333V37.6667C38 38.4667 38.5333 39 39.3333 39C40.1333 39 40.6667 38.4667 40.6667 37.6667V32.3333C40.6667 31.5333 40.1333 31 39.3333 31ZM44.6667 31C43.8667 31 43.3333 31.5333 43.3333 32.3333V37.6667C43.3333 38.4667 43.8667 39 44.6667 39C45.4667 39 46 38.4667 46 37.6667V32.3333C46 31.5333 45.4667 31 44.6667 31Z" fill="white"/>
<path d="M44.0625 18H39.9375C38.0125 18 36.5 19.54 36.5 21.5V22.2H32.375C31.55 22.2 31 22.76 31 23.6C31 24.44 31.55 25 32.375 25H32.5125L33.6125 40.68C33.75 43.76 36.0875 46 38.975 46H45.025C47.9125 46 50.3875 43.76 50.525 40.82L51.4875 25H51.625C52.45 25 53 24.44 53 23.6C53 22.76 52.45 22.2 51.625 22.2H47.5V21.5C47.5 19.54 45.9875 18 44.0625 18ZM39.25 21.5C39.25 21.08 39.525 20.8 39.9375 20.8H44.0625C44.475 20.8 44.75 21.08 44.75 21.5V22.2H39.25V21.5ZM48.7375 25L47.6375 40.54C47.5 42.08 46.4 43.2 44.8875 43.2H38.975C37.4625 43.2 36.3625 42.08 36.225 40.54L35.2625 25H48.7375Z" fill="white"/>
</svg>
  )
}

export default RedDeleteIconSvg
