/* global process */

import axios from "axios";

const USER_API_URL = process.env.REACT_APP_BASE_URL

const getHelpCenterData = () => {
    return axios({
        url: `${USER_API_URL}/HelpCenter/get-all-faq's`,

        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
        },
    });
};


const postHelpCenterData = (data: any) => {
    return axios({
        url: `${USER_API_URL}/HelpCenter/create-helpcenter`,
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
        },
        // data: JSON.stringify({ data: data }),
        data: JSON.stringify(data), 
    });
};


export const HELP_CENTER_APIS = {
    getHelpCenterData,
    postHelpCenterData
};

