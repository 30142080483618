import React from "react";
import { Box, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "@mui/material";
import { bgColors } from "../../../utils/bgColors";
import NoAccessPage from "../../../components/common/NoAccess";
import PaymentSummary from "../../../components/WalletComponents/PaymentSummary";
import { useAppSelector } from "../../../utils/redux-hooks";

const useStyles = makeStyles({
  mainContainer: {
    backgroundColor: bgColors.white1,
    padding: "8px",
    height: "100%",
    width: "100%",
  },
  bgContainer: {
    backgroundColor: bgColors.white,
    borderRadius: "25px",
    height: "100%",
    width: "100%",
  },
  blackColor: {
    color: `${bgColors.black1} !important`,
    fontWeight: "600 !important",
  },
  emailContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    // gap: "10px"
  },
  mainBorderStyles: {
    borderRadius: "18px",
    border: `2px solid ${bgColors.gray5}`,
    marginLeft: "100px",
    marginRight: "100px",
  },
  updateButtonStyles: {
    backgroundColor: "#3C3C3C",
    color: "#ffffff",
    height: "60px",
    borderRadius: "12px",
    width: "100%",
    fontSize: "24px ",
    fontWeight: "600",
  },
});

const ProfilePaymentMethod = () => {
  const classes = useStyles();
  const navigation = useNavigate();

  const getuserPermissionData = useAppSelector(
    (state:any) => state.getUserPermissions?.data
  );
  const subscriptionPermissionObject = getuserPermissionData?.profile?.find(
    (item: any) => Object?.prototype?.hasOwnProperty.call(item, "subscription")
  );
  const subscriptionPermissionActions = subscriptionPermissionObject
    ? subscriptionPermissionObject?.subscription
    : [];
  // console.log("manageCompanyActions", getPermissionData?.profile);

  const hasAccess = (permission: any) => {
    if (subscriptionPermissionActions?.includes(permission)) {
      return true;
    }
    return false;
  };

  const handleBack = () => {
    navigation("/profile/subscription");
  };
  return (
    <Grid container item className={classes.mainContainer}>
      {hasAccess("makePayment") ? (
        <Grid item className={classes.bgContainer}>
          <Tooltip
            title="Back"
            sx={{
              cursor: "pointer",
            }}
          >
            <ArrowBackIcon onClick={handleBack} />
          </Tooltip>
          <Box
            sx={{
              display: "flex",
              flexDirection: { lg: "row", sm: "row", xs: "column", md: "row" },
              justifyContent: "space-around",
              alignItems: "center",
              // gap: "100px",
              width: "100%",
              // marginLeft: "100px"
            }}
          >
            {/* <Box> <PaymentMethod /></Box> */}
            <Box>
              {" "}
              <PaymentSummary />
            </Box>
          </Box>
        </Grid>
      ) : (
        <NoAccessPage />
      )}
    </Grid>
  );
};

export default ProfilePaymentMethod;
