/* global process */

import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import MainSideBar from "../../components/HomeComponents/MainSideBar";
// import ProfileSideBar from "../../components/HomeComponents/ProfileSideBar";
import { useLocation } from "react-router-dom";
import { makeStyles } from "@mui/styles";
// import ChatSideBar from "../../components/InboxComponents/ChatSideBar";
import ProfileMainPage from "./ProfileMainPage";
import Inbox from "../Inbox/Inbox";
// import CampaignsSideBar from "../../components/CampaingnsComponents/CampaignsSideBar";
import CampaignsMainPage from "./CampaignMainPage";
import Contacts from "../Contacts/Contacts";
import AutomationMainPage from "./AutomationMainPage";
import AnalyticsMainPage from "./AnalyticsMainPage";
import WalletMainPage from "./WalletMainPage";
import TemplateMainPage from "./TemplateMainPage";
// import ProfileNewCard from "../Wallet/Profile_NewCard";
import { useAppDispatch, useAppSelector } from "../../utils/redux-hooks";
// import LoadingComponent from "../../components/common/LoadingComponent";
// import { bgColors } from "../../utils/bgColors";
import EngagetoLogoSvg from "../../assets/svgs/EngagetoLogoSvg";
import { useEffect, useState } from "react";
// import { fetchCurrentPlanDetails } from "../../redux/slices/Subscription/SubscriptionSlice";
import { HttpTransportType, HubConnectionBuilder } from "@microsoft/signalr";
import { fetchGetAllCountries } from "../../redux/slices/ManageAccount/GetAllCountryDetails";
import { fetchAllRoles } from "../../redux/slices/ManageCompany/DisplayAllRoles";
import { fetchAllCountryCodes } from "../../redux/slices/ManageAccount/GetAllCountryCode";
import { fetchManagePermissions } from "../../redux/slices/ManagePermissions/ManagePermissionsSlice";
import { fetchUserPermission } from "../../redux/slices/ManagePermissions/GetUserPermissionSlice";
import { sumOfUnreads } from "../../utils/functions";
import { generateToken, messaging } from "../../firebase";
import { onMessage } from "firebase/messaging";
import { fetchManageNotifications } from "../../redux/slices/ManageNotifications/ManageNotificationsSlice";
import { getWalletAndSubscription } from "../../redux/slices/Wallet/WalletSlice";
import { fetchAccountDetails } from "../../redux/slices/ManageAccount/AccountDetailsSlice";
import { bgColors } from "../../utils/bgColors";
import ProfilePaymentMethod from "../profile/Subscription/PaymentMethod";
import CommerceMainPage from "./CommerceMainPage";
import LinkComponent from "../../components/common/LinkComponent";
import IntegrationsMainPage from "./IntegrationsMainPage";
import WhatsappLink from "../Integrations/WhatsappLink";
import WhatsappWidget from "../Integrations/WhatsappWidget";
// import { fetchManageNotifications } from "../../redux/slices/ManageNotifications/ManageNotificationsSlice";

const useStyles = makeStyles({
  container: {
    overflowY: "hidden",
    overflowX: "hidden",
  },
  sideBar: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "110px",
    minWidth: "110px",
    overflowY: "auto",
  },
  badge: {
    position: "sticky",
    top: 0,
    zIndex: 1000,
    width: "100%",
    color: "#fff",
    textAlign: "center",
    padding: "8px 0",
    fontSize: "14px",
  },
});

interface ContactData {
  currentPage: number;
  totalPages: number;
  totalCount: number;
  itemsPerPage: number;
  data: any[];
}

interface LoadingStates {
  allContactsList: boolean;
  paginatedContactsList: boolean;
}

const WEBHOOKS_SOCKET_URL = process.env.REACT_APP_WEB_SOCKET_BASE_URL;

const Home = () => {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const loginData = useAppSelector((state: any) => state.adminLogin.data);
  const businessId = loginData?.companyId;
  const roleId = loginData?.roleId;
  const getuserPermissionSlice = useAppSelector(
    (state: any) => state.getUserPermissions
  );
  const accountDataSlice = useAppSelector((state: any) => state?.accountData);
  const accountData = accountDataSlice?.data;
  const currentPlanDetails = useAppSelector(
    (state: any) => state?.wallet?.walletAndSubscription?.data
  );
  const walletAndSubscriptionStatus = useAppSelector(
    (state: any) => state?.wallet?.walletAndSubscriptionStatus
  );
  const notifications = useAppSelector(
    (state: any) => state?.manageNotifications?.data
  );
  const chatNotification = notifications?.find(
    (menu: any) => menu.mainMenu === "Chat Notifications"
  );
  const subMenuStatus = chatNotification?.subMenus.find(
    (subMenu: any) => subMenu.name === "Get notification when i get a message"
  )?.status;
  const createContactSlice = useAppSelector(
    (state: any) => state?.createContactData?.data
  );
  const deleteContactSlice = useAppSelector(
    (state: any) => state?.removeContactData?.data
  );
  const updateContactSlice = useAppSelector(
    (state: any) => state?.updateContactData?.data
  );
  const inboxContactAssignmentSlice = useAppSelector(
    (state: any) => state?.inboxContactAssignment?.data
  );

  const [connection, setConnection] = useState<any>(null);
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [contactsListLoading, setContactsListLoading] = useState<LoadingStates>(
    { allContactsList: false, paginatedContactsList: false }
  );
  const [contacts, setContacts] = useState<ContactData>({
    currentPage: 1,
    totalPages: 1,
    totalCount: 0,
    itemsPerPage: 0,
    data: [],
  });
  const [messages, setMessages] = useState<any>();
  const [chat, setChat] = useState<any>();
  const [searchInput, setSearchInput] = useState<string>("");
  const [contactNumber, setContactNumber] = useState<any>("help");
  const [filterData, setFilterData] = useState<any>({ filters: [], sort: {} });
  const [chatsPageNumber, setChatsPageNumber] = useState(1);
  const [notify, setNotify] = useState<boolean>(true);
  const [totalUnreads, setTotalUnreads] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  const isProfileRoute = location.pathname.startsWith("/profile/");
  const isInbox = location.pathname.startsWith("/inbox");
  const isCampaingns = location.pathname.startsWith("/campaigns/");
  const isTemplate = location.pathname.startsWith("/templates");
  const isContacts = location.pathname.startsWith("/contacts");
  const isAutomation = location.pathname.startsWith("/automation/");
  const isAnalytics = location.pathname.startsWith("/analytics");
  const isWallet =
    location.pathname.startsWith("/wallet") ||
    location.pathname.startsWith("/billing-details");
  const isWalletPayment = location.pathname.startsWith("/payment");
  const isCommerce =
    location.pathname.startsWith("/commerce-settings") ||
    location.pathname.startsWith("/order-panel") ||
    location.pathname.startsWith("/catalog") ||
    location.pathname.startsWith("/auto-checkout-flow");
  // const isWalletCardDetails = location.pathname.startsWith("/card-details");
  const isIntegrations =
    // location.pathname.startsWith("/integrations-discover") ||
    location.pathname.startsWith("/whatsapp-widget") ||
    location.pathname.startsWith("/whatsapp-link");
  // const isWhatsappLink = location.pathname.startsWith("/whatsapp-link");

  const handleWebSocketMessage = (receivedMessage: any) => {
    // Check if receivedMessage status is sent, delivered, or seen
    if (
      ["sending", "sent", "delivered", "seen"].includes(receivedMessage.status)
    ) {
      // Find and remove the matching message from messages array
      setMessages((prevMessages: any) =>
        prevMessages.filter((msg: any) => {
          if (msg.mediaMimeType && receivedMessage.mediaMimeType) {
            // For media messages, compare using mediaUrl
            return !(
              msg.mediaMimeType === receivedMessage.mediaMimeType &&
              msg.status === "sending"
            );
          } else {
            // For text messages, compare using textMessage
            return !(
              msg.textMessage === receivedMessage.textMessage &&
              msg.status === "sending"
            );
          }
        })
      );
    }
  };

  const operations = {
    searching: {
      value: searchInput,
    },
    sorting: filterData?.sort,
    filtering: {
      filterType: "and",
      conditions: filterData?.filters,
    },
  };

  const handleLoadMore = (pageNumber: number) => {
    if (loginData?.userId) {
      setPageNumber(pageNumber);
    }
  };

  useEffect(() => {
    const total = sumOfUnreads(contacts?.data);
    setTotalUnreads(total);
  }, [contacts]);

  useEffect(() => {
    if (loginData?.userId && subMenuStatus !== undefined) {
      setNotify(subMenuStatus);
    }
  }, [subMenuStatus]);

  useEffect(() => {
    if (loginData?.userId) {
      const hubConnection: any = new HubConnectionBuilder()
        .withUrl(`${WEBHOOKS_SOCKET_URL}/conversations`, {
          accessTokenFactory: () => loginData?.token,
          transport:
            HttpTransportType.WebSockets |
            HttpTransportType.ServerSentEvents |
            HttpTransportType.LongPolling,
        })
        .withAutomaticReconnect()
        // .configureLogging(LogLevel.Information)
        .build();
      setConnection(hubConnection);
      setContactsListLoading({
        allContactsList: true,
        paginatedContactsList: false,
      });
      hubConnection
        .start()
        .then(() => {
          setIsConnected(true);
          hubConnection?.invoke(
            "SendUserData",
            loginData?.userId,
            businessId,
            operations,
            chatsPageNumber
          );
          hubConnection.on("SendContacts", (data: any) => {
            setContacts(() => ({
              currentPage: data.currentPage,
              totalPages: data.totalPages,
              totalCount: data.totalCount,
              itemsPerPage: data.pageSize,
              data: data.data,
            }));
            setContactsListLoading({
              allContactsList: false,
              paginatedContactsList: false,
            });
          });
          // hubConnection
          //   .invoke(
          //     "ContactConversationHistory",
          //     contactNumber,
          //     businessId,
          //     loginData?.userId,
          //     1
          //   )
          //   .then(() => { console.log("data receivedddd", contactNumber);})
          //   .catch((error: any) => {});
          // hubConnection.on("SendConversations", (data: any) => {
          //   setChat(data);
          //   setMessages(data?.data);
          // });
        })
        .catch((err: any) => {
          console.error("Error connecting to SignalR hub:", err);
          setIsConnected(false);
        });
      hubConnection.onreconnecting((error: any) => {
        console.log("error: ", error);
      });
      hubConnection.onreconnected((connectionId: any) => {
        console.log("connectionId: ", connectionId);
        hubConnection.invoke(
          "SendUserData",
          loginData?.userId,
          businessId,
          operations,
          chatsPageNumber
        );
      });
      // // Clean up function to remove the event listener when dependencies change
      // return () => {
      //   // hubConnection.off("RenderContacts");
      //   hubConnection.off("SendContacts");
      // };
    }
  }, [loginData]);

  useEffect(() => {
    if (connection && loginData?.userId) {
      const handleRenderContacts = () => {
        connection.invoke(
          "ContactsData",
          loginData?.userId,
          businessId,
          operations,
          chatsPageNumber
        );
      };

      connection.on("RenderContacts", handleRenderContacts);

      // Clean up the event listener when the component unmounts or dependencies change
      return () => {
        connection?.off("RenderContacts", handleRenderContacts);
      };
    }
  }, [operations, chatsPageNumber]);

  useEffect(() => {
    // console.log("pagenumberAPP", pageNumber);
    if (connection && isConnected && loginData?.userId) {
      // console.log("data received1", contactNumber);
      connection
        .invoke(
          "ContactConversationHistory",
          contactNumber,
          businessId,
          loginData?.userId,
          pageNumber
        )
        .then(() => {
          // console.log("data received2", contactNumber);
        })
        .catch((error: any) => {
          console.log("error", error);
        });

      connection.on("SendConversations", (data: any) => {
        // console.log(data, "nowww");
        if (data?.data) {
          setChat(data);

          if (pageNumber === 1) {
            // Ensure data.data is not undefined before setting messages
            setMessages(data.data);
          } else {
            // Ensure both data.data and messages are defined
            setMessages(() => [...data.data, ...messages]);
          }
        } else {
          // Handle the case where data or data.data is undefined
          console.error(
            'Received data is undefined or missing "data" property'
          );
          // Optionally set default values or handle errors here
        }
      });
    }
  }, [
    connection,
    isConnected,
    contactNumber,
    pageNumber,
    inboxContactAssignmentSlice,
  ]);

  useEffect(() => {
    const handleNewMessage = async (data: any) => {
      // Check if the contactNumber matches either from or to
      if (contactNumber === data[0]?.from || contactNumber === data[0]?.to) {
        // console.log("receive message");

        handleWebSocketMessage(data[0]);
        setMessages((prevConversations: any) => {
          // console.log("prevMessages", prevConversations);

          // Check if the message already exists in the messages state
          const index = prevConversations?.findIndex(
            (message: any) => message?.id === data[0]?.id
          );

          // If the message doesn't exist, mark as read and append it
          if (index !== -1) {
            // console.log("data1", messages, index, data);
            // If the message is found, replace it
            const updatedConversations = [...prevConversations];
            updatedConversations[index] = data[0];
            return updatedConversations;
          } else {
            // If the message is not found, add it to the list
            connection.invoke("MarkAsRead", data[0]?.id, businessId);
            // console.log("data2", index, data);
            return [...prevConversations, data[0]];
          }
          // return prevConversations;
        });
      }

      if (data[0]?.from?.length < 13 && notify) {
        if (navigator.serviceWorker && navigator.serviceWorker.controller) {
          navigator.serviceWorker.controller.postMessage({
            type: "NEW_MESSAGE",
            message: {
              title: `${data[0]?.name}`,
              body: `${data[0]?.textMessage || data[0]?.mediaMimeType}`, // Customize the body content
              icon: "/images/profile.png", // Specify an appropriate icon
              data: {
                url: `/inbox/${data[0]?.from}`,
              },
            },
          });
        }
      }
    };

    const setupMessageHandler = async () => {
      if (connection && isConnected && loginData?.userId) {
        connection.on("ReceiveMessageFromServer", handleNewMessage);

        // connection.on("RenderContacts");
      }
    };

    setupMessageHandler();

    return () => {
      if (connection) {
        connection.off("ReceiveMessageFromServer", handleNewMessage);
        // connection.off("SendConversations");
      }
    };
  }, [connection, isConnected, contactNumber, notify]);

  useEffect(() => {
    if (connection && isConnected && loginData?.userId) {
      setContactsListLoading({
        allContactsList: false,
        paginatedContactsList: true,
      });

      connection.invoke(
        "ContactsData",
        loginData?.userId,
        businessId,
        operations,
        chatsPageNumber
      );

      const handleSendContacts = (data: any) => {
        if (data?.data) {
          if (chatsPageNumber === 1) {
            setContacts(() => ({
              currentPage: data.currentPage,
              totalPages: data.totalPages,
              totalCount: data.totalCount,
              itemsPerPage: data.pageSize,
              data: data.data,
            }));
          } else {
            setContacts(() => {
              // Ensure prevContacts and prevContacts.data are defined
              // const previousData = prevContacts?.data ?? []; // Use an empty array if prevContacts?.data is undefined

              return {
                currentPage: data.currentPage,
                totalPages: data.totalPages,
                totalCount: data.totalCount,
                itemsPerPage: data.pageSize,
                data: [...contacts.data, ...data.data], // Safely concatenate arrays
              };
            });
          }
        } else {
          // Handle the case where data or data.data is undefined
          console.error(
            'Received data is undefined or missing "data" property'
          );
          // Optionally, you can set contacts to a default value or handle the error here
        }

        setContactsListLoading({
          allContactsList: false,
          paginatedContactsList: false,
        });
      };

      connection.on("SendContacts", handleSendContacts);

      // Clean up function to remove the event listener when dependencies change
      return () => {
        connection?.off("SendContacts", handleSendContacts);
      };
    }
  }, [
    filterData?.filters,
    filterData?.sort,
    searchInput,
    chatsPageNumber,
    createContactSlice,
    inboxContactAssignmentSlice,
    deleteContactSlice,
    updateContactSlice,
  ]);

  useEffect(() => {
    if (loginData?.userId) {
      setPageNumber(1);
    }
  }, [contactNumber]);

  useEffect(() => {
    return () => {
      if (connection) {
        connection.stop();
        setContacts({
          currentPage: 1,
          totalPages: 1,
          totalCount: 0,
          itemsPerPage: 0,
          data: [],
        });
      }
    };
  }, [connection]);

  useEffect(() => {
    if ("serviceWorker" in navigator) {
      window.addEventListener("load", function () {
        navigator.serviceWorker.register("/firebase-messaging-sw.js").then(
          function () {
            navigator.serviceWorker.ready.then(() => {
              generateToken();
            });
          },
          function (err) {
            console.log("ServiceWorker registration failed: ", err);
          }
        );
      });
    }
    onMessage(messaging, () => {});
  }, []);

  useEffect(() => {
    if (loginData?.userId) {
      dispatch(fetchGetAllCountries());
      dispatch(fetchAllCountryCodes());
      dispatch(fetchAllRoles(businessId));
      dispatch(fetchAccountDetails(loginData?.userId));
      // dispatch(fetchCurrentPlanDetails(businessId));
      dispatch(
        fetchManagePermissions({ roleId: roleId, companyId: businessId })
      );
      dispatch(
        fetchUserPermission({
          roleId: roleId,
          companyId: businessId,
        })
      );
      dispatch(getWalletAndSubscription(businessId));
      dispatch(
        fetchManageNotifications({
          userId: loginData?.userId,
          companyId: businessId,
        })
      );
    }
  }, [loginData?.userId, dispatch]);

  // console.log("accountMetaStatus", location, accountMetaStatus);

  return (
    <>
      {accountData?.companyVerificationStatus === true &&
      accountData?.isMetaEnabled === false &&
      (currentPlanDetails?.subscriptionPlan?.isActive === true ||
        currentPlanDetails?.subscriptionPlan?.isActive === false) ? (
        <Box className={classes.badge} sx={{ backgroundColor: bgColors?.red }}>
          <Typography>
            Oops!! Your account has been disabled by Meta. Please contact our
            support team.&nbsp;
            {!location?.pathname?.includes("profile/manage-account/") && (
              <LinkComponent
                title="Click here to view details"
                color={bgColors?.white}
                underline="always"
                href={`/profile/manage-account/${businessId}`}
              />
            )}
          </Typography>
        </Box>
      ) : accountData?.companyVerificationStatus === false &&
        accountData?.isMetaEnabled === false &&
        currentPlanDetails?.subscriptionPlan?.isActive === false ? (
        <Box
          className={classes.badge}
          sx={{ backgroundColor: bgColors?.yellow }}
        >
          <Typography>
            Your free trail has been expired. Please subscribe a plan to resume
            your Engageto activities.
          </Typography>
        </Box>
      ) : accountData?.companyVerificationStatus === false &&
        accountData?.isMetaEnabled === false &&
        currentPlanDetails?.subscriptionPlan?.planName !== "Intro" &&
        currentPlanDetails?.subscriptionPlan?.isActive === true ? (
        <Box
          className={classes.badge}
          sx={{ backgroundColor: bgColors?.green }}
        >
          <Typography>
            You have an active subscription. Please link with Meta to start
            engaging with your customers.
          </Typography>
        </Box>
      ) : (
        accountData?.companyVerificationStatus === true &&
        accountData?.isMetaEnabled === true &&
        currentPlanDetails?.subscriptionPlan?.isActive === false && (
          <Box
            className={classes.badge}
            sx={{ backgroundColor: bgColors?.yellow }}
          >
            <Typography>
              Your current plan has been expired. Please subscribe or upgrade to
              a new plan to resume your Engageto activities.
            </Typography>
          </Box>
        )
      )}
      <Grid
        container
        className={classes.container}
        sx={{
          position: "relative",
        }}
      >
        {accountDataSlice?.status === "loading" ||
        walletAndSubscriptionStatus === "loading" ||
        getuserPermissionSlice?.status === "loading" ? (
          <Box
            sx={{ justifyContent: "center", width: "100%", height: "100vh" }}
          >
            {/* <LoadingComponent height="100%" color={bgColors?.blue} /> */}
            <div id="loading-screen">
              <EngagetoLogoSvg />
              {/* <img
              src="/loading2.gif"
              style={{ width: "48px", height: "20px" }}
            /> */}
            </div>
          </Box>
        ) : (
          <>
            <Grid
              item
              xs={12}
              sx={{
                height: { xs: "auto", md: "100vh" },
                width: { xs: "100%", md: "110px" },
                // paddingLeft: { xs: "10px", md: "0" }
                // maxWidth: '120px !important',
              }}
              md={1}
              className={classes.sideBar}
            >
              <MainSideBar totalUnreads={totalUnreads} />
            </Grid>
            <Grid
              container
              sx={{
                marginLeft: { md: "110px" },
                marginTop: { xs: "67px", md: "0px" },
              }}
            >
              {isProfileRoute && <ProfileMainPage />}
              {isInbox && (
                <Inbox
                  contactsListLoading={contactsListLoading}
                  connection={connection}
                  contacts={contacts}
                  messages={messages}
                  chat={chat}
                  filterData={filterData}
                  chatsPageNumber={chatsPageNumber}
                  setChatsPageNumber={setChatsPageNumber}
                  setMessages={setMessages}
                  searchInput={searchInput}
                  setSearchInput={setSearchInput}
                  setFilterData={setFilterData}
                  setContactNumber={setContactNumber}
                  contactNumber={contactNumber}
                  handleLoadMore={handleLoadMore}
                />
              )}
              {isCampaingns && <CampaignsMainPage />}
              {isContacts && <Contacts />}
              {isAutomation && <AutomationMainPage />}
              {isAnalytics && <AnalyticsMainPage />}
              {isWallet && <WalletMainPage />}
              {isWalletPayment && <ProfilePaymentMethod />}
              {/* {isWalletCardDetails && <ProfileNewCard />} */}
              {isTemplate && <TemplateMainPage />}
              {isCommerce && <CommerceMainPage />}
              {isIntegrations && <IntegrationsMainPage />}
              {/* {isWhatsappLink && <WhatsappLink />} */}
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default Home;
