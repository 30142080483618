import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { AUTOMATION_API } from "../../../Apis/AdminLogin/Automation/Automation";
import { decrypt } from "../../../utils/encrypt";
import { transformKeysToCamelCase } from "../../../utils/functions";

export interface IData {
  status: "loading" | "succeeded" | "failed" | "idle";
  data: any;
}

const initialState: IData = {
  status: "idle",
  data: null,
};

export const getWorkingHours = createAsyncThunk(
  "getWorkingHours",
  async (data: any, { rejectWithValue }) => {
    try {
      // Make the API call to get encrypted data
      const response = await AUTOMATION_API.getWorkingHours(data);

      // Decrypt the response data
      const decryptedData = decrypt(response);
      // const parsedData = JSON.parse(decryptedData);
      const parsedData = transformKeysToCamelCase(JSON.parse(decryptedData));
      // console.log("Decrypted data", parsedData1);

      // Return the decrypted data
      return parsedData; // Assuming the decrypted data is a JSON string
    } catch (error: any) {
      // Provide a descriptive error message
      return rejectWithValue(error.response || "An error occurred");
    }
  }
);

export const getWorkingHoursSlice = createSlice({
  name: "getWorkingHoursSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getWorkingHours.pending, (state) => {
        state.status = "loading";
        state.data = null;
      })
      .addCase(getWorkingHours.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(getWorkingHours.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const getWorkingHoursActions = getWorkingHoursSlice.actions;
export default getWorkingHoursSlice.reducer;
