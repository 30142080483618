import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { ChangeEvent, useState } from "react";
import { bgColors } from "../../../utils/bgColors";
import { makeStyles } from "@mui/styles";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import { useAppDispatch, useAppSelector } from "../../../utils/redux-hooks";
import TagsAndAttributesTableData from "../../../components/TagComponents/TableData";
import NewTagMember from "../../../components/ContactsComponents/NewTagMember";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import TagsTableData from "../../../components/TagComponents/TableData";
import SearchIconSvg2 from "../../../assets/svgs/SearchIconSvg2";

const useStyles = makeStyles({
  mainContainer: {
    // backgroundColor: bgColors.white1,
    padding: "14px",
    width: "100%",
    height: "100%",
  },
  bgContainer: {
    backgroundColor: bgColors.white,
    borderRadius: "25px",
    height: "100%",
    width: "100%",
    overflow: "auto",
  },
  manageContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  tabsContainer: {
    width: "100%",
  },
  account: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "8px 0 0 0",
  },
  switch: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: "14px !important",
    color: `${bgColors.black1} !important`,
    // opacity: "60% !important",
  },
  lineStyles: {
    border: "none",
    borderTop: `1px solid ${bgColors.gray2}`,
    width: "100%",
    margin: "0 auto",
  },
  blackColor: {
    color: `${bgColors.black1} !important`,
    fontWeight: "600 !important",
  },
  SaveChangesButton: {
    // backgroundColor: bgColors.green,
    // color: bgColors.white,
    color: bgColors.green,
    border: `1px solid ${bgColors.green}`,
    borderRadius: "8px",
    // width: "fit-content",
    width: "100px",
    height: "32px",
    // padding: "10px",
    cursor: "pointer",
    marginLeft: "160px",
  },
  searchField: {
    width: "100%",
    borderRadius: "12px",
    // height: "38px",
    // backgroundColor: bgColors.white2,
    backgroundColor: "white",
    "& input::placeholder": {
      textAlign: "left",
      fontSize: "14px",
      fontFamily: "inter",
      color: "#000000 !important",
    },
  },
});

const Tags = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const getContactTag = useAppSelector(
    (state: any) => state?.getContactTagsData?.data
  );
  // console.log(getContactTag);
  const listOfOptions = [
    {
      name: "Tags",
    },
    {
      name: "Attributes",
    },
  ];

  // const [selected, setSelected] = useState<string>("Tags");
  // console.log(selected);
  const options = ["Tags"];
  const [value, setValue] = React.useState<any>(options[0]);
  const [newTagDialog, setNewTagDialog] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  // console.log(searchQuery);

  const openNewTagPopover = () => {
    setNewTagDialog(true);
  };
  const handleCloseTagPopover = () => {
    setNewTagDialog(false);
  };
  const handleFieldChange = (value: string) => {
    setValue(value);
  };

  const handleSearchContacts = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const filteredTags = getContactTag?.filter((tag: any) =>
    tag?.tag?.toLowerCase().includes(searchQuery.toLowerCase())
  );
  console.log(filteredTags);

  return (
    <Grid className={classes.mainContainer}>
      <Grid className={classes.bgContainer}>
        {" "}
        <Box
          // py={{ xs: 1, md: 4 }}
          px={{ xs: 1, md: 4 }}
          mt={5}
          mb={2}
          className={classes.manageContainer}
        >
          <Typography
            variant="h6"
            className={classes.blackColor}
            sx={{ display: "flex" }}
          >
            <span style={{ marginTop: "3px" }}>
              <LocalOfferIcon sx={{ height: "20px" }} />
            </span>
            &nbsp;Manage Tags
          </Typography>
          {/* {loginData?.isPlatform && ( */}

          <Box sx={{ width: "55%" }}>
            <TextField
              className={classes.searchField}
              variant="standard"
              size="small"
              fullWidth
              // id="password"
              onChange={handleSearchContacts}
              InputProps={{
                disableUnderline: true,
                style: {
                  fontSize: "12px",
                  padding: "7px",
                  // border: '1px solid gray',
                  borderRadius: "14px",
                },
                startAdornment: (
                  <IconButton
                    sx={{ p: 0, color: "inherit" }}
                    aria-label="search"
                  >
                    {/* <SearchIconSvg /> */}
                    <SearchIconSvg2 />
                  </IconButton>
                ),
              }}
              inputProps={{
                style: {
                  // Additional style for placeholder
                  fontWeight: "600 !important",
                  paddingTop: "3px", // Apply font weight here
                },
              }}
              placeholder="Search contacts"
            />
          </Box>

          <Box sx={{ display: "flex", flexDirection: "column" }}>
            {/* <FormControl
              sx={{
                m: 1,
                minWidth: 300,
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Autocomplete
                  sx={{
                    width: "100%",
                    cursor: "pointer",
                    "& .MuiAutocomplete-inputRoot": {
                      paddingTop: "0px",
                      paddingBottom: "0px",
                      height: "35px",
                      "& .MuiInputBase-root": {
                        height: "100%",
                      },
                    },
                  }}
                  value={value}
                  onChange={(event: any, newValue: string | null) => {
                    setValue(newValue);
                  }}
                  disableClearable
                  freeSolo={false} // Disables typing in the text field
                  id="controllable-states-demo"
                  options={options}
                  // sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField
                      sx={{
                        flexGrow: 1,
                        height: "35px",
                        borderRadius: "8px",
                        paddingTop: "0px",
                        paddingBottom: "0px",
                        "& .MuiOutlinedInput-root": {
                          height: "100%",
                          "& .MuiOutlinedInput-input": {
                            padding: "0 14px", // Adjust padding to control the input text height
                            display: "flex",
                            alignItems: "center", // Center the text vertically
                            fontSize: "14px",
                          },
                        },
                      }}
                      {...params}
                      label="Select Option"
                      inputProps={{
                        ...params.inputProps,
                        readOnly: true, // Makes the text field read-only to prevent typing
                      }}
                    />
                  )}
                />
              </Box>
            </FormControl> */}
            <FormControl
              fullWidth
              size="small"
              // error={!!valid.businessCategory}
            >
              <InputLabel
                // id="demo-simple-select-label"
                id="demo-simple-select-error-label"
                sx={{ fontSize: { xs: 12, md: 14 } }}
              >
                Select Option
              </InputLabel>
              <Select
                // labelId="demo-simple-select-label"
                // id="demo-simple-select"
                labelId="demo-simple-select-error-label"
                id="demo-simple-select-error"
                value={value}
                size="small"
                label="Select Option"
                inputProps={{
                  style: {
                    fontSize: "14px",
                  },
                }}
                sx={{
                  // marginTop: "3px",
                  borderRadius: "8px",
                  fontSize: "14px",
                  // fontWeight: "bold",
                }}
                onChange={(e) => handleFieldChange(e.target.value)}
                // onChange={handleChange}
              >
                {options &&
                  options?.map((item: any, index: number) => (
                    <MenuItem key={index} value={item} sx={{ fontSize: 14 }}>
                      {item}
                    </MenuItem>
                  ))}
              </Select>
              {/* {valid.businessCategory && (
                  <FormHelperText>{valid.businessCategory}</FormHelperText>
                )} */}
            </FormControl>
            {/* )} */}
            <button
              onClick={openNewTagPopover}
              // color="success"
              // sx={{ textTransform: "none", height: "20px", ml: 24 }}
              className={classes.SaveChangesButton}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              style={{
                backgroundColor: isHovered ? "rgba(68, 71, 70, 0.08)" : "#fff",
                cursor: isHovered ? "pointer" : "default",
                marginTop: "5px",
              }}
            >
              + Add Tag
            </button>
          </Box>
        </Box>{" "}
        <TagsTableData
          data={value}
          newTagDialog={newTagDialog}
          filteredTags={filteredTags}
        />
        <NewTagMember open={newTagDialog} handleClose={handleCloseTagPopover} />
      </Grid>
    </Grid>
  );
};

export default Tags;
