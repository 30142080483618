import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import translationEN from "../src/utils/transition.json";
import frenchTranslation from "../src/utils/frenchTransition.json";
import germanTranslation from "../src/utils/germanTransition.json";
import chineseTranslation from "../src/utils/chineseTransition.json";
// the translations
const resources = {
  en: {
    translation: translationEN,
  },
  fre: {
    translation: frenchTranslation,
  },
  ger: {
    translation: germanTranslation,
  },
  chi: {
    translation: chineseTranslation,
  },
};

i18n
  .use(detector)
  .use(initReactI18next as any) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en",

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
