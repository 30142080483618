import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import "./global.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "draft-js/dist/Draft.css";
import { Provider } from "react-redux";
import { store, persistor } from "./redux/slices/store";
import { PersistGate } from "redux-persist/integration/react";
import "./i18next";
import { QueryClient, QueryClientProvider } from "react-query";

// const container = document.getElementById("root");
// const root = createRoot(container!);
const root = createRoot(document.getElementById("root") as HTMLElement);
const queryClient = new QueryClient();
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </PersistGate>
    </Provider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// import React from "react";
// import { createRoot } from "react-dom/client";
// import App from "./App";
// import reportWebVitals from "./reportWebVitals";
// import { BrowserRouter } from "react-router-dom"; // Replace HashRouter with BrowserRouter
// import "./global.css";
// import "@fontsource/roboto/300.css";
// import "@fontsource/roboto/400.css";
// import "@fontsource/roboto/500.css";
// import "@fontsource/roboto/700.css";
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// import "draft-js/dist/Draft.css";
// import { Provider } from "react-redux";
// import { store, persistor } from "./redux/slices/store";
// import { PersistGate } from "redux-persist/integration/react";
// import "./i18next";
// import { QueryClient, QueryClientProvider } from "react-query";

// // const container = document.getElementById("root");
// // const root = createRoot(container!);
// const root = createRoot(document.getElementById("root") as HTMLElement);
// const queryClient = new QueryClient();
// root.render(
//   <BrowserRouter>
//     <Provider store={store}>
//       <PersistGate loading={null} persistor={persistor}>
//         <QueryClientProvider client={queryClient}>
//           <App />
//         </QueryClientProvider>
//       </PersistGate>
//     </Provider>
//   </BrowserRouter>
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
