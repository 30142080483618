/* global process */

import axios from "axios";

const TEMPLATES_API_URL = process.env.REACT_APP_BASE_URL;

const createTemplate = (data: any) => {
  return axios({
    url: `${TEMPLATES_API_URL}/Template/CreateTemplate?Draft=${data?.draft}`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
    data: data,
  });
};

const editTemplate = (data: any) => {
  // console.log("FormData", formData);
  return axios({
    url: `${TEMPLATES_API_URL}/Template/EditTemplate`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
    data: data,
  });
};

const getTemplatesByCompanyId = (data: any) => {
  return axios({
    url: `${TEMPLATES_API_URL}/Template/TemplateFilters?BusinessId=${data?.businessId}&UserId=${data?.userId}&page=${data?.pageNumber}&per_page=${data?.per_page}`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
    data: data?.filters,
  });
};

const getTemplateById = (data: any) => {
  return axios({
    url: `${TEMPLATES_API_URL}/Template/TemplatebyId?BusinessId=${data?.businessId}&UserId=${data?.userId}&id=${data?.templateId}`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
  });
};

const deleteTemplate = (data: any) => {
  return axios({
    url: `${TEMPLATES_API_URL}/Template/Delete-TemplateById`,
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
    data: data,
  });
};

const uploadFile = (data: any) => {
  return axios({
    url: `${TEMPLATES_API_URL}/Template/uploadFile`,
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: data,
  });
};

const checkTemplateName = (data: any) => {
  return axios({
    url: `${TEMPLATES_API_URL}/Template/check-template-name/${data?.businessId}/${data?.templateName}`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
  });
};

export const TEMPLATE_APIS = {
  createTemplate,
  editTemplate,
  getTemplatesByCompanyId,
  getTemplateById,
  deleteTemplate,
  uploadFile,
  checkTemplateName,
};
