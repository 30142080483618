import {
  Box,
  MenuItem,
  Select,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  IconButton,
  Button,
  Tooltip,
  InputAdornment,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { DraftEditorComponent } from "../../common/DraftEditorComponent";
import { ContentState, EditorState, Modifier } from "draft-js";
import { reactDraftWysiwygToolbarOptionsarticle } from "../../../utils/react-draft-wysiwyg-options";
import DeleteIconSvg from "../../../assets/svgs/DeleteIconSvg";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import SelectListDialog from "./SelectListDialog";
import { useAppDispatch, useAppSelector } from "../../../utils/redux-hooks";
import { toastActions } from "../../../utils/toastSlice";
import { getVariableNames } from "../../../redux/slices/Workflows/getVariableNamesSlice";
import LoadingComponent from "../../common/LoadingComponent";
import { bgColors } from "../../../utils/bgColors";
import { getWorkflowList } from "../../../redux/slices/Workflows/getWorkflowListSlice";
import { updateWorkflowList } from "../../../redux/slices/Workflows/updateWorkflowListSlice";
import AddVariablePopup from "./AddVariablePopup";
import { getSelectResponse } from "../../../redux/slices/Workflows/getSelectResponse";
import AddSelectResponse from "./AddSelectResponsePopup";
import DeleteSvg from "../../../assets/svgs/DeleteSvg";
import EditIconSvg from "../../../assets/svgs/EditIconSvg";
import DeletePopUp from "../../common/DeletePopup";
import { deleteSelectResponse } from "../../../redux/slices/Workflows/deleteSelectResponseSlice";
import { parseTextToDraft } from "../../../utils/functions";
import TextFieldWithBorderComponent from "../../common/TextFieldWithBorderComponent";
import {
  ButtonOptions,
  extractVariables,
  getNextVariableCount,
  insertTextAtCursor,
  SaveResponseRadioOptions,
  variableOptions,
} from "./functions";
import VariableAssignmentComponent from "./variableAssignmentComponent";

const CustomResponseComponent = ({
  expandedStep,
  selectedRadioValue,
  handleStepDataChange,
  workflowData,
  handleEditorStateChange,
  handleVariablesChange,
  handleButtonsChange,
  handleSelectedListChange,
  handleSave,
  isWorkflowEditing,
}: any) => {
  const [selectedVariable, setSelectedVariable] = useState("");
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const { createWorkflowStatus } = useAppSelector(
    (state: any) => state?.createWorkflow
  );
  const { updateWorkflowStatus } = useAppSelector(
    (state: any) => state?.updateWorkflow
  );
  const [selectedResponse, setSelectedResponse] = useState("");
  const [isAddNewPopupOpen, setIsAddNewPopupOpen] = useState(false);
  // const [expanded, setExpanded] = useState(false);
  const [selectedButtonValue, setSelectedButtonValue] = useState(() => {
    const flowResponseType = workflowData[expandedStep - 1]?.flowResponseType;
    if (flowResponseType === 1) return "button";
    if (flowResponseType === 2) return "list";
    return "";
  });
  const [showSaveUserResponse, setShowSaveUserResponse] = useState(false);
  const [saveResponseType, setSaveResponseType] = useState("Variable");
  const [openListDialog, setOpenListDialog] = useState(false);
  const [openAddselectResponseDialog, setOpenAddSelectResponseDialog] =
    useState(false);
  const [selectedList, setSelectedList] = useState<any>(null);
  // const [localVariables, setLocalVariables] = useState<any>([]);
  const [editSelectResponseId, setEditSelectResponseId] = useState<any>();
  const [response, setResponse] = useState("");
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
  const [isWorkflowDeleteLoading, setIsWorkflowDeleteLoading] = useState(false);
  const [deleteSelectResponseId, setDeleteSelectResponseId] = useState("");
  const [newVariableName, setNewVariableName] = useState("");

  const dispatch = useAppDispatch();
  const { data } = useAppSelector((state: any) => state.getVariableNames);
  const { selectResponseData } = useAppSelector(
    (state: any) => state?.getSelectResponseNames
  );
  const userProfileSlice = useAppSelector((state: any) => state?.adminLogin);
  const userData = userProfileSlice?.data;

  const { workflowListData, workflowListStatus } = useAppSelector(
    (state: any) => state.getWorkflowList
  );

  const dictionary = {
    Name: "Name",
    CountryCode: "CountryCode",
    CountryName: "CountryName",
    Contact: "Contact",
    Email: "Email",
  };

  const dictionaryOptions = Object.entries(dictionary).map(([key, value]) => ({
    key,
    value,
  }));

  const handleSaveUserVariableChange = (event: any) => {
    const selectedVariableId = event.target.value;
    setSelectedVariable(selectedVariableId);

    // Update the workflow data with the selected variable id
    const updatedWorkflowData = {
      ...workflowData[expandedStep - 1],
      responseCustomerMessage: {
        ...workflowData[expandedStep - 1].responseCustomerMessage,
        veriableNameEntityId: selectedVariableId,
      },
    };
    handleStepDataChange(expandedStep, updatedWorkflowData);
  };

  const handleAddNewClick = () => {
    setIsAddNewPopupOpen(true);
  };

  const handlePopupClose = () => {
    setIsAddNewPopupOpen(false);
    setNewVariableName("");
  };

  useEffect(() => {
    const flowResponseType = workflowData[expandedStep - 1]?.flowResponseType;
    if (flowResponseType === 1) {
      setSelectedButtonValue("button");
    } else if (flowResponseType === 2) {
      setSelectedButtonValue("list");
    } else {
      setSelectedButtonValue("");
    }
  }, [workflowData, expandedStep]);

  const handleAddSelectResponse = () => {
    setOpenAddSelectResponseDialog(true);
  };

  const handleCloseSelectResponse = () => {
    setOpenAddSelectResponseDialog(false);
    setEditSelectResponseId(null);
    setResponse("");
  };

  const handleResponseChange = async (event: any) => {
    if (typeof event === "string") {
      const payload = {
        companyId: userData?.companyId,
      };
      const result = await dispatch(getSelectResponse(payload));
      const selectedResponseObject = result?.payload?.find(
        (item: any) => item.selectResponse === event
      );
      setSelectedResponse(selectedResponseObject?.id);
      handleStepDataChange(expandedStep, {
        selectResponse: event,
        title: selectedResponseObject
          ? selectedResponseObject.selectResponse
          : "",
      });
    } else {
      const value = event.target.value;
      setSelectedResponse(value);
      const selectedResponseObject = selectResponseData.find(
        (item: any) => item.id === value
      );
      handleStepDataChange(expandedStep, {
        selectResponse: value,
        title: selectedResponseObject
          ? selectedResponseObject.selectResponse
          : "",
      });
    }
  };

  const fetchSelectResponse = async () => {
    const payload = {
      companyId: userData?.companyId,
    };
    try {
      const result = await dispatch(getSelectResponse(payload));
    } catch (error) {
      // dispatch(
      //   toastActions.setToaster({
      //     message: "An error occurred while fetching the selected response",
      //     type: "error",
      //   })
      // );
    }
  };

  useEffect(() => {
    fetchSelectResponse();
  }, []);

  useEffect(() => {
    if (selectedButtonValue === "list") {
      fetchWorkflowList();
    }
  }, [selectedButtonValue]);

  const fetchWorkflowList = async () => {
    const payload = {
      companyId: userData?.companyId,
    };

    try {
      const result = await dispatch(getWorkflowList(payload)).unwrap();
      if (result.success) {
        // dispatch(
        //   toastActions.setToaster({
        //     message: result.message,
        //     type: "success",
        //   })
        // );
      } else {
        // dispatch(
        //   toastActions.setToaster({
        //     message: result.message || "Failed to fetch workflow list",
        //     type: "error",
        //   })
        // );
      }
    } catch (error) {
      // dispatch(
      //   toastActions.setToaster({
      //     message: "An error occurred while fetching the workflow list",
      //     type: "error",
      //   })
      // );
    }
  };

  useEffect(() => {
    const fetchWorkflowVariableNames = async () => {
      const payload = {
        companyId: userData?.companyId,
      };

      try {
        const result = await dispatch(getVariableNames(payload)).unwrap();
        if (result.success) {
          // Assuming you handle the data in Redux itself or you use it directly
          // dispatch(
          //   toastActions.setToaster({
          //     message: result.message,
          //     type: "success",
          //   })
          // );
        } else {
          // dispatch(
          //   toastActions.setToaster({
          //     message: result.message || "Failed to fetch workflow list",
          //     type: "error",
          //   })
          // );
        }
      } catch (error) {
        // dispatch(
        //   toastActions.setToaster({
        //     message: "An error occurred while fetching the workflow list",
        //     type: "error",
        //   })
        // );
      }
    };

    // Check if there's a companyId and fetch the list
    if (userData?.companyId) {
      fetchWorkflowVariableNames();
    }
  }, []);

  const handleAddVariable = () => {
    const nextCount = getNextVariableCount(editorState, "editor");
    const variableText = `{{${nextCount}}}`;
    const newEditorState = insertTextAtCursor(editorState, variableText);
    setEditorState(newEditorState);
    handleEditorStateChange(expandedStep, newEditorState, "editor");

    const currentVariables = workflowData[expandedStep - 1]?.veriables || [];
    const newVariable = {
      index: nextCount - 1,
      veriable: variableText,
      value: "",
      type: 1,
      fallbackValue: "",
      referenceTableType: 0,
    };
    const updatedVariables = [...currentVariables, newVariable];
    handleVariablesChange(expandedStep, updatedVariables);
  };

  const toggleSaveUserResponse = () => {
    setShowSaveUserResponse(!showSaveUserResponse);
  };

  const handleButtonChange = (event: any) => {
    const value = event.target.value as string;
    setSelectedButtonValue(value);

    let flowResponseType = 0;
    let autoReplyWorkflowButtons = null;
    let workflowListId = null;

    if (value === "button") {
      flowResponseType = 1;
      autoReplyWorkflowButtons = workflowData[expandedStep - 1]
        ?.autoReplyWorkflowButtons || [""];
    } else if (value === "list") {
      flowResponseType = 2;
      workflowListId = workflowData[expandedStep - 1]?.workflowListId;
    }
    // console.log(
    //   "buttons",
    //   flowResponseType,
    //   autoReplyWorkflowButtons,
    //   workflowListId
    // );

    handleStepDataChange(expandedStep, {
      flowResponseType,
      autoReplyWorkflowButtons,
      workflowListId,
    });
  };

  const handleButtonTextChange = (index: number, value: string) => {
    const updatedButtons = [
      ...(workflowData[expandedStep - 1]?.autoReplyWorkflowButtons || []),
    ];
    updatedButtons[index] = value;
    handleButtonsChange(expandedStep, updatedButtons);
    // console.log("updatedButtons", updatedButtons);
  };

  const handleRemoveButton = (index: number) => {
    const updatedButtons = (
      workflowData[expandedStep - 1]?.autoReplyWorkflowButtons || []
    ).filter((_: any, i: number) => i !== index);
    handleButtonsChange(expandedStep, updatedButtons);
  };

  const handleAddButton = () => {
    const currentButtons =
      workflowData[expandedStep - 1]?.autoReplyWorkflowButtons || [];
    const updatedButtons = [...currentButtons, ""];
    handleButtonsChange(expandedStep, updatedButtons);
  };

  const handleListSelect = async (listData: any) => {
    // console.log("Selected list data:", listData);
    setSelectedList(listData);
    setOpenListDialog(false);

    // Prepare the payload for the API
    const payload = {
      id: listData.id,
      companyId: userData?.companyId,
      listName: listData.listName,
      buttonName: listData.buttonName,
      inputs: listData.inputs,
    };
    try {
      const result = await dispatch(updateWorkflowList(payload)).unwrap();
      if (result.success) {
        dispatch(
          toastActions.setToaster({
            message: "Workflow list updated successfully",
            type: "success",
          })
        );
      } else {
        dispatch(
          toastActions.setToaster({
            message: result.message || "Failed to update workflow list",
            type: "error",
          })
        );
      }
    } catch (error) {
      dispatch(
        toastActions.setToaster({
          message: "An error occurred while updating the workflow list",
          type: "error",
        })
      );
    }
  };

  useEffect(() => {
    const selectedItem = selectResponseData?.find(
      (item: any) =>
        item.selectResponse === workflowData[expandedStep - 1]?.title
    );
    setSelectedResponse(selectedItem?.id);
    if (workflowData?.length > 0 && workflowData[expandedStep - 1]?.title) {
      setEditorState(
        EditorState?.createWithContent(
          parseTextToDraft(workflowData[expandedStep - 1]?.title)
        )
      );
    }
  }, [selectResponseData]);

  const handlePopupSuccess = () => {
    // Refresh the variable list
    // fetchWorkflowVariableNames();
  };

  const handleDeleteClick = (item: any) => {
    setIsDeleteConfirmOpen(true);
    setDeleteSelectResponseId(item.id);
  };
  const handleDeleteCancel = () => {
    setIsDeleteConfirmOpen(false);
  };

  const handleDeleteConfirm = async () => {
    try {
      const payload = {
        selectResponseId: deleteSelectResponseId,
      };
      const result = await dispatch(deleteSelectResponse(payload)).unwrap();
      if (result.success) {
        setSelectedResponse("");
        handleStepDataChange(expandedStep, {
          // selectResponse: event,
          title: "",
        });
        dispatch(
          toastActions.setToaster({
            message: "Workflow deleted successfully",
            type: "success",
          })
        );
        // Refresh the workflow list
        dispatch(getSelectResponse({ companyId: userData?.companyId }));
      } else {
        dispatch(
          toastActions.setToaster({
            message: result.message || "Failed to delete workflow",
            type: "error",
          })
        );
      }
    } catch (error: any) {
      dispatch(
        toastActions.setToaster({
          message:
            error.message || "An error occurred while deleting the workflow",
          type: "error",
        })
      );
    }
    setIsDeleteConfirmOpen(false);
  };

  const handleEditResponse = (item: any) => {
    setEditSelectResponseId(item?.id);
    setResponse(item?.selectResponse);
    setOpenAddSelectResponseDialog(true);
  };

  // console.log(selectedRadioValue, "selectedRadioValue");
  return (
    <>
      <Box>
        {selectedRadioValue == 2 ? (
          <>
            <DraftEditorComponent
              editorState={editorState}
              handleEditorStateChange={(newEditorState: EditorState) => {
                setEditorState(newEditorState);
                handleEditorStateChange(expandedStep, newEditorState, "editor");
                const updatedVariables = extractVariables(
                  newEditorState,
                  workflowData[expandedStep - 1]?.veriables || []
                );
                handleVariablesChange(expandedStep, updatedVariables);
              }}
              reactDraftWysiwygToolbarOptionsarticle={
                reactDraftWysiwygToolbarOptionsarticle
              }
            />
            <Typography
              variant="body2"
              onClick={handleAddVariable}
              style={{
                color: "green",
                fontWeight: "600",
                fontSize: "14px",
                cursor: "pointer",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              + Add Variables
            </Typography>
            <VariableAssignmentComponent
              expandedStep={expandedStep}
              editorState={editorState}
              variableType={1}
              workflowData={workflowData}
              variableOptions={variableOptions}
              dictionaryOptions={dictionaryOptions}
              handleVariablesChange={handleVariablesChange}
              handleEditorStateChange={handleEditorStateChange}
            />
          </>
        ) : (
          // <CustomResponseDropdownComponent
          //   workflowData={workflowData}
          //   expandedStep={expandedStep}
          //   editorState={editorState}
          //   setEditorState={setEditorState}
          //   handleEditorStateChange={handleEditorStateChange}
          //   handleVariablesChange={handleVariablesChange}
          //   handleAddVariable={handleAddVariable}
          //   handleRemoveVariable={handleRemoveVariable}
          //   handleVariableTypeChange={handleVariableTypeChange}
          //   handleVariableValueChange={handleVariableValueChange}
          //   handleFallbackValueChange={handleFallbackValueChange}
          //   handleAccordionChange={handleAccordionChange}
          //   expanded={expanded}
          //   reactDraftWysiwygToolbarOptionsarticle={
          //     reactDraftWysiwygToolbarOptionsarticle
          //   }
          //   variableOptions={variableOptions}
          //   dictionaryOptions={dictionaryOptions}
          // />
          <>
            <Select
              fullWidth
              displayEmpty
              value={selectedResponse}
              onChange={handleResponseChange}
              renderValue={(selected) => {
                if (!selected) {
                  return (
                    <Typography style={{ color: "#a8a7a7", fontSize: "14px" }}>
                      Select a Response
                    </Typography>
                  );
                }
                const selectedItem = selectResponseData?.find(
                  (item: any) => item.id === selected
                );
                return selectedItem ? selectedItem.selectResponse : "";
              }}
              sx={{
                height: "36px",
                fontSize: "14px",
                borderRadius: "10px",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "10px",
                },
              }}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 200,
                    overflowY: "auto",
                  },
                },
              }}
              endAdornment={
                <InputAdornment position="end">
                  <MenuItem
                    value="create_new"
                    sx={{
                      color: "green",
                      fontWeight: "600",
                      marginRight: "10px",
                      fontSize: "14px",
                      cursor: "pointer",
                    }}
                    onClick={handleAddSelectResponse}
                  >
                    + Add
                  </MenuItem>
                </InputAdornment>
              }
            >
              {/* <MenuItem disabled value="">
                <em>Select a response</em>
              </MenuItem> */}
              {selectResponseData &&
                selectResponseData.length > 0 &&
                selectResponseData
                  .filter((item: any) => !item.isDeleted)
                  .map((item: any) => (
                    <MenuItem
                      key={item.id}
                      value={item.id}
                      sx={{
                        width: "270px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        fontSize: "14px",
                      }}
                    >
                      <Tooltip title={item.selectResponse} placement="top">
                        <Box
                          component="span"
                          sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "calc(100% - 80px)",
                          }}
                        >
                          {item.selectResponse}
                        </Box>
                      </Tooltip>
                      <Box onClick={(e) => e.stopPropagation()}>
                        <IconButton
                          size="small"
                          onClick={() => handleEditResponse(item)}
                        >
                          <EditIconSvg />
                        </IconButton>
                        <IconButton
                          size="small"
                          onClick={() => handleDeleteClick(item)}
                        >
                          <DeleteSvg />
                        </IconButton>
                      </Box>
                    </MenuItem>
                  ))}
            </Select>
          </>
        )}
        <AddSelectResponse
          open={openAddselectResponseDialog}
          handleClose={handleCloseSelectResponse}
          handleResponseChange={handleResponseChange}
          editSelectResponseId={editSelectResponseId}
          setResponse={setResponse}
          response={response}
        />
        <DeletePopUp
          open={isDeleteConfirmOpen}
          handleDelete={handleDeleteConfirm}
          handleClose={handleDeleteCancel}
          title="select response"
          handleLoad={isWorkflowDeleteLoading}
        />
        <Typography sx={{ fontSize: 14, mt: 2, mb: 2 }}>
          Add buttons or lists to your response
        </Typography>
        <Select
          fullWidth
          displayEmpty
          value={selectedButtonValue}
          onChange={handleButtonChange}
          sx={{
            mb: 2,
            height: "36px",
            fontSize: "14px",
            borderRadius: "10px",
            "& .MuiOutlinedInput-root": {
              borderRadius: "10px",
            },
          }}
        >
          {ButtonOptions.map((option) => (
            <MenuItem
              key={option.value}
              sx={{ fontSize: 14 }}
              value={option.value}
            >
              {option.label}
            </MenuItem>
          ))}
        </Select>

        {selectedButtonValue === "button" && (
          <Box>
            {workflowData[expandedStep - 1]?.autoReplyWorkflowButtons?.map(
              (buttonText: string, index: number) => (
                <React.Fragment key={index}>
                  <Typography sx={{ mr: 2, fontSize: "14px" }}>
                    Button {index + 1}
                  </Typography>
                  <Box
                    sx={{ display: "flex", alignItems: "center", mb: 2, mt: 1 }}
                  >
                    <TextFieldWithBorderComponent
                      label={`Button text ${index + 1}`}
                      value={buttonText}
                      name="buttonText"
                      onChange={(e) =>
                        handleButtonTextChange(index, e.target.value)
                      }
                      placeholder={`Button text ${index + 1}`}
                      fullWidth
                      size="small"
                      // sx={{
                      //   width: "80%",
                      //   height: "36px",
                      //   fontSize: "14px",
                      //   borderRadius: "10px",
                      //   "& .MuiOutlinedInput-root": {
                      //     borderRadius: "10px",
                      //   },
                      // }}
                    />
                    <IconButton
                      onClick={() => handleRemoveButton(index)}
                      size="small"
                      sx={{ ml: 1 }}
                    >
                      <DeleteIconSvg />
                    </IconButton>
                  </Box>
                </React.Fragment>
              )
            )}
            {workflowData[expandedStep - 1]?.autoReplyWorkflowButtons?.length <
              3 && (
              <Typography
                onClick={handleAddButton}
                style={{
                  color: "green",
                  fontWeight: "600",
                  fontSize: "14px",
                  cursor: "pointer",
                }}
              >
                + Add another button
              </Typography>
            )}
          </Box>
        )}

        {selectedButtonValue === "list" && (
          <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
            {workflowListStatus === "loading" ? (
              <LoadingComponent height="100%" color={bgColors?.blue} />
            ) : (
              <Typography
                onClick={() => setOpenListDialog(true)}
                style={{
                  color: "green",
                  fontWeight: "600",
                  fontSize: "14px",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <span style={{ fontSize: "20px" }}>≡</span>
                {selectedList ? selectedList.listName : "Select a List"}
              </Typography>
            )}
          </Box>
        )}

        <SelectListDialog
          open={openListDialog}
          onClose={() => setOpenListDialog(false)}
          onSelect={handleListSelect}
          workflowListData={workflowListData}
          // selectedList={selectedList}
          handleSelectedListChange={handleSelectedListChange}
          expandedStep={expandedStep}
        />

        <Box sx={{ mt: 1, mb: 2 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              onClick={toggleSaveUserResponse}
              style={{
                color: "green",
                fontWeight: "600",
                fontSize: "14px",
                cursor: "pointer",
              }}
            >
              + Save user response
            </Typography>
            <Box>
              <IconButton size="small" onClick={toggleSaveUserResponse}>
                {showSaveUserResponse ? (
                  <CancelOutlinedIcon fontSize="small" color="error" />
                ) : (
                  <CheckCircleOutlineIcon fontSize="small" color="primary" />
                )}
              </IconButton>
            </Box>
          </Box>

          {showSaveUserResponse && (
            <Box
              sx={{
                mt: 2,
                p: 2,
                bgcolor: "white",
                border: "1px solid #cdcdcd",
                borderRadius: "4px",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  top: 2,
                  right: 0,
                  display: "flex",
                }}
              >
                <IconButton
                  size="small"
                  onClick={() => {
                    /* Add save functionality */
                  }}
                  sx={{ mr: 1 }}
                >
                  <CheckCircleOutlineIcon fontSize="small" color="primary" />
                </IconButton>
              </Box>
              {/* <Typography variant="body2" sx={{ mb: 1 }}>
                Select where you want to save this user response
              </Typography> */}
              <RadioGroup
                value={saveResponseType}
                onChange={(e) => setSaveResponseType(e.target.value)}
              >
                {SaveResponseRadioOptions.map((option) => (
                  <FormControlLabel
                    key={option.value}
                    value={option.value}
                    control={
                      <Radio
                        sx={{
                          "&.Mui-checked": { color: "green" },
                          "& .MuiSvgIcon-root": { fontSize: 16 },
                        }}
                      />
                    }
                    label={option.label}
                    sx={{
                      "& .MuiFormControlLabel-label": { fontSize: "14px" },
                    }}
                  />
                ))}
              </RadioGroup>
              {saveResponseType === "Variable" && (
                <Select
                  fullWidth
                  displayEmpty
                  value={
                    workflowData[expandedStep - 1]?.responseCustomerMessage
                      ?.veriableName?.id || selectedVariable
                  }
                  onChange={handleSaveUserVariableChange}
                  sx={{
                    mt: 1,
                    height: "36px",
                    fontSize: "14px",
                    borderRadius: "10px",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "10px",
                    },
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 150,
                        overflowY: "auto",
                      },
                    },
                  }}
                >
                  <MenuItem value="" disabled>
                    <Typography style={{ fontSize: "14px" }}>
                      Select a variable
                    </Typography>
                  </MenuItem>
                  {data?.map((item: any) => (
                    <MenuItem
                      key={item.id}
                      value={item.id}
                      sx={{ fontSize: 14 }}
                    >
                      {item.veriableName}
                    </MenuItem>
                  ))}
                  <Box
                    sx={{
                      position: "sticky",
                      bottom: 0,
                      backgroundColor: "background.paper",
                      borderTop: "1px solid",
                      borderColor: "divider",
                      display: "flex",
                      justifyContent: "center",
                      p: 1,
                    }}
                  >
                    <Typography
                      sx={{
                        color: "green",
                        fontWeight: 600,
                        fontSize: "14px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleAddNewClick()}
                    >
                      + Add New
                    </Typography>
                  </Box>
                </Select>
              )}
              <AddVariablePopup
                open={isAddNewPopupOpen}
                onClose={handlePopupClose}
                onSuccess={handlePopupSuccess}
                setNewVariableName={setNewVariableName}
                newVariableName={newVariableName}
              />
            </Box>
          )}
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          {createWorkflowStatus === "loading" ||
          updateWorkflowStatus === "loading" ? (
            <LoadingComponent height="50px" color={bgColors.blue} />
          ) : (
            <Button
              fullWidth
              variant="outlined"
              onClick={() => handleSave(expandedStep)}
              style={{
                color: bgColors.green,
                border: `1px solid ${bgColors.green}`,
                borderRadius: "8px",
                width: "70px",
                height: "36px",
                textTransform: "capitalize",
                // padding: "5px",
                cursor: "pointer",
                backgroundColor: "#fff",
                marginTop: "3px",
              }}
            >
              {isWorkflowEditing ? "Update" : "Save"}
            </Button>
          )}
        </Box>
      </Box>
    </>
  );
};

export default CustomResponseComponent;
