import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GET_MANAGE_ACCOUNT } from "../../../Apis/ManageAccountApis/GetManangeAccount";

export interface IData {
  status: "loading" | "succeeded" | "failed" | "idle";
  data: any;
}

const initialState: IData = {
  status: "idle",
  data: null,
};

export const fetchAccountMetaStatus = createAsyncThunk(
  "getAccountMetaStatus",
  async (id: any) => {
    const response = await GET_MANAGE_ACCOUNT.getAccountMetaStatus(id);
    // console.log("response", response);
    return response?.data;
  }
);

export const AccountMetaStatusSlice = createSlice({
  name: "AccountMetaStatusSlice",
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchAccountMetaStatus.pending, (state) => {
        state.status = "loading";
        //   state.error = ""
        state.data = [];
      })
      .addCase(fetchAccountMetaStatus.fulfilled, (state, action) => {
        state.status = "succeeded";
        //   state.error = ""
        state.data = action.payload;
        //   state.EstaActivo = true;
        //   state.filterData = action.payload.filter((each:any) => _.get(each,'EstaActivo'))
      })
      .addCase(fetchAccountMetaStatus.rejected, (state) => {
        state.status = "failed";
        state.data = [];
        //   state.error = action.error.message || "";
      });
  },
});

export const AccountMetaStatusAction = AccountMetaStatusSlice.actions;
export default AccountMetaStatusSlice.reducer;
