import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ADMIN_MANAGE_COMPANY_APIS } from "../../../Apis/ManageCompany/ManageCompany";

export interface IData {
  status: "loading" | "succeeded" | "failed" | "idle";
  data: any;
}

const initialState: IData = {
  status: "idle",
  data: null,
};

export const fetchCompanyDetails = createAsyncThunk(
  "getCompanyDetails",
  async (id: any) => {
    const response = await ADMIN_MANAGE_COMPANY_APIS.getCompanyDetails(id);
    // console.log("response", response);
    return response?.data;
  }
);

export const CompanyDetailsSlice = createSlice({
  name: "CompanyDetailsSlice",
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchCompanyDetails.pending, (state) => {
        state.status = "loading";
        //   state.error = ""
        state.data = [];
      })
      .addCase(fetchCompanyDetails.fulfilled, (state, action) => {
        state.status = "succeeded";
        //   state.error = ""
        state.data = action.payload;
        //   state.EstaActivo = true;
        //   state.filterData = action.payload.filter((each:any) => _.get(each,'EstaActivo'))
      })
      .addCase(fetchCompanyDetails.rejected, (state) => {
        state.status = "failed";
        state.data = [];
        //   state.error = action.error.message || "";
      });
  },
});

export const CompanyDetailsActions = CompanyDetailsSlice.actions;
export default CompanyDetailsSlice.reducer;
