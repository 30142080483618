import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Grid, Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Template from "../../assets/svgs/TemplateSvg";
import { useAppSelector } from "../../utils/redux-hooks";
import SettingsInputSvideoIcon from "@mui/icons-material/SettingsInputSvideo";
import CategoryIcon from "@mui/icons-material/Category";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import StoreIcon from "@mui/icons-material/Store";
import WidgetsOutlinedIcon from "@mui/icons-material/WidgetsOutlined";
import {
  checkAutoCheckoutFlowPermission,
  checkCatalogPermission,
  checkCommerceSettingsPermission,
  checkDiscoverPermission,
  checkOrderPanelPermission,
} from "../../utils/permissions";

const useStyles = makeStyles({
  container: {
    backgroundColor: "#F8F8F8",
    height: "100vh",
    textAlign: "center",
  },
  sideBarContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
    alignItems: "center",
  },
  manageItem: {
    cursor: "pointer",
    padding: "5px",
  },
  activeItem: {
    backgroundColor: "#f0f0f0",
    color: "#303030 !important",
    "& .MuiTypography-root": {
      fontWeight: "600 !important",
    },
  },
  signOutContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  textColor: {
    color: "#4B5A5A",
    fontSize: "20px",
  },
  blackColor: {
    color: "#000000",
    fontWeight: "600 !important",
  },
  sideBar: {
    position: "sticky",
    top: 0,
    height: "100vh",
    overflowY: "auto",
  },
});

const IntegrationsSidebar = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  const getuserPermissionSlice = useAppSelector(
    (state: any) => state.getUserPermissions
  );
  const getUserPermissionData = getuserPermissionSlice?.data;

  const [activeSection, setActiveSection] = useState("");

  const hasDiscoverPermission = checkDiscoverPermission(
    getUserPermissionData?.integrations
  );

  // Function to handle navigation and set active section
  const handleNavigation = (path: string) => {
    navigate(`/${path}`);
    setActiveSection(path);
  };

  useEffect(() => {
    const path = location.pathname;
    setActiveSection(path || "");
  }, [location.pathname]);

  return (
    <Grid className={classes.container}>
      <Box textAlign="start" pl={1} className={classes.sideBarContainer} pt={3}>
        <Box m={2} mb={1}>
          {/* <StoreIcon /> */}
          <WidgetsOutlinedIcon />
        </Box>
        <Typography variant="h6" className={classes.blackColor}>
          Integrations
        </Typography>
      </Box>

      <Box>
        {/* {!hasDiscoverPermission && ( */}
        {/* <Box
          m={2}
          pl={1}
          textAlign="start"
          className={`${classes.manageItem} ${classes.textColor} ${
            activeSection.includes("integrations-discover")
              ? classes.activeItem
              : ""
          }`}
          onClick={() => handleNavigation("integrations-discover")}
        >
          <Typography
            fontSize="14px"
            sx={{ display: "flex", alignItems: "center" }}
          >
            
            &nbsp;Discover
          </Typography>
        </Box> */}
        {/* )} */}
        {/* {!hasDiscoverPermission && ( */}
        <Box
          m={2}
          pl={1}
          textAlign="start"
          className={`${classes.manageItem} ${classes.textColor} ${
            activeSection.includes("whatsapp-link") ? classes.activeItem : ""
          }`}
          onClick={() => handleNavigation("whatsapp-link")}
        >
          <Typography
            fontSize="14px"
            sx={{ display: "flex", alignItems: "center" }}
          >
            {/* <span style={{ paddingTop: "2px" }}>
              <WidgetsOutlinedIcon
                fontSize="small"
                sx={{ marginTop: "5x", height: "18px" }}
              />
            </span> */}
            &nbsp;Whatsapp link
          </Typography>
        </Box>
        {/* )} */}
        {/* {!hasDiscoverPermission && ( */}
        <Box
          m={2}
          pl={1}
          textAlign="start"
          className={`${classes.manageItem} ${classes.textColor} ${
            activeSection.includes("whatsapp-widget") ? classes.activeItem : ""
          }`}
          onClick={() => handleNavigation("whatsapp-widget")}
        >
          <Typography
            fontSize="14px"
            sx={{ display: "flex", alignItems: "center" }}
          >
            {/* <span style={{ paddingTop: "2px" }}>
              <WidgetsOutlinedIcon
                fontSize="small"
                sx={{ marginTop: "5x", height: "18px" }}
              />
            </span> */}
            &nbsp;Whatsapp widget
          </Typography>
        </Box>
        {/* )} */}
      </Box>
    </Grid>
  );
};

export default IntegrationsSidebar;
