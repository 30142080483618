import React from 'react'

const CheckCircleSvg = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C19.9936 4.47982 15.5202 0.00642897 10 0Z" fill="#5243C2" fillOpacity="0.103693"/>
<path d="M13.4953 6.7533C13.204 6.72805 12.9117 6.84849 12.737 7.08322L9.26981 11.3733L7.09234 9.78501C6.74046 9.5283 6.22846 9.57581 5.93971 9.90476C5.63851 10.2479 5.70599 10.7506 6.07104 11.0169L8.91279 13.0883L8.91496 13.0899C9.09165 13.2158 9.31246 13.2695 9.52841 13.2436C9.74444 13.2177 9.94582 13.1132 10.0841 12.946L14.0621 8.02428C14.257 7.80083 14.3066 7.49063 14.1816 7.21975C14.0575 6.95073 13.7891 6.77878 13.4953 6.7533Z" fill="#00934F"/>
</svg>
  )
}

export default CheckCircleSvg

