import React from "react";
import { Popover, List, ListItem, ListItemText, Box } from "@mui/material";
import { RiSpam2Line } from "react-icons/ri";
import { MdOutlineAssignmentLate } from "react-icons/md";
import { IoMdDoneAll } from "react-icons/io";

const filtersArray = [
  {
    id: 1,
    filter: "All",
    icon: <IoMdDoneAll />,
  },
  {
    id: 2,
    filter: "Unassigned",
    icon: <MdOutlineAssignmentLate />,
  },
  {
    id: 3,
    filter: "Spam",
    icon: <RiSpam2Line />,
  },
];

interface FilterObject {
  column: string;
  operator: string;
  value: string | boolean;
}

export const basicFilterMapping: { [key: string]: FilterObject[] } = {
  Unassigned: [
    {
      column: "Assignee",
      operator: "equals",
      value: "",
    },
  ],
  Spam: [
    {
      column: "IsSpam",
      operator: "equals",
      value: "true",
    },
  ],
};

export const getSelectedFilter = (
  currentFilters: FilterObject[],
  basicFilterMapping: { [key: string]: FilterObject[] }
): string => {
  for (const key in basicFilterMapping) {
    const filter = basicFilterMapping[key];
    if (JSON.stringify(filter) === JSON.stringify(currentFilters)) {
      return key;
    }
  }
  return "All";
};

const BasicFilterPopover = ({
  anchorEl,
  selectedFilterText,
  handleClose,
  handleSelectBasicFilter,
}: any) => {
  const handleApplyBasicFilter = (filter: string) => {
    const filterData: FilterObject[] = basicFilterMapping[filter] || [];
    const Data = filterData.filter((filter) => filter.value !== "All");

    handleSelectBasicFilter({
      filters: { filters: Data },
      filterText: filter,
    });
    handleClose();
  };

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <List>
        {filtersArray.map(
          (option: { id: number; filter: string; icon: any }) => (
            <ListItem
              key={option.id}
              onClick={() => handleApplyBasicFilter(option.filter)}
              sx={{
                cursor: "pointer",
                paddingTop: 0.5,
                paddingBottom: 0.5,
                backgroundColor:
                  selectedFilterText === option.filter
                    ? "lightgray"
                    : "transparent",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                {option?.icon}
                <ListItemText
                  primary={option.filter}
                  primaryTypographyProps={{ fontSize: 12 }}
                />
              </Box>
            </ListItem>
          )
        )}
      </List>
    </Popover>
  );
};

export default BasicFilterPopover;
