import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { WORKFLOW_API } from "../../../Apis/AdminLogin/Automation/Workflows";

export interface IData {
  workflowStatus: "loading" | "succeeded" | "failed" | "idle";
  workflowData: any;
}

const initialState: IData = {
  workflowStatus: "idle",
  workflowData: null,
};

export const getWorkflow = createAsyncThunk(
  "workflow/getWorkflow",
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await WORKFLOW_API.getWorkflow(payload);
      return response?.data;
    } catch (error: any) {
      // Provide a descriptive error message
      return rejectWithValue(error.response || "An error occurred");
    }
  }
) as any;

export const getWorkflowSlice = createSlice({
  name: "getWorkflow",
  initialState,
  reducers: {
    resetWorkflowData: (state) => {
      state.workflowData = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWorkflow.pending, (state) => {
        state.workflowStatus = "loading";
        state.workflowData = null;
      })
      .addCase(getWorkflow.fulfilled, (state, action) => {
        state.workflowStatus = "succeeded";
        state.workflowData = action.payload.data;
      })
      .addCase(getWorkflow.rejected, (state) => {
        state.workflowStatus = "failed";
      });
  },
});

export const getWorkflowSliceActions = getWorkflowSlice.actions;
export default getWorkflowSlice.reducer;
