import React, { useState } from "react";
import {
  Box,
  Typography,
  Paper,
  Switch,
  FormControlLabel,
  Tooltip,
} from "@mui/material";
import TextFieldWithBorderComponent from "../common/TextFieldWithBorderComponent";
import RedDeleteIconSvg from "../../assets/svgs/RedDeleteIconSvg";
import EditIcon from "@mui/icons-material/Edit";
import { makeStyles } from "@mui/styles";
import { bgColors } from "../../utils/bgColors";
import { useAppSelector } from "../../utils/redux-hooks";
import LoadingComponent from "../common/LoadingComponent";
import TextFieldComponent from "./TextFieldComponent";

const useStyles = makeStyles({
  mainContainer: {
    backgroundColor: bgColors.white,
    borderRadius: "25px",
    marginTop: "20px",
    width: "100%",
  },
  chatArea: {
    padding: "20px",
  },
  chatTextBox: {
    position: "relative",
    border: "1px solid #262738",
    borderRadius: 8,
  },
  toolBarStyle: {
    border: "none",
    backgroundColor: "rgb(247, 244, 244)",
    padding: "10px 0",
    marginBottom: 0,
    order: 2,
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "10px",
  },
  wrapperClassName: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
  },
  editorClassName: {
    padding: "10px",
    minHeight: "60px",
    maxHeight: "200px",
    fontSize: "14px",
    order: 1,
  },
  fileUploadInput: {
    display: "none",
  },
  bgContainer: {
    backgroundColor: bgColors.white,
    borderRadius: "25px",
    height: "100%",
    width: "100%",
  },
  manageContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  textColor: {
    color: bgColors.black1,
    fontSize: "16px",
    opacity: "80%",
  },
  mainBorderStyles: {
    borderRadius: "18px",
    border: `2px solid ${bgColors.gray5}`,
  },
  emailContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  blackColor: {
    color: `${bgColors.black1} !important`,
    fontWeight: "600 !important",
  },
  resetBtnContainer: {
    backgroundColor: bgColors.white,
    // border: `2px solid ${bgColors.gray5}`,
    borderRadius: "8px",
    width: "100px",
    padding: "8px",
    textAlign: "center",
    cursor: "pointer",
  },
  variable: {
    color: `${bgColors.green} !important`,
    fontWeight: "600 !important",
    fontSize: "14px !important",
    cursor: "pointer",
  },
  changePasswordText: {
    color: bgColors.black,
    fontWeight: "600 !important",
  },
  welcomeContainer: {
    backgroundColor: bgColors.white,
    border: `2px solid ${bgColors.gray5}`,
    borderRadius: "8px",
    width: "90px",
    padding: "8px",
    textAlign: "center",
    cursor: "pointer",
  },
  cursor: {
    cursor: "pointer",
  },
  welcometext: {
    color: bgColors.black,
    fontWeight: "600 !important",
  },
  delayedContainer: {
    backgroundColor: bgColors.white,
    border: `2px solid ${bgColors.gray5}`,
    borderRadius: "8px",
    width: "90px",
    padding: "8px",
    textAlign: "center",
    cursor: "pointer",
  },
  grayColor: {
    color: "#303030",
    opacity: "60%",
    fontSize: "20px",
    // padding:"5px"
  },
  delayedtext: {
    color: bgColors.black,
    fontWeight: "600 !important",
  },
  hours: {
    color: bgColors.black,
    opacity: "80%",
    fontSize: "15px !important",
  },
  time: {
    color: bgColors.black,
    opacity: "80%",
    fontSize: "16px !important",
    // fontWeight: "Bold !important",
  },
  lineStyles: {
    border: "none",
    borderTop: "3px solid #4B5A5A",
    width: "100%",
    margin: "0 auto",
    opacity: "20%",
    marginTop: "5px",
  },
  SaveChangesButton: {
    color: bgColors.green,
    border: `1px solid ${bgColors.green}`,
    // backgroundColor: bgColors.blue,
    borderRadius: "8px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
    cursor: "pointer",
    width: "120px",
    height: "32px",
    gap: "0px",
  },
  mediaContainer: {
    position: "relative",
    display: "flex", // Added flexbox
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    marginTop: "10px",
    minHeight: 80,
    maxHeight: 500,
    borderRadius: "4px",
    padding: "18px",
    backgroundColor: "rgb(248, 248, 248)",
  },
  mediaFile: {
    width: "100%", // Set image to fill container width
    maxWidth: 330, // Max width
    height: "auto", // Maintain aspect ratio
    maxHeight: 400, // Max height
    objectFit: "contain", // Ensure image scales properly without distortion
    // border: "1px solid #cdcdcd",
    borderRadius: "4px",
  },
});

const OptInAndOptOutComponent = ({
  textFieldRef,
  keywords,
  showTextField,
  currentKeyword,
  validKeyword,
  handleKeywordChange,
  handleAddKeywords,
  handleSaveSettings,
  handleDeleteKeyword,
  handleEditResponse,
  showResponseTextField,
  responseMessage,
  setValidResponseMessage,
  handleSwitchChange,
  isChecked,
  handleResponseChange,
  handleSaveResponse,
  validationMessage,
  optStatus,
  validResponseMessage,
  // handleSendMessage,
  mediaUrl,
  setMediaUrl,
  setResponseMessage,
  openTextField,
  //   bgColors,
  //   classes,
  maxKeywords = 5,
  type,
  label,
}: any) => {
  // console.log(mediaUrl);
  // console.log(validationMessage);
  // console.log(keywords,'keywords');
  const classes = useStyles();
  // const handleSendMessage = (messageState: any, resetState: () => void) => {
  //   console.log(messageState);
  //   console.log(resetState);
  // };
  return (
    <Box
      sx={{
        ml: 3,
        mr: 2,
        mt: 3,
        mb: type === "opt-in" ? 3 : 0, // Conditionally set mb based on someCondition
      }}
    >
      <Box p={4} className={classes.mainBorderStyles}>
        {optStatus === "loading" ? (
          <LoadingComponent height="300px" color={bgColors?.blue} />
        ) : (
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              justifyContent: "space-between",
              marginBottom: { xs: 0, lg: 5 },
            }}
          >
            <Box>
              <Typography>
                {type === "opt-in" ? "Opt-in" : "Opt-out"} Keywords
              </Typography>
              <Typography mt={2} sx={{ fontSize: "14px", color: "gray" }}>
                This user will have to type one of these messages
                <Typography sx={{ fontSize: "14px", color: "gray" }}>
                  on which they should automatically{" "}
                  {type === "opt-in" ? "opt-in" : "opt-out"}
                </Typography>
              </Typography>

              {keywords?.map((keyword: any, index: any) => (
                <Box
                  sx={{
                    display: "flex",
                  }}
                >
                  <Tooltip title={keyword} placement="top-start">
                    <Typography
                      key={index}
                      sx={{
                        justifyContent: "space-evenly",
                        backgroundColor: bgColors?.gray2,
                        fontSize: 13,
                        marginTop: "8px",
                        marginBottom: "10px",
                        width: "50%",
                        padding: "10px",
                        paddingBottom: "2px",
                        borderRadius: "10px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {keyword}
                    </Typography>
                  </Tooltip>
                  <Box
                    sx={{ color: "red", cursor: "pointer", ml: 2 }}
                    onClick={() => handleDeleteKeyword(keyword, index)}
                  >
                    <RedDeleteIconSvg
                    // sx={{ color: "red", cursor: "pointer", ml: 2 }}
                    // onClick={() => handleDeleteKeyword(index)}
                    />
                  </Box>
                </Box>
              ))}

              {keywords?.length < maxKeywords && showTextField && (
                <TextFieldWithBorderComponent
                  margin="dense"
                  label={`Enter your ${
                    type === "opt-in" ? "opt-in" : "opt-out"
                  } keyword`}
                  name="currentKeyword"
                  placeholder=""
                  value={currentKeyword}
                  onChange={handleKeywordChange}
                  error={!validKeyword}
                  helperText={!validKeyword ? validationMessage : ""}
                  sx={{ width: "100%", mt: 5 }}
                />
              )}

              {keywords?.length < maxKeywords && (
                <Typography
                  sx={{ cursor: "pointer", mt: 4 }}
                  onClick={handleAddKeywords}
                >
                  + Add more
                </Typography>
              )}

              <button
                style={{ marginTop: "25px" }}
                className={classes.SaveChangesButton}
                onClick={handleSaveSettings}
              >
                Save Settings
              </button>
            </Box>

            <Box>
              <Box sx={{ display: "flex", gap: "20px" }}>
                <Typography>
                  {type === "opt-in" ? "Opt-in" : "Opt-out"} Response
                </Typography>
                <Box sx={{ display: "flex" }}>
                  {/* <Switch /> */}

                  <Switch
                    checked={isChecked}
                    onChange={handleSwitchChange}
                    color="primary"
                  />
                  {!showResponseTextField ? (
                    <button
                      className={classes.SaveChangesButton}
                      onClick={handleEditResponse}
                    >
                      <EditIcon /> Configure
                    </button>
                  ) : (
                    <button
                      className={classes.SaveChangesButton}
                      onClick={handleSaveResponse}
                    >
                      Save
                    </button>
                  )}
                </Box>
              </Box>
              <Typography
                mt={2}
                mb={2}
                sx={{ fontSize: "14px", color: "gray" }}
              >
                Setup a response message for{" "}
                {type === "opt-in" ? "opt-in" : "opt-out"} user keywords
              </Typography>

              {!showResponseTextField ? (
                <>
                  {mediaUrl && (
                    <Box className={classes.mediaContainer}>
                      {/* {messageState?.selectedFile.type.startsWith("image/") && ( */}
                      <img
                        src={mediaUrl}
                        alt="Selected File"
                        className={classes.mediaFile}
                      />
                      {/* )} */}
                    </Box>
                  )}
                  <Paper
                    elevation={3}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      p: 2,
                      borderRadius: 2,
                      maxWidth: 320,
                      backgroundColor: "#F1F1F1",
                      mt: 2,
                      ml: 3,
                    }}
                  >
                    <Box>
                      <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                        {responseMessage}
                      </Typography>
                    </Box>
                  </Paper>
                </>
              ) : (
                <>
                  {/* <TextFieldWithBorderComponent
                    margin="dense"
                    label={`Enter your ${
                      type === "opt-in" ? "opt-in" : "opt-out"
                    } response`}
                    name="currentResponse"
                    placeholder=""
                    value={responseMessage}
                    onChange={handleResponseChange}
                    error={validResponseMessage !== ""}
                    helperText={
                      validResponseMessage !== "" ? validResponseMessage : ""
                    }
                    sx={{ width: "100%", mt: 5 }}
                  /> */}
                  <TextFieldComponent
                    ref={textFieldRef}
                    openTextField={openTextField}
                    type={type}
                    responseMessage={responseMessage}
                    setValidResponseMessage={setValidResponseMessage}
                    mediaUrl={mediaUrl}
                    setMediaUrl={setMediaUrl}
                    validResponseMessage={validResponseMessage}
                    setResponseMessage={setResponseMessage}
                  />
                </>
              )}

              <Typography
                sx={{
                  fontSize: 14,
                  color: isChecked ? "#4CAF50" : "#B0B0B0",
                  mt: 5,
                  ml: 3,
                }}
              >
                {!isChecked
                  ? "Auto response is disabled"
                  : "Auto response is enabled"}
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default OptInAndOptOutComponent;
