import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { MANAGE_CLIENTS } from "../../../Apis/AdminLogin/ManageClients/ManageClient";

export interface IData {
  status: "loading" | "succeeded" | "failed" | "idle";
  data: any;
}

const initialState: IData = {
  status: "idle",
  data: null,
};

export const fetchGetAllClients = createAsyncThunk(
  "fetchGetAllClients",
  async (data: any) => {
    const response = await MANAGE_CLIENTS.getAllClients(data);

    return response?.data;
  }
);

export const getAllCientSlice = createSlice({
  name: "fetchGetAllClients",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchGetAllClients.pending, (state) => {
        state.status = "loading";
        //   state.error = ""
        state.data = [];
      })
      .addCase(fetchGetAllClients.fulfilled, (state, action) => {
        state.status = "succeeded";
        //   state.error = ""
        state.data = action.payload;
        //   state.EstaActivo = true;
        //   state.filterData = action.payload.filter((each:any) => _.get(each,'EstaActivo'))
      })
      .addCase(fetchGetAllClients.rejected, (state) => {
        state.status = "failed";
        state.data = [];
        //   state.error = action.error.message || "";
      });
  },
});

export const getAllClientsSliceActions = getAllCientSlice.actions;
export default getAllCientSlice.reducer;
