import {
  Popover,
  ListItem,
  ListItemText,
  Checkbox,
  Typography,
  Box,
  TextField,
  IconButton,
  Tooltip,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { bgColors } from "../../utils/bgColors";
import RedDeleteIconSvg from "../../assets/svgs/RedDeleteIconSvg";
import { CONTACT_TAGS_APIS } from "../../Apis/Contacts/ContactTagsApis";
import { useAppDispatch, useAppSelector } from "../../utils/redux-hooks";
import React, { useState } from "react";
import { fetchAllContacts } from "../../redux/slices/Contacts/AllContactsSlice";
import { toastActions } from "../../utils/toastSlice";
import { getContactTags } from "../../redux/slices/Contacts/getContactTags";
import { Dialog, DialogTitle, DialogContent } from "@mui/material";
import LoadingComponent from "../common/LoadingComponent";
import SearchIconSvg2 from "../../assets/svgs/SearchIconSvg2";
import ChatInformationSvg from "../../assets/svgs/ChatInformationSvg";
import { IoMdPricetag } from "react-icons/io";

const useStyles = makeStyles({
  searchField: {
    width: "113%",
    borderRadius: "12px",
    height: "38px",
    fontSize: "18px",
    // backgroundColor: bgColors.white2,
    backgroundColor: "white",
    "& input::placeholder": {
      textAlign: "left",
      fontSize: "14px",
      fontFamily: "Inter",
      color: "#4B5A5A !important",
      fontWeight: "500 !important",
    },
  },
  lineStyles: {
    border: "none",
    borderTop: "3px solid #4B5A5A",
    width: "80%",
    // margin: "0 auto",
    opacity: "20%",
  },
  resetBtnStyles: {
    color: bgColors.green,
    border: `1px solid ${bgColors.green}`,
    borderRadius: "8px",
    width: "70px",
    height: "32px",
    // padding: "5px",
    cursor: "pointer",
    backgroundColor: "#fff",
    "&:hover": {
      backgroundColor: "rgba(68, 71, 70, 0.08)",
    },
  },
  SaveChangesButton: {
    color: bgColors.green,
    border: `1px solid ${bgColors.green}`,
    borderRadius: "8px",
    width: "110px",
    height: "32px",
    // padding: "3px",
    cursor: "pointer",
    backgroundColor: "#fff",
    "&:hover": {
      backgroundColor: "rgba(68, 71, 70, 0.08)",
    },
  },
  deleteIcon: {
    color: "red",
    visibility: "hidden",
    cursor: "pointer",
  },
  listItem: {
    position: "relative",
    "&:hover $deleteIcon": {
      visibility: "visible",
    },
    // padding: "-5px"
  },
});

const ContactsFilterPopover = ({
  anchorEl,
  handleClose,
  options,
  handleSearchChange,
  setSearchQuery,
  setPage,
  setTeamData,
}: any) => {
  // console.log(options)
  const classes = useStyles();
  // console.log(options, "options")
  const dispatch = useAppDispatch();
  const userProfileSlice = useAppSelector((state: any) => state?.adminLogin);
  const userData = userProfileSlice?.data;
  const getuserPermissionData = useAppSelector(
    (state: any) => state.getUserPermissions?.data
  );
  const manageContactsObject = getuserPermissionData?.contacts;

  const [checkedIds, setCheckedIds] = useState<number[]>([]);
  const [deleteTagsTooltip, setDeleteTagsTooltip] = useState(null);
  const [deleteOptionId, setDeleteOptionId] = useState(null);
  const [openConfirmationDialog, setOpenConfirmationDialog] =
    useState<any>(false);
  const [isDeleteTagLoading, setIsDeleteTagLoading] = useState<any>(false);

  // Filter out options with empty tag
  const filteredOptions = options?.filter(
    (option: { tag: string }) => option?.tag?.trim() !== ""
  );
  // console.log(filteredOptions);
  
  const handleDialogOpen = (option: any) => {
    setOpenConfirmationDialog(true);
    setDeleteOptionId(option);
  };

  const handleDialogClose = () => {
    setOpenConfirmationDialog(false);
  };

  const handleResetFilter = async () => {
    setCheckedIds([]);
    setPage(1);
    setTeamData([]);
    // const bodyData = {
    //   businessId: userData?.companyId,
    //   userId: userData?.userId,
    //   page: 1,
    //   per_page: 40,
    //   filterType: "or",
    //   column: "Tags",
    //   value: [],
    // };
    // const getContactTags = await dispatch(fetchAllContacts(bodyData));
    handleClose();
    setSearchQuery("");
  };

  // console.log(checkedIds, options);
  const handleContactsTagApply = async () => {
    // console.log(options)
    if (checkedIds.length > 0) {
      // console.log(setPage)
      // console.log("called")
      // setPage && setPage(1)
      if (setTeamData) {
        setTeamData(checkedIds);
      }
      try {
        const bodyData = {
          businessId: userData?.companyId,
          userId: userData?.userId,
          page: 1,
          per_page: 40,
          filterType: "or",
          column: "Tags",
          value: checkedIds,
        };
        const getContactTags = await dispatch(fetchAllContacts(bodyData));

        if (getContactTags?.meta?.requestStatus === "fulfilled") {
          handleClose();
          setSearchQuery("");
        }
      } catch (error) {
        console.log("Error fetching contacts", error);
      }
    } else {
      dispatch(
        toastActions.setToaster({
          type: "error",
          message: `Please select atleast one tag`,
        })
      );
    }
  };

  // console.log(checkedIds);
  const handleCheckboxChange = (id: number) => {
    setCheckedIds((prevIds) =>
      prevIds.includes(id)
        ? prevIds.filter((item) => item !== id)
        : [...prevIds, id]
    );
  };
  const hasDeleteTagPermission = (permission: any) => {
    for (const profileItem of permission) {
      if (Object.prototype.hasOwnProperty.call(profileItem, "deleteTags")) {
        return true;
      }
    }
  };
  const handleDeleteTag = async () => {
    const hasPermission = hasDeleteTagPermission(manageContactsObject);
    if (hasPermission) {
      const data = {
        businessId: userData?.companyId,
        userId: userData?.userId,
        tagIds: deleteOptionId,
      };
      setIsDeleteTagLoading(true);
      const getDelRes = await CONTACT_TAGS_APIS.deleteTags(data);

      if (getDelRes?.status === 200) {
        handleClose();
        setOpenConfirmationDialog(false);
        dispatch(
          toastActions.setToaster({
            type: "success",
            message: "Tag Deleted Successfully",
          })
        );
        setIsDeleteTagLoading(false);
        const body = {
          businessId: userData?.companyId,
          userId: userData?.userId,
          search: "",
        };
        await dispatch(getContactTags(body));
      }
    } else {
      setDeleteTagsTooltip(deleteOptionId);
      setTimeout(() => {
        setDeleteTagsTooltip(null);
      }, 2000);
      setIsDeleteTagLoading(false);
    }
  };

  const maxLength = 16;
  const truncateText = (text: any, maxLength: any) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };
  return (
    <>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "70vh",
            width: "300px", // Adjust this width as needed
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              overflowY: "auto",
              padding: "6px",
              // Scrollbar styling
              "&::-webkit-scrollbar": {
                cursor: "pointer",
                width: "6px",
              },
              "&::-webkit-scrollbar-thumb": {
                cursor: "pointer",
                backgroundColor: "#cdcdcd",
                borderRadius: "8px",
                transition: "background-color 0.2s ease-in-out",
              },
            }}
          >
            <Typography
              m={2}
              variant="h6"
              sx={{
                fontSize: "16px",
                display: "flex",
                alignItems: "center",
                gap: "10px",
                fontWeight: "bold",
              }}
            >
              <span style={{ marginTop: "5px" }}>
                <IoMdPricetag />
              </span>
              Tags
              <Tooltip
                title="Select any of the below tags to filter contacts."
                placement="right"
              >
                <span style={{ marginTop: "8px" }}>
                  <ChatInformationSvg />
                </span>
              </Tooltip>
            </Typography>
            <Box
              m={{
                xs: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TextField
                className={classes.searchField}
                variant="standard"
                size="small"
                onChange={handleSearchChange}
                InputProps={{
                  disableUnderline: true,
                  style: {
                    padding: "6px",
                    fontWeight: "600 !important",
                    height: "38px",
                  },
                  startAdornment: (
                    <IconButton
                      sx={{ p: 0, color: "inherit" }}
                      aria-label="search"
                    >
                      <SearchIconSvg2 />
                    </IconButton>
                  ),
                }}
                inputProps={{
                  style: {
                    fontWeight: "600 !important",
                    padding: "0px",
                  },
                }}
                placeholder="Search tags."
              />
            </Box>
            <Box m={{ xs: 1 }}>
              <div>
                {filteredOptions?.map((option: { id: number; tag: string }) => (
                  <ListItem
                    key={option.id}
                    className={classes.listItem}
                    sx={{ p: 0 }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                        marginBottom: "-5px",
                      }}
                    >
                      <>
                        <Checkbox
                          checked={checkedIds.includes(option.id)}
                          onChange={() => handleCheckboxChange(option.id)}
                          sx={{
                            "& .MuiSvgIcon-root": { fontSize: 17 },
                            color: "gray",
                            "&.Mui-checked": {
                              color: "gray",
                            },
                          }}
                        />
                        <ListItemText
                          primary={
                            <Box
                              sx={{
                                fontSize: "14px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Tooltip title={option?.tag} placement="top">
                                <span
                                  style={{
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    maxWidth: "150px",
                                    verticalAlign: "middle",
                                    fontSize: "14px",
                                  }}
                                >
                                  {truncateText(option?.tag, maxLength)}
                                </span>
                              </Tooltip>
                            </Box>
                          }
                        />
                      </>
                      <Tooltip
                        title="Access Denied"
                        open={deleteTagsTooltip === option?.id}
                        placement="top"
                        onClose={() => setDeleteTagsTooltip(null)}
                        sx={{ marginLeft: "14px" }}
                      >
                        <div
                          className={classes.deleteIcon}
                          onClick={() => handleDialogOpen(option.id)}
                        >
                          {/* <DeleteSvg /> */}
                        </div>
                      </Tooltip>
                    </Box>
                  </ListItem>
                ))}
              </div>
            </Box>
          </Box>
          <Box
            sx={{
              position: "sticky",
              bottom: 0,
              backgroundColor: "white",
              padding: "10px 0",
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              // borderTop: '1px solid #e0e0e0',
            }}
          >
            <button
              className={classes.resetBtnStyles}
              onClick={handleResetFilter}
              style={{ cursor: "pointer" }}
            >
              Reset
            </button>
            <button
              className={classes.SaveChangesButton}
              onClick={handleContactsTagApply}
              style={{ cursor: "pointer" }}
            >
              Apply filters
            </button>
          </Box>
        </Box>
      </Popover>
      <Dialog
        open={openConfirmationDialog}
        // onClick={handleDialogClose}
        PaperProps={{
          style: { minWidth: "18%", borderRadius: "20px", padding: "0px 40px" },
        }}
      >
        <Box
          mt={3}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <RedDeleteIconSvg />
        </Box>
        <DialogTitle>
          <Box>
            <Typography
              textAlign="center"
              variant="h6"
              style={{
                color: `${bgColors.black1} !important`,
                fontWeight: "600 !important",
              }}
            >
              Are you sure
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box>
            <Box textAlign="center">
              <Typography
                variant="body2"
                style={{
                  color: `${bgColors.black1} !important`,
                  opacity: "60% !important",
                  fontWeight: "600 !important",
                }}
              >
                Do you really want to delete the tag ?
              </Typography>
            </Box>
          </Box>
        </DialogContent>

        <Box
          mb={3}
          display="flex"
          flexDirection="row"
          gap={2}
          justifyContent={"center"}
          //  width="100%"
        >
          <button
            style={{
              backgroundColor: "#ffffff",
              color: "#000000",
              height: "40px",
              fontSize: "14px",
              borderRadius: "8px",
              cursor: "pointer",
              border: `1px solid ${bgColors.gray3}`,
              width: "180%",
              padding: "8px",
              fontWeight: "Bold",
            }}
            onClick={handleDialogClose}
          >
            Cancel
          </button>
          {isDeleteTagLoading ? (
            <LoadingComponent height="auto" color={bgColors?.blue} />
          ) : (
            <button
              style={{
                backgroundColor: "#3C3C3C",
                color: "#ffffff",
                height: "40px",
                borderRadius: "8px",
                cursor: "pointer",
                width: "190%",
                padding: "8px",
                fontWeight: "Bold",
                fontSize: "14px",
              }}
              onClick={() => handleDeleteTag()}
            >
              Delete
            </button>
          )}
        </Box>
      </Dialog>
    </>
  );
};

export default ContactsFilterPopover;
