import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  TextField,
  Typography,
  Button,
  Tooltip,
} from "@mui/material";
import CloseSvg from "../../assets/svgs/CloseSvg";
import { makeStyles } from "@mui/styles";
import PhoneInput from "../common/PhoneInput";
import NewTagMember from "./NewTagMember";
import { createContact } from "../../redux/slices/Contacts/CreateContactSlice";
import { useAppDispatch, useAppSelector } from "../../utils/redux-hooks";
import { fetchAllContacts } from "../../redux/slices/Contacts/AllContactsSlice";
import { getContactTags } from "../../redux/slices/Contacts/getContactTags";
import { toastActions } from "../../utils/toastSlice";
import TextFieldWithBorderComponent from "../common/TextFieldWithBorderComponent";
import { bgColors } from "../../utils/bgColors";
import LoadingComponent from "../common/LoadingComponent";
import ContactsIconSvg from "../../assets/svgs/ContactsIconSvg";
import { validatePhoneNumber } from "../../utils/functions";

interface ContactForm {
  name: string;
  countryCode: string;
  contact: string;
  countryName: string;
  email: string;
  tags: string[];
}

const useStyles = makeStyles({
  changePasswordContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    cursor: "pointer",
    fontWeight: "Semi Bold !important",
  },
  blackColor: {
    color: "#303030 !important",
    fontWeight: "500 !important",
  },
  updateButtonStyles: {
    backgroundColor: "#3C3C3C",
    color: "#ffffff",
    cursor: "pointer",
    height: "36px",
    borderRadius: "8px",
    width: "100%",
    fontSize: "14px ",
    fontWeight: "600",
  },
  icon: {
    cursor: "pointer",
    textAlign: "right",
  },
  signOutButtonStyles: {
    backgroundColor: "#3C3C3C",
    color: "#ffffff",
    height: "40px",
    width: "80px",
    borderRadius: "8px",
    cursor: "pointer",
    // width: "190%",
    padding: "8px",
    fontWeight: "Bold",
    fontSize: "14px",
  },
  autocompleteBox: {
    "& .MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-fullWidth MuiInputBase-formControl MuiInputBase-sizeSmall MuiInputBase-adornedEnd MuiAutocomplete-inputRoot css-dj7bjd-MuiInputBase-root-MuiOutlinedInput-root":
      {
        borderRadius: "10px !important",
      },
    borderTopLeftRadius: 0,
    fontSize: "14px",
  },
});

const NewContactPopOver = ({ open, handleClose }: any) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const userProfileSlice = useAppSelector((state: any) => state?.adminLogin);
  const countryCodeArray = useAppSelector(
    (state: any) => state.getAllCoutryCodes?.data
  );
  const userData = userProfileSlice?.data;
  const getContactTag = useAppSelector(
    (state: any) => state?.getContactTagsData?.data
  );
  const getAllCountries = useAppSelector(
    (state: any) => state?.getAllCountries?.data
  );
  const getuserPermissionData = useAppSelector(
    (state: any) => state.getUserPermissions?.data
  );
  const manageContactsObject = getuserPermissionData?.contacts;

  const [createTagsTooltip, setCreateTagsTooltip] = useState(false);
  const [formData, setFormData] = useState<ContactForm>({
    name: "",
    countryCode: "+91",
    contact: "",
    countryName: "",
    email: "",
    tags: [],
  });
  const [isAddContactLoading, setIsAddContactLoading] = useState(false);
  const [newTagDialog, setNewTagDialog] = useState(false);
  const [newTagAdded, setNewTagAdded] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [searchValue, setSearchValue] = useState("");
  const [formErrors, setFormErrors] = useState({
    name: "",
    countryCode: "",
    contact: "",
    countryName: "",
    email: "",
  });

  const hasCreateTagPermission = (permission: any) => {
    for (const profileItem of permission) {
      if (Object.prototype.hasOwnProperty.call(profileItem, "createTags")) {
        return true;
      }
    }
  };

  const isFormValid = () => {
    const errors = { ...formErrors };
    let isValid = true;
    if (!formData?.name || formData.name.trim().length === 0) {
      errors.name = "Contact name is required";
      isValid = false;
    }
    const isValidPhone = /^\d+$/.test(formData?.contact);
    if (!formData?.contact && !formData?.countryCode) {
      errors.contact = "Phone Number & Country Code are required.";
      isValid = false;
    } else if (!formData?.contact && formData?.countryCode) {
      errors.contact = "Phone number is required.";
      isValid = false;
    } else if (!formData?.countryCode && formData?.contact) {
      errors.contact = "Country code is required.";
      isValid = false;
    } else if (!validatePhoneNumber(formData?.contact, formData?.countryCode)) {
      errors.contact =
        "This phone number format is not recognized. Please check the country code and phone number.";
      isValid = false;
    } else if (!isValidPhone) {
      errors.contact = "Phone number should only contain numbers.";
      isValid = false;
    } else if (formData?.contact && formData?.countryCode) {
      isValid = true;
    }

    if (
      formData?.email &&
      !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(formData.email)
    ) {
      errors.email = "Invalid email address.";
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  };

  const openNewTagPopover = () => {
    const hasPermission = hasCreateTagPermission(manageContactsObject);
    if (hasPermission) {
      setNewTagDialog(true);
    } else {
      setCreateTagsTooltip(true);
      setTimeout(() => {
        setCreateTagsTooltip(false);
      }, 2000);
    }
  };

  const handleCloseTagPopover = () => {
    setNewTagDialog(false);
  };

  const handleAddedNewTag = async (status: boolean, newTag: string) => {
    setNewTagAdded(status);
    const body = {
      businessId: userData?.companyId,
      userId: userData?.userId,
      search: "",
    };
    const tagsListData: any = await dispatch(getContactTags(body));
    const newTagData = tagsListData?.payload?.filter(
      (item: any) => item?.tag === newTag
    );
    if (status && newTag) {
      setFormData((prevData: any) => ({
        ...prevData,
        tags: [...prevData.tags, newTagData[0]?.id],
      }));
    }
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const codeArray =
    countryCodeArray &&
    countryCodeArray?.map((item: any) => ({
      countryCode: item?.countryCode,
      countryImage: item?.countryImage,
    }));

  const getAllSortedCountries =
    getAllCountries &&
    [...getAllCountries]?.sort((a, b) =>
      a.countryName.localeCompare(b.countryName)
    );

  const handleCloseAll = () => {
    setFormErrors({
      name: "",
      countryCode: "",
      contact: "",
      countryName: "",
      email: "",
    });
    setFormData({
      name: "",
      countryCode: "+91",
      contact: "",
      countryName: "",
      email: "",
      tags: [],
    });
    handleClose();
    setNewTagAdded(false);
  };

  const handlePhoneChange = (value: string) => {
    // Validate phone number format
    // const phoneRegex = /^(\+\d{1,3})?\s?\d{10}$/; // Updated regex to handle optional country code
    validatePhoneNumber(formData?.contact, formData?.countryCode);
    if (!validatePhoneNumber) {
      setErrors({
        ...errors,
        contact:
          "This phone number format is not recognized. Please check the country code and phone number format",
      });
      // console.log(errors.contact, "current error message");
    } else {
      setErrors({ ...errors, contact: "" });
    }
    // console.log("Phone number", validatePhoneNumber);

    setFormData({
      ...formData,
      contact: value,
    });
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      contact: "",
    }));
  };

  const handleCountryCodeChange = (value: string) => {
    // console.log("value", value);
    setFormData({ ...formData, countryCode: value });
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      contact: "",
    }));
  };

  const handleSave = async () => {
    if (isFormValid()) {
      setIsAddContactLoading(true);
      if (formData?.name.trim().length > 0 && formData?.name !== "") {
        const bodyData = {
          businessId: userData?.companyId,
          userId: userData?.userId,
          data: formData,
        };
        const bodyData1 = {
          businessId: userData?.companyId,
          userId: userData?.userId,
          page: 1,
          per_page: 40,
          filters: {},
        };
        try {
          // const res = await CONTACTS_APIS.createContact(bodyData)
          // console.log(res,'res')
          const response: any = await dispatch(createContact(bodyData));
          // console.log("response======", response);
          if (response.meta.requestStatus === "fulfilled") {
            dispatch(
              toastActions.setToaster({
                type: "success",
                message: `${response?.payload?.message}`,
              })
            );
            handleClose();
            setFormData({
              name: "",
              countryCode: "+91",
              contact: "",
              countryName: "",
              email: "",
              tags: [],
            });
          } else {
            dispatch(
              toastActions.setToaster({
                type: "error",
                message: `${response?.payload}`,
              })
            );
          }
          await dispatch(fetchAllContacts(bodyData1));
        } catch (error: any) {
          // console.log("error", error);
          dispatch(
            toastActions.setToaster({
              type: "error",
              message: `${error?.message}`,
            })
          );
        }
      }
      setIsAddContactLoading(false);
    }
  };

  useEffect(() => {
    const body = {
      businessId: userData?.companyId,
      userId: userData?.userId,
      search: searchValue,
    };
    dispatch(getContactTags(body));
  }, [searchValue]);

  const handleTagChange = (e: any, newValue: any) => {
    if (newValue) {
      const tagIds = newValue.map((tag: any) => tag.id);
      setFormData((prevData) => ({
        ...prevData,
        tags: tagIds,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        tags: [],
      }));
    }
  };

  const selectedCountry = formData?.countryName
    ? getAllSortedCountries?.find(
        (country: { countryName: any }) =>
          country.countryName === formData.countryName
      )
    : null;

  const handleSelectChange = (event: any, newValue: { countryName: any }) => {
    const customEvent = {
      target: {
        name: "countryName",
        value: newValue ? newValue.countryName : "",
      },
    };
    handleChange(customEvent);
  };

  return (
    <Dialog
      open={open}
      onClose={handleCloseAll}
      PaperProps={{
        style: { minWidth: "35%", maxWidth: "30%", borderRadius: "20px" },
      }}
    >
      <DialogTitle>
        <Box m={1} className={classes.changePasswordContainer}>
          <Typography
            // variant="h6"
            sx={{
              color: "#000000",
              fontWeight: "600",
              fontSize: { xs: "14px", md: "20px" },
              display: "flex",
              alignItems: "center",
              gap: "15px",
            }}
          >
            <ContactsIconSvg />
            Add Contact
          </Typography>
          <Box sx={{ cursor: "pointer" }} onClick={handleCloseAll}>
            <CloseSvg />
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box m={1} mt={0}>
          <Box mb={2}>
            <InputLabel
              className={classes.blackColor}
              sx={{ fontSize: { xs: 12, md: 14 } }}
            >
              Name*
            </InputLabel>
            <TextFieldWithBorderComponent
              // variant="outlined"
              // required
              size="small"
              fullWidth
              name="name"
              placeholder=""
              label="Enter contact name"
              value={formData?.name}
              onChange={handleChange}
              sx={{
                "& input": {
                  // fontWeight: "600",
                },
              }}
              error={!!formErrors?.name}
              helperText={formErrors?.name}
              // placeholder="Enter template name"
            />
            {/* {formErrors?.name && (
              <Typography variant="body2" color="error">
                {formErrors?.name}
              </Typography>
            )} */}
          </Box>
          <Box mb={2}>
            <InputLabel
              className={classes.blackColor}
              sx={{ fontSize: { xs: 12, md: 14 } }}
            >
              Contact*
            </InputLabel>
            <Grid item xs={12}>
              <PhoneInput
                width="130px"
                gapRequired="48px"
                phoneNumWidth="270px"
                countryCodeArray={codeArray}
                name="contact"
                onChange={handlePhoneChange}
                onCountryCodeChange={handleCountryCodeChange}
                propsValue={formData?.contact || ""}
                countryCode={formData?.countryCode || ""}
                error={!!formErrors?.contact}
                // helperText={formErrors?.contact}
              />
              {formErrors?.contact && (
                <Typography
                  variant="caption"
                  color="error"
                  sx={{ fontSize: 12 }}
                >
                  {formErrors?.contact}
                </Typography>
              )}
            </Grid>
          </Box>
          <Box mb={2}>
            <InputLabel
              className={classes.blackColor}
              sx={{ fontSize: { xs: 12, md: 14 } }}
            >
              Email
            </InputLabel>
            <TextFieldWithBorderComponent
              variant="outlined"
              size="small"
              placeholder=""
              fullWidth
              label="Enter email address"
              name="email"
              value={formData?.email}
              onChange={handleChange}
              sx={{
                "& input": {
                  // fontWeight: "600",
                },
              }}
              error={!!formErrors?.email}
              helperText={formErrors?.email}
            />
          </Box>
          <Box mb={2}>
            <InputLabel
              className={classes.blackColor}
              sx={{ fontSize: { xs: 12, md: 14 }, mb: 1 }}
            >
              Country
            </InputLabel>
            <FormControl fullWidth size="small">
              <Autocomplete
                id="country-autocomplete"
                options={getAllSortedCountries || []}
                getOptionLabel={(option) => option.countryName}
                value={selectedCountry}
                onChange={handleSelectChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select"
                    sx={{
                      "& .MuiInputLabel-root": {
                        fontSize: { xs: 12, md: 14 },
                        top: "-5px",
                      },
                      "& .MuiInputLabel-root.Mui-focused": {
                        top: "0px",
                      },
                      "& .MuiFormLabel-filled": {
                        top: "0px",
                      },
                      "& .MuiOutlinedInput-root": {
                        height: "38px",
                        borderRadius: "8px",
                        fontSize: "14px",
                        "& .MuiSelect-select": {
                          // fontWeight: "600",
                        },
                        "& input": {
                          // fontWeight: "600",
                        },
                      },
                    }}
                    InputProps={{
                      ...params.InputProps,
                      style: {
                        fontSize: 14,
                        paddingTop: "0px",
                        paddingBottom: "0px",
                      },
                    }}
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props}>
                    <Typography
                      sx={{
                        fontSize: 14,
                        fontWeight:
                          option.countryName === formData?.countryName
                            ? "normal"
                            : "normal",
                      }}
                    >
                      {option.countryName}
                    </Typography>
                  </li>
                )}
              />
              {formErrors?.countryName && (
                <Typography variant="body2" color="error">
                  {formErrors?.countryName}
                </Typography>
              )}
            </FormControl>
          </Box>
          <Box mb={2}>
            <InputLabel
              className={classes.blackColor}
              sx={{ fontSize: { xs: 12, md: 14 }, mb: 1 }}
            >
              Tags
            </InputLabel>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: "15px",
              }}
            >
              <FormControl fullWidth size="small">
                <Autocomplete
                  id="Select Tags"
                  size="small"
                  multiple
                  disableCloseOnSelect={true}
                  options={
                    getContactTag
                      ? getContactTag.filter((each: any) => each.isActive)
                      : []
                  }
                  getOptionLabel={(option: any) => option?.tag}
                  autoHighlight
                  value={
                    newTagAdded
                      ? [
                          ...getContactTag?.filter((tag: any) =>
                            formData?.tags?.includes(tag?.id)
                          ),
                        ]
                      : formData?.tags?.map((id: any) =>
                          getContactTag?.find((tag: any) => tag?.id === id)
                        )
                  }
                  onChange={handleTagChange}
                  onInputChange={(event, newValue) => {
                    setSearchValue(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select tags"
                      variant="outlined"
                      size="small"
                      sx={{
                        fontSize: 14,
                        borderColor: "gray",
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "8px",
                        },
                        "& .MuiFormLabel-root": {
                          backgroundColor: "#fff",
                          padding: "0px 4px 0px 0px",
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          fontSize: "14px",
                        },
                      }}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {params.InputProps.endAdornment}
                            <Tooltip
                              open={createTagsTooltip}
                              title="Access Denied"
                              placement="top"
                              onClose={() => setCreateTagsTooltip(false)}
                            >
                              <Button
                                onClick={openNewTagPopover}
                                color="success"
                                sx={{ textTransform: "none", height: "20px" }}
                              >
                                + Add new tag
                              </Button>
                            </Tooltip>
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>
              <NewTagMember
                open={newTagDialog}
                handleClose={handleCloseTagPopover}
                addedNewTag={handleAddedNewTag}
              />
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box width="100%" mx={3} mb={2}>
          {isAddContactLoading ? (
            <LoadingComponent height="auto" color={bgColors?.blue} />
          ) : (
            <button onClick={handleSave} className={classes.updateButtonStyles}>
              Add Contact
            </button>
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default NewContactPopOver;
