import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { OPTED_MANAGEMENT_APIS } from "../../../Apis/AdminLogin/Automation/OptinManagement";

export interface IData {
  status: "loading" | "succeeded" | "failed" | "idle";
  data: any;
}

const initialState: IData = {
  status: "idle",
  data: null,
};

export const optedKeywordsData = createAsyncThunk(
  "optedKeywords",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await OPTED_MANAGEMENT_APIS.optedKeywords(data);
      return response?.data;
    } catch (error: any) {
      return rejectWithValue(error.response || "An error occurred");
    }
  }
);

export const optedManagementDataSlice = createSlice({
  name: "optedManagement",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(optedKeywordsData?.pending, (state) => {
        state.status = "loading";
        state.data = [];
      })
      .addCase(optedKeywordsData?.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(optedKeywordsData?.rejected, (state) => {
        state.status = "failed";
        state.data = [];
      });
  },
});

export const optedManagementDataActions = optedManagementDataSlice?.actions;
export default optedManagementDataSlice.reducer;
