import {
  Box,
  Grid,
  IconButton,
  // LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { bgColors } from "../../../utils/bgColors";
import FileIconSvg from "../../../assets/svgs/FileIconSvg";
import { useNavigate } from "react-router-dom";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { useAppDispatch, useAppSelector } from "../../../utils/redux-hooks";
import { toastActions } from "../../../utils/toastSlice";
import {
  fetchSubscriptionDeductions,
  subscriptionInvoice,
} from "../../../redux/slices/Subscription/SubscriptionSlice";
import LoadingComponent from "../../../components/common/LoadingComponent";
import { format, parseISO } from "date-fns";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import { hasManageSubscriptionPermission } from "../../../utils/permissions";
import NoAccessPage from "../../../components/common/NoAccess";

const useStyles = makeStyles({
  mainContainer: {
    backgroundColor: bgColors.white1,
    padding: "14px",
    height: "100%",
    width: "100%",
  },
  bgContainer: {
    backgroundColor: bgColors.white,
    borderRadius: "25px",
    height: "100%",
    // width: "100%",
    flex: 1,
    paddingBottom: "24px",
    overflow: "auto",
  },
  manageContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  planButton: {
    backgroundColor: bgColors.green1,
    color: bgColors.green,
    fontWeight: "700",
    paddingTop: 6,
    paddingRight: 22,
    paddingBottom: 6,
    paddingLeft: 22,
    borderRadius: 25,
  },
  planExpiredButton: {
    backgroundColor: bgColors.red,
    color: bgColors.white,
    borderRadius: "10px",
  },
  blackColor: {
    color: `${bgColors.black1} !important`,
    // fontWeight: "600 !important",
  },
  grayColor: {
    color: `${bgColors.black1} !important`,
    fontWeight: "600 !important",
    opacity: "60%",
  },
  updateButtonStyles: {
    backgroundColor: bgColors.blue,
    color: "#ffffff",
    height: "40px",
    borderRadius: "14px",
    fontWeight: "600 !important",
    // fontSize: "16px",
    cursor: "pointer",
    // width: "100%",
    paddingLeft: 40,
    paddingRight: 40,
    // paddingTop: 10,
    // paddingBottom: 10,
  },
  makePayment: {
    color: "#3C3C3C !important",
    fontWeight: "600 !important",
    border: `2px solid ${bgColors.black1}`,
    paddingLeft: 40,
    paddingRight: 40,
    paddingTop: 10,
    paddingBottom: 10,
    borderRadius: "14px",
    background: "#3C3C3C1A",
    cursor: "pointer",
  },
  fileContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    // borderBottom: "1px solid #838383",
    borderBottom: "1px solid #f0f0f0",
  },
  fileNameContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  popoverContent: {
    padding: "18px",
    display: "flex",
    flexDirection: "column",
  },
  subscriptionContainer: {
    border: `2px solid ${bgColors.gray5}`,
    borderRadius: "18px",
    // height: "200px",
  },
  justifySpaceBetween: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
  },
  RsStyles: {
    fontSize: "16px",
    fontWeight: "500",
    fontFamily: "inter",
    marginTop: "33px",
    marginRight: "5px",
    color: "gray",
  },
  planAmout: {
    fontSize: "44px",
    margin: 0,
  },
  alignCenter: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "flex-start",
  },
  paid: {
    color: "green",
  },
  pending: {
    color: "red",
  },
  table: {
    borderCollapse: "separate",
    borderSpacing: "0 8px",
    borderColor: "lightgray",
    "& th, & td": {
      // border: "none",
      padding: "3px",
      borderBottom: "1px solid #f0f0f0",
    },
    "& th:last-child, & td:last-child": {
      // paddingRight: "20px",
    },
  },
  teamProfileContainer: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
});

const capitalizeFirstLetter = (string: any) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const Subscription = () => {
  const classes = useStyles();
  const history = useNavigate();
  const dispatch = useAppDispatch();

  const loginData = useAppSelector((state: any) => state.adminLogin.data);
  const isPlatform = loginData?.isPlatform;
  const getuserPermissionData = useAppSelector(
    (state:any) => state.getUserPermissions?.data
  );
  const hasSubscriptionPermission = hasManageSubscriptionPermission(
    getuserPermissionData?.profile
  );

  const planStatus = useAppSelector(
    (state: any) => state?.wallet?.walletAndSubscriptionStatus
  );

  const currentPlan = useAppSelector(
    (state:any) => state?.wallet?.walletAndSubscription?.data
  );

  // const totalClients = currentPlan?.subscriptionPlan?.totalClients || 0;
  // const samePlanWithClients =
  //   currentPlan?.subscriptionPlan?.samePlanWithClients || 0;
  // const progressValue = (samePlanWithClients / totalClients) * 100;

  const subscriptionData = useAppSelector(
    (state:any) => state?.Subscription?.subscriptionDeductions?.data
  );

  const subscriptionDataStatus = useAppSelector(
    (state:any) => state?.Subscription?.subscriptionDeductionsStatus
  );

  let [dateColor, setDateColor] = useState("red");
  const [makePaymentTooltip, setMakePaymentTooltip] = useState(false);
  const [upgradeTooltip, setUpgradeTooltip] = useState("");
  const [introTooltip, setIntroTooltip] = useState("");
  const [upgaradePlanPermissionTooltip, setUpgaradePlanPermissionTooltip] =
    useState(false);
  const [
    downloadInvoicePermissionTooltip,
    setDownloadInvoicePermissionTooltip,
  ] = useState("");
  const [makepaymentPermissionTooltip, setMakepaymentPermissionTooltip] =
    useState(false);
  const [invoiceTooltipMessage, setInvoiceTooltipMessage] = useState("");

  const subscriptionPermissionObject = getuserPermissionData?.profile?.find(
    (item: any) => Object.prototype.hasOwnProperty.call(item, "subscription")
  );

  const subscriptionPermissionActions = subscriptionPermissionObject
    ? subscriptionPermissionObject?.subscription
    : [];

  const hasAccess = (permission: any) => {
    if (subscriptionPermissionActions?.includes(permission)) {
      return true;
    }
    return false;
  };

  const handleUpgrade = () => {
    const hasPermission = hasAccess("upgradePlan");
    if (hasPermission) {
      if (isPlatform && currentPlan?.subscriptionPlan?.planName === "Pro") {
        setUpgradeTooltip("Platform users have unlimited access.");
        setTimeout(() => {
          setUpgradeTooltip("");
        }, 2000);
      } else if (
        currentPlan?.subscriptionPlan?.planName === "Pro" &&
        currentPlan?.subscriptionPlan?.planType === "Annually"
      ) {
        setUpgradeTooltip(
          'You are already in highest plan, Please use "Make Payment" for extending plan.'
        );
        setTimeout(() => {
          setUpgradeTooltip("");
        }, 2000);
      } else {
        history("/profile/upgrade-plan");
      }
    } else {
      setUpgaradePlanPermissionTooltip(true);
      setTimeout(() => {
        setUpgaradePlanPermissionTooltip(false);
      }, 2000);
    }
    // }
  };

  const handleMakePayment = () => {
    if (isPlatform && currentPlan?.subscriptionPlan?.planName === "Pro") {
      setIntroTooltip("Platform users have unlimited access.");
      setMakePaymentTooltip(true);
      setTimeout(() => {
        setMakePaymentTooltip(false);
      }, 2000);
    } else {
      const hasPermission = hasAccess("makePayment");
      if (hasPermission) {
        if (!currentPlan?.subscriptionPlan?.isActive) {
          setIntroTooltip("Please activate plan.");
          setMakePaymentTooltip(true);
          setTimeout(() => {
            setMakePaymentTooltip(false);
          }, 2000);
        } else if (
          currentPlan?.subscriptionPlan?.planName === "Intro" &&
          currentPlan?.subscriptionPlan?.isActive
        ) {
          setIntroTooltip("Please upgrade plan.");
          setMakePaymentTooltip(true);
          setTimeout(() => {
            setMakePaymentTooltip(false);
          }, 2000);
        } else {
          history("/profile/payment");
        }
      } else {
        setMakepaymentPermissionTooltip(true);
        setTimeout(() => {
          setMakepaymentPermissionTooltip(false);
        }, 2000);
      }
    }
  };

  const formatDate = (dateString: any) => {
    const date = parseISO(dateString);
    return {
      formattedDate: format(date, "MMMM dd, yyyy"), // February 13, 2024 format
      date: format(date, "MM/yy"),
      time: format(date, "hh : mm a").replace("AM", "am").replace("PM", "pm"),
      FullDate: format(date, "MMM dd, yyyy"),
    };
  };

  // const getLast30DaysDates = () => {
  //   const endDate = new Date(); // Today's date
  //   const startDate = new Date();
  //   startDate.setDate(startDate.getDate() - 30); // Subtract 30 days from today

  //   const formatDate = (date: any) => {
  //     const year = date.getFullYear();
  //     const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
  //     const day = String(date.getDate()).padStart(2, "0");
  //     return `${year}-${month}-${day}`;
  //   };

  //   return {
  //     startDate: formatDate(startDate),
  //     endDate: formatDate(endDate),
  //   };
  // };

  const downloadSubscriptionInvoice = async (id: string, date: any) => {
    // Decode the base64 string
    const response = await dispatch(subscriptionInvoice(id));
    const data = response.payload;
    if (data) {
      const byteCharacters = atob(data?.data?.fileContent);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      // Create a Blob from the byte array
      const blob = new Blob([byteArray], { type: "application/pdf" });
      // Create a URL for the Blob
      const url = URL.createObjectURL(blob);
      // Create a link element to trigger the download
      const link = document.createElement("a");
      link.href = url;
      link.download = `Invoice ${date}`;
      document.body.appendChild(link);
      link.click();
      // Clean up and remove the link

      // link.parentNode.removeChild(link);
      if (link.parentNode) {
        link.parentNode.removeChild(link);
      } else {
        console.warn("Link element has no parent node, unable to remove it.");
      }
      URL.revokeObjectURL(url);

      dispatch(
        toastActions.setToaster({
          type: "success",
          message: "Successfully downloaded",
        })
      );
    } else {
      dispatch(
        toastActions.setToaster({
          type: "error",
          message: "Unable to download",
        })
      );
    }
  };

  const SubscriptionPopoverOpen = (orderAmount: any, event: any, date: any) => {
    const hasPermission = hasAccess("downloadInvoice");
    if (hasPermission) {
      if (event && orderAmount !== 0) {
        downloadSubscriptionInvoice(event, date);
      } else {
        setInvoiceTooltipMessage(`Not allowed for "Intro" plan`);
        setDownloadInvoicePermissionTooltip(event);
      }
    } else {
      setInvoiceTooltipMessage("Access Denied");
      setDownloadInvoicePermissionTooltip(event);
      setTimeout(() => {
        setInvoiceTooltipMessage("");
      }, 2000);
    }
  };

  React.useEffect(() => {
    // const { startDate, endDate } = getLast30DaysDates();
    dispatch(
      fetchSubscriptionDeductions()
      // { startDate: startDate, endDate: endDate }
    );
  }, [dispatch, loginData]);

  const currentDate = new Date();

  useEffect(() => {
    try {
      const planEndDateObj = formatDate(
        currentPlan.subscriptionPlan.planEndDate
      ).formattedDate;

      if (new Date(planEndDateObj) > currentDate) {
        setDateColor("#4caf50");
      } else {
        setDateColor("red");
      }
    } catch (e: any) {
      console.log("Error", e);
    }
  }, [currentPlan]);

  const isButtonStyling =
    !isPlatform &&
    currentPlan?.subscriptionPlan?.planName !== "Intro" &&
    currentPlan?.subscriptionPlan?.isActive === true;
  return (
    <Grid className={classes.mainContainer}>
      {hasSubscriptionPermission ? (
        <Grid className={classes.bgContainer}>
          <Box
            py={{ xs: 1, md: 4 }}
            px={{ xs: 1, md: 4 }}
            className={classes.manageContainer}
          >
            <Typography
              variant="h6"
              className={classes.blackColor}
              sx={{ display: "flex" }}
            >
              <span style={{ marginTop: "3px" }}>
                <SubscriptionsIcon sx={{ height: "20px" }} />
              </span>
              &nbsp;Subscription
            </Typography>
          </Box>
          <Grid container item xs={12} pl={{ xs: 1, sm: 2, md: 3, lg: 4 }}>
            <Grid
              item
              xs={12}
              lg={7}
              sx={{ ml: { xs: 0, sm: 1, md: 2, lg: 3 } }}
            >
              <Box
                ml={1}
                className={classes.subscriptionContainer}
                sx={{
                  height: {
                    // xs: "100px",  // example height for extra-small screens
                    // sm: "150px",  // example height for small screens
                    md: "180px", // example height for medium screens
                    lg: "200px", // example height for large screens
                    xl: "200px", // height for extra-large screens
                    xxl: "250px", // height for custom extra-extra-large screens
                  },
                }}
              >
                {planStatus !== "loading" ? (
                  <>
                    <Box
                      className={classes.justifySpaceBetween}
                      sx={{
                        flexDirection: { xs: "column", sm: "row" },
                        ml: { xs: 1, sm: 2 },
                      }}
                    >
                      <Box ml={{ xs: 1, sm: 3, md: 4 }} mt={3}>
                        {currentPlan?.subscriptionPlan?.planName &&
                        currentPlan?.subscriptionPlan?.isActive ? (
                          <button className={classes.planButton}>
                            {currentPlan?.subscriptionPlan?.planName.toUpperCase()}{" "}
                            PLAN
                          </button>
                        ) : (
                          <Typography>
                            Your {currentPlan?.subscriptionPlan?.planName} plan
                            has been expired, please activate plan.
                          </Typography>
                        )}
                      </Box>
                      {isPlatform &&
                      currentPlan?.subscriptionPlan?.planName === "Pro" ? (
                        // (
                        <Box mt={2} mr={3} className={classes.flexRow}>
                          <Typography>Unlimited Access</Typography>
                        </Box>
                      ) : (
                        // )
                        <Box>
                          {currentPlan?.subscriptionPlan?.isActive && (
                            <>
                              {currentPlan?.subscriptionPlan?.planName ===
                              "Intro" ? (
                                <Box mt={2} mr={3} className={classes.flexRow}>
                                  <Typography>
                                    You are under free trial
                                  </Typography>
                                </Box>
                              ) : (
                                <Box mt={2} mr={3} className={classes.flexRow}>
                                  <span className={classes.RsStyles}>Rs</span>
                                  <h1 className={classes.planAmout}>
                                    {currentPlan?.subscriptionPlan?.currentSubscriptionPlanAmount?.toLocaleString()}
                                  </h1>
                                  <span className={classes.RsStyles}>
                                    {currentPlan?.subscriptionPlan?.planType}
                                  </span>
                                </Box>
                              )}
                            </>
                          )}
                        </Box>
                      )}
                    </Box>
                    <Grid
                      container
                      item
                      xs={12}
                      mt={3.5}
                      justifyContent="space-between"
                      alignItems="center"
                      sx={{
                        flexDirection: { xs: "column", sm: "row" },
                        ml: { xs: 1, sm: 2 },
                      }}
                    >
                      <Grid item xs={6}>
                        {/* <Box ml={{ xs: 1, sm: 2, md: 3, lg: 5 }}>
                          <Typography
                            variant="body1"
                            className={classes.blackColor}
                            mb={1}
                          >
                            {`${samePlanWithClients} of ${totalClients} users`}
                          </Typography>
                          <LinearProgress
                          variant="determinate"
                          value={progressValue}
                          color="success"
                        />
                        </Box> */}

                        {!isPlatform &&
                          currentPlan?.subscriptionPlan?.isActive &&
                          currentPlan?.subscriptionPlan?.planName !==
                            "Intro" && (
                            <Box>
                              <Typography
                                variant="body2"
                                sx={{
                                  color: "#757575",
                                  lineHeight: 0.43,
                                  fontSize: "12px",
                                }}
                              >
                                <Box component="span" sx={{ color: "red" }}>
                                  Note:
                                </Box>
                                The prices shown exclude GST.
                              </Typography>
                            </Box>
                          )}
                      </Grid>
                      <Grid item xs={5}>
                        <Box
                          mx={2}
                          mb={4}
                          sx={{
                            textAlign: "end",
                            mt: { xs: 3 },
                            mr: { xs: 5 },
                          }}
                        >
                          <Box>
                            {upgaradePlanPermissionTooltip ? (
                              <Tooltip
                                title="Access Denied"
                                placement="left"
                                open
                                onClose={() =>
                                  setUpgaradePlanPermissionTooltip(false)
                                }
                              >
                                <button
                                  className={classes.updateButtonStyles}
                                  style={{
                                    marginTop: isButtonStyling ? "0px" : "30px",
                                  }}
                                  onClick={handleUpgrade}
                                >
                                  {currentPlan === null ||
                                  currentPlan?.subscriptionPlan?.isActive ===
                                    false
                                    ? "Activate Plan"
                                    : currentPlan?.subscriptionPlan
                                        ?.planName === "Pro" &&
                                      currentPlan?.subscriptionPlan
                                        ?.planType === "Annually"
                                    ? "Upgrade Plan"
                                    : "Upgrade Plan"}
                                </button>
                              </Tooltip>
                            ) : upgradeTooltip !== "" ? (
                              <Tooltip
                                title={upgradeTooltip}
                                placement="top"
                                open
                              >
                                <button
                                  className={classes.updateButtonStyles}
                                  style={{
                                    marginTop: isButtonStyling ? "0px" : "30px",
                                  }}
                                  onClick={handleUpgrade}
                                >
                                  {currentPlan === null ||
                                  currentPlan?.subscriptionPlan?.isActive ===
                                    false
                                    ? "Activate Plan"
                                    : currentPlan?.subscriptionPlan
                                        ?.planName === "Pro" &&
                                      currentPlan?.subscriptionPlan
                                        ?.planType === "Annually"
                                    ? "Upgrade Plan"
                                    : "Upgrade Plan"}
                                </button>
                              </Tooltip>
                            ) : (
                              <button
                                className={classes.updateButtonStyles}
                                style={{
                                  marginTop: isButtonStyling ? "0px" : "30px",
                                }}
                                onClick={handleUpgrade}
                              >
                                {currentPlan === null ||
                                currentPlan?.subscriptionPlan?.isActive ===
                                  false
                                  ? "Activate Plan"
                                  : currentPlan?.subscriptionPlan?.planName ===
                                      "Pro" &&
                                    currentPlan?.subscriptionPlan?.planType ===
                                      "Annually"
                                  ? "Upgrade Plan"
                                  : "Upgrade Plan"}
                              </button>
                            )}
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <LoadingComponent height="100%" color={bgColors.blue} />
                )}
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              lg={4}
              sx={{ mt: { xs: 4, lg: 0 }, pb: { xs: 0 } }}
            >
              <Box
                ml={1}
                className={classes.subscriptionContainer}
                sx={{
                  height: {
                    // xs: "100px",  // example height for extra-small screens
                    // sm: "150px",  // example height for small screens
                    md: "180px", // example height for medium screens
                    lg: "200px", // example height for large screens
                    xl: "200px", // height for extra-large screens
                    xxl: "250px", // height for custom extra-extra-large screens
                  },
                }}
              >
                {planStatus !== "loading" ? (
                  <Box
                    mt={3}
                    // ml={3}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "10px",
                    }}
                  >
                    <Typography variant="body2" color="#303030" pb={2}>
                      Next payment
                    </Typography>

                    {isPlatform &&
                    currentPlan.subscriptionPlan?.planName === "Pro" ? (
                      <Typography
                        variant="body2"
                        className={classes.blackColor}
                        p={1}
                        sx={{ backgroundColor: "#eee", borderRadius: "10px" }}
                      >
                        Not applicable
                      </Typography>
                    ) : currentPlan?.subscriptionPlan?.isActive ? (
                      <Typography
                        variant="body2"
                        className={classes.blackColor}
                        pb={2}
                      >
                        <span style={{ color: dateColor }}>
                          {
                            formatDate(currentPlan.subscriptionPlan.planEndDate)
                              .formattedDate
                          }
                        </span>
                      </Typography>
                    ) : (
                      <Typography
                        variant="body2"
                        className={classes.planExpiredButton}
                        p={1}
                        // sx={{ backgroundColor: "#eee", borderRadius: "10px" }}
                      >
                        Plan Expired
                      </Typography>
                    )}
                    <Box mt={2}>
                      {makepaymentPermissionTooltip ? (
                        <Tooltip
                          title="Access Denied"
                          placement="left"
                          open
                          onClose={() => setMakepaymentPermissionTooltip(false)}
                        >
                          <button
                            className={classes.makePayment}
                            onClick={handleMakePayment}
                          >
                            Make Payment
                          </button>
                        </Tooltip>
                      ) : (
                        <Tooltip
                          title={introTooltip}
                          placement="top"
                          open={makePaymentTooltip}
                        >
                          <button
                            className={classes.makePayment}
                            onClick={handleMakePayment}
                          >
                            Make Payment
                          </button>
                        </Tooltip>
                      )}
                    </Box>
                  </Box>
                ) : (
                  <LoadingComponent height="100%" color={bgColors.blue} />
                )}
              </Box>
            </Grid>
          </Grid>
          {/* {AccessToInvoiceHistory&&  */}
          <Box m={5}>
            {!(
              isPlatform && currentPlan?.subscriptionPlan?.planName === "Pro"
            ) && (
              <Box>
                <Typography variant="body2" className={classes.grayColor}>
                  Invoices
                  {subscriptionData?.length
                    ? `(${subscriptionData.length})`
                    : ""}
                </Typography>

                <Box>
                  <TableContainer component={Box}>
                    <Table className={classes.table}>
                      <TableHead className={classes.grayColor}>
                        <TableRow>
                          <TableCell>
                            <span style={{ marginLeft: "20px" }}>
                              File Name
                            </span>
                          </TableCell>
                          <TableCell>Date</TableCell>
                          <TableCell>Status</TableCell>
                          <TableCell>Actions</TableCell>
                        </TableRow>
                      </TableHead>

                      {subscriptionDataStatus !== "loading" ? (
                        <TableBody>
                          {subscriptionData && subscriptionData?.length > 0 ? (
                            subscriptionData?.map((row: any) => {
                              const formattedDate = formatDate(row.updatedAt);
                              return (
                                <TableRow key={row.id}>
                                  <TableCell>
                                    <Box className={classes.fileNameContainer}>
                                      <FileIconSvg />
                                      <Typography
                                        variant="body2"
                                        ml={2}
                                        className={classes.blackColor}
                                      >
                                        {/* {invoice.fileName} */}
                                        Invoice_{formattedDate.date}.pdf
                                      </Typography>
                                    </Box>
                                  </TableCell>
                                  <TableCell className={classes.blackColor}>
                                    {/* {invoice.date} */}
                                    {formattedDate.FullDate}
                                  </TableCell>
                                  <TableCell>
                                    <Typography
                                      variant="body2"
                                      className={
                                        row.status === "Paid"
                                          ? classes.paid
                                          : classes.pending
                                      }
                                    >
                                      {capitalizeFirstLetter(row.status)}
                                    </Typography>
                                  </TableCell>

                                  <TableCell>
                                    <>
                                      {" "}
                                      <Tooltip
                                        title={
                                          invoiceTooltipMessage !== "" &&
                                          downloadInvoicePermissionTooltip ===
                                            row?.id
                                            ? invoiceTooltipMessage
                                            : "Download"
                                        }
                                        placement="top"
                                      >
                                        <Box
                                          onClick={() =>
                                            SubscriptionPopoverOpen(
                                              row.orderAmount,
                                              row.id,
                                              formattedDate.date
                                            )
                                          }
                                        >
                                          <IconButton>
                                            <FileDownloadOutlinedIcon />
                                          </IconButton>
                                        </Box>
                                      </Tooltip>
                                    </>
                                  </TableCell>
                                </TableRow>
                              );
                            })
                          ) : (
                            <TableRow>
                              <TableCell colSpan={7} align="center">
                                No Subscriptions
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      ) : (
                        <TableBody
                          sx={{
                            position: "relative",
                            margin: "20px",
                            height: "500px",
                          }}
                        >
                          <TableRow
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              width: "100%",
                              position: "absolute",
                              left: "50%",
                              transform: "translateX(-50%)",
                              paddingLeft: "15px",
                              top: "20px",
                            }}
                          >
                            <LoadingComponent
                              height="auto"
                              color={bgColors.blue}
                            />
                          </TableRow>
                        </TableBody>
                      )}
                    </Table>
                  </TableContainer>
                </Box>
              </Box>
            )}
          </Box>
        </Grid>
      ) : (
        <NoAccessPage />
      )}
    </Grid>
  );
};

export default Subscription;
