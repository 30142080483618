/* global process */

import axios from "axios";

const CAMPAIGN_API_URL = process.env.REACT_APP_BASE_URL;

const createCampaign = (data: any) => {
  return axios({
    url: `${CAMPAIGN_API_URL}/Campaign/CreateCampaign`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
    data: JSON.stringify(data),
  });
};

const getCampaign = (data: any) => {
  return axios({
    url: `${CAMPAIGN_API_URL}/Campaign/CampaignMessage_Counts?BusinessId=${data?.businessId}&UserId=${data?.userId}`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
  });
};

const getCampaignByDetails = (data: any) => {
  return axios({
    url: `${CAMPAIGN_API_URL}/Campaign/GetById?BusinessId=${data?.businessId}&UserId=${data?.userId}&CampaignId=${data?.campaignId}`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
  });
};

const getScheduledCampaigns = (data: any) => {
  return axios({
    url: `${CAMPAIGN_API_URL}/Campaign/AllScheduleCampaignDetails?BusinessId=${data?.businessId}&UserId=${data?.userId}&page=${data?.page}&perPage=${data?.perPage}`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
    data: data?.filters,
  });
};

const uploadCampaignMedia = (data: any) => {
  const formData = new FormData();
  formData.append("File", data?.mediaFile);
  return axios({
    url: `${CAMPAIGN_API_URL}/Template/uploadFile`,
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: formData,
  });
};

const updateCampaign = (campaignId: any, data1: any) => {
  return axios({
    // url: `${CAMPAIGN_API_URL}/Campaign/Edit/${campaignId}`,
    url: `${CAMPAIGN_API_URL}/Campaign/EditCampaign`,
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
    data: JSON.stringify(data1),
  });
};

export const filtersByIdCampaigns = (data: any) => {
  return axios({
    url: `${CAMPAIGN_API_URL}/Campaign/CampaignDetail_filters?BusinessId=${data?.businessId}&UserId=${data?.userId}&page=${data?.page}&perPage=${data?.perPage}`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
    data: data?.filters,
  });
};

export const deleteScheduleCampaign = (data: any) => {
  // console.log("data", data?.campaignId);
  return axios({
    url: `${CAMPAIGN_API_URL}/Campaign/DeleteScheduledCampaign?businessId=${data?.businessId}&userId=${data?.userId}&campaignId=${data?.campaignId}`,
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
  });
};

const checkCampaignTitle = (data: any) => {
  return axios({
    url: `${CAMPAIGN_API_URL}/Campaign/check-campaign-title/${data?.businessId}/${data?.campaignTitle}`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
  });
};

export const CAMPAIGN_API = {
  createCampaign,
  getCampaign,
  getCampaignByDetails,
  updateCampaign,
  uploadCampaignMedia,
  filtersByIdCampaigns,
  getScheduledCampaigns,
  deleteScheduleCampaign,
  checkCampaignTitle,
};
