import React, { useRef } from "react";
import Typography from "@mui/material/Typography";
import { Box, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { bgColors } from "../../utils/bgColors";
import CloudSvg from "../../assets/svgs/CloudSvg";
import { useAppDispatch } from "../../utils/redux-hooks";
import { uploadFile } from "../../redux/slices/Templates/uploadFileSlice";
import { toastActions } from "../../utils/toastSlice";

const useStyles = makeStyles({
  dragAndDrop: {
    backgroundColor: bgColors.gray9,
    height: 90,
    width: "100%",
    border: "2px dashed #ccc",
    borderRadius: "20px",
    padding: "10px",
    // justifyContent:'center',
    // alignItems:'center'
    // textAlign: "center",
  },
  link: {
    textDecoration: "none",
    color: bgColors.blue,
  },
  inputDisplay: {
    display: "none",
  },
});

interface FileUploadProps {
  fileType: number;
  selectedFiles: any;
  handleMediaChange: (file: string, event: File | null) => void;
  isFileValid: any;
}

const FileUpload: React.FC<FileUploadProps> = ({
  fileType,
  selectedFiles,
  handleMediaChange,
  isFileValid,
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  // const handleFileChange = async (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   if (event.target.files) {
  //     // const files = Array.from(event.target.files);
  //     console.log(event.target.files[0])
  //     const formData = new FormData();
  //     formData.append("File", event.target.files[0]);
  //     const uploadResponse: any = await dispatch(uploadFile(formData));
  //     handleMediaChange(uploadResponse, event.target.files[0]);
  //   }
  // };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files) {
      const file = event.target.files[0];
      // console.log(file)
      const maxSize = getMaxFileSize(file.type);

      if (file.size > maxSize) {
        dispatch(
          toastActions.setToaster({
            type: "error",
            message: `The file size exceeds the limit. The maximum allowed size for ${
              file.type
            } is ${maxSize / (1024 * 1024)} MB.`,
          })
        );
        return;
      }

      const formData = new FormData();
      formData.append("File", file);
      const uploadResponse: any = await dispatch(uploadFile(formData));
      // console.log(uploadResponse?.payload)
      handleMediaChange(uploadResponse, file);
    }
  };

  const getMaxFileSize = (fileType: string): number => {
    switch (fileType) {
      case "image/png":
      case "image/jpg":
      case "image/jpeg":
        return 5 * 1024 * 1024; // 5 MB
      case "video/mp4":
      case "video/avi":
      case "video/mov":
      case "video/mpeg":
        return 16 * 1024 * 1024; // 16 MB
      case "application/pdf":
      case "application/msword":
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      case "application/vnd.ms-excel":
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        return 100 * 1024 * 1024; // 100 MB
      default:
        return 0;
    }
  };

  const getAcceptAttribute = () => {
    if (isFileValid && fileType === 5) {
      return ".pdf";
    }

    switch (fileType) {
      case 3:
        return ".png, .jpg, .jpeg";
      case 4:
        return "video/*";
      case 5:
        return ".pdf, .doc, .docx, .xls, .xlsx"; // Add other document types as needed
      default:
        return "";
    }
  };

  // console.log("selected file", selectedFiles);

  return (
    <div style={{ cursor: "pointer" }}>
      <Grid item xs={12} className={classes.dragAndDrop}>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          onClick={() => fileInputRef.current?.click()}
        >
          <Box style={{ display: "flex", flexDirection: "row" }}>
            <Box>
              <CloudSvg />
            </Box>
            <Box>
              <Typography
                mt={2}
                ml={2}
                variant="body2"
                component="p"
                paragraph
                color={bgColors.gray3}
              >
                <span>Click to browse or drag</span>
                <br />
                <span>
                  and drop{" "}
                  {fileType === 3
                    ? "image"
                    : fileType === 4
                    ? "video"
                    : fileType === 5
                    ? "document"
                    : ""}
                </span>
              </Typography>
            </Box>
          </Box>
        </Box>
        <input
          type="file"
          multiple
          accept={getAcceptAttribute()}
          className={classes.inputDisplay}
          onChange={handleFileChange}
          ref={fileInputRef}
        />
        <Box m={1}>
          {selectedFiles && (
            <li
              style={{
                fontSize: "12px",
                color: bgColors?.blue,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "100%", // or any specific width you want
              }}
            >
              {selectedFiles?.name ? selectedFiles?.name : selectedFiles}
            </li>
          )}
        </Box>
      </Grid>
    </div>
  );
};

export default FileUpload;
