import { EditorState, Modifier } from "draft-js";

const dictionary = {
  Name: "Name",
  //   CountryCode: "CountryCode",
  CountryName: "CountryName",
  Contact: "Contact",
  Email: "Email",
};

export const dictionaryOptions = Object.entries(dictionary).map(
  ([key, value]) => ({
    key,
    value,
  })
);

export const ButtonOptions = [
  { value: "", label: "None" },
  { value: "button", label: "Button" },
  { value: "list", label: "List" },
];

export const variableOptions = [
  // { value: "User Trait", label: "User Trait" },
  // { value: "Workflow Variable", label: "Workflow Variable" },
  { value: "Variable", label: "Variable" },
];

export const SaveResponseRadioOptions = [
  // { value: "trait", label: "User Trait" },
  // { value: "variable", label: "Workflow Variable" },
  { value: "Variable", label: "Variable" },
];

export interface Veriables {
  veriable: string;
  value: string;
  fallbackValue: string;
  type?: string;
}

// export const getNextVariableCount = (editorState: EditorState): number => {
//   const content = editorState?.getCurrentContent()?.getPlainText();
//   const variableMatches = content?.match(/\{\{(\d+)\}\}/g);
//   if (!variableMatches) return 1;
//   const variableNumbers = variableMatches?.map((match) =>
//     parseInt(match.replace(/\{\{|\}\}/g, ""), 10)
//   );
//   return Math.max(...variableNumbers) + 1;
// };

export const getNextVariableCount = (
  inputValue: EditorState | string,
  inputType: "editor" | "text"
): number => {
  let content = "";

  // Determine content based on inputType
  if (
    inputType === "editor" &&
    (inputValue as EditorState)?.getCurrentContent
  ) {
    // If input is of type editor, get the plain text from EditorState
    content = (inputValue as EditorState).getCurrentContent().getPlainText();
  } else if (inputType === "text") {
    // If input is plain string
    content = inputValue as string;
  }

  // Find matches for {{x}} variables
  const variableMatches = content.match(/\{\{(\d+)\}\}/g);
  if (!variableMatches) return 1;

  // Extract variable numbers
  const variableNumbers = variableMatches.map((match) =>
    parseInt(match.replace(/\{\{|\}\}/g, ""), 10)
  );

  // Return the next variable count
  return Math.max(...variableNumbers) + 1;
};

export const insertTextAtCursor = (
  editorState: EditorState,
  text: string
): EditorState => {
  const contentState = editorState.getCurrentContent();
  const selectionState = editorState.getSelection();
  const newContentState = Modifier.insertText(
    contentState,
    selectionState,
    text
  );
  return EditorState.push(editorState, newContentState, "insert-characters");
};

export const extractVariablesInWorkflows = (
  editorState: EditorState,
  existingVariables: any = []
): any => {
  const content = editorState.getCurrentContent().getPlainText();
  const variableRegex = /\{\{(\d+)\}\}/g;
  const matches: any = content.match(variableRegex);

  if (!matches)
    return existingVariables.filter((v: any) => content.includes(v.veriable));

  return matches?.map((match: any) => {
    const variable = match.replace(/\{\{|\}\}/g, "");
    const existingVar = existingVariables.find(
      (v: any) => v.veriable === `{{${variable}}}`
    );
    return (
      existingVar || {
        veriable: `{{${variable}}}`,
        type: "User Trait",
        value: "",
        fallbackValue: "",
      }
    );
  });
};

export const extractVariables = (
  editorState: EditorState,
  existingVariables: any = []
): any => {
  const content = editorState.getCurrentContent().getPlainText();
  const variableRegex = /\{\{(\d+)\}\}/g;
  const matches: any = content.match(variableRegex);

  if (!matches)
    return existingVariables.filter((v: any) => content.includes(v.veriable));

  return matches?.map((match: any) => {
    const variable = match.replace(/\{\{|\}\}/g, "");
    const existingVar = existingVariables.find(
      (v: any) => v.veriable === `{{${variable}}}`
    );
    return (
      existingVar || {
        veriable: `{{${variable}}}`,
        type: "User Trait",
        value: "",
        fallbackValue: "",
      }
    );
  });
};

export const extractVeriablesFromString = (
  content: string,
  existingVeriables: Veriables[] = []
): Veriables[] => {
  const variableRegex = /\{\{(\d+)\}\}/g; // Regex to match {{number}}
  const matches = content?.match(variableRegex);

  if (!matches) {
    // If no matches found, filter the existing veriables
    return existingVeriables?.filter((v) => content?.includes(v.veriable));
  }

  // Map over the matches to extract veriable information
  return matches.map((match) => {
    const veriable = match.replace(/\{\{|\}\}/g, ""); // Extract number
    const existingVeriable = existingVeriables.find(
      (v) => v.veriable === `{{${veriable}}}`
    );
    return (
      existingVeriable || {
        veriable: `{{${veriable}}}`,
        type: "",
        value: "",
        fallbackValue: "",
      }
    );
  });
};
