import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { bgColors } from "../../../utils/bgColors";
import SearchIconSvg2 from "../../../assets/svgs/SearchIconSvg2";
import CloseSvg from "../../../assets/svgs/CloseSvg";
import { useAppDispatch, useAppSelector } from "../../../utils/redux-hooks";
import { fetchAllTemplatesByCompanyId } from "../../../redux/slices/Templates/AllTemplatesSlice";
import DevicePreviewComponent from "../../TemplateComponents/TemplateForm/devicePreview";
import LoadingComponent from "../../common/LoadingComponent";
import TextFieldWithBorderComponent from "../../common/TextFieldWithBorderComponent";
import TemplateComponent from "../../TemplateComponents/TemplateForm";
import {
  extractVariables,
  generatePayload,
  ProcessButtonData,
} from "../../TemplateComponents/TemplateForm/functions";
import { styled } from "@mui/material/styles";
import { debounce } from "lodash";
import { parseTextToHtml } from "../../../utils/functions";

const StyledPaper = styled("div")(() => ({
  minWidth: "88%",
  minHeight: "80%",
  "&::-webkit-scrollbar": {
    cursor: "pointer",
    width: "8px", // Width of the scrollbar
  },
  "&::-webkit-scrollbar-thumb": {
    cursor: "pointer",
    backgroundColor: "#999999", // Color of the scrollbar thumb
    borderRadius: "6px", // Rounded corners for the scrollbar thumb
    transition: "background-color 0.2s ease-in-out", // Optional: Add a transition effect for smooth hover
  },
}));

const useStyles = makeStyles({
  mainContainer: {
    backgroundColor: bgColors.white1,
    padding: "8px",
    height: "60%",
    width: "100%",
    borderRadius: "25px",
  },
  bgContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "space-between",
    borderRadius: "25px",
    height: "100%",
    width: "100%",
  },
  manageContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  spaceBetween: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  searchField: {
    width: "100%",
    borderRadius: "12px",
    fontSize: "14px",
    backgroundColor: "transparent",
    "& input::placeholder": {
      textAlign: "left",
      fontSize: "14px",
      fontFamily: "Inter",
      color: "#4B5A5A !important",
      fontWeight: "500 !important",
    },
  },
  messageIconStyles: {
    backgroundColor: "#00934F",
    borderRadius: "14px",
    width: "62px",
    height: "62px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  createTemplateButtonText: {
    display: "flex",
    cursor: "pointer",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    // marginLeft: "50px !important",
    color: "#00934F !important",
    fontSize: "16px !important",
    fontWeight: "Semi Bold !important",
  },
  insertButton: {
    border: `1px solid ${bgColors.green}`,
    borderRadius: "8px",
    width: "80px",
    padding: "5px",
    height: "38px",
    textAlign: "center",
    cursor: "pointer",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "rgba(68, 71, 70, 0.08)",
    },
  },
  // resetButton: {
  //   border: `1px solid ${bgColors.red}`,
  //   borderRadius: "8px",
  //   width: "80px",
  //   padding: "5px",
  //   height: "38px",
  //   textAlign: "center",
  //   cursor: "pointer",
  //   alignItems: "center",
  //   "&:hover": {
  //     background: bgColors.red2,
  //   },
  // },
  // previewButton: {
  //   border: `1px solid ${bgColors.blue}`,
  //   borderRadius: "8px",
  //   width: "80px",
  //   padding: "5px",
  //   height: "38px",
  //   textAlign: "center",
  //   cursor: "pointer",
  //   alignItems: "center",
  //   "&:hover": {
  //     backgroundColor: "rgba(68, 71, 70, 0.08)",
  //   },
  // },
  buttonText: {
    cursor: "pointer",
    backgroundColor: "transparent",
    border: "none",
    color: bgColors.green,
  },
  mainBorderStyles: {
    borderRadius: "18px",
    border: `2px solid ${bgColors.gray5}`,
  },
  blackColor: {
    color: `${bgColors.black1} !important`,
    fontWeight: "500 !important",
    fontSize: "14px !important",
  },
  grayColor: {
    color: `${bgColors.black1} !important`,
    opacity: "60% !important",
    fontSize: "14px !important",
    textAlign: "justify",
  },
  textColor: {
    color: "#4B5A5A !important",
    fontSize: "14px !important",
  },
  variable: {
    color: `${bgColors.black1} !important`,
    // fontWeight: "500 !important",
    fontSize: "18px !important",
  },
});

interface TemplateState {
  templateId: string;
  templateName: string;
  category: number;
  subCategory: string;
  mediaType: number;
  mediaAwsUrl: any;
  header: string;
  body: string;
  callButtonName: string;
  phoneNumber: string;
  countryCode: string;
  urlButtonName: string[];
  redirectUrl: string[];
  quickReply: string[];
  footer: string;
  buttons: {
    buttonType: string;
    buttonValue: string;
    buttonName?: any; // Optional property
  }[];
  variables: Array<{
    type: string;
    id: string;
    value: string;
    field: string;
    fallBackValue: string;
  }>;
}

const TemplatePopUp = ({
  contactNumber,
  open,
  handleCloseTemplatePopup,
  setSendTemplatePayload,
}: any) => {
  // console.log(contactNumber)
  // console.log(formData)
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const tableContainerRef = useRef(null);

  const userData = useAppSelector((state: any) => state?.adminLogin?.data);
  const contactDetails = useAppSelector(
    (state: any) => state?.inboxContactDetails
  );
  const getuserPermissionData = useAppSelector(
    (state: any) => state.getUserPermissions?.data
  );
  const manageInboxObject = getuserPermissionData?.inbox;
  const templatesSlice = useAppSelector(
    (state: any) => state?.allTemplatesData
  );
  // console.log(templatesSlice)
  const activeTemplatesData = templatesSlice?.data?.data;
  // console.log("activeTemplatesData", activeTemplatesData);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [templatesData, setTemplatesData] = useState<any>([]);
  const [searchInput, setSearchInput] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [variableErrors, setVariableErrors] = useState<any>({});
  const [templateTooltip, setTemplateTooltip] = useState(false);
  const [openCreateTemplateDialog, setOpenCreateTemplateDialog] =
    useState(false);
  const [editObjectData, setEditObjectData] = useState({
    canEdit: false,
    templateId: "",
  });
  const [templateState, setTemplateState] = useState<TemplateState>({
    templateId: "",
    templateName: "",
    category: 1,
    subCategory: "",
    mediaType: 1,
    mediaAwsUrl: null,
    header: "",
    body: "",
    callButtonName: "",
    phoneNumber: "",
    countryCode: "",
    urlButtonName: [],
    redirectUrl: [],
    quickReply: [],
    footer: "",
    buttons: [],
    variables: [],
  });
  // console.log(templateState)
  const contactColumnNames = [
    // {
    //   name: "ContactId",
    //   value: contactDetails?.data?.contactId,
    // },
    { name: "Name", value: contactDetails?.data?.name },
    // { name: "BusinessId", value: userData?.companyId },
    // { name: "UserId", value: userData?.userId },
    { name: "ContactNumber", value: contactDetails?.data?.phoneNumber },
    { name: "Email", value: contactDetails?.data?.email },
    { name: "CountryName", value: contactDetails?.data?.countryName },
    // { name: "Tags", value: contactDetails?.data?.tags },
    // { name: "CreatedDate", value: contactDetails?.data?.createdDate },
    // { name: "IsActive", value: contactDetails?.data?.contactExist },
    // { name: "Note", value: contactDetails?.data?.note },
    // { name: "ChatStatus", value: contactDetails?.data?.chatStatus },
    // { name: "IsSpam", value: contactDetails?.data?.isSpam },
  ];
  // console.log(contactColumnNames)

  const hasTemplatePermission = (permission: any) => {
    for (const profileItem of permission) {
      if (Object.prototype.hasOwnProperty.call(profileItem, "sendTemplates")) {
        return true;
      }
    }
    return false;
  };

  const handleOpenCreateTemplateDialog = (
    action: string,
    templateId: string
  ) => {
    setOpenCreateTemplateDialog(true);
    setEditObjectData(
      action === "edit"
        ? { canEdit: true, templateId: templateId }
        : { canEdit: false, templateId: "" }
    );
  };

  const handleCloseCreateTemplateDialog = () => {
    setOpenCreateTemplateDialog(false);
  };

  const handleSearchChange = (event: any) => {
    const inputValue = event.target.value;
    setPageNumber(1);
    setSearchInput(inputValue);
  };

  const handleChange = (event: any) => {
    // console.log("event", event);
    const { buttons, ...restTemplateState } = templateState;
    const { updatedState, quickReply, redirectUrl, urlButtonName } =
      ProcessButtonData(restTemplateState, buttons);

    const bodyVariables = extractVariables(event?.body, "body");
    const headerVariables = extractVariables(event?.header, "header");
    const redirectUrlVariables = extractVariables(event?.buttons, "url Button");
    // console.log("redirectUrlVariables", redirectUrlVariables);
    const newVariables = [
      ...bodyVariables,
      ...headerVariables,
      ...redirectUrlVariables,
    ];
    // console.log(newVariables)

    setTemplateState({
      templateId: event?.templateId,
      templateName: event?.templateName,
      category: event?.category,
      subCategory: event?.subCategory,
      mediaType: event?.mediaType,
      mediaAwsUrl: event?.mediaAwsUrl,
      header: event?.header,
      body: event?.body,
      callButtonName: updatedState?.callButtonName || "",
      phoneNumber: updatedState?.phoneNumber || "",
      countryCode: event?.countryCode,
      urlButtonName: urlButtonName,
      redirectUrl: redirectUrl,
      quickReply: quickReply,
      footer: event?.footer,
      buttons: event?.buttons,
      variables: newVariables,
    });
  };

  const handleVariableValueChange = (
    event: React.ChangeEvent<{ value: unknown }>,
    valueType: string,
    variableIndex: number
  ) => {
    const { value }: any = event.target;

    setTemplateState((prevState) => {
      // console.log(prevState)
      const updatedVariables = prevState.variables.map((variable, index) => {
        if (index === variableIndex) {
          if (valueType === "value") {
            const selectedColumn = contactColumnNames.find(
              (column) => column.name === value
            ); // Find the corresponding column object
            return {
              ...variable,
              field: value, // Assign the selected field name to the field key
              value: selectedColumn?.value ?? value, // Assign the value from the selected column or an empty string if not found
            };
          } else if (valueType === "fallBackValue") {
            return { ...variable, fallBackValue: value as string };
          }
        }
        return variable;
      });

      return {
        ...prevState,
        variables: updatedVariables,
      };
    });
    setVariableErrors((prevErrors: any) => {
      const newErrors = { ...prevErrors };
      if (valueType === "value") {
        if (value) {
          if (newErrors[variableIndex]) {
            delete newErrors[variableIndex]?.value;
            if (Object.keys(newErrors[variableIndex]).length === 0) {
              delete newErrors[variableIndex];
            }
          }
        } else {
          newErrors[variableIndex] = {
            ...newErrors[variableIndex],
            value: "Value is required",
          };
        }
      } else if (valueType === "fallBackValue") {
        if (value) {
          if (newErrors[variableIndex]) {
            delete newErrors[variableIndex]?.fallbackValue;
            if (Object.keys(newErrors[variableIndex]).length === 0) {
              delete newErrors[variableIndex];
            }
          }
        } else {
          newErrors[variableIndex] = {
            ...newErrors[variableIndex],
            fallbackValue: "Fallback is required",
          };
        }
      }
      return newErrors;
    });
  };

  const handlePreview = () => {
    // Get current template state
    const bodyText = templateState?.body || "";
    // console.log(bodyText)
    const headerText = templateState?.header || "";

    // Get the latest variables based on their type
    const latestBodyVariables = templateState?.variables?.filter(
      (variable) => variable?.type === "body"
    );
    // console.log(latestBodyVariables)
    const latestHeaderVariables = templateState?.variables?.filter(
      (variable) => variable?.type === "header"
    );
    // console.log(latestBodyVariables)
    const latestUrlButtonVariables = templateState?.variables?.filter(
      (variable) => variable?.type === "url Button"
    );
    // console.log(latestHeaderVariables)
    // Replace variables in body text
    let updatedBodyText = bodyText;
    // console.log(updatedBodyText)
    latestBodyVariables?.forEach((variable) => {
      updatedBodyText = updatedBodyText?.replace(
        variable?.id,
        variable?.value ? variable?.value : variable?.fallBackValue
      );
    });

    // Replace variables in header text
    let updatedHeaderText = headerText;
    latestHeaderVariables.forEach((variable) => {
      updatedHeaderText = updatedHeaderText?.replace(
        variable?.id,
        variable?.value ? variable?.value : variable?.fallBackValue
      );
    });

    // Replace variables in URL button values
    let updatedButtons = templateState?.buttons?.map((button: any) => {
      if (button?.buttonType === "URL") {
        let updatedButtonValue = button?.buttonValue;
        latestUrlButtonVariables.forEach((variable) => {
          updatedButtonValue = updatedButtonValue.replace(
            variable.id,
            variable.value ? variable.value : variable.fallBackValue
          );
        });
        return {
          ...button,
          buttonValue: updatedButtonValue,
        };
      }
      return button;
    });
    // console.log("updatedButtons", updatedButtons);

    // Update template state with replaced texts
    setTemplateState((prevState) => ({
      ...prevState,
      body: updatedBodyText,
      header: updatedHeaderText,
      buttons: updatedButtons,
    }));
  };

  const handleVariableValues = () => {
    const errors: any = {};
    templateState?.variables?.forEach((variable, index) => {
      if (!variable?.field) {
        errors[index] = { ...errors[index], value: "Value is required" };
      }
      if (!variable?.fallBackValue) {
        errors[index] = {
          ...errors[index],
          fallbackValue: "Fallback is required",
        };
      }
    });
    setVariableErrors(errors);
    return Object.keys(errors).length === 0;
  };

  // Function to handle inserting the template
  const handleInsertTemplate = () => {
    const hasPermission = hasTemplatePermission(manageInboxObject);
    if (hasPermission) {
      if (!handleVariableValues()) {
        console.log("Validation failed, cannot insert template.");
        return;
      }

      // Call handlePreview to update the template state
      handlePreview();
      // Generate payload from the updated template state
      const payload = generatePayload(userData, contactNumber, templateState);
      // console.log(payload)
      if (payload?.templateId) {
        // console.log("templatepayload", payload, templateState);
        setSendTemplatePayload(payload, templateState);
        setTemplateState({
          templateId: "",
          templateName: "",
          category: 1,
          subCategory: "",
          mediaType: 1,
          mediaAwsUrl: null,
          header: "",
          body: "",
          callButtonName: "",
          phoneNumber: "",
          countryCode: "",
          urlButtonName: [],
          redirectUrl: [],
          quickReply: [],
          footer: "",
          buttons: [],
          variables: [],
        });
      }
    } else {
      setTemplateTooltip(true);
      setTimeout(() => {
        setTemplateTooltip(false);
      }, 2000);
    }
    // Log or use the payload as needed
  };

  useEffect(() => {
    const postData = {
      userId: userData?.userId,
      businessId: userData?.companyId,
      pageNumber: pageNumber,
      per_page: 40,
      filters: {
        searching: {
          value: searchInput,
        },
        sorting: {
          column: "",
          order: "",
        },
        filtering: {
          filterType: "and",
          conditions: [
            {
              column: "Status",
              operator: "equal",
              value: "2",
            },
          ],
        },
      },
    };
    dispatch(fetchAllTemplatesByCompanyId(postData));
  }, [dispatch, searchInput, pageNumber]);

  useEffect(() => {
    setTemplatesData(templatesSlice?.data?.data);
  }, []);
  // console.log(templatesData)
  // console.log(templatesSlice)
  // console.log(pageNumber, "pageNumber")
  const handleScroll = useCallback(
    debounce(() => {
      if (tableContainerRef?.current) {
        const { scrollTop, scrollHeight, clientHeight } =
          tableContainerRef.current;
        if (
          scrollTop + clientHeight >= scrollHeight - 10 &&
          !isLoadingMore &&
          templatesData?.length !== templatesSlice?.data?.total
        ) {
          // console.log("called");
          handleLoadMore();
        }
      }
    }, 200),
    [isLoadingMore, templatesData?.length, templatesSlice?.data?.total]
  );
  const handleLoadMore = useCallback(() => {
    if (
      !isLoadingMore &&
      templatesData?.length !== templatesSlice?.data?.total &&
      pageNumber <= Math.ceil(templatesSlice?.data?.total) / 40
    ) {
      setIsLoadingMore(true);
      setPageNumber((prevPage) => prevPage + 1);
    }
  }, [isLoadingMore, templatesData?.length, templatesSlice?.data?.total]);

  useEffect(() => {
    if (activeTemplatesData) {
      if (pageNumber === 1) {
        setTemplatesData(activeTemplatesData);
      } else {
        setTemplatesData((prevData: any) => [
          ...prevData,
          ...(activeTemplatesData || []),
        ]);
      }
      setIsLoadingMore(false);
    }
  }, [activeTemplatesData]);

  // console.log("templates", templateState);

  return (
    <Dialog
      open={open}
      onClose={handleCloseTemplatePopup}
      PaperProps={{
        component: StyledPaper,
      }}
    >
      <DialogTitle>
        <Grid
          // item
          sx={{
            // margin: { xs: "8px", md: "16px 32px" },
            overflow: "hidden",
          }}
          // xs={12}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Grid item xs={3}>
            <Typography
              variant="h6"
              style={{
                color: "#000000",
                fontWeight: "600",
                fontSize: "18px",
                paddingLeft: "25px",
              }}
            >
              Select Template
            </Typography>
          </Grid>
          <Grid item xs={4.9}>
            <Typography
              variant="h6"
              style={{
                color: "#000000",
                fontWeight: "600",
                fontSize: "18px",
                paddingLeft: "34px",
              }}
            >
              Message
            </Typography>
          </Grid>
          <Grid item xs={3.9} display={"flex"} justifyContent={"space-between"}>
            <Typography
              variant="h6"
              style={{
                color: "#000000",
                fontWeight: "600",
                fontSize: "18px",
                paddingLeft: "5px",
              }}
            >
              Preview
            </Typography>
            <Box
              onClick={() => {
                setTemplateState({
                  templateId: "",
                  templateName: "",
                  category: 1,
                  subCategory: "",
                  mediaType: 1,
                  mediaAwsUrl: null,
                  header: "",
                  body: "",
                  callButtonName: "",
                  phoneNumber: "",
                  countryCode: "",
                  urlButtonName: [],
                  redirectUrl: [],
                  quickReply: [],
                  footer: "",
                  buttons: [],
                  variables: [],
                });
                handleCloseTemplatePopup();
              }}
              style={{ cursor: "pointer", marginLeft: "60px" }}
            >
              <CloseSvg />
            </Box>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          sx={{
            overflow: "auto",
            // height: "80vh",
            // boxShadow: "1px 2px 2px #CDCDCD",
          }}
        >
          <Grid
            item
            xs={3}
            ml={3}
            className={classes.mainContainer}
            sx={{ height: "99%", overflow: "hidden" }}
          >
            {/* <Grid className={classes.bgContainer}> */}
            <Typography
              mt={4}
              ml={2}
              className={classes.createTemplateButtonText}
              variant="body2"
              onClick={() => handleOpenCreateTemplateDialog("add", "")}
            >
              + Create Template
            </Typography>
            <Box className={classes.bgContainer}>
              <Box
                sx={{
                  padding: "8px 16px",
                }}
                className={classes.spaceBetween}
              >
                <TextField
                  className={classes.searchField}
                  variant="standard"
                  size="small"
                  fullWidth
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      padding: "18px",
                      fontSize: "12px",
                      // fontWeight: "600 !important",
                      height: "38px",
                    },
                    startAdornment: (
                      <IconButton sx={{ p: 0, paddingTop: "3px" }}>
                        <SearchIconSvg2 />
                      </IconButton>
                    ),
                  }}
                  inputProps={{
                    style: {
                      // Additional style for placeholder
                      // fontWeight: "600 !important",
                      padding: "0px", // Apply font weight here
                    },
                  }}
                  onChange={handleSearchChange}
                  placeholder="Search templates"
                />
              </Box>
              {/* {templatesSlice?.status === "loading" ? (
                  <LoadingComponent height="100%" color={bgColors?.blue} />
                ) : ( */}
              <Box
                sx={{
                  height: "99vh",
                  overflowY: "scroll",
                  position: "relative",
                  scrollBehavior: "smooth",
                  // Scrollbar styling
                  "&::-webkit-scrollbar": {
                    cursor: "pointer",
                    width: "6px",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    cursor: "pointer",
                    backgroundColor: "#cdcdcd",
                    borderRadius: "8px",
                    transition: "background-color 0.2s ease-in-out",
                  },
                }}
                ref={tableContainerRef}
                onScroll={handleScroll}
              >
                {templatesData?.length > 0 ? (
                  templatesData?.map((template: any) => (
                    <Box
                      className={classes.manageContainer}
                      // style={{ cursor: "pointer" }}
                      sx={{
                        margin: "0px 16px",
                        cursor: "pointer",
                        borderRadius: "8px",
                        background:
                          templateState?.templateId === template?.templateId
                            ? bgColors?.gray2
                            : "transparent",
                        "&:hover": {
                          background: bgColors?.gray2,
                          // borderRadius: "8px",
                        },
                      }}
                      key={template?.templateId}
                      onClick={() => handleChange(template)}
                    >
                      <Typography
                        variant="h6"
                        p={1}
                        className={classes.blackColor}
                        style={{ fontSize: "14px" }}
                      >
                        {template?.templateName}
                      </Typography>
                    </Box>
                  ))
                ) : (
                  <Typography
                    style={{
                      paddingLeft: "40px",
                      paddingTop: "20px",
                      fontSize: "14px",
                    }}
                  >
                    No Templates Found
                  </Typography>
                )}
              </Box>
              {/* )} */}
              {/* <Box mt={2}>
                  <PaginationComponent
                    total={templatesSlice?.data?.total}
                    setPage={setPageNumber}
                  />
                </Box> */}
              {templatesSlice?.status === "loading" && (
                <LoadingComponent height="20%" color={bgColors?.blue} />
              )}
              {/* <div style={{ height: '20px', margin: '10px 0' }}>
                  <LoadingComponent height="100%" color={bgColors?.blue} />
                </div> */}
            </Box>
            {/* </Grid> */}
          </Grid>
          {templateState?.templateId ? (
            <Grid item xs={5}>
              <Box m={3} p={3} className={classes.mainBorderStyles}>
                <Typography variant="body2" className={classes.blackColor}>
                  Header
                </Typography>
                {templateState?.mediaType === 2 ? (
                  <Typography variant="body2" className={classes.grayColor}>
                    {templateState?.header}
                  </Typography>
                ) : templateState?.mediaType === 3 ? (
                  <img
                    style={{
                      maxWidth: "100%",
                      maxHeight: "150px",
                      width: "auto",
                      height: "auto",
                      objectFit: "contain",
                    }}
                    src={templateState?.mediaAwsUrl}
                  />
                ) : templateState?.mediaType === 4 ? (
                  <video
                    style={{
                      maxWidth: "100%",
                      maxHeight: "150px",
                      width: "auto",
                      height: "auto",
                      objectFit: "contain",
                    }}
                    src={templateState?.mediaAwsUrl}
                  />
                ) : templateState?.mediaType === 5 ? (
                  <iframe
                    style={{
                      maxWidth: "100%",
                      maxHeight: "150px",
                      width: "auto",
                      height: "auto",
                      objectFit: "contain",
                    }}
                    src={templateState?.mediaAwsUrl}
                  ></iframe>
                ) : (
                  ""
                )}
                <Box mt={2}>
                  <Typography variant="body2" className={classes.blackColor}>
                    Body
                  </Typography>
                  <Typography variant="body2" className={classes.grayColor}>
                    <span
                      // ref={(el) => (messageRefs.current[index] = el)}
                      dangerouslySetInnerHTML={{
                        __html: parseTextToHtml(templateState?.body),
                      }}
                    />
                  </Typography>
                </Box>
                <Box mt={2}>
                  <Typography variant="body2" className={classes.blackColor}>
                    Footer
                  </Typography>
                  <Typography variant="body2" className={classes.grayColor}>
                    {templateState?.footer}
                  </Typography>
                </Box>
                <Box mt={2}>
                  <Typography variant="body2" className={classes.blackColor}>
                    Buttons
                  </Typography>
                  {templateState?.buttons?.length > 0 ? (
                    <Typography variant="body2" className={classes.blackColor}>
                      <ul>
                        {templateState?.buttons?.map(
                          (button: any, index: number) =>
                            button?.buttonType === "PHONE_NUMBER" ? (
                              <li key={index}>
                                Phone Number -
                                <span
                                  style={{
                                    color: "#1795D2",
                                    fontSize: "12px",
                                  }}
                                >
                                  {button?.buttonValue}
                                </span>
                              </li>
                            ) : button?.buttonType === "URL" ? (
                              <li key={index}>
                                Website -
                                <a
                                  href={button?.buttonValue}
                                  target="_blank"
                                  style={{
                                    color: "#1795D2",
                                    fontSize: "12px",
                                    maxWidth: "350px", // Set the maximum width
                                    whiteSpace: "nowrap", // Prevent text from wrapping to the next line
                                    overflow: "hidden", // Hide the overflowed content
                                    textOverflow: "ellipsis", // Add ellipsis at the end of the text
                                    display: "inline-block", // Required for text-overflow to work on the anchor tag
                                    verticalAlign: "bottom", // Align it with text
                                  }}
                                  rel="noreferrer"
                                >
                                  {button?.buttonValue}
                                </a>
                              </li>
                            ) : (
                              <li key={index}>
                                Quick Reply -
                                <span
                                  style={{
                                    color: "#1795D2",
                                    fontSize: "12px",
                                  }}
                                >
                                  {button?.buttonValue}
                                </span>
                              </li>
                            )
                        )}
                      </ul>
                    </Typography>
                  ) : (
                    <Typography
                      sx={{
                        fontSize: 12,
                        fontStyle: "italic",
                        color: bgColors?.gray1,
                      }}
                    >
                      -- No Buttons --
                    </Typography>
                  )}
                  {/* <Grid item>
                  <FileUpload />
                </Grid> */}
                </Box>
              </Box>
              {templateState?.variables?.map((variable, index) => (
                <Box key={index}>
                  <Typography sx={{ fontSize: 12, ml: 4 }}>
                    {variable?.type?.toUpperCase()}&nbsp;VARIABLES:
                  </Typography>
                  <Box
                    style={{
                      justifyContent: "center",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <Grid item xs={2} mb={2}>
                      <InputLabel className={classes.blackColor}>
                        Variable
                      </InputLabel>
                      <TextFieldWithBorderComponent
                        name="variable"
                        size="small"
                        variant="outlined"
                        value={variable?.id}
                        disabled
                        label="Variable"
                        placeholder=""
                        sx={{
                          "& input": {
                            // fontWeight: "600",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={4} mb={2} ml={2}>
                      <InputLabel
                        className={classes.blackColor}
                        sx={{ fontSize: { xs: 12, md: 14 }, mb: 1 }}
                      >
                        Value
                      </InputLabel>
                      <FormControl
                        fullWidth
                        size="small"
                        // error={!!formErrors?.category}
                        error={!!variableErrors[index]?.value}
                      >
                        <InputLabel
                          // id="demo-simple-select-label"
                          id="demo-simple-select-error-label"
                          sx={{ fontSize: { xs: 12, md: 14 } }}
                        >
                          Value
                        </InputLabel>
                        <Select
                          // labelId="demo-simple-select-label"
                          // id="demo-simple-select"
                          labelId="demo-simple-select-error-label"
                          id="demo-simple-select-error"
                          // disabled={canEdit}
                          value={variable?.field}
                          onChange={(event: any) =>
                            handleVariableValueChange(event, "value", index)
                          }
                          name="value"
                          label="Value"
                          inputProps={{ style: { fontSize: 14 } }}
                          sx={{
                            fontSize: 14,
                            borderRadius: "8px",
                            // fontWeight: "600",
                          }}
                        >
                          {contactColumnNames?.map((item) => (
                            <MenuItem
                              value={item?.name}
                              key={item?.name}
                              sx={{ fontSize: { xs: 10, md: 12 } }}
                            >
                              {item?.name}
                            </MenuItem>
                          ))}
                        </Select>
                        {variableErrors[index]?.value && (
                          <FormHelperText>
                            {variableErrors[index].value}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={4} mb={2} ml={2}>
                      <InputLabel className={classes.blackColor}>
                        Fallback Value
                      </InputLabel>
                      <TextFieldWithBorderComponent
                        name="fallBackValue"
                        size="small"
                        variant="outlined"
                        label="Fallback Value"
                        value={variable?.fallBackValue}
                        onChange={(event: any) =>
                          handleVariableValueChange(
                            event,
                            "fallBackValue",
                            index
                          )
                        }
                        placeholder=""
                        sx={{
                          "& input": {
                            // fontWeight: "600",
                          },
                        }}
                        error={!!variableErrors[index]?.fallbackValue}
                        helperText={variableErrors[index]?.fallbackValue}
                      />
                    </Grid>
                  </Box>
                </Box>
              ))}
            </Grid>
          ) : (
            <Grid item xs={5}>
              <Typography
                sx={{
                  textAlign: "center",
                  fontSize: 16,
                  mt: 10,
                  fontStyle: "italic",
                  color: bgColors?.gray1,
                }}
              >
                Please choose any template...
              </Typography>
            </Grid>
          )}

          <Grid
            item
            xs={3.5}
            justifyContent="center"
            sx={{ overflow: "hidden" }}
          >
            <DevicePreviewComponent
              header={templateState?.header}
              body={templateState?.body}
              footer={templateState?.footer}
              mediaType={templateState?.mediaType}
              mediaFile={
                templateState?.mediaAwsUrl ? templateState?.mediaAwsUrl : null
              }
              buttons={templateState?.buttons}
            />
          </Grid>
        </Grid>
        <Grid />
      </DialogContent>
      <TemplateComponent
        editObjectData={editObjectData}
        open={openCreateTemplateDialog}
        handleClose={handleCloseCreateTemplateDialog}
      />
      <DialogActions sx={{ mx: 3, my: 1 }}>
        <Box
          mr={3}
          className={classes.insertButton}
          onClick={() =>
            setTemplateState({
              templateId: "",
              templateName: "",
              category: 1,
              subCategory: "",
              mediaType: 1,
              mediaAwsUrl: null,
              header: "",
              body: "",
              callButtonName: "",
              phoneNumber: "",
              countryCode: "",
              urlButtonName: [],
              redirectUrl: [],
              quickReply: [],
              footer: "",
              buttons: [],
              variables: [],
            })
          }
        >
          <button
            className={classes.buttonText}
            // style={{ color: bgColors.red }}
          >
            Reset
          </button>
        </Box>
        <Box
          mr={3}
          onClick={() => handlePreview()}
          className={classes.insertButton}
        >
          <button
            className={classes.buttonText}
            // style={{ color: bgColors.blue }}
          >
            Preview
          </button>
        </Box>
        <Tooltip
          title="Access Denied"
          open={templateTooltip}
          placement="top"
          onClose={() => setTemplateTooltip(false)}
        >
          <Box
            onClick={() => handleInsertTemplate()}
            className={classes.insertButton}
            mr={4}
          >
            <button
              className={classes.buttonText}
              // style={{ color: bgColors.green }}
            >
              {contactNumber ? "Send" : "Insert"}
            </button>
          </Box>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};

export default TemplatePopUp;
