import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Grid, Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Template from "../../assets/svgs/TemplateSvg";
import { useAppSelector } from "../../utils/redux-hooks";
import SettingsInputSvideoIcon from "@mui/icons-material/SettingsInputSvideo";
import CategoryIcon from "@mui/icons-material/Category";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import StoreIcon from "@mui/icons-material/Store";
import {
  checkAutoCheckoutFlowPermission,
  checkCatalogPermission,
  checkCommerceSettingsPermission,
  checkOrderPanelPermission,
} from "../../utils/permissions";

const useStyles = makeStyles({
  container: {
    backgroundColor: "#F8F8F8",
    height: "100vh",
    textAlign: "center",
  },
  sideBarContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
    alignItems: "center",
  },
  manageItem: {
    cursor: "pointer",
    padding: "5px",
  },
  activeItem: {
    backgroundColor: "#f0f0f0",
    color: "#303030 !important",
    "& .MuiTypography-root": {
      fontWeight: "600 !important",
    },
  },
  signOutContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  textColor: {
    color: "#4B5A5A",
    fontSize: "20px",
  },
  blackColor: {
    color: "#000000",
    fontWeight: "600 !important",
  },
  sideBar: {
    position: "sticky",
    top: 0,
    height: "100vh",
    overflowY: "auto",
  },
});

const CommerceSidebar = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  const getuserPermissionSlice = useAppSelector(
    (state: any) => state.getUserPermissions
  );
  const getUserPermissionData = getuserPermissionSlice?.data;

  const [activeSection, setActiveSection] = useState("");

  const hasCommerceSettingsPermission = checkCommerceSettingsPermission(
    getUserPermissionData?.commerce
  );
  const hasCatalogPermission = checkCatalogPermission(
    getUserPermissionData?.commerce
  );
  const hasOrderPanelPermission = checkOrderPanelPermission(
    getUserPermissionData?.commerce
  );
  const hasAutoCheckoutFlowPermission = checkAutoCheckoutFlowPermission(
    getUserPermissionData?.commerce
  );
  // Function to handle navigation and set active section
  const handleNavigation = (path: string) => {
    navigate(`/${path}`);
    setActiveSection(path);
  };

  useEffect(() => {
    const path = location.pathname;
    setActiveSection(path || "");
  }, [location.pathname]);

  return (
    <Grid className={classes.container}>
      <Box textAlign="start" pl={1} className={classes.sideBarContainer} pt={3}>
        <Box m={2} mb={1}>
          <StoreIcon />
        </Box>
        <Typography variant="h6" className={classes.blackColor}>
          Commerce
        </Typography>
      </Box>

      <Box>
        {!hasCommerceSettingsPermission && (
          <Box
            m={2}
            pl={1}
            textAlign="start"
            className={`${classes.manageItem} ${classes.textColor} ${
              activeSection.includes("commerce-settings")
                ? classes.activeItem
                : ""
            }`}
            onClick={() => handleNavigation("commerce-settings")}
          >
            <Typography
              fontSize="14px"
              sx={{ display: "flex", alignItems: "center" }}
            >
              <span style={{ paddingTop: "2px" }}>
                <SettingsInputSvideoIcon
                  fontSize="small"
                  sx={{ marginTop: "5x", height: "18px" }}
                />
              </span>
              &nbsp;Commerce Settings
            </Typography>
          </Box>
        )}
        {!hasCatalogPermission && (
          <Box
            m={2}
            pl={1}
            textAlign="start"
            className={`${classes.manageItem} ${classes.textColor} ${
              activeSection.includes("catalog") ? classes.activeItem : ""
            }`}
            onClick={() => handleNavigation("catalog")}
          >
            <Typography
              fontSize="14px"
              sx={{ display: "flex", alignItems: "center" }}
            >
              <span style={{ marginTop: "0px", height: "18px" }}>
                <CategoryIcon fontSize="small" />
              </span>
              &nbsp;Catalog
            </Typography>
          </Box>
        )}
        {/* {!hasOrderPanelPermission && (
          <Box
            m={2}
            pl={1}
            textAlign="start"
            className={`${classes.manageItem} ${classes.textColor} ${
              activeSection.includes("order-panel") ? classes.activeItem : ""
            }`}
            onClick={() => handleNavigation("order-panel")}
          >
            <Typography
              fontSize="14px"
              sx={{ display: "flex", alignItems: "center" }}
            >
              <span style={{ marginTop: "0px", height: "18px" }}>
                <LocalMallIcon fontSize="small" />
              </span>
              &nbsp;Order Panel
            </Typography>
          </Box>
        )}
        {!hasAutoCheckoutFlowPermission && (
          <Box
            m={2}
            pl={1}
            textAlign="start"
            className={`${classes.manageItem} ${classes.textColor} ${
              activeSection.includes("auto-checkout-flow")
                ? classes.activeItem
                : ""
            }`}
            onClick={() => handleNavigation("auto-checkout-flow")}
          >
            <Typography
              fontSize="14px"
              sx={{ display: "flex", alignItems: "center" }}
            >
              <span style={{ marginTop: "0px", height: "18px" }}>
                <ShoppingCartIcon fontSize="small" />
              </span>
              &nbsp;Auto Checkout Flow
            </Typography>
          </Box>
        )} */}
      </Box>
    </Grid>
  );
};

export default CommerceSidebar;
