import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  Tooltip,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseSvg from "../../assets/svgs/CloseSvg";
import { GiTakeMyMoney } from "react-icons/gi";
import { useAppSelector } from "../../utils/redux-hooks";
import LoadingComponent from "../common/LoadingComponent";
import { bgColors } from "../../utils/bgColors";

const AddWalletBalancePopup = ({
  amountClear,
  open,
  addBalanceTooltip,
  handleClose,
  handleUpgrade,
}: any) => {
  // console.log(open);
  // console.log(amountClear);
  const [amount, setAmount] = useState("");
  // console.log(amount)
  const [amountErr, setAmountErr] = useState<any>("");
  // console.log(amountErr)

  const getCreateOrderStatus = useAppSelector(
    (state: any) => state?.Subscription?.createOrderStatus
  );
  // console.log(getCreateOrderStatus);

  const predefinedAmounts = [1000, 5000, 10000, 20000];
  useEffect(() => {
    if (amountClear) {
      setAmount("");
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        setAmount("");
        setAmountErr("");
        handleClose();
      }}
    >
      <DialogTitle>
        <Box display="flex" alignItems="center">
          <Typography>
            {" "}
            <GiTakeMyMoney />
            &nbsp;Add Wallet Balance
          </Typography>
          <Box flexGrow={1} />
          <Button
            onClick={() => {
              setAmount("");
              setAmountErr("");
              handleClose();
            }}
          >
            <CloseSvg />
          </Button>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2" gutterBottom>
          Enter the Amount you want to add balance to Wallet
        </Typography>
        <TextField
          fullWidth
          value={amount}
          onChange={(e) => {
            const value = e.target.value;
            if (value === "" || /^(\d+(\.\d{0,2})?|(\.\d{0,2}))$/.test(value)) {
              const numericValue = parseFloat(value);
              if (value === "." || numericValue <= 0) {
                setAmountErr("Amount must be more than zero.");
              } else {
                setAmount(value);
                setAmountErr("");
              }
            }
          }}
          InputProps={{
            startAdornment: <Typography>₹</Typography>,
          }}
          placeholder="0.00"
          margin="normal"
          type="text" // Changed from "number" to "text" for better control
          inputProps={{
            inputMode: "decimal", // For mobile keyboards
            pattern: "[0-9]*\\.?[0-9]{0,2}", // Allows up to 2 decimal places
          }}
          error={amountErr != ""}
          helperText={amountErr}
        />
        <Box display="flex" justifyContent="space-between" my={2}>
          {predefinedAmounts.map((presetAmount) => (
            <Button
              key={presetAmount}
              variant="outlined"
              color="success"
              onClick={() => setAmount(presetAmount.toString())}
            >
              ₹{presetAmount}
            </Button>
          ))}
        </Box>
        <Accordion>
          <Box>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{
                margin: "0px",
                minHeight: "auto !important",
                "& .Mui-expanded": {
                  margin: "10px 0px 0px 0px !important",
                },
              }}
            >
              <Typography sx={{ marginBottom: "0px" }}>
                Terms & Conditions
              </Typography>
            </AccordionSummary>
          </Box>

          <Box>
            <Typography sx={{ px: "20px" }}>
              <ul>
                <li>
                  Subscription charges are not deducted from wallet balance
                </li>
                <li>Wallet balance is non-refundable & non-transferable</li>
              </ul>
            </Typography>
          </Box>
        </Accordion>
        {getCreateOrderStatus != "loading" ? (
          <Tooltip
            placement="top"
            title="Access Denied"
            open={addBalanceTooltip}
            // onClose={() => setAddBalanceTooltip(false)}
          >
            <Button
              fullWidth
              variant="contained"
              color="success"
              style={{ marginTop: "16px" }}
              onClick={() => handleUpgrade(amount)}
              disabled={amount == "" ? true : false}
            >
              Add Balance
            </Button>
          </Tooltip>
        ) : (
          <LoadingComponent height="auto" color={bgColors?.blue} />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default AddWalletBalancePopup;
