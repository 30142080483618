import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Switch,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import { bgColors } from "../../utils/bgColors";
import TextFieldWithBorderComponent from "../common/TextFieldWithBorderComponent";
import CloseSvg from "../../assets/svgs/CloseSvg";
import InfoIcon from "./InfoIcon";
const label = { inputProps: { "aria-label": "Switch demo" } };

const useStyles = makeStyles({
  SaveChangesButton: {
    // backgroundColor: bgColors.green,
    border: `1px solid ${bgColors.green}`,
    color: bgColors.green,
    borderRadius: "8px",
    width: "120px",
    height: "32px",
    // padding: "10px",
    cursor: "pointer",
  },
  blackColor: {
    color: `${bgColors.black1} !important`,
    fontWeight: "500 !important",
  },
  flexDirection: {
    display: "flex",
    alignItems: "center",
  },
});

interface UrlField {
  id: number;
  sourceUrl: string;
  preFilledMessage: string;
  onScreenMessage: string;
  removeChecked: boolean;
  capitalizeChecked: boolean;
}

interface WhatsappUrlConfigProps {
  urlFields: UrlField[];
  onAddUrl: () => void;
  onRemoveUrl: (id: number) => void;
  onUrlFieldChange: (
    id: number,
    field: keyof UrlField
  ) => (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const WhatsappUrlConfig: React.FC<WhatsappUrlConfigProps> = ({
  urlFields,
  onAddUrl,
  onRemoveUrl,
  onUrlFieldChange,
}) => {
  const classes = useStyles();
  // console.log(urlFields, "urlFields");
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const handleSwitchChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const checked = event.target.checked;
    setIsChecked(checked);
  };
  return (
    <Box>
      <Box sx={{ display: "flex", gap: "50px", mt: 5, paddingBottom: "40px" }}>
        <Box sx={{ marginLeft: "10px" }}>
          <Box sx={{ display: "flex" }}>
            <Typography sx={{ marginTop: "5px" }}>
              URL Personalization
            </Typography>
            {/* <Switch defaultChecked color="success" /> */}
            <Switch
              checked={isChecked}
              onChange={handleSwitchChange}
              color="success"
            />
          </Box>
          <Typography
            sx={{ color: "#6E6E6E", fontSize: "12px", fontWeight: "400" }}
          >
            You can personalize the user message based on your website's URL
          </Typography>
        </Box>
        {!isChecked ? (
          <Box
            sx={{
              border: "1px solid rgb(240, 240, 240)",
              // rgb(240, 240, 240)
              padding: "10px",
              // padding: "20px 20px",
              borderRadius: "8px",
              width: "56%",
              // width: "100%",
              color: "blue",
            }}
          >
            Enable URL personalization feature
          </Box>
        ) : (
          <Box sx={{ width: "600px" }}>
            <Button onClick={onAddUrl} sx={{ marginBottom: "10px" }}>
              + ADD URL
            </Button>

            {urlFields.map((field) => (
              <Box
                key={field.id}
                sx={{
                  position: "relative",
                  border: "1px solid rgb(240, 240, 240)",
                  padding: "15px",
                  borderRadius: "8px",
                  marginBottom: "10px",
                }}
              >
                {/* <Box
                sx={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                }}
                onClick={() => onRemoveUrl(field.id)}
              >
                <CloseSvg />
              </Box> */}
                <Box
                  sx={{
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                    cursor: urlFields.length === 1 ? "not-allowed" : "pointer",
                    opacity: urlFields.length === 1 ? 0.5 : 1,
                  }}
                  onClick={
                    urlFields.length > 1
                      ? () => onRemoveUrl(field.id)
                      : () => {}
                  }
                >
                  <CloseSvg />
                </Box>

                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
                >
                  <Box>
                    <Box className={classes.flexDirection}>
                      <Typography
                        variant="body1"
                        className={classes.blackColor}
                        sx={{ fontSize: { xs: 12, md: 14 }, mb: 1 }}
                      >
                        Source Url
                      </Typography>
                      <Box>
                        <InfoIcon tooltip="You can parameterize urls. ex. - https://aisensy.com/products/{{product_name}}. Only alphabets with _ is allowed as parameters name." />
                      </Box>
                    </Box>
                    <TextFieldWithBorderComponent
                      placeholder=""
                      label="Source Url"
                      name="sourceUrl"
                      value={field.sourceUrl}
                      onChange={onUrlFieldChange(field.id, "sourceUrl")}
                    />
                    <Typography
                      sx={{
                        color: "#6E6E6E",
                        fontSize: "12px",
                        fontWeight: "400",
                      }}
                    >
                      Parameters - {"{{ page_url }} & {{ page_title }}"}
                    </Typography>
                  </Box>

                  <Box>
                    <Box className={classes.flexDirection}>
                      <Typography
                        variant="body1"
                        className={classes.blackColor}
                        sx={{ fontSize: { xs: 12, md: 14 }, mb: 1 }}
                      >
                        Pre-filled Message
                      </Typography>
                      <Box>
                        <InfoIcon tooltip="User message to be pre-filled when directed to WhatsApp from the url." />
                      </Box>
                    </Box>
                    <TextFieldWithBorderComponent
                      placeholder=""
                      label="Pre-filled Message"
                      name="preFilledMessage"
                      value={field.preFilledMessage}
                      onChange={onUrlFieldChange(field.id, "preFilledMessage")}
                    />
                  </Box>

                  <Box>
                    <Box className={classes.flexDirection}>
                      <Typography
                        variant="body1"
                        className={classes.blackColor}
                        sx={{ fontSize: { xs: 12, md: 14 }, mb: 1 }}
                      >
                        On-screen Message
                      </Typography>
                      <Box>
                        <InfoIcon tooltip="Business message to be displayed in the widget based on the page url." />
                      </Box>
                    </Box>
                    <TextFieldWithBorderComponent
                      placeholder=""
                      label="Onscreen message"
                      name="onScreenMessage"
                      value={field.onScreenMessage}
                      onChange={onUrlFieldChange(field.id, "onScreenMessage")}
                    />
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 1,
                      marginTop: "10px",
                    }}
                  >
                    <Box className={classes.flexDirection}>
                      <Typography
                        variant="body1"
                        className={classes.blackColor}
                        sx={{ fontSize: { xs: 12, md: 14 }, mb: 1 }}
                      >
                        Format parameters values
                      </Typography>
                      <Box>
                        <InfoIcon tooltip="Format settings will be applied to each parameter value." />
                      </Box>
                    </Box>

                    <Box sx={{ display: "flex" }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={field.removeChecked}
                            onChange={onUrlFieldChange(
                              field.id,
                              "removeChecked"
                            )}
                            sx={{ "&.Mui-checked": { color: "#FFB800" } }}
                          />
                        }
                        label="Remove - & _"
                        sx={{ color: "#000", fontSize: "14px" }}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={field.capitalizeChecked}
                            onChange={onUrlFieldChange(
                              field.id,
                              "capitalizeChecked"
                            )}
                            sx={{ "&.Mui-checked": { color: "#FFB800" } }}
                          />
                        }
                        label="Capitalize first letter"
                        sx={{ color: "#000", fontSize: "14px" }}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default WhatsappUrlConfig;
