import React from "react";

const ChatUserEmailSvg = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.1663 4.6665H5.83301C4.90475 4.6665 4.01451 5.03525 3.35813 5.69163C2.70176 6.34801 2.33301 7.23825 2.33301 8.1665V19.8332C2.33301 20.7614 2.70176 21.6517 3.35813 22.308C4.01451 22.9644 4.90475 23.3332 5.83301 23.3332H22.1663C23.0946 23.3332 23.9848 22.9644 24.6412 22.308C25.2976 21.6517 25.6663 20.7614 25.6663 19.8332V8.1665C25.6663 7.23825 25.2976 6.34801 24.6412 5.69163C23.9848 5.03525 23.0946 4.6665 22.1663 4.6665ZM21.3847 6.99984L13.9997 12.5415L6.61468 6.99984H21.3847ZM22.1663 20.9998H5.83301C5.52359 20.9998 5.22684 20.8769 5.00805 20.6581C4.78926 20.4393 4.66634 20.1426 4.66634 19.8332V8.45817L13.2997 14.9332C13.5016 15.0846 13.7472 15.1665 13.9997 15.1665C14.2521 15.1665 14.4977 15.0846 14.6997 14.9332L23.333 8.45817V19.8332C23.333 20.1426 23.2101 20.4393 22.9913 20.6581C22.7725 20.8769 22.4758 20.9998 22.1663 20.9998Z"
        fill="#4B5A5A"
      />
    </svg>
  );
};

export default ChatUserEmailSvg;
