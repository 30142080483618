import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  IconButton,
  RadioGroup,
  FormControlLabel,
  Radio,
  MenuItem,
  Select,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIconSvg from "../../../assets/svgs/DeleteIconSvg";
import TextFieldWithBorderComponent from "../../common/TextFieldWithBorderComponent";
import { ContentState, EditorState } from "draft-js";

interface VariableAssignmentProps {
  arrayState?: any;
  editorState?: any;
  requestUrl?: string;
  bodyDescription?: string;
  expandedStep: number;
  variableType: number;
  workflowData: any[];
  variableOptions: any[];
  dictionaryOptions: any[];
  handleVariablesChange: (stepNumber: number, newVariables: any[]) => void;
  handleEditorStateChange: (
    stepNumber: number,
    newEditorState: any,
    type: string
  ) => void;
  // handleTextStateChange?: (stepNumber: number, newTextState: string) => void;
}

const VariableAssignmentComponent: React.FC<VariableAssignmentProps> = ({
  editorState,
  arrayState,
  requestUrl,
  bodyDescription,
  expandedStep,
  variableType,
  workflowData,
  variableOptions,
  dictionaryOptions,
  handleVariablesChange,
  handleEditorStateChange,
  // handleTextStateChange,
}) => {
  const [expanded, setExpanded] = useState(false);

  const handleAccordionChange = (
    event: React.SyntheticEvent,
    isExpanded: boolean
  ) => {
    setExpanded(isExpanded);
  };

  const handleVariableTypeChange = (index: number, newType: string) => {
    const updatedVariables = workflowData[expandedStep - 1]?.veriables.map(
      (v: any, i: number) =>
        i === index
          ? { ...v, referenceTableType: newType === "Variable" ? 0 : 1 }
          : v
    );
    handleVariablesChange(expandedStep, updatedVariables);
  };

  const handleVariableValueChange = (
    index: number,
    type: number,
    newValue: string
  ) => {
    // const updatedVariables = workflowData[expandedStep - 1]?.veriables?.map(
    //   (v: any, i: number) =>
    //     i === index && v?.type === variableType ? { ...v, value: newValue } : v
    // );
    const updatedVariables = workflowData[expandedStep - 1]?.veriables?.map(
      (v: any, i: number) => {
        // Find the correct variable by type in the filtered data
        const filteredIndex = workflowData[expandedStep - 1]?.veriables
          ?.filter((item: any) => item?.type === type)
          ?.findIndex((filteredVariable: any) => filteredVariable === v);

        // If the current variable matches the filtered variable and the index is correct, update the value
        if (filteredIndex === index && v?.type === type) {
          return { ...v, value: newValue };
        }

        // Otherwise, return the variable as-is
        return v;
      }
    );

    handleVariablesChange(expandedStep, updatedVariables);
  };

  const handleFallbackValueChange = (
    index: number,
    type: number,
    newValue: string
  ) => {
    // const updatedVariables = workflowData[expandedStep - 1]?.veriables.map(
    //   (v: any, i: number) =>
    //     i === index ? { ...v, fallbackValue: newValue } : v
    // );
    const updatedVariables = workflowData[expandedStep - 1]?.veriables?.map(
      (v: any, i: number) => {
        // Find the correct variable by type in the filtered data
        const filteredIndex = workflowData[expandedStep - 1]?.veriables
          ?.filter((item: any) => item?.type === type)
          ?.findIndex((filteredVariable: any) => filteredVariable === v);

        // If the current variable matches the filtered variable and the index is correct, update the value
        if (filteredIndex === index && v?.type === type) {
          return { ...v, fallbackValue: newValue };
        }

        // Otherwise, return the variable as-is
        return v;
      }
    );

    handleVariablesChange(expandedStep, updatedVariables);
  };

  const handleRemoveVariable = (index: number, type: number) => {
    // const updatedVariables = workflowData[expandedStep - 1]?.veriables
    //   .filter((_: any, i: number) => i !== index)
    //   .map((v: any, i: number) => ({ ...v, index: i }));
    // Find the variable with the correct type (assuming variableType is available)
    const filteredVariables = workflowData[expandedStep - 1]?.veriables?.filter(
      (v: any) => v?.type === type
    );

    // Find the actual index in the original veriables array
    const variableToRemove = filteredVariables[index];
    const actualIndex = workflowData[expandedStep - 1]?.veriables.findIndex(
      (v: any) => v === variableToRemove
    );

    // Remove the variable from the original veriables array
    const updatedVariables = workflowData[expandedStep - 1]?.veriables
      ?.filter((_: any, i: number) => i !== actualIndex)
      .map((v: any, i: number) => ({ ...v, index: i }));

    handleVariablesChange(expandedStep, updatedVariables);
    // console.log("array state", index, arrayState, updatedVariables);
    // Handle editorState removal
    if (editorState) {
      const content = editorState.getCurrentContent();
      const plainText = content.getPlainText();
      const newText = plainText.replace(`{{${index + 1}}}`, "");
      const newContent = ContentState.createFromText(newText);
      const newEditorState = EditorState.push(
        editorState,
        newContent,
        "remove-range"
      );
      handleEditorStateChange(expandedStep, newEditorState, "editor");
    }
    // Handle textState removal
    else if (requestUrl) {
      const newTextState = requestUrl?.replace(`{{${index + 1}}}`, "");
      handleEditorStateChange(expandedStep, newTextState, "requestUrl");
    } else if (arrayState?.length > 0) {
      const updatedArrayState: any = arrayState?.map((item: any) => {
        if (item?.value.includes(`{{${index + 1}}}`)) {
          // Replace the {{index}} variable in the array state's value
          return {
            ...item,
            value: item.value.replace(`{{${index + 1}}}`, ""),
          };
        }
        return item;
      });

      // Update arrayState with the modified value
      handleEditorStateChange(expandedStep, updatedArrayState, "headers");
    } else if (bodyDescription) {
      const newTextState = bodyDescription?.replace(`{{${index + 1}}}`, "");
      handleEditorStateChange(expandedStep, newTextState, "bodyDescription");
    }
  };
  // console.log(
  //   "variableType",
  //   variableType,
  //   workflowData[expandedStep - 1]?.veriables?.filter(
  //     (item: any) => item?.type === variableType
  //   )
  // );

  return (
    <Accordion expanded={expanded} onChange={handleAccordionChange}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: "14px",
            display: "flex",
            alignItems: "center",
          }}
        >
          Variables
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: "14px",
              color: "primary.main",
              marginLeft: 1,
              backgroundColor: "rgba(0, 0, 0, 0.1)",
              borderRadius: "20px",
              padding: "0 7px",
            }}
          >
            {/* {workflowData[expandedStep - 1]?.veriables?.length} */}
            {
              workflowData[expandedStep - 1]?.veriables?.filter(
                (item: any) => item.type === variableType
              )?.length
            }
          </Typography>
        </Typography>
      </AccordionSummary>

      <AccordionDetails>
        {workflowData[expandedStep - 1]?.veriables
          ?.filter((item: any) => item?.type === variableType)
          ?.map((variable: any, index: number) => (
            <Box
              key={index}
              sx={{
                mb: 2,
                border: "1px solid #e0e0e0",
                padding: 1,
                borderRadius: 1,
                position: "relative",
              }}
            >
              <IconButton
                onClick={() => handleRemoveVariable(index, variableType)}
                size="small"
                sx={{
                  position: "absolute",
                  top: 8,
                  right: 8,
                  color: "red",
                }}
              >
                <DeleteIconSvg />
              </IconButton>

              <Typography variant="subtitle1" sx={{ mb: 1 }}>
                Variable {index + 1}
              </Typography>

              <RadioGroup
                value="Variable"
                onChange={(e) =>
                  handleVariableTypeChange(index, e.target.value)
                }
                sx={{ mb: 1 }}
              >
                {variableOptions?.map((option) => (
                  <FormControlLabel
                    key={option.value}
                    value={option.value}
                    control={
                      <Radio
                        sx={{
                          "&.Mui-checked": { color: "green" },
                          "& .MuiSvgIcon-root": { fontSize: 14 },
                        }}
                      />
                    }
                    label={option.label}
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: "14px",
                      },
                    }}
                  />
                ))}
              </RadioGroup>

              <Select
                fullWidth
                value={variable.value}
                onChange={(e) =>
                  handleVariableValueChange(index, variableType, e.target.value)
                }
                displayEmpty
                sx={{
                  mb: 1,
                  height: "36px",
                  fontSize: 14,
                  borderRadius: "10px",
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "10px",
                  },
                }}
                size="small"
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 200,
                      overflowY: "auto",
                    },
                  },
                }}
              >
                <MenuItem disabled value="">
                  <Typography style={{ color: "#a8a7a7", fontSize: "14px" }}>
                    Select a variable
                  </Typography>
                </MenuItem>
                {dictionaryOptions?.map(({ key, value }: any) => (
                  <MenuItem sx={{ fontSize: 14 }} key={key} value={value}>
                    {key}
                  </MenuItem>
                ))}
              </Select>

              <TextFieldWithBorderComponent
                fullWidth
                placeholder=""
                name="fallBackValue"
                label="Enter fallback value"
                value={variable.fallbackValue}
                onChange={(e) =>
                  handleFallbackValueChange(index, variableType, e.target.value)
                }
                size="small"
                sx={{
                  height: "36px",
                  fontSize: "14px",
                  borderRadius: "10px",
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "10px",
                  },
                }}
              />
            </Box>
          ))}
      </AccordionDetails>
    </Accordion>
  );
};

export default VariableAssignmentComponent;
