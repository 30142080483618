import React from "react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import moment from "moment";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  Typography,
  InputLabel,
  Grid,
  FormControl,
  Select,
  MenuItem,
  InputAdornment,
  FormControlLabel,
  Switch,
  IconButton,
  // TextareaAutosize,
  Autocomplete,
  Checkbox,
  // ListSubheader,
  ListItemText,
  Popover,
  Chip,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import CloseSvg from "../../assets/svgs/CloseSvg";
import { makeStyles } from "@mui/styles";
import TextFeildWithBorderComponet from "../common/TextFieldWithBorderComponent";
import InfoIcon from "@mui/icons-material/Info";
import ChatEmojiIcon from "../../assets/svgs/ChatEmojiIcon";
import ChatFileIcon from "../../assets/svgs/ChatFileIcon";
import ChatTemplate from "../../assets/svgs/ChatTemplate";
import { bgColors } from "../../utils/bgColors";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs"; // { Dayjs }
import { useAppDispatch, useAppSelector } from "../../utils/redux-hooks";
import { createCampaign } from "../../redux/slices/Campaign/CreateCampaignSlice";
import EmojiPopover from "../InboxComponents/inboxDetailsComponents/EmojiPicker";
import CloseIconSvg from "../../assets/svgs/CloseIconSvg";
import { toastActions } from "../../utils/toastSlice";
import { LoadingButton } from "@mui/lab";
import FilterIconSvg from "../../assets/svgs/FilterIconSvg";
import LoadingComponent from "../common/LoadingComponent";
import TemplatePopUp from "../InboxComponents/inboxDetailsComponents/TemplatePopUp";
import { CAMPAIGN_API } from "../../Apis/Campaign/Campaign";
import { updateCampaign } from "../../redux/slices/Campaign/EditCampaignSlice";
import "moment-timezone";
import TemplatePreviewLayout from "../TemplateComponents/TemplateForm/templatePreviewLayout";
import { fetchTemplateById } from "../../redux/slices/Templates/TemplateById";
import { getCampaign } from "../../redux/slices/Campaign/GetCampaignSlice";
import CampaignIcon from "@mui/icons-material/Campaign";
import { getScheduledCampaign } from "../../redux/slices/Campaign/GetScheduledCampaignSlice";
import CloseIcon from "@mui/icons-material/Close";
import { getCampaignTitle } from "../../redux/slices/Campaign/GetCampaignTitleSlice";
import useDebouncedFetch from "../../utils/debounceHook";
// import { AnyARecord } from "dns";
import { getExpectedWalletDetails } from "../../redux/slices/Wallet/WalletSlice";
import { getCurrentDate } from "../../utils/functions";

const useStyles = makeStyles({
  popoverContainer: {
    padding: "16px",
    // width: "300px",
  },
  greenButton: {
    // backgroundColor: bgColors.green1,
    color: "#1976d2",
    fontWeight: "700",
    borderRadius: 12,
    paddingInline: "6px",
  },
  yellowButton: {
    // backgroundColor: bgColors.yellow2,
    color: "#ff9800",
    fontWeight: "700",
    borderRadius: 12,
    paddingInline: "6px",
  },
  redButton: {
    // backgroundColor: bgColors.red2,
    color: bgColors.red1,
    fontWeight: "700",
    borderRadius: 12,
    paddingInline: "6px",
  },
  darkGreenButton: {
    // backgroundColor: bgColors.green,
    color: "#4caf50",
    fontWeight: "700",
    borderRadius: 12,
    paddingInline: "6px",
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "16px",
  },
  title: {
    color: bgColors.black1,
    fontWeight: "normal",
  },
  closeButton: {
    color: bgColors.black1,
  },
  selectContainer: {
    marginBottom: "16px",
  },
  textColor: {
    color: "#3C3C3C",
    fontWeight: "500",
  },
  changePasswordContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    // cursor: "pointer",
  },
  updateButtonStyles: {
    backgroundColor: "#3C3C3C",
    color: "#ffffff",
    height: "40px",
    borderRadius: "8px",
    width: "100%",
  },
  blackColor: {
    color: "#303030 !important",
    fontWeight: "500 !important",
    marginBottom: "8px",
  },
  button: {
    backgroundColor: `#3C3C3C !important`,
    width: "100% !important",
    fontSize: "24px !important",
    fontWeight: "Semi Bold !important",
    borderRadius: "8px !important",
  },
  messageCountContainer: {
    border: "1px solid #cdcdcd",
    borderRadius: "12px",
    padding: "8px",
    // width: "10px",
    paddingBottom: 6,
    justifyContent: "center",
    alignItems: "center",
  },
  messageInnerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    // alignItems: "center",
  },
  messageCount: {
    backgroundColor: "#DBDBDB",
    borderRadius: "24px",
    padding: "3px",
    width: "40px",
    // color: "white",
    textAlign: "center",
  },
  grayColor: {
    color: "#4B5A5A !important",
    opacity: "60%",
    // padding:"5px"
  },
  iconStyles: {
    cursor: "pointer",
    // paddingLeft: "5px",
    // marginLeft:'385px'
  },
  cursor: {
    cursor: "pointer",
    fontSize: "10px",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  resetBtnStyles: {
    color: bgColors.green,
    border: `1px solid ${bgColors.green}`,
    borderRadius: "8px",
    width: "100px",
    height: "38px",
    padding: "5px",
    cursor: "pointer",
    backgroundColor: "#fff",
    "&:hover": {
      backgroundColor: "rgba(68, 71, 70, 0.08)",
    },
  },
  SaveChangesButton: {
    color: bgColors.green,
    border: `1px solid ${bgColors.green}`,
    borderRadius: "8px",
    width: "130px",
    height: "38px",
    padding: "5px",
    cursor: "pointer",
    backgroundColor: "#fff",
    "&:hover": {
      backgroundColor: "rgba(68, 71, 70, 0.08)",
    },
  },
  SelctAlloption: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.12) !important",
    // display: "flex !important",
    // flexDirection: "row",
    // justifyContent: "space-between !important",
    // alignItems: "center",
    // padding: "5px !important",
  },
  SelctAlloptionText: {
    display: "flex !important",
    flexDirection: "row",
    justifyContent: "space-between !important",
    alignItems: "center !important",
    width: "100% !important",
  },
  account: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "5px",
  },
  switch: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: "16px !important",
    color: `#3C3C3C !important`,
  },
  textField: {
    height: "10px !important",
  },
  dateField: {
    width: "135px",
    // '& .MuiInputBase-input[type="date"]': {
    //   padding: "15px",
    // },
    // "& .MuiIconButton-root": {
    //   marginLeft: "-8px",
    // },
  },
  timefield: {
    ".css-jv54yp-MuiList-root-MuiMultiSectionDigitalClockSection-root": {
      scrollBehavior: "auto",
      scrollbarWidth: "none !important",
    },

    ".css-1st8yxe-MuiPickersLayout-root .MuiPickersLayout-actionBar": {
      display: "none",
    },
  },
});

const EditCampaign = ({
  title,
  open,
  handleClose,
  data,
  setFilterData,
  setChatStatusFilter,
}: any) => {
  // console.log(title);
  // console.log(data);
  // console.log(open);
  const classes = useStyles();
  // console.log(data)
  const dispatch = useAppDispatch();

  const debouncedFetchCampaigns = useDebouncedFetch(getCampaignTitle, 0);

  const getContactSlice = useAppSelector(
    (state: any) => state?.inboxContactsData
  );
  const getContactStatus = getContactSlice?.status;
  const getContacts = getContactSlice?.data;
  const getContactsData = getContacts?.filter(
    (item: any) => item?.isOptIn === 1
  );
  // console.log("getContactsData", getContacts, getContactsData);
  // useEffect(() => {
  //   setDuplicateContactData(getContactsData);
  // }, [getContactsData]);

  const userInfoSlice = useAppSelector((state: any) => state.adminLogin);
  const userInfo = userInfoSlice?.data;
  // console.log(userInfo);
  const createCamapignStatus = useAppSelector(
    (state: any) => state.addCampaign.status
  );
  const accountData = useAppSelector((state: any) => state.accountData.data);
  // const getuserPermissionData = useAppSelector(
  //   (state) => state.getUserPermissions?.data
  // );

  const getCampaignByIdStatus = useAppSelector(
    (state: any) => state.getCampaignById.status
  );
  // console.log(getCampaignByIdStatus);

  // const templateIdData = useAppSelector((state) => state.templateByIdData?.data);
  // console.log(templateIdData)

  // const SelectAllOption = "SELECT_ALL";
  const tagsArray = useAppSelector(
    (state: any) => state.getContactTagsData.data
  );
  // const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [emojiPopoverOpen, setEmojiPopoverOpen] = useState(false);
  const [anchorElement, setAnchorElement] = useState(null);
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [anchorEl, setAnchorEl] = useState(null);
  // const [selectedFilter, setSelectedFilter] = useState("All Contacts");
  const [openDialog, setOpenDialog] = useState(false);
  // const [duplicateContactData, setDuplicateContactData] = useState([]);

  // const [text, setText] = useState("");
  const [
    mediaType,
    // setMediaType
  ] = useState("");
  const [filters, setFilter] = useState<any>("");
  const [statusFilter, setStatusFilter] = useState<any>("");
  const [openAutocomplete, setOpenAutocomplete] = useState(false);
  // console.log(openAutocomplete)
  // const [iconColor, setIconColor] = useState("rgba(0, 0, 0, 0.35)");
  const [sendFileToApi, setSendFileToApi] = useState<any>(null);
  // console.log(sendFileToApi)
  const [isSwitchChecked, setSwitchChecked] = useState(false);
  // const [openTemplateDialog, setOpenTemplateDialog] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [templateData, setTemplateData] = useState<any>({});
  // console.log(templateData, "templateData");
  // const [templateMessagePayload, setTemplateMessagePayload] = useState<any>({});
  const [
    selectedFilter1,
    //  setSelectedFilter1
  ] = useState("All");
  const [
    searchCampaignQuery,
    //  setSearchCampaignQuery
  ] = useState<string>("");
  const [isEditLoading, setIsEditLoading] = useState(false);
  const [titleSearch, setTitleSearch] = useState("");
  const [formData, setFormData] = useState<any>({
    name: "",
    description: "",
    startDate: "",
    endDate: "",
    time: "",
    period: "",
    message: "",
    contacts: [],
    selectedDate: getCurrentDate(),
    setSelectedTime: dayjs("2024-04-24T12:00"),
  });
  // console.log(formData);

  const [formErrors, setFormErrors] = useState({
    name: "",
    description: "",
    message: "",
    contacts: "",
  });
  // console.log(titleSearch);
  // console.log(templateData);
  // console.log(templateMessagePayload);
  // console.log(fileInputRef)

  // const hasSendMessagePermission = (permission: any) => {
  //   for (const profileItem of permission) {
  //     if (profileItem.hasOwnProperty("sendMessageAndEmojies")) {
  //       return true;
  //     }
  //   }
  //   return false;
  // };

  const handleMainFilterClick = (event: any) => {
    setAnchorElement(event.currentTarget);
  };

  const handleCloseMainPopover = () => {
    setAnchorElement(null);
  };
  // console.log(formErrors);

  const chatStatus =
    formData?.contacts?.length > 0
      ? formData?.contacts?.every(
          (contact: any) => contact?.chatStatus === "open"
        )
      : [];
  // console.log(chatStatus)
  // console.log("hours", formData.setSelectedTime);

  const setSendTemplatePayload = async (
    templateMessagePayload: any,
    templateState: any
  ) => {
    // console.log(templateMessagePayload,'templateMessagePayload');
    // console.log(templateState,"templateState");

    if (templateState && templateMessagePayload) {
      await setTemplateData(templateState);
      // setFormData((prev) => ({
      //   ...prev,
      //   message: templateState.body,
      // }));
      // setTemplateMessagePayload(templateMessagePayload);
    }
    // handleSendTemplateMessage(templateMessagePayload, templateState);
    setOpenDialog(false);
    // console.log("payload", templateMessagePayload);
  };

  const templateDataById = async (params: any) => {
    // console.log("templateDataById",params)
    const res = await dispatch(fetchTemplateById(params));
    // console.log(res?.payload);
    const templateIdData = res?.payload;
    // console.log(templateIdData,'templateIdData')
    setTemplateData(templateIdData[0]);
  };

  useEffect(() => {
    if (data && title !== "Add") {
      setSwitchChecked(true);
      const searchContactId =
        getContactsData?.length > 0
          ? getContactsData?.filter((contact: any) =>
              data?.audiance?.includes(contact?.contactId)
            )
          : [];
      // console.log(searchContactId);
      const utcDateTime = data?.dateSetLive;
      const localDateTime = moment.utc(utcDateTime).local();
      const localDate = localDateTime.format("YYYY-MM-DD");
      // const localTime = localDateTime.format("HH:mm A"); // For 24-hour format with AM/PM
      const istDateTime = moment.utc(utcDateTime).tz("Asia/Kolkata");

      // console.log("now", dayjs(utcDateTime));
      // const istTime = istDateTime.format("h:mm A");
      // console.log("local", typeof istDateTime.format());
      setFormData({
        ...formData,
        name: data?.campaignTitle,
        contacts: searchContactId,
        message: data?.sendTextType,
        selectedDate: localDate,
        setSelectedTime: dayjs(istDateTime.format()),
      });
      // if (data?.templateId) {
      //   setTemplateData()
      // }
      if (data?.templateId) {
        const params = {
          userId: data?.userId,
          businessId: data?.businessId,
          templateId: data?.templateId,
        };
        // dispatch(fetchTemplateById(params));
        // console.log(res?.payload)
        // setTemplateData(templateIdData[0])
        templateDataById(params);
      }
      // console.log("called");
      setSelectedFile(data?.mediaUrl || null);
      // setMediaType(data[0]?.conversations[0]?.mediaMimeType || "")
    }
  }, [data, title, getContactStatus]);

  const handleChange = (event: any, value: any) => {
    // console.log(value);
    setFormData({ ...formData, contacts: value });
    setFormTouched(true);
    // setFormErrors({ ...formErrors, contacts: "" });
  };

  // console.log("selectedFile", selectedFile);
  const handleCancel = useCallback(() => {
    setSelectedFile(null);
    setSendFileToApi(null);
  }, []);

  // const memoizedVideoPlayer = useMemo(() => {
  //   if (
  //     selectedFile &&
  //     (selectedFile.type?.startsWith("video/mp4") ||
  //       (typeof selectedFile === "string" &&
  //         (selectedFile.endsWith(".mp4") ||
  //           selectedFile.endsWith(".mov") ||
  //           selectedFile.endsWith(".mkv"))))
  //   ) {
  //     return (
  //       <Box sx={{ position: "relative", display: "inline-block" }}>
  //         <video
  //           controls
  //           src={
  //             typeof selectedFile === "string"
  //               ? selectedFile
  //               : URL.createObjectURL(selectedFile)
  //           }
  //           style={{ height: "100px", width: "200px" }}
  //         />
  //         <IconButton
  //           onClick={handleCancel}
  //           sx={{
  //             position: "absolute",
  //             top: 0,
  //             right: 0,
  //           }}
  //         >
  //           <CloseIconSvg />
  //         </IconButton>
  //       </Box>
  //     );
  //   }
  //   return null;
  // }, [selectedFile, handleCancel]);

  const memoizedMediaPlayer = useMemo(() => {
    if (selectedFile) {
      const isVideo =
        selectedFile.type?.startsWith("video/mp4") ||
        (typeof selectedFile === "string" &&
          (selectedFile.endsWith(".mp4") ||
            selectedFile.endsWith(".mov") ||
            selectedFile.endsWith(".mkv")));

      const isPDF =
        selectedFile.type === "application/pdf" ||
        (typeof selectedFile === "string" && selectedFile.endsWith(".pdf"));

      if (isVideo) {
        return (
          <Box sx={{ position: "relative", display: "inline-block" }}>
            <video
              controls
              src={
                typeof selectedFile === "string"
                  ? selectedFile
                  : URL.createObjectURL(selectedFile)
              }
              style={{ height: "100px", width: "200px" }}
            />
            <IconButton
              onClick={handleCancel}
              sx={{
                position: "absolute",
                top: 0,
                right: 0,
              }}
            >
              <CloseIconSvg />
            </IconButton>
          </Box>
        );
      }

      if (isPDF) {
        return (
          <Box sx={{ position: "relative", display: "inline-block" }}>
            <embed
              src={
                typeof selectedFile === "string"
                  ? selectedFile
                  : URL.createObjectURL(selectedFile)
              }
              type="application/pdf"
              style={{ height: "100px", width: "200px" }}
            />
            <IconButton
              onClick={handleCancel}
              sx={{
                position: "absolute",
                top: 0,
                right: 0,
              }}
            >
              <CloseIconSvg />
            </IconButton>
          </Box>
        );
      }
    }
    return null;
  }, [selectedFile, handleCancel]);

  useEffect(() => {
    // console.log("called");
    const uploadFile = async () => {
      if (selectedFile && typeof selectedFile !== "string" && !sendFileToApi) {
        setIsUploading(true);
        try {
          setIsEditLoading(true);
          const data = { mediaFile: selectedFile };
          const getRes = await CAMPAIGN_API.uploadCampaignMedia(data);
          setSendFileToApi(getRes);
        } catch (error) {
          console.error("Error uploading file:", error);
        } finally {
          setIsUploading(false);
          setIsEditLoading(false);
        }
      }
    };

    uploadFile();
  }, [selectedFile, sendFileToApi]);

  const handleChangeFilters =
    () =>
    // filterName: string
    (event: any) => {
      // console.log(event, "event");
      setFilter(event.target.value);
    };

  const handleChangeStatusFilter =
    () =>
    // filterName: string
    (event: any) => {
      // console.log(event, "eventevent");
      setStatusFilter(event.target.value);
    };

  const handleCloseAll = () => {
    handleClose();
    setSelectedFile(null);
    setSwitchChecked(false);
    setFormData({
      name: "",
      description: "",
      startDate: "",
      endDate: "",
      time: "",
      period: "",
      message: "",
      contacts: [],
      selectedDate: getCurrentDate(),
      setSelectedTime: dayjs("2024-04-24T12:00"),
    });
    setFormErrors({
      name: "",
      description: "",
      message: "",
      contacts: "",
    });
    setSelectedFile(null);
    setTemplateData({});
    // setTemplateMessagePayload({});
    setFormTouched(false);
    // setValidCampaignMessage("");
  };

  // const maxLength = 1024;
  // const handleTextChange = (event: any) => {
  //   setText(event.target.value);
  // };

  const handleDateChange = (event: any) => {
    const newDate = event.target.value;
    setFormData({ ...formData, selectedDate: newDate });
  };

  const handleTimeChange = (newValue: any) => {
    setFormData({ ...formData, setSelectedTime: newValue });
  };

  const handleOpenDialog1 = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const isValidForm = () => {
    const errors = { ...formErrors };
    let isValid = true;

    if (!formData?.name.trim()) {
      errors.name = "Campaign name is required";
      isValid = false;
    }
    // else if (formData?.name?.trim().length > 30) {
    //   errors.name = "Campaign name cannot be more than 30 characters.";
    //   isValid = false;
    // }
    else if (searchStatus === "failed") {
      errors.name = searchResult;
      isValid = false;
    } else {
      errors.name = ""; // Clear any previous error
    }

    if (!formData?.message.trim() && !templateData?.templateId) {
      errors.message = "Message is required.";
      isValid = false;
    } else {
      errors.message = ""; // Clear any previous error
    }

    if (!formData?.contacts?.length) {
      errors.contacts = "Contact is required.";
      isValid = false;
    } else {
      errors.contacts = ""; // Clear any previous error
    }

    setFormErrors(errors);

    return isValid;
  };

  // console.log("data?.campaignId", data?.campaignId);

  const handleSave = async () => {
    // Add your save logic here
    // console.log(isValidForm())
    // console.log("now calling");
    setIsEditLoading(true);
    // console.log(isValidForm());
    if (isValidForm()) {
      // console.log("todayyy");
      // console.log(isValidForm());
      if (isUploading) {
        dispatch(
          toastActions.setToaster({
            type: "error",
            message: "File upload in progress. Please wait.",
          })
        );
        return;
      }
      try {
        const getSelectedContats = formData?.contacts?.map(
          (contact: any) => contact?.contactId
        );
        // console.log(getSelectedContats)
        const dateTime = moment(
          `${formData?.selectedDate}T${
            typeof formData?.setSelectedTime !== "string" &&
            formData?.setSelectedTime?.format("HH:mm")
          }`
        )
          .utc()
          .format();
        // console.log("dateTime", dateTime);

        if (title === "Add") {
          const data = {
            businessId: userInfo?.companyId,
            userId: userInfo?.userId,
            audiance: getSelectedContats,
            campaignTitle: formData?.name?.trim(),
            sendTextType: formData?.message || null,
            templateId: templateData?.templateId || null,
            headerVariableValue:
              templateData?.variables
                ?.filter((variable: any) => variable?.type === "header")
                .map((variable: any) => ({
                  value: variable?.field,
                  fallBackValue: variable?.fallBackValue,
                }))[0] || null,

            bodyVariableValues:
              templateData?.variables
                ?.filter((variable: any) => variable?.type === "body")
                .map((variable: any) => ({
                  value: variable?.field,
                  fallBackValue: variable?.fallBackValue,
                })) || null,
            redirectUrlVariableValues:
              templateData?.variables
                ?.filter((variable: any) => variable?.type === "url Button")
                .map((variable: any) => ({
                  value: variable?.field,
                  fallBackValue: variable?.fallBackValue,
                })) || null,
            mediaFile: sendFileToApi?.data,
            scheduledDateTime: isSwitchChecked ? dateTime : null,
            // scheduleRepeater: 0,
          };
          // console.log(data)
          const getCampaignRes = await dispatch(createCampaign(data));
          // console.log(getCampaignRes?.payload)
          if (getCampaignRes?.meta?.requestStatus === "fulfilled") {
            handleClose();
            setFormErrors({
              name: "",
              description: "",
              message: "",
              contacts: "",
            });
            const data = {
              businessId: userInfo?.companyId,
              userId: userInfo?.userId,
              page: 1,
              perPage: 10,
              filters: {
                searching: {
                  value: searchCampaignQuery,
                },
                filtering: {
                  filterType: "and",
                  conditions: [
                    {
                      column: "dateSetLive",
                      operator: selectedFilter1,
                      value: new Date(),
                    },
                  ],
                },
              },
            };
            // dispatch(getCampaign(data));
            dispatch(getCampaign(data));
            dispatch(
              getScheduledCampaign({
                businessId: userInfo?.companyId,
                userId: userInfo?.userId,
                page: 1,
                perPage: 40,
              })
            );
            dispatch(
              toastActions.setToaster({
                type: "success",
                message:
                  getCampaignRes?.payload?.message ===
                  "Campaign successfully sent."
                    ? getCampaignRes?.payload?.message
                    : "Campaign created successfully",
              })
            );
            dispatch(getExpectedWalletDetails(userInfo?.companyId));
            setFormTouched(false);
            // setSelectAllChecked(false);
            setSelectedFile(null);
            setSendFileToApi(null);
            setFormData({
              name: "",
              description: "",
              startDate: "",
              endDate: "",
              time: "",
              period: "",
              message: "",
              contacts: [],
              selectedDate: getCurrentDate(),
              setSelectedTime: dayjs("2024-04-24T12:00"),
            });
            setTemplateData({});
            // setTemplateMessagePayload({});
          } else {
            dispatch(
              toastActions.setToaster({
                type: "error",
                message: getCampaignRes?.payload?.data?.message,
              })
            );
          }
        } else {
          const data1 = {
            businessId: userInfo?.companyId,
            userId: userInfo?.userId,
            audiance: getSelectedContats,
            campaignId: data?.campaignId,
            campaignTitle: formData?.name?.trim(),
            sendTextType: formData?.message || null,
            templateId: templateData?.templateId || null,
            headerVariableValue:
              templateData?.variables
                ?.filter((variable: any) => variable?.type === "header")
                .map((variable: any) => ({
                  value: variable?.field,
                  fallBackValue: variable?.fallBackValue,
                }))[0] || null,

            bodyVariableValues:
              templateData?.variables
                ?.filter((variable: any) => variable?.type === "body")
                .map((variable: any) => ({
                  value: variable?.field,
                  fallBackValue: variable?.fallBackValue,
                })) || null,
            redirectUrlVariableValues:
              templateData?.variables
                ?.filter((variable: any) => variable?.type === "url Button")
                .map((variable: any) => ({
                  value: variable?.field,
                  fallBackValue: variable?.fallBackValue,
                })) || null,
            mediaFile: sendFileToApi?.data,
            scheduledDateTime: isSwitchChecked ? dateTime : null,
            // scheduleRepeater: 0,
          };
          const getUpdateCampaignRes = await dispatch(
            updateCampaign({ campaignId: data?.campaignId, data1 })
          );
          // console.log("getUpdateCampaignRes", getUpdateCampaignRes);
          if (getUpdateCampaignRes?.meta?.requestStatus === "fulfilled") {
            handleClose();
            setFormErrors({
              name: "",
              description: "",
              message: "",
              contacts: "",
            });
            const data = {
              businessId: userInfo?.companyId,
              userId: userInfo?.userId,
              page: 1,
              perPage: 10,
              filters: {
                searching: {
                  value: searchCampaignQuery,
                },
                filtering: {
                  filterType: "and",
                  conditions: [
                    {
                      column: "dateSetLive",
                      operator: selectedFilter1,
                      value: new Date(),
                    },
                  ],
                },
              },
            };
            // dispatch(getCampaign(data));
            dispatch(getCampaign(data));
            dispatch(
              getScheduledCampaign({
                businessId: userInfo?.companyId,
                userId: userInfo?.userId,
                page: 1,
                perPage: 40,
              })
            );
            dispatch(
              toastActions.setToaster({
                type: "success",
                message:
                  getUpdateCampaignRes?.payload?.message ||
                  "Campaign updated successfully",
              })
            );
            dispatch(getExpectedWalletDetails(userInfo?.companyId));
            setFormTouched(false);
            // setSelectAllChecked(false);
            setSelectedFile(null);
            setSendFileToApi(null);
            setFormData({
              name: "",
              description: "",
              startDate: "",
              endDate: "",
              time: "",
              period: "",
              message: "",
              contacts: [],
              selectedDate: getCurrentDate(),
              setSelectedTime: dayjs("2024-04-24T12:00"),
            });
            setTemplateData({});
            // setTemplateMessagePayload({});
          } else {
            dispatch(
              toastActions.setToaster({
                type: "error",
                message: getUpdateCampaignRes?.payload?.data?.message,
              })
            );
          }
        }
        // console.log("get", getCampaignRes)
      } catch {
        // console.log("campaignError", error);
        dispatch(
          toastActions.setToaster({
            type: "error",
            message: "request failed with 404",
          })
        );
      }
    }
    setIsEditLoading(false);
  };

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSwitchChecked(event.target.checked);
  };
  const capitalizeFirstLetter = (string: any) => {
    try {
      return string.toString().charAt(0).toUpperCase() + string.slice(1);
    } catch {
      return "";
    }
  };

  const handleApplyFilter = () => {
    setFilterData(filters);
    setChatStatusFilter(statusFilter);
    setOpenAutocomplete(true);
    handleCloseMainPopover();
  };

  const handleResetFilter = () => {
    setFilter("");
    setStatusFilter("");
    setFilterData("");
    setChatStatusFilter("");
    handleCloseMainPopover();
  };

  // const handleSelectAllChange = (event: any) => {
  //   // const newSelectedContacts = event.target.checked ? options : [];
  //   // setSelectAllChecked(event.target.checked);
  //   // onChange(null, newSelectedContacts);
  // };
  // const getOptionLabel = (option: any) => option?.name || "";
  // const renderOption = (
  //   props: any,
  //   option: any,
  //   index: number,
  //   { selected }: { selected: any }
  // ) => {
  //   return (
  //     <MenuItem {...props} key={index}>
  //       {/* <Checkbox checked={selected} /> */}
  //       <div>
  //         <Typography>{option?.name}</Typography>
  //       </div>
  //       {/* <Typography>{option.contact}</Typography> */}
  //     </MenuItem>
  //   );
  // };

  // const handleCancel = () => {
  //   setSelectedFile(null);
  //   setSendFileToApi(null);
  // };
  const [formTouched, setFormTouched] = useState(false);
  // console.log(formTouched);
  const handleTextChange = (event: any) => {
    const { name, value } = event.target;
    // console.log(name, value);
    if (name === "name") {
      setTitleSearch(value);
    }
    // console.log(name, value);
    setFormTouched(true);
    setFormData((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));

    // isValidForm();
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleCloseEmojiPopover = () => {
    setEmojiPopoverOpen(false);
  };
  const handleEmojiClick = (event: any) => {
    setAnchorEl(event.currentTarget);
    setEmojiPopoverOpen(true);
  };

  const messageRef: any = useRef(null);
  const handleEmojiSelect = (emoji: string) => {
    const input = messageRef.current.querySelector("textarea");

    if (input && typeof input.setSelectionRange === "function") {
      const start = input.selectionStart;
      const end = input.selectionEnd;

      const newMessage =
        formData.message.slice(0, start) + emoji + formData.message.slice(end);

      setFormData((prevData: any) => ({
        ...prevData,
        message: newMessage,
      }));

      // Set the cursor position after the newly added emoji
      setTimeout(() => {
        input.setSelectionRange(start + emoji.length, start + emoji.length);
        input.focus();
      }, 0);
    }
  };

  const handleFileIconClick = () => {
    fileInputRef.current?.click();
    // setIconColor("#4B5A5A");
  };

  // const renderGroup = (params: any) => [
  //   <div key="group">{params.group}</div>,
  //   params.children,
  //   <MenuItem key="selectAll">
  //     <Checkbox checked={selectAllChecked} onChange={handleSelectAllChange} />
  //     Select All Contacts
  //   </MenuItem>,
  // ];

  // const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   console.log("event", event);

  //   const file = event.target.files && event.target.files[0];
  //   console.log(file)

  //   // console.log(
  //   //   "file",
  //   //   file.type ===
  //   //     "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  //   // );
  //   if (file && file.type.startsWith("image/")) {
  //     setSelectedFile(file);
  //     setIconColor("rgba(0, 0, 0, 0.35)");
  //   } else if (file && file.type.startsWith("video/mp4")) {
  //     setSelectedFile(file);
  //     setIconColor("rgba(0, 0, 0, 0.35)");
  //   } else if (file && file.type === "application/pdf") {
  //     setSelectedFile(file);
  //     setIconColor("rgba(0, 0, 0, 0.35)");
  //   } else if (file && file.type === "text/plain") {
  //     setSelectedFile(file);
  //     setIconColor("rgba(0, 0, 0, 0.35)");
  //   } else if (
  //     file &&
  //     file.type ===
  //     "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  //   ) {
  //     setSelectedFile(file);
  //     setIconColor("rgba(0, 0, 0, 0.35)");
  //   } else if (
  //     file &&
  //     file.type ===
  //     "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
  //   ) {
  //     setSelectedFile(file);
  //     setIconColor("rgba(0, 0, 0, 0.35)");
  //   } else {
  //     dispatch(
  //       toastActions.setToaster({
  //         type: "error",
  //         message: "Please select only PNG, MP4, or PDF files.",
  //       })
  //     );
  //     setIconColor("rgba(0, 0, 0, 0.35)");
  //   }

  //   if (fileInputRef.current) {
  //     fileInputRef.current.value = "";
  //   }
  // };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];

    const maxImageSize = 5 * 1024 * 1024; // 5MB in bytes
    const maxDocSize = 100 * 1024 * 1024; // 100MB in bytes
    const maxVideoSize = 16 * 1024 * 1024; // 16MB in bytes

    if (file) {
      const { type, size } = file;
      const validImageTypes = ["image/jpeg", "image/jpg", "image/png"];
      const validDocTypes = ["application/pdf"];
      const validVideoTypes = ["video/mp4"];

      if (
        (validImageTypes.includes(type) && size <= maxImageSize) ||
        (validDocTypes.includes(type) && size <= maxDocSize) ||
        (validVideoTypes.includes(type) && size <= maxVideoSize)
      ) {
        setSelectedFile(file);
        // setIconColor("rgba(0, 0, 0, 0.35)");
      } else {
        if (validImageTypes.includes(type) && size > maxImageSize) {
          alert("Image files should be 5MB or smaller.");
        } else if (validDocTypes.includes(type) && size > maxDocSize) {
          alert("Document files should be 100MB or smaller.");
        } else if (validVideoTypes.includes(type) && size > maxVideoSize) {
          alert("Video files should be 16MB or smaller.");
        } else {
          alert(
            "Invalid file type. Please select a JPEG, JPG, PNG, PDF, or MP4 file."
          );
        }
        // setIconColor("rgba(0, 0, 0, 0.35)");
      }
    } else {
      dispatch(
        toastActions.setToaster({
          type: "error",
          message: "Please select a valid file.",
        })
      );
      // setIconColor("rgba(0, 0, 0, 0.35)");
    }

    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleSelectAll = () => {
    if (formData.contacts.length === getContactsData?.length) {
      setFormData({ ...formData, contacts: [] });
    } else {
      setFormData({ ...formData, contacts: getContactsData });
      setFormErrors({ ...formErrors, contacts: "" });
    }
  };

  // const options =
  //   getContactsData?.length > 0
  //     ? [
  //         { name: "Select All Contacts", contact: "", selectAll: true },
  //         ...getContactsData,
  //       ]
  //     : [];

  // console.log(options)

  const filterOptions = (options: any, inputValue: any) => {
    // console.log(options, inputValue);
    const filterValue = inputValue.toLowerCase().trim();
    return options.filter(
      (option: any) =>
        option.name.toLowerCase().includes(filterValue) ||
        option.contact.includes(filterValue)
    );
  };

  const handleChipClick = () => {
    setOpenAutocomplete(true);
  };
  // Helper function to determine if an option is selected
  const isOptionSelected = (option: any) => {
    // console.log(option);
    return formData?.contacts?.some(
      (contact: any) => contact.contact === option.contact
    );
  };
  // console.log(isOptionSelected)

  const [existingCampaignName, setExistingCampaignName] = useState(true);
  // console.log(existingCampaignName);
  // const [validCampaignMessage, setValidCampaignMessage] = useState("");
  // console.log(validCampaignMessage);
  useEffect(() => {
    const fetchData = async () => {
      const data = {
        businessId: userInfo?.companyId,
        campaignTitle: titleSearch,
      };

      await debouncedFetchCampaigns(data);
    };

    if (title === "Edit") {
      if (formData?.name?.trim() !== data?.campaignTitle) {
        // console.log("calling");
        setExistingCampaignName(true);
        fetchData();
        // setValidCampaignMessage(searchResult?.message);
      } else {
        setFormErrors({ ...formErrors, name: "" });
        // console.log("called");
        setExistingCampaignName(false);
      }
    } else {
      if (titleSearch && title !== "Edit") {
        fetchData();
        // setValidCampaignMessage(searchResult?.message);
      }
    }
  }, [titleSearch]);

  const searchResult = useAppSelector(
    (state: any) => state?.getCampaignTitle?.data
  );
  // console.log(searchResult);
  const searchStatus = useAppSelector(
    (state: any) => state?.getCampaignTitle?.status
  );
  // console.log(searchStatus);

  useEffect(() => {
    if (formTouched) {
      // console.log("second");
      isValidForm();
    }
  }, [formData, searchStatus]);
  return (
    <Dialog
      open={open}
      onClose={handleCloseAll}
      PaperProps={{ style: { minWidth: "40%", borderRadius: "20px" } }}
    >
      <DialogTitle>
        <Box m={2} mb={0} className={classes.changePasswordContainer}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h5"
              style={{
                color: "#000000",
                fontWeight: "600",
                fontSize: "20px",
                display: "flex",
              }}
            >
              <span style={{ marginTop: "2px" }}>
                <CampaignIcon sx={{ height: "22px" }} />
              </span>{" "}
              &nbsp; {title} Campaign
            </Typography>
            <Tooltip
              title={
                <>
                  <div>
                    1. We can only send message and media campaigns to contacts
                    with chat status open only.
                  </div>
                  <div>
                    2. We can only send campaigns to contacts with OptIn status
                    active only.
                  </div>
                </>
              }
              arrow
            >
              <IconButton size="small">
                <InfoIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>
          <Box onClick={handleCloseAll} sx={{ cursor: "pointer" }}>
            <CloseSvg />
          </Box>
        </Box>
      </DialogTitle>

      <DialogContent>
        {getContactStatus === "loading" ||
        getCampaignByIdStatus === "loading" ? (
          <>
            {title === "Add" ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "40px",
                  height: "300px",
                }}
              >
                <LoadingComponent height="auto" color={bgColors?.blue} />
                <Typography sx={{ fontSize: 12 }}>
                  Loading contacts...
                </Typography>
              </Box>
            ) : (
              <LoadingComponent height="300px" color={bgColors?.blue} />
            )}
          </>
        ) : (
          <>
            <Box m={2} mt={0}>
              <Box mb={2}>
                <InputLabel className={classes.blackColor}>Audience</InputLabel>
                <Autocomplete
                  multiple
                  id="multi-contact-select"
                  options={
                    getContactsData?.length > 0
                      ? [
                          {
                            name: "Select All Contacts",
                            contact: "",
                            selectAll: true,
                          },
                          ...getContactsData,
                        ]
                      : []
                  }
                  disableCloseOnSelect
                  open={openAutocomplete}
                  onOpen={() => setOpenAutocomplete(true)}
                  onClose={() => setOpenAutocomplete(false)}
                  getOptionLabel={(option) =>
                    `${option.name} (${option.contact})`
                  }
                  // loading={getContactStatus === "loading" ? true : false}
                  // loading={getContactStatus === "loading"}
                  // filterOptions={filterOptions}
                  filterOptions={(options, { inputValue }) =>
                    filterOptions(options, inputValue)
                  }
                  renderOption={(props, option, { selected }) => (
                    <MenuItem
                      {...props}
                      onClick={() => {
                        console.log(props, option, selected);
                        if (option.selectAll) {
                          handleSelectAll();
                        } else {
                          // const newSelectedContacts: any = isOptionSelected(
                          //   option
                          // )
                          //   ? formData?.contacts?.filter(
                          //       (contact: any) => contact !== option
                          //     )
                          //   : [...formData?.contacts, option];
                          const contacts = formData?.contacts || [];
                          const newSelectedContacts: any = isOptionSelected(
                            option
                          )
                            ? contacts.filter(
                                (contact: any) => contact !== option
                              )
                            : [...contacts, option];

                          setFormData({
                            ...formData,
                            contacts: newSelectedContacts,
                          });
                          setFormErrors({ ...formErrors, contacts: "" });
                        }
                      }}
                      className={option.selectAll ? classes.SelctAlloption : ""}
                    >
                      {getContactsData?.length ? (
                        <div
                          style={{
                            alignItems: "center",
                            display: "flex",
                            width: "100%",
                            // backgroundColor:'red',
                            height: "20px",
                            fontSize: "14px",
                          }}
                        >
                          <Checkbox
                            checked={
                              option.selectAll
                                ? formData?.contacts?.length ===
                                  getContactsData?.length
                                : isOptionSelected(option)
                            }
                            sx={{ marginRight: 1, transform: "scale(0.6)" }}
                          />
                          <Box
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            {/* <ListItemText
                          className={
                            option.selectAll ? classes.SelctAlloptionText : ""
                          }
                          primary={option?.name}
                          secondary={
                            <span
                              className={
                                option.selectAll ? classes.messageCount : ""
                              }
                            >
                              {option?.contact?.length > 0
                                ? option?.contact
                                : getContactsData?.length}
                            </span>
                          }
                        /> */}
                            <Box
                              className={
                                option.selectAll
                                  ? classes.SelctAlloptionText
                                  : ""
                              }
                            >
                              {option?.name} &nbsp;
                              <span
                                className={
                                  option.selectAll ? classes.messageCount : ""
                                }
                              >
                                {option?.contact?.length > 0
                                  ? `+${option?.contact}`
                                  : getContactsData?.length}
                              </span>
                            </Box>
                          </Box>
                          <Box
                            // ml={1}
                            className={
                              option?.chatStatus === "open"
                                ? classes?.greenButton
                                : option?.chatStatus === "expired"
                                ? classes?.yellowButton
                                : option?.chatStatus === "new"
                                ? classes?.darkGreenButton
                                : classes?.redButton
                            }
                          >
                            <Typography
                              sx={{ fontSize: "10px" }}
                              alignItems="center"
                            >
                              {capitalizeFirstLetter(option?.chatStatus)}
                            </Typography>
                          </Box>
                        </div>
                      ) : (
                        <ListItemText primary={"No Contacts"} />
                      )}
                    </MenuItem>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select contacts"
                      variant="outlined"
                      // placeholder=""
                      error={!!formErrors?.contacts}
                      helperText={formErrors?.contacts}
                      size="small"
                      sx={{
                        fontSize: 14,
                        borderColor: "gray",
                        width: "100%",
                        "& input": {
                          // fontWeight: "600",
                        },
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "8px",
                        },
                        "& .MuiFormLabel-root-MuiInputLabel-root": {
                          backgroundColor: "#fff",
                        },
                        "& .MuiFormLabel-root": {
                          backgroundColor: "#fff",
                          padding: "0px 4px 0px 0px",
                        },
                        position: "relative",
                      }}
                      InputLabelProps={{ style: { fontSize: "14px" } }}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {params.InputProps.endAdornment}
                            <IconButton
                              onClick={handleMainFilterClick}
                              color="success"
                              sx={{
                                position: "absolute",
                                right: "50px",
                                textTransform: "none",
                                height: "20px",
                              }}
                            >
                              <FilterIconSvg />
                            </IconButton>
                          </>
                        ),
                      }}
                    />
                  )}
                  renderTags={(value, getTagProps) => {
                    // console.log("called");
                    const displayedTags = value
                      .slice(0, 2)
                      .map((option: any, index: number) => {
                        const tagProps = getTagProps({ index });
                        return (
                          // <Chip label={option.name} {...getTagProps({ index })} />
                          <Chip
                            {...tagProps} // Spread tagProps first
                            key={index} // Provide the key manually after spreading tagProps
                            label={option.name}
                          />
                        );
                      });
                    if (value.length > 2) {
                      displayedTags.push(
                        <Chip
                          key="more"
                          label={`+${value.length - 2} more`}
                          onClick={handleChipClick}
                        />
                      );
                    }
                    return displayedTags;
                  }}
                  noOptionsText={
                    accountData?.companyVerificationStatus
                      ? "No Contacts"
                      : "Please verify your account with meta to start campaigning"
                  }
                  value={formData?.contacts}
                  onChange={handleChange}
                />
              </Box>

              <Box mb={2} mt={2}>
                <InputLabel className={classes.blackColor}>
                  Campaign Title
                </InputLabel>
                <TextFeildWithBorderComponet
                  // style={{ }}
                  label="Enter your campaign title"
                  name="name"
                  placeholder=""
                  value={formData?.name}
                  // error={!!formErrors.name}
                  error={
                    title === "Edit"
                      ? !!formErrors.name &&
                        formData?.name !== data?.campaignTitle
                      : title === "Add" && !!formErrors.name
                  }
                  // helperText={formErrors?.name}
                  helperText={
                    searchStatus === "idle" ||
                    searchStatus === "loading" ||
                    formData?.name === data?.campaignTitle
                      ? ""
                      : formErrors?.name ||
                        (formData?.name &&
                          formErrors.name === "" &&
                          formTouched === true &&
                          existingCampaignName === true &&
                          searchResult?.message)
                    // validCampaignMessage
                  }
                  onChange={handleTextChange}
                  sx={{
                    "& input": {
                      // fontWeight: "600",
                    },
                    "& .MuiFormHelperText-root": {
                      color: formErrors.name ? "red" : "green", // Color based on success or error
                    },
                  }}
                  InputProps={{
                    sx: { fontSize: 14 },
                    endAdornment: (
                      <>
                        {searchStatus === "loading" && (
                          <CircularProgress size={20} />
                        )}
                      </>
                    ),
                  }}
                />
              </Box>

              {formData?.contacts?.length === 0 || chatStatus ? (
                <Box mb={2} mt={2}>
                  <InputLabel className={classes.blackColor}>
                    Text Message
                  </InputLabel>

                  {selectedFile && (
                    <Box
                      sx={{
                        position: "relative",
                        alignItems: "center",
                        bottom: "0px",
                      }}
                    >
                      {(selectedFile?.type?.startsWith("image/") ||
                        (typeof selectedFile === "string" &&
                          selectedFile?.endsWith(".png")) ||
                        (typeof selectedFile === "string" &&
                          selectedFile?.endsWith(".jpg")) ||
                        (typeof selectedFile === "string" &&
                          selectedFile?.endsWith(".jpeg")) ||
                        (mediaType && mediaType?.startsWith("image/"))) && (
                        <Box
                          sx={{ position: "relative", display: "inline-block" }}
                        >
                          <img
                            src={
                              typeof selectedFile === "string"
                                ? selectedFile
                                : URL.createObjectURL(selectedFile)
                            }
                            alt="Selected File"
                            // style={{
                            //   maxHeight: 80,
                            //   maxWidth: "auto",
                            // }}
                            style={{ height: "100px", width: "200px" }}
                            // controls
                          />
                          <IconButton
                            onClick={handleCancel}
                            sx={{
                              position: "absolute",
                              top: 0,
                              right: 0,
                            }}
                          >
                            <CloseIconSvg />
                          </IconButton>
                        </Box>
                      )}
                      {/* {(selectedFile?.type?.startsWith("video/mp4") ||
                        (typeof selectedFile === "string" &&
                          selectedFile?.endsWith(".mp4")) ||
                        (typeof selectedFile === "string" &&
                          selectedFile?.endsWith(".mov")) ||
                        (typeof selectedFile === "string" &&
                          selectedFile?.endsWith(".mkv")) ||
                        (mediaType && mediaType?.startsWith("video/mp4"))) && (
                        <Box
                          sx={{ position: "relative", display: "inline-block" }}
                        >
                          <video
                            controls
                            src={
                              typeof selectedFile === "string"
                                ? selectedFile
                                : URL.createObjectURL(selectedFile)
                            }
                            style={{ height: "100px", width: "200px" }}
                          />
                          <IconButton
                            onClick={handleCancel}
                            sx={{
                              position: "absolute",
                              top: 0,
                              right: 0,
                            }}
                          >
                            <CloseIconSvg />
                          </IconButton>
                        </Box>
                      )} */}
                      {
                        // selectedFile.type === "application/pdf" ||
                        // selectedFile.type ===
                        //   "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                        // selectedFile.type ===
                        //   "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                        // selectedFile.type === "text/plain" ||
                        // ((mediaType && mediaType === "text/plain") ||
                        //   mediaType === "application/pdf" ||
                        //   mediaType ===
                        //     "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                        //   mediaType ===
                        //     "application/vnd.openxmlformats-officedocument.wordprocessingml.document") && (
                        (selectedFile?.type?.startsWith("application/pdf") ||
                          (typeof selectedFile === "string" &&
                            selectedFile?.endsWith(".pdf")) ||
                          (mediaType &&
                            mediaType?.startsWith("application/pdf"))) && (
                          <Box
                            p={1}
                            mr={4}
                            sx={{
                              width: "200px",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              position: "relative",
                              display: "inline-block",
                            }}
                          >
                            {/* <a
                            href={
                              typeof selectedFile === "string"
                                ? selectedFile
                                : URL.createObjectURL(selectedFile)
                            }
                            download={selectedFile.name}
                            style={{ fontSize: "14px" }}
                          >
                            {selectedFile.name}
                          </a> */}
                            {/* <iframe
                              src={
                                typeof selectedFile === "string"
                                  ? selectedFile
                                  : URL.createObjectURL(selectedFile)
                              }
                              
                              style={{ height: "100px", width: "200px" }}
                            ></iframe> */}
                            <IconButton
                              onClick={handleCancel}
                              sx={{
                                position: "absolute",
                                top: 0,
                                right: 0,
                              }}
                            >
                              <CloseIconSvg />
                            </IconButton>
                          </Box>
                        )
                      }
                    </Box>
                  )}
                  <div style={{ position: "relative", marginTop: "10px" }}>
                    {!templateData?.templateId ? (
                      <Grid item xs={12}>
                        {memoizedMediaPlayer}
                        <TextField
                          fullWidth
                          ref={messageRef}
                          variant="outlined"
                          size="small"
                          name="message"
                          multiline
                          rows={6}
                          value={formData?.message}
                          error={!!formErrors.message}
                          helperText={formErrors?.message}
                          onChange={handleTextChange}
                          // onKeyDown={handleKeyDown}
                          placeholder="Write a response..."
                          // placeholder={message.trim().length > 0 ? '' : ' '}
                          // className={`${classes.inputtest} custom-placeholder`}
                          sx={{
                            "& .MuiInputBase-root": {
                              backgroundColor: "#f3f3f3",
                              // minHeight: selectedFile ? 200 : 40,
                              padding: "2px",

                              alignItems: "flex-end",
                            },
                            "& .MuiOutlinedInput-root": {
                              "&:hover fieldset": {
                                // borderColor: "#4B5A5A", // Change the hover border color here
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "#4B5A5A", // Change the focused border color here
                              },
                            },
                            "& .MuiInputBase-input": {
                              "&::placeholder": {
                                color: "black", // Change the placeholder color here
                                fontFamily: "inherit",
                                // fontWeight:"100",
                                fontSize: "14px",
                              },
                            },
                            "& ::placeholder": {
                              // color: "#4B5A5A !important", // Change the placeholder color here
                              color: "black !important", // Change the placeholder color here
                            },
                          }}
                          InputProps={{
                            style: {
                              backgroundColor: "#f3f3f3",
                              // minHeight: selectedFile ? 400 : 40,
                              padding: "2px",
                            }, // Increase text field height
                            startAdornment: (
                              <InputAdornment position="start">
                                <Box
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <Box
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      position: "absolute",
                                      right: "10px",
                                      bottom: "5px",
                                    }}
                                    // ml={0.5}
                                    // mt={0.8}
                                  >
                                    <>
                                      <Box
                                        pr={1}
                                        className={classes.cursor}
                                        // onClick={(e) => {
                                        //   openDialog ? "" : handleEmojiClick(e);
                                        // }}
                                        onClick={(e) => {
                                          if (!openDialog) {
                                            handleEmojiClick(e);
                                          }
                                        }}
                                      >
                                        <ChatEmojiIcon
                                          //  fillColor="rgba(0, 0, 0, 0.35)"
                                          fillColor={
                                            openDialog ? "#cdcdcd" : "#4B5A5A"
                                          }
                                        />
                                      </Box>
                                      <EmojiPopover
                                        open={emojiPopoverOpen}
                                        anchorEl={anchorEl}
                                        onClose={handleCloseEmojiPopover}
                                        onEmojiSelect={handleEmojiSelect}
                                      />
                                      <Box
                                        pr={1}
                                        className={classes.cursor}
                                        // onClick={() => {
                                        //   openDialog
                                        //     ? ""
                                        //     : handleFileIconClick();
                                        // }}
                                        onClick={() => {
                                          if (!openDialog) {
                                            handleFileIconClick();
                                          }
                                        }}
                                      >
                                        <ChatFileIcon
                                          //  fill={iconColor}
                                          fill={
                                            openDialog ? "#cdcdcd" : "#4B5A5A"
                                          }
                                        />
                                        <input
                                          ref={fileInputRef}
                                          type="file"
                                          multiple
                                          accept=".pdf,.doc,.ppt,image/*,video/*"
                                          style={{ display: "none" }}
                                          onChange={handleFileChange}
                                        />
                                      </Box>
                                    </>

                                    <Box
                                      pr={1}
                                      className={
                                        formData?.message?.length === 0 &&
                                        (selectedFile === null ||
                                          selectedFile === undefined)
                                          ? classes.cursor
                                          : ""
                                      }
                                      // onClick={() => {
                                      //   formData?.message?.length === 0 &&
                                      //   (selectedFile === null ||
                                      //     selectedFile === undefined)
                                      //     ? handleOpenDialog1()
                                      //     : null;
                                      // }}
                                      onClick={() => {
                                        if (
                                          formData?.message?.length === 0 &&
                                          (selectedFile === null ||
                                            selectedFile === undefined)
                                        ) {
                                          handleOpenDialog1();
                                        }
                                      }}
                                    >
                                      <ChatTemplate
                                        // fillColor="rgba(0, 0, 0, 0.35)"
                                        fillColor={
                                          formData?.message?.length === 0 &&
                                          (selectedFile === null ||
                                            selectedFile === undefined)
                                            ? "#4B5A5A"
                                            : "#cdcdcd"
                                        }
                                      />
                                    </Box>
                                  </Box>
                                </Box>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    ) : (
                      <Grid item xs={12}>
                        <Box
                          sx={{
                            border: "1px solid #d3d3d3",
                            borderRadius: "10px",
                          }}
                        >
                          <IconButton
                            aria-label="close"
                            sx={{
                              position: "absolute",
                              top: "5px",
                              right: "5px",
                              zIndex: 1,
                            }}
                            onClick={() => {
                              setTemplateData({});
                            }}
                          >
                            <CloseIcon sx={{ fontSize: "20px" }} />
                          </IconButton>
                          <TemplatePreviewLayout
                            header={templateData?.header || ""}
                            body={templateData?.body || ""}
                            footer={templateData?.footer || ""}
                            mediaType={templateData?.mediaType || null}
                            // mediaFile={templateData?.mediaFile || null}
                            mediaFile={
                              templateData?.mediaAwsUrl ||
                              templateData?.mediaFile ||
                              null
                            }
                            buttons={templateData?.buttons || []}
                          />
                        </Box>
                      </Grid>
                    )}
                  </div>
                  {/* < TemplatePreviewLayout/> */}
                </Box>
              ) : (
                <Box>
                  {!templateData?.templateId ? (
                    <Button
                      sx={{
                        justifyContent: "center",
                        backgroundColor: bgColors?.gray2,
                        border: `0.5px solid ${bgColors?.gray5}`,
                        "&:hover": {
                          backgroundColor: bgColors?.gray5,
                          // color: "#ffffff",
                        },
                        color: bgColors?.green,
                        fontSize: 10,
                        mt: 2,
                        width: "100%",
                      }}
                      onClick={handleOpenDialog1}
                    >
                      Send Template
                    </Button>
                  ) : (
                    <>
                      <InputLabel className={classes.blackColor}>
                        Text Message
                      </InputLabel>
                      <Box
                        sx={{
                          border: "1px solid #d3d3d3",
                          borderRadius: "10px",
                        }}
                      >
                        <IconButton
                          aria-label="close"
                          // sx={{
                          //   position: "absolute",
                          //   top: "5px",
                          //   right: "5px",
                          //   zIndex: 1,
                          // }}
                          onClick={() => {
                            setTemplateData({});
                          }}
                        >
                          <CloseIcon sx={{ fontSize: "20px" }} />
                        </IconButton>
                        <TemplatePreviewLayout
                          header={templateData?.header || ""}
                          body={templateData?.body || ""}
                          footer={templateData?.footer || ""}
                          mediaType={templateData?.mediaType || null}
                          // mediaFile={templateData?.mediaFile || null}
                          mediaFile={
                            templateData?.mediaAwsUrl ||
                            templateData?.mediaFile ||
                            null
                          }
                          buttons={templateData?.buttons || []}
                        />
                      </Box>
                    </>
                  )}
                </Box>
              )}

              <TemplatePopUp
                open={openDialog}
                handleCloseTemplatePopup={handleCloseDialog}
                setSendTemplatePayload={setSendTemplatePayload}
                formData={formData}
              />
              <Box mt={3} className={classes.account}>
                <Box
                  display="flex"
                  flexDirection="row"
                  // justifyContent="space-between"
                >
                  <Box>
                    <Typography variant="body1" className={classes.switch}>
                      Schedule for Later
                    </Typography>
                  </Box>
                  <Box ml={2} style={{ marginTop: "-7px" }}>
                    <FormControlLabel
                      label=""
                      control={
                        <Switch
                          checked={isSwitchChecked}
                          onChange={handleSwitchChange}
                          color="success"
                          sx={{
                            "&.Mui-unchecked .MuiSwitch-thumb": {
                              color: "white",
                            },
                            "& .MuiSwitch-track": {
                              backgroundColor: "#3CAA93",
                            },
                            "&.Mui-checked .MuiSwitch-thumb": {
                              color: "green",
                            },
                            "&.Mui-checked + .MuiSwitch-track": {
                              backgroundColor: "green",
                            },
                          }}
                        />
                      }
                    />
                    <Box
                      ml={"-160px"}
                      mt={"20px"}
                      style={{
                        display: isSwitchChecked ? "flex" : "none",
                        flexDirection: "row",
                        gap: 20,
                      }}
                    >
                      <TextField
                        id="date"
                        label="Date"
                        type="date"
                        name="date"
                        // defaultValue="2024-04-24"
                        defaultValue={formData?.selectedDate}
                        onChange={handleDateChange}
                        inputProps={{
                          min: getCurrentDate(),
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        // className={classes.dateField}
                        sx={{
                          width: 145,
                          top: 7,
                          height: "35px", // set desired height here
                          "& .MuiInputBase-root": {
                            height: "100%",
                          },
                        }}
                      />
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={["TimePicker"]}>
                          <FormControl
                            sx={{
                              height: "25%",
                              width: "140px",
                              bottom: "0px",
                            }}
                          >
                            <TimePicker
                              label="Time"
                              className={classes.timefield}
                              // defaultValue={dayjs(formData.setSelectedTime)}
                              value={formData.setSelectedTime}
                              onChange={handleTimeChange}
                              minutesStep={1}
                              // ampm={false}
                              sx={{
                                height: "35px", // set desired height here
                                "& .MuiInputBase-root": {
                                  height: "100%",
                                  overflow: "hidden",
                                },
                              }}
                            />
                          </FormControl>
                        </DemoContainer>
                      </LocalizationProvider>
                    </Box>
                  </Box>
                  {/* {isSwitchChecked && (
                
                <Box style={{ display: "flex", flexDirection: "row" }}>
                  <Box>
                    <TextField
                      id="date"
                      label="Date"
                      type="date"
                      defaultValue="2023-02-02"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      className={classes.dateField}
                    />
                  </Box>
                  <Box ml={2} mb={-5} mt={-1}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={['TimePicker']}>
                        <FormControl
                          sx={{
                            height: "30%",
                            width: "150px"
                          }}
                        >
                          <TimePicker
                            label="Time"                       
                          />
                        </FormControl>
                      </DemoContainer>
                    </LocalizationProvider>
                  </Box>
                </Box>
              )} */}
                </Box>
              </Box>
            </Box>
            <Box>
              <Popover
                open={Boolean(anchorElement)}
                anchorEl={anchorElement}
                onClose={handleCloseMainPopover}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <Box className={classes.popoverContainer}>
                  <Box className={classes.headerContainer}>
                    <Typography
                      sx={{ fontSize: "14px" }}
                      className={classes.title}
                    >
                      Select filters
                    </Typography>
                    <IconButton
                      onClick={handleCloseMainPopover}
                      className={classes.closeButton}
                    >
                      {/* <ChatFilterCloseIconSvg /> */}
                      <CloseSvg />
                    </IconButton>
                  </Box>
                  <Box className={classes.selectContainer}>
                    <Typography
                      sx={{ fontSize: "14px" }}
                      mb={1}
                      className={classes.textColor}
                    >
                      Chat Status
                    </Typography>
                    <Select
                      value={statusFilter}
                      // onChange={handleChangeStatusFilter("chatStatus")}
                      onChange={handleChangeStatusFilter()}
                      fullWidth
                      variant="outlined"
                      size="small"
                      sx={{ fontSize: "12px" }}
                    >
                      {/* <MenuItem value="All" sx={{ fontSize: "12px" }}>
              All
            </MenuItem> */}
                      {/* Map through tagsArray and create MenuItem for each tag */}
                      {["open", "close"]?.length !== 0 ? (
                        ["All", "Open", "New", "Expired", "Resolved"]?.map(
                          (tag: any) => (
                            <MenuItem
                              key={tag?.id}
                              value={tag}
                              sx={{ fontSize: "12px" }}
                            >
                              {tag}
                            </MenuItem>
                          )
                        )
                      ) : (
                        <Typography sx={{ fontSize: "12px" }}>
                          No Data
                        </Typography>
                      )}
                    </Select>
                  </Box>
                  <Box className={classes.selectContainer}>
                    <Typography
                      sx={{ fontSize: "14px" }}
                      mb={1}
                      className={classes.textColor}
                    >
                      Tags
                    </Typography>
                    <Select
                      value={filters}
                      // onChange={handleChangeFilters("tags")}
                      onChange={handleChangeFilters()}
                      fullWidth
                      variant="outlined"
                      size="small"
                      sx={{ fontSize: "12px" }}
                    >
                      {/* <MenuItem value="All" sx={{ fontSize: "12px" }}>
              All
            </MenuItem> */}
                      {/* Map through tagsArray and create MenuItem for each tag */}
                      {tagsArray?.length !== 0 ? (
                        tagsArray?.map((tag: any) => (
                          <MenuItem
                            key={tag?.id}
                            value={tag?.id}
                            sx={{ fontSize: "12px" }}
                          >
                            {tag?.tag}
                          </MenuItem>
                        ))
                      ) : (
                        <Typography sx={{ fontSize: "12px" }}>
                          No Data
                        </Typography>
                      )}
                    </Select>
                  </Box>
                  <Box className={classes.buttonContainer}>
                    <Box m={2}>
                      <button
                        className={classes.resetBtnStyles}
                        onClick={handleResetFilter}
                      >
                        Reset
                      </button>
                    </Box>
                    <Box m={2}>
                      <button
                        className={classes.SaveChangesButton}
                        onClick={handleApplyFilter}
                      >
                        Apply Filters
                      </button>
                    </Box>
                  </Box>
                </Box>
              </Popover>
            </Box>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Grid item xs={12} mb={4} ml={4} mr={4}>
          {createCamapignStatus === "loading" || isEditLoading ? (
            <LoadingComponent height="auto" color={bgColors?.blue} />
          ) : (
            <LoadingButton
              //  loading={createCamapignStatus === "loading"}
              onClick={handleSave}
              // className={classes.button}
              sx={{
                backgroundColor: `${bgColors.black} !important`,
                height: "40px",
                color: "white !important",
                width: "100% !important",
                fontSize: "14px !important",
                fontWeight: "Semi Bold !important",
                borderRadius: "8px !important",
              }}
            >
              Start Campaign
            </LoadingButton>
          )}
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default EditCampaign;
