import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { WALLET_APIS } from "../../../Apis/Wallet/walletApi";
// import { MANAGE_SUBSCRIPTION_APIS } from "../../../Apis/Subscription/Subscription";

export interface IData {
  status: "loading" | "succeeded" | "failed" | "idle";
  Walletstatus: "loading" | "succeeded" | "failed" | "idle";
  expectedWalletstatus: "loading" | "succeeded" | "failed" | "idle";
  data: any;
  amount: any;
  expectedAmount: any;
  paymentStatus: any;
  transactionDataStatus: any;
  billingDetailsstatus: any;
  transactionData: any;
  walletInvoiceData: any;
  billingDetailsData: any;
  editBillingDetailsData: any;
  fetchAllDiscountsData: any;
  fetchDiscountByCodeData: any;
  fetchDiscountByCodeStatus: any;
  walletAndSubscription: any;
  walletAndSubscriptionStatus: any;
  fetchAllDiscountsStatus: any;
  isStatus: any;
}

const initialState: IData = {
  status: "idle",
  Walletstatus: "idle",
  expectedWalletstatus: "idle",
  data: null,
  amount: null,
  expectedAmount: null,
  paymentStatus: null,
  transactionDataStatus: null,
  billingDetailsstatus: null,
  transactionData: null,
  walletInvoiceData: null,
  billingDetailsData: null,
  editBillingDetailsData: null,
  fetchAllDiscountsData: null,
  fetchDiscountByCodeData: null,
  fetchDiscountByCodeStatus: null,
  walletAndSubscription: null,
  walletAndSubscriptionStatus: null,
  fetchAllDiscountsStatus: null,
  isStatus: "idle",
};

export const postWalletAmount = createAsyncThunk(
  "postWalletAmount",
  async (data: any, { rejectWithValue }) => {
    // console.log(data)
    try {
      const response = await WALLET_APIS.postAddAmount(data);
      // console.log("response", response);
      return response?.data;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || "An error occurred"
      );
    }
  }
);
export const getExpectedWalletDetails = createAsyncThunk(
  "getExpectedWalletDetails",
  async (companyId: any, { rejectWithValue }) => {
    // console.log(data)
    try {
      const response = await WALLET_APIS.getExpectedWalletDetails(companyId);
      // console.log("response", response);
      return response?.data;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || "An error occurred"
      );
    }
  }
);
export const getWalletDetails = createAsyncThunk(
  "getWalletDetails",
  async () => {
    // console.log(data)
    try {
      const response = await WALLET_APIS.getUserWalletDetails();
      // console.log("response", response);
      return response?.data;
    } catch (error: any) {
      console.log("error", error);
    }
  }
);
// export const getPaymentStatus = createAsyncThunk(
//   "getPaymentStatus",
//   async({ paymentId, orderId }: { paymentId: string; orderId: string })=>{
//     const response =await WALLET_APIS.getPaymentStatus(paymentId,orderId);
//     return response?.data;
//   }
// )
export const getPaymentDetails = createAsyncThunk(
  "getPaymentDetails",
  async ({ paymentId, orderId }: { paymentId: string; orderId: string }) => {
    const response = await WALLET_APIS.getPaymentStatus({ paymentId, orderId });
    return response?.data;
  }
);
//walletInvoice

export const walletTransactions = createAsyncThunk(
  "walletTransactions",
  async ({ startDate, endDate }: { startDate: string; endDate: string }) => {
    const response = await WALLET_APIS.walletTransactions({
      startDate,
      endDate,
    });
    return response?.data;
  }
);

export const walletInvoice = createAsyncThunk(
  "walletInvoice",
  async (userId: string) => {
    const response = await WALLET_APIS.walletInvoice(userId);
    return response?.data;
  }
);

export const billingDetails = createAsyncThunk(
  "billingDetails",
  async (companyId: string) => {
    const response = await WALLET_APIS.billingDetails(companyId);
    return response?.data;
  }
);

export const editBillingDetails = createAsyncThunk(
  "editBillingDetails",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await WALLET_APIS.editBillingDetails(data);
      // console.log("response", response);
      return response?.data;
    } catch (error: any) {
      // console.log(error)
      return rejectWithValue(
        error.response?.data?.message || "An error occurred"
      );
    }
  }
);

// export const getWalletAndSubscription = createAsyncThunk(
//   "getWalletAndSubscription",
//   async (companyId: string) => {
//     const response = await WALLET_APIS.getWalletAndSubscription(companyId);
//     return response;
//   }
// )

export const getWalletAndSubscription = createAsyncThunk(
  "getWalletAndSubscription",
  async (companyId: string, { rejectWithValue }) => {
    try {
      const response = await WALLET_APIS.getWalletAndSubscription(companyId);
      return response?.data;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || "An error occurred"
      );
    }
  }
);

export const fetchAllDiscounts = createAsyncThunk(
  "fetchAllDiscounts",
  async (_, { rejectWithValue }: any) => {
    try {
      const response = await WALLET_APIS.getAllDiscounts();
      return response?.data;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || "An error occurred"
      );
    }
  }
);

export const fetchDiscountByCode = createAsyncThunk(
  "fetchDiscountByCode",
  async (
    { discountCode, companyId }: { discountCode: any; companyId: any },
    { rejectWithValue }
  ) => {
    try {
      const response = await WALLET_APIS.getDiscountByCode({
        discountCode,
        companyId,
      });

      return response?.data;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || "An error occurred"
      );
    }
  }
);

export const WalletSlice = createSlice({
  name: "WalletSlice",
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(postWalletAmount.pending, (state) => {
        state.status = "loading";
        state.data = [];
      })
      .addCase(postWalletAmount.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(postWalletAmount.rejected, (state) => {
        state.status = "failed";
        state.data = [];
      })
      .addCase(getWalletDetails.pending, (state) => {
        state.Walletstatus = "loading";
        state.amount = [];
      })
      .addCase(getWalletDetails.fulfilled, (state, action) => {
        state.Walletstatus = "succeeded";
        state.amount = action.payload;
      })
      .addCase(getWalletDetails.rejected, (state) => {
        state.Walletstatus = "failed";
        state.amount = [];
      })
      .addCase(getExpectedWalletDetails.pending, (state) => {
        state.expectedWalletstatus = "loading";
        state.expectedAmount = [];
      })
      .addCase(getExpectedWalletDetails.fulfilled, (state, action) => {
        state.expectedWalletstatus = "succeeded";
        state.expectedAmount = action.payload;
      })
      .addCase(getExpectedWalletDetails.rejected, (state) => {
        state.expectedWalletstatus = "failed";
        state.expectedAmount = [];
      })
      .addCase(getPaymentDetails.pending, (state) => {
        state.isStatus = "loading";
        state.paymentStatus = [];
      })
      .addCase(getPaymentDetails.fulfilled, (state, action) => {
        state.isStatus = "succeeded";
        state.paymentStatus = action.payload;
      })
      .addCase(getPaymentDetails.rejected, (state) => {
        state.isStatus = "failed";
        state.paymentStatus = [];
      })
      .addCase(walletTransactions.pending, (state) => {
        state.transactionDataStatus = "loading";
        state.transactionData = [];
      })
      .addCase(walletTransactions.fulfilled, (state, action) => {
        state.transactionDataStatus = "succeeded";
        state.transactionData = action.payload;
      })
      .addCase(walletTransactions.rejected, (state) => {
        state.transactionDataStatus = "failed";
        state.transactionData = [];
      })
      .addCase(walletInvoice.pending, (state) => {
        state.status = "loading";
        state.walletInvoiceData = [];
      })
      .addCase(walletInvoice.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.walletInvoiceData = action.payload;
      })
      .addCase(walletInvoice.rejected, (state) => {
        state.status = "failed";
        state.walletInvoiceData = [];
      })
      .addCase(billingDetails.pending, (state) => {
        state.billingDetailsstatus = "loading";
        state.billingDetailsData = [];
      })
      .addCase(billingDetails.fulfilled, (state, action) => {
        state.billingDetailsstatus = "succeeded";
        state.billingDetailsData = action.payload;
      })
      .addCase(billingDetails.rejected, (state) => {
        state.billingDetailsstatus = "failed";
        state.billingDetailsData = [];
      })
      .addCase(editBillingDetails.pending, (state) => {
        state.status = "loading";
        state.editBillingDetailsData = [];
      })
      .addCase(editBillingDetails.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.editBillingDetailsData = action.payload;
      })
      .addCase(editBillingDetails.rejected, (state) => {
        state.status = "failed";
        state.editBillingDetailsData = [];
      })
      .addCase(getWalletAndSubscription.pending, (state) => {
        state.walletAndSubscriptionStatus = "loading";
        state.walletAndSubscription = [];
      })
      .addCase(getWalletAndSubscription.fulfilled, (state, action) => {
        state.walletAndSubscriptionStatus = "succeeded";
        state.walletAndSubscription = action.payload;
      })
      .addCase(getWalletAndSubscription.rejected, (state) => {
        state.walletAndSubscriptionStatus = "failed";
        state.walletAndSubscription = [];
      })
      .addCase(fetchAllDiscounts.pending, (state) => {
        state.fetchAllDiscountsStatus = "loading";
        state.fetchAllDiscountsData = [];
      })
      .addCase(fetchAllDiscounts.fulfilled, (state, action) => {
        state.fetchAllDiscountsStatus = "succeeded";
        state.fetchAllDiscountsData = action.payload;
      })
      .addCase(fetchAllDiscounts.rejected, (state) => {
        state.fetchAllDiscountsStatus = "failed";
        state.fetchAllDiscountsData = [];
      })
      .addCase(fetchDiscountByCode.pending, (state) => {
        state.fetchDiscountByCodeStatus = "loading";
        state.fetchDiscountByCodeData = [];
      })
      .addCase(fetchDiscountByCode.fulfilled, (state, action) => {
        state.fetchDiscountByCodeStatus = "succeeded";
        state.fetchDiscountByCodeData = action.payload;
      })
      .addCase(fetchDiscountByCode.rejected, (state) => {
        state.fetchDiscountByCodeStatus = "failed";
        state.fetchDiscountByCodeData = [];
      });
  },
});

export const SubscriptionActions = WalletSlice.actions;
export default WalletSlice.reducer;
