import React, { useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import { useAppDispatch } from "../../utils/redux-hooks";
import { toastActions } from "../../utils/toastSlice";
// import { toastActions, IToast } from "./your-toast-slice-file-path";

interface ToastComponentProps {
  type: string;
  message: string;
}

const ToastComponent: React.FC<ToastComponentProps> = ({ type = "", message = "" }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (type && message) {
      const timer = setTimeout(() => {
        dispatch(toastActions.setToaster({ type: "", message: "" }));
      }, 5000);
      
      return () => clearTimeout(timer); // Clear timeout on component unmount
    }
  }, [type, message, dispatch]);

  useEffect(() => {
    // Show toast message when component is mounted
    if (type && message) {
      showToast(type, message);
    }
  }, [type, message]);

  const showToast = (type: string, message: string) => {
    switch (type) {
      case "info":
        toast.info(message, getToastOptions());
        break;
      case "success":
        toast.success(message, getToastOptions());
        break;
      case "warning":
        toast.warn(message, getToastOptions());
        break;
      case "error":
        toast.error(message, getToastOptions());
        break;
      default:
        break;
    }
  };

  const getToastOptions: any = () => {
    return {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    };
  };

  return <ToastContainer />;
};

export default ToastComponent;
