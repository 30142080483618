import React from "react";
import { Box, Typography } from "@mui/material";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";

interface Button {
  reply: {
    id: string;
    title: string;
  };
}

interface ChatActionButtonsProps {
  buttons?: Button[];
  button?: string;
  bgColors: {
    green: string;
    white: string;
  };
}

const ChatActionButtons: React.FC<ChatActionButtonsProps> = ({
  buttons,
  button,
  bgColors,
}) => {
  return (
    <Box>
      {/* Render buttons in pairs if msg.action.buttons exists */}
      {buttons && (
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            marginTop: "1px", // Adjust margin as needed
          }}
        >
          {buttons?.map((buttonItem: Button, index: number) => (
            <Box
              key={index}
              sx={{
                flex:
                  buttons.length === 1
                    ? "1 1 100%" // Full width if only one button
                    : buttons.length <= 3
                    ? "1 1 calc(33.33% - 2px)" // One row if 3 or fewer buttons
                    : "1 1 calc(33.33% - 2px)",
                display: "flex",
                minWidth: "50px",
                maxWidth: "100%",
                justifyContent: "center",
                alignItems: "center",
                padding: "4px 8px",
                borderRadius: "4px",
                marginInline: "1px",
                color: bgColors?.green,
                backgroundColor: bgColors?.white, // Button background color
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  color: bgColors?.green, // Text color for the list view
                  fontSize: 12,
                }}
              >
                {buttonItem?.reply?.title}
              </Typography>
            </Box>
          ))}
        </Box>
      )}

      {/* Render list view if msg.action.button exists */}
      {button && (
        <Box
          sx={{
            flex: "0 1 calc(50% - 2px)", // Two buttons per row
            display: "flex",
            minWidth: "50px",
            justifyContent: "center",
            alignItems: "center",
            padding: "4px 8px",
            borderRadius: "4px",
            marginInline: "1px",
            color: bgColors?.green,
            backgroundColor: bgColors?.white, // Button background color
            marginTop: "1px",
          }}
        >
          <FormatListBulletedIcon
            sx={{ fontSize: 12, color: bgColors?.green }}
          />
          <Typography
            variant="body2"
            sx={{
              color: bgColors?.green, // Text color for the list view
              fontSize: 12,
            }}
          >
            {button}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default ChatActionButtons;
