/* global process */

import axios from "axios";

const USER_API_URL = process.env.REACT_APP_BASE_URL;

const getAllPermissions = (roleId: any, companyId: any) => {
  return axios({
    url: `${USER_API_URL}/permissions/get-permission-menus/${roleId}/${companyId}`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
  });
};

const postPermissions = (
  roleId: any,
  data: any,
  companyId: any,
  menuName: any,
  status: any
) => {
  return axios({
    url: `${USER_API_URL}/permissions/update-menu-status/${roleId}/${companyId}/?menuName=${menuName}&status=${status}`,
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
      "Cache-Control": "no-cache",
    },
    // data: data?.data,
    // data: JSON.stringify(data),
  });
};

const updateClientPermissions = (
  roleId: any,
  companyId: any,
  menuName: any,
  status: any
) => {
  return axios({
    url: `${USER_API_URL}/permissions/update-clientmenu-status/${roleId}/${companyId}/?menuName=${menuName}&status=${status}`,
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
      "Cache-Control": "no-cache",
    },
    // data: data?.data,
    // data: JSON.stringify(data),
  });
};

const getAllUserPermissions = (roleId: any, companyId: any) => {
  return axios({
    url: `${USER_API_URL}/permissions/get-user-menuPermissions?roleId=${roleId}&companyId=${companyId}`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
      "Cache-Control": "no-cache",
    },
  });
};

export const MANAGE_PERMISSIONS_APIS = {
  getAllPermissions,
  postPermissions,
  updateClientPermissions,
  getAllUserPermissions,
};
