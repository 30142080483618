import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { bgColors } from "../../utils/bgColors";
import EditIconSvg from "../../assets/svgs/EditIconSvg";
import WorkflowDialog from "./WorkflowDialog";
import DeleteIconSvg from "../../assets/svgs/DeleteIconSvg";
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from "../../utils/redux-hooks";
import { checkWorkflowsPermission } from "../../utils/permissions";
import NoAccessPage from "../../components/common/NoAccess";
// import { getWorkflowList } from "../../redux/slices/Workflows/getWorkflowListSlice";
import { toastActions } from "../../utils/toastSlice";
import LoadingComponent from "../../components/common/LoadingComponent";
import { deleteWorkflow } from "../../redux/slices/Workflows/deleteWorkflowSlice";
import { getWorkflowNames } from "../../redux/slices/Workflows/getWorkflowNamesSlice";
import DeletePopUp from "../../components/common/DeletePopup";
import { getWorkflow } from "../../redux/slices/Workflows/getWorkflowSlice";

const useStyles = makeStyles({
  mainContainer: {
    backgroundColor: bgColors.white,
    borderRadius: "25px",
    marginTop: "20px",
    width: "100%",
  },
  chatArea: {
    padding: "20px",
  },
  bgContainer: {
    backgroundColor: bgColors.white,
    borderRadius: "25px",
    height: "100%",
    width: "100%",
  },
  manageContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  blackColor: {
    color: `${bgColors.black1} !important`,
    fontWeight: "600 !important",
  },
  SaveChangesButton: {
    color: bgColors.green,
    border: `1px solid ${bgColors.green}`,
    borderRadius: "8px",
    width: "140px",
    height: "32px",
    cursor: "pointer",
  },
  textColor: {
    color: bgColors.gray1,
    fontSize: "16px",
  },
  templateBox: {
    backgroundColor: "#f5f5f5",
    borderRadius: "10px",
    padding: "20px",
    marginTop: "20px",
    borderLeft: `4px solid ${bgColors.green}`,
  },
  headerText: {
    fontWeight: "600 !important",
    marginBottom: "10px !important",
    fontSize: "15px",
  },
  tableContainer: {
    marginTop: "10px",
    boxShadow: "none",
    backgroundColor: "transparent",
  },
  table: {
    // borderCollapse: "separate",
    borderSpacing: "0",
    textAlign: "center",
    borderColor: "lightgray",
    "& th, & td": {
      borderBottom: "1px solid #f0f0f0",
      padding: "4px",
    },
    "& th:first-child, & td:first-child": {
      borderLeft: "none",
      // paddingLeft:'4px'
    },
    "& th:last-child, & td:last-child": {
      borderRight: "none",
    },
  },
  tableRow: {
    transition: "box-shadow 0.3s ease",
    "&:hover": {
      boxShadow:
        "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)",
    },
  },
  grayText: {
    color: bgColors.gray1,
  },
  workflowsCreatedBox: {
    marginTop: "30px",
  },
  workflowItem: {
    backgroundColor: "#fff",
    borderRadius: "5px",
    padding: "10px",
    marginBottom: "10px",
  },
  overlayLoader: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 9999,
  },
});

const WorkFlow = () => {
  const classes = useStyles();
  const userProfileSlice = useAppSelector((state: any) => state?.adminLogin);
  const userData = userProfileSlice?.data;
  const dispatch = useAppDispatch();
  const [isHovered, setIsHovered] = useState(false);
  const [isOpenWorkflowDialog, setOpenWorkflowDialog] = useState(false);
  const { workflowNamesData, workflowNamesStatus } = useAppSelector(
    (state: any) => state.getWorkflowNames
  );
  const { workflowData, workflowStatus } = useAppSelector(
    (state: any) => state.getWorkflow
  );
  const [convertedWorkFlowData, setConvertedWorkFlowData] = useState<any>(null);
  const [isWorkflowEditing, setIsWorkflowEditing] = useState<boolean>(false);
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
  const [workflowToDelete, setWorkflowToDelete] = useState<any>(null);
  const [isWorkflowDeleteLoading, setIsWorkflowDeleteLoading] = useState(false);


  const handleWorkflow = (workflow: any) => {
    setIsWorkflowEditing(true);
    const fetchWorkflows = async () => {
      const payload = {
        companyId: userData?.companyId,
        workflowName: workflow?.workflowName,
      };

      try {
        const result = await dispatch(getWorkflow(payload)).unwrap();
        if (result.success) {
          // Assuming you handle the data in Redux itself or you use it directly
          // dispatch(
          //   toastActions.setToaster({
          //     message: result.message,
          //     type: "success",
          //   })
          // );
          setOpenWorkflowDialog(true);
        } else {
          dispatch(
            toastActions.setToaster({
              message: result.message || "Failed to fetch workflow list",
              type: "error",
            })
          );
        }
      } catch (error: any) {
        // dispatch(
        //   toastActions.setToaster({
        //     message:
        //       error.message ||
        //       "An error occurred while fetching the workflow list",
        //     type: "error",
        //   })
        // );
      }
    };
    if (userData?.companyId) {
      fetchWorkflows();
    }
  };

  // console.log(workflowNamesData);

  useEffect(() => {
    const fetchWorkflowNames = async () => {
      const payload = {
        companyId: userData?.companyId,
      };

      try {
        const result = await dispatch(getWorkflowNames(payload)).unwrap();
        if (result.success) {
          // Assuming you handle the data in Redux itself or you use it directly
          // dispatch(
          //   toastActions.setToaster({
          //     message: result.message,
          //     type: "success",
          //   })
          // );
        } else {
          dispatch(
            toastActions.setToaster({
              message: result.message || "Failed to fetch workflow list",
              type: "error",
            })
          );
        }
      } catch (error: any) {
        // dispatch(
        //   toastActions.setToaster({
        //     message:
        //       error.message ||
        //       "An error occurred while fetching the workflow list",
        //     type: "error",
        //   })
        // );
      }
    };

    // Check if there's a companyId and fetch the list
    if (userData?.companyId) {
      fetchWorkflowNames();
    }
  }, [userData?.companyId]);

  const handleDeleteClick = (workflow: any) => {
    setWorkflowToDelete(workflow);
    setIsDeleteConfirmOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (workflowToDelete) {
      setIsWorkflowDeleteLoading(true);
      try {
        const payload = {
          companyId: userData?.companyId,
          workflowName: workflowToDelete.workflowName,
        };
        const result = await dispatch(deleteWorkflow(payload)).unwrap();
        setIsWorkflowDeleteLoading(false);
        if (result.success) {
          dispatch(
            toastActions.setToaster({
              message: "Workflow deleted successfully",
              type: "success",
            })
          );
          // Refresh the workflow list
          dispatch(getWorkflowNames({ companyId: userData?.companyId }));
        } else {
          dispatch(
            toastActions.setToaster({
              message: result.message || "Failed to delete workflow",
              type: "error",
            })
          );
          setIsWorkflowDeleteLoading(false);
        }
      } catch (error: any) {
        dispatch(
          toastActions.setToaster({
            message:
              error.message || "An error occurred while deleting the workflow",
            type: "error",
          })
        );
        setIsWorkflowDeleteLoading(false);
      }
    }
    setIsDeleteConfirmOpen(false);
    setWorkflowToDelete(null);
  };

  const getUserPermissionSlice = useAppSelector(
    (state: any) => state.getUserPermissions
  );
  const getUserPermissionData = getUserPermissionSlice?.data;

  const hasWorkflowsPermission = checkWorkflowsPermission(
    getUserPermissionData?.automation
  );

  useEffect(() => {
    setConvertedWorkFlowData(Object.values(workflowData || {})[0] || null);
  }, [workflowData]);

  const handleWorkflowDialog = () => {
    // setWorkflowData([]);
    setConvertedWorkFlowData(null);
    setOpenWorkflowDialog(true);
  };

  // const templateWorkflows = [
  //   "Lead Gen - Agency",
  //   "Lead Gen - with pincode",
  //   "Vehicle Insurance renewal",
  //   "Vehicle service booking",
  //   "Vehicle service booking",
  //   "Vehicle service booking",
  //   "Vehicle service booking",
  //   "Vehicle service booking",
  //   "Vehicle service booking",
  //   "Vehicle service booking",
  // ];

  // const createdWorkflows = [
  //   "testing purpose",
  //   "Untitled Workflow 1722855508537",
  // ];

  const handleDeleteCancel = () => {
    setIsDeleteConfirmOpen(false);
    setWorkflowToDelete(null);
  };

  return (
    <>
      {hasWorkflowsPermission ? (
        <Box style={{ position: "relative" }}>
          {workflowStatus === "loading" && (
            <Box className={classes.overlayLoader}>
              <LoadingComponent height="50px" color={bgColors.blue} />
            </Box>
          )}
          <Grid container className={classes.mainContainer}>
            <Grid item className={classes.bgContainer}>
              <Box className={classes.manageContainer}>
                <Box ml={4} mt={2} mb={1}>
                  <Typography variant="h6" className={classes.blackColor}>
                    Workflows
                  </Typography>
                </Box>
                <Box style={{ marginLeft: "auto" }} mt={3} mr={2}>
                  <button
                    className={classes.SaveChangesButton}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    style={{
                      backgroundColor: isHovered
                        ? "rgba(68, 71, 70, 0.08)"
                        : "#fff",
                      cursor: isHovered ? "pointer" : "default",
                    }}
                    onClick={handleWorkflowDialog}
                  >
                    + Add Workflow
                  </button>
                </Box>
              </Box>
              <Box ml={4}>
                <Typography variant="body2" className={classes.textColor}>
                  This section allows users to create and manage Custom Auto
                  Replies in response to customer chats.
                </Typography>
              </Box>
              {/* first box */}
              {/* <Box sx={{ marginLeft: "50px", marginRight: "50px" }}>
                <Box className={classes.templateBox}>
                  <Typography variant="h6" className={classes.headerText}>
                    Looking for a faster and more efficient way to create
                    stunning Workflows? Look no further than our templates!
                  </Typography>
                  <Typography
                    variant="h6"
                    className={classes.headerText}
                    sx={{ fontSize: "15px" }}
                  >
                    Workflow templates
                  </Typography>
                  {workflowNamesStatus == "loading" ? (
                    <LoadingComponent height="auto" color={bgColors?.blue} />
                  ) : (
                    <TableContainer
                      component={Paper}
                      className={classes.tableContainer}
                      sx={{
                        maxHeight: "23vh",
                        overflowY: "scroll",
                        marginTop: "10px",
                      }}
                    >
                      <Table className={classes.table}>
                        <TableBody>
                          {templateWorkflows.map((workflow, index) => (
                            <TableRow
                              key={index}
                              className={classes.tableRow}
                              sx={{
                                "&:hover": {
                                  "& .edit-icon": {
                                    visibility: "visible",
                                  },
                                },
                              }}
                            >
                              <TableCell
                                className={classes.grayText}
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Typography
                                  sx={{
                                    marginLeft: "10px",
                                    fontSize: "0.875rem",
                                  }}
                                >
                                  {workflow}
                                </Typography>
                                <IconButton
                                  size="small"
                                  sx={{
                                    marginLeft: "10px",
                                    visibility: "hidden",
                                  }}
                                  className="edit-icon"
                                >
                                  <EditIconSvg />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                </Box>
              </Box> */}

              {/*second box */}
              <Box
                sx={{
                  marginLeft: "50px",
                  marginRight: "50px",
                  marginTop: "20px",
                  paddingBottom: "30px",
                }}
              >
                <Typography
                  variant="h6"
                  className={classes.headerText}
                  sx={{ fontSize: "15px" }}
                >
                  Workflows created by you
                </Typography>

                <TableContainer
                  component={Paper}
                  className={classes.tableContainer}
                  sx={{
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                  }}
                >
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <span style={{ marginLeft: "10px" }}>
                            Workflow Name
                          </span>
                        </TableCell>
                        <TableCell>Company ID</TableCell>
                        <TableCell>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    {workflowNamesStatus === "loading" ? (
                      <TableBody>
                        <TableRow>
                          <TableCell colSpan={3} align="center">
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100px",
                              }}
                            >
                              <LoadingComponent
                                height="auto"
                                color={bgColors?.blue}
                              />
                            </Box>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ) : workflowNamesData?.length === 0 ? (
                      <TableBody>
                        <TableRow>
                          <TableCell colSpan={3} align="center">
                            <Typography variant="body2" sx={{ color: "gray" }}>
                              No workflows found
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ) : (
                      <TableBody>
                        {workflowNamesData?.map((workflow: any, index: any) => (
                          <TableRow
                            key={index}
                            className={classes.tableRow}
                            sx={{
                              "&:hover": {
                                "& .action-icons": {
                                  visibility: "visible",
                                },
                              },
                            }}
                          >
                            <TableCell>
                              <Tooltip
                                title={workflow?.workflowName || ""}
                                placement="top"
                              >
                                <Typography
                                  sx={{
                                    marginLeft: "10px",
                                    fontSize: "0.875rem",
                                    cursor: "pointer",
                                    width: "500px",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                  onClick={() => handleWorkflow(workflow)}
                                >
                                  {workflow?.workflowName}
                                </Typography>
                              </Tooltip>
                            </TableCell>
                            <TableCell>
                              <Typography
                                sx={{
                                  fontSize: "0.875rem",
                                }}
                              >
                                {workflow?.companyId}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Box
                                sx={{
                                  display: "flex",
                                }}
                                className="action-icons"
                              >
                                <IconButton
                                  size="small"
                                  onClick={() => handleWorkflow(workflow)}
                                >
                                  <EditIconSvg />
                                </IconButton>
                                <IconButton
                                  size="small"
                                  onClick={() => handleDeleteClick(workflow)}
                                >
                                  <DeleteIconSvg />
                                </IconButton>
                              </Box>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Box>
              <DeletePopUp
                open={isDeleteConfirmOpen}
                handleDelete={handleDeleteConfirm}
                handleClose={handleDeleteCancel}
                title={`Workflow "${workflowToDelete?.workflowName}"`}
                handleLoad={isWorkflowDeleteLoading}
              />
            </Grid>
            <WorkflowDialog
              setOpen={setOpenWorkflowDialog}
              open={isOpenWorkflowDialog}
              initialWorkflowData={convertedWorkFlowData}
              isWorkflowEditing={isWorkflowEditing}
              setIsWorkflowEditing={setIsWorkflowEditing}
            />
          </Grid>
        </Box>
      ) : (
        <NoAccessPage />
      )}
    </>
  );
};

export default WorkFlow;
