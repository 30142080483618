import { makeStyles } from "@mui/styles";
import React from "react";
import { Grid } from "@mui/material";
import { Outlet, useLocation } from "react-router-dom";
import TemplateSidebar from "../../components/TemplateComponents/TemplateSideBar";
import NoAccessPage from "../../components/common/NoAccess";
import { useAppSelector } from "../../utils/redux-hooks";

const useStyles = makeStyles({
  sideBar: {
    position: "sticky",
    top: 0,
    height: "100vh",
    overflowY: "auto",
  },
  outlet: {
    overflowY: "auto",
    height: "100vh",
    backgroundColor: "#F8F8F8",
  },
});

const TemplateMainPage = () => {
  const classes = useStyles();
  const location = useLocation();

  const getuserPermissionData = useAppSelector(
    (state: any) => state?.getUserPermissions?.data
  );
  const currentPlanDetails = useAppSelector(
    (state: any) => state?.wallet?.walletAndSubscription?.data
  );
  const accountData = useAppSelector((state: any) => state?.accountData?.data);
  // Check if the current route starts with "/profile/"
  const isTemplate = location.pathname.startsWith("/templates");
  return (
    <>
      {currentPlanDetails?.subscriptionPlan?.isActive &&
      ((accountData?.companyVerificationStatus === true &&
        accountData?.isMetaEnabled) ||
        (accountData?.isMetaEnabled === false &&
          accountData?.companyVerificationStatus === false &&
          currentPlanDetails?.subscriptionPlan?.planName === "Intro")) &&
      getuserPermissionData?.Templates ? (
        <>
          <Grid item xs={2} className={classes.sideBar}>
            {/* {isTemplate && */}
            <TemplateSidebar />
            {/* } */}
          </Grid>

          <Grid item xs={10} className={classes.outlet}>
            <Outlet />
          </Grid>
        </>
      ) : (
        <NoAccessPage />
      )}
    </>
  );
};

export default TemplateMainPage;
