import { bgColors } from "../../utils/bgColors";
import { makeStyles } from "@mui/styles";
import ArrowDownSvg from "../../assets/svgs/ArrowDownSvg";
import ExportWhiteIcon from "../../assets/svgs/ExportWhiteIcon";
import { useAppDispatch, useAppSelector } from "../../utils/redux-hooks";
import React, { useEffect, useState, ChangeEvent } from "react";
import { Box, Grid, Paper, Typography, Button, Tooltip, CircularProgress } from "@mui/material";
import ContactsFilterPopover from "../../components/ContactsComponents/ContactsFilterPopOver";
import ReactECharts from "echarts-for-react";
import { AiOutlineInbox } from "react-icons/ai";
import DateRangePicker from "../../components/AnalyticsComponent/DatePickerComponent";
import { IoReload } from "react-icons/io5";
import { VscGitStashApply } from "react-icons/vsc";
import { fetchInboxAnalysisByCompanyId } from "../../redux/slices/Analytics/InboxAnalyticsSlice";
import LoadingComponent from "../../components/common/LoadingComponent";
import { ANALYTICS_API } from "../../Apis/Analytics/Analytics";

const useStyles = makeStyles({
  mainContainer: {
    backgroundColor: bgColors.white1,
    padding: "14px",
    height: "100%",
    width: "100%",
  },
  bgContainer: {
    backgroundColor: bgColors.white,
    borderRadius: "25px",
    height: "100%",
    width: "100%",
    overflow: "hidden !important",
    display: "flex",
    flexDirection: "column",
  },
  manageContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  blackColor: {
    color: `${bgColors.black1} !important`,
    fontWeight: "600 !important",
  },
  grayColor: {
    color: `${bgColors.black1} !important`,
    opacity: "60% !important",
  },
  grayColor1: {
    color: "#303030",
    opacity: "90%",
    fontSize: "13px",
  },
  rotatedIcon: {
    cursor: "pointer",
    paddingRight: "5px",
    transform: "rotate(180deg)",
  },
  iconStyles: {
    cursor: "pointer",
    paddingLeft: "5px",
  },
  messageCountContainer: {
    height: "31px",
    border: "1px solid #F2F2F2",
    cursor: "pointer",
    borderRadius: "5px",
    paddingInline: "8px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  messageInnerContainer: {
    display: "flex",
    flexDirection: "row",
  },
});
const COLORS = ["#4c78ff", "#16dbcc", "#f75e90", "#ffbb38"];

// const cardData = [
//   { label: "Open Chats", value: 27, color: "#f4f7ff" },
//   { label: "Pending", value: 13, color: "#fff8f2" },
//   { label: "Closed", value: 135, color: "#f2fff2" },
//   { label: "Expired", value: 33, color: "#f7f3ff" },
//   { label: "Unassigned", value: 33, color: "#f7f3ff" },
// ];
// const dataLine = [
//   { name: "Jan", Open: 200, Pending: 300, Solved: 150 },
//   { name: "Feb", Open: 220, Pending: 280, Solved: 160 },
//   { name: "Mar", Open: 250, Pending: 270, Solved: 180 },
//   { name: "Apr", Open: 280, Pending: 300, Solved: 200 },
//   { name: "May", Open: 300, Pending: 320, Solved: 210 },
//   { name: "Jun", Open: 320, Pending: 340, Solved: 220 },
//   { name: "Jul", Open: 340, Pending: 360, Solved: 230 },
//   { name: "Aug", Open: 360, Pending: 380, Solved: 240 },
//   { name: "Sep", Open: 380, Pending: 400, Solved: 250 },
//   { name: "Oct", Open: 400, Pending: 420, Solved: 260 },
//   { name: "Nov", Open: 420, Pending: 440, Solved: 270 },
//   { name: "Dec", Open: 440, Pending: 460, Solved: 280 },
// ];

// const dataPie = [
//   { name: "Sent", value: 400 },
//   { name: "Received", value: 300 },
//   { name: "Failed", value: 300 },
//   { name: "Spam", value: 200 },
// ];
// const dataUser = [
//   { name: "Fiksi R", score: 376, color: "#FFA500" }, // orange
//   { name: "Fauzan A", score: 109, color: "#6A5ACD" }, // blue
//   { name: "Kedar Kanziya", score: 645, color: "#00BFFF" }, // sky blue
//   { name: "Bianca Ho", score: 429, color: "#FF4500" }, // red
//   { name: "Bianca Ho", score: 100, color: "#20B2AA" }, // light sea green
// ];

const InboxAnalytics = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const loginData = useAppSelector((state: any) => state.adminLogin.data);
  const userData = useAppSelector((state: any) => state?.adminLogin?.data);
  const indexAnalysis = useAppSelector(
    (state: any) => state?.inboxAnalysis
  );
  // console.log("indexAnalysis", indexAnalysis)
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const convertData = (data: any) => {
    return [
      { label: "Open Chats", value: data.openCount || 0, color: "#f4f7ff" },
      { label: "Pending", value: data.pendingCount || 0, color: "#fff8f2" },
      { label: "Resolved", value: data.resolvedCount || 0, color: "#f2fff2" },
      { label: "Expired", value: data.expiredCount || 0, color: "#f7f3ff" },
      // { label: "Unassigned", value: data.unAssinedCount || 0, color: "#f7f3ff" },
    ];
  };
  const convertToDataLine = (statusChat: any) => {
    // List of month abbreviations to ensure all months are represented in the output
    const months = [
      { name: "Jan", Open: 0, Pending: 0, Resolved: 0, Expired: 0 },
      { name: "Feb", Open: 0, Pending: 0, Resolved: 0, Expired: 0 },
      { name: "Mar", Open: 0, Pending: 0, Resolved: 0, Expired: 0 },
      { name: "Apr", Open: 0, Pending: 0, Resolved: 0, Expired: 0 },
      { name: "May", Open: 0, Pending: 0, Resolved: 0, Expired: 0 },
      { name: "Jun", Open: 0, Pending: 0, Resolved: 0, Expired: 0 },
      { name: "Jul", Open: 0, Pending: 0, Resolved: 0, Expired: 0 },
      { name: "Aug", Open: 0, Pending: 0, Resolved: 0, Expired: 0 },
      { name: "Sep", Open: 0, Pending: 0, Resolved: 0, Expired: 0 },
      { name: "Oct", Open: 0, Pending: 0, Resolved: 0, Expired: 0 },
      { name: "Nov", Open: 0, Pending: 0, Resolved: 0, Expired: 0 },
      { name: "Dec", Open: 0, Pending: 0, Resolved: 0, Expired: 0 },
    ];

    // Check if statusChat is an array
    if (!Array.isArray(statusChat)) {
      // console.error("statusChat is not an array:", statusChat);
      return months; // Return the default months array with zeroed data if statusChat is not an array
    }

    // Loop through the statusChat data to fill in the counts
    statusChat.forEach(({ monthYear, openCount, pendingCount, resolvedCount, expiredCount }: any) => {
      const monthIndex = new Date(`${monthYear}-01`).getMonth(); // Convert monthYear to month index
      months[monthIndex].Open = openCount;
      months[monthIndex].Pending = pendingCount;
      months[monthIndex].Resolved = resolvedCount;
      months[monthIndex].Expired = expiredCount;
    });

    return months;
  };

  const convertCountsToArray = (data: any) => {
    if (!data) {
      // Return an empty array if data is undefined or null
      return [];
    }
    return [
      { name: "Sent", value: data.sentCount || 0 },  // Default to 0 if undefined
      { name: "Received", value: data.receivedCount || 0 },
      { name: "Failed", value: data.faildCount || 0 }, // Correct spelling in data if possible
      { name: "Spam", value: data.spamCount || 0 }
    ];
  };
  const convertUserDataToNewFormat = (data: any) => {
    // Check if data is an array before mapping
    if (!Array.isArray(data)) {
      return []; // Return an empty array if data is not an array
    }
    // Define a list of colors to assign to each object
    const colors = ["#FFA500", "#6A5ACD", "#00BFFF", "#FF4500", "#20B2AA"];
    // Map the input data to the desired format
    return data.map((item: any, index: any) => ({
      name: item.name,                 // Keep the name as is
      score: item.messageCount,        // Use messageCount as score
      color: colors[index % colors.length] // Assign color in a repeating sequence
    }));
  };

  const [inboxAnalytic, setInboxAnalytic] = useState(convertData(indexAnalysis?.data?.data?.inboxAnalytics || {}));

  const [analyticsTotalMessage, setanAlyticsTotalMessage] = useState(convertCountsToArray(indexAnalysis?.data?.data?.analyticsTotalMessage) || []);
  const [statusChat, setStatusChat] = useState(convertToDataLine(indexAnalysis?.data?.data?.statusChat) || []);
  const topUserPerformanceData = indexAnalysis?.data?.data?.topUserPerformance || [];
  const [topUserPerformance, setTopUserPerformance] = useState(convertUserDataToNewFormat(topUserPerformanceData));

  useEffect(() => {
    setIsLoadingMore(true);
    if (indexAnalysis.status === "succeeded") {
    setInboxAnalytic(convertData(indexAnalysis?.data?.data?.inboxAnalytics));
    setanAlyticsTotalMessage(convertCountsToArray(indexAnalysis?.data?.data?.analyticsTotalMessage));
    setStatusChat(convertToDataLine(indexAnalysis?.data?.data?.statusChat));
    setTopUserPerformance(convertUserDataToNewFormat(topUserPerformanceData));
    setIsLoadingMore(false);
    }
  }, [indexAnalysis])

  const [anchorElFilter, setAnchorElFilter] = useState<null | HTMLElement>(
    null
  );
  const [selectedFilter, setSelectedFilter] = useState("Tags");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [page, setPage] = useState(1);
  const [teamData, setTeamData] = useState<any>([]);
  const [isDateRangeApplied, setIsDateRangeApplied] = useState(false);
  const today = () => {
    const currentDate = new Date();
    return currentDate.toISOString().split('T')[0]; // Formats the date to 'YYYY-MM-DD'
  }

  const [endCalenderDate, setEndCalenderDate] = useState(today());
  const [startCalenderDate, setStartCalenderDate] = useState(today());
  // const [startCalenderDate, setStartCalenderDate] = useState<any>(null);
  // const [endCalenderDate, setEndCalenderDate] = useState<any>(null);
  const icon = isDateRangeApplied ? <IoReload /> : <VscGitStashApply />;
  const tooltipText = isDateRangeApplied ? "Reload" : "Apply";
  const [isExportAllByIdLoading, setIsExportAllByIdLoading] = useState(false);

  useEffect(() => {
    const postData = {
      userId: userData?.userId,
      companyId: userData?.companyId,
      fromDate: startCalenderDate,
      toDate: endCalenderDate,
      tags: teamData,
      isExportData: false,
      filters: {
        sorting: {
          column: "",
          order: "",
        },
        filtering: {
          filterType: "and",
          conditions: [
            {
              column: selectedFilter
            },
          ],
        },
      },
    };

    dispatch(fetchInboxAnalysisByCompanyId(postData));
    // setInboxAnalytic(convertData(indexAnalysis?.data?.data?.inboxAnalytics));
    // setanAlyticsTotalMessage(convertCountsToArray(indexAnalysis?.data?.data?.analyticsTotalMessage));
    // setStatusChat(convertToDataLine(indexAnalysis?.data?.data?.statusChat));
    // setTopUserPerformance(convertUserDataToNewFormat(topUserPerformanceData));
  }, [
    dispatch,
    selectedFilter,
    endCalenderDate,
    startCalenderDate,
    teamData
  ]);

  const TicketStatusChartOptions = {
    dataset: [
      {
        id: "dataset_raw",
        source: statusChat,
      },
      {
        id: "dataset_open",
        fromDatasetId: "dataset_raw",
        transform: {
          type: "filter",
          config: {
            and: [{ dimension: "Open", gte: 0 }],
          },
        },
      },
      {
        id: "dataset_pending",
        fromDatasetId: "dataset_raw",
        transform: {
          type: "filter",
          config: {
            and: [{ dimension: "Pending", gte: 0 }],
          },
        },
      },
      {
        id: "dataset_solved",
        fromDatasetId: "dataset_raw",
        transform: {
          type: "filter",
          config: {
            and: [{ dimension: "Resolved", gte: 0 }],
          },
        },
      },
      {
        id: "dataset_expired",
        fromDatasetId: "dataset_raw",
        transform: {
          type: "filter",
          config: {
            and: [{ dimension: "Expired", gte: 0 }],
          },
        },
      },
    ],
    title: {
      text: "Ticket Status Chart",
      // left: 'center',
      textStyle: {
        fontSize: "16px",
        color: "#666",
        fontWeight: "500",
      },
    },
    tooltip: {
      trigger: "axis",
    },
    xAxis: {
      type: "category",
      // name: 'Month',
      nameLocation: "middle",
      nameGap: 30,
      axisLine: {
        lineStyle: {
          color: "#ccc",
        },
      },
      axisLabel: {
        color: "#999",
      },
      data: statusChat.map((item) => item.name),
    },
    yAxis: {
      type: "value",
      // name: 'Tickets',
      axisLine: {
        lineStyle: {
          color: "#ccc",
        },
      },
      axisLabel: {
        color: "#999",
      },
      splitLine: {
        lineStyle: {
          color: "#f0f0f0",
        },
      },
    },
    series: [
      {
        type: "line",
        datasetId: "dataset_open",
        name: "Open",
        smooth: true,
        showSymbol: false,
        encode: {
          x: "name",
          y: "Open",
        },
        itemStyle: {
          color: "#a700ff", // Purple
        },
        lineStyle: {
          width: 3,
        },
      },
      {
        type: "line",
        datasetId: "dataset_pending",
        name: "Pending",
        smooth: true,
        showSymbol: false,
        encode: {
          x: "name",
          y: "Pending",
        },
        itemStyle: {
          color: "#ef4444", // Red
        },
        lineStyle: {
          width: 3,
        },
      },
      {
        type: "line",
        datasetId: "dataset_solved",
        name: "Resolved",
        smooth: true,
        showSymbol: false,
        encode: {
          x: "name",
          y: "Resolved",
        },
        itemStyle: {
          color: "#3cd856", // Green
        },
        lineStyle: {
          width: 3,
        },
      },
      {
        type: "line",
        datasetId: "dataset_expired",
        name: "Expired",
        smooth: true,
        showSymbol: false,
        encode: {
          x: "name",
          y: "Expired",
        },
        itemStyle: {
          color: "#FFA500",
        },
        lineStyle: {
          width: 3,
        },
      },
    ],
    legend: {
      data: ["Open", "Pending", "Resolved", "Expired"],
      bottom: -5,
      left: "center",
      textStyle: {
        color: "#999",
        // paddingTop: '10px',
      },
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "8%",
      containLabel: true,
    },
  };
  // const TotalMessagesChartOptions = {
  //   legend: {
  //     top: "bottom",
  //   },
  //   tooltip: {
  //     trigger: "item", // Triggers the tooltip on hover
  //     formatter: "{b}: {c} ({d}%)", // Formats the tooltip content
  //   },
  //   series: [
  //     {
  //       type: "pie",
  //       radius: [30, 100], // For rose-type pie chart
  //       center: ["50%", "50%"],
  //       roseType: "area", // Makes the chart a rose-type
  //       itemStyle: {
  //         borderRadius: 1, // Adds border radius to pie segments
  //       },
  //       data: analyticsTotalMessage.map((entry, index) => ({
  //         value: entry.value,
  //         name: entry.name,
  //         itemStyle: {
  //           color: COLORS[index % COLORS.length],
  //         },
  //       })),
  //       label: {
  //         show: false, // Hides the labels on the pie chart
  //       },
  //     },
  //   ],
  // };

  const TotalMessagesChartOptions = {
    tooltip: {
      trigger: "item", // Triggers the tooltip on hover
    },
    legend: {
      top: "bottom",  // Positions the legend at the top center
      left: "center",
    },
    series: [
      {
        name: "Total Messages",  // Updated name for the series
        type: "pie",
        radius: ["40%", "70%"],  // Set inner and outer radius for a donut shape
        avoidLabelOverlap: false,
        label: {
          show: false, // Hide labels by default
          position: "center",
        },
        emphasis: {
          label: {
            show: true,  // Show label on emphasis
            fontSize: 20,  // Increased font size on hover
            fontWeight: "bold",  // Bold font weight on hover
          },
        },
        labelLine: {
          show: false,  // Hide lines connecting labels to pie slices
        },
        data: analyticsTotalMessage.map((entry, index) => ({
          value: entry.value,
          name: entry.name,
          itemStyle: {
            color: COLORS[index % COLORS.length],  // Dynamically assign colors
          },
        })),
      },
    ],
  };


  const TopUsers = () => {
    const maxScore = Math.max(...topUserPerformance.map((user: any) => user.score));

    return (
      <Box>
        <Typography
          sx={{ fontSize: "16px", color: "#666", fontWeight: "500", mb: 2 }}
          gutterBottom
        >
          Top users
        </Typography>
        {topUserPerformance.map((user: any, index: any) => (
          <Box key={index} display="flex" flexDirection="column" mb={1.5}>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: 12,
                flexShrink: 0,
                color: "#666",
              }}
            >
              {user.name}
            </Typography>
            <Box
              sx={{
                flexGrow: 1,
                ml: 2,
                position: "relative",
              }}
            >
              <ReactECharts
                option={{
                  xAxis: { type: "value", show: false, max: maxScore },
                  yAxis: { type: "category", data: [user.name], show: false },
                  series: [
                    {
                      data: [user.score],
                      type: "bar",
                      barWidth: "70%", // Adjust the bar width
                      itemStyle: {
                        color: user.color,
                        borderRadius: [5, 5, 5, 5], // Border radius for the bar
                      },
                      label: {
                        position: "right",
                        formatter: "{c}",
                        color: user.color,
                        fontSize: 12,
                      },
                    },
                  ],
                  grid: { left: 0, right: 50, top: 0, bottom: 0 },
                  animation: true,
                }}
                style={{ height: "12px", width: "100%" }} // Adjust height and width
              />
              <Box
                sx={{
                  position: "absolute",
                  top: "0",
                  right: "0",
                  height: "100%",
                  width: "50px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="body2"
                  sx={{ color: user.color, fontWeight: 500 }}
                >
                  {user.score}
                </Typography>
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    );
  };

  const getContactTag = useAppSelector(
    (state: any) => state?.getContactTagsData?.data
  );
  const activeTagsArray: any = getContactTag.filter((tag: any) => tag.isActive);
  const filteredTags = activeTagsArray?.filter((tag: any) =>
    tag?.tag?.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const handleFilterClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElFilter(event.currentTarget);
  };

  const handleCloseFilterPopover = () => {
    setAnchorElFilter(null);
  };

  const handleOptionClick = (option: string) => {
    setSelectedFilter(option);
    handleCloseFilterPopover();
  };

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const handleDateRangeChange = ({ startDate, endDate }: any) => {
    setStartCalenderDate(startDate);
    setEndCalenderDate(endDate);
    setIsDateRangeApplied(false);
    // console.log("StartCalenderDate", startCalenderDate, "EndCalenderDate", endCalenderDate)
  };
  // const handleApplyDateRange = () => {
  //   if (isDateRangeApplied) {
  //     setStartCalenderDate(today());
  //     setEndCalenderDate(today());
  //     setIsDateRangeApplied(false);
  //   } else {
  //     // Apply the date range
  //     setIsDateRangeApplied(true);
  //   }
  // };
  const handleExportById = async () => {
    // const hasPermission = hasExportByIdPermission(manageContactsObject);      
    try {
      let data = {
        userId: userData?.userId,
        companyId: userData?.companyId,
        // pageNumber: pageNumber,
        fromDate: startCalenderDate,
        toDate: endCalenderDate,
        isExportData: true,
        per_page: 40,
        tags: teamData,
      }
      setIsExportAllByIdLoading(true);
      const getResponseById = await ANALYTICS_API.getInboxAnalytics(data);
      let rdata:string = getResponseById.data.data;
      // Decode base64 string to binary data
      const byteCharacters = atob(rdata);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      // Create a blob from the byte array
      const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");

      link.href = url;
      link.download = "exported_InboxAnalytics.xlsx";
      link.style.display = "none";
      document.body.appendChild(link);

      link.click();

      // Cleanup
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
      setIsExportAllByIdLoading(false);
    } catch (error) {
      console.error("Error exporting Inbox Analytics :", error);
      setIsExportAllByIdLoading(false);
    }
  };
  return (
    <Grid className={classes.mainContainer}>
      <Box className={classes.bgContainer} >
        <Box sx={{ display: "flex", flexDirection: "column", height: "100px" }} mt={1}>
          <Box className={classes.manageContainer}>
            <Box my={2} mx={3}>
              <Typography
                variant="h6"
                className={classes.blackColor}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <AiOutlineInbox />
                &nbsp;Inbox Analytics
              </Typography>
            </Box>
          </Box>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
            ml={2}
          >
            <Box style={{ display: "flex", flexDirection: "row" }}>
              <Box className={classes.messageCountContainer}>
                <Typography
                  className={classes.grayColor1}
                  sx={{ fontSize: "13px" }}
                  onClick={handleFilterClick}
                >
                  {selectedFilter}
                </Typography>
                <Box className={classes.iconStyles} onClick={handleFilterClick}>
                  <ArrowDownSvg />
                </Box>
                <ContactsFilterPopover
                  anchorEl={anchorElFilter}
                  handleClose={handleCloseFilterPopover}
                  options={filteredTags}
                  handleOptionClick={handleOptionClick}
                  handleSearchChange={handleSearchChange}
                  setSearchQuery={setSearchQuery}
                  setPage={setPage}
                  setTeamData={setTeamData}
                />
              </Box>
              <Box>
                <Box
                  ml={2}
                  sx={{
                    display: "flex",
                    // justifyContent: "space-between"
                    border: "1px solid #eee",
                    borderRadius: "5px",
                    height: "31px",
                  }}
                >
                  <DateRangePicker
                    onDateRangeChange={handleDateRangeChange}
                    // disabled={transactionData && transactionData?.length < 1}
                    startCalenderDate={startCalenderDate}
                    endCalenderDate={endCalenderDate}
                  />
                  {/* <Button
                    // disabled={!startCalenderDate || !endCalenderDate}
                    onClick={handleApplyDateRange}
                    sx={{
                      color: "green",
                      fontSize: "12px",
                      padding: 0,
                      margin: 0,
                    }}
                  >
                    <Tooltip title={tooltipText}>{icon}</Tooltip>
                  </Button> */}
                </Box>
              </Box>
            </Box>
            <Box mr={3}>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  // backgroundColor: bgColors.blue,
                  borderRadius: "8px",
                  padding: "10px",
                  cursor: "pointer",
                  width: "130px",
                  height: "32px",
                  color: bgColors.green,
                  border: `1px solid ${bgColors.green}`,
                }}
                sx={{
                  "&:hover": {
                    cursor: "pointer",
                    backgroundColor: "rgba(68, 71, 70, 0.08)",
                  },
                }}
              >
                {isExportAllByIdLoading ? (
                  <Box sx={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center" }}>
                    <CircularProgress size={16} />
                  </Box>
                ) : (
                  <Box sx={{ display: "flex", width: "100%", justifyContent: "space-between" }} onClick={handleExportById} >
                    <Typography variant="body2" style={{ padding: "5px" }}>
                      Export
                    </Typography>
                    <Box className={classes.iconStyles}>
                      <ExportWhiteIcon
                        style={{
                          fill: bgColors.green,
                          paddingTop: "5px",
                        }}
                      />
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={{ flex: 1, overflow: "auto",}} pr={1}>
          {
            indexAnalysis?.status == 'succeeded' && !isLoadingMore ?
              (
                <Box
                  sx={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    overflow: "auto",
                    px: 2,
                  }}
                >
                  <Grid container spacing={2} mt={1}>
                    {inboxAnalytic.map((status, index) => (
                      <Grid item xs={12} sm={4} md={4} lg={3} key={index}>  {/* lg={2.4} to fit 5 columns */}
                        <Paper
                          sx={{
                            margin: "2px",
                            padding: 2,
                            width: "100%",
                            backgroundColor: status.color,
                            boxShadow: "none", // This removes the box shadow
                            borderRadius: "12px",
                          }}
                        >
                          <Typography sx={{ color: "#666", fontWeight: "500" }}>
                            {status.label}
                          </Typography>
                          <Typography sx={{ fontWeight: "900", fontSize: "30px" }}>
                            {status.value}
                          </Typography>
                        </Paper>
                      </Grid>
                    ))}
                  </Grid>

                  <Box mt={4} sx={{ flex: 1, padding: "3px" }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6} lg={5.4}>
                        <Paper
                          sx={{
                            padding: 2,
                            borderRadius: 2,
                            height: "350px",
                            boxShadow: "0 0 10px #eee",
                          }}
                        >
                          <ReactECharts
                            option={TicketStatusChartOptions}
                            style={{ height: 300 }}
                          />
                        </Paper>
                      </Grid>
                      <Grid item xs={12} md={6} lg={3.3}>
                        <Paper
                          sx={{
                            padding: 2,
                            height: "350px",
                            boxShadow: "0 0 10px #eee",
                          }}
                        >
                          <Typography
                            sx={{ fontSize: "16px", color: "#666", fontWeight: "500" }}
                          >
                            Total Messages
                          </Typography>
                          <ReactECharts
                            option={TotalMessagesChartOptions}
                            style={{ height: 300 }}
                          />
                        </Paper>
                      </Grid>
                      <Grid item xs={12} md={6} lg={3.3}>
                        <Paper
                          sx={{
                            padding: 2,
                            borderRadius: 2,
                            height: "350px",
                            boxShadow: "0 0 10px #eee",
                          }}
                        >
                          <TopUsers />
                        </Paper>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              ) : (
                <>
                  <LoadingComponent height="67vh" color={bgColors.blue} />
                </>)
          }
        </Box>
      </Box>
    </Grid>
  );
};

export default InboxAnalytics;
