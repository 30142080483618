import { Box, Grid, Paper, Switch, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { bgColors } from "../../utils/bgColors";
import { useAppDispatch, useAppSelector } from "../../utils/redux-hooks";
import { makeStyles } from "@mui/styles";
import EditIcon from "@mui/icons-material/Edit";
import TextFieldWithBorderComponent from "../../components/common/TextFieldWithBorderComponent";
import RedDeleteIconSvg from "../../assets/svgs/RedDeleteIconSvg";
import DeleteIcon from "@mui/icons-material/Delete";
import { optedKeywordsData } from "../../redux/slices/Automation/OptedKeywordsSlice";
import OptInKeywordsComponent from "../../components/AutomationComponents/Optin-OptoutComponent";
import { fetchOptinKeywords } from "../../redux/slices/Automation/GetOptinKeywords";
import { fetchOptoutKeywords } from "../../redux/slices/Automation/GetOptoutKeywords";
import OptInAndOptOutComponent from "../../components/AutomationComponents/Optin-OptoutComponent";
import { deleteOptedKeywords } from "../../redux/slices/Automation/deleteOptedKeywords";
import { toastActions } from "../../utils/toastSlice";
import TextFieldComponent from "../../components/AutomationComponents/TextFieldComponent";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles({
  mainContainer: {
    backgroundColor: bgColors.white,
    borderRadius: "25px",
    marginTop: "20px",
    width: "100%",
  },
  chatArea: {
    padding: "20px",
  },
  chatTextBox: {
    position: "relative",
    border: "1px solid #262738",
    borderRadius: 8,
  },
  toolBarStyle: {
    border: "none",
    backgroundColor: "rgb(247, 244, 244)",
    padding: "10px 0",
    marginBottom: 0,
    order: 2,
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "10px",
  },
  wrapperClassName: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
  },
  editorClassName: {
    padding: "10px",
    minHeight: "60px",
    maxHeight: "200px",
    fontSize: "14px",
    order: 1,
  },
  fileUploadInput: {
    display: "none",
  },
  bgContainer: {
    backgroundColor: bgColors.white,
    borderRadius: "25px",
    height: "100%",
    width: "100%",
  },
  manageContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  textColor: {
    color: bgColors.black1,
    fontSize: "16px",
    opacity: "80%",
  },
  mainBorderStyles: {
    borderRadius: "18px",
    border: `2px solid ${bgColors.gray5}`,
  },
  emailContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  blackColor: {
    color: `${bgColors.black1} !important`,
    fontWeight: "600 !important",
  },
  resetBtnContainer: {
    backgroundColor: bgColors.white,
    // border: `2px solid ${bgColors.gray5}`,
    borderRadius: "8px",
    width: "100px",
    padding: "8px",
    textAlign: "center",
    cursor: "pointer",
  },
  variable: {
    color: `${bgColors.green} !important`,
    fontWeight: "600 !important",
    fontSize: "14px !important",
    cursor: "pointer",
  },
  changePasswordText: {
    color: bgColors.black,
    fontWeight: "600 !important",
  },
  welcomeContainer: {
    backgroundColor: bgColors.white,
    border: `2px solid ${bgColors.gray5}`,
    borderRadius: "8px",
    width: "90px",
    padding: "8px",
    textAlign: "center",
    cursor: "pointer",
  },
  cursor: {
    cursor: "pointer",
  },
  welcometext: {
    color: bgColors.black,
    fontWeight: "600 !important",
  },
  delayedContainer: {
    backgroundColor: bgColors.white,
    border: `2px solid ${bgColors.gray5}`,
    borderRadius: "8px",
    width: "90px",
    padding: "8px",
    textAlign: "center",
    cursor: "pointer",
  },
  grayColor: {
    color: "#303030",
    opacity: "60%",
    fontSize: "20px",
    // padding:"5px"
  },
  delayedtext: {
    color: bgColors.black,
    fontWeight: "600 !important",
  },
  hours: {
    color: bgColors.black,
    opacity: "80%",
    fontSize: "15px !important",
  },
  time: {
    color: bgColors.black,
    opacity: "80%",
    fontSize: "16px !important",
    // fontWeight: "Bold !important",
  },
  lineStyles: {
    border: "none",
    borderTop: "3px solid #4B5A5A",
    width: "100%",
    margin: "0 auto",
    opacity: "20%",
    marginTop: "5px",
  },
  SaveChangesButton: {
    color: bgColors.green,
    border: `1px solid ${bgColors.green}`,
    // backgroundColor: bgColors.blue,
    borderRadius: "8px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
    cursor: "pointer",
    width: "120px",
    height: "32px",
    gap: "0px",
  },
});

const label = { inputProps: { "aria-label": "Switch demo" } };

const OptinManagement = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const textFieldRef = useRef(null);

  const getLoginData = useAppSelector((state: any) => state.adminLogin.data);
  // console.log(getLoginData);
  const optedManagementData = useAppSelector(
    (state: any) => state.optedManagement
  );
  // console.log(optedManagementData);
  const optedManagementStatus = optedManagementData?.status;
  const getOptInData = useAppSelector((state: any) => state.optinKeywords);
  // console.log(getOptInData);
  const getOptOutData = useAppSelector((state: any) => state.optoutKeywords);
  // console.log(getOptOutData);

  const optInStatus = getOptInData?.status;
  // console.log(optInStatus);
  const optOutStatus = getOptOutData?.status;
  // console.log(optOutStatus);

  // console.log(getOptOutData);

  const [optInkeywords, setOptInKeywords] = useState<string[]>([]);
  // console.log(optInkeywords, "optInkeywords");
  const [optInCurrentKeyword, setOptInCurrentKeyword] = useState("");
  const [optInvalidKeyword, setOptInValidKeyword] = useState(true); // Initially set to true
  const [optInValidationMessage, setOptInValidationMessage] = useState("");
  const [showOptInTextField, setShowOptInTextField] = useState(false);
  const [optInEdit, setOptInEdit] = useState(false);
  const [optInMediaUrl, setOptInMediaUrl] = useState("");
  // console.log(optInMediaUrl);
  const [optInResponseMessage, setOptInResponseMessage] = useState(
    "Thanks, You have been opted in for your future communication"
  );
  // console.log(optInResponseMessage, "optInResponseMessage");
  const [optInValidResponseMessage, setOptInValidResponseMessage] =
    useState("");
  const [isOptInChecked, setIsOptInChecked] = useState<boolean>(false);
  const [optInOpenTextField, setOptInOpenTextField] = useState(false);
  const [optInId, setOptInId] = useState("");
  // console.log(optInId);

  const [optOutkeywords, setOptOutKeywords] = useState<string[]>([]);
  // console.log(optOutkeywords, "optOutkeywords");
  const [optOutCurrentKeyword, setOptOutCurrentKeyword] = useState("");
  const [optOutvalidKeyword, setOptOutValidKeyword] = useState(true); // Initially set to true
  const [optOutValidationMessage, setOptOutValidationMessage] = useState("");
  const [showOptOutTextField, setShowOptOutTextField] = useState(false);
  const [optOutEdit, setOptOutEdit] = useState(false);
  const [optOutMediaUrl, setOptOutMediaUrl] = useState("");
  // console.log(optOutMediaUrl);
  const [optOutResponseMessage, setOptOutResponseMessage] = useState(
    "You have been opted-out for your future communication"
  );
  // console.log(optOutResponseMessage);
  const [optOutValidResponseMessage, setOptOutValidResponseMessage] =
    useState("");
  const [isOptOutChecked, setIsOptOutChecked] = useState<boolean>(false);
  // console.log(isOptOutChecked);
  const [optOutOpenTextField, setOptOutOpenTextField] = useState(false);
  const [optOutId, setOptOutId] = useState("");
  // console.log(optOutId);

  const handleOptInKeywordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOptInCurrentKeyword(e.target.value);
    setOptInValidKeyword(true); // Reset validKeyword whenever the input changes
  };

  const handleOptOutKeywordChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setOptOutCurrentKeyword(e.target.value);
    setOptOutValidKeyword(true); // Reset validKeyword whenever the input changes
  };

  const handleAddOptInKeywords = async () => {
    if (!showOptInTextField) {
      setShowOptInTextField(true); // Show the text field on the first click
      return;
    }

    // Validation
    if (optInCurrentKeyword.trim() === "") {
      setOptInValidKeyword(false); // Show error for empty keyword
      setOptInValidationMessage("Keyword is required");
      return;
    }

    // Validate the keyword
    if (!validateOptInKeyword(optInCurrentKeyword)) return;

    // Update the keywords array
    const updatedKeywords = [...optInkeywords, optInCurrentKeyword];
    setOptInKeywords(updatedKeywords);
    setOptInCurrentKeyword(""); // Clear the input after saving
    setOptInValidKeyword(true); // Reset validation state
    setShowOptInTextField(true); // Keep TextField open for the next keyword

    // Dispatch API call with updated keywords
  };

  const handleAddOptOutKeywords = async () => {
    if (!showOptOutTextField) {
      setShowOptOutTextField(true); // Show the text field on the first click
      return;
    }

    // Validation
    if (optOutCurrentKeyword?.trim() === "") {
      setOptOutValidKeyword(false); // Show error for empty keyword
      setOptOutValidationMessage("Keyword is required");
      return;
    }

    // Validate the keyword
    if (!validateOptOutKeyword(optOutCurrentKeyword)) return;

    // Update the keywords array
    const updatedKeywords = [...optOutkeywords, optOutCurrentKeyword];
    setOptOutKeywords(updatedKeywords);
    setOptOutCurrentKeyword(""); // Clear the input after saving
    setOptOutValidKeyword(true); // Reset validation state
    setShowOptOutTextField(true); // Keep TextField open for the next keyword

    // Dispatch API call with updated keywords
  };

  const handleDeleteOptInKeyword = async (keyword: string, index: number) => {
    const updatedKeywords = optInkeywords?.filter((_, i) => i !== index);
    const data = {
      id: optInId,
      businessId: getLoginData?.companyId,
      userId: getLoginData?.userId,
      optInKeyword: updatedKeywords,
      optInMessageToggle: isOptInChecked,
      textOptIn: optInResponseMessage,
      urlOptIn: "",
    };
    const res = await handleOptInApiCall(data);
    setOptInKeywords(updatedKeywords);
  };

  const handleDeleteOptOutKeyword = async (keyword: string, index: number) => {
    const updatedKeywords = optOutkeywords?.filter((_, i) => i !== index);

    // Make API call with the updated keywords
    const data = {
      id: optOutId,
      businessId: getLoginData?.companyId,
      userId: getLoginData?.userId,
      optOutKeyword: updatedKeywords,
      optOutMessageToggle: isOptOutChecked,
      textOptOut: optOutResponseMessage,
      urlOptOut: "",
    };
    const res = await handleOptOutApiCall(data);
    setOptOutKeywords(updatedKeywords);
  };

  const handleOptInSaveSettings = async () => {
    // Validate the keyword
    if (!validateOptInKeyword(optInCurrentKeyword)) return;

    let updatedKeywords = optInkeywords;

    if (optInCurrentKeyword?.trim() !== "") {
      // Update the optInkeywords array directly
      updatedKeywords = [...optInkeywords, optInCurrentKeyword];
      setOptInKeywords(updatedKeywords);
      setOptInCurrentKeyword(""); // Clear the input after saving

      // Make API call with the updated keywords
      const data = {
        id: optInId,
        businessId: getLoginData?.companyId,
        userId: getLoginData?.userId,
        optInKeyword: updatedKeywords,
        optInMessageToggle: isOptInChecked,
        textOptIn: optInResponseMessage,
        urlOptIn: "",
      };
      const res = await handleOptInApiCall(data);
    }

    setShowOptInTextField(false); // Hide the TextField
    setOptInValidKeyword(true);
  };

  const handleOptOutSaveSettings = async () => {
    // Validate the keyword
    if (!validateOptOutKeyword(optOutCurrentKeyword)) return;

    if (optOutCurrentKeyword?.trim() !== "") {
      // Update the optOutkeywords array directly
      const updatedKeywords = [...optOutkeywords, optOutCurrentKeyword];
      setOptOutKeywords(updatedKeywords);
      setOptOutCurrentKeyword(""); // Clear the input after saving

      // Make API call with the updated keywords
      const data = {
        id: optOutId,
        businessId: getLoginData?.companyId,
        userId: getLoginData?.userId,
        optOutKeyword: updatedKeywords,
        optOutMessageToggle: isOptOutChecked,
        textOptOut: optOutResponseMessage,
        urlOptOut: "",
      };
      const res = await handleOptOutApiCall(data);
    }

    setShowOptOutTextField(false); // Hide the TextField
    setOptOutValidKeyword(true);
  };

  const handleOptInEditResponse = () => {
    setOptInEdit(true);
  };

  const handleOptOutEditResponse = () => {
    setOptOutEdit(true);
  };

  const handleSwitchOptInChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const checked = event.target.checked;
    setIsOptInChecked(checked);
    const data = {
      id: optInId,
      businessId: getLoginData?.companyId,
      userId: getLoginData?.userId,
      optInKeyword: optInkeywords,
      optInMessageToggle: checked,
      textOptIn: optInResponseMessage,
      urlOptIn: "",
    };
    const res = await handleOptInApiCall(data);
  };

  const handleSwitchOptOutChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const checked = event.target.checked;
    setIsOptOutChecked(checked);
    const data = {
      id: optOutId,
      businessId: getLoginData?.companyId,
      userId: getLoginData?.userId,
      optOutKeyword: optOutkeywords,
      optOutMessageToggle: checked,
      textOptOut: optOutResponseMessage,
      urlOptOut: "",
    };
    const res = await handleOptOutApiCall(data);
  };

  const handleOptInResponseChange = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setOptInValidResponseMessage("");
    setOptInResponseMessage(e.target.value);
  };

  const handleOptOutResponseChange = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setOptOutValidResponseMessage("");
    setOptOutResponseMessage(e.target.value);
  };

  const handleOptInSaveResponse = async () => {
    if (optInResponseMessage?.trim() === "") {
      setOptInValidResponseMessage("Opt-in response is required.");
    } else {
      setOptInEdit(false);
      const data = {
        id: optInId,
        businessId: getLoginData?.companyId,
        userId: getLoginData?.userId,
        optInKeyword: optInkeywords,
        optInMessageToggle: isOptInChecked,
        textOptIn: optInResponseMessage,
        urlOptIn: optInMediaUrl,
      };
      const res = await handleOptInApiCall(data);
      setOptInValidResponseMessage("");
    }
  };

  const handleOptOutSaveResponse = async () => {
    // if (textFieldRef.current) {
    //   textFieldRef.current.callHandleFunction("Argument 1", "Argument 2");
    // }

    if (optOutResponseMessage?.trim() === "") {
      setOptOutValidResponseMessage("Opted-out response in required.");
    } else {
      setOptOutEdit(false);
      const data = {
        id: optOutId,
        businessId: getLoginData?.companyId,
        userId: getLoginData?.userId,
        optOutKeyword: optOutkeywords,
        optOutMessageToggle: isOptOutChecked,
        textOptOut: optOutResponseMessage,
        urlOptOut: optOutMediaUrl,
      };
      const res = await handleOptOutApiCall(data);
      setOptOutValidResponseMessage("");
    }
  };

  // useEffect(() => {
  //   setOptOutKeywords(getOptOutData?.data?.optOutKeywords);
  // }, [getOptOutData]);

  // useEffect(() => {
  //   setOptInKeywords(getOptInData?.data?.optInKeywords);
  // }, [getOptInData]);

  // Reusable function for keyword validation
  const validateOptOutKeyword = (keyword: any) => {
    const trimmedKeyword = keyword?.trim();

    // Validation: Check if the keyword is empty
    // if (trimmedKeyword === "") {
    //   setOptOutValidKeyword(false);
    //   setOptOutValidationMessage("Keyword is required");
    //   return false;
    // }

    // Validation: Check if the keyword exceeds 25 characters
    if (trimmedKeyword.length > 25) {
      setOptOutValidKeyword(false);
      setOptOutValidationMessage("Keyword must not exceed 25 characters");
      return false;
    }

    // Combine optOutkeywords and optInkeywords into one array
    const combinedKeywords = [...optOutkeywords, ...optInkeywords];

    // Validation: Check if the keyword is already in the combined array (case-insensitive)
    if (
      combinedKeywords.some(
        (existingKeyword) =>
          existingKeyword.toLowerCase() === trimmedKeyword.toLowerCase()
      )
    ) {
      setOptOutValidKeyword(false);
      setOptOutValidationMessage("Keyword is already added");
      return false;
    }

    // If all validations pass
    setOptOutValidKeyword(true);
    return true;
  };

  // Reusable function for keyword validation
  const validateOptInKeyword = (keyword: any) => {
    const trimmedKeyword = keyword?.trim();

    // // Validation: Check if the keyword is empty
    // if (trimmedKeyword === "") {
    //   setOptInValidKeyword(false);
    //   setOptInValidationMessage("Keyword is required");
    //   return false;
    // }

    // Validation: Check if the keyword exceeds 25 characters
    if (trimmedKeyword.length > 25) {
      setOptInValidKeyword(false);
      setOptInValidationMessage("Keyword must not exceed 25 characters");
      return false;
    }

    // Combine optInkeywords and optOutkeywords into one array
    const combinedKeywords = [...optInkeywords, ...optOutkeywords];

    // Validation: Check if the keyword is already in the combined array (case-insensitive)
    if (
      combinedKeywords.some(
        (existingKeyword) =>
          existingKeyword.toLowerCase() === trimmedKeyword.toLowerCase()
      )
    ) {
      setOptInValidKeyword(false);
      setOptInValidationMessage("Keyword is already added");
      return false;
    }

    // If all validations pass
    setOptInValidKeyword(true);
    return true;
  };

  const handleOptOutApiCall = async (data: any) => {
    const res = await dispatch(optedKeywordsData(data));
    // console.log(res?.payload);
    if (res?.payload?.message === "OptOut Keywords Updated successfully") {
      dispatch(
        toastActions.setToaster({
          type: "success",
          message: res?.payload?.message,
        })
      );
      await dispatch(fetchOptoutKeywords(getLoginData?.companyId));
    }
  };

  const handleOptInApiCall = async (data: any) => {
    const res = await dispatch(optedKeywordsData(data));
    // console.log(res?.payload);
    if (res?.payload?.message === "OptIn Keywords Updated successfully") {
      dispatch(
        toastActions.setToaster({
          type: "success",
          message: res?.payload?.message,
        })
      );
      await dispatch(fetchOptinKeywords(getLoginData?.companyId));
    }
  };
  useEffect(() => {
    const getRes = async () => {
      const optOutRes: any = await dispatch(
        fetchOptoutKeywords(getLoginData?.companyId)
      );
      // console.log(optOutRes.payload);
      const optOut = optOutRes?.payload;
      setOptOutId(optOut?.id);
      setOptOutKeywords(optOut?.optOutKeyword);
      setOptOutResponseMessage(optOut?.textOptOut);
      setIsOptOutChecked(optOut?.optOutMessageToggle);
      setOptOutMediaUrl(optOut?.urlOptOut);

      const optInRes: any = await dispatch(
        fetchOptinKeywords(getLoginData?.companyId)
      );
      // console.log(optInRes.payload);
      const optIn = optInRes?.payload;
      setOptInId(optIn?.id);
      setOptInKeywords(optIn?.optInKeyword);
      setOptInResponseMessage(optIn?.textOptIn);
      setIsOptInChecked(optIn?.optInMessageToggle);
      setOptInMediaUrl(optIn?.urlOptIn);
    };
    getRes();
  }, []);

  return (
    <Grid container className={classes.mainContainer}>
      <Grid item className={classes.bgContainer}>
        <Box
          // className={classes.scrollableContainer} // Style for fixed height and scroll
          sx={{
            height: "600px", // Set a fixed height for the scrollable area
            overflowY: "scroll", // Enable vertical scrolling
            position: "relative", // Keeps it in the same place
          }}
        >
          <Box className={classes.manageContainer}>
            <Box ml={4} mt={2} mb={1}>
              <Typography variant="h6" className={classes.blackColor}>
                Opt-in Management
              </Typography>
            </Box>
          </Box>
          <OptInAndOptOutComponent
            textFieldRef={textFieldRef}
            keywords={optOutkeywords}
            showTextField={showOptOutTextField}
            currentKeyword={optOutCurrentKeyword}
            validKeyword={optOutvalidKeyword}
            handleKeywordChange={handleOptOutKeywordChange}
            handleAddKeywords={handleAddOptOutKeywords}
            handleSaveSettings={handleOptOutSaveSettings}
            handleDeleteKeyword={handleDeleteOptOutKeyword}
            handleEditResponse={handleOptOutEditResponse}
            showResponseTextField={optOutEdit}
            responseMessage={optOutResponseMessage}
            setResponseMessage={setOptOutResponseMessage}
            handleSwitchChange={handleSwitchOptOutChange}
            isChecked={isOptOutChecked}
            handleResponseChange={handleOptOutResponseChange}
            handleSaveResponse={handleOptOutSaveResponse}
            validationMessage={optOutValidationMessage}
            optStatus={optOutStatus || optedManagementStatus}
            validResponseMessage={optOutValidResponseMessage}
            setValidResponseMessage={setOptOutValidResponseMessage}
            mediaUrl={optOutMediaUrl}
            setMediaUrl={setOptOutMediaUrl}
            openTextField={optOutOpenTextField}
            // bgColors={{ gray2: "#f0f0f0" }} // Pass appropriate colors
            // classes={/* Your classes object here */}
            type="opt-out"
            label="Opt-out Keywords"
          />

          <OptInAndOptOutComponent
            textFieldRef={textFieldRef}
            keywords={optInkeywords}
            showTextField={showOptInTextField}
            currentKeyword={optInCurrentKeyword}
            validKeyword={optInvalidKeyword}
            handleKeywordChange={handleOptInKeywordChange}
            handleAddKeywords={handleAddOptInKeywords}
            handleSaveSettings={handleOptInSaveSettings}
            handleDeleteKeyword={handleDeleteOptInKeyword}
            handleEditResponse={handleOptInEditResponse}
            showResponseTextField={optInEdit}
            responseMessage={optInResponseMessage}
            setResponseMessage={setOptInResponseMessage}
            handleSwitchChange={handleSwitchOptInChange}
            isChecked={isOptInChecked}
            handleResponseChange={handleOptInResponseChange}
            handleSaveResponse={handleOptInSaveResponse}
            validationMessage={optInValidationMessage}
            optStatus={optInStatus || optedManagementStatus}
            validResponseMessage={optInValidResponseMessage}
            setValidResponseMessage={setOptInValidResponseMessage}
            mediaUrl={optInMediaUrl}
            setMediaUrl={setOptInMediaUrl}
            openTextField={optInOpenTextField}
            // bgColors={{ gray2: "#f0f0f0" }} // Pass appropriate colors
            // classes={/* Your classes object here */}
            type="opt-in"
            label="Opt-in Keywords"
          />
        </Box>
      </Grid>
    </Grid>
  );
};
export default OptinManagement;
