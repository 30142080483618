import React from "react";

const FileIconSvg = () => {
  return (
    <svg
      width="46"
      height="36"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="1" y="1" width="54" height="54" rx="11" fill="white" />
      <rect
        x="1"
        y="1"
        width="54"
        height="54"
        rx="11"
        stroke="#DBDBDB"
        strokeWidth="2"
      />
      <path
        d="M38.9375 21.4375C39.0833 21.5833 39.2292 21.7708 39.375 22H32V14.625C32.2292 14.7708 32.4167 14.9167 32.5625 15.0625L38.9375 21.4375ZM31.5 24H40V40.5C40 40.9167 39.8542 41.2708 39.5625 41.5625C39.2708 41.8542 38.9167 42 38.5 42H17.5C17.0833 42 16.7292 41.8542 16.4375 41.5625C16.1458 41.2708 16 40.9167 16 40.5V15.5C16 15.0833 16.1458 14.7292 16.4375 14.4375C16.7292 14.1458 17.0833 14 17.5 14H30V22.5C30 22.9167 30.1458 23.2708 30.4375 23.5625C30.7292 23.8542 31.0833 24 31.5 24ZM34 35.5V34.5C34 34.3542 33.9531 34.2344 33.8594 34.1406C33.7656 34.0469 33.6458 34 33.5 34H22.5C22.3542 34 22.2344 34.0469 22.1406 34.1406C22.0469 34.2344 22 34.3542 22 34.5V35.5C22 35.6458 22.0469 35.7656 22.1406 35.8594C22.2344 35.9531 22.3542 36 22.5 36H33.5C33.6458 36 33.7656 35.9531 33.8594 35.8594C33.9531 35.7656 34 35.6458 34 35.5ZM34 31.5V30.5C34 30.3542 33.9531 30.2344 33.8594 30.1406C33.7656 30.0469 33.6458 30 33.5 30H22.5C22.3542 30 22.2344 30.0469 22.1406 30.1406C22.0469 30.2344 22 30.3542 22 30.5V31.5C22 31.6458 22.0469 31.7656 22.1406 31.8594C22.2344 31.9531 22.3542 32 22.5 32H33.5C33.6458 32 33.7656 31.9531 33.8594 31.8594C33.9531 31.7656 34 31.6458 34 31.5ZM34 27.5V26.5C34 26.3542 33.9531 26.2344 33.8594 26.1406C33.7656 26.0469 33.6458 26 33.5 26H22.5C22.3542 26 22.2344 26.0469 22.1406 26.1406C22.0469 26.2344 22 26.3542 22 26.5V27.5C22 27.6458 22.0469 27.7656 22.1406 27.8594C22.2344 27.9531 22.3542 28 22.5 28H33.5C33.6458 28 33.7656 27.9531 33.8594 27.8594C33.9531 27.7656 34 27.6458 34 27.5Z"
        fill="#B4B4B4"
      />
    </svg>
  );
};

export default FileIconSvg;
