import { Box, Grid, Typography } from "@mui/material";
import LogOut from "../../assets/svgs/LogOut";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router";
import SignOutPopUp from "../SignOutComponents/SignOutPopUp";
import { useAppSelector } from "../../utils/redux-hooks";
import LoadingComponent from "../common/LoadingComponent";
import { bgColors } from "../../utils/bgColors";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import PeopleIcon from "@mui/icons-material/People";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import RoomPreferencesIcon from "@mui/icons-material/RoomPreferences";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import HelpIcon from "@mui/icons-material/Help";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import {
  hasHelpCenterPermission,
  hasManageAccountPermission,
  hasManageClientsPermission,
  hasManageCompanyPermission,
  hasManageNotificationsPermission,
  hasManagePermissionPermission,
  hasManageSubscriptionPermission,
} from "../../utils/permissions";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";

const useStyles = makeStyles({
  container: {
    backgroundColor: "#F8F8F8",
    height: "100vh",
    textAlign: "center",
    // backgroundColor:"red",
  },
  sideBarContainer: {
    display: "flex",
    flexDirection: "row",
    // justifyContent: "center",
    alignItems: "center",
  },
  manageContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "80vh",
  },
  manageItem: {
    cursor: "pointer",
    padding: "5px",
  },
  activeItem: {
    backgroundColor: "#f0f0f0",
    color: "#303030 !important",
    "& .MuiTypography-root": {
      fontWeight: "600 !important",
    },
  },
  signOutContainer: {
    marginTop: "16px",
    marginLeft: "40px",
    display: "flex",
    flexDirection: "row",
    // justifyContent: "center",
    alignItems: "center",
  },
  textColor: {
    color: "#4B5A5A",
  },
  blackColor: {
    color: "rgba(0, 0, 0, 0.8)",
    fontWeight: "600 !important",
    fontsize: "24px",
  },
  sideBar: {
    position: "sticky",
    top: 0,
    height: "100vh",
    overflowY: "auto",
  },
});

const ProfileSideBar = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const getUserImage = localStorage.getItem("userImage");

  const userInfoSlice = useAppSelector((state: any) => state.adminLogin);
  const userInfo = userInfoSlice?.data;
  const getuserPermissionSlice = useAppSelector(
    (state: any) => state.getUserPermissions
  );
  const accountData = useAppSelector((state: any) => state?.accountData?.data);
  const currentPlanDetails = useAppSelector(
    (state: any) => state?.wallet?.walletAndSubscription?.data
  );
  const getuserPermissionData = useAppSelector(
    (state: any) => state.getUserPermissions?.data
  );

  const [openDialog, setOpenDialog] = useState(false);
  const [activeSection, setActiveSection] = useState("");
  // const getManageAccount = useAppSelector((state) => state.accountData);
  // const data = getManageAccount?.data;
  // console.log("getuserPermissionData", getuserPermissionData?.profile);

  // Function to handle navigation and set active section
  const handleNavigation = (path: string) => {
    navigate(`/profile/${path}`);
    setActiveSection(path);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    const path = location.pathname.split("/")[2];
    setActiveSection(path || "");
  }, [location.pathname]);

  // useEffect(() => {
  //   dispatch(fetchAccountDetails(userInfo?.userId));
  // }, []);

  const hasAccountPermission = hasManageAccountPermission(
    getuserPermissionData?.profile
  );
  const hasHelpcenterPermission = hasHelpCenterPermission(
    getuserPermissionData?.profile
  );
  const hasCompanyPermission = hasManageCompanyPermission(
    getuserPermissionData?.profile
  );
  const hasClientPermission = hasManageClientsPermission(
    getuserPermissionData?.profile
  );
  const hasPermissionsPermission = hasManagePermissionPermission(
    getuserPermissionData?.profile
  );
  const hasNotificationsPermission = hasManageNotificationsPermission(
    getuserPermissionData?.profile
  );
  const hasSubscriptionPermission = hasManageSubscriptionPermission(
    getuserPermissionData?.profile
  );

  const handleManageClick = () => {
    handleNavigation(`manage-account/${userInfo?.userId}`);
  };

  const handleManageCompanyClick = () => {
    handleNavigation(`manage-company/${userInfo?.companyId}`);
  };

  const handleManageClientsClick = () => {
    handleNavigation("manage-clients");
  };

  const handleManagePermissionClick = () => {
    handleNavigation("manage-permissions");
  };

  const handleManageNotificationsClick = () => {
    handleNavigation("manage-notifications");
  };

  const handleManageTagsClick = () => {
    handleNavigation("manage-tags");
  };

  const handleManageSubscriptionClick = () => {
    handleNavigation("subscription");
  };

  const handleHelpCenterClick = () => {
    handleNavigation("help-center");
  };

  // console.log("currentPlanDetails", currentPlanDetails);
  return (
    <Grid
      // sx={{ display: { xs: "none", md: "block" } }}
      className={classes.container}
      // sx={{width:{lg:"200px"}

      // }}
    >
      <Box className={classes.sideBarContainer} pt={4} ml={2} gap={2}>
        {/* <Box m={{ xs: 1, md: 2 }} mb={0}> */}
        <img
          alt="profile"
          src={
            getUserImage
              ? getUserImage
              : userInfo?.userImage || "/images/profile.png"
          }
          style={{ width: "32px", height: "32px", borderRadius: "6px" }}
        />
        {/* </Box> */}
        <Typography variant="h6" className={classes.blackColor}>
          Profile
        </Typography>
      </Box>
      <Box className={classes.manageContainer} pt={2}>
        {getuserPermissionSlice?.status === "loading" ? (
          <LoadingComponent color={bgColors?.blue} height="100%" />
        ) : (
          <Box>
            {hasAccountPermission && (
              <Box
                mt={2}
                ml={2}
                className={`${classes.manageItem} ${classes.textColor} ${
                  activeSection.includes("manage-account")
                    ? classes.activeItem
                    : ""
                }`}
                onClick={handleManageClick}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    textAlign: "start",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span style={{ marginTop: "5px" }}>
                    <ManageAccountsIcon sx={{ height: "20px" }} />
                  </span>
                  &nbsp;Manage Account
                </Typography>
              </Box>
            )}
            {hasCompanyPermission && (
              <Box
                mt={2}
                ml={2}
                className={`${classes.manageItem} ${classes.textColor} ${
                  activeSection.includes("manage-company")
                    ? classes.activeItem
                    : ""
                }`}
                onClick={handleManageCompanyClick}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    textAlign: "start",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span style={{ marginTop: "5px" }}>
                    <RoomPreferencesIcon sx={{ height: "20px" }} />
                  </span>
                  &nbsp;Manage Company
                </Typography>
              </Box>
            )}
            {userInfo?.isPlatform && (
              // (userInfo?.roleName === "Admin" ||
              //   userInfo?.roleName === "Owner") &&
              <>
                {hasClientPermission && (
                  <Box
                    mt={2}
                    ml={2}
                    className={`${classes.manageItem} ${classes.textColor} ${
                      activeSection.includes("manage-clients")
                        ? classes.activeItem
                        : ""
                    }`}
                    onClick={handleManageClientsClick}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        textAlign: "start",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <span style={{ marginTop: "5px" }}>
                        <PeopleIcon sx={{ height: "20px" }} />
                      </span>
                      &nbsp;Manage Clients
                    </Typography>
                  </Box>
                )}
              </>
            )}
            {currentPlanDetails?.subscriptionPlan?.isActive &&
              (accountData?.isMetaEnabled ||
                (accountData?.isMetaEnabled === false &&
                  currentPlanDetails?.subscriptionPlan?.planName ===
                    "Intro")) &&
              hasPermissionsPermission && (
                <Box
                  mt={2}
                  ml={2}
                  className={`${classes.manageItem} ${classes.textColor} ${
                    activeSection.includes("manage-permissions")
                      ? classes.activeItem
                      : ""
                  }`}
                  onClick={handleManagePermissionClick}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      textAlign: "start",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <span style={{ marginTop: "5px" }}>
                      <AdminPanelSettingsIcon sx={{ height: "20px" }} />
                    </span>
                    &nbsp;Manage Permissions
                  </Typography>
                </Box>
              )}
            {currentPlanDetails?.subscriptionPlan?.isActive &&
              (accountData?.isMetaEnabled ||
                (accountData?.isMetaEnabled === false &&
                  currentPlanDetails?.subscriptionPlan?.planName ===
                    "Intro")) &&
              hasNotificationsPermission && (
                <Box
                  mt={2}
                  ml={2}
                  className={`${classes.manageItem} ${classes.textColor} ${
                    activeSection.includes("manage-notifications")
                      ? classes.activeItem
                      : ""
                  }`}
                  onClick={handleManageNotificationsClick}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      textAlign: "start",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <span style={{ marginTop: "5px" }}>
                      <CircleNotificationsIcon sx={{ height: "20px" }} />
                    </span>
                    &nbsp;Manage Notifications
                  </Typography>
                </Box>
              )}
            {/* {userInfo?.roleName !== "Admin" && ( */}
            <Box
              mt={2}
              ml={2}
              className={`${classes.manageItem} ${classes.textColor} ${
                activeSection.includes("manage-tags") ? classes.activeItem : ""
              }`}
              onClick={handleManageTagsClick}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  textAlign: "start",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span style={{ marginTop: "5px" }}>
                  <LocalOfferIcon sx={{ height: "20px" }} />
                </span>
                &nbsp;Tags
              </Typography>
            </Box>
            {hasSubscriptionPermission && (
              <Box
                mt={2}
                ml={2}
                className={`${classes.manageItem} ${classes.textColor} ${
                  activeSection.includes("subscription") ||
                  activeSection.includes("upgrade-plan")
                    ? classes.activeItem
                    : ""
                }`}
                onClick={handleManageSubscriptionClick}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    textAlign: "start",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span style={{ marginTop: "5px" }}>
                    <SubscriptionsIcon sx={{ height: "20px" }} />
                  </span>
                  &nbsp;Subscription
                </Typography>
              </Box>
            )}
            {/* )} */}
            {hasHelpcenterPermission && (
              <Box
                mt={2}
                ml={2}
                className={`${classes.manageItem} ${classes.textColor} ${
                  activeSection.includes("help-center")
                    ? classes.activeItem
                    : ""
                }`}
                onClick={handleHelpCenterClick}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    textAlign: "start",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span style={{ marginTop: "5px" }}>
                    <HelpIcon sx={{ height: "20px" }} />
                  </span>
                  &nbsp;Help Center
                </Typography>
              </Box>
            )}
          </Box>
        )}
        <Box
          className={`${classes.signOutContainer} ${classes.textColor} ${
            activeSection === "signOut" ? classes.activeItem : ""
          }`}
          mr={5}
        >
          <LogOut />
          <Typography
            sx={{ fontSize: "14px", cursor: "pointer", textAlign: "start" }}
            mx={2}
            // mt={2}
            // ml={2}
            onClick={handleOpenDialog}
          >
            Sign Out
          </Typography>
          <SignOutPopUp open={openDialog} handleClose={handleCloseDialog} />
        </Box>
      </Box>
    </Grid>
  );
};

export default ProfileSideBar;
