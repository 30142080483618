import React from "react";

const AnalyticsSvg = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_124_6541)">
        <path
          d="M14.7622 15.1907V20.5638C14.7979 21.268 14.6896 21.9721 14.4438 22.6332C14.198 23.2944 13.8199 23.8988 13.3325 24.4098C12.8451 24.9208 12.2585 25.3277 11.6084 25.6057C10.9583 25.8837 10.2583 26.0271 9.55084 26.0271C8.84342 26.0271 8.14341 25.8837 7.49331 25.6057C6.84322 25.3277 6.25661 24.9208 5.76918 24.4098C5.28175 23.8988 4.90368 23.2944 4.65791 22.6332C4.41214 21.9721 4.30384 21.268 4.33955 20.5638V15.1907C4.40713 13.8586 4.98565 12.6032 5.95555 11.6842C6.92546 10.7651 8.21256 10.2526 9.55084 10.2526C10.8891 10.2526 12.1763 10.7651 13.1462 11.6842C14.1161 12.6032 14.6946 13.8586 14.7622 15.1907ZM27.5009 5.20066V20.5638C27.5009 21.9431 26.9512 23.2659 25.9726 24.2413C24.994 25.2166 23.6668 25.7645 22.283 25.7645C20.8991 25.7645 19.5719 25.2166 18.5933 24.2413C17.6147 23.2659 17.065 21.9431 17.065 20.5638V5.20066C17.065 3.82136 17.6147 2.49856 18.5933 1.52325C19.5719 0.547934 20.8991 0 22.283 0C23.6668 0 24.994 0.547934 25.9726 1.52325C26.9512 2.49856 27.5009 3.82136 27.5009 5.20066ZM29.9901 32H2.03671C1.50716 32 0.999288 31.7903 0.624839 31.4171C0.25039 31.0439 0.0400391 30.5377 0.0400391 30.0099C0.0400391 29.4821 0.25039 28.976 0.624839 28.6028C0.999288 28.2296 1.50716 28.0199 2.03671 28.0199H29.9901C30.5197 28.0199 31.0275 28.2296 31.402 28.6028C31.7764 28.976 31.9868 29.4821 31.9868 30.0099C31.9868 30.5377 31.7764 31.0439 31.402 31.4171C31.0275 31.7903 30.5197 32 29.9901 32Z"
          fill="#303030"
        />
      </g>
      <defs>
        <clipPath id="clip0_124_6541">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AnalyticsSvg;
