import React from "react";
import { Link, LinkProps } from "@mui/material";
import { bgColors } from "../../utils/bgColors";

interface CustomLinkProps {
  title: React.ReactNode;
}

type LinkPropsTypes = CustomLinkProps & LinkProps;

const LinkComponent: React.FC<LinkPropsTypes> = ({
  href = "#",
  title,
  target = "",
  underline = "none",
  color = "",
  fontFamily = "Inter",
  fontSize = "14px",
  style = {},
  ...rest
}) => {
  return (
    <Link
      href={href}
      underline={underline}
      target={target}
      color={color}
      fontFamily={fontFamily}
      fontSize={fontSize}
      // style={{ color: bgColors.green, ...style }}
      {...rest}
    >
      {title}
    </Link>
  );
};

export default LinkComponent;
