import React from "react";
import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  ThemeProvider,
  Tooltip,
  Typography,
  createTheme,
  keyframes,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import EditSvg from "../../../assets/svgs/EditSvg";
// import DeleteIconSvg from "../../../assets/svgs/DeleteIconSvg";
import EditIconSvg from "../../../assets/svgs/EditIconSvg";
import { bgColors } from "../../../utils/bgColors";
import { useNavigate, useParams } from "react-router-dom";
import AddNewTeamMember from "../../../components/ManageCompanyComponents/AddNewTeamMember";
import EditTeamMember from "../../../components/ManageCompanyComponents/EditTeamMember";
import { useAppDispatch, useAppSelector } from "../../../utils/redux-hooks";
import LoadingComponent from "../../../components/common/LoadingComponent";
import { fetchCompanyDetails } from "../../../redux/slices/ManageCompany/CompanyDetailsSlice";
import { ADMIN_MANAGE_COMPANY_APIS } from "../../../Apis/ManageCompany/ManageCompany";
import { toastActions } from "../../../utils/toastSlice";
import { fetchGetAllTeamMembersByCompanyId } from "../../../redux/slices/ManageCompany/GetAllTeamMembersByCompanyId";
import SearchIconSvg2 from "../../../assets/svgs/SearchIconSvg2";
// import { DeleteOutlineOutlined, EditOutlined } from "@mui/icons-material";
// import DevicePreview from "../../../components/ManageCompanyComponents/PreViewDevice";
import PreviewPopover from "../../../components/ManageCompanyComponents/PreviewPopOver";
import DeletePopUp from "../../../components/common/DeletePopup";
import useDebouncedFetch from "../../../utils/debounceHook";
// import { capitalizeWords } from "../manageClients/ManageClientDetails";
import RoomPreferencesIcon from "@mui/icons-material/RoomPreferences";
import GroupsIcon from "@mui/icons-material/Groups";
import { TablePagination } from "@mui/material";
import DeleteSvg from "../../../assets/svgs/DeleteSvg";
import { hasManageCompanyPermission } from "../../../utils/permissions";
import NoAccessPage from "../../../components/common/NoAccess";

const theme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          width: "14.2%",
        },
      },
    },
  },
});
const useStyles = makeStyles({
  mainContainer: {
    backgroundColor: bgColors.white1,
    padding: "14px",
    height: "100vh",
    width: "100%",
  },

  bgContainer: {
    backgroundColor: bgColors.white,
    borderRadius: "25px",
    height: "94%",
    width: "100%",
    overflow: "auto",
  },
  manageContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  mainBorderStyles: {
    borderRadius: "18px",
    border: `2px solid ${bgColors.gray5}`,
  },
  blackColor: {
    // color: `${bgColors.black1} !important`,
    // fontWeight: "600 !important",
  },
  grayColor: {
    color: `${bgColors.black1} !important`,
    opacity: "60% !important",
    fontWeight: "600 !important",
  },
  planButton: {
    backgroundColor: bgColors.green1,
    color: bgColors.green,
    // fontWeight: "700",
    paddingTop: 6,
    paddingRight: 22,
    paddingBottom: 6,
    paddingLeft: 22,
    borderRadius: 25,
  },
  expiredButton: {
    backgroundColor: "#F64E601A",
    color: "#F64E60",
    // fontWeight: "700",
    paddingTop: 6,
    paddingRight: 22,
    paddingBottom: 6,
    paddingLeft: 22,
    borderRadius: 25,
  },
  editButtonContainer: {
    // backgroundColor: bgColors.blue,
    borderRadius: "8px",
    border: `1px solid ${bgColors.green}`,
    height: "32px",
    display: "flex",
    flexDirection: "row",
    // alignItems: "center",
    justifyContent: "space-evenly",
    width: "130px",
    cursor: "pointer",
    // padding: "10px",
  },
  editButton: {
    backgroundColor: "transparent",
    color: bgColors.green,
    cursor: "pointer",
    borderRadius: "34px",
    marginLeft: 10,
  },
  emailContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  emailContainer1: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: `1px solid ${bgColors.gray5}`,
  },
  searchField: {
    width: "100%",
    borderRadius: "12px",
    // backgroundColor: bgColors.white2,
    backgroundColor: "white",
    // border: "2px solid black",
    "& input::placeholder": {
      textAlign: "left",
      fontSize: "14px",
      fontFamily: "Inter",
      color: bgColors.black,
      // fontWeight: "500 !important",
    },
  },
  SaveChangesButton: {
    // backgroundColor: bgColors.green,
    border: `1px solid ${bgColors.green}`,
    width: "120px",
    height: "32px",
    color: bgColors.green,
    borderRadius: "8px",
    cursor: "pointer",
  },
  table: {
    borderCollapse: "separate",
    borderSpacing: "0 8px",
    textAlign: "center",
    borderColor: "lightgray",
    "& th, & td": {
      // borderTop: '1px solid gray',
      borderBottom: "1px solid #f0f0f0",
      padding: "3px",
    },
    "& th:first-child, & td:first-child": {
      paddingLeft: "20px",
    },
    "& th:last-child, & td:last-child": {
      paddingRight: "20px",
    },
  },
  profileTextContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  warnText: {
    marginLeft: "auto",
    display: "flex",
    flexDirection: "row",
    textAlign: "center",
  },
  teamProfileContainer: {
    display: "flex",
    alignItems: "center",
  },
  manageTeamContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "20px",
    width: "full",
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
    marginRight: 10,
  },
});

const ManageCompany = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const params = useParams();
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const companyData = useAppSelector((state: any) => state.companyData);
  const companyDetails = companyData?.data && companyData?.data;
  // console.log(companyDetails);
  const planDetails = companyDetails?.plan;
  const loginData = useAppSelector((state: any) => state?.adminLogin?.data);
  // console.log(loginData)
  const getuserPermissionData = useAppSelector(
    (state: any) => state.getUserPermissions?.data
  );
  const hasCompanyPermission = hasManageCompanyPermission(
    getuserPermissionData?.profile
  );
  const manageCompanyObject = getuserPermissionData?.profile?.find(
    (item: any) =>
      Object?.prototype?.hasOwnProperty?.call(item, "manageCompany")
  );
  const manageCompanyActions = manageCompanyObject
    ? manageCompanyObject?.manageCompany
    : [];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const hasAcess = (permission: any) => {
    if (manageCompanyActions?.includes(permission)) {
      return true;
    }
    return false;
  };

  // const manageAccountActions = manageAccountObject ? manageAccountObject.manageAccount : [];
  const getAllTeamMembers = useAppSelector(
    (state: any) => state?.getAllTeamMembersByCompanyId
  );
  // console.log(getAllTeamMembers?.status)
  const teamMembersStatus = getAllTeamMembers?.status;
  // console.log(getAllTeamMembers, 'getAllTeamMembers');
  const getAllTeamMembersData = getAllTeamMembers?.data?.filter(
    (item: any) => item?.status === true
  );
  // console.log(getAllTeamMembersData)
  const debouncedFetchTeamMembers = useDebouncedFetch(
    fetchGetAllTeamMembersByCompanyId,
    1500
  );
  const [isHovered, setIsHovered] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
  const [animate, setAnimate] = useState(true);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [editTeamMember, setEditTeamMember] = useState("");
  const [searchTeamMember, setSearchTeamMember] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [editTooltip, setEditTooltip] = useState(false);
  const [addMemberTooltip, setAddMemberTooltip] = useState(false);
  const [editTeamMemberTooltip, setEditTeamMemberTooltip] = useState(null);
  const [deleteTeamMemberTooltip, setDeleteTeamMemberTooltip] = useState(null);

  // const earliestCreationDate = getAllTeamMembersData?.reduce(
  //   (earliest: any, member: any) => {
  //     return new Date(member.creationDate) < new Date(earliest)
  //       ? member.creationDate
  //       : earliest;
  //   },
  //   getAllTeamMembersData[0]?.creationDate
  // );

  const handleClickEdit = () => {
    const hasPermission = hasAcess("editCompanyDetails");
    if (hasPermission) {
      navigate(`/profile/manage-company/${loginData?.companyId}/edit`);
    } else {
      setEditTooltip(true);
      setTimeout(() => {
        setEditTooltip(false);
      }, 2000);
    }
  };

  const handleOpenDeleteDialog = (row?: any) => {
    const hasPermission = hasAcess("deleteTeamMember");
    if (hasPermission) {
      if (row !== undefined && row !== null) {
        setDeleteId(row?.id);
      }
      setOpenDeleteDialog(true);
    } else {
      setDeleteTeamMemberTooltip(row);
      setTimeout(() => {
        setDeleteTeamMemberTooltip(null);
      }, 2000);
    }
  };

  const handleCloseDelete = () => {
    setOpenDeleteDialog(false);
  };

  const handleOpenDialog = () => {
    const hasPermission = hasAcess("addTeamMember");
    if (hasPermission) {
      setOpenDialog(true);
    } else {
      setAddMemberTooltip(true);
      setTimeout(() => {
        setAddMemberTooltip(false);
      }, 2000);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handlePreviewOpenDialog = () => {
    setOpenPreviewDialog(true);
    setAnimate(false);
  };
  const handlePreviewCloseDialog = () => {
    setOpenPreviewDialog(false);
  };

  const handleOpenEditDialog = (row?: any) => {
    const hasPermission = hasAcess("editTeamMember");
    if (hasPermission) {
      if (row !== undefined && row !== null) {
        setEditTeamMember(row);
      }
      setOpenEditDialog(true);
    } else {
      setEditTeamMemberTooltip(row);
      setTimeout(() => {
        setEditTeamMemberTooltip(null);
      }, 2000);
    }
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
  };

  const handleDeleteTeamMember = async () => {
    // console.log("delete team member", deleteId);
    if (deleteId) {
      setIsDeleteLoading(true);
      try {
        const response = await ADMIN_MANAGE_COMPANY_APIS.deleteTeamMember(
          deleteId
        );
        if (response.status === 200) {
          dispatch(
            toastActions.setToaster({
              type: "success",
              message: response?.data?.message,
            })
          );
          const postData = {
            search: searchTeamMember,
            companyId: loginData?.companyId,
          };
          dispatch(fetchGetAllTeamMembersByCompanyId(postData));
          handleCloseDelete();
          // window.location.reload();
        }
      } catch (err: any) {
        dispatch(
          toastActions.setToaster({
            type: "error",
            message: err?.response?.data?.message,
          })
        );
        // window.location.reload();
      }
      setIsDeleteLoading(false);
    }
  };

  useEffect(() => {
    dispatch(fetchCompanyDetails(params?.id));
  }, [dispatch]);

  useEffect(() => {
    const postData = {
      search: searchTeamMember,
      companyId: loginData?.companyId,
    };

    debouncedFetchTeamMembers(postData);
  }, [dispatch, searchTeamMember]);

  // console.log("search", searchTeamMember);
  // console.log("all team members", getAllTeamMembersData);
  // console.log("loginData", loginData);

  const pulse = keyframes`
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(68, 71, 70, 0.4);
  }
  70% {
    transform: scale(1.05);
    box-shadow: 0 0 15px 5px rgba(68, 71, 70, 0.4);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(68, 71, 70, 0.4);
  }
`;

  const [hoveredRow, setHoveredRow] = useState(null);

  const handleRowHover = (rowId: any) => {
    setHoveredRow(rowId);
  };

  const splitCamelCaseAndJoin = (str: string): string => {
    // Replace special characters with a space
    const cleanedStr = str.replace(/[^a-zA-Z0-9]+/g, " ");
    // Split the string at capital letters and join with a space
    const splitStr = cleanedStr.replace(/([a-z])([A-Z])/g, "$1 $2");
    // Capitalize the first letter of each word
    const result = splitStr.replace(/\b\w/g, (char: string) =>
      char.toUpperCase()
    );
    return result;
  };

  return (
    <>
      {hasCompanyPermission ? (
        <Grid
          className={classes.mainContainer}
          sx={{ height: getAllTeamMembersData?.length > 1 ? "100%" : "auto" }}
        >
          {companyData.status === "loading" ? (
            <Box sx={{ height: "100vh" }}>
              {" "}
              <LoadingComponent height="100%" color={bgColors.blue} />
            </Box>
          ) : (
            <Grid className={classes.bgContainer}>
              <Box
                pt={{ xs: 1, md: 4 }}
                px={{ xs: 1, md: 4 }}
                className={classes.manageContainer}
              >
                <Typography
                  variant="h6"
                  className={classes.blackColor}
                  sx={{ display: "flex" }}
                >
                  <span style={{ marginTop: "5px" }}>
                    <RoomPreferencesIcon
                      sx={{ height: "25px", marginTop: "-2px" }}
                    />
                  </span>
                  &nbsp;Manage Company
                </Typography>
              </Box>
              <Box m={3}>
                <Box p={3} className={classes.mainBorderStyles}>
                  <Box sx={{ display: { xs: "block", md: "flex" } }}>
                    <Box>
                      <img
                        alt="profile"
                        src={
                          companyDetails?.company?.companyLogoLink ||
                          "/images/companyLogo.png"
                        }
                        style={{
                          width: "70px",
                          height: "70px",
                          borderRadius: "14px",
                        }}
                      />
                    </Box>
                    <Box className={classes.profileTextContainer}>
                      <Box ml={{ xs: 0, md: 3 }} mt={1}>
                        <Typography
                          variant="body2"
                          className={classes.blackColor}
                        >
                          {companyDetails?.company?.businessName || ""}
                        </Typography>
                        <Typography
                          variant="body2"
                          mt={2}
                          className={classes.grayColor}
                        >
                          {companyDetails?.company?.businessCategory
                            ? splitCamelCaseAndJoin(
                                companyDetails?.company?.businessCategory
                              )
                            : ""}
                        </Typography>
                      </Box>

                      <Box ml={3} mt={1}>
                        <button
                          className={
                            planDetails?.subscriptionPlan?.isActive
                              ? classes.planButton
                              : classes.expiredButton
                          }
                        >
                          {planDetails?.subscriptionPlan?.isActive
                            ? `${planDetails?.subscriptionPlan?.planName?.toUpperCase()} PLAN`
                            : `${planDetails?.subscriptionPlan?.planName?.toUpperCase()} PLAN EXPIRED`}
                        </button>
                      </Box>
                    </Box>
                    <Tooltip
                      title="Access Denied"
                      placement="top"
                      open={editTooltip}
                      onClose={() => setEditTooltip(false)}
                    >
                      <Box>
                        <Box
                          ml={{ xs: "none", md: "auto" }}
                          className={classes.editButtonContainer}
                          onClick={handleClickEdit}
                          sx={{
                            "&:hover": {
                              cursor: "pointer",
                              backgroundColor: "rgba(68, 71, 70, 0.08)",
                            },
                          }}
                        >
                          <button className={classes.editButton}>
                            Edit Details
                          </button>

                          <Box className={classes.flexCenter}>
                            <EditSvg
                              style={{
                                fill: bgColors.green,
                              }}
                            />
                          </Box>
                        </Box>
                        <Box className={classes.warnText}>
                          <Typography
                            sx={{ fontSize: 10 }}
                            className={classes.grayColor}
                          >
                            *Only owner & admins can edit
                          </Typography>
                        </Box>
                      </Box>
                    </Tooltip>
                  </Box>

                  <Box
                    mt={{ xs: 2, md: 3 }}
                    // mr={3}
                    sx={{
                      justifyContent: "space-between",
                      display: { xs: "block", md: "flex" },
                    }}
                  >
                    <Box
                      mb={{ xs: 2, md: 0 }}
                      sx={{
                        width: "38%",
                      }}
                    >
                      <Typography variant="body2" className={classes.grayColor}>
                        Company Email
                      </Typography>
                      <Typography
                        variant="body2"
                        className={classes.blackColor}
                      >
                        {companyDetails?.company?.businessEmail || ""}
                      </Typography>
                    </Box>
                    <Box
                      mb={{ xs: 2, md: 0 }}
                      mx={{ xs: 2, md: 4 }}
                      sx={{
                        width: "30%",
                      }}
                    >
                      <Typography variant="body2" className={classes.grayColor}>
                        Phone
                      </Typography>
                      <Typography
                        variant="body2"
                        className={classes.blackColor}
                      >
                        {companyDetails?.company?.countryCode &&
                        companyDetails?.company?.phoneNumber
                          ? companyDetails?.company?.countryCode +
                              " " +
                              companyDetails?.company?.phoneNumber || ""
                          : ""}
                        {/* djnnfuf cnfnffu */}
                      </Typography>
                    </Box>
                    <Box
                      mb={{ xs: 2, md: 0 }}
                      mr={{ xs: 0, md: 0 }}
                      sx={{
                        width: "23%",
                      }}
                    >
                      <Typography variant="body2" className={classes.grayColor}>
                        GST
                      </Typography>
                      <Tooltip title={companyDetails?.company?.gstNumber || ""}>
                        <Typography
                          variant="body2"
                          className={classes.blackColor}
                        >
                          {companyDetails?.company?.gstNumber || ""}
                        </Typography>
                      </Tooltip>
                    </Box>
                  </Box>
                  <Box
                    mt={{ xs: 2, md: 3 }}
                    // mr={3}
                    sx={{
                      justifyContent: "space-between",
                      display: { xs: "block", md: "flex" },
                    }}
                  >
                    <Box
                      mb={{ xs: 2, md: 0 }}
                      mr={{
                        xs: 0,
                        md: 0,
                        // md:companyDetails?.countryName ?:1
                      }}
                      sx={{
                        width: "38%",
                      }}
                    >
                      <Typography
                        variant="body2"
                        mb={1}
                        className={classes.grayColor}
                      >
                        Country
                      </Typography>
                      <Typography
                        variant="body2"
                        className={classes.blackColor}
                      >
                        {companyDetails?.company?.countryName || ""}
                      </Typography>
                    </Box>
                    <Box
                      mb={{ xs: 2, md: 0 }}
                      mx={{ xs: 2, md: 4 }}
                      sx={{
                        width: "30%",
                      }}
                    >
                      <Typography
                        variant="body2"
                        mb={1}
                        className={classes.grayColor}
                      >
                        Company Website
                      </Typography>
                      <Typography
                        variant="body2"
                        className={classes.blackColor}
                      >
                        {companyDetails?.company?.businessWebsite || ""}
                        {/* ahextexhndshcdshcnjk */}
                      </Typography>
                    </Box>
                    <Box
                      mb={{ xs: 2, md: 0 }}
                      mr={{
                        xs: 0,
                        // md:16.5
                        md: 0,
                      }}
                      sx={{
                        width: "23%",
                      }}
                    >
                      <Typography
                        variant="body2"
                        mb={1}
                        // className={classes.grayColor}
                      ></Typography>
                      <Button
                        // className={classes.blackColor}
                        onClick={() => {
                          handlePreviewOpenDialog();
                        }}
                        //  className={classes.editButtonContainer}
                        sx={{
                          width: "120px",
                          height: "32px",
                          fontSize: 10,
                          "&:hover": {
                            cursor: "pointer",
                            backgroundColor: "rgba(68, 71, 70, 0.08)",
                          },
                          //  color: `${bgColors.black1} !important`,
                          opacity: "60% !important",
                          // fontWeight: "600 !important",
                          borderRadius: "8px",
                          border: `1px solid ${bgColors?.green}`,
                          color: bgColors?.green,
                          animation: animate ? `${pulse} 2s` : "none",
                        }}
                      >
                        {/* {companyDetails?.company?.businessWebsite || ""} */}
                        {/* ahextexhndshcdshcnjk */}
                        Profile preview
                      </Button>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <Box>
                      <Box mt={3}>
                        {/* <Box mb={{ xs: 2, md: 0 }} sx={{ width: "130px" }}> */}
                        <Typography
                          variant="body2"
                          mb={1}
                          className={classes.grayColor}
                        >
                          Company Address
                        </Typography>
                        <Typography
                          variant="body2"
                          className={classes.blackColor}
                        >
                          {companyDetails?.company?.companyAddress || ""}
                        </Typography>
                        {/* </Box> */}
                      </Box>
                      <Box mt={3}>
                        <Typography
                          variant="body2"
                          mb={1}
                          className={classes.grayColor}
                        >
                          Description
                        </Typography>
                        <Typography
                          variant="body2"
                          className={classes.blackColor}
                        >
                          {companyDetails?.company?.description || ""}
                        </Typography>
                      </Box>
                    </Box>
                    <Box>
                      <PreviewPopover
                        open={openPreviewDialog}
                        handleClose={handlePreviewCloseDialog}
                      />
                      {/* <DevicePreview /> */}
                    </Box>
                  </Box>
                </Box>
                {/* {loginData && !loginData?.roleName?.includes("Admin") && ( */}
                <Box mt={3}>
                  <Box p={3} className={classes.mainBorderStyles}>
                    <Box>
                      <Typography
                        className={classes.blackColor}
                        variant="body2"
                      >
                        Compliance Info
                      </Typography>
                    </Box>
                    <Box mt={3} pb={3} className={classes.emailContainer1}>
                      <Box
                        sx={{
                          width: "38%",
                        }}
                      >
                        <Typography
                          variant="body2"
                          mb={1}
                          className={classes.grayColor}
                        >
                          Legal name of Company
                        </Typography>
                        <Typography
                          variant="body2"
                          className={classes.blackColor}
                        >
                          {companyDetails?.company?.companyLegalName || ""}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          width: "30%",
                        }}
                        mx={{ xs: 2, md: 4 }}
                      >
                        <Typography
                          variant="body2"
                          mb={1}
                          className={classes.grayColor}
                        >
                          Company Type
                        </Typography>
                        <Typography
                          variant="body2"
                          className={classes.blackColor}
                        >
                          {splitCamelCaseAndJoin(
                            companyDetails?.company?.companyType || ""
                          )}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          width: "23%",
                        }}
                      >
                        <Typography
                          variant="body2"
                          mb={1}
                          className={classes.grayColor}
                        >
                          Company Registered
                        </Typography>
                        <Typography
                          variant="body2"
                          className={classes.blackColor}
                        >
                          {companyDetails?.company?.companyRegistered
                            ? "Yes"
                            : "No"}
                        </Typography>
                      </Box>
                      <Box></Box>
                    </Box>
                    <Box>
                      <Box mt={3}>
                        <Typography
                          className={classes.blackColor}
                          variant="body2"
                        >
                          Customer Care Information
                        </Typography>
                      </Box>
                      <Box mt={3} pb={3} className={classes.emailContainer1}>
                        <Box
                          sx={{
                            width: "38%",
                          }}
                        >
                          <Typography
                            variant="body2"
                            mb={1}
                            className={classes.grayColor}
                          >
                            Customer Care Email
                          </Typography>
                          <Typography
                            variant="body2"
                            className={classes.blackColor}
                          >
                            {companyDetails?.company?.customerCareEmail || ""}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            width: "30%",
                          }}
                          mx={{ xs: 2, md: 4 }}
                        >
                          <Typography
                            variant="body2"
                            mb={1}
                            className={classes.grayColor}
                          >
                            Phone
                          </Typography>
                          <Typography
                            variant="body2"
                            className={classes.blackColor}
                          >
                            {companyDetails?.company?.customerCareCountryCode &&
                            companyDetails?.company?.customerCarePhone
                              ? companyDetails?.company
                                  ?.customerCareCountryCode +
                                  " " +
                                  companyDetails?.company?.customerCarePhone ||
                                ""
                              : ""}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            width: "23%",
                          }}
                        ></Box>
                        {/* <Box></Box> */}
                      </Box>
                      <Box mt={3}>
                        <Typography
                          className={classes.blackColor}
                          variant="body2"
                        >
                          Grievance Officer Information
                        </Typography>
                      </Box>
                      <Box mt={3} pb={3} className={classes.emailContainer1}>
                        <Box
                          sx={{
                            width: "38%",
                          }}
                        >
                          <Typography
                            variant="body2"
                            mb={1}
                            className={classes.grayColor}
                          >
                            Name
                          </Typography>
                          <Typography
                            variant="body2"
                            className={classes.blackColor}
                          >
                            {companyDetails?.company?.grievanceOfficerName ||
                              ""}
                          </Typography>
                        </Box>
                        <Box
                          mx={{ xs: 2, md: 4 }}
                          sx={{
                            width: "30%",
                          }}
                        >
                          <Typography
                            variant="body2"
                            mb={1}
                            className={classes.grayColor}
                          >
                            Email
                          </Typography>
                          <Typography
                            variant="body2"
                            className={classes.blackColor}
                          >
                            {/* sfvbhsvus */}
                            {companyDetails?.company?.grievanceOfficerEmail ||
                              ""}
                          </Typography>
                        </Box>
                        <Box
                          // sx={{
                          //   marginRight: companyDetails?.company?.grievanceOfficerPhone
                          //     ? "0px"
                          //     : "15px",
                          // }}
                          sx={{
                            width: "23%",
                          }}
                        >
                          <Typography
                            variant="body2"
                            mb={1}
                            className={classes.grayColor}
                          >
                            Phone
                          </Typography>
                          <Typography
                            variant="body2"
                            className={classes.blackColor}
                          >
                            {companyDetails?.company
                              ?.grievanceOfficerCountryCode &&
                            companyDetails?.company?.grievanceOfficerPhone
                              ? companyDetails?.company
                                  ?.grievanceOfficerCountryCode +
                                  " " +
                                  companyDetails?.company
                                    ?.grievanceOfficerPhone || ""
                              : ""}
                          </Typography>
                        </Box>
                        {/* <Box></Box> */}
                      </Box>
                    </Box>
                  </Box>
                </Box>
                {/* )} */}
              </Box>
              {/* {getAllTeamMembers.status === "loading" ? (
            <LoadingComponent height="100%" color={bgColors.blue} />
          ) : ( */}
              <Box>
                <Box m={3} className={classes.manageTeamContainer}>
                  {/* <Box> */}
                  <Typography
                    variant="h6"
                    sx={{ flexShrink: 0, display: "flex", width: "170px" }}
                    className={classes.blackColor}
                  >
                    <span style={{ marginTop: "5px" }}>
                      <GroupsIcon sx={{ height: "25px", marginTop: "-2px" }} />
                    </span>
                    &nbsp;Manage Team
                  </Typography>
                  {/* </Box> */}
                  {/* <Grid xs={2.5} ml={3}> */}
                  {/* <Box sx={{ width: '100%', mx: 3 }}> */}
                  <TextField
                    className={classes.searchField}
                    variant="standard"
                    size="small"
                    fullWidth
                    value={searchTeamMember}
                    onChange={(e) => setSearchTeamMember(e.target.value)}
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        padding: "10px",
                        fontSize: "12px",
                        // fontWeight: "600 !important",
                        height: "38px",
                        // border: "1px solid gray",
                        borderRadius: "14px",
                      },
                      startAdornment: (
                        <IconButton
                          sx={{ p: 0, color: "inherit", paddingTop: "3px" }}
                          aria-label="search"
                        >
                          {/* <SearchIconSvg /> */}
                          <SearchIconSvg2 />
                        </IconButton>
                      ),
                    }}
                    inputProps={{
                      style: {
                        // Additional style for placeholder
                        fontWeight: "600 !important",
                        padding: "0px", // Apply font weight here
                      },
                    }}
                    placeholder="Search team members"
                  />
                  {/* </Box> */}
                  {/* </Grid> */}
                  <Tooltip
                    title="Access Denied"
                    placement="top"
                    open={addMemberTooltip}
                    onClose={() => setAddMemberTooltip(false)}
                  >
                    <Box
                      // sx={{ marginLeft: "auto", marginRight: "25px" }}
                      onClick={handleOpenDialog}
                    >
                      <button
                        className={classes.SaveChangesButton}
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                        style={{
                          // backgroundColor:"#fff",
                          backgroundColor: isHovered
                            ? "rgba(68, 71, 70, 0.08)"
                            : "#fff",
                          cursor: isHovered ? "pointer" : "default",
                        }}
                      >
                        + Add Member
                      </button>
                    </Box>
                  </Tooltip>
                  <AddNewTeamMember
                    open={openDialog}
                    handleClose={handleCloseDialog}
                  />
                </Box>
                <ThemeProvider theme={theme}>
                  <TableContainer
                    component={Box}
                    mt={3}
                    sx={{
                      overflow: "unset",
                    }}
                  >
                    <Table className={classes.table}>
                      <TableHead className={classes.grayColor}>
                        <TableRow>
                          <TableCell style={{ width: "20%" }}>Member</TableCell>
                          <TableCell style={{ width: "15%" }}>
                            Created by
                          </TableCell>
                          <TableCell style={{ width: "20%" }}>Email</TableCell>
                          <TableCell style={{ width: "15%" }}>
                            Contact
                          </TableCell>
                          <TableCell style={{ width: "15%" }}>
                            Created On
                          </TableCell>
                          <TableCell style={{ width: "10%" }}>Role</TableCell>
                          <TableCell style={{ width: "5%" }}>Action</TableCell>
                        </TableRow>
                      </TableHead>

                      {teamMembersStatus !== "loading" ? (
                        <TableBody>
                          {getAllTeamMembersData &&
                          getAllTeamMembersData?.length > 0 ? (
                            getAllTeamMembersData
                              ?.slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((row: any) => {
                                const isFirstCreated = row.roleId === "Owner";
                                return (
                                  <TableRow
                                    key={row.id}
                                    onMouseEnter={() => handleRowHover(row.id)}
                                    onMouseLeave={() => setHoveredRow(null)}
                                    // onClick={() => handleRowSelect(row.contactId)}
                                    sx={{
                                      cursor: "pointer",
                                      boxShadow: row.id === hoveredRow ? 3 : 0,
                                      transition: "box-shadow 0.3s",
                                    }}
                                  >
                                    <TableCell>
                                      <Box
                                        className={classes.teamProfileContainer}
                                      >
                                        <Avatar
                                          alt={row.name}
                                          src={row.image}
                                          style={{
                                            width: "30px",
                                            height: "30px",
                                          }}
                                        />
                                        <Tooltip title={row.name} arrow>
                                          <Typography
                                            className={classes.blackColor}
                                            style={{
                                              marginLeft: "4px",
                                              fontSize: "12px",
                                              whiteSpace: "nowrap",
                                              overflow: "hidden",
                                              textOverflow: "ellipsis",
                                              maxWidth: "150px",
                                            }}
                                          >
                                            {row.name}
                                          </Typography>
                                        </Tooltip>
                                      </Box>
                                    </TableCell>
                                    <TableCell
                                      className={classes.blackColor}
                                      sx={{ fontSize: "12px" }}
                                    >
                                      {row.createdBy}
                                    </TableCell>
                                    <TableCell
                                      className={classes.blackColor}
                                      sx={{ fontSize: "12px" }}
                                    >
                                      {row.emailAddress}
                                    </TableCell>
                                    <TableCell
                                      className={classes.blackColor}
                                      sx={{ fontSize: "12px" }}
                                    >
                                      {row?.countryCode && row?.phoneNumber
                                        ? row?.countryCode +
                                            " " +
                                            row?.phoneNumber || ""
                                        : ""}
                                      {/* {row?.countryCode + " " + row?.phoneNumber} */}
                                    </TableCell>
                                    <TableCell
                                      className={classes.blackColor}
                                      sx={{ fontSize: "12px" }}
                                    >
                                      {/* {new Date(row?.creationDate).toLocaleDateString(
                              "en-US",
                              {
                                month: "short",
                                day: "2-digit",
                                year: "numeric",
                              }                             
                            )} */}
                                      {row?.creationDate
                                        ? new Date(
                                            row?.creationDate
                                          ).toLocaleDateString("en-US", {
                                            month: "short",
                                            day: "2-digit",
                                            year: "numeric",
                                          })
                                        : "N/A"}
                                    </TableCell>
                                    <TableCell
                                      className={classes.blackColor}
                                      sx={{ fontSize: "12px" }}
                                    >
                                      {row.roleId}
                                    </TableCell>
                                    {/* <TableCell>
                                  {companyDetails?.company?.createdAt ===
                                    row?.creationDate ? (
                                    <Box
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Box
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Tooltip title={isFirstCreated ? "Access Denied" : "Edit"}>
                                          <Box
                                            mr={1}
                                            onClick={() => !isFirstCreated && handleOpenEditDialog(row)}
                                            sx={{
                                              cursor: isFirstCreated ? 'not-allowed' : 'pointer',
                                              opacity: isFirstCreated ? 0.3 : 1,
                                            }}
                                          >
                                            <EditIconSvg />
                                          </Box>
                                        </Tooltip>
                                        <Tooltip
                                          title={isFirstCreated ? "Access Denied" : "Delete"}
                                        // sx={{ cursor: "pointer" }}
                                        // onClick={() => handleOpenDeleteDialog(row)}
                                        >
                                          <Box
                                            onClick={() => !isFirstCreated && handleOpenDeleteDialog(row)}
                                            sx={{
                                              cursor: isFirstCreated ? 'not-allowed' : 'pointer',
                                              opacity: isFirstCreated ? 0.3 : 1,
                                            }}>
                                            <DeleteSvg />
                                          </Box>
                                        </Tooltip>
                                      </Box>
                                    </Box>
                                  ) : (
                                    <Box
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Tooltip
                                        title="Access Denied"
                                        open={editTeamMemberTooltip === row}
                                        placement="top"
                                        onClose={() =>
                                          setEditTeamMemberTooltip(null)
                                        }
                                        sx={{ cursor: "pointer" }}
                                      >
                                        <Box
                                          mr={1}
                                          onClick={() => handleOpenEditDialog(row)}
                                        >
                                          <EditIconSvg />
                                        </Box>
                                      </Tooltip>
                                      <Tooltip
                                        title="Access Denied"
                                        open={deleteTeamMemberTooltip === row}
                                        placement="top"
                                        onClose={() =>
                                          setDeleteTeamMemberTooltip(null)
                                        }
                                        sx={{ cursor: "pointer" }}
                                        onClick={() => handleOpenDeleteDialog(row)}
                                      >
                                        <Box>
                                          <DeleteIconSvg />
                                        </Box>
                                      </Tooltip>
                                    </Box>
                                  )}
                                </TableCell> */}
                                    <TableCell>
                                      <Box
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          alignItems: "center",
                                        }}
                                      >
                                        {/* <Tooltip title={isFirstCreated ? "Default User can't be edited" : "Edit"}> */}
                                        <Tooltip
                                          title="Access Denied"
                                          open={editTeamMemberTooltip === row}
                                          placement="top"
                                          onClose={() =>
                                            setEditTeamMemberTooltip(null)
                                          }
                                        >
                                          <Box
                                            mr={1}
                                            onClick={() =>
                                              !isFirstCreated &&
                                              handleOpenEditDialog(row)
                                            }
                                            sx={{
                                              cursor: isFirstCreated
                                                ? "not-allowed"
                                                : "pointer",
                                              opacity: isFirstCreated ? 0.3 : 1,
                                            }}
                                          >
                                            <EditIconSvg />
                                          </Box>
                                        </Tooltip>
                                        {/* <Tooltip
                                      title={
                                        isFirstCreated
                                          ? "Default User can't be deleted"
                                          : "Delete"
                                      }
                                    > */}
                                        <Tooltip
                                          title="Access Denied"
                                          open={deleteTeamMemberTooltip === row}
                                          placement="top"
                                          onClose={() =>
                                            setDeleteTeamMemberTooltip(null)
                                          }
                                        >
                                          <Box
                                            onClick={() =>
                                              !isFirstCreated &&
                                              handleOpenDeleteDialog(row)
                                            }
                                            sx={{
                                              cursor: isFirstCreated
                                                ? "not-allowed"
                                                : "pointer",
                                              opacity: isFirstCreated ? 0.3 : 1,
                                            }}
                                          >
                                            <DeleteSvg />
                                          </Box>
                                        </Tooltip>
                                      </Box>
                                    </TableCell>
                                  </TableRow>
                                );
                              })
                          ) : (
                            <TableRow>
                              <TableCell colSpan={7} align="center">
                                No team member is added
                              </TableCell>
                            </TableRow>
                          )}
                          <DeletePopUp
                            title="Team Member"
                            open={openDeleteDialog}
                            handleDelete={handleDeleteTeamMember}
                            handleClose={handleCloseDelete}
                            handleLoad={isDeleteLoading}
                          />
                          {editTeamMember && (
                            <EditTeamMember
                              usedFor="company"
                              data={editTeamMember}
                              open={openEditDialog}
                              handleClose={handleCloseEditDialog}
                            />
                          )}
                        </TableBody>
                      ) : (
                        <TableBody
                          sx={{
                            position: "relative",
                            margin: "20px",
                            height: "500px",
                          }}
                        >
                          <TableRow
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              width: "100%",
                              position: "absolute",
                              left: "50%",
                              transform: "translateX(-50%)",
                              paddingLeft: "15px",
                              top: "20px",
                            }}
                          >
                            <LoadingComponent
                              height="500px"
                              color={bgColors.blue}
                            />
                          </TableRow>
                        </TableBody>
                      )}
                    </Table>
                  </TableContainer>
                </ThemeProvider>
                <br />
              </Box>
              {/* )} */}
            </Grid>
          )}
          <TablePagination
            rowsPerPageOptions={[10, 25]}
            component="div"
            count={getAllTeamMembersData ? getAllTeamMembersData.length : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Grid>
      ) : (
        <NoAccessPage />
      )}
    </>
  );
};

export default ManageCompany;
