import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  Typography,
  IconButton,
  Box,
  InputAdornment,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { bgColors } from "../../utils/bgColors";
import { DraftEditorComponent } from "../../components/common/DraftEditorComponent";
import { EditorState, Modifier } from "draft-js";
import { useDispatch, useSelector } from "react-redux";
import {
  AppDispatch,
  RootState,
  useAppSelector,
} from "../../utils/redux-hooks";
import { postAutoReply } from "../../redux/slices/Automation/autoReplySlice";
import { toastActions } from "../../utils/toastSlice";
import { reactDraftWysiwygToolbarOptionsarticle } from "../../utils/react-draft-wysiwyg-options";
import LoadingComponent from "../../components/common/LoadingComponent";
import { updateAutoReply } from "../../redux/slices/Automation/updateAutoReplySlice";
import { getAutoReply } from "../../redux/slices/Automation/getAutoReplySlice";
import { formatContent, parseTextToDraft } from "../../utils/functions";
import WorkflowLibraryPopup from "./workflowLibraryPopup";
import { getNextVariableCount } from "../../components/AutomationComponents/WorkflowComponents/functions";

interface AddAutoReplyPopupProps {
  open: boolean;
  onClose: () => void;
  onSave: (payload: any) => void;
  companyId: string;
  userId: string;
  editData?: any;
}

interface Variable {
  variable: string;
  value: string;
  fallbackValue: string;
}

const AddAutoReplyPopup: React.FC<AddAutoReplyPopupProps> = ({
  open,
  onClose,
  companyId,
  userId,
  editData,
  onSave,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  // const { loading, error, success } = useSelector((state: RootState) => state.autoReply);
  const { status } = useSelector((state: any) => state.autoReply);
  const { updateStatus } = useSelector((state: any) => state.updateAutoReply);
  const [workflowLibraryOpen, setWorkflowLibraryOpen] = useState(false);
  const [selectedWorkflow, setSelectedWorkflow] = useState<string | null>(null);
  const [input, setInput] = useState("");
  const [variations, setVariations] = useState<string[]>([]);
  const [responseType, setResponseType] = useState("Custom Message");
  const radioValues = ["Custom Message", "Workflow"];
  const [variables, setVariables] = useState<any[]>([]);
  const [buttons, setButtons] = useState<string[]>([]);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  // const staticVariables = [
  //   "",
  //   "Name",
  //   "CountryName",
  //   "CountryCode",
  //   "Contact1",
  //   "Email",
  // ];

  const dictionary = {
    Name: "Name",
    CountryCode: "CountryCode",
    CountryName: "CountryName",
    Contact: "Contact",
    Email: "Email",
  };
  const dictionaryOptions = Object.entries(dictionary).map(([key, value]) => ({
    key,
    value,
  }));
  const [inputError, setInputError] = useState(false);
  const userProfileSlice = useAppSelector((state: any) => state?.adminLogin);
  const userData = userProfileSlice?.data;
  const [errors, setErrors] = useState<string[]>([]);

  const handleClose = () => {
    setInput("");
    setVariations([]);
    setResponseType("Custom Message");
    setEditorState(EditorState.createEmpty());
    setVariables([]);
    setButtons([]);
    setErrors([]);
    setInputError(false);
    onClose();
  };

  useEffect(() => {
    if (editData) {
      setInput(editData?.autoReplyAutomation?.input);
      setVariations(editData?.autoReplyAutomation?.inputVariations || []);
      setResponseType(
        editData?.autoReplyAutomation?.automationResponseType === 1
          ? "Custom Message"
          : "Workflow"
      );
      setEditorState(
        EditorState.createWithContent(parseTextToDraft(editData?.bodyMessage))
      );
      setVariables(
        editData?.veriables?.map((v: any) => ({
          variable: v?.veriable,

          value: v?.value,
          fallbackValue: v?.fallbackValue,
        }))
      );
      setButtons(editData?.buttonValue?.map((b: any) => b?.value));
      setModalSelectedWorkflow(editData?.autoReplyAutomation?.workflowName);
    }
  }, [editData, onClose]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInput(e.target.value);
    setInputError(false);
  };

  const validateInput = () => {
    if (input.trim() === "") {
      setInputError(true);
      return false;
    }
    return true;
  };

  const insertTextAtCursor = (
    editorState: EditorState,
    text: string
  ): EditorState => {
    const contentState = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();
    const newContentState = Modifier.insertText(
      contentState,
      selectionState,
      text
    );
    return EditorState.push(editorState, newContentState, "insert-characters");
  };
  const [modalSelectedWorkflow, setModalSelectedWorkflow] = useState<
    string | null
  >(null);
  // console.log(modalSelectedWorkflow);
  const extractVariables = (
    editorState: EditorState,
    existingVariables: Variable[] = []
  ): Variable[] => {
    const content = editorState?.getCurrentContent().getPlainText();
    const variableRegex = /\{\{(\d+)\}\}/g;
    const matches = content.match(variableRegex);

    if (!matches)
      return existingVariables?.filter((v) => content.includes(v?.variable));

    return matches?.map((match) => {
      const variable = match.replace(/\{\{|\}\}/g, "");
      const existingVar = existingVariables.find(
        (v) => v?.variable === `{{${variable}}}`
      );
      return (
        existingVar || {
          variable: `{{${variable}}}`,
          value: "",
          fallbackValue: "",
        }
      );
    });
  };

  const handleAddVariation = () => {
    setVariations([...variations, ""]);
  };

  const handleAddButton = () => {
    setButtons([...buttons, ""]);
    setErrors([...errors, ""]);
  };

  const handleButtonChange = (index: number, value: string) => {
    const newButtons = [...buttons];
    newButtons[index] = value;
    setButtons(newButtons);

    // Validate uniqueness
    const newErrors = [...errors];
    newErrors[index] = newButtons.some(
      (button, idx) => idx !== index && button === value
    )
      ? "Please try a different value."
      : "";
    setErrors(newErrors);
  };

  const handleRemoveButton = (index: number) => {
    const newButtons = buttons?.filter((_, idx) => idx !== index);
    const newErrors = errors?.filter((_, idx) => idx !== index);
    setButtons(newButtons);
    setErrors(newErrors);
  };

  const handleVariationChange = (index: number, value: string) => {
    const newVariations = [...variations];
    newVariations[index] = value;
    setVariations(newVariations);
  };

  const handleRemoveVariation = (index: number) => {
    const newVariations = variations?.filter((_, i) => i !== index);
    setVariations(newVariations);
  };

  const handleResponseTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setResponseType(event.target.value);
  };

  const handleAddVariable = () => {
    const nextCount = getNextVariableCount(editorState, "editor");
    const variableText = `{{${nextCount}}}`;
    const newEditorState = insertTextAtCursor(editorState, variableText);
    setEditorState(newEditorState);
    setVariables(extractVariables(newEditorState, variables));
  };

  const handleVariableChange = (
    index: number,
    field: keyof Variable,
    value: string
  ) => {
    const newVariables = [...variables];
    newVariables[index] = { ...newVariables[index], [field]: value };
    setVariables(newVariables);
  };

  const handleRemoveVariable = (index: number) => {
    const newVariables = variables.filter((_, i) => i !== index);
    setVariables(newVariables);
  };

  const handleEditorStateChange = (newEditorState: EditorState) => {
    setEditorState(newEditorState);
    setVariables(extractVariables(newEditorState, variables));
  };

  const handleSaveWithWorkflow = (selectedWorkflow: string) => {
    setSelectedWorkflow(selectedWorkflow);
    // handleSave(selectedWorkflow);
  };

  const handleSave = async (Workflow: any) => {
    // const contentState = editorState.getCurrentContent();
    // const rawContent = convertToRaw(contentState);
    if (!input.trim()) {
      setInputError(true);
      dispatch(
        toastActions.setToaster({
          type: "error",
          message: "Input field is required",
        })
      );
      return;
    }
    // Validate body message
    const bodyMessage = formatContent(editorState.getCurrentContent());
    if (!bodyMessage.trim() && responseType == "Custom Message") {
      dispatch(
        toastActions.setToaster({
          type: "error",
          message: "Body message is required",
        })
      );
      return;
    }
    // Check if all variable fields are filled
    console.log(variables);
    const allFieldsFilled = variables?.every(
      (variable) =>
        variable?.variable && variable?.value && variable?.fallbackValue
    );
    console.log(allFieldsFilled);
    if (variables && !allFieldsFilled) {
      dispatch(
        toastActions.setToaster({
          type: "error",
          message: "Please fill all the variable fields",
        })
      );
      return;
    }
    const hasDuplicateButtons = buttons?.some(
      (button, index) => buttons.indexOf(button) !== index
    );

    if (hasDuplicateButtons) {
      dispatch(
        toastActions.setToaster({
          type: "error",
          message:
            "Duplicate button values are not allowed. Please use unique values for each button.",
        })
      );
      return;
    }
    // const bodyMessage = formatContent(contentState);
    // const allFieldsFilled = variables?.every(
    //   (variable) =>
    //     variable?.variable && variable?.value && variable?.fallbackValue
    // );

    // const hasDuplicateButtons = buttons?.some(
    //   (button, index) => buttons.indexOf(button) !== index
    // );

    if (hasDuplicateButtons) {
      dispatch(
        toastActions.setToaster({
          type: "error",
          message:
            "Duplicate button values are not allowed. Please use unique values for each button.",
        })
      );
      return;
    }

    if (variables && !allFieldsFilled) {
      dispatch(
        toastActions.setToaster({
          type: "error",
          message: "Please fill all the fields",
        })
      );
      return;
    }

    const payload = {
      autoReplyAutomation: {
        id: editData ? editData?.autoReplyAutomation?.id : undefined,
        companyId: companyId,
        userId: userId,
        input: input,
        automationResponseType: responseType === "Custom Message" ? 1 : 3,
        inputVariations: variations,
        workflowName: modalSelectedWorkflow,
      },
      bodyMessage: bodyMessage,
      veriables: variables?.map((v, index) => ({
        id: editData ? editData?.veriables[index]?.id : undefined,
        referenceId: editData
          ? editData?.veriables[index]?.referenceId
          : undefined,
        index: index + 1,
        veriable: v?.variable,
        value: v?.value,
        fallbackValue: v?.fallbackValue,
        referenceTableType: 1,
      })),
      buttonValue: buttons?.map((button) => ({
        key: "OK",
        value: button,
      })),
    };
    try {
      // const response: any = await dispatch(postAutoReply(payload));
      const response: any = await dispatch(
        editData ? updateAutoReply(payload) : postAutoReply(payload)
      );
      console.log(response);
      setEditorState(() => EditorState.createEmpty());
      setButtons([]);
      setInput("");
      setVariations([]);
      setVariables([]);
      handleClose();
      if (response?.payload?.success) {
        const payload = {
          companyId: userData?.companyId,
        };
        dispatch(getAutoReply(payload));
        dispatch(
          toastActions.setToaster({
            type: "success",
            message:
              response?.payload?.data?.message ||
              "Auto reply custom message created successfully",
          })
        );
      } else {
        dispatch(
          toastActions.setToaster({
            type: "error",
            message:
              response?.payload?.data?.message ||
              "Failed to create auto reply custom message",
          })
        );
      }
    } catch (error: any) {
      console.log(error);
      dispatch(
        toastActions.setToaster({
          type: "error",
          message:
            error?.payload?.message ||
            "An error occurred while saving the auto reply custom message",
        })
      );
    }
    handleClose();
  };

  const handleViewWorkflowLibrary = () => {
    setWorkflowLibraryOpen(true);
  };

  return (
    <Box>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="md"
        fullWidth
        sx={{
          borderRadius: "16px",
          "& .MuiPaper-root": {
            borderRadius: "16px",
          },
        }}
      >
        <DialogTitle
          sx={{
            bgcolor: "white",
            color: "black",
            display: "flex",
            alignItems: "center",
            borderTopLeftRadius: "16px",
            borderTopRightRadius: "16px",
            borderBottomLeftRadius: "0",
            borderBottomRightRadius: "0",
            // boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
          }}
        >
          <Box
            component="span"
            sx={{ display: "flex", alignItems: "center", fontWeight: "bold" }}
          >
            <span role="img" aria-label="robot" style={{ marginRight: "8px" }}>
              🤖
            </span>
            {editData ? "Update Custom Auto Reply" : "Setup Custom Auto Reply"}
          </Box>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{ marginLeft: "auto", color: "black" }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography
            variant="h6"
            sx={{ mt: 2, mb: 1, fontWeight: "bold", fontSize: "20px" }}
          >
            Customer Input
          </Typography>
          <Typography variant="body2" sx={{ mb: 2, fontSize: "15px" }}>
            Add the customer input for which you want the Custom Auto Reply to
            be triggered. As a user, you can enter the Input and up to 5
            variations of the same. In case the customer types in either of
            them, the Auto response gets triggered. Click <a href="#">here</a>{" "}
            to see an example of how this works
          </Typography>
          <Box sx={{ width: "70%" }}>
            <Typography
              variant="subtitle1"
              sx={{ mb: 1, fontWeight: "bold", fontSize: "16px" }}
            >
              Input
            </Typography>
            <Typography variant="body2" sx={{ mb: 1, fontSize: "14px" }}>
              While the Auto replies are triggered both for Customer input and
              its variations. It is the input here that gets shown when the
              Engagetocrm List messages are set up
            </Typography>
            <Box sx={{ mb: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  borderRadius: "20px",
                  transition: "all 0.3s ease-in-out",
                }}
              >
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Input"
                  value={input}
                  onChange={handleInputChange}
                  onBlur={validateInput}
                  inputProps={{ maxLength: 70 }}
                  size="small"
                  required
                  error={inputError}
                  helperText={inputError ? "This field is required" : ""}
                  sx={{
                    width: "60%",
                    height: "40px",
                    borderRadius: "10px",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "10px",
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Box
                          sx={{
                            color: inputError ? "error.main" : "text.secondary",
                            fontSize: "0.75rem",
                            mr: 1,
                          }}
                        >
                          {`${input.length}/70`}
                        </Box>
                      </InputAdornment>
                    ),
                    sx: {
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: inputError ? "error.main" : "inherit",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: inputError ? "error.main" : "inherit",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: inputError ? "error.main" : "primary.main",
                      },
                    },
                  }}
                />
              </Box>
            </Box>
            <Typography
              variant="subtitle1"
              sx={{ mb: 1, fontWeight: "bold", fontSize: "16px" }}
            >
              Input Variations
            </Typography>
            <Typography variant="body2" sx={{ mb: 1, fontSize: "14px" }}>
              Users can set up to 5 variations of the customer input. The Auto
              replies get triggered in case of an exact match with the input or
              any of the variation here
            </Typography>
            {variations?.map((variation: any, index: number) => (
              <Box
                key={index}
                sx={{ display: "flex", alignItems: "center", mb: 1 }}
              >
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder={`Variation ${index + 1}`}
                  value={variation}
                  onChange={(e) => handleVariationChange(index, e.target.value)}
                  size="small"
                  sx={{
                    width: "60%",
                    height: "40px",
                    borderRadius: "10px",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "10px",
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Box
                          sx={{
                            color: "text.secondary",
                            fontSize: "0.75rem",
                            mr: 1,
                          }}
                        >
                          {`${variation.length}/70`}
                        </Box>
                      </InputAdornment>
                    ),
                    sx: {
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "12px",
                      },
                      "& .MuiInputBase-input": {
                        borderRadius: "12px",
                        paddingRight: "40px",
                      },
                    },
                  }}
                  inputProps={{ maxLength: 70 }}
                />
                <IconButton
                  onClick={() => handleRemoveVariation(index)}
                  sx={{ ml: 1 }}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            ))}
            {variations.length < 5 && (
              <Button
                variant="outlined"
                onClick={handleAddVariation}
                style={{
                  color: "green",
                  fontWeight: "600",
                  border: "1px solid green",
                  borderRadius: "8px",
                  textTransform: "capitalize",
                  fontSize: "14px",
                  cursor: "pointer",
                  marginBottom: 2,
                }}
              >
                + Add another variation
              </Button>
            )}
          </Box>

          <Typography
            variant="h6"
            sx={{ mt: 2, mb: 1, fontWeight: "bold", fontSize: "20px" }}
          >
            Custom Auto Reply
          </Typography>
          <Typography variant="body2" sx={{ mb: 2, fontSize: "14px" }}>
            Configure the type of Auto reply you want to be triggered when a
            customer sends the user input or one of its variations in the chat
          </Typography>
          <Box sx={{ width: "70%" }}>
            <Typography
              variant="subtitle1"
              sx={{ mb: 1, fontWeight: "bold", fontSize: "16px" }}
            >
              Select response type
            </Typography>
            <RadioGroup
              row
              value={responseType}
              onChange={handleResponseTypeChange}
            >
              {radioValues?.map((label: string) => (
                <FormControlLabel
                  key={label}
                  value={label}
                  control={
                    <Radio
                      sx={{
                        "&.Mui-checked": {
                          color: "green",
                        },
                      }}
                    />
                  }
                  label={label}
                />
              ))}
            </RadioGroup>
            <Box>
              {responseType === "Custom Message" ? (
                <>
                  <DraftEditorComponent
                    editorState={editorState}
                    handleEditorStateChange={handleEditorStateChange}
                    reactDraftWysiwygToolbarOptionsarticle={
                      reactDraftWysiwygToolbarOptionsarticle
                    }
                  />
                  <Typography
                    variant="body2"
                    onClick={handleAddVariable}
                    style={{
                      color: "green",
                      fontWeight: "600",
                      fontSize: "14px",
                      cursor: "pointer",
                      marginTop: "10px",
                    }}
                  >
                    + Add Variables
                  </Typography>
                  <Box sx={{ marginTop: "15px" }}>
                    {variables?.map((variable: any, index: number) => (
                      <Box
                        key={index}
                        sx={{ display: "flex", alignItems: "center", mb: 1 }}
                      >
                        <TextField
                          variant="outlined"
                          placeholder="Variable"
                          value={variable.variable}
                          onChange={(e) =>
                            handleVariableChange(
                              index,
                              "variable",
                              e.target.value
                            )
                          }
                          size="small"
                          sx={{
                            mr: 1,
                            width: "40%",
                            height: "40px",
                            borderRadius: "10px",
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "10px",
                            },
                          }}
                          required
                        />
                        <Select
                          value={variable.value}
                          onChange={(e: any) =>
                            handleVariableChange(index, "value", e.target.value)
                          }
                          displayEmpty
                          variant="outlined"
                          size="small"
                          sx={{
                            mr: 1,
                            width: "40%",
                            height: "40px",
                            borderRadius: "10px",
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "10px",
                            },
                          }}
                          required
                        >
                          {/* <MenuItem value="" disabled>
                Select Value
              </MenuItem> */}
                          {dictionaryOptions?.map(({ key, value }: any) => (
                            <MenuItem key={key} value={value}>
                              {key}
                            </MenuItem>
                          ))}
                        </Select>
                        <TextField
                          variant="outlined"
                          placeholder="Fallback Value"
                          value={variable.fallbackValue}
                          onChange={(e) =>
                            handleVariableChange(
                              index,
                              "fallbackValue",
                              e.target.value
                            )
                          }
                          size="small"
                          sx={{
                            mr: 1,
                            width: "40%",
                            height: "40px",
                            borderRadius: "10px",
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "10px",
                            },
                          }}
                          required
                        />
                        <IconButton onClick={() => handleRemoveVariable(index)}>
                          <CloseIcon />
                        </IconButton>
                      </Box>
                    ))}
                  </Box>
                  <Typography
                    variant="subtitle1"
                    sx={{ mb: 1, fontWeight: "bold", fontSize: "16px" }}
                  >
                    Buttons (Optional)
                  </Typography>
                  <Box sx={{ marginTop: "10px" }}>
                    {buttons.length < 3 && (
                      <Button
                        variant="outlined"
                        onClick={handleAddButton}
                        style={{
                          color: "green",
                          fontWeight: "600",
                          border: "1px solid green",
                          borderRadius: "8px",
                          textTransform: "capitalize",
                          fontSize: "14px",
                          cursor: "pointer",
                          marginBottom: 2,
                        }}
                      >
                        + Add Button
                      </Button>
                    )}
                    {buttons?.map((button, index) => (
                      <Box
                        key={index}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          marginTop: "10px",
                          width: "250px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <TextField
                            variant="outlined"
                            value={button}
                            onChange={(e) =>
                              handleButtonChange(index, e.target.value)
                            }
                            inputProps={{ maxLength: 20 }}
                            placeholder={`Button text`}
                            sx={{
                              height: "40px",
                              borderRadius: "10px",
                              "& .MuiOutlinedInput-root": {
                                borderRadius: "10px",
                              },
                            }}
                            size="small"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Box
                                    sx={{
                                      color: "text.secondary",
                                      fontSize: "0.75rem",
                                      mr: 1,
                                    }}
                                  >
                                    {`${button.length}/20`}
                                  </Box>
                                </InputAdornment>
                              ),
                              sx: {
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: "12px",
                                },
                                "& .MuiInputBase-input": {
                                  borderRadius: "12px",
                                  // paddingRight: "40px",
                                },
                              },
                            }}
                          />
                          <IconButton onClick={() => handleRemoveButton(index)}>
                            <CloseIcon />
                          </IconButton>
                        </Box>
                        {errors[index] && (
                          <Box
                            sx={{
                              color: "error.main",
                              fontSize: "0.75rem",
                              mt: 1,
                            }}
                          >
                            {errors[index]}
                          </Box>
                        )}
                      </Box>
                    ))}
                  </Box>
                </>
              ) : (
                <>
                  <Button
                    variant="outlined"
                    onClick={handleViewWorkflowLibrary}
                    style={{
                      color: "green",
                      fontWeight: "600",
                      border: "1px solid green",
                      borderRadius: "8px",
                      textTransform: "capitalize",
                      fontSize: "14px",
                      cursor: "pointer",
                      marginTop: "10px",
                    }}
                  >
                    View Workflow Library
                  </Button>
                  <WorkflowLibraryPopup
                    open={workflowLibraryOpen}
                    onClose={() => setWorkflowLibraryOpen(false)}
                    onSave={handleSaveWithWorkflow}
                    setSelectedWorkflow={setModalSelectedWorkflow}
                    selectedWorkflow={modalSelectedWorkflow}
                  />
                </>
              )}
            </Box>
            <Box>
              {modalSelectedWorkflow ? (
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: "16px",
                    color: "black",
                    margin: "8px 0",
                  }}
                >
                  Selected workflow:{" "}
                  <Box
                    component="span"
                    sx={{
                      fontWeight: 600,
                      fontSize: "16px",
                      color: "gray",
                    }}
                  >
                    {modalSelectedWorkflow}
                  </Box>
                </Typography>
              ) : (
                <></>
              )}
            </Box>
          </Box>
        </DialogContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "160px",
            // py: 3,
            // pt: 0,
          }}
        >
          {status === "loading" || updateStatus === "loading" ? (
            <LoadingComponent height="50px" color={bgColors.blue} />
          ) : (
            <Button
              onClick={() => handleSave(null)}
              sx={{
                backgroundColor: "#3C3C3C",
                color: "#ffffff",
                cursor: "pointer",
                height: "36px",
                borderRadius: "8px",
                width: "30%",
                fontSize: "14px",
                fontWeight: "600",
                "&:hover": {
                  backgroundColor: "#3C3C3C",
                  opacity: 1,
                },
              }}
            >
              {editData ? "Update custom auto reply" : "Save custom auto reply"}
            </Button>
          )}
        </Box>
      </Dialog>
    </Box>
  );
};

export default AddAutoReplyPopup;
