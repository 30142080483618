import { FolderSpecialOutlined, Send } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import { bgColors } from "../../utils/bgColors";
import "../../";
const useStyles: any = makeStyles(() => ({
  // chatArea: {},
  // chatTextBox: {
  //   position: "relative",
  //   border: "1px solid #262738",
  //   // border: `1px solid ${props.borderColor || "#262738"}`,
  //   borderRadius: 8,
  // },
  chatTextBox: (props: any) => ({
    position: "relative",
    border: `1px solid ${props.borderColor || "#262738"}`,
    borderRadius: 8,
  }),
  toolBarStyle: {
    border: "none",
    backgroundColor: "rgb(247, 244, 244)",
    padding: "10px 0",
    marginBottom: 0,
    order: 2,
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "10px",
  },
  variable: {
    color: `${bgColors.green}`,
    fontWeight: "600 !important",
    fontSize: "14px !important",
    cursor: "pointer",
  },
  wrapperClassName: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    marginTop: "12px",
  },
  editorClassName: {
    padding: "10px",
    minHeight: "60px",
    maxHeight: "200px",
    fontSize: "14px",
    "& .DraftEditor-root": {
      "& .public-DraftStyleDefault-block": {
        margin: 0,
      },
      "& .public-DraftEditorPlaceholder-root": {
        margin: 0,
      },
    },
  },
  fileUploadInput: {
    display: "none",
  },
  placeholder: {
    paddingTop: "22px",
    paddingLeft: "10px",
    // paddingTop: "22px",
    fontSize: "14px",
    color: "#aaa",
    position: "absolute",
    pointerEvents: "none",
  },
}));
export const DraftEditorComponent = ({
  editorState,
  handleEditorStateChange,
  handleSaveInboxSettings,
  reactDraftWysiwygToolbarOptionsarticle,
  blockRendererFn,
  file,
  handleFileUpload,
  chatAreaRef,
  borderColor,
  isSendButton,
  handleAddVariable,
  mediaUrl,
  placeholder,
}: any) => {
  const classes = useStyles({ borderColor });
  const [, setSendIconPosition] = useState({
    top: "440px",
    right: "117px",
  });
  const [, setFileIconPosition] = useState({
    top: file ? "500px" : "400px",
    right: "815px",
  });

  const isEditorEmpty = (editorState: EditorState) => {
    const contentState = editorState?.getCurrentContent();
    return (
      !contentState?.hasText() &&
      contentState?.getBlockMap().first().getType() === "unstyled"
    );
  };

  useEffect(() => {
    if (mediaUrl && editorState) {
      // const contentState = editorState.getCurrentContent();
      // const contentStateWithEntity = contentState.createEntity(
      //   "IMAGE",
      //   "IMMUTABLE",
      //   { src: mediaUrl }
      // );
      // const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
      // const newEditorState = AtomicBlockUtils.insertAtomicBlock(
      //   EditorState.set(editorState, { currentContent: contentStateWithEntity }),
      //   entityKey,
      //   ''
      // );
      // handleEditorStateChange(newEditorState);
    }
  }, [mediaUrl]);

  useEffect(() => {
    const handleScroll = () => {
      if (chatAreaRef?.current) {
        const scrollHeight = chatAreaRef?.current.scrollHeight;
        const scrollTop = chatAreaRef?.current.scrollTop;
        const clientHeight = chatAreaRef?.current.clientHeight;

        const scrolledToBottom = scrollHeight - scrollTop === clientHeight;

        if (scrolledToBottom) {
          setSendIconPosition({ top: "10px", right: "117px" });
          setFileIconPosition({
            top: file ? "530px" : "400px",
            right: "815px",
          });
        } else {
          setSendIconPosition({
            top: `${clientHeight - 10}px`,
            right: "117px",
          });
          setFileIconPosition({
            top: `${clientHeight - (file ? 130 : 0)}px`,
            right: "815px",
          });
        }
      }
    };

    const chatArea = chatAreaRef?.current;
    if (chatArea) {
      chatArea.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (chatArea) {
        chatArea.removeEventListener("scroll", handleScroll);
      }
    };
  }, [file]);

  return (
    <>
      <Box className={classes.chatTextBox}>
        {/* {isEditorEmpty(editorState) && <Placeholder text="Enter body message" />} */}
        {/* {isEditorEmpty(editorState) && <Placeholder text={placeholder ? placeholder : `Enter body message`} />} */}
        {isEditorEmpty(editorState) && (
          <Typography className={classes.placeholder} sx={{ fontSize: 14 }}>
            {placeholder ? placeholder : "Enter body message"}{" "}
          </Typography>
        )}
        <Editor
          editorState={editorState}
          customBlockRenderFunc={blockRendererFn}
          onEditorStateChange={handleEditorStateChange}
          toolbarClassName={classes.toolBarStyle}
          wrapperClassName={classes.wrapperClassName}
          editorClassName={classes.editorClassName}
          toolbar={reactDraftWysiwygToolbarOptionsarticle}
          toolbarCustomButtons={[
            <>
              {isSendButton ? (
                <>
                  <FileUpload handleFileUpload={handleFileUpload} />
                  <SendMessageIcon
                    handleSaveInboxSettings={handleSaveInboxSettings}
                  />
                </>
              ) : (
                <></>
              )}
            </>,
          ]}
        />
      </Box>
      {/* <Box
        position="absolute"
        top={sendIconPosition.top}
        right={sendIconPosition.right}
        sx={{ cursor: "pointer" }}
      >
        <IconButton
          sx={{
            background: "#0082B6 !important",
            border: "1px solid #0071A9",
            boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.08)",
            borderRadius: "8px",
            width: "32px",
            height: "32px",
          }}
          color="primary"
          // disabled={disableSendButton || contentText.trim().length <= 0}

          onClick={handleSaveInboxSettings}
        >
          <Send
            fontSize="small"
            sx={{
              color: "#D9D9D9",
              width: "16.65px",
              height: "16.06px",
            }}
          />
        </IconButton>
      </Box>
      <Box
        position="absolute"
        top={fileIconPosition.top}
        right={fileIconPosition.right}
        sx={{ backgroundColor: "#fff", borderBottomLeftRadius: "8px" }}
      >
        <input
          type="file"
          accept=".mp4, .avi, .mov .mp3, .jpg, .jpeg, .png, .gif"
          className={classes.fileUploadInput}
          onChange={handleFileUpload}
          id="file-upload-input"
          // key={file ? 'file-upload-input' : 'file-upload-input-reset'}
          // disabled={selectedChatUser.communicationId ? true : false}
        />
        <label htmlFor="file-upload-input">
          <IconButton component="span">
            <FolderSpecialOutlined />
          </IconButton>
        </label>
      </Box> */}

      {handleAddVariable && (
        <Typography
          variant="h5"
          sx={{
            width: 115,
            marginTop: "10px",
          }}
          className={classes.variable}
          onClick={() => handleAddVariable("body")}
        >
          + Add Variables
        </Typography>
      )}
    </>
  );

  //   return (
  //     <Box className={classes.chatArea}>
  //       <Box className={classes.chatTextBox}>
  //         <Editor
  //           editorState={editorState}
  //           customBlockRenderFunc={blockRendererFn}
  //           onEditorStateChange={handleEditorStateChange}
  //           toolbarClassName={classes.toolBarStyle}
  //           wrapperClassName={classes.wrapperClassName}
  //           editorClassName={classes.editorClassName}
  //           toolbar={reactDraftWysiwygToolbarOptionsarticle}
  //           toolbarCustomButtons={[
  //             <>
  //               <FileUpload handleFileUpload={handleFileUpload} />
  //               <SendMessageIcon
  //                 handleSaveInboxSettings={handleSaveInboxSettings}
  //               />
  //             </>,
  //           ]}
  //         />
  //       </Box>
  //     </Box>
  //   );
};

export const FileUpload = ({ handleFileUpload }: any) => {
  const classes = useStyles();

  return (
    <Box
      position="absolute"
      left="185px"
      sx={{ backgroundColor: "#fff", borderBottomLeftRadius: "8px" }}
    >
      <input
        type="file"
        accept=".mp4, .avi, .mov .mp3, .jpg, .jpeg, .png, .gif"
        className={classes.fileUploadInput}
        onChange={handleFileUpload}
        id="file-upload-input"
        // key={file ? 'file-upload-input' : 'file-upload-input-reset'}
        // disabled={selectedChatUser.communicationId ? true : false}
      />
      <label htmlFor="file-upload-input">
        <IconButton component="span">
          <FolderSpecialOutlined />
        </IconButton>
      </label>
    </Box>
  );
};

export const SendMessageIcon = ({ handleSaveInboxSettings }: any) => {
  return (
    <Box position="absolute" right="10px" sx={{ cursor: "pointer" }}>
      <IconButton
        sx={{
          background: "#0082B6 !important",
          border: "1px solid #0071A9",
          boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.08)",
          borderRadius: "8px",
          width: "32px",
          height: "32px",
        }}
        color="primary"
        // disabled={disableSendButton || contentText.trim().length <= 0}

        onClick={handleSaveInboxSettings}
      >
        <Send
          fontSize="small"
          sx={{
            color: "#D9D9D9",
            width: "16.65px",
            height: "16.06px",
          }}
        />
      </IconButton>
    </Box>
  );
};
