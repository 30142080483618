import {
  Box,
  FormControlLabel,
  Grid,
  Switch,
  Tab,
  Typography,
  IconButton,
  FormControl,
  // InputLabel,
  // Select,
  MenuItem,
  Tooltip,
  TextField,
  Autocomplete,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect } from "react";
import { bgColors } from "../../../utils/bgColors";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useAppSelector, useAppDispatch } from "../../../utils/redux-hooks";
import {
  PostManagePermissions,
  fetchManagePermissions,
} from "../../../redux/slices/ManagePermissions/ManagePermissionsSlice";
import NewRoleMember from "../../../components/ManagePermissionsComponents/NewRoleMember";
import AddIcon from "@mui/icons-material/Add";
import LoadingComponent from "../../../components/common/LoadingComponent";
import { fetchAllRoles } from "../../../redux/slices/ManageCompany/DisplayAllRoles";
import { toastActions } from "../../../utils/toastSlice";
// import { text } from "stream/consumers";
// import { fetchGetManageCompanies } from "../../../redux/slices/ManageCompany/GetAllMangeCompanySlice";
import { MANAGE_PERMISSIONS_APIS } from "../../../Apis/AdminLogin/ManagePermissions/ManagePermissions";
// import CloseIconSvg from "../../../assets/svgs/CloseIconSvg";
import { fetchGetAllClients } from "../../../redux/slices/ManageClients/getAllClientSlice";
// import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import { hasManagePermissionPermission } from "../../../utils/permissions";
import NoAccessPage from "../../../components/common/NoAccess";
// import { fetchCurrentPlanDetails } from "../../../redux/slices/Subscription/SubscriptionSlice";

// interface Permission {
//   permissionName: string;
//   status: number;
// }

// interface Props {
//   permissions?: Permission[];
//   dispatch: (action: any) => void; // Update this type according to your Redux action type
// }

// interface SwitchesState {
//   [key: string]: number;
// }

const useStyles = makeStyles({
  mainContainer: {
    // backgroundColor: bgColors.white1,
    padding: "14px",
    width: "100%",
    height: "100%",
  },
  bgContainer: {
    backgroundColor: bgColors.white,
    borderRadius: "25px",
    height: "100%",
    width: "100%",
    overflow: "auto",
  },
  manageContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  tabsContainer: {
    width: "100%",
  },
  account: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "8px 0 0 0",
  },
  switch: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: "14px !important",
    color: `${bgColors.black1} !important`,
    // opacity: "60% !important",
  },
  lineStyles: {
    border: "none",
    borderTop: `1px solid ${bgColors.gray2}`,
    width: "100%",
    margin: "0 auto",
  },
  blackColor: {
    color: `${bgColors.black1} !important`,
    fontWeight: "600 !important",
  },
  SaveChangesButton: {
    backgroundColor: bgColors.green,
    color: bgColors.white,
    borderRadius: "20px",
    // width: "150px",
    height: "38px",
    padding: "10px",
    cursor: "pointer",
  },
});

const ManagePermissions = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const loginData = useAppSelector((state:any) => state.adminLogin.data);
  const companyId = loginData?.companyId;
  const getAllRolesSlice = useAppSelector((state:any) => state.getAllRoles);
  const getAllRolesData = getAllRolesSlice?.data;
  const permissions = useAppSelector((state:any) => state.managePermissions.data);
  const getAllCompanies = useAppSelector((state:any) => state.getAllClients.data);
  const currentPlanDetails = useAppSelector(
    (state:any) => state?.wallet?.walletAndSubscription?.data
  );
  const accountData = useAppSelector((state:any) => state?.accountData?.data);
  // console.log(getAllCompanies)
  const permissionsSStatus = useAppSelector(
    (state:any) => state.managePermissions.status
  );
  const getuserPermissionData = useAppSelector(
    (state:any) => state.getUserPermissions?.data
  );
  const managePermissionStatus = getuserPermissionData?.profile?.find(
    (item: any) =>
      Object?.prototype?.hasOwnProperty?.call(item, "managePermissions")
  );

  const managePermissionActions = managePermissionStatus
    ? managePermissionStatus?.managePermissions
    : [];
  const hasPermissionsPermission = hasManagePermissionPermission(
    getuserPermissionData?.profile
  );
  // const currentplan = useAppSelector(
  //   (state) => state?.Subscription?.currentPlan
  // );
  // const planName = currentplan?.data?.planDetails?.planName;
  // console.log("currentplan", currentplan);

  const [selectedCompany, setSelectedCompany] = React.useState<any>("");
  const [selectedRoleId, setSelectedRoleId] = React.useState(loginData?.roleId);
  const [switches, setSwitches] = React.useState<any>({});
  const [openMainMenus, setOpenMainMenus] = React.useState<any>({});
  const [newRoleDialog, setNewRoleDialog] = React.useState(false);
  const [openSubMenus, setOpenSubMenus] = React.useState<any>({});
  const [checkRoleName, setCheckRoleName] = React.useState(false);
  // console.log("permissions", permissions);
  // console.log("getuserPermissionData", getuserPermissionData);
  // console.log("selectedRoleId", selectedRoleId);
  const hasAcess = (permission: any) => {
    if (managePermissionActions?.includes(permission)) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (selectedCompany) {
      setCheckRoleName(true);
    } else {
      if (loginData?.roleName === "Owner") {
        setCheckRoleName(true);
      } else {
        setCheckRoleName(false);
      }
    }
  }, [selectedCompany]);

  useEffect(() => {
    if (loginData?.isPlatform) {
      const data = {
        search: "",
        includeInactive: true,
        sortBy: "",
        isSortAscending: true,
      };
      dispatch(fetchGetAllClients(data));
    }
  }, [dispatch]);

  const openNewRolePopover = () => {
    setNewRoleDialog(true);
  };

  const handleCloseRolePopover = () => {
    setNewRoleDialog(false);
  };

  // const handleCompanyChange = (event: any) => {
  //   setSelectedCompany(event.target.value);
  // };

  React.useEffect(() => {
    if (permissions && typeof permissions === "object") {
      if (selectedCompany) {
        const switchStates = Object.values(permissions).reduce(
          (acc: any, permissionArray: any) => {
            permissionArray.forEach((permission: any) => {
              // console.log("permissionsvsaa", permission);
              const { menuName, subMenus, status } = permission;
              acc[menuName] = status === true;

              if (subMenus) {
                subMenus.forEach((subMenu: any) => {
                  const {
                    menuName: subMenuName,
                    actions,
                    status: subMenuAccess,
                  } = subMenu;
                  // console.log("subMenuAccess", subMenuAccess, access)
                  acc[`${menuName}.${subMenuName}`] = subMenuAccess === true;

                  if (actions) {
                    actions.forEach((action: any) => {
                      const { actionName, status: actionAccess } = action;
                      acc[`${menuName}.${subMenuName}.${actionName}`] =
                        actionAccess === true;
                    });
                  }
                });
              }
            });
            return acc;
          },
          {}
        );
        // console.log("switchStatesas", switchStates)
        setSwitches(switchStates);
      } else {
        const switchStates = Object.values(permissions).reduce(
          (acc: any, permissionArray: any) => {
            permissionArray.forEach((permission: any) => {
              // console.log("permissionsvsaa", permission);
              const { menuName, subMenus, status } = permission;
              acc[menuName] = status === true;

              if (subMenus) {
                subMenus.forEach((subMenu: any) => {
                  const {
                    menuName: subMenuName,
                    actions,
                    status: subMenuAccess,
                  } = subMenu;
                  // console.log("subMenuAccess", subMenuAccess, access)
                  acc[`${menuName}.${subMenuName}`] = subMenuAccess === true;

                  if (actions) {
                    actions.forEach((action: any) => {
                      const { actionName, status: actionAccess } = action;
                      acc[`${menuName}.${subMenuName}.${actionName}`] =
                        actionAccess === true;
                    });
                  }
                });
              }
            });
            return acc;
          },
          {}
        );
        // console.log("switchStatesas", switchStates)
        setSwitches(switchStates);
      }
    }
  }, [permissions, selectedCompany]);

  // console.log("switches", switches);

  React.useEffect(() => {
    if (selectedCompany) {
      dispatch(
        fetchManagePermissions({
          roleId: selectedRoleId,
          companyId: selectedCompany,
        })
      );
      // dispatch(fetchCurrentPlanDetails(selectedCompany));
      dispatch(fetchAllRoles(selectedCompany));
    } else {
      dispatch(fetchManagePermissions({ roleId: selectedRoleId, companyId }));
      // dispatch(fetchCurrentPlanDetails(companyId));
    }
  }, [dispatch, selectedRoleId, companyId, selectedCompany]);

  useEffect(() => {
    dispatch(fetchAllRoles(companyId));
  }, [loginData, dispatch]);

  const visibleRoles = React.useMemo(() => {
    if (selectedCompany) {
      return getAllRolesData;
    } else {
      if (loginData.roleName === "Owner") {
        // Owner can see all roles
        return getAllRolesData;
      } else if (loginData.roleName === "Admin") {
        // Admin can see only Admin and Teammate roles
        return getAllRolesData?.filter((role: any) => role.name !== "Owner");
      } else {
        // All other roles see only their specific role
        return getAllRolesData?.filter((role: any) => role.level >= 3);
      }
    }
  }, [loginData, getAllRolesData, selectedCompany]);

  const handleChange = (event: any, newValue: any) => {
    setSelectedRoleId(newValue);
  };

  const handleChangeSwitch = async (
    menuName: any,
    subMenuName: any,
    permissionName: any
  ) => {
    // const newStatus = !switches[`${menuName}.${subMenuName}.${permissionName}`];
    let key: any;
    if (menuName && subMenuName && permissionName) {
      key = `${menuName}.${subMenuName}.${permissionName}`;
    } else if (subMenuName && permissionName) {
      key = `${subMenuName}.${permissionName}`;
    } else if (permissionName) {
      key = permissionName;
    }

    const newStatus = !switches[key];
    // console.log("Status changed", key, newStatus);

    // let permissionSection;
    if (Array.isArray(permissions)) {
      // const foundPermission = permissions.find(
      //   (p: any) => p.menuName === permissionName
      // );
      // permissionSection = foundPermission?.section;
    }

    // // Update the switches state
    setSwitches((prev: any) => ({
      ...prev,
      [key]: {
        ...prev[key],
        status: newStatus,
      },
    }));

    // Dispatch the updated permission status along with its section
    try {
      if (selectedCompany) {
        const response = await MANAGE_PERMISSIONS_APIS.updateClientPermissions(
          selectedRoleId,
          selectedCompany,
          permissionName,
          newStatus
        );
        if (response?.status == 200) {
          dispatch(
            toastActions.setToaster({
              type: "success",
              message: response?.data?.message,
            })
          );
          dispatch(
            fetchManagePermissions({
              roleId: selectedRoleId,
              companyId: selectedCompany ? selectedCompany : companyId,
            })
          );
        } else {
          dispatch(
            toastActions.setToaster({
              type: "error",
              message: "Something Went Wrong",
            })
          );
          dispatch(
            fetchManagePermissions({ roleId: selectedRoleId, companyId })
          );
        }
      } else {
        const getresponse = await dispatch(
          PostManagePermissions({
            roleId: selectedRoleId,
            companyId: companyId,
            menuName: permissionName,
            status: newStatus,
            data: [
              {
                status: newStatus,
                // section: permissionSection  // Include section in the payload
              },
            ],
          })
        );
        if (getresponse?.meta?.requestStatus == "fulfilled") {
          dispatch(
            toastActions.setToaster({
              type: "success",
              message: getresponse?.payload?.message,
            })
          );
          dispatch(
            fetchManagePermissions({
              roleId: selectedRoleId,
              companyId: selectedCompany ? selectedCompany : companyId,
            })
          );
        } else {
          dispatch(
            toastActions.setToaster({
              type: "error",
              message: "Failed to update menu status.",
            })
          );
          dispatch(
            fetchManagePermissions({ roleId: selectedRoleId, companyId })
          );
        }
      }
    } catch (error) {
      console.log("permissionError:", error);
    }
  };

  React.useEffect(() => {
    if (selectedCompany) {
      const getId = visibleRoles?.find((each: any) => each?.name === "Owner");
      //  console.log("getId", getId);
      if (getId) {
        setSelectedRoleId(getId?.id);
      }
    } else {
      setSelectedRoleId(loginData?.roleId);
    }
  }, [selectedCompany]);

  const shouldDisableSwitch = (
    roleName: any
    //  menuName: any, access: any
  ) => {
    const userRole = loginData.roleName;
    // console.log({roleName, userRole}, "roleName 405");
    const hasPermission = hasAcess("statusChange");
    // const userRole:any = "Teammate";
    if (!hasPermission) {
      return true;
    } else {
      if (selectedCompany) {
        return false;
      } else {
        if (userRole !== roleName) {
          return false;
        } else {
          return true;
        }
      }
    }
  };

  // const camelCaseToWords = (str: string) => {
  //   return str.replace(/([a-z])([A-Z])/g, '$1 $2');
  // };
  const camelCaseToWords = (str: string) => {
    if (str === "addNewFaq") {
      return "Add FAQ";
    }
    return str.replace(/([a-z])([A-Z])/g, "$1 $2");
  };

  const toggleMainMenu = (mainMenuName: any) => {
    setOpenMainMenus((prevState: any) => ({
      ...prevState,
      [mainMenuName]: !prevState[mainMenuName],
    }));
  };
  const toggleSubMenu = (subMenuName: any) => {
    setOpenSubMenus((prevState: any) => ({
      ...prevState,
      [subMenuName]: !prevState[subMenuName],
    }));
  };

  const renderSection = (sectionName: any, section: any, role: any) => {
    // console.log("render section", planName);

    return (
      <Box
        key={sectionName}
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        sx={{ width: "100%" }}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          sx={{ width: "100%" }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 1,
              alignItems: "center",
              paddingTop: "10px",
              cursor: "pointer",
            }}
            onClick={() => toggleMainMenu(section?.menuName)}
          >
            <Typography sx={{ fontSize: { xs: 10, md: 16 } }}>
              {openMainMenus[section?.menuName] ? "-" : "+"}
            </Typography>
            <Typography
              sx={{ fontSize: { xs: 10, md: 16 }, textTransform: "capitalize" }}
              className={classes.blackColor}
            >
              {camelCaseToWords(section?.menuName)}
            </Typography>
          </Box>
          <hr
            className={classes.lineStyles}
            style={{ width: "100%", marginTop: "8px" }}
          />
          {openMainMenus[section?.menuName] &&
            section?.subMenus &&
            section?.subMenus
              ?.filter(
                (eachSubMenu: any) =>
                  !(
                    eachSubMenu?.menuName === "manageClients" &&
                    ((loginData?.isPlatform === true && selectedCompany) ||
                      loginData?.isPlatform === false)
                  )
              )
              ?.map((eachSubMenu: any) => {
                const hasActions =
                  eachSubMenu?.actions && eachSubMenu?.actions?.length > 0;
                // section?.subMenus?.map((eachSubMenu: any) => {
                //   const hasActions =
                //     eachSubMenu?.actions && eachSubMenu?.actions?.length > 0;
                // console.log(eachSubMenu?.menuName, "eachSubMenu");
                // console.log("chec", switches[`${section?.menuName}.${eachSubMenu?.menuName}`])
                return (
                  <Box
                    key={eachSubMenu?.menuName}
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    sx={{ width: "100%" }}
                  >
                    <Box sx={{ width: "100%", marginLeft: "20px" }}>
                      <Box
                        onClick={() => toggleSubMenu(eachSubMenu?.menuName)}
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: 1,
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        {section?.menuName !== "contacts" &&
                          section?.menuName !== "inbox" && (
                            <Typography sx={{ fontSize: { xs: 8, md: 14 } }}>
                              {openSubMenus[eachSubMenu?.menuName] ? "-" : "+"}
                            </Typography>
                          )}
                        <Typography
                          sx={{
                            fontSize: hasActions
                              ? { xs: 8, md: 14 }
                              : { xs: 6, md: 12 },
                            color: hasActions
                              ? " "
                              : "rgba(48, 48, 48, 0.6) !important",

                            mb: hasActions ? 1 : 1,
                            mt: hasActions ? 1 : 1,

                            textTransform: "capitalize",
                          }}
                          className={classes.blackColor}
                        >
                          {camelCaseToWords(eachSubMenu?.menuName)}
                        </Typography>
                      </Box>

                      {openSubMenus[eachSubMenu?.menuName] && (
                        <Box sx={{ width: "100%" }}>
                          {eachSubMenu?.actions &&
                            eachSubMenu?.actions?.map((eachAction: any) => {
                              // console.log(
                              //   "chedsac",
                              //   switches[
                              //     `${section?.menuName}.${eachSubMenu?.menuName}.${eachAction?.actionName}`
                              //   ]
                              // );
                              return (
                                <Box
                                  key={eachAction?.actionName}
                                  display="flex"
                                  flexDirection="row"
                                  justifyContent="space-between"
                                  alignItems="center"
                                  sx={{ width: "100%", margin: "3px 0px" }}
                                >
                                  <Box>
                                    <Typography
                                      sx={{
                                        marginLeft: { xs: 1, md: 2 },
                                        fontSize: {
                                          xs: 10,
                                          md: 12,
                                          color:
                                            "rgba(48, 48, 48, 0.6) !important ",
                                        },
                                        textTransform: "capitalize",
                                      }}
                                      className={classes.blackColor}
                                    >
                                      {camelCaseToWords(eachAction?.actionName)}
                                    </Typography>
                                  </Box>
                                  <Box sx={{}}>
                                    <FormControlLabel
                                      key={eachAction?.actionName}
                                      label=""
                                      control={
                                        // {permissionsSStatus==="loading"?(

                                        // )}
                                        <Switch
                                          checked={
                                            switches[
                                              `${section?.menuName}.${eachSubMenu?.menuName}.${eachAction?.actionName}`
                                            ] || false
                                          }
                                          onChange={() =>
                                            handleChangeSwitch(
                                              section?.menuName,
                                              eachSubMenu?.menuName,
                                              eachAction?.actionName
                                            )
                                          }
                                          disabled={shouldDisableSwitch(
                                            role.name,
                                            // eachAction?.actionName,
                                            // eachSubMenu?.access
                                          )}
                                          // disabled={
                                          //   (sectionName === "automation" &&
                                          //     planName !== "Pro" &&
                                          //     planName !== "Enterprise") ||
                                          //   shouldDisableSwitch(
                                          //     role.name,
                                          //     eachAction?.actionName,
                                          //     eachSubMenu?.access
                                          //   )
                                          // }
                                          //    name="Allow to view Analytics page"
                                          color="success"
                                          sx={{
                                            "&.Mui-unchecked  .MuiSwitch-thumb":
                                              {
                                                color: "white",
                                              },
                                            "& .MuiSwitch-track": {
                                              backgroundColor: "#3CAA93",
                                            },
                                            "&.Mui-checked .MuiSwitch-thumb": {
                                              color: "green",
                                            },
                                            "&.Mui-checked + .MuiSwitch-track":
                                              {
                                                backgroundColor: "green",
                                              },
                                          }}
                                        />
                                      }
                                    />
                                  </Box>
                                </Box>
                              );
                            })}
                        </Box>
                      )}
                    </Box>
                    <Box>
                      <FormControlLabel
                        key={eachSubMenu.menuName}
                        label=""
                        sx={{
                          marginLeft: "-75px !important",
                        }}
                        control={
                          // {permissionsSStatus==="loading"?(

                          // )}
                          <>
                            {eachSubMenu?.menuName !== "manageAccount" &&
                              eachSubMenu?.menuName !== "helpCenter" && (
                                <Switch
                                  checked={
                                    switches[
                                      `${section?.menuName}.${eachSubMenu?.menuName}`
                                    ] || false
                                  }
                                  onChange={() =>
                                    // eachSubMenu?.menuName !== "manageAccount" || eachSubMenu?.menuName !== "helpCenter" &&
                                    handleChangeSwitch(
                                      null,
                                      section?.menuName,
                                      eachSubMenu?.menuName
                                    )
                                  }
                                  disabled={shouldDisableSwitch(
                                    role.name,
                                    // eachSubMenu?.menuName,
                                    // section?.access
                                  )}
                                  // disabled={
                                  //   (sectionName === "automation" &&
                                  //     planName !== "Pro" &&
                                  //     planName !== "Enterprise") ||
                                  //   shouldDisableSwitch(
                                  //     role.name,
                                  //     eachSubMenu?.menuName,
                                  //     section?.access
                                  //   )
                                  // }
                                  //    name="Allow to view Analytics page"
                                  color="success"
                                  sx={{
                                    "&.Mui-unchecked  .MuiSwitch-thumb": {
                                      color: "white",
                                    },
                                    "& .MuiSwitch-track": {
                                      backgroundColor: "#3CAA93",
                                    },
                                    "&.Mui-checked .MuiSwitch-thumb": {
                                      color: "green",
                                    },
                                    "&.Mui-checked + .MuiSwitch-track": {
                                      backgroundColor: "green",
                                    },
                                  }}
                                />
                              )}
                          </>
                        }
                      />
                    </Box>
                  </Box>
                );
              })}
        </Box>
        <Box>
          <FormControlLabel
            key={section?.menuName}
            label=""
            sx={{
              marginLeft: "-75px !important",
              paddingTop: "5px",
            }}
            control={
              // {permissionsSStatus==="loading"?(

              // )}

              <>
                {section?.menuName !== "profile" &&
                  section?.menuName !== "inbox" && (
                    <Switch
                      checked={switches[section?.menuName] || false}
                      onChange={() =>
                        handleChangeSwitch(null, null, section?.menuName)
                      }
                      disabled={shouldDisableSwitch(
                        role.name,
                        // section?.menuName,
                        // null
                      )}
                      // disabled={
                      //   (sectionName === "automation" &&
                      //     planName !== "Pro" &&
                      //     planName !== "Enterprise") ||
                      //   shouldDisableSwitch(role.name, section?.menuName, null)
                      // }
                      //    name="Allow to view Analytics page"
                      color="success"
                      sx={{
                        // "& .MuiSwitch-root": {
                        //   width: 58, // Increase the width of the Switch component
                        //   height: 38, // Increase the height of the Switch component
                        // },
                        "&.Mui-unchecked  .MuiSwitch-thumb": {
                          color: "white",
                        },
                        "& .MuiSwitch-track": {
                          backgroundColor: "#3CAA93",
                        },
                        "&.Mui-checked .MuiSwitch-thumb": {
                          color: "green",
                        },
                        "&.Mui-checked + .MuiSwitch-track": {
                          backgroundColor: "green",
                        },
                      }}
                    />
                  )}
              </>
            }
          />
        </Box>
      </Box>
    );
  };

  return (
    <Grid className={classes.mainContainer}>
      {currentPlanDetails?.subscriptionPlan?.isActive &&
      (accountData?.isMetaEnabled ||
        (accountData?.isMetaEnabled === false &&
          currentPlanDetails?.subscriptionPlan?.planName === "Intro")) &&
      hasPermissionsPermission ? (
        <Grid className={classes.bgContainer}>
          <Box
            py={{ xs: 1, md: 4 }}
            px={{ xs: 1, md: 4 }}
            className={classes.manageContainer}
          >
            <Typography
              variant="h6"
              className={classes.blackColor}
              sx={{ display: "flex" }}
            >
              <span style={{ marginTop: "3px" }}>
                <AdminPanelSettingsIcon sx={{ height: "20px" }} />
              </span>
              &nbsp;Manage Permissions
            </Typography>
            {loginData?.isPlatform && (
              <FormControl
                sx={{
                  m: 1,
                  minWidth: 300,
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Autocomplete
                    sx={{
                      width: "100%",
                      "& .MuiAutocomplete-inputRoot": {
                        paddingTop: "0px",
                        paddingBottom: "0px",
                        height: "35px",
                        "& .MuiInputBase-root": {
                          height: "100%",
                        },
                      },
                    }}
                    id="company-autocomplete"
                    options={getAllCompanies?.filter(
                      (company: any) =>
                        company?.status === true && companyId !== company?.id
                    )}
                    getOptionLabel={(option) => option?.businessName || ""}
                    value={
                      getAllCompanies?.find(
                        (company: any) => company?.id === selectedCompany
                      ) || null
                    }
                    onChange={(event, newValue) => {
                      setSelectedCompany(newValue?.id || "");
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Company"
                        sx={{
                          flexGrow: 1,
                          height: "35px",
                          borderRadius: "8px",
                          paddingTop: "0px",
                          paddingBottom: "0px",
                          "& .MuiOutlinedInput-root": {
                            height: "100%",
                            "& .MuiOutlinedInput-input": {
                              padding: "0 14px", // Adjust padding to control the input text height
                              display: "flex",
                              alignItems: "center", // Center the text vertically
                              fontSize: "14px",
                            },
                          },
                        }}
                        InputLabelProps={{
                          sx: {
                            top: "50%",
                            transform: "translateY(-50%)",
                            fontSize: 14,
                            paddingLeft: "10px",
                            "&.MuiInputLabel-shrink": {
                              top: -9,
                              fontSize: "12px !important",
                              paddingLeft: "13px",
                              transform: "translateY(0)",
                            },
                          },
                        }}
                      />
                    )}
                    renderOption={(props, option) => (
                      <MenuItem
                        {...props}
                        key={option?.id}
                        sx={{ fontSize: 12 }}
                        value={option?.id}
                      >
                        {option?.businessName}
                      </MenuItem>
                    )}
                  />
                </Box>
              </FormControl>
            )}
          </Box>

          <Box className={classes.tabsContainer}>
            <TabContext value={selectedRoleId}>
              <Box>
                <TabList
                  onChange={handleChange}
                  indicatorColor="secondary"
                  aria-label="simple tabs example"
                  sx={{
                    "& .MuiTabs-indicator": {
                      backgroundColor: bgColors.green,
                      height: "3px",
                    },
                    "& .Mui-selected": {
                      color: `${bgColors.black} !important`,
                      fontWeight: "600 !important",
                    },
                    px: 3,
                  }}
                >
                  {visibleRoles?.map((role: any) => (
                    <Tab
                      key={role.id}
                      label={role.name.trim()}
                      value={role.id}
                      sx={{ textTransform: "none" }}
                    />
                  ))}

                  {checkRoleName && (
                    <Tooltip title="Add Role" placement="top" arrow>
                      <IconButton onClick={openNewRolePopover} size="small">
                        <AddIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  <NewRoleMember
                    open={newRoleDialog}
                    handleClose={handleCloseRolePopover}
                    companyId={selectedCompany}
                  />
                </TabList>
              </Box>
              {permissionsSStatus === "loading" ||
              getAllRolesSlice?.status === "loading" ? (
                <Box sx={{ height: "70vh" }}>
                  <LoadingComponent height="100%" color={bgColors.blue} />
                </Box>
              ) : (
                <>
                  <hr className={classes.lineStyles} />
                  {getAllRolesData?.map((role: any) => (
                    <TabPanel key={role.id} value={role.id}>
                      {Object.entries(permissions)?.map(
                        ([sectionName, section]: any) =>
                          renderSection(sectionName, section[0], role)
                      )}
                    </TabPanel>
                  ))}
                </>
              )}
            </TabContext>
          </Box>
        </Grid>
      ) : (
        <NoAccessPage />
      )}
    </Grid>
  );
};

export default ManagePermissions;
