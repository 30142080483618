// NavBar.js
import React from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { FaList } from "react-icons/fa";
import CloseIcon from "@mui/icons-material/Close";

const NavBar = ({ onClose }:any) => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      bgcolor: "white",
      color: "black",
      borderTopLeftRadius: "16px",
      borderTopRightRadius: "16px",
      padding: "10px",
      paddingLeft: "30px",
      zIndex: 1,
    }}
  >
    <Box
      component="span"
      sx={{
        display: "flex",
        alignItems: "center",
        fontWeight: "bold",
        flexGrow: 1,
        fontSize: "22px",
      }}
    >
      <span style={{ marginRight: "8px" }}>
        <FaList style={{ height: "20px", width: "20px", marginTop: "5px" }} />
      </span>
      Select a list
    </Box>
    <IconButton aria-label="close" sx={{ color: "black" }} onClick={onClose}>
      <CloseIcon />
    </IconButton>
  </Box>
);

export default NavBar;