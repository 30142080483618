import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Typography,
  InputLabel,
  Grid,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import CloseSvg from "../../assets/svgs/CloseSvg";
import { makeStyles } from "@mui/styles";
import TextFeildWithBorderComponet from "../common/TextFieldWithBorderComponent";
import PhoneInput from "../common/PhoneInput";
import { useAppDispatch, useAppSelector } from "../../utils/redux-hooks";
// import { fetchAllCountryCodes } from "../../redux/slices/ManageAccount/GetAllCountryCode";
// import { ADMIN_MANAGE_COMPANY_APIS } from "../../Apis/ManageCompany/ManageCompany";
import { toastActions } from "../../utils/toastSlice";
// import { fetchGetAllTeamMembers } from "../../redux/slices/ManageCompany/GetAllTeamMembers";
import { fetchGetAllTeamMembersByCompanyId } from "../../redux/slices/ManageCompany/GetAllTeamMembersByCompanyId";
import { useLocation } from "react-router-dom";
import { fetchAllRoles } from "../../redux/slices/ManageCompany/DisplayAllRoles";
import { MANAGE_CLIENTS } from "../../Apis/AdminLogin/ManageClients/ManageClient";
import LoadingComponent from "../common/LoadingComponent";
import { bgColors } from "../../utils/bgColors";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { validatePhoneNumber } from "../../utils/functions";

const useStyles = makeStyles({
  changePasswordContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    cursor: "pointer",
  },
  updateButtonStyles: {
    backgroundColor: "#3C3C3C",
    color: "#ffffff",
    height: "40px",
    borderRadius: "8px",
    width: "100%",
    cursor: "pointer",
  },
  blackColor: {
    color: "#303030 !important",
    fontWeight: "500 !important",
  },
});
const ALPHA_REGEX = /^[a-zA-Z\s]*$/;
const AddNewClientMember = ({ open, handleClose, clientId }: any) => {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const getAllRoles = useAppSelector((state:any) => state.getAllRoles?.data);
  // console.log(getAllRoles,'getAllRoles')
  // const loginData = useAppSelector((state) => state?.adminLogin?.data);
  // const getRoles = loginData?.roleName?.includes("Platform")
  //   ? getAllRoles?.filter((item: any) => item?.name?.includes("Platform"))
  //   : getAllRoles?.filter((item: any) => item?.name?.includes("Client"));

  const [isAddClientMemberLoading, SetIsAddClientMemberLoading] =
    useState(false);
  const [formData, setFormData] = useState({
    fullname: "",
    email: "",
    phoneNumber: "",
    countryCode: "+91",
    role: "",
  });
  const [formErrors, setFormErrors] = useState({
    fullname: "",
    email: "",
    countryCode: "",
    phoneNumber: "",
    role: "",
  });

  const handlePhoneChange = (value: string) => {
    setFormData({ ...formData, phoneNumber: value });
    setFormErrors({ ...formErrors, phoneNumber: "" });
  };
  const handleCountryCodeChange = (value: string) => {
    // console.log("value", value);
    setFormData({ ...formData, countryCode: value });
    setFormErrors({ ...formErrors, phoneNumber: "" });
  };
  useEffect(() => {
    dispatch(fetchAllRoles(clientId));
    // dispatch(fetchAllCountryCodes());
  }, [dispatch]);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    if (name === "fullname" && !ALPHA_REGEX.test(value)) {
      return;
    }
    setFormData({
      ...formData,
      [name]: value,
    });
    // Clear the error message when user starts typing
    setFormErrors({
      ...formErrors,
      [name]: "",
    });
  };

  const validateEmail = (email: string) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) {
      return "Email address is required.";
    } else if (!emailPattern.test(email.toLowerCase())) {
      return "Invalid Email Address";
    }
    return "";
  };

  // const validatePhoneNumber = (phoneNumber: string) => {
  //   const phoneNumberPattern = /^\d{10}$/;
  //   if (!phoneNumberPattern.test(phoneNumber)) {
  //     return "This phone number format is not recognized. Please check the country code and phone number";
  //   }
  //   return "";
  // };

  const isFormValid = () => {
    let valid = true;
    const newErrors: any = { ...formErrors };
    if (formData.fullname.trim() === "") {
      newErrors.fullname = "Full Name is required";
      valid = false;
    } else if (formData.fullname.length > 75) {
      newErrors.fullname = "Full Name cannot be more than 75 characters.";
      valid = false;
    }

    const emailError = validateEmail(formData.email);
    if (emailError) {
      newErrors.email = emailError;
      valid = false;
    }
    if (formData.role === "") {
      newErrors.role = "Role is required";
      valid = false;
    }

    // console.log(formData.phoneNumber, formData?.countryCode)
    const isValidPhone = /^\d+$/.test(formData?.phoneNumber);
    if (!formData?.phoneNumber && !formData?.countryCode) {
      newErrors.phoneNumber = "Phone Number & Country Code are required.";
      valid = false;
    } else if (!formData?.phoneNumber && formData?.countryCode) {
      newErrors.phoneNumber = "phone number is required.";
      valid = false;
    } else if (
      !validatePhoneNumber(formData?.phoneNumber, formData?.countryCode)
    ) {
      newErrors.phoneNumber =
        "This phone number format is not recognized. Please check the country code and phone number.";
      valid = false;
    } else if (!formData?.countryCode && formData?.phoneNumber) {
      newErrors.phoneNumber = "Country code is required.";
      valid = false;
    } else if (!isValidPhone) {
      newErrors.phoneNumber = "Phone number should only contain numbers.";
      valid = false;
    }

    // const phoneError = validatePhoneNumber(formData.phoneNumber);
    // if (formData.phoneNumber.trim() === "") {
    //   newErrors.phoneNumber = "Phone Number is required";
    //   valid = false;
    // } else if (phoneError) {
    //   // If there's an error in the phone number format, update form errors accordingly
    //   newErrors.phoneNumber = phoneError;
    //   valid = false;
    // } else if (formData.countryCode.trim() === "") {
    //   // If country code is empty, set the error in phoneNumber field
    //   newErrors.phoneNumber = "Country Code is required";
    //   valid = false;
    // }

    setFormErrors(newErrors);
    return valid;
  };

  const handleSave = async () => {
    if (isFormValid()) {
      SetIsAddClientMemberLoading(true);
      try {
        const response = await MANAGE_CLIENTS.addTeamMemberByCompanyId({
          ...formData,
          // phoneNumber: mobileNumber,
          companyId: location?.state?.clientDetails?.id,
        });
        // console.log("response", response);
        if (response.status === 200) {
          dispatch(
            toastActions.setToaster({
              type: "success",
              message: response?.data?.message,
            })
          );
          const postData = {
            search: "",
            companyId: location?.state?.clientDetails?.id,
          };
          dispatch(fetchGetAllTeamMembersByCompanyId(postData));
          setFormData({
            fullname: "",
            email: "",
            phoneNumber: "",
            role: "",
            countryCode: "+91",
          });
          // window.location.reload();
        } else {
          // console.log("error", response);
          dispatch(
            toastActions.setToaster({
              type: "error",
              message: response?.data?.message,
            })
          );
        }
      } catch (err: any) {
        // console.log("err", err);
        dispatch(
          toastActions.setToaster({
            type: "error",
            message:
              err?.response?.data?.message ||
              err?.response?.data?.errors?.Mobile[0],
          })
        );
      }
      SetIsAddClientMemberLoading(false);
      handleClose();
    }
  };

  // console.log("formErrors", formErrors);

  const getAllCountryCodes = useAppSelector((state:any) => state.getAllCoutryCodes);
  const getAllCountryCodesData = getAllCountryCodes?.data;
  const codeArray =
    getAllCountryCodesData &&
    getAllCountryCodesData?.map((item: any) => ({
      countryCode: item?.countryCode,
      countryImage: item?.countryImage,
    }));

  const handleCloseAll = () => {
    handleClose();
    setFormData({
      fullname: "",
      email: "",
      phoneNumber: "",
      role: "",
      countryCode: "+91",
    });
    setFormErrors({
      fullname: "",
      email: "",
      phoneNumber: "",
      role: "",
      countryCode: "",
    });
  };

  return (
    <Dialog
      open={open}
      onClose={handleCloseAll}
      PaperProps={{ style: { minWidth: "35%", borderRadius: "20px" } }}
    >
      <DialogTitle>
        <Box m={2} mb={0} className={classes.changePasswordContainer}>
          <Typography variant="h6" sx={{ display: "flex" }}>
            <span style={{ marginTop: "3px" }}>
              <PersonAddIcon sx={{ height: "22px" }} />
            </span>
            &nbsp;Add Team Member
          </Typography>
          <Box onClick={handleCloseAll}>
            <CloseSvg />
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box m={2} mt={0}>
          <Box mb={2}>
            <InputLabel className={classes.blackColor}>Full name</InputLabel>
            <TextFeildWithBorderComponet
              label="Enter your full name"
              name="fullname"
              placeholder=""
              value={formData?.fullname}
              error={!!formErrors?.fullname}
              helperText={formErrors?.fullname}
              onChange={handleInputChange}
              sx={{
                fontWeight: "600",
              }}
            />
          </Box>
          <Box mb={2}>
            <InputLabel className={classes.blackColor}>Email</InputLabel>
            <TextFeildWithBorderComponet
              label="Enter your email address"
              name="email"
              placeholder=""
              value={formData?.email}
              error={!!formErrors?.email}
              helperText={formErrors?.email}
              // disabled
              onChange={handleInputChange}
              sx={{
                fontWeight: "600",
              }}
            />
          </Box>
          <Box mb={3}>
            <InputLabel className={classes.blackColor}>Phone Number</InputLabel>
            <Grid xs={12}>
              <PhoneInput
                width="130px"
                gapRequired="48px"
                phoneNumWidth="270px"
                countryCodeArray={codeArray && codeArray}
                name="mobile"
                propsValue={formData?.phoneNumber}
                countryCode={formData?.countryCode}
                onChange={handlePhoneChange}
                onCountryCodeChange={handleCountryCodeChange}
                error={!!formErrors?.phoneNumber}
                // helperText={formErrors?.phoneNumber}
              />
            </Grid>
            {formErrors?.phoneNumber && (
              <Typography variant="caption" color="error" sx={{ fontSize: 12 }}>
                {formErrors?.phoneNumber}
              </Typography>
            )}
            {/* {formErrors?.countryCode && (
              <Typography variant="caption" color="error">
                {`${ formErrors?.countryCode}`}
              </Typography>
            )} */}
          </Box>

          <Box mb={2}>
            <InputLabel
              className={classes.blackColor}
              style={{ marginBottom: 5 }}
            >
              Role
            </InputLabel>
            <FormControl fullWidth size="small" error={!!formErrors.role}>
              <InputLabel
                // id="demo-simple-select-label"
                id="demo-simple-select-error-label"
                sx={{ fontSize: { xs: 12, md: 14 } }}
              >
                Select role for member
              </InputLabel>
              <Select
                // labelId="demo-simple-select-label"
                // id="Select role for member"
                labelId="demo-simple-select-error-label"
                id="Select role for member"
                name="role"
                value={formData.role}
                onChange={handleInputChange}
                // error={!!formErrors.role}
                label="Select role for member"
                error={!!formErrors?.role}
                // helperText={formErrors?.role}
                // inputlabelp
                sx={{
                  fontSize: "14px",
                  borderRadius: "8px",
                }}
              >
                {getAllRoles &&
                  getAllRoles
                    ?.filter((item: any) => item?.name !== "Owner")
                    ?.map((item: any) => {
                      return (
                        <MenuItem
                          key={item.id}
                          value={item.name}
                          sx={{ fontSize: 14 }}
                        >
                          {item?.name}
                        </MenuItem>
                      );
                    })}
              </Select>
              {formErrors.role && (
                <FormHelperText>{formErrors.role}</FormHelperText>
              )}
            </FormControl>
            {/* {formErrors.role && (
              <Typography variant="caption" color="error">
                {formErrors.role}
              </Typography>
            )} */}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box width="100%" ml={4} mr={4} mb={4}>
          {isAddClientMemberLoading ? (
            <LoadingComponent height="auto" color={bgColors?.blue} />
          ) : (
            <button onClick={handleSave} className={classes.updateButtonStyles}>
              Add Member
            </button>
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default AddNewClientMember;
