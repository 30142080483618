import { countVariables } from "../../../utils/functions";

interface Button {
  buttonType: string;
  buttonValue: string;
  countryCode?: any;
  buttonName?: any;
}

interface TemplateState {
  callButtonName?: string;
  phoneNumber?: string;
  countryCode?: string;
  [key: string]: any;
}

interface CreateButtonArrayParams {
  quickReplies: string[];
  redirectUrls: string[];
  urlButtonNames: string[];
  phoneNumber?: string;
  callButtonName?: string;
}
interface ProcessedData {
  updatedState: TemplateState;
  quickReply: string[];
  redirectUrl: string[];
  urlButtonName: string[];
}

export function ProcessButtonData(
  restTemplateState: TemplateState,
  buttons: Button[]
): ProcessedData {
  // Create the updatedState without 'buttons' and 'mediaFile'
  const updatedState: TemplateState = { ...restTemplateState };

  // Reset fields related to buttons
  let callButtonName = "";
  let phoneNumber = "";
  let countryCode = "";
  let quickReply: string[] = [];
  let redirectUrl: string[] = [];
  let urlButtonName: string[] = [];

  // Update the state fields based on buttonType
  buttons.forEach((button) => {
    if (button.buttonType === "PHONE_NUMBER") {
      callButtonName = button.buttonName;
      phoneNumber = button.buttonValue;
      countryCode = button.countryCode;
    }
    if (button.buttonType === "URL") {
      redirectUrl.push(button.buttonValue);
      urlButtonName.push(button.buttonName);
    }
    if (button.buttonType === "QUICK_REPLY") {
      quickReply.push(button.buttonValue);
    }
  });

  // Assign updated values to the state
  updatedState.callButtonName = callButtonName;
  updatedState.phoneNumber = phoneNumber;
  updatedState.countryCode = countryCode;

  return { updatedState, quickReply, redirectUrl, urlButtonName };
}

export function CreateButtonArray({
  quickReplies,
  redirectUrls,
  urlButtonNames,
  phoneNumber,
  callButtonName,
}: CreateButtonArrayParams): Button[] {
  const buttons: Button[] = [];

  if (phoneNumber && callButtonName) {
    buttons.push({
      buttonType: "PHONE_NUMBER",
      buttonValue: phoneNumber,
      buttonName: callButtonName,
    });
  }

  redirectUrls.forEach((url, index) => {
    buttons.push({
      buttonType: "URL",
      buttonValue: url,
      countryCode: "",
      buttonName: urlButtonNames[index],
    });
  });

  quickReplies.forEach((reply) => {
    buttons.push({
      buttonType: "QUICK_REPLY",
      buttonValue: reply,
      countryCode: "",
    });
  });

  return buttons;
}

export const extractVariables = (
  text: string | undefined | Array<any>, // Accept text or buttons array
  type: "body" | "header" | "url Button"
) => {
  if (!text) {
    return [];
  }

  // Check if type is url Button and text is an array (event.buttons)
  if (type === "url Button" && Array.isArray(text)) {
    // Iterate over buttons array and extract variables from each buttonValue
    return text.flatMap((button: any) => {
      const variableIds = button.buttonValue.match(/{{(\d+)}}/g) || [];
      if (variableIds.length === 0) return [];

      return variableIds.map((id: string) => ({
        type: "url Button",
        id: id,
        buttonName: button.buttonName, // Include buttonName for context
        value: "",
        field: "",
        fallBackValue: "",
      }));
    });
  }

  // For body and header, handle as usual (text is a string)
  if (typeof text === "string") {
    const variableIds = text.match(/{{(\d+)}}/g) || [];
    if (variableIds.length === 0) {
      return [];
    }

    return variableIds.map((id: string) => ({
      type: type,
      id: id,
      value: "",
      field: "",
      fallBackValue: "",
    }));
  }

  return [];
};

export const generatePayload = (
  userData: any,
  contactNumber: number,
  templateState: any
) => {
  // Get the latest variables based on their type
  const latestBodyVariables = templateState?.variables?.filter(
    (variable: any) => variable.type === "body"
  );
  const latestHeaderVariables = templateState?.variables?.filter(
    (variable: any) => variable.type === "header"
  );
  const latestRedirectUrlVariables = templateState?.variables?.filter(
    (variable: any) => variable.type === "url Button"
  );

  // Populate bodyVariableValues array
  const bodyVariableValues = latestBodyVariables?.map((variable: any) =>
    variable.value ? variable.value : variable.fallBackValue
  );

  // Populate headerVariableValue string
  const headerVariableValue = latestHeaderVariables?.length
    ? latestHeaderVariables[0]?.value
      ? latestHeaderVariables[0]?.value
      : latestHeaderVariables[0]?.fallBackValue
    : "";

  // Populate redirectUrlVariableValues array
  const redirectUrlVariableValues = latestRedirectUrlVariables?.map(
    (variable: any) =>
      variable.value
        ? encodeURIComponent(variable.value)
        : encodeURIComponent(variable.fallBackValue)
  );

  return {
    businessId: userData?.companyId,
    userId: userData?.userId,
    templateId: templateState.templateId,
    contact: [contactNumber],
    headerVariableValue: headerVariableValue ? headerVariableValue : null,
    bodyVariableValues:
      bodyVariableValues?.length !== 0 ? bodyVariableValues : [],
    redirectUrlVariableValues:
      redirectUrlVariableValues?.length !== 0 ? redirectUrlVariableValues : [],
    mediaFile: templateState?.mediaAwsUrl ? templateState?.mediaAwsUrl : null,
  };
};

export const updateVariables = (
  type: string,
  templateState: any,
  buttonIndex?: number
) => {
  // Get existing variables from the template state
  const existingVariables = templateState?.variables || [];

  // Filter variables based on the type (body, header, or url Button)
  const variablesOfType =
    existingVariables.filter((variable: any) => variable.type === type) || [];

  // Determine the new variable count
  const existingVariableCount = variablesOfType.length;
  const newCount = existingVariableCount + 1;

  // Create a new variable object
  const newVariable = {
    type: type,
    id: `{{${newCount}}}`,
    value: "",
    field: "",
    fallBackValue: "",
  };

  // Create the new variables array based on the type
  let newVariables: any;
  if (type === "body") {
    const newBodyVariables = [...variablesOfType, newVariable];
    newVariables = existingVariables
      .filter((variable: any) => variable.type !== "body")
      .concat(newBodyVariables);
  } else if (type === "header") {
    const newHeaderVariables = [...variablesOfType, newVariable];
    newVariables = existingVariables
      .filter((variable: any) => variable.type !== "header")
      .concat(newHeaderVariables);
  } else if (type === "url Button") {
    // Insert new variable at the specified index if provided, otherwise at the end
    const newUrlButtonVariables =
      buttonIndex !== undefined
        ? [
            ...variablesOfType.slice(0, buttonIndex),
            newVariable,
            ...variablesOfType.slice(buttonIndex),
          ]
        : [...variablesOfType, newVariable];

    newVariables = existingVariables
      .filter((variable: any) => variable.type !== "url Button")
      .concat(newUrlButtonVariables);
  }

  console.log("new", newVariables);
  // Return the updated variables
  return { newVariables, newCount };
};

export const countTemplateVariables = (templateData: any) => {
  const headerText = templateData?.header || "";
  const bodyText = templateData?.body || "";

  // Count variables in the header
  const headerVariablesCount = countVariables(headerText);

  // Count variables in the body
  const bodyVariablesCount = countVariables(bodyText);

  // Count variables in each URL button individually
  const urlButtonVariablesCount =
    templateData?.buttons
      ?.filter((button: any) => button.buttonType === "URL")
      .map((button: any, index: number) => ({
        buttonIndex: index, // Identify the URL button
        buttonName: button.buttonName, // Give a meaningful name
        buttonValue: button.buttonValue, // Give a meaningful name
        variablesCount: countVariables(button.buttonValue || ""),
      })) || [];

  return {
    headerVariablesCount,
    bodyVariablesCount,
    urlButtonVariablesCount, // Array with count for each URL button
  };
};
