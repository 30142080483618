import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import TextFeildWithBorderComponet from "../../../components/common/TextFieldWithBorderComponent";
import PhoneInput from "../../../components/common/PhoneInput";
// import EditSvg from "../../../assets/svgs/EditSvg";
import { bgColors } from "../../../utils/bgColors";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../utils/redux-hooks";
// import { fetchAllCountryCodes } from "../../../redux/slices/ManageAccount/GetAllCountryCode";
import { MANAGE_CLIENTS } from "../../../Apis/AdminLogin/ManageClients/ManageClient";
import { toastActions } from "../../../utils/toastSlice";
import { businessCategories } from "../EditManageCompany/EditManageCompany";
import LoadingComponent from "../../../components/common/LoadingComponent";
import PeopleIcon from "@mui/icons-material/People";
import NoAccessPage from "../../../components/common/NoAccess";
import { validatePhoneNumber } from "../../../utils/functions";

const useStyles = makeStyles({
  mainContainer: {
    backgroundColor: bgColors.white1,
    padding: "14px",
    height: "100%",
    width: "100%",
    overflowY: "auto",
  },
  bgContainer: {
    backgroundColor: bgColors.white,
    borderRadius: "25px",
    height: "100%",
    width: "100%",
    overflow: "auto",
  },
  manageContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  SaveChangesButton: {
    // backgroundColor: bgColors.green,
    border: `1px solid ${bgColors.green}`,
    color: bgColors.green,
    borderRadius: "8px",
    width: "110px",
    height: "32px",
    // padding: "10px",
    cursor: "pointer",
  },
  mainBorderStyles: {
    borderRadius: "18px",
    border: `2px solid ${bgColors.gray5}`,
  },
  editColor: {
    color: `${bgColors.green} !important`,
    fontWeight: "600 !important",
    // fontSize: "18px !important",
  },
  blackColor: {
    top: "8px",
    color: `${bgColors.black1} !important`,
    fontWeight: "500 !important",
  },
  grayColor: {
    color: `${bgColors.black1} !important`,
    opacity: "60% !important",
  },
  editButtonContainer: {
    borderRadius: "34px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "120px",
    height: "32px",
    cursor: "pointer",
    border: `2px solid ${bgColors.green}`,
  },
  profileEditContainer: {
    textAlign: "center",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  hiddenInput: {
    display: "none",
  },
});

const EditManageClient = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const history = useNavigate();
  const { clientDetails } = location?.state || {};
  // console.log(clientDetails)

  const getCountry = useAppSelector(
    (state: any) => state?.getAllCountries?.data
  );
  const getuserPermissions = useAppSelector(
    (state: any) => state?.getUserPermissions?.data
  );
  const manageClientObject = getuserPermissions?.profile?.find((item: any) =>
    Object?.prototype?.hasOwnProperty?.call(item, "manageClients")
  );
  const manageClientActions = manageClientObject
    ? manageClientObject?.manageClients
    : [];
  const hasEditClientAcess = (permission: any) => {
    if (manageClientActions?.includes(permission)) {
      return true;
    }
    return false;
  };
  const [isEditManageLoading, setIsEditManageLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [img, setImg] = useState("");
  const [formData, setFormData] = useState({
    companyName: clientDetails?.businessName,
    // clientName: clientDetails?.name,
    email: clientDetails?.businessEmail,
    website: "",
    businessCategory: clientDetails?.businessCategory,
    clientId: clientDetails?.id,
    countryCode: clientDetails?.countryCode,
    phoneNumber: clientDetails?.phoneNumber,
    address: clientDetails?.address,
    country: clientDetails?.country,
    image: clientDetails?.companyLogoLink,
    category: clientDetails?.category || "",
  });
  // console.log(formData);
  const [valid, setValid] = useState({
    clientId: "",
    email: "",
    website: "",
    phoneNumber: "",
    companyName: "",
    // clientName: "",
    businessCategory: "",
  });

  useEffect(() => {
    setFormData({
      companyName: clientDetails?.businessName,
      // clientName: clientDetails?.name,
      email: clientDetails?.businessEmail,
      website: clientDetails?.businessWebsite,
      countryCode: clientDetails?.countryCode,
      phoneNumber: clientDetails?.phoneNumber,
      clientId: clientDetails?.id,
      address: clientDetails?.address,
      country: clientDetails?.countryName,
      image: clientDetails?.companyLogoLink,
      category: clientDetails?.category || "",
      businessCategory: clientDetails?.businessCategory,
    });
  }, [
    clientDetails?.businessName,
    clientDetails?.category,
    clientDetails?.country,
    clientDetails?.countryCode,
    clientDetails?.phoneNumber,
    clientDetails?.businessEmail,
    clientDetails?.address,
    clientDetails?.companyLogoLink,
    clientDetails?.businessCategory,
  ]);

  // console.log("clientDetails", clientDetails);

  const handleImageEditClick = () => {
    const inputFile = document.getElementById("image");
    if (inputFile) {
      inputFile.click();
    }
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      // Validate image format
      const imageType = file.type;
      if (imageType !== "image/jpeg" && imageType !== "image/png") {
        setErrors({ ...errors, image: "Image must be JPG or PNG format" });
        return;
      } else {
        setErrors({ ...errors, image: "" });
      }

      const reader = new FileReader();
      reader.onload = () => {
        const binaryString = reader.result;
        const bytes = new Uint8Array(binaryString as ArrayBuffer);
        const blob = new Blob([bytes], { type: file.type });
        setImg(URL.createObjectURL(blob));
        const formDataNew = new FormData();
        formDataNew.append("image", blob, file.name);
        setFormData({
          ...formData,
          image: formDataNew.get("image"),
        });
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const getAllCountryCodes = useAppSelector(
    (state: any) => state.getAllCoutryCodes
  );
  const getAllCountryCodesData = getAllCountryCodes?.data;
  const codeArray =
    getAllCountryCodesData &&
    getAllCountryCodesData?.map((item: any) => ({
      countryCode: item?.countryCode,
      countryImage: item?.countryImage,
    }));

  // useEffect(() => {
  //   dispatch(fetchAllCountryCodes());
  // }, [dispatch]);

  const handleChange = (e: any) => {
    // const isRequiredFields = validateRequiredFields();
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setValid((prevData) => ({
      ...prevData,
      [name]: "",
    }));
  };

  const validateRequiredFields = () => {
    const errors = { ...valid };
    let isValid = true;

    if (!formData.clientId) {
      errors.clientId = "ClientId is required.";
    }

    if (formData?.companyName?.trim() && formData?.companyName?.length > 100) {
      errors.companyName = "Company name cannot be more than 100 characters.";
      isValid = false;
    }

    const emailPattern =
      // /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/;

    if (formData?.email?.trim() && !emailPattern.test(formData.email)) {
      errors.email = "Invalid email address.";
      isValid = false;
    }

    const isValidPhone = /^\d+$/.test(formData?.phoneNumber);

    if (
      formData?.phoneNumber?.trim() &&
      !validatePhoneNumber(formData?.phoneNumber, formData?.countryCode)
    ) {
      errors.phoneNumber =
        "This phone number format is not recognized. Please check the country code and phone number.";
      isValid = false;
    } else if (formData?.phoneNumber?.trim() && !isValidPhone) {
      errors.phoneNumber = "Phone number should only contain numbers.";
      isValid = false;
    }

    const websitePattern =
      /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;

    if (formData?.website?.trim() && !websitePattern.test(formData.website)) {
      errors.website = "Invalid website format.";
      isValid = false;
    }
    if (!formData.businessCategory) {
      errors.businessCategory = "Company category is required.";
      isValid = false;
    }

    setValid(errors);
    return isValid;
  };

  const handleUpdateChange = async () => {
    const isRequiredFields = validateRequiredFields();
    // console.log(isRequiredFields, "isRequiredFields");
    if (isRequiredFields) {
      setIsEditManageLoading(true);
      try {
        const data = {
          clientId: clientDetails?.id,
          companyName: formData?.companyName,
          // clientName: formData?.clientName,
          website: formData?.website,
          businessEmail: formData?.email,
          countryCode: formData?.countryCode,
          phoneNumber: formData?.phoneNumber,
          country: formData?.country,
          address: formData?.address,
          companyLogoLink: formData?.image,
          category: formData?.category || "",
          businessCategory: formData?.businessCategory,
        };
        // console.log("data", data);
        const getUpdateRes = await MANAGE_CLIENTS.updateClient(data);
        // console.log(getUpdateRes, "getUpdateRes")
        if (getUpdateRes?.status === 200) {
          // console.log("getUpdateRes", getUpdateRes);
          history(-1);
          dispatch(
            toastActions.setToaster({
              type: "success",
              message: getUpdateRes?.data?.message,
            })
          );
        } else {
          // console.log("getUpdateRes", getUpdateRes);
        }
      } catch (error: any) {
        // console.log("error", error);
        dispatch(
          toastActions.setToaster({
            type: "error",
            message: `${error?.response?.data?.message}`,
          })
        );
      }
      setIsEditManageLoading(false);
    }
  };

  const handlePhoneChange = (value: string) => {
    // console.log("value", value);

    // // Validate phone number format
    // const phoneRegex = /^\d{14}$/;
    // if (!phoneRegex.test(value)) {
    //   setErrors({ ...errors, phoneNumber: "Phone number must be 10 digits" });
    // } else {
    //   setErrors({ ...errors, phoneNumber: "" });
    // }
    setFormData({ ...formData, phoneNumber: value });
    setValid({ ...valid, phoneNumber: "" });
  };
  const handleCountryCodeChange = (value: string) => {
    // console.log("value", value);
    setFormData({ ...formData, countryCode: value });
  };

  const handleFieldChange = (fieldName: string, value: string) => {
    setFormData({ ...formData, [fieldName]: value });
    setValid({ ...valid, businessCategory: "" });
  };
  const [isHovered, setIsHovered] = useState(false);
  const selectedCountry = formData?.country
    ? getCountry?.find(
        (country: { countryName: any }) =>
          country.countryName === formData.country
      )
    : null;
  return (
    <Grid className={classes.mainContainer}>
      {hasEditClientAcess("editClient") ? (
        <Grid className={classes.bgContainer}>
          <Box p={3} className={classes.manageContainer}>
            <Typography m={2} variant="h6" sx={{ display: "flex" }}>
              <span style={{ marginTop: "5px" }}>
                <PeopleIcon sx={{ height: "22px" }} />
              </span>
              &nbsp;Edit Client Details
            </Typography>
            <Box m={2}>
              {isEditManageLoading ? (
                <LoadingComponent height="auto" color={bgColors?.blue} />
              ) : (
                <button
                  className={classes.SaveChangesButton}
                  onClick={handleUpdateChange}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                  style={{
                    backgroundColor: isHovered
                      ? "rgba(68, 71, 70, 0.08)"
                      : "#fff",
                    cursor: isHovered ? "pointer" : "default",
                  }}
                >
                  Update Details
                </button>
              )}
            </Box>
          </Box>
          <Box ml={3} mr={2}>
            <Box p={4} className={classes.mainBorderStyles}>
              <Box m={2} className={classes.profileEditContainer}>
                <img
                  alt="company"
                  style={{
                    width: "70px",
                    height: "70px",
                    borderRadius: "14px",
                  }}
                  src={
                    img ||
                    clientDetails?.companyLogoLink ||
                    "/images/companyLogo.png"
                  }
                />
                <Box
                  className={classes.editButtonContainer}
                  onClick={handleImageEditClick}
                  ml={2}
                >
                  <Typography
                    variant="body2"
                    className={classes.editColor}
                    p={1}
                    sx={{ fontSize: { xs: 10, md: 12 } }}
                  >
                    Change Image
                  </Typography>
                </Box>
                <input
                  type="file"
                  id="image"
                  className={classes.hiddenInput}
                  accept="image/*"
                  onChange={handleImageChange}
                />
              </Box>
              <Grid container item xs={12}>
                <Grid item xs={5.5} mr={4}>
                  <Box mb={3}>
                    <InputLabel
                      className={classes.blackColor}
                      sx={{ fontSize: { xs: 12, md: 14 }, mb: 1 }}
                    >
                      Company Name
                    </InputLabel>
                    <TextFeildWithBorderComponet
                      value={formData?.companyName}
                      label="Enter company name"
                      name="companyName"
                      // fontweight="bold"
                      onChange={handleChange}
                      placeholder=""
                      error={!!valid.companyName}
                      helperText={valid.companyName}
                    />
                  </Box>
                  {/* <Box mb={3}>
                  <InputLabel
                    className={classes.blackColor}
                    sx={{ fontSize: { xs: 12, md: 14 }, mb: 1 }}
                  >
                    Client Name
                  </InputLabel>
                  <TextFeildWithBorderComponet
                    value={formData?.clientName}
                    label="Enter client name"
                    name="clientName"
                    // fontweight="bold"
                    onChange={handleChange}
                    placeholder=""
                    error={!!valid.clientName}
                    helperText={valid.clientName}
                  />
                </Box> */}
                  <Box mb={3} mt={3}>
                    <InputLabel
                      className={classes.blackColor}
                      sx={{ fontSize: { xs: 12, md: 14 }, mb: 1 }}
                    >
                      Phone Number
                    </InputLabel>
                    {/* <Grid xs={12}> */}
                    <PhoneInput
                      width="130px"
                      gapRequired="58px"
                      phoneNumWidth="225px"
                      countryCodeArray={codeArray && codeArray}
                      name="phoneNumber"
                      // fontweight="bold"
                      disabled={true}
                      propsValue={formData?.phoneNumber || ""}
                      countryCode={formData?.countryCode || "+91"}
                      onChange={handlePhoneChange}
                      onCountryCodeChange={handleCountryCodeChange}
                      error={!!valid.phoneNumber}
                      helperText={valid.phoneNumber}
                    />
                    {/* </Grid> */}
                    {valid.phoneNumber && (
                      <Typography
                        variant="caption"
                        color="error"
                        sx={{ fontSize: 12 }}
                      >
                        {valid.phoneNumber}
                      </Typography>
                    )}
                  </Box>
                  <Box mb={3}>
                    <InputLabel
                      className={classes.blackColor}
                      sx={{ fontSize: { xs: 12, md: 14 }, mb: 1 }}
                    >
                      Client ID
                    </InputLabel>
                    <TextFeildWithBorderComponet
                      value={formData?.clientId}
                      label="Enter client ID"
                      name="clientId"
                      disabled
                      placeholder=""
                      // fontweight="bold"
                      error={!!valid.clientId}
                      helperText={valid.clientId}
                    />
                  </Box>
                  <Box mb={3}>
                    <Typography
                      className={classes.blackColor}
                      sx={{ fontSize: { xs: 12, md: 14 }, mb: 2 }}
                    >
                      Country
                    </Typography>
                    <Autocomplete
                      fullWidth
                      size="small"
                      options={getCountry || []}
                      getOptionLabel={(option) => option.countryName}
                      value={selectedCountry}
                      onChange={(event, newValue) => {
                        handleFieldChange(
                          "country",
                          newValue ? newValue.countryName : ""
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Country"
                          sx={{
                            "& .MuiInputLabel-root": {
                              fontSize: { xs: 12, md: 14 },
                              mb: 1,
                            },
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "8px",
                              fontSize: "14px",
                            },
                          }}
                          InputProps={{
                            ...params.InputProps,
                            style: {
                              fontSize: "14px",
                              // fontWeight: "bold",
                            },
                          }}
                        />
                      )}
                      renderOption={(props, option) => (
                        <li {...props}>
                          <Typography
                            sx={{
                              fontSize: 14,
                              fontWeight:
                                option.countryName === formData?.country
                                  ? "normal"
                                  : "normal",
                            }}
                          >
                            {option.countryName}
                          </Typography>
                        </li>
                      )}
                    />
                  </Box>
                </Grid>
                <Grid item ml={3} xs={5.5}>
                  <Box mb={3}>
                    <InputLabel
                      className={classes.blackColor}
                      sx={{ fontSize: { xs: 12, md: 14 }, mb: 2 }}
                    >
                      Company Category
                    </InputLabel>
                    <FormControl
                      fullWidth
                      size="small"
                      error={!!valid.businessCategory}
                    >
                      <InputLabel
                        // id="demo-simple-select-label"
                        id="demo-simple-select-error-label"
                        sx={{ fontSize: { xs: 12, md: 14 } }}
                      >
                        Company category
                      </InputLabel>
                      <Select
                        // labelId="demo-simple-select-label"
                        // id="demo-simple-select"
                        labelId="demo-simple-select-error-label"
                        id="demo-simple-select-error"
                        value={formData?.businessCategory || ""}
                        size="small"
                        label="Company category"
                        inputProps={{
                          style: {
                            fontSize: "14px",
                          },
                        }}
                        sx={{
                          marginTop: "3px",
                          borderRadius: "8px",
                          fontSize: "14px",
                          // fontWeight: "bold",
                        }}
                        onChange={(e) =>
                          handleFieldChange("businessCategory", e.target.value)
                        }
                        // onChange={handleChange}
                      >
                        {businessCategories &&
                          businessCategories?.map((item: any) => (
                            <MenuItem
                              key={item?.value}
                              value={item?.value}
                              sx={{ fontSize: 14 }}
                            >
                              {item.categoryName}
                            </MenuItem>
                          ))}
                      </Select>
                      {valid.businessCategory && (
                        <FormHelperText>
                          {valid.businessCategory}
                        </FormHelperText>
                      )}
                    </FormControl>
                    {/* {valid.businessCategory && (
              <Typography variant="caption" display="block" style={{ color: "red", marginTop: "3px" }}>
                {valid.businessCategory}
              </Typography>
            )} */}
                  </Box>
                  <Box mb={3} mt={3.5}>
                    <InputLabel
                      className={classes.blackColor}
                      sx={{ fontSize: { xs: 12, md: 14 }, mb: 1 }}
                    >
                      Company Website
                    </InputLabel>
                    <TextFeildWithBorderComponet
                      value={formData?.website}
                      label="Enter business website"
                      name="website"
                      onChange={handleChange}
                      placeholder=""
                      // fontweight="bold"
                      error={!!valid.website}
                      helperText={valid.website}
                    />
                  </Box>
                  <Box mb={3} mt={3.5}>
                    <InputLabel
                      className={classes.blackColor}
                      sx={{ fontSize: { xs: 12, md: 14 }, mb: 1 }}
                    >
                      Company Email
                    </InputLabel>
                    <TextFeildWithBorderComponet
                      value={formData?.email}
                      label="Enter company email"
                      name="email"
                      placeholder=""
                      onChange={handleChange}
                      // disabled
                      // fontweight="bold"
                      error={!!valid.email}
                      helperText={valid.email}
                    />
                  </Box>

                  <Box mb={3}>
                    <InputLabel
                      className={classes.blackColor}
                      sx={{ fontSize: { xs: 12, md: 14 }, mb: 1 }}
                    >
                      Company Address
                    </InputLabel>
                    <TextFeildWithBorderComponet
                      value={formData?.address}
                      onChange={handleChange}
                      label="Enter company address"
                      name="address"
                      placeholder=""
                      // fontweight="bold"
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      ) : (
        <NoAccessPage />
      )}
    </Grid>
  );
};

export default EditManageClient;
