/* global process */

import axios from "axios";

const INBOX_API_URL = process.env.REACT_APP_BASE_URL;

const fetchInboxContacts = (data: any) => {
  return axios({
    url: `${INBOX_API_URL}/inbox-contacts?BusinessId=${data?.businessId}&UserId=${data?.userId}`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
    data: data?.filtering,
  });
};

const fetchInboxContactConversations = (data: any) => {
  return axios({
    url: `${INBOX_API_URL}/inbox-contact-conversations?BusinessId=${data?.businessId}&Contact=${data?.contact}`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
  });
};

const fetchInboxContactAssignment = (data: any) => {
  return axios({
    url: `${INBOX_API_URL}/assign-chat?BusinessId=${data?.businessId}&AssignUserId=${data?.teamMemberId}`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
    data: {
      contact: data?.contact,
    },
  });
};

const fetchInboxUserDetails = (data: any) => {
  return axios({
    url: `${INBOX_API_URL}/user-details?BusinessId=${data?.businessId}&Contact=${data?.contactId}`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
  });
};

const closeChat = (data: any) => {
  return axios({
    url: `${INBOX_API_URL}/close-chat?BusinessId=${data?.businessId}&UserId=${data?.userId}`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
    data: {
      contact: [data?.contact],
    },
  });
};
const markAsSpam = (data: any) => {
  return axios({
    url: `${INBOX_API_URL}/mark-as-spam?BusinessId=${data?.businessId}&UserId=${data?.userId}`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
    data: {
      contact: [data?.contact],
    },
  });
};

export const INBOX_APIS = {
  fetchInboxContacts,
  fetchInboxContactConversations,
  fetchInboxContactAssignment,
  fetchInboxUserDetails,
  closeChat,
  markAsSpam,
};
