import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { OPTED_MANAGEMENT_APIS } from "../../../Apis/AdminLogin/Automation/OptinManagement";

export interface IData {
  status: "loading" | "succeeded" | "failed" | "idle";
  data: any;
}

const initialState: IData = {
  status: "idle",
  data: null,
};

export const fetchOptinKeywords = createAsyncThunk(
  "fetchOptinKeywords",
  async (
    businessId:string ,
    { rejectWithValue }
  ) => {
    try {
      const response = await OPTED_MANAGEMENT_APIS.getOptinKeywords(
        businessId
      );
      return response?.data;
    } catch (error: any) {
      return rejectWithValue(error.response || "An error occurred");
    }
  }
);

export const optinKeywordsSlice = createSlice({
  name: "optinKeywords",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchOptinKeywords?.pending, (state) => {
        state.status = "loading";
        state.data = [];
      })
      .addCase(fetchOptinKeywords?.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(fetchOptinKeywords?.rejected, (state) => {
        state.status = "failed";
        state.data = [];
      });
  },
});

export const optinKeywordsActions = optinKeywordsSlice?.actions;
export default optinKeywordsSlice.reducer;
