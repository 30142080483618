import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { WORKFLOW_API } from "../../../Apis/AdminLogin/Automation/Workflows";

export interface IData {
    status: "loading" | "succeeded" | "failed" | "idle";
    data: any;
  }

  const initialState: IData = {
    status: "idle",
    data: null,
  };

  export const getVariableNames = createAsyncThunk(
    "workflow/getVariableNames",
    async (payload: any, { rejectWithValue }) => {
      try {
        const response = await WORKFLOW_API.getVariableName(payload?.companyId);
        return response?.data;
      } catch (error: any) {
        // Provide a descriptive error message
        return rejectWithValue(error.response || 'An error occurred');
      }
    }
  );


  export const getVariableNamesSlice = createSlice({
    name: "getVariableNames",
    initialState,
    reducers: {
       
    },
    extraReducers: (builder) => {
      builder
        .addCase(getVariableNames.pending, (state) => {
          state.status = "loading";
          state.data = null;
        })
        .addCase(getVariableNames.fulfilled, (state, action) => {
          state.status = "succeeded";
          state.data = action.payload.data;
        })
        .addCase(getVariableNames.rejected, (state) => {
          state.status = "failed";
        });
    },
  });


export const getVeriableNamesSliceActions = getVariableNamesSlice.actions;
export default getVariableNamesSlice.reducer;
