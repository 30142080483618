import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CAMPAIGN_API } from "../../../Apis/Campaign/Campaign";


export interface IData {
    status: "loading" | "succeeded" | "failed" | "idle";
    data: any;
  }

  const initialState: IData = {
    status: "idle",
    data: null,
  };

  export const getCampaign = createAsyncThunk(
    "getCampaign",
    async (data: any) => {
      const response = await CAMPAIGN_API.filtersByIdCampaigns(data);
      return response?.data;
    }

  );


  export const GetCampaignSlice = createSlice({
    name: "GetCampaignSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(getCampaign.pending, (state) => {
          state.status = "loading";
        })
        .addCase(getCampaign.fulfilled, (state, action) => {
          state.status = "succeeded";
          state.data = action.payload;
        })
        .addCase(getCampaign.rejected, (state) => {
          state.status = "failed";
        });
    },
  });

  export const GetCampaignActions = GetCampaignSlice.actions
  export default GetCampaignSlice.reducer
