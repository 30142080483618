import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { WORKFLOW_API } from "../../../Apis/AdminLogin/Automation/Workflows";

export interface IData {
  createSelectResponseStatus: "loading" | "succeeded" | "failed" | "idle";
  createSelectResponseData: any;
  error: string | null;
}

const initialState: IData = {
  createSelectResponseStatus: "idle",
  createSelectResponseData: null,
  error: null,
};

export const createSelectResponse = createAsyncThunk(
  "workflow/createSelectResponse",
  async (payload: { companyId: string, response: string },{ rejectWithValue }) => {
    try {
      const response = await WORKFLOW_API.createSelectResponse(
        payload.companyId, 
        payload.response
      );
      return response?.data;
    } catch (error: any) {
      // Provide a descriptive error message
      return rejectWithValue(error.response || "An error occurred");
    }
  }
) as any;

export const createSelectResponseSlice = createSlice({
  name: "createSelectResponse",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createSelectResponse.pending, (state) => {
        state.createSelectResponseStatus = "loading";
        state.createSelectResponseData = null;
        state.error = null;
      })
      .addCase(
        createSelectResponse.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.createSelectResponseStatus = "succeeded";
          state.createSelectResponseData = action.payload;
          state.error = null;
        }
      )
      .addCase(createSelectResponse.rejected, (state, action) => {
        state.createSelectResponseStatus = "failed";
        // state.error = action.payload || 'An error occurred';
      });
  },
});

export const createSelectResponseSliceActions = createSelectResponseSlice.actions;
export default createSelectResponseSlice.reducer;
