import { Box, Grid, Tooltip, Typography } from "@mui/material";
import LogOut from "../../assets/svgs/LogOut";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router";
import SignOutPopUp from "../SignOutComponents/SignOutPopUp";
import { useAppSelector } from "../../utils/redux-hooks";
import SpeakerSvg from "../../assets/svgs/SpeakerSvg";
import ScheduleSendOutlinedIcon from "@mui/icons-material/ScheduleSendOutlined";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { checkOnetimeCampaignsPermission, checkSceduledCampaignsPermission } from "../../utils/permissions";

const useStyles = makeStyles({
  container: {
    backgroundColor: "#F8F8F8",
    height: "100vh",
    textAlign: "center",
  },
  sideBarContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
    alignItems: "center",
  },

  manageItem: {
    cursor: "pointer",
    padding: "5px",
  },
  activeItem: {
    backgroundColor: "#f0f0f0",
    color: "#303030 !important",
    "& .MuiTypography-root": {
      fontWeight: "600 !important",
    },
  },
  signOutContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  textColor: {
    color: "#4B5A5A",
    fontSize: "20px",
  },
  blackColor: {
    color: "#000000",
    fontWeight: "600 !important",
  },
  sideBar: {
    position: "sticky",
    top: 0,
    height: "100vh",
    overflowY: "auto",
  },
});

const CampaignsSideBar = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  // const getManageAccount = useAppSelector((state) => state.manageAccountData);
  // const data = getManageAccount?.data;
  // const loginData = useAppSelector((state: any) => state.adminLogin.data);

  const getuserPermissionSlice = useAppSelector(
    (state:any) => state.getUserPermissions
  );
  const getUserPermissionData = getuserPermissionSlice?.data;

  const [activeSection, setActiveSection] = useState("");
  // const [scheduledTooltip, setScheduledTooltip] = useState(false);
  // const [openDialog, setOpenDialog] = useState(false);

  const hasOnetimeCampaignsPermission = checkOnetimeCampaignsPermission(
    getUserPermissionData?.campaigns
  );
  const hasScheduledCampaignsPermission = checkSceduledCampaignsPermission(
    getUserPermissionData?.campaigns
  );

  // Function to handle navigation and set active section
  const handleNavigation = (path: string) => {
    navigate(`/campaigns/${path}`);
    setActiveSection(path);
  };

  // const handleOpenDialog = () => {
  //   setOpenDialog(true);
  // };

  // const handleCloseDialog = () => {
  //   setOpenDialog(false);
  // };

  useEffect(() => {
    const path = location.pathname.split("/")[2];
    setActiveSection(path || "");
  }, [location.pathname]);
  // console.log("activeSection", activeSection);
  return (
    <Grid className={classes.container}>
      <Box textAlign="start" pl={1} className={classes.sideBarContainer} pt={3}>
        <Box m={2} mb={1}>
          <SpeakerSvg />
        </Box>
        <Typography variant="h6" className={classes.blackColor}>
          Campaigns
        </Typography>
      </Box>
      {/* <Box className={classes.manageContainer}> */}
      <Box>
        {hasOnetimeCampaignsPermission && (
          <Box
            m={2}
            textAlign="start"
            pl={1}
            className={`${classes.manageItem} ${classes.textColor} ${
              activeSection.includes("one-time") ? classes.activeItem : ""
            }`}
            onClick={() => handleNavigation("one-time")}
          >
            <Typography
              fontSize="14px"
              sx={{ display: "flex", alignItems: "center" }}
            >
              <span style={{ paddingTop: "3px" }}>
                <AccessTimeIcon sx={{ height: "18px" }} />
              </span>
              &nbsp;Onetime
            </Typography>
          </Box>
        )}
        {hasScheduledCampaignsPermission && (
          <Box
            m={2}
            textAlign="start"
            pl={1}
            className={`${classes.manageItem} ${classes.textColor} ${
              activeSection.includes("scheduled") ? classes.activeItem : ""
            }`}
            onClick={() => handleNavigation("scheduled")}
          >
            <Typography
              fontSize="14px"
              sx={{ display: "flex", alignItems: "center" }}
            >
              <span style={{ paddingTop: "3px" }}>
                <ScheduleSendOutlinedIcon sx={{ height: "18px" }} />
              </span>
              &nbsp;Scheduled
            </Typography>
          </Box>
        )}

        {/* <Box
            m={2}
            className={`${classes.manageItem} ${classes.textColor} ${
              activeSection.includes("Drafts") ? classes.activeItem : ""
            }`}
            // onClick={() => handleNavigation("Drafts")}
          >
            <Typography variant="body1">Drafts</Typography>
          </Box> */}
      </Box>
      {/* </Box> */}
    </Grid>
  );
};

export default CampaignsSideBar;
