import React, { useState, useRef } from "react";
import Typography from "@mui/material/Typography";
import { Box, Grid } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { makeStyles } from "@mui/styles";
import { bgColors } from "../../../utils/bgColors";

const useStyles = makeStyles({
  DragAndDropStyles: {
    color: bgColors.green,
    border: `1px solid ${bgColors.green}`,
    borderRadius: "8px",
    width: "150px",
    height: "32px",
    cursor: "pointer",
    backgroundColor: "#fff",
    "&:hover": {
      backgroundColor: "rgba(68, 71, 70, 0.08)",
    },
  },
  inputDisplay: {
    display: "none",
  },
  inputFiles: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 12,
    // color: bgColors?.blue,
  },
});

interface FileUploadProps {
  selectedFiles: File[];
  setSelectedFiles: React.Dispatch<React.SetStateAction<File[]>>;
}

const FileUpload: React.FC<FileUploadProps> = ({
  selectedFiles,
  setSelectedFiles,
}) => {
  const classes = useStyles();
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const files = Array.from(event.target.files);
      setSelectedFiles(files);
    }
  };

  const handleIconClick = () => {
    fileInputRef.current?.click();
  };

  return (
    <div>
      <Grid item xs={12}>
        <Box
          sx={{
            border: "2px dashed #ccc",
            borderRadius: "8px",
            padding: 4,
            textAlign: "center",
            backgroundColor: "#f9f9f9",
            mb: 2,
            cursor: "pointer",
          }}
          onClick={handleIconClick}
        >
          <CloudUploadIcon
            fontSize="large"
            sx={{ color: bgColors?.green, mb: 2 }}
          />
          <Typography variant="body1" fontWeight="bold" color="textSecondary">
            Select a file to upload
          </Typography>
          <Typography variant="body2" color="textSecondary">
            or drag and drop it here
          </Typography>
        </Box>
        <input
          type="file"
          multiple
          accept=".xls, .xlsx"
          className={classes.inputDisplay}
          onChange={handleFileChange}
          ref={fileInputRef}
        />
        <div className={classes.inputFiles}>
          {selectedFiles?.map((file, index) => (
            <div key={index}>{file?.name}</div>
          ))}
        </div>
      </Grid>
    </div>
  );
};

export default FileUpload;
