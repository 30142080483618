import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Typography,
  Avatar,
  IconButton,
  Button,
  Popover,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { styled } from "@mui/system";
import ReactDOM from "react-dom";
import { useLocation } from "react-router-dom";

const ChatContainer = styled(Box)({
  width: "300px",
  borderRadius: "10px",
  overflow: "hidden",
  boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
});

const Header = styled(Box)<{ bgColor: string }>(({ bgColor }) => ({
  backgroundColor: bgColor, // Use the bgColor prop here
  padding: "10px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

const ChatBody = styled(Box)({
  height: "200px",
  padding: "10px",
  backgroundImage: `url("/images/whatsappBG.jpg")`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  overflowY: "auto",
});

const MessageBubble = styled(Box)({
  backgroundColor: "white",
  borderRadius: "10px",
  padding: "10px",
  maxWidth: "80%",
  marginBottom: "10px",
});

const StartChatButton = styled(Button)<{ bgColor: string }>(({ bgColor }) => ({
  backgroundColor: bgColor,
  color: "white",
  width: "100%",
  "&:hover": {
    backgroundColor: "#128C7E",
  },
}));

const PopupTrigger = styled(Button)(
  ({ theme, isOpen, bgColor, borderRadius, position }: any) => ({
    position: "fixed",
    bottom: "20px",
    left: position === "bottom-left" ? "20px" : "auto",
    right: position === "bottom-right" ? "20px" : "auto",
    borderRadius: `${borderRadius}px`,
    padding: isOpen ? "12px" : "8px 16px",
    minWidth: isOpen ? "48px" : "auto",
    width: isOpen ? "48px" : "auto",
    height: isOpen ? "48px" : "auto",
    backgroundColor: bgColor,
    color: "white",
    textTransform: "none",
    boxShadow: "0 2px 5px rgba(0,0,0,0.3)",
    "&:hover": {
      backgroundColor: "#128C7E",
    },
    zIndex: theme.zIndex.modal - 1,
  })
);

const ChatPopup = styled(Box)(({ theme, position }: any) => ({
  position: "fixed",
  bottom: "80px",
  left: position === "bottom-left" ? "20px" : "auto",
  right: position === "bottom-right" ? "20px" : "auto",
  zIndex: theme.zIndex.modal,
}));

const EngageChatPopup = ({ chatConfig, widgetconfig, urlFields }: any) => {
  const location = useLocation(); // Get the current location object
  const currentPage = location.pathname; // Extract the current page path
  // console.log(currentPage, "currentPage");
  const [isOpen, setIsOpen] = useState<any>(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  // const whatsappRedirect = `https://api.whatsapp.com/send?phone=${phoneNumber?.countryCode.replace(
  //   "+",
  //   ""
  // )}${
  //   phoneNumber?.phoneNumber
  // }&text=${message}#/${currentPage}&title=${titleName}`;

  // const handleWhatsappUrl = () => {
  //   window.open(whatsappRedirect, "_blank"); // Opens in a new tab
  // };

  const dynamicMargins = {
    marginBottom: chatConfig?.marginBottom
      ? `${chatConfig.marginBottom}px`
      : "0px",
    marginLeft: chatConfig?.marginLeft ? `${chatConfig.marginLeft}px` : "0px",
    marginRight: chatConfig?.marginRight
      ? `${chatConfig.marginRight}px`
      : "0px",
  };

  const popupContent = isOpen ? (
    <ChatPopup position={chatConfig?.position}>
      <ChatContainer sx={{ ...dynamicMargins, backgroundColor: "white" }}>
        <Header bgColor={widgetconfig?.brandColor}>
          <Box display="flex" alignItems="center">
            {widgetconfig?.brandImageUrl ? (
              <img
                src={widgetconfig.brandImageUrl}
                alt={widgetconfig?.brandName || "Brand Logo"}
                style={{
                  width: 32,
                  height: 32,
                  borderRadius: "50%",
                  marginRight: "8px",
                }}
              />
            ) : (
              <Avatar
                sx={{
                  bgcolor: "orange",
                  width: 32,
                  height: 32,
                  marginRight: 1,
                }}
              >
                E
              </Avatar>
            )}
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="subtitle1" color="white">
                {widgetconfig?.brandName}
              </Typography>
              <Typography sx={{ fontSize: "12px", color: "white" }}>
                {widgetconfig?.brandSubtitle}
              </Typography>
            </Box>
          </Box>
          <IconButton
            size="small"
            sx={{ color: "white" }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </Header>
        <ChatBody>
          <MessageBubble>
            <Typography variant="body2">{widgetconfig?.brandName}</Typography>
            <Typography variant="body1">
              <span
                dangerouslySetInnerHTML={{
                  __html: widgetconfig?.defaultOnScreenMessage.replaceAll(
                    "\n",
                    "<br />"
                  ),
                }}
              />
            </Typography>
          </MessageBubble>
        </ChatBody>
        <Box
          p={1}
          //  onClick={handleWhatsappUrl}
        >
          <StartChatButton
            variant="contained"
            fullWidth
            bgColor={chatConfig?.buttonBackground}
          >
            {widgetconfig?.widgetCtaText}
          </StartChatButton>
        </Box>
        <Typography
          variant="caption"
          align="center"
          display="block"
          sx={{ bgcolor: "#f0f0f0", py: 0.5 }}
        >
          ⚡ by Engageto
        </Typography>
      </ChatContainer>
    </ChatPopup>
  ) : null;

  return (
    <>
      <PopupTrigger
        onClick={() => handleToggle()}
        //@ts-ignore
        isOpen={isOpen}
        bgColor={chatConfig?.buttonBackground}
        borderRadius={chatConfig?.borderRadius}
        position={chatConfig?.position}
        sx={dynamicMargins}
      >
        {isOpen ? (
          <WhatsAppIcon />
        ) : (
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <WhatsAppIcon />
            <Typography>{chatConfig?.ctaText}</Typography>
          </Box>
        )}
      </PopupTrigger>
      {ReactDOM.createPortal(popupContent, document.body)}
    </>
  );
};

export default EngageChatPopup;
