import { bgColors } from "../../utils/bgColors";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import ArrowDownSvg from "../../assets/svgs/ArrowDownSvg";
import TemplatesFilterPopover from "../../components/TemplateComponents/TemplatesFilterPopover";
import React, { useCallback, useEffect, useRef, useState } from "react";
import CategoryIcon from "@mui/icons-material/Category";
import { SiAuthy, SiMarketo } from "react-icons/si";
import { SiTicktick } from "react-icons/si";
import { MdDelete, MdOutlineDataUsage } from "react-icons/md";
import { IoIosCloseCircle } from "react-icons/io";
import { GiSandsOfTime } from "react-icons/gi";
import { VscIssueDraft } from "react-icons/vsc";
import { MdChecklist } from "react-icons/md";
import { BiSolidCategory } from "react-icons/bi";
import { checkTemplatesLibraryPermission } from "../../utils/permissions";
import NoAccessPage from "../../components/common/NoAccess";

const useStyles = makeStyles({
  mainContainer: {
    backgroundColor: bgColors.white1,
    padding: "14px",
    height: "100vh",
    width: "100%",
    overFlow: "hidden !important",
  },
  bgContainer: {
    backgroundColor: bgColors.white,
    borderRadius: "25px",
    height: "100%",
    width: "100%",
    overFlow: "hidden !important",
    display: "flex",
    flexDirection: "column",
  },
  searchField: {
    width: "100%",
    borderRadius: "12px",
    // height: "38px",
    // backgroundColor: bgColors.white2,
    backgroundColor: "white",
    "& input::placeholder": {
      textAlign: "left",
      fontSize: "14px",
      fontFamily: "inter",
      color: "#000000 !important",
    },
  },
  manageContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  blackColor: {
    color: `${bgColors.black1} !important`,
    fontWeight: "600 !important",
  },
  grayColor: {
    color: `${bgColors.black1} !important`,
    opacity: "60% !important",
  },
  messageCountContainer: {
    // border: `2px solid ${bgColors.gray5}`,
    // borderRadius: "5px",
    // padding: "3px",
    // paddingRight: "5px",
  },
  messageInnerContainer: {
    border: "2px solid #F2F2F2",
    borderRadius: "6px",
    paddingInline: "4px",
    display: "flex",
    flexDirection: "row",
  },
  iconStyles: {
    cursor: "pointer",
    paddingLeft: "5px",
  },
  rotatedIcon: {
    cursor: "pointer",
    paddingRight: "5px",
    transform: "rotate(180deg)",
  },
  SaveChangesButton: {
    // backgroundColor: bgColors.green,
    border: `1px solid ${bgColors.green}`,
    color: bgColors.green,
    borderRadius: "8px",
    width: "120px",
    height: "32px",
    // padding: "10px",
    cursor: "pointer",
  },
});

const Catalog = () => {
  const classes = useStyles();
  let templateRef = useRef<HTMLDivElement>(null);

  return (
    <Grid className={classes.mainContainer} ref={templateRef}>
      {/* {hasTemplatesLibraryPermission ? ( */}
      <Box className={classes.bgContainer}>
        <Box sx={{ height: "120px" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              justifyContent: "space-between",
              alignItems: "center",
            }}
            px={{ xs: 1, md: 0 }}
            py={{ xs: 1, md: 3 }}
          >
            <Box
              sx={{
                // display: { xs: "block", md: "flex" },
                width: "100%",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h6"
                ml={{ xs: 1, md: 3 }}
                className={classes.blackColor}
                sx={{ display: "flex" }}
              >
                <span style={{ marginTop: "5px" }}>
                  <CategoryIcon sx={{ height: "22px" }} />
                </span>
                &nbsp;Catalog
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      {/* ) : (
        <NoAccessPage />
      )} */}
    </Grid>
  );
};

export default Catalog;
