import { Box, Grid, InputLabel, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PhoneInput from "../../../components/common/PhoneInput";
import { bgColors } from "../../../utils/bgColors";
import { useAppDispatch, useAppSelector } from "../../../utils/redux-hooks";
import React, { useState } from "react";
// import { fetchGetAllCountries } from "../../../redux/slices/ManageAccount/GetAllCountryDetails";
// import { fetchAllCountryCodes } from "../../../redux/slices/ManageAccount/GetAllCountryCode";
import { GET_MANAGE_ACCOUNT } from "../../../Apis/ManageAccountApis/GetManangeAccount";
import { toastActions } from "../../../utils/toastSlice";
import { fetchAccountDetails } from "../../../redux/slices/ManageAccount/AccountDetailsSlice";
import { useNavigate } from "react-router-dom";
import TextFieldWithBorderComponent from "../../../components/common/TextFieldWithBorderComponent";
import { Autocomplete } from "@mui/material";
import LoadingComponent from "../../../components/common/LoadingComponent";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import NoAccessPage from "../../../components/common/NoAccess";
import { validatePhoneNumber } from "../../../utils/functions";

const useStyles = makeStyles({
  mainContainer: {
    backgroundColor: bgColors.white1,
    padding: "14px",
    height: "100vh",
    width: "100%",
    overflowY: "auto",
  },
  bgContainer: {
    backgroundColor: bgColors.white,
    borderRadius: "25px",
    height: "100%",
    width: "100%",
    overflow: "auto",
  },
  manageContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  SaveChangesButton: {
    // backgroundColor: bgColors.green,
    border: `1px solid ${bgColors.green}`,
    color: bgColors.green,
    borderRadius: "8px",
    width: "110px",
    height: "32px",
    // padding: "10px",
    cursor: "pointer",
  },
  mainBorderStyles: {
    borderRadius: "18px",
    border: `2px solid ${bgColors.gray5}`,
  },
  blackColor: {
    color: `${bgColors.black1} !important`,
    fontWeight: "500 !important",
    top: "8px",
  },
  editColor: {
    color: `${bgColors.green} !important`,
    fontWeight: "600 !important",
    // fontSize: "14px !important",
  },
  grayColor: {
    color: `${bgColors.black1} !important`,
    opacity: "60% !important",
  },
  editButtonContainer: {
    borderRadius: "34px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "120px",
    height: "32px",
    cursor: "pointer",
    border: `2px solid ${bgColors.green}`,
  },
  profileEditContainer: {
    textAlign: "center",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  hiddenInput: {
    display: "none",
  },
});
// interface Country {
//   countryName: string;
// }

// interface CountrySelectProps {
//   classes: any;
//   editedData: { countryName?: string };
//   handleFieldChange: (fieldName: string, value: string) => void;
//   getAllCountries: Country[];
// }
const EditManageAccount = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const getManageAccount = useAppSelector((state: any) => state.accountData);
  const accountData = getManageAccount?.data;
  const countryCodeArray = useAppSelector(
    (state: any) => state.getAllCoutryCodes?.data
  );
  const codeArray =
    countryCodeArray &&
    countryCodeArray?.map((item: any) => ({
      countryCode: item?.countryCode,
      countryImage: item?.countryImage,
    }));
  const getAllCountries = useAppSelector(
    (state: any) => state.getAllCountries?.data
  );
  const getuserPermissionData = useAppSelector(
    (state: any) => state?.getUserPermissions?.data
  );
  const profilePermissions = getuserPermissionData?.profile;
  const manageAccountObject = profilePermissions?.find((item: any) =>
    Object?.prototype?.hasOwnProperty?.call(item, "manageAccount")
  );
  const manageAccountActions = manageAccountObject
    ? manageAccountObject.manageAccount
    : [];
  const hasEditAccountAcess = (permission: any) => {
    if (manageAccountActions?.includes(permission)) {
      return true;
    }
    return false;
  };

  const [isUpdateDetailsLoading, setIsUpdateDetailsLoading] = useState(false);
  const [img, setImg] = useState("");
  const [editedData, setEditedData] = useState<any>({
    emailAddress: accountData?.emailAddress || "",
    name: accountData?.name || "",
    address: accountData?.address || "",
    phoneNumber: accountData?.phoneNumber || "",
    countryCode: accountData?.countryCode || "+91",
    designation: accountData?.designation || "",
    countryName: accountData.countryName || "",
    about: accountData?.about || "",
    facebookBusinessManagerId: accountData?.facebookBusinessManagerId || "",
    whatsAppBusinessId: accountData?.whatsAppBusinessId || "",
    image: accountData?.image || null,
  });
  const [errors, setErrors] = useState<any>({});
  const [valid, setValid] = useState({
    name: "",
    emailAddress: "",
    phoneNumber: "",
    designation: "",
    facebookBusinessManagerId: "",
    whatsAppBusinessId: "",
  });
  const [isHovered, setIsHovered] = useState(false);

  const validateRequiredFields = () => {
    const errors = { ...valid };
    let isValid = true;

    if (!editedData?.name) {
      errors.name = "Name is required.";
      isValid = false;
    } else if (editedData.name.length > 75) {
      errors.name = "Name cannot be more than 75 characters.";
      isValid = false;
    }

    const emailPattern =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (!editedData.emailAddress) {
      errors.emailAddress = "Email address is required.";
      isValid = false;
    } else if (!emailPattern.test(editedData.emailAddress)) {
      errors.emailAddress = "Invalid email address.";
      isValid = false;
    }

    const isValidPhone = /^\d+$/.test(editedData?.phoneNumber);
    if (!editedData?.phoneNumber) {
      errors.phoneNumber = "Phone number is required.";
      isValid = false;
    } else if (
      !validatePhoneNumber(editedData?.phoneNumber, editedData?.countryCode)
    ) {
      errors.phoneNumber =
        "This phone number format is not recognized. Please check the country code and phone number.";
      isValid = false;
    } else if (!isValidPhone) {
      errors.phoneNumber = "Phone number should only contain numbers.";
      isValid = false;
    }

    if (editedData.designation.length > 75) {
      errors.designation = "Designation cannot be more than 75 characters.";
      isValid = false;
    }
    // if (editedData.whatsAppBusinessId && !/^[\w-.]+$/.test(editedData.whatsAppBusinessId)) {
    //   errors.whatsAppBusinessId = "Invalid WhatsApp Business ID.";
    //   isValid = false;
    // }

    if (
      editedData.whatsAppBusinessId &&
      !/^[\w-.@#_!]+$/.test(editedData.whatsAppBusinessId)
    ) {
      errors.whatsAppBusinessId = "Invalid WhatsApp Business ID.";
      isValid = false;
    }

    // if (editedData.facebookBusinessManagerId && !/^\d+$/.test(editedData.facebookBusinessManagerId)) {
    //   errors.facebookBusinessManagerId = "Invalid Facebook Business Manager ID.";
    //   isValid = false;
    // }
    if (
      editedData.facebookBusinessManagerId &&
      !/^(https?:\/\/)?(www\.)?facebook\.com\/[A-Za-z0-9._-]+$/.test(
        editedData.facebookBusinessManagerId
      )
    ) {
      errors.facebookBusinessManagerId =
        "Invalid Facebook Business Manager ID.";
      isValid = false;
    }

    setValid(errors);
    return isValid;
  };

  const handleImageEditClick = () => {
    const inputFile = document.getElementById("imageInput");
    if (inputFile) {
      inputFile.click();
    }
  };

  const handlePhoneChange = (value: string) => {
    // console.log("value", value);

    // Validate phone number format
    // const phoneRegex = /^\d{14}$/;
    // if (!phoneRegex.test(value)) {
    //   setErrors({ ...errors, phoneNumber: "Phone number must be 10 digits" });
    // } else {
    //   setErrors({ ...errors, phoneNumber: "" });
    // }
    setEditedData({ ...editedData, phoneNumber: value });
    setValid((prevErrors) => ({
      ...prevErrors,
      phoneNumber: "",
    }));
  };
  const handleCountryCodeChange = (value: string) => {
    // console.log("value", value);
    setEditedData({ ...editedData, countryCode: value });
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      // Validate image format
      const imageType = file.type;
      if (imageType !== "image/jpeg" && imageType !== "image/png") {
        setErrors({ ...errors, image: "Image must be JPG or PNG format" });
        return;
      } else {
        setErrors({ ...errors, image: "" });
      }

      const reader = new FileReader();
      reader.onload = () => {
        const binaryString = reader.result;
        const bytes = new Uint8Array(binaryString as ArrayBuffer);
        const blob = new Blob([bytes], { type: file.type });
        setImg(URL.createObjectURL(blob));
        const formData = new FormData();
        formData.append("image", blob, file.name);
        setEditedData({
          ...editedData,
          image: formData.get("image"),
        });
      };
      const base64Reader = new FileReader();
      base64Reader.onload = () => {
        const base64String = base64Reader.result as string;
        localStorage.setItem("userImage", base64String);
      };
      base64Reader.readAsDataURL(file);
      reader.readAsArrayBuffer(file);
    }
  };

  // const handleFieldChange = (fieldName: string, value: string) => {
  //   let newValue = value;
  //   let fieldErrors = { ...errors };
  //    // Apply validation for name and designation fields
  // if (fieldName === "name" || fieldName === "designation") {
  //   // Remove non-alphabetic characters and limit to 30 characters
  //   newValue = value.replace(/[^a-zA-Z]/g, '').substring(0, 30);

  //   // Check if newValue is empty
  //   if (newValue.trim() === '') {
  //     fieldErrors[fieldName] = "Field cannot be empty";
  //   } else if (fieldName === "name" && value.length > 30 ) {
  //     // Display error if the length of original value is greater than 30
  //     fieldErrors[fieldName] = "More than 30 characters not allowed";
  //   } else {
  //     delete fieldErrors[fieldName]; // Clear any existing error
  //   }
  // }

  //   setEditedData({ ...editedData, [fieldName]: value });
  //   setErrors(fieldErrors);
  // };
  const handleFieldChange = (fieldName: string, value: string) => {
    // console.log("fieldname", fieldName, value);
    let fieldErrors = { ...errors };
    // const isRequiredFields = validateRequiredFields();

    // Apply validation for name and designation fields
    if (fieldName === "name" || fieldName === "designation") {
      // Remove non-alphabetic characters and limit to 30 characters
      const newValue = value.replace(/[^a-zA-Z\s]/g, "").substring(0, 76);

      // Check if newValue is empty
      if (newValue.trim() === "") {
        fieldErrors[fieldName] = "Field cannot be empty";
      } else if (fieldName === "name" && newValue.length === 30) {
        // Display error if the length of newValue is greater than 30
        fieldErrors[fieldName] = "More than 30 characters not allowed";
      } else if (newValue.length === 30 && fieldName === "designation") {
        // Display error if the length of newValue is greater than 30
        fieldErrors[fieldName] = "More than 30 characters not allowed";
      } else {
        delete fieldErrors[fieldName]; // Clear any existing error
      }

      setEditedData({ ...editedData, [fieldName]: newValue });
    } else {
      setEditedData({ ...editedData, [fieldName]: value });
    }
    setValid((prevErrors) => ({
      ...prevErrors,
      [fieldName]: "",
    }));
    setErrors(fieldErrors);
  };

  const handleSubmit = async () => {
    // Validation for mandatory fields
    // const mandatoryFields = [
    //   "image",
    //   "phoneNumber",
    //   "emailAddress",
    //   "address",
    //   "designation",
    //   "countryName",
    //   "name",
    // ];
    // const errorsCopy = { ...errors };
    // mandatoryFields.forEach((field) => {
    //   if (!editedData[field]) {
    //     formValid = false;
    //     errorsCopy[field] = "This Field is Required";
    //   } else {
    //     errorsCopy[field] = "";
    //   }
    // });
    // setErrors(errorsCopy);
    const isRequiredFields = validateRequiredFields();
    if (isRequiredFields) {
      setIsUpdateDetailsLoading(true);
      try {
        const response = await GET_MANAGE_ACCOUNT.updateManageAccount({
          ...editedData,
          id: accountData?.id,
        });
        // console.log("response", response);
        if (response.status === 200) {
          dispatch(fetchAccountDetails(response?.data?.manageAccountId));
          navigate(-1);
          setEditedData({});
          setImg("");
          dispatch(
            toastActions.setToaster({
              type: "success",
              message: `${response?.data?.message}`,
            })
          );
        }
        setIsUpdateDetailsLoading(false);
      } catch (err: any) {
        // console.log(err);
        dispatch(
          toastActions.setToaster({
            type: "error",
            message: `${err?.response?.data?.message}`,
          })
        );
        setIsUpdateDetailsLoading(false);
      }
    }
  };

  // useEffect(() => {
  //   if (data) {
  //     setEditedData(data);
  //   }
  // }, [data]);

  // console.log("getManageAccount", getAllCountries);
  const selectedCountry = editedData?.countryName
    ? getAllCountries?.find(
        (country: { countryName: any }) =>
          country.countryName === editedData.countryName
      )
    : null;

  // console.log("editedData", editedData);

  return (
    <Grid className={classes.mainContainer}>
      {hasEditAccountAcess("editDetails") ? (
        <Grid className={classes.bgContainer}>
          <Box
            pt={{ xs: 1, md: 4 }}
            px={{ xs: 1, md: 3 }}
            className={classes.manageContainer}
          >
            <Typography variant="h6" sx={{ display: "flex" }}>
              <span style={{ marginTop: "5px" }}>
                <ManageAccountsIcon
                  sx={{ height: "25px", marginTop: "-2px" }}
                />
              </span>
              &nbsp;Edit Account
            </Typography>
            {/* <Box className={classes.editButtonContainer}> */}
            {isUpdateDetailsLoading ? (
              <LoadingComponent height="auto" color={bgColors?.blue} />
            ) : (
              <button
                onClick={handleSubmit}
                className={classes.SaveChangesButton}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                style={{
                  backgroundColor: isHovered
                    ? "rgba(68, 71, 70, 0.08)"
                    : "#fff",
                  cursor: isHovered ? "pointer" : "default",
                }}
              >
                Update Details
              </button>
            )}
            {/* </Box> */}
          </Box>
          <Box p={{ xs: 1, md: 3 }}>
            <Box p={4} className={classes.mainBorderStyles}>
              <Box m={2} className={classes.profileEditContainer}>
                <img
                  alt="profile"
                  src={img || editedData?.image || "/images/profile.png"}
                  style={{
                    width: "106px",
                    height: "106px",
                    borderRadius: "14px",
                  }}
                />
                {/* <Box m={2} className={classes.profileEditContainer}> */}
                <Box
                  className={classes.editButtonContainer}
                  onClick={handleImageEditClick}
                  ml={2}
                >
                  <Typography
                    // variant="body2"
                    className={classes.editColor}
                    p={1}
                    sx={{ fontSize: { xs: 10, md: 12 } }}
                  >
                    Change Image
                  </Typography>
                </Box>
                <input
                  type="file"
                  id="imageInput"
                  className={classes.hiddenInput}
                  accept="image/*"
                  onChange={handleImageChange}
                />
                {/* </Box> */}
              </Box>
              <Box style={{ display: "flex", justifyContent: "center" }}>
                <Typography variant="body2" color="error">
                  {errors.image}
                </Typography>
              </Box>
              <Grid container justifyContent="space-between">
                <Grid item xs={12} md={5.5}>
                  <Box mb={3}>
                    <InputLabel
                      className={classes.blackColor}
                      sx={{ fontSize: { xs: 12, md: 14 }, mb: 1 }}
                    >
                      Name
                    </InputLabel>
                    <TextFieldWithBorderComponent
                      placeholder=""
                      label="Enter your name"
                      name="name"
                      // size="small"
                      value={editedData?.name || ""}
                      // sx={{fontSize:"22px",fontWeight:"600 !important"}}
                      onChange={(e) =>
                        handleFieldChange("name", e.target.value)
                      }
                      // fontweight="bold"
                      error={!!valid.name}
                      helperText={valid.name}
                    />
                    {/* <Typography variant="body2" color="error">
                    {errors.name}
                  </Typography> */}
                  </Box>
                  <Box mb={3}>
                    <InputLabel
                      className={classes.blackColor}
                      sx={{ fontSize: { xs: 12, md: 14 }, mb: 1 }}
                    >
                      Email Address
                    </InputLabel>
                    <TextFieldWithBorderComponent
                      disabled
                      placeholder=""
                      label="Enter your email address"
                      name="emailAddress"
                      size="small"
                      value={editedData?.emailAddress || ""}
                      onChange={(e) =>
                        handleFieldChange("emailAddress", e.target.value)
                      }
                      // fontweight="bold"
                      error={!!valid.emailAddress}
                      helperText={valid.emailAddress}
                    />
                    {/* <Typography variant="body2" color="error">
                    {errors.emailAddress}
                  </Typography> */}
                  </Box>
                  <Box mb={3}>
                    <InputLabel
                      className={classes.blackColor}
                      sx={{ fontSize: { xs: 12, md: 14 }, mt: 2, top: 10 }}
                    >
                      Address
                    </InputLabel>
                    <TextFieldWithBorderComponent
                      sx={{ mt: 2 }}
                      placeholder=""
                      label="Enter your address"
                      name="address"
                      size="small"
                      value={editedData?.address || ""}
                      onChange={(e) =>
                        handleFieldChange("address", e.target.value)
                      }
                      // fontweight="bold"
                    />
                    {/* <Typography variant="body2" color="error">
                    {errors.address}
                  </Typography> */}
                  </Box>
                  <Box mb={3}>
                    <InputLabel
                      className={classes.blackColor}
                      sx={{ fontSize: { xs: 12, md: 14 }, mt: 2, top: 6 }}
                    >
                      Facebook Business Manager ID
                    </InputLabel>
                    <TextFieldWithBorderComponent
                      sx={{ mt: 1.5 }}
                      placeholder=""
                      label="Enter your Facebook business manager ID"
                      name="facebookBusinessManagerId"
                      size="small"
                      value={editedData?.facebookBusinessManagerId || ""}
                      onChange={(e) =>
                        handleFieldChange(
                          "facebookBusinessManagerId",
                          e.target.value
                        )
                      }
                      // fontweight="bold"
                      error={!!valid.facebookBusinessManagerId}
                      helperText={valid.facebookBusinessManagerId}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={5.5}>
                  <Box mb={3}>
                    <InputLabel
                      className={classes.blackColor}
                      sx={{ fontSize: { xs: 12, md: 14 }, mb: 1 }}
                    >
                      Designation
                    </InputLabel>
                    <TextFieldWithBorderComponent
                      placeholder=""
                      label="Enter your designation"
                      name="designation"
                      size="small"
                      value={editedData?.designation || ""}
                      onChange={(e) =>
                        handleFieldChange("designation", e.target.value)
                      }
                      // fontweight="bold"
                      error={!!valid.designation}
                      helperText={valid.designation}
                    />
                    {/* <Typography variant="body2" color="error">
                    {errors.designation}
                  </Typography> */}
                  </Box>
                  <Box mb={3}>
                    <InputLabel
                      className={classes.blackColor}
                      sx={{ fontSize: { xs: 12, md: 14 }, mb: 1 }}
                    >
                      Phone Number
                    </InputLabel>
                    <Grid xs={12}>
                      <PhoneInput
                        width="130px"
                        gapRequired="58px"
                        phoneNumWidth="220px"
                        // fontweight="bold"
                        countryCodeArray={codeArray}
                        name="phoneNumber"
                        onCountryCodeChange={handleCountryCodeChange}
                        onChange={handlePhoneChange}
                        propsValue={editedData?.phoneNumber || ""}
                        countryCode={editedData?.countryCode || ""}
                        error={!!valid.phoneNumber}
                        // helperText={valid.phoneNumber}
                      />
                      {valid.phoneNumber && (
                        <Typography
                          variant="caption"
                          color="error"
                          sx={{ fontSize: 12 }}
                        >
                          {valid.phoneNumber}
                        </Typography>
                      )}
                    </Grid>
                  </Box>
                  {/* <Box mb={3}>
                  <InputLabel
                    className={classes.blackColor}
                    sx={{ fontSize: { xs: 12, md: 14 }, mb: 2 }}
                  >
                    Country
                  </InputLabel>
                  <FormControl fullWidth size="small">
                    <InputLabel
                      id="demo-simple-select-label"
                      sx={{ fontSize: { xs: 12, md: 14 }, mb: 1 }}
                    >
                      Country
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Country"
                      size="small"
                      inputProps={{
                        style: {
                          fontSize: "14px",
                          // fontWeight: "bold",
                        },
                      }}
                      sx={{
                        borderRadius: "8px",
                        fontSize: "14px", // Apply borderRadius here
                      }}
                      value={editedData?.countryName || ""}
                      onChange={(e) =>
                        handleFieldChange("countryName", e.target.value)
                      }
                    >
                      {getAllCountries &&
                        getAllCountries?.map((item: any) => (
                          <MenuItem
                            key={item?.countryName}
                            value={item?.countryName}
                            sx={{ fontSize: 14 }}
                          >
                            <Typography
                              sx={{
                                fontSize: 14,
                                fontWeight:
                                  item?.countryName === editedData?.countryName
                                    ? "bold"
                                    : "normal",
                              }}
                            >
                              {item.countryName}
                            </Typography>
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                 
                </Box> */}
                  {/* <Typography variant="body2" color="error">
                    {errors.countryName}
                  </Typography> */}
                  <Box mb={3}>
                    <Typography
                      className={classes.blackColor}
                      sx={{ fontSize: { xs: 12, md: 14 }, mb: 2 }}
                    >
                      Country
                    </Typography>
                    <Autocomplete
                      fullWidth
                      size="small"
                      options={getAllCountries || []}
                      getOptionLabel={(option) => option.countryName}
                      value={selectedCountry}
                      onChange={(event, newValue) => {
                        // console.log(event, newValue, "event")
                        handleFieldChange(
                          "countryName",
                          newValue ? newValue.countryName : ""
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Country"
                          sx={{
                            "& .MuiInputLabel-root": {
                              fontSize: { xs: 12, md: 14 },
                              mb: 1,
                            },
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "8px",
                              fontSize: "14px",
                            },
                          }}
                          InputProps={{
                            ...params.InputProps,
                            style: {
                              fontSize: "14px",
                              // fontWeight: "bold",
                            },
                          }}
                        />
                      )}
                      renderOption={(props, option) => (
                        <li {...props}>
                          <Typography
                            sx={{
                              fontSize: 14,
                              fontWeight:
                                option.countryName === editedData?.countryName
                                  ? "bold"
                                  : "normal",
                            }}
                          >
                            {option.countryName}
                          </Typography>
                        </li>
                      )}
                    />
                    {/* <Typography variant="body2" color="error">
        {errors.countryName}
      </Typography> */}
                  </Box>
                  <Box mb={3}>
                    <InputLabel
                      className={classes.blackColor}
                      sx={{ fontSize: { xs: 12, md: 14 }, mb: 1 }}
                    >
                      WhatsApp Business ID
                    </InputLabel>
                    <TextFieldWithBorderComponent
                      placeholder=""
                      label="Enter your WhatsApp business ID"
                      name="whatsAppBusinessId"
                      size="small"
                      value={editedData?.whatsAppBusinessId || ""}
                      onChange={(e) =>
                        handleFieldChange("whatsAppBusinessId", e.target.value)
                      }
                      // fontweight="bold"
                      error={!!valid.whatsAppBusinessId}
                      helperText={valid.whatsAppBusinessId}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <InputLabel
                  className={classes.blackColor}
                  sx={{ fontSize: { xs: 12, md: 14 }, mb: 2, ml: 0.5 }}
                >
                  About
                </InputLabel>
                <Box mt={1}>
                  <textarea
                    style={{
                      width: "100%",
                      height: "140px",
                      border: "2px solid #DBDBDB",
                      borderRadius: "8px",
                      padding: "8px",
                      fontFamily: "unset",
                      fontSize: "14px",
                      // fontWeight: editedData?.about ? "normal" : "normal",
                      color: editedData?.about
                        ? bgColors.black
                        : "rgba(150, 160, 173, 1)",
                    }}
                    placeholder="Enter about yourself"
                    value={editedData?.about || ""}
                    onChange={(e) => handleFieldChange("about", e.target.value)}
                  />
                </Box>
              </Grid>
            </Box>
          </Box>
        </Grid>
      ) : (
        <NoAccessPage />
      )}
    </Grid>
  );
};

export default EditManageAccount;
