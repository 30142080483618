/* global process */

import axios from "axios";

const USER_API_URL = process.env.REACT_APP_BASE_URL;

const getAccountDetails = (id: string) => {
  return axios({
    url: `${USER_API_URL}/AccountDetails/${id}
    `,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
  });
};

const getManageAccount = () => {
  return axios({
    url: `${USER_API_URL}/AccountDetails/get-all-accounts
    `,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
  });
};

const getAllLanguages = () => {
  return axios({
    url: `${USER_API_URL}/Language/DisplayAllLanguages
    `,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
  });
};

const changePassword = ({
  email,
  currentPassword,
  newPassword,
  confirmPassword,
}: {
  email: string;
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}) => {
  return axios({
    url: `${USER_API_URL}/User/update-password`,
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
    data: JSON.stringify({
      email: email,
      newPassword: newPassword,
      confirmPassword: confirmPassword,
      temporaryPassword: currentPassword,
    }),
  });
};

const getAllCountries = () => {
  return axios({
    url: `${USER_API_URL}/CountryDetails/get-all-countries
    `,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
  });
};

const setPrefferedLanguage = (languageName: string) => {
  return axios({
    url: `${USER_API_URL}/Language/set-preferred-language
    `,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
    data: JSON.stringify({ languageName: languageName }),
  });
};

const getAllPaymentsCards = () => {
  return axios({
    url: `${USER_API_URL}/PaymentsDetails/payment-cards
    `,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
  });
};

const addPaymentCard = ({
  CardNumber,
  ExpiryDate,
  CVV,
  CardHolderName,
}: {
  CardNumber: string;
  ExpiryDate: string;
  CVV: string;
  CardHolderName: string;
}) => {
  const formData = new FormData();
  formData.append("CardNumber", CardNumber);
  formData.append("ExpiryDate", ExpiryDate);
  formData.append("CVV", CVV);
  formData.append("CardholderName", CardHolderName);

  return axios({
    url: `${USER_API_URL}/PaymentsDetails/add-payment-card`,
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: localStorage.getItem("token") || "",
    },
    data: formData,
  });
};

const getAllCountyCodes = () => {
  return axios({
    url: `${USER_API_URL}/CountryDetails/get-all-country-codes
    `,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
  });
};

const updateManageAccount = ({
  name,
  designation,
  emailAddress,
  phoneNumber,
  facebookBusinessManagerId,
  whatsAppBusinessId,
  countryCode,
  countryName,
  address,
  about,
  image,
  id,
}: {
  name: string;
  designation: string;
  emailAddress: string;
  phoneNumber: string;
  facebookBusinessManagerId: string;
  whatsAppBusinessId: string;
  countryName: string;
  countryCode: string;
  address: string;
  about: string;
  image: any;
  id: string;
}) => {
  const formData = new FormData();

  // Append fields to the formData object
  formData.append("Name", name);
  formData.append("designation", designation);
  formData.append("EmailAddress", emailAddress);
  formData.append("PhoneNumber", phoneNumber);
  formData.append("FacebookBusinessManagerId", facebookBusinessManagerId);
  formData.append("WhatsAppBusinessId", whatsAppBusinessId);
  formData.append("CountryName", countryName);
  formData.append("CountryCode", countryCode);
  formData.append("Address", address);
  formData.append("About", about);
  formData.append("Image", image);

  return axios({
    url: `${USER_API_URL}/AccountDetails/Update-Account/${id}`,
    method: "PUT",
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: localStorage.getItem("token"),
    },
    data: formData,
  });
};

const deletePaymentCard = ({ id }: { id: string }) => {
  return axios({
    url: `${USER_API_URL}/PaymentsDetails/${id}`,
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
  });
};

const getAccountMetaStatus = (businessId: string) => {
  return axios({
    url: `${USER_API_URL}/AccountDetails/get-meta-status/businessId?businessId=${businessId}
    `,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
  });
};

export const GET_MANAGE_ACCOUNT = {
  getAccountDetails,
  getManageAccount,
  getAllLanguages,
  changePassword,
  getAllCountries,
  setPrefferedLanguage,
  getAllPaymentsCards,
  addPaymentCard,
  getAllCountyCodes,
  updateManageAccount,
  deletePaymentCard,
  getAccountMetaStatus,
};
