import React, { FunctionComponent, useState } from "react";
import { Box, Typography, Grid, Link } from "@mui/material";
import ButtonComponent from "../common/ButtonComponent";
import LinkComponent from "../common/LinkComponent";
import { bgColors } from "../../utils/bgColors";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import OTPForm from "./OTPForm"; // Import the OTP component
import { ADMIN_LOGIN_API } from "../../Apis/AdminLogin/AdminLoginApi";
import { useAppDispatch, useAppSelector } from "../../utils/redux-hooks";
import { toastActions } from "../../utils/toastSlice";
import { adminLoginSliceActions } from "../../redux/slices/AdminLoginSlice";

export type VerificationFormContainerType = {
  loginPromptText?: string;
  // email?: string;
  frameDivLeft?: number | string; // Adjust the type according to your requirements
};

const useStyles = makeStyles({
  grid: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    // marginTop:"110px"
  },
  container: {
    // width: "200%",
    // width:"80%",
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
    borderRadius: "15px",
    padding: "50px",
    boxSizing: "border-box",
    backgroundColor: "#ffffff",
  },
  // button: {
  //   backgroundColor: `${bgColors.green}!important`,
  //   width: "100% !important",
  //   fontSize: "24px !important",
  //   fontWeight: "Semi Bold !important",
  //   borderRadius: "8px !important",
  //   marginLeft: "12px!important",
  // },
  button: {
    backgroundColor: `${bgColors.green}!important`,
    width: "100% !important",
    fontSize: "14px !important",
    fontWeight: "Semi Bold !important",
    borderRadius: "8px !important",
  },
  link: {
    textAlign: "right",
    fontSize: "16px!important",
    fontWeight: "600 !important",
    color: bgColors.green,
    marginLeft: "10px !important",
  },
  promptText: {
    marginBottom: "20px !important",
    fontWeight: "bold !important",
    textAlign: "center",
  },
  emailmsg: {
    color: `#A7AEC1 !important`,
    fontSize: "16px !important",
    textAlign: "center",
  },
  code: {
    color: `#191D31 !important`,
    fontWeight: "Regular !important",
    fontSize: "16px !important",
    textAlign: "center",
  },
  otp: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  resendmsg: {
    color: "#828282 !important",
    fontSize: "16px !important",
    fontFamily: "Inter !important",
    fontWeight: "normal !important",
    textAlign: "center",
  },
  linkContainer: {
    fontSize: "16px",
    textAlign: "center",
  },
  backText: {
    color: `#828282 !important`,
    fontSize: "16px !important",
    textAlign: "center",
    fontWeight: "500 !important",
  },
  arrowIcon: {
    marginRight: "10px",
  },
  receive: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginBottom: "15px",
  },
});

const VerificationFormContainer: FunctionComponent<
  VerificationFormContainerType
> = ({ loginPromptText }) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState("");
  const otpPattern = /^[0-9]{6}$/;

  const email = useAppSelector((state: any) => state.adminLogin?.email);

  //   useEffect(()=>{

  //   },[handleOtpVerify])

  const handleOtpVerify = async () => {
    // Validate OTP format
    if (!otpPattern.test(otp)) {
      setOtpError("OTP must be a 6-digit number.");
      return;
    }

    try {
      const response = await ADMIN_LOGIN_API.verifyOtp({ otp, email });
      // console.log("response", response);

      if (response.status === 200) {
        dispatch(
          toastActions.setToaster({
            type: "success",
            message: `${response?.data}`,
          })
        );
        navigate("/new-password");
      } else {
        dispatch(
          toastActions.setToaster({
            type: "error",
            message: `${response?.data?.message}`,
          })
        );
      }
    } catch (err: any) {
      // console.log("Error:", err?.response?.data);
      dispatch(
        toastActions.setToaster({
          type: "error",
          message: `${err?.response?.data}`,
        })
      );
      // console.log(err);
    }
  };
  const handleResendOtp = async () => {
    if (email) {
      try {
        const response = await ADMIN_LOGIN_API.forgotPassword({ email });
        // console.log(response,'response')
        if (response?.data?.message === "OTP sent successfully.") {
          dispatch(adminLoginSliceActions.setEmail(email));
          dispatch(
            toastActions.setToaster({
              type: "success",
              message: response?.data?.message,
            })
          );
        } else {
          dispatch(
            toastActions.setToaster({
              type: "error",
              message: response?.data?.message,
            })
          );
        }
      } catch (e: any) {
        dispatch(
          toastActions.setToaster({
            type: "error",
            message: e?.response?.data?.message,
          })
        );
      }
    }
  };
  return (
    <Grid item xs={5} className={classes.grid}>
      <Box className={classes.container}>
        <Typography variant="h5" className={classes.promptText}>
          {loginPromptText}
        </Typography>
        <Typography variant="h6" className={classes.emailmsg}>
          We have sent the verification code to
        </Typography>
        <Typography variant="h6" className={classes.code}>
          {email?.substring(0, 3)}******{email?.slice(-10)}
        </Typography>

        <Grid item xs={12} mt={4} className={classes.otp}>
          <OTPForm
            separator={<span> </span>}
            value={otp}
            onChange={(value) => setOtp(value)}
            length={6}
            error={!!otpError}
            helperText={otpError}
          />
        </Grid>

        {/* <Grid container spacing={2} xs={12}> */}
        <Grid item xs={12} my={2}>
          <ButtonComponent
            onClick={handleOtpVerify}
            title="Submit"
            className={classes.button}
          />
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.receive}>
            <Typography variant="h6" className={classes.resendmsg}>
              Didn&apos;t receive the code?
            </Typography>
            {/* <LinkComponent
            onClick={handleForgotPassword}
              href="/#/forgot-password"
              title="Resend"
              className={classes.link}
            /> */}
            <Typography
              className={classes.link}
              sx={{
                cursor: "pointer",
              }}
              onClick={handleResendOtp}
            >
              Resend
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} className={classes.linkContainer}>
          <Link href="/login">
            <img
              alt=""
              src="/images/BackArrow.png"
              className={classes.arrowIcon}
            />
          </Link>
          <LinkComponent
            href="/login"
            title="Back to Login"
            className={classes.backText}
          />
        </Grid>
        {/* </Grid> */}
      </Box>
    </Grid>
  );
};

export default VerificationFormContainer;
