import React from "react";
import { Typography, Container, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import QRCode from "qrcode.react";
import { useAppSelector } from "../../../utils/redux-hooks";

const useStyles = makeStyles({
  root: {
    height: "100vh",
    width: "100%",
    overflowY: "hidden" /* Initially hide the scrollbar */,
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    paddingTop: 40,
  },
  step: {
    fontSize: "18px",
  },
  number: {
    fontSize: "14px",
    fontWeight: "bold",
    marginBottom: "5px",
    color: "#000000",
  },
});

const StepByStepGuide: React.FC = () => {
  const classes = useStyles();
  const loginData = useAppSelector((state:any) => state?.adminLogin?.data);
  const accountData = useAppSelector((state:any) => state?.accountData?.data);
  const phoneNumber = "7396986638";
  const defaultMessage = `Hey! I have a question ${loginData?.companyId}`;
  const encodedMessage = encodeURIComponent(defaultMessage);
  const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;

  return (
    <Container className={classes.root}>
      {accountData?.companyVerificationStatus === false && (
        <Box sx={{ mb: 10 }}>
          <Typography variant="h6" gutterBottom>
            Scan this QR code to open WhatsApp chat
          </Typography>
          <br />
          <br />
          <QRCode value={whatsappUrl} size={200} />
        </Box>
      )}
    </Container>
  );
};

export default StepByStepGuide;
