import React, { useState } from "react";
import {
  AccordionDetails,
  Grid,
  Box,
  Typography,
  TextField,
  Button,
} from "@mui/material";
import InputPair from "./InputPair";

const ListDetails = ({ list, onUpdate, onAddInput }: any) => {
  const [editingList, setEditingList] = useState(list);

  const handleInputChange = (index: number, field: string, value: any) => {
    const newInputs = editingList.inputs.map((input: any, i: any) => {
      if (i === index) {
        return { ...input, [field]: value };
      }
      return input;
    });
    setEditingList({ ...editingList, inputs: newInputs });
  };

  const handleListNameChange = (value: any) => {
    setEditingList({ ...editingList, listName: value });
  };

  const handleButtonNameChange = (value: any) => {
    setEditingList({ ...editingList, buttonName: value });
  };

  const handleAddNewInput = () => {
    if (editingList.inputs.length < 10) {
      setEditingList({
        ...editingList,
        inputs: [...editingList.inputs, { title: "", description: "" }],
      });
    }
  };
  return (
    <AccordionDetails sx={{ overflowY: "scroll", p: "16px" }}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Box>
            <Typography variant="subtitle2" sx={{ mb: 1 }}>
              List Name
            </Typography>
            <TextField
              label="List Name"
              variant="outlined"
              fullWidth
              size="small"
              value={editingList.listName}
              onChange={(e) => handleListNameChange(e.target.value)}
              sx={{
                height: "40px",
                borderRadius: "10px",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "10px",
                },
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box>
            <Typography variant="subtitle2" sx={{ mb: 1 }}>
              Button Name
            </Typography>
            <TextField
              label="Button Name"
              variant="outlined"
              fullWidth
              size="small"
              value={editingList.buttonName}
              onChange={(e) => handleButtonNameChange(e.target.value)}
              sx={{
                height: "40px",
                borderRadius: "10px",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "10px",
                },
              }}
            />
          </Box>
        </Grid>
      </Grid>

      <Box sx={{ marginTop: "10px" }}>
        <Typography variant="subtitle1">Input(s)</Typography>
        <Typography variant="caption">
          Total number of inputs cannot be more than 10
        </Typography>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          {editingList.inputs.map((input: any, index: number) => (
            <Grid item xs={12} key={index}>
              <InputPair
                input={input}
                onChange={handleInputChange}
                index={index}
              />
            </Grid>
          ))}
        </Grid>
        <Box
          sx={{
            mt: 2,
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Typography
            sx={{
              color: "green",
              fontWeight: 600,
              fontSize: "14px",
              cursor: "pointer",
            }}
          >
            Choose from custom Auto Reply
          </Typography>
          <Typography variant="caption">OR</Typography>
          <Typography
            // onClick={() => onAddInput(editingList.inputs.length)}
            onClick={handleAddNewInput}
            sx={{
              color: editingList.inputs.length >= 10 ? "gray" : "green",
              fontWeight: 600,
              fontSize: "14px",
              cursor:
                editingList.inputs.length >= 10 ? "not-allowed" : "pointer",
              pointerEvents: editingList.inputs.length >= 10 ? "none" : "auto",
            }}
          >
            + Add custom input
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Button
          fullWidth
          variant="outlined"
          style={{
            color: "green",
            fontWeight: "600",
            border: "1px solid green",
            borderRadius: "8px",
            textTransform: "capitalize",
            width: "200px",
            fontSize: "14px",
            cursor: "pointer",
            marginTop: "40px",
            marginBottom: "0px",
          }}
          onClick={() => onUpdate(editingList)}
        >
          Update and Select
        </Button>
      </Box>
    </AccordionDetails>
  );
};

export default ListDetails;
