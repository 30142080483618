/* global process */

import axios from "axios";

const WORKFLOW_API_URL = process.env.REACT_APP_BASE_URL;

const getWorkflowList = (companyId: any) => {
  return axios({
    url: `${WORKFLOW_API_URL}/AutoReplyMessage/GetWorkflowList?companyId=${companyId}`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
  });
};

const getWorkflow = (data: any) => {
  return axios({
    url: `${WORKFLOW_API_URL}/AutoReplyMessage/GetWorkflow?companyId=${data?.companyId}&workflowName=${data?.workflowName}`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
  });
};

const getWorkflowNames = (companyId: any) => {
  return axios({
    url: `${WORKFLOW_API_URL}/AutoReplyMessage/GetWorkflowNames/${companyId}`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
  });
};

const getVariableName = (companyId: any) => {
  return axios({
    url: `${WORKFLOW_API_URL}/AutoReplyMessage/GetVeriableNames?companyId=${companyId}`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
  });
};

  const deleteWorkflow = (data: any) => {
    return axios({
      url: `${WORKFLOW_API_URL}/AutoReplyMessage/DeleteWorkflow?companyId=${data?.companyId}&workflowName=${data?.workflowName}`,
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
    });
  }

const createWorkflowCustomMessage = (data: any) => {
  return axios({
    url: `${WORKFLOW_API_URL}/AutoReplyMessage/AddWorkflowList`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
    data: data,
  });
};

const createWorkflow = (data: any) => {
  return axios({
    url: `${WORKFLOW_API_URL}/AutoReplyMessage/CreateWorkflow/${data?.isNew}`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
    data: data.workflowData,
  });
};

  const updateWorkflow = (data: any) => {
    return axios({
      url: `${WORKFLOW_API_URL}/AutoReplyMessage/UpdateWorkflow`,
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
      data: data.workflowData,
    });
  };  

  const updateWorkflowList = (data: any) => {
    return axios({
      url: `${WORKFLOW_API_URL}/AutoReplyMessage/updateWOrkflowList`,
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
      data: data,
    });
  };

  const downloadWorkflowResponseListByName = (data: any) => {
    return axios({
      url: `${WORKFLOW_API_URL}/AutoReplyMessage/DownloadWorkflowResponseListByName?companyId=${data?.companyId}&workflowName=${data?.workflowName}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
      responseType: "blob",  
    });
  };
  

// select a response

const getSelectResponseData = (payload:any) => {
  return axios({
    url: `${WORKFLOW_API_URL}/AutoReplyMessage/AutomationSelectResponse/${payload}`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
  });
};

const createSelectResponse=(companyId: string, response: string)=>{
  console.log(companyId)
  return axios({
    url: `${WORKFLOW_API_URL}/AutoReplyMessage/AutomationSelectResponse/${companyId}`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
    data: response, 
  });
}

const updateSelectResponse=(selectResponseId: string, response: string)=>{
  return axios({
    url: `${WORKFLOW_API_URL}/AutoReplyMessage/AutomationSelectResponse/${selectResponseId}`,
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
    data: response, 
  });
}

const deleteSelectResponse=(selectResponseId: string)=>{
  console.log(selectResponseId)
  return axios({
    url: `${WORKFLOW_API_URL}/AutoReplyMessage/AutomationSelectResponse/${selectResponseId}`,
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
  });
}

  export const WORKFLOW_API = {
    getWorkflowList,
    getVariableName,
    deleteWorkflow,
    createWorkflowCustomMessage,
    updateWorkflowList,
    createWorkflow,
    getWorkflowNames,
    getSelectResponseData,
    getWorkflow,
    updateWorkflow,
    downloadWorkflowResponseListByName,
    createSelectResponse,
    updateSelectResponse,
    deleteSelectResponse
  };
