import React, { RefObject, useEffect, useState } from "react";
import {
  Box,
  MenuItem,
  Select,
  Typography,
  InputLabel,
  FormControl,
  Tooltip,
  FormHelperText,
} from "@mui/material";
// import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { makeStyles } from "@mui/styles";
import TextFieldWithBorderComponent from "../../common/TextFieldWithBorderComponent";
import { bgColors } from "../../../utils/bgColors";
import { DeleteOutlineRounded } from "@mui/icons-material";
import PhoneInput from "../../common/PhoneInput";
import {
  // useAppDispatch,
  useAppSelector,
} from "../../../utils/redux-hooks";
import { validatePhoneNumber, validateUrl } from "../../../utils/functions";
import { TemplateState } from "../TemplateForm";
// import { fetchAllCountryCodes } from "../../../redux/slices/ManageAccount/GetAllCountryCode";

const useStyles = makeStyles({
  changePasswordContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    // cursor: "pointer",
  },
  blackColor: {
    color: "#303030 !important",
    fontWeight: "600 !important",
  },
});

interface ButtonComponentProps {
  formErrors: any;
  buttonTypesList: any;
  buttonType: string;
  buttonValue: string;
  countryCode: string;
  index: number;
  buttonName?: string; // Optional property
  phoneNumberCount?: number;
  urlsCount?: number;
  repliesCount?: number;
  onRemoveButton: () => void;
  updateButton: (updatedButton: {
    buttonType: string;
    buttonValue: string;
    countryCode: string;
    buttonName?: string;
  }) => void;
  handleAddVariable: (
    type: string,
    urlButtonIndex?: number,
    buttonIndex?: number
  ) => void;
  urlButtonVariablesCount: any;
  urlButtonRef: RefObject<HTMLInputElement>;
  templateState: TemplateState;
  setTemplateState: any;
}

const ActionButtonsComponent: React.FC<ButtonComponentProps> = ({
  formErrors,
  buttonTypesList,
  buttonType,
  buttonValue,
  countryCode = "+91",
  index,
  buttonName = "",
  phoneNumberCount = 0,
  urlsCount = 0,
  repliesCount = 0,
  onRemoveButton,
  updateButton,
  handleAddVariable,
  urlButtonVariablesCount,
  urlButtonRef,
  templateState,
  setTemplateState,
}) => {
  const classes = useStyles();
  // const dispatch = useAppDispatch();

  const countryCodeArray = useAppSelector(
    (state: any) => state.getAllCoutryCodes?.data
  );
  const codeArray =
    countryCodeArray &&
    countryCodeArray?.map((item: any) => ({
      countryCode: item?.countryCode,
      countryImage: item?.countryImage,
    }));

  const [selectedType, setSelectedType] = useState<string>(buttonType);
  const [buttonValueState, setButtonValueState] = useState<string>(buttonValue);
  const [countryCodeValue, setCountryCodeValue] = useState<string>(countryCode);
  const [buttonTextLabel, setButtonTextLabel] = useState<string>(buttonName);
  const [errors, setErrors] = useState({
    websiteLink: formErrors?.redirectUrl || "",
    phoneNumber: formErrors?.phoneNumber || "",
    quickReply: formErrors?.quickReply || "",
    buttonText: formErrors?.urlButtonName || formErrors?.callButtonName || "",
  });

  const handleTypeChange = (event: any) => {
    const newSelectedType = event.target.value;
    setSelectedType(newSelectedType);
    setButtonValueState("");
    setButtonTextLabel("");
    // updateButton({
    //   buttonType: newSelectedType,
    //   buttonValue: buttonValueState,
    //   countryCode: countryCodeValue,
    //   buttonName: buttonTextLabel,
    // });
    updateButton({
      buttonType: newSelectedType,
      buttonValue: "",
      countryCode: countryCodeValue,
      buttonName: "",
    });
    setErrors({
      ...errors,
      websiteLink: "",
      phoneNumber: "",
      quickReply: "",
      buttonText: "",
    });
  };

  const handleValueChange = (event: any) => {
    const newButtonValue =
      selectedType === "PHONE_NUMBER" ? event : event.target.value;
    setButtonValueState(newButtonValue);
    updateButton({
      buttonType: selectedType,
      buttonValue: newButtonValue,
      countryCode: countryCodeValue,
      buttonName: buttonTextLabel,
    });
    const variableIds: any = newButtonValue?.match(/{{(\d+)}}/g) || [];
    // console.log("new", selectedType, newButtonValue);
    if (selectedType === "URL") {
      const updatedVariables =
        templateState.variables?.filter(
          (variable: any) =>
            variable.type !== "url Button" || variableIds.includes(variable.id)
        ) || [];
      setTemplateState((prevState: any) => ({
        ...prevState,
        variables: updatedVariables,
      }));
      // console.log("newww", updatedVariables, templateState);
    }
    let newErrors = { ...errors };
    if (selectedType === "URL" && newButtonValue.trim() === "") {
      newErrors.websiteLink = "Enter a valid website URL";
      const isValidUrl = validateUrl(newButtonValue);
      newErrors.websiteLink = isValidUrl ? "" : "Enter a valid website URL";
    } else if (selectedType === "URL" && newButtonValue.trim() !== "") {
      const isValidUrl = validateUrl(newButtonValue);
      newErrors.websiteLink = isValidUrl ? "" : "Enter a valid website URL";
    } else {
      newErrors.websiteLink = "";
    }
    if (selectedType === "PHONE_NUMBER" && newButtonValue.trim() === "") {
      newErrors.phoneNumber = "Phone number is required";
    } else if (
      newButtonValue &&
      !validatePhoneNumber(newButtonValue, countryCodeValue)
    ) {
      newErrors.phoneNumber =
        "This phone number format is not recognized. Please check the country code and phone number.";
    } else {
      newErrors.phoneNumber = "";
    }
    if (selectedType === "QUICK_REPLY" && newButtonValue.trim() === "") {
      newErrors.quickReply = "Quick reply text is required";
    } else {
      newErrors.quickReply = "";
    }
    setErrors(newErrors);
  };

  const handleCountryCodeChange = (value: string) => {
    setCountryCodeValue(value);
    updateButton({
      buttonType: selectedType,
      buttonValue: buttonValueState,
      countryCode: value,
      buttonName: buttonTextLabel,
    });

    if (selectedType === "PHONE_NUMBER" && value.trim() === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        phoneNumber: "Country code is required",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        phoneNumber: "",
      }));
    }
  };

  const handleTextChange = (event: any) => {
    const newButtonText = event.target.value;
    setButtonTextLabel(newButtonText);
    updateButton({
      buttonType: selectedType,
      buttonValue: buttonValueState,
      countryCode: countryCodeValue,
      buttonName: newButtonText,
    });

    if (selectedType === "PHONE_NUMBER" && newButtonText.trim() === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        buttonText: "Phone button name is required.",
      }));
    } else if (selectedType === "URL" && newButtonText.trim() === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        buttonText: "URL button name is required.",
      }));
    } else if (selectedType === "PHONE_NUMBER" && newButtonText?.length > 20) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        buttonText: "Phone button name should not exceed 20 characters.",
      }));
    } else if (selectedType === "URL" && newButtonText?.length > 25) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        buttonText: "URL button name should not exceed 25 characters.",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        buttonText: "",
      }));
    }
  };

  // Find the object that matches the buttonValueState and buttonName
  const matchingObject =
    urlButtonVariablesCount?.length > 0 &&
    urlButtonVariablesCount?.find(
      (item: any) =>
        item?.buttonValue === buttonValueState && item?.variablesCount <= 1
    );

  useEffect(() => {
    if (buttonType === "") {
      setSelectedType("");
      setButtonValueState("");
      setButtonTextLabel("");
    }
  }, [buttonType]);

  useEffect(() => {
    setSelectedType(buttonType);
    setButtonValueState(buttonValue);
    setCountryCodeValue(
      countryCode
        ? countryCode
        : selectedType === "PHONE_NUMBER"
        ? "+91"
        : countryCode
    );
    setButtonTextLabel(buttonName);
  }, [buttonType, buttonValue, countryCode, buttonName]);
  // console.log("matched", matchingObject);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        margin: "0 auto",
        paddingBlock: 2,
      }}
    >
      <Box
        display="flex"
        width="100%"
        sx={{ alignItems: "center" }}
        justifyContent="space-between"
      >
        <Typography fontSize={14} mb={2}>
          Button&nbsp;{index + 1}
        </Typography>
        <Tooltip title="Delete Button">
          <Typography
            onClick={onRemoveButton}
            sx={{
              fontSize: 12,
              cursor: "pointer",
              color: bgColors?.red,
              textTransform: "none",
            }}
          >
            <DeleteOutlineRounded />
          </Typography>
        </Tooltip>
      </Box>
      <Box width="100%" mb={{ xs: 1, md: 2 }}>
        <InputLabel
          className={classes.blackColor}
          sx={{ fontSize: { xs: 12, md: 14 }, mb: 2 }}
        >
          Buttons
        </InputLabel>
        <FormControl fullWidth size="small">
          <InputLabel
            id="demo-simple-select-label"
            sx={{ fontSize: { xs: 12, md: 14 } }}
          >
            Select Button
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedType}
            name="buttons"
            label="Select Button"
            inputProps={{ style: { fontSize: 14 } }}
            onChange={handleTypeChange}
            sx={{
              fontSize: 14,
              borderRadius: "8px",
            }}
          >
            {buttonTypesList?.map((item: any, index: number) => (
              <MenuItem
                key={index}
                disabled={
                  (item.value === "PHONE_NUMBER" && phoneNumberCount >= 1) ||
                  (item.value === "URL" && urlsCount >= 2) ||
                  (item.value === "QUICK_REPLY" && repliesCount >= 10)
                }
                value={item?.value}
                sx={{ fontSize: { xs: 10, md: 12 } }}
              >
                {item?.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      {selectedType !== "None" && (
        <Box display="flex" width="100%" justifyContent="space-between">
          <Box
            mb={{ xs: 1, md: 2 }}
            width={selectedType !== "QUICK_REPLY" ? "auto" : "100%"}
            sx={{
              width: "100%",
              paddingRight: selectedType !== "QUICK_REPLY" ? "3%" : 0,
            }}
          >
            <InputLabel
              className={classes.blackColor}
              sx={{ fontSize: { xs: 12, md: 14 }, mb: 1 }}
            >
              {selectedType === "QUICK_REPLY"
                ? "Quick Reply"
                : selectedType === "URL"
                ? "Website Link"
                : selectedType === "PHONE_NUMBER"
                ? "Phone Number"
                : ""}
            </InputLabel>
            {selectedType === "PHONE_NUMBER" ? (
              <>
                <PhoneInput
                  cntyPhnWidth={{ cntyWidth: 3.2, phnWidth: 8.3 }}
                  width="130px"
                  gapRequired="48px"
                  phoneNumWidth="270px"
                  countryCodeArray={codeArray}
                  name="buttonValue"
                  onChange={handleValueChange}
                  onCountryCodeChange={handleCountryCodeChange}
                  propsValue={buttonValueState}
                  countryCode={countryCodeValue}
                  error={!!errors?.phoneNumber || !!formErrors?.phoneNumber}
                />
                {(formErrors?.phoneNumber || errors?.phoneNumber) && (
                  <FormHelperText
                    style={{ color: bgColors?.red, fontSize: 12 }}
                  >
                    {formErrors?.phoneNumber || errors?.phoneNumber}
                  </FormHelperText>
                )}
              </>
            ) : selectedType === "URL" ? (
              <>
                <TextFieldWithBorderComponent
                  inputRef={urlButtonRef}
                  size="small"
                  fullWidth
                  name="buttonValue"
                  placeholder="Enter URL"
                  label="Enter URL"
                  value={buttonValueState}
                  onChange={handleValueChange}
                  error={!!errors?.websiteLink || !!formErrors?.redirectUrl}
                  helperText={errors?.websiteLink || formErrors?.redirectUrl}
                  sx={{
                    "& input": {
                      // fontWeight: "600",
                    },
                  }}
                />
                <Typography
                  variant="h5"
                  sx={{
                    fontSize: 12,
                    cursor: "pointer",
                    color: bgColors?.green,
                    textTransform: "none",
                    width: 85,
                    visibility:
                      matchingObject?.variablesCount >= 1
                        ? "hidden"
                        : "visible",
                  }}
                  onClick={() => {
                    if (matchingObject?.variablesCount < 1) {
                      handleAddVariable(
                        "url Button",
                        matchingObject?.buttonIndex,
                        index
                      );
                    }
                  }}
                >
                  + Add Variable
                </Typography>
              </>
            ) : selectedType === "QUICK_REPLY" ? (
              <TextFieldWithBorderComponent
                size="small"
                fullWidth
                name="buttonValue"
                placeholder="Enter Quick Reply"
                label="Enter Quick Reply"
                value={buttonValueState}
                onChange={handleValueChange}
                error={!!errors?.quickReply || !!formErrors?.quickReply}
                helperText={errors?.quickReply || formErrors?.quickReply}
                sx={{
                  "& input": {
                    // fontWeight: "600",
                  },
                }}
              />
            ) : null}
          </Box>
          {selectedType !== "QUICK_REPLY" && (
            <Box mb={{ xs: 1, md: 2 }}>
              <InputLabel
                className={classes.blackColor}
                sx={{ fontSize: { xs: 12, md: 14 }, mb: 1 }}
              >
                Button Text
              </InputLabel>
              <TextFieldWithBorderComponent
                size="small"
                fullWidth
                name="buttonType"
                placeholder=""
                label="Enter button text"
                value={buttonTextLabel}
                onChange={handleTextChange}
                error={
                  selectedType === "PHONE_NUMBER"
                    ? !!formErrors?.callButtonName || !!errors?.buttonText
                    : !!formErrors?.urlButtonName || !!errors?.buttonText
                  // !!errors?.buttonText ||
                }
                helperText={
                  selectedType === "PHONE_NUMBER"
                    ? formErrors?.callButtonName || errors?.buttonText
                    : formErrors?.urlButtonName || errors?.buttonText
                  // errors?.buttonText ||
                  // formErrors?.urlButtonName ||
                  // formErrors?.callButtonName
                }
                sx={{
                  "& input": {
                    // fontWeight: "600",
                  },
                }}
              />
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default ActionButtonsComponent;
