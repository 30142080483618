import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DownloadIcon from "@mui/icons-material/Download";
import CloseIcon from "@mui/icons-material/Close";
import FileUpload from "./fileUpload";
import { bgColors } from "../../../utils/bgColors";

interface UploadCSVDialogProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function UploadCSVDialog({ open, setOpen }: UploadCSVDialogProps) {
  const [selectedFiles, setSelectedFiles] = useState<any>([]);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: { borderRadius: "12px", padding: 2 },
      }}
    >
      <DialogTitle>
        <Typography variant="h6" fontWeight="bold">
          Upload CSV
        </Typography>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <FileUpload
          selectedFiles={selectedFiles}
          setSelectedFiles={setSelectedFiles}
        />
        <Box textAlign="center" sx={{ mb: 2 }}>
          <Button
            variant="text"
            sx={{
              color: bgColors?.green,
              textTransform: "none",
              fontSize: "12px",
            }}
            startIcon={<DownloadIcon fontSize="small" />}
          >
            Download sample CSV
          </Button>
        </Box>

        <Accordion sx={{ borderRadius: "8px" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{ borderRadius: "8px" }}
          >
            <Typography variant="body2" fontWeight="bold">
              Instructions to upload CSV
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2" sx={{ ml: 1 }}>
              <ul style={{ paddingLeft: "20px", fontSize: 12 }}>
                <li>
                  Maximum 50MB file allowed (or up to 100 items) and .csv format
                  only.
                </li>
                <li>
                  CSV should mandatorily contain retailer_id, Title,
                  description, link, and price.
                </li>
                <li>
                  If quantity is not defined, we will take the default quantity
                  as infinite.
                </li>
                <li>
                  If product collection is not defined, the product will be
                  shown under "All products".
                </li>
                <li>
                  If you have a line item with the same retailer ID, that
                  product information will be modified.
                </li>
                <li>
                  Valid values under availability are: in stock, out of stock,
                  available for order.
                </li>
                <li>
                  Valid values under condition are New, Refurbished, Used.
                </li>
                <li>Download the file and export it to CSV to upload here.</li>
              </ul>
            </Typography>
          </AccordionDetails>
        </Accordion>
      </DialogContent>
    </Dialog>
  );
}

export default UploadCSVDialog;
